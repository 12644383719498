/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { X } from 'lucide-react'
import CommonInput from '../../InputComponet/CommonInput'
import { debounce } from '../../../helpers/Utility'
import {
  s3ImageHotel,
  s3ImageOrganizer,
  s3ImageRestaurant
} from '../../../services/Service'
// import Dropdown from '../Dropdown/Dropdown'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import English from '../../../helpers/English'
import Images from '../../../helpers/Images'
import { APICall, EndPoints } from '../../../services'
import CommonModalMain from '../../CommonModalMain'
import Loader from '../../Loader/Loader'

const ConnectLocationModal = ({
  ShowProductsModel,
  setShowProductsModel,
  onSubmited
}) => {
  const app_id = useSelector((state) => state.user?.app_id)
  const [isLibraryLoading, setIsLibraryLoading] = useState(false)
  const [product, setProduct] = useState([])

  const [loading, setLoading] = useState(false)
  const productLibraryInitialized = useRef(false)
  const [hotels, setHotels] = useState([])

  const [selected, setSelected] = useState([])
  const [searchText, setSearchText] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    setProduct([...hotels])
  }, [hotels])

  const allHotelData = (search, currentPage) => {
    setIsLibraryLoading(true)
    const payload = {
      app_id: app_id?.id,
      search: search || ''
    }
    APICall(
      'post',
      payload,
      `${EndPoints.locationList}?search=${search}&page=${currentPage}`,
      'local'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          if (res?.decryptedData?.data?.length > 0) {
            const list = res?.decryptedData.data
            setHotels(currentPage === 1 ? list : [...hotels, ...list])
            setTotalPages(res?.decryptedData.last_page)
            setCurrentPage(res?.decryptedData.current_page)
            setIsLibraryLoading(false)
          }
        } else {
          setIsLibraryLoading(false)
        }
      })
      .catch(() => {
        setIsLibraryLoading(false)
      })
  }

  const clearSearch = () => {
    setSearchText('')
    handleSearch2('')
  }

  const handleSearch = (value) => {
    setSearchText(value)
    handleSearch2(value)
  }

  const ConnectLocalAPI = () => {
    setLoading(true)
    const { hotelIds, restaurantIds, organizerIds } = selected.reduce(
      (acc, item) => {
        if (item.hotel_id !== undefined) acc.hotelIds.push(item.hotel_id)
        if (item.restaurant_id !== undefined) acc.restaurantIds.push(item.restaurant_id)
        if (item.organizer_id !== undefined) acc.organizerIds.push(item.organizer_id)
        return acc
      },
      { hotelIds: [], restaurantIds: [], organizerIds: [] }
    )
    const data = {
      app_id: [app_id.id],
      ...(hotelIds.length > 0 && { hotel_id: hotelIds }),
      ...(restaurantIds.length > 0 && { restaurant_id: restaurantIds }),
      ...(organizerIds.length > 0 && { organizer_id: organizerIds }),
      publish: true
    }
    APICall(
      'post',
      data,
      `${EndPoints.connectLocal}`,
      'local'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          onSubmited()
          setIsLibraryLoading(false)
          setLoading(false)
          setShowProductsModel(false)
        } else {
          setIsLibraryLoading(false)
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLibraryLoading(false)
        setLoading(false)
      })
  }

  const handleSearch2 = useCallback(
    debounce((inputVal) => {
      // ConnectLocalAPI(inputVal)
      allHotelData(inputVal, currentPage)
    }, 500),
    []
  )

  const AddLibrary = () => {
    ConnectLocalAPI()
  }

  const HandleCancel = () => {
    setShowProductsModel(false)
  }

  useEffect(() => {
    if (!productLibraryInitialized.current && ShowProductsModel) {
      productLibraryInitialized.current = true
      allHotelData(null, currentPage)
    }
  }, [ShowProductsModel])

  const onSelectedItem = (data) => {
    setSelected((prevSelected) => {
      const exists = prevSelected.some(
        (item) => item?.hotel_id === data.hotel_id &&
          item?.restaurant_id === data.restaurant_id &&
          item?.organizer_id === data.organizer_id
      )
      if (exists) {
        return prevSelected.filter(
          (item) => item?.hotel_id !== data?.hotel_id ||
            item?.restaurant_id !== data?.restaurant_id ||
            item?.organizer_id !== data?.organizer_id
        )
      }
      return [...prevSelected, data]
    })
  }

  const handlePageChange = (pageNumber) => {
    allHotelData(null, pageNumber)
    setCurrentPage(pageNumber)
  }

  return (
    <CommonModalMain
      showCross
      title={English.B91}
      isPrevious={English.G78}
      isNext={English.B90}
      onPressClose={HandleCancel}
      onPressSave={() => AddLibrary()}
      onPressBack={HandleCancel}
      showFooter
      isButtonDisabled={selected.length === 0}
    >
      <div className="w-full h-full flex flex-col gap-5 justify-between overflow-y-auto">
        {loading && <Loader />}
        <div className="">
          <div className="">
            <div className="">
              <div className="w-full relative">
                <img
                  src={Images.serch_icon_store}
                  alt="search"
                  className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5"
                />
                <CommonInput
                  placeholder="Search for an item..."
                  className="!pl-10 !rounded-full !bg-PrimaryBg before:"
                  onChange={(e) => {
                    handleSearch(e.target.value)
                  }}
                  value={searchText}
                />
                {searchText && (
                <button
                  type="button"
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-black"
                  onClick={clearSearch}
                >
                  <X className="w-5 h-5" />
                </button>
            )}
              </div>
            </div>
          </div>
          <div className="!py-5 grid-cols-2 grid !gap-5">
            {product?.length === 0 && !isLibraryLoading ? (
              <div className="awards-item">
                <div className="product-footer">
                  <p className="add-value" />
                  <span className="add-value" />
                </div>
              </div>
            ) : isLibraryLoading ? (
              <div className="awards-item">
                <div className="add-profile-video-cover add-product-video-cover">
                  <button type="button" />
                </div>
                <div className="product-footer">
                  <p className="add-value">
                    <Skeleton className="add-value" width={202} height={35} />
                  </p>
                  <span className="add-value">
                    <Skeleton className="add-value" width={67} height={35} />
                  </span>
                </div>
              </div>
            ) : (
              product?.map((data, i) => (
                <div
                  key={i}
                  className={`product-item !h-[260px] rounded-xl relative overflow-hidden border  ${selected.some((item) => item?.hotel_id === data?.hotel_id && item?.restaurant_id === data?.restaurant_id && item?.organizer_id === data?.organizer_id) ? 'border-black' : 'border-PrimaryBorder'}`}
                >
                  <div onClick={() => onSelectedItem(data)} className="h-full">
                    <div
                      className="h-[200px]"
                      style={{
                        backgroundImage: `url(${data.event_id ? s3ImageOrganizer + data.images[0] : (data.restaurant_id ? s3ImageRestaurant : data?.organizer_id ? s3ImageOrganizer : s3ImageHotel) + data.images[0]})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}
                    >
                      <div className="rating hidden">
                        <img src={Images.RatingStar} alt="star" />
                        <span>4.9</span>
                      </div>
                    </div>
                    <div className="product-item-details flex flex-1 flex-col">
                      <div className="font-bold text-[16px] truncate whitespace-nowrap overflow-hidden text-ellipsis">
                        {data.name}
                      </div>
                      <div className="font-normal text-[14px] text-[#5A5A5A] leading-none truncate whitespace-nowrap overflow-hidden text-ellipsis">
                        {data.address}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
            {totalPages > 1 && currentPage <= totalPages - 1 && (
            <div
              className="p-5 cursor-pointer transition-transform duration-300 ease-in-out hover:scale-150"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <img
                className="justify-self-center"
                src={Images.down_arrow_icon}
                alt="load more"
              />
            </div>
          )}
          </div>
        </div>
        <div className="item-details-input pt-0 hidden">
          <div className="button-row store_sticky_btn pt-4 pb-4">
            <CommonButton
              label={English.G116}
              type="button"
              onClick={HandleCancel}
              isPrimary={false}
              context={English.G232}
            />
          </div>
        </div>
      </div>
    </CommonModalMain>
  )
}

export default ConnectLocationModal

/* eslint-disable no-unused-vars */
/* eslint-disable no-return-await */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import './RoomAdd.css'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Dropdown from '../../Dropdown/Dropdown'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import {
  capitalizeFirstLetter,
  lockScroll,
  unlockScroll
} from '../../../helpers/Utility'
import { s3ImageHotel } from '../../../services/Service'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import Loader from '../../Loader/Loader'
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal'
import Pagination from '../../Pagination/Pagination'
import English from '../../../helpers/English'
import Images from '../../../helpers/Images'
import { APICall, EndPoints } from '../../../services'

const RoomAdd = ({ isHotelId }) => {
  const [isAddRoom, setIsAddRoom] = useState(false)
  const [isRoomFeature, setIsRoomFeature] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [inputFields, setInputFields] = useState({
    name: '',
    room_type_id: '',
    other_details: {
      person: '',
      bathroom: '',
      sofa: '',
      tv: ''
    },
    price: '',
    images: [],
    hotel_id: isHotelId,
    room_id: '',
    bed_option_id: '',
    size_in_sq_ft: ''
  })
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [isRoomType, setIsRoomType] = useState([])
  const [isBedType, setIsBedType] = useState([])
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [roomListData, setRoomListData] = useState([])
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [loading, setLoading] = useState(false)
  const [delRoomId, setDelRoomId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [particularRoom, setParticularRoom] = useState([])
  const [roomAddLoading, setRoomAddLoading] = useState(false)

  const clearValue = () => {
    setInputFields({
      ...inputFields,
      name: '',
      room_type_id: '',
      other_details: {
        person: '',
        bathroom: '',
        sofa: '',
        tv: ''
      },
      price: '',
      images: [],
      bed_option_id: '',
      size_in_sq_ft: ''
    })
    setIsEdit(false)
  }

  const initialized = useRef()

  const handleCancels = () => {
    setIsAddRoom(false)
    setIsRoomFeature(false)
    clearValue()
  }

  const openAddRoom = (data = {}) => {
    if (Object.keys(data).length > 0) {
      setIsEdit(true)
      setInputFields({
        hotel_id: data?.hotel_id,
        name: data?.name,
        price: data?.price,
        room_type_id: data?.room_type_id,
        bed_option_id: data?.bed_option_id,
        size_in_sq_ft: data?.size_in_sq_ft,
        room_id: data?.room_id,
        user_id: data?.user_id,
        other_details: {
          person: data?.other_details?.person.replace(/[^\d]/g, ''),
          bathroom: data?.other_details?.bathroom.replace(/[^\d]/g, ''),
          sofa: data?.other_details?.sofa.replace(/[^\d]/g, ''),
          tv: data?.other_details?.tv
        },
        images: data?.images
      })
    }
    setInitialValues({
      hotel_id: data?.hotel_id,
      name: data?.name,
      price: data?.price,
      room_type_id: data?.room_type_id,
      bed_option_id: data?.bed_option_id,
      size_in_sq_ft: data?.size_in_sq_ft,
      room_id: data?.room_id,
      user_id: data?.user_id,
      other_details: {
        person: data?.other_details?.person.replace(/[^\d]/g, ''),
        bathroom: data?.other_details?.bathroom.replace(/[^\d]/g, ''),
        sofa: data?.other_details?.sofa.replace(/[^\d]/g, ''),
        tv: data?.other_details?.tv
      },
      images: data?.images
    })
    setIsAddRoom(true)
  }

  const roomType = (room_id) => {
    setIsRoomFeature(true)
    particularRoomData(room_id)
  }
  // const handleCancel = () => {
  //   setIsRoomFeature(false)
  // }

  const clickOnParticularEdit = (data) => {
    openAddRoom(data)
    setIsRoomFeature(false)
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (['person', 'bathroom', 'sofa', 'tv'].includes(name)) {
      setInputFields({
        ...inputFields,
        other_details: {
          ...inputFields.other_details,
          [name]: value
        }
      })
    } else {
      setInputFields({
        ...inputFields,
        [name]: value
      })
    }
  }

  // const onUploadImage = async (file, fieldName, index) => {
  //   if (file) {
  //     const reader = new FileReader()
  //     reader.onload = async () => {
  //       const imageFile = await compressImage(file)
  //       const coverFormData = new FormData()
  //       coverFormData.append('file', imageFile)
  //       coverFormData.append('type', 'hotel_room')

  //       try {
  //         const res = await APICall(
  //           'post',
  //           coverFormData,
  //           '',
  //           'imageUploadLocal',
  //           true
  //         )

  //         if (res.status === 200 && res.data?.code === 200) {
  //           const decryptedImageData = dataDecrypt(res.data.data)

  //           const updatedImages = [...inputFields.images]
  //           if (
  //             (index !== undefined || index !== null) &&
  //             Array.isArray(updatedImages)
  //           ) {
  //             updatedImages[index] = decryptedImageData
  //           }
  //           setInputFields((prev) => ({
  //             ...prev,
  //             images: updatedImages
  //           }))
  //         } else {
  //           toast.error(res?.data?.message || 'Error uploading image')
  //         }
  //       } catch (err) {
  //         toast.error(err?.response?.data?.message || 'Upload failed')
  //       }
  //     }

  //     reader.readAsDataURL(file)
  //   }
  // }

  const onUploadImage = async (file, fieldName, index) => {
    if (file) {
      setInputFields((prev) => {
        const prevData = prev[fieldName] || []
        const updatedData = [...prevData]

        if (index !== undefined && index !== null && Array.isArray(prevData)) {
          updatedData[index] = file
        } else {
          updatedData.push(file)
        }

        return {
          ...prev,
          [fieldName]: updatedData
        }
      })
    }
  }

  const onUpload = async (file, fieldName, index) => {
      try {
        const coverFormData = new FormData()

          const compressedImages = await Promise.all(
            Array.from(file)
              .filter((file) => file instanceof File)
              .map(async (file) => await compressImage(file))
          )
          compressedImages.forEach((imageFile) => {
            coverFormData.append('files[]', imageFile)
          })

          coverFormData.append('type', 'hotel_room')

        try {
          const res = await APICall(
            'post',
            coverFormData,
            EndPoints.uploadFileMultipleLocal,
            'local',
            true
          )
          if (res.status === 200 && res.data?.code === 200) {
            const decryptedImageData = dataDecrypt(res.data.data)
            if (fieldName === 'images') {
              return [
                ...decryptedImageData,
                ...file.filter((item) => !(item instanceof File))
              ]
            }
          } else {
            toast.error(res?.data?.message || 'Error uploading image')
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || 'Upload failed')
        }
      } catch (error) {
        console.error('Error uploading image:', error)
      }
    }

  const ShowConfirm = (videoId) => {
    setDelRoomId(videoId)
    setShowConfirmModel(true)
  }
  const handleDeleteCancel = () => {
    setShowConfirmModel(false)
  }

  const typeofRoomListData = () => {
    APICall('post', {}, `${EndPoints.typeofRoom}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsRoomType(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }
  const typeofBedListData = () => {
    APICall('post', {}, `${EndPoints.bedOption}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsBedType(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const roomAddApiCall = async () => {
    const newFile = inputFields.images
    .map((item) => (typeof item !== 'string' ? item : null))
    .filter((item) => item !== null)


  const uploadedImages =
    newFile.length !== 0 ? await onUpload(newFile, 'images') : []

  const images = inputFields.images.map((item) => (typeof item === 'string' ? item : uploadedImages?.shift()))

    setRoomAddLoading(true)

    const payload = {
      name: inputFields.name,
      room_type_id: inputFields.room_type_id,
      bed_option_id: inputFields.bed_option_id,
      size_in_sq_ft: inputFields.size_in_sq_ft,
      other_details: inputFields.other_details,
      images,
      price: inputFields.price,
      hotel_id: inputFields.hotel_id,
      room_id: inputFields.room_id
    }
    if (isEdit) {
      await APICall('post', payload, `${EndPoints.editRoom}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setTimeout(() => {
              setIsAddRoom(false)
              setIsEdit(false)
              toast.success(res?.data?.message)
              allRoomData(currentPage)
              clearValue()
              setRoomAddLoading(false)
            }, 3000)
          } else {
            setIsEdit(false)
            setRoomAddLoading(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setIsEdit(false)
          setRoomAddLoading(false)
          toast.error(error?.data?.message)
        })
    } else {
      await APICall('post', payload, `${EndPoints.addRoom}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setTimeout(() => {
              setIsAddRoom(false)
              setIsEdit(false)
              toast.success(res?.data?.message)
              allRoomData(currentPage)
              clearValue()
              setRoomAddLoading(false)
            }, 3000)
          } else {
            setRoomAddLoading(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setRoomAddLoading(false)
          toast.error(error?.data?.message)
        })
    }
  }

  const allRoomData = (currentPage) => {
    const payload = {
      hotel_id: isHotelId
    }
    APICall(
      'post',
      payload,
      `${EndPoints.getRoom}?page=${currentPage}`,
      'local'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setRoomListData(res?.decryptedData.data)
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const particularRoomData = (room_id) => {
    const payload = {
      hotel_id: isHotelId,
      room_id
    }
    APICall('post', payload, `${EndPoints.particularRoom}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setParticularRoom(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const deleteRoom = () => {
    const data = {
      room_id: delRoomId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.deleteRoom}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setLoading(false)
          setTimeout(() => {
            toast.success(res?.data?.message)
            allRoomData(currentPage)
            clearValue()
          }, 2000)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const removeImage = (index) => {
    const updatedImages = [...inputFields.images]
    updatedImages.splice(index, 1)
    setInputFields((prev) => ({
      ...prev,
      images: updatedImages
    }))
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setInputFields((prev) => {
        const copy = [...prev.images]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        return {
          ...prev,
          images: copy
        }
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  useEffect(() => {
    if (ShowConfirmModel) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [ShowConfirmModel])

  const renderImg = useMemo(() => {
    return (
      <>
        {inputFields.images.map((image, index) => {
          return (
            <div
              className={`image position-relative !shadow-none ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative !shadow-none ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImageHotel + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button
                className="image_remove_icon"
                type="button"
                onClick={() => removeImage(index)}
              >
                <img src={Images.RemoveLocal} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    onUploadImage(file, 'images', index)
                  }}
                  selectedImg=""
                  defaultImg={Images.plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [inputFields.images, draggedIndex])

  const getRoomName = (room_type_id) => {
    const categoryObj = isRoomType.find(
      (cat) => cat.room_type_id === room_type_id
    )
    return categoryObj ? categoryObj.type : 'Select room type'
  }
  const getBedName = (bed_option_id) => {
    const categoryObj = isBedType.find(
      (cat) => cat.bed_option_id === bed_option_id
    )
    return categoryObj ? categoryObj.option : 'Select bed type'
  }

  const validateForm = () => {
    const {
      name,
      room_type_id,
      other_details,
      price,
      images,
      bed_option_id,
      size_in_sq_ft
    } = inputFields
    const { person, bathroom, sofa, tv } = other_details

    if (
      name &&
      room_type_id &&
      person &&
      bathroom &&
      sofa &&
      tv &&
      price &&
      bed_option_id &&
      size_in_sq_ft &&
      images.length > 0
    ) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const handlePageChange = (pageNumber) => {
    allRoomData(pageNumber)
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    if (!isEdit) {
      validateForm()
    }
  }, [inputFields])

  useEffect(() => {
    if (isEdit) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEdit])

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      typeofRoomListData()
      typeofBedListData()
      allRoomData(currentPage)
    }
  }, [])

  useEffect(() => {
    if (isAddRoom || isRoomFeature || ShowConfirmModel) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [isAddRoom, isRoomFeature, ShowConfirmModel])

  const renderRoomList = useMemo(() => {
    return (
      <div className="room-lists">
        {roomListData.map((room, index) => {
          return (
            <div key={index} className="room-card position-relative">
              {/* <div className="discount-badge">{room.discount}</div> */}
              <img
                className="room-card-img"
                src={s3ImageHotel + room.images[0]}
                alt={room.name}
                onClick={() => roomType(room.room_id)}
              />
              <div className="local-room-detail">
                <h4>{room.name}</h4>
                <div className="room-facilities">
                  <p>
                    <img src={Images.person} alt="sofa" />
                    {room.other_details.person}
                  </p>
                  <p>
                    <img src={Images.Sofa} alt="sofa" />
                    {room.other_details.sofa}
                  </p>
                  <p>
                    <img src={Images.RoomTV} alt="tv" />
                    {room.other_details.tv}
                  </p>
                  <p>
                    <img src={Images.BathRoom} alt="Bathroom" />
                    {room.other_details.bathroom}
                  </p>
                </div>
                <p className="room-price">
                  {English.G283}
                  {room.price}
                  {' '}
                  {English.G273}
                </p>
              </div>
              <div className="image-buttons z-1">
                <label>
                  <button
                    type="button"
                    className="d-none"
                    onClick={() => openAddRoom(room)}
                  />
                </label>
                <button
                  type="button"
                  className="p-0"
                  onClick={() => {
                    setUpdatingIndex(index)
                    ShowConfirm(room.room_id)
                  }}
                />
              </div>
              <ConfirmationModal
                isVisible={ShowConfirmModel && updatingIndex === index}
                onConfirm={deleteRoom}
                onCancel={handleDeleteCancel}
                message="Do you want to delete this?"
                isLoading={loading}
              />
              {loading && updatingIndex === index && <Loader />}
            </div>
          )
        })}
      </div>
    )
  }, [roomListData, loading, updatingIndex, ShowConfirmModel])

  return (
    <div className="">
      <div className="">
        {roomListData.length === 0 ? (
          <div className="add_room_text">
            <img className="mb-2" src={Images.bedroom} alt="bedroom" />
            <h3>{English.G334}</h3>
            <p>{English.G335}</p>
            <div className="add-video-rows mt-3" onClick={() => openAddRoom()}>
              <div className="add_new_content uppercase italic !px-[20px] !py-[13px]">
                <img src={Images.plusrounded} alt="" />
                <span className="mr-2">
                  {English.G336}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="local-room-main">
            <div className="d-flex align-items-center justify-content-between mb-[15px]">
              <h3 className="uppercase pe-2 mb-0">{English.G287}</h3>
              <button
                type="button"
                className="primary-btn text-sm font-black italic uppercase"
                onClick={() => openAddRoom()}
              >
                {/* <img src={Images.PlusIco} alt="add" /> */}
                {English.G336}
              </button>
            </div>
            <div className="room-list">
              {renderRoomList}
              {totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </div>
        )}
      </div>

      {isAddRoom && (
        <div className="add-item-model">
          {roomAddLoading && <Loader />}
          <div className="add-item-content add_room_modal !max-w-[1200px] !m-5">
            <div className="type_close mb-2 local_sticky_header">
              <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                {isEdit ? 'EDIT ROOM' : 'ADD ROOM'}
              </div>
              <button
                className="cancel_icon"
                type="button"
                onClick={handleCancels}
              >
                <img src={Images.RemoveLocal} alt="" />
              </button>
            </div>
            <div className="item-details-input pt-0 local_contact_scroll_bars">
              <div className="grid grid-cols-2 !gap-5">
                <div>
                  <div className="">
                    <div>
                      <label>{English.G337}</label>
                      <CommonInput
                        placeholder={English.G338}
                        name="name"
                        onChange={handleChange}
                        value={capitalizeFirstLetter(inputFields.name)}
                        className=""
                        allowAlphaNumericOnly
                      />
                    </div>
                    <div className="!mb-5">
                      <label>{English.A183}</label>
                      <Dropdown
                        title={getRoomName(inputFields.room_type_id)}
                        name="room_type_id"
                        onChanged={(d) => {
                          setInputFields({
                            ...inputFields,
                            room_type_id: d.id
                          })
                        }}
                        Data={isRoomType.map((d) => ({
                          title: d.type,
                          id: d.room_type_id
                        }))}
                        customClass={
                          inputFields.room_type_id
                            ? 'selected_class_local_room mb-0'
                            : 'local_dropdown_room mb-0'
                        }
                      />
                    </div>
                    <div className="!mb-5">
                      <label>{English.A182}</label>
                      <Dropdown
                        title={getBedName(inputFields.bed_option_id)}
                        name="room_type_id"
                        onChanged={(d) => {
                          setInputFields({
                            ...inputFields,
                            bed_option_id: d.id
                          })
                        }}
                        Data={isBedType.map((d) => ({
                          title: d.option,
                          id: d.bed_option_id
                        }))}
                        customClass={
                          inputFields.bed_option_id
                            ? 'selected_class_local_room mb-0'
                            : 'local_dropdown_room mb-0'
                        }
                      />
                    </div>
                  </div>
                  <div className="">
                    <div>
                      <label>{English.G339}</label>
                      <CommonInput
                        placeholder={English.G340}
                        name="person"
                        onChange={handleChange}
                        pattern="/^-?\d+\.?\d*$/"
                        maxLength={1}
                        onKeyPress={(e) => {
                          if (e.target.value.length === 1) {
                            e.preventDefault()
                          }
                        }}
                        value={inputFields.other_details.person}
                        className=""
                        allowNumberOnly
                      />
                    </div>
                    <div>
                      <label>{English.G341}</label>
                      <CommonInput
                        placeholder={English.G343}
                        name="bathroom"
                        onChange={handleChange}
                        pattern="/^-?\d+\.?\d*$/"
                        maxLength={1}
                        onKeyPress={(e) => {
                          if (e.target.value.length === 1) {
                            e.preventDefault()
                          }
                        }}
                        value={inputFields.other_details.bathroom}
                        className=""
                        allowNumberOnly
                      />
                    </div>
                  </div>
                  <div className="">
                    <div>
                      <label>{English.G342}</label>
                      <CommonInput
                        placeholder={English.G344}
                        name="sofa"
                        onChange={handleChange}
                        pattern="/^-?\d+\.?\d*$/"
                        maxLength={1}
                        onKeyPress={(e) => {
                          if (e.target.value.length === 1) {
                            e.preventDefault()
                          }
                        }}
                        value={inputFields.other_details.sofa}
                        className=""
                        allowNumberOnly
                      />
                    </div>
                    <div>
                      <label>{English.G345}</label>
                      <CommonInput
                        placeholder={English.G346}
                        name="tv"
                        onChange={handleChange}
                        value={inputFields.other_details.tv}
                        className=""
                        pattern="/^-?\d+\.?\d*$/"
                        maxLength={2}
                        onKeyPress={(e) => {
                          if (e.target.value.length === 2) {
                            e.preventDefault()
                          }
                        }}
                        allowNumberOnly
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="!mb-5">
                      <label>{English.G131}</label>
                      <CommonInput
                        placeholder={English.G347}
                        name="price"
                        type="number"
                        pattern="/^-?\d+\.?\d*$/"
                        maxLength={4}
                        onKeyPress={(e) => {
                          if (e.target.value.length === 4) {
                            e.preventDefault()
                          }
                        }}
                        onChange={handleChange}
                        value={inputFields.price}
                        className="mb-0"
                      />
                    </div>
                    <div>
                      <label>{English.A184}</label>
                      <CommonInput
                        placeholder={English.A203}
                        name="size_in_sq_ft"
                        type="number"
                        pattern="/^-?\d+\.?\d*$/"
                        maxLength={4}
                        onKeyPress={(e) => {
                          if (e.target.value.length === 4) {
                            e.preventDefault()
                          }
                        }}
                        onChange={handleChange}
                        value={inputFields.size_in_sq_ft}
                        className="mb-0"
                      />
                    </div>
                  </div>
                </div>
                <div className="hotel_image_upload_section">
                  <div className="hotel_upload_div mt-0 bg-[#FAFAFA] h-[calc(80vh-219px)] overflow-y-auto sticky top-0 flex items-center justify-center">
                    {inputFields.images.length < 1 ? (
                      <CommonImageUpload
                        handleImageChange={(file) => onUploadImage(
                            file,
                            'images',
                            inputFields.images.length
                          )}
                        buttonText="Add Image"
                        selectedImg=""
                        defaultImg={Images.plusGiveaway}
                        className="room_add_img"
                        forImage="hotel"
                      />
                    ) : (
                      <div className="input-image-show m-0 local_images_show">
                        {renderImg}
                        {inputFields.images.length < 6 && (
                          <div className="input-img-label">
                            <CommonImageUpload
                              handleImageChange={(file) => onUploadImage(
                                  file,
                                  'images',
                                  inputFields.images.length
                                )}
                              buttonText="Add Image"
                              selectedImg=""
                              defaultImg={Images.plusGiveaway}
                              className="room_add_img plsimg"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="item-details-input pt-0">
              <div className="local_button_row local_sticky_btn">
                <CommonButton
                  label={English.G116}
                  onClick={handleCancels}
                  isPrimary={false}
                  context={English.A197}
                />
                <CommonButton
                  label={English.G117}
                  type="button"
                  isPrimary
                  disabled={!isFormComplete}
                  onClick={roomAddApiCall}
                  context={English.A197}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isRoomFeature && (
        <div className="add-item-model">
          {particularRoom.map((data, index) => (
            <div
              className="add-item-content add_room_modal !max-w-[1200px] !m-5"
              key={index}
            >
              <div className="type_close mb-4 local_sticky_header">
                <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3 pl-[9px]">
                  {data.name}
                </div>
                <button
                  className="cancel_icon"
                  type="button"
                  onClick={handleCancels}
                >
                  <img src={Images.RemoveLocal} alt="" />
                </button>
              </div>
              <div className="grid grid-cols-2 !gap-5 local_contact_scroll_bars">
                <div className="item-details-input pt-0 mt-3">
                  <div className="detail-grid">
                    <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 bg-[#E7EFFF] !mb-5">
                      <p className="room_show_label">{English.G348}</p>
                      <label>{data.room_type}</label>
                    </div>
                    <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 !mb-5">
                      <p className="room_show_label">{English.A182}</p>
                      <label>{data.bed_option}</label>
                    </div>
                    <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 !mb-5">
                      <p className="room_show_label">{English.G131}</p>
                      <label>
                        {English.G283}
                        {data.price}
                        {English.G273}
                      </label>
                    </div>
                  </div>
                  <div className="detail-grid">
                    <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 !mb-5">
                      <p className="room_show_label">{English.G339}</p>
                      <label>{data.other_details.person}</label>
                    </div>
                    <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 !mb-5">
                      <p className="room_show_label">{English.G341}</p>
                      <label>{data.other_details.bathroom}</label>
                    </div>
                  </div>
                  <div className="detail-grid">
                    <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 !mb-5">
                      <p className="room_show_label">{English.G342}</p>
                      <label>{data.other_details.sofa}</label>
                    </div>
                    <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 !mb-5">
                      <p className="room_show_label">{English.G345}</p>
                      <label>
                        {data.other_details.tv}
                        {' '}
                        {English.G349}
                      </label>
                    </div>
                    <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 !mb-5">
                      <p className="room_show_label">{English.A185}</p>
                      <label>
                        {data.size_in_sq_ft}
                        {' '}
                        {English.A186}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="!h-[360px]">
                  <div className={`gap-[15px] grid ${data?.images?.length === 1 ? 'grid-cols-1 h-[340px]' : data?.images?.length === 2 ? 'grid-cols-2' : 'grid-cols-3 h-fit'}`}>
                    {data.images.map((d, i) => {
                      return (
                        <div className={`${data?.images?.length === 1 ? 'h-[360px] !object-none' : 'aspect-square'} `} key={i}>
                          <img
                            className="rounded-[15px] w-full h-full object_position object-center"
                            src={s3ImageHotel + d}
                            alt="roomImage"
                            key={i}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="item-details-input pt-0">
                <div className="local_button_row local_sticky_btn">
                  <CommonButton
                    label={English.A196}
                    onClick={() => {
                      setUpdatingIndex(index)
                      ShowConfirm(data.room_id)
                      setIsRoomFeature(false)
                    }}
                    isPrimary={false}
                    context={English.A197}
                  />
                  <CommonButton
                    label={English.G81}
                    type="button"
                    isPrimary
                    onClick={() => clickOnParticularEdit(data)}
                    context={English.A197}
                  />
                </div>
              </div>
              {/* <div className="local_button_row">
                <CommonButton
                  label={English.G359}
                  onClick={handleCancel}
                  isPrimary={false}
                  context={English.G232}
                  className="common-button"
                />
              </div> */}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
export default RoomAdd

import React, { useEffect, useState } from 'react'
import './Store.css'
import ReactDatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import { English, Images } from '../../helpers'
import CommonButton from '../ButtonComponent/ButtonCommon'
import OrderChart from '../Local/chart'
// import StoreProductAllView from './StoreProductAllView'
import ReviewsSection from '../Local/EventComponent/RatingCards'
import { emitter, EventType } from '../../helpers/Emitter'
import ParticularProductShow from './particularProductShow'
import ProductList from './productList'
import StoreAddonsScreen from './storeAddonsScreen'
import Dropdown from '../Dropdown/Dropdown'

const StoreNew = () => {
  const storeInfo = useSelector((state) => state.user?.storeInfo)
  const [startDate, setStartDate] = useState(new Date('2025/10/01'))
  const [endDate, setEndDate] = useState(new Date('2025/11/01'))
  const [newStore, setNewStore] = useState(true)
  const [productData, setProductData] = useState()
  const [productListData, setProductListData] = useState()
  const [productListDataTrue, setProductListTrue] = useState(false)

  const hasStoreAddOn = storeInfo?.some((item) => item?.add_ons_data?.some((addOn) => addOn.name === 'Store'))

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  useEffect(() => {
    const update = emitter.addListener(
      EventType.productShow,
      ({ product_id }) => {
        setNewStore(false)
        setProductData(product_id)
      }
    )
    const updated = emitter.addListener(
      EventType.productListShow,
      ({ product_id }) => {
        setNewStore(false)
        setProductListTrue(true)
        setProductListData(product_id)
      }
    )

    return () => {
      update.remove()
      updated.remove()
    }
  }, [])

  const onBackClick = () => {
    setNewStore(true)
    setProductData()
    setProductListData()
  }

  return (
    <div className="content-manager">
      {hasStoreAddOn && newStore && (
        <div className="feed-container d-block">
          {/* <div className="details-tab-view common-card-body p-20"> */}
          <div className="common-card-body p-20 !shadow-none">
            <div className="">
              <div className="d-flex align-items-center justify-content-between flex-wrap !mb-5">
                <div className="text-Main text-[36px] italic font-black mb-0 uppercase">
                  {English.G266}
                </div>
                <div className="tool-option d-flex align-items-center drop-search-3-0">
                  <Dropdown
                    title="All Platforms"
                    Data={[
                      { img: Images.CreatorTalentsDrop }
                    ]}
                    customClass=""
                  />
                  <CommonButton
                    className="flex gap-2 !bg-PrimaryBg !border-BorderLight"
                    type="button"
                    label={English.A248}
                    imgSrc={Images.Marketplace}
                    spanClassName="uppercase"
                    isPrimary={false}
                    context={English.A197}
                  />
                </div>
              </div>
              <div className="grid grid-cols-4 !gap-4 max-[1400px]:grid-cols-2">
                <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
                  <div className="w-[50px] h-[50px]">
                    <img src={Images.Visitor} alt="visitor" />
                  </div>
                  <div className="">
                    <p className="text-Main text-base italic font-black mb-[5px] uppercase">
                      {English.G299}
                    </p>
                    <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                      25
                    </span>
                  </div>
                </div>
                <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
                  <div className="">
                    <img src={Images.impressionLocal} alt="impression" />
                  </div>
                  <div className="">
                    <p className="text-Main text-base italic font-black mb-[5px] uppercase">
                      {English.A247}
                    </p>
                    <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                      2.5k
                      <img src={Images.UpPath} alt="" />
                      <span className="text-[#00B69B] text-sm font-semibold">
                        1.3%
                      </span>
                    </span>
                  </div>
                </div>
                <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
                  <div className="">
                    <img src={Images.ClicksLocal} alt="Clicks" />
                  </div>
                  <div className="">
                    <p className="text-Main text-base italic font-black mb-[5px] uppercase">
                      {English.G301}
                    </p>
                    <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                      358
                      <img src={Images.UpPath} alt="" />
                      <span className="text-[#00B69B] text-sm font-semibold">
                        1.3%
                      </span>
                    </span>
                  </div>
                </div>
                <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
                  <div className="">
                    <img src={Images.RequestsLocal} alt="request" />
                  </div>
                  <div className="">
                    <p className="text-Main text-base italic font-black mb-[5px] uppercase">
                      {English.G302}
                    </p>
                    <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                      86.04%
                      <img src={Images.UpPath} alt="" />
                      <span className="text-[#00B69B] text-sm font-semibold">
                        1.3%
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="feed-container mt-40 d-block">
              <div className="content-manager-Statistics mb-40">
                <div className="position-relative !px-5 !pt-5">
                  <Dropdown
                    title="Impressions"
                    Data={[
                      { title: 'Impressions' }
                    ]}
                    customClass="!rounded-[100px] !border-BorderLight !bg-PrimaryBg !w-[165px] !py-3"
                  />
                  <div className="absolute top-6 right-[210px] flex items-center gap-2.5 h-9">
                    <div className="h-1 bg-[#ECE9F1] w-[50px] rounded-[999px]" />
                    <div className="text-[10px] font-semibold text-[#D0D1D2]">Previous Month</div>
                  </div>
                  <div className="absolute top-6 right-6">
                    <div className="month-range">
                      <ReactDatePicker
                        selected={startDate}
                        onChange={handleChange}
                        selectsRange
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="MMM/yyyy"
                        showMonthYearPicker
                      />
                    </div>
                  </div>
                  <OrderChart />
                </div>
              </div>
              <div>
                <ProductList />
                {/* {' '}
                <StoreProductAllView /> */}
              </div>
              <div>
                <ReviewsSection />
              </div>
            </div>
          </div>
        </div>
      )}
      {!hasStoreAddOn && <StoreAddonsScreen />}
      {productListData && (
        <ProductList
          ProductDetails={productListData}
          onBack={onBackClick}
          productListDataTrue={productListDataTrue}
          productData={productData}
        />
      )}
      {productData && (
        <ParticularProductShow
          ProductDetails={productData}
          onBack={onBackClick}
        />
      )}
    </div>
  )
}
export default StoreNew

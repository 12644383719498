import React from 'react'
import { Link } from 'react-router-dom'
import { Images } from '../../../helpers'

const reviews = [
  {
    name: 'Kathryn Murphy',
    date: 'May 2021',
    rating: 4.5,
    review:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus faucibus id platea egestas. Eget vitae at aliquam facilisis. Montes, quam porttit sed fringilla dignissim aliquet.',
    image: Images.Friend_image3
  },
  {
    name: 'Kathryn Murphy',
    date: 'May 2021',
    rating: 4.5,
    review:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus faucibus id platea egestas. Eget vitae at aliquam facilisis. Montes, quam porttit sed fringilla dignissim aliquet.',
    image: Images.Friend_image3
  },
  {
    name: 'Kathryn Murphy',
    date: 'May 2021',
    rating: 4.5,
    review:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus faucibus id platea egestas. Eget vitae at aliquam facilisis. Montes, quam porttit sed fringilla dignissim aliquet.',
    image: Images.Friend_image3
  }
]

const ReviewCard = ({ name, date, rating, review, image }) => {
  return (
    <div className="border rounded-lg p-[20px] shadow-md !h-[219px] !w-[375px]">
      <div className="flex items-center mb-1">
        <img src={image} alt={name} className="w-10 h-10 rounded-full mr-3" />
        <div>
          <h3 className="font-medium text-[16px] text-[#303030]">{name}</h3>
          <p className="text-sm text-gray-500">{date}</p>
        </div>
      </div>
      <div className="flex items-center mb-2">
        <div className="text-yellow-500 text-xl">⭐⭐⭐⭐⭐</div>
        <span className="ml-2 font-semibold">{rating}</span>
      </div>
      <p className="text-[14px] text-[#303030] font-normal line-clamp-4 ...">{review}</p>
    </div>
  )
}

const ReviewsSection = () => {
  return (
    <div className="py-6">
      <div className="flex justify-between items-center !mb-4">
        <h2 className="text-[#151515] italic font-black text-[18px] uppercase">LATEST REVIEWS</h2>
      </div>
      <div className="flex space-x-4 overflow-hidden">
        {reviews.map((review, index) => (
          <ReviewCard key={index} {...review} />
        ))}
      </div>
      <div className="text-center pt-2.5">
        <Link to="" className="bg-primaryGradient bg-clip-text text-transparent font-bold text-lg">See All</Link>
      </div>
    </div>
  )
}

export default ReviewsSection

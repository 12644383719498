/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { X } from 'lucide-react'
import Dropdown from '../Dropdown/Dropdown'
import {
  s3ImageProfile
} from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
import Loader from '../Loader/Loader'
import Pagination from '../Pagination/Pagination'
import {
  formatDateTimeLeft,
  lockScroll,
  unlockScroll
} from '../../helpers/Utility'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommonInput from '../InputComponet/CommonInput'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import ContentMangerCommonTitle from './SubComponent/ContentMangerCommon.js/ContentMangerCommonTitle'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import GiveawayAddModal from './Modal/GiveawayAddModal'
import GiveawayItem from './SubComponent/GiveawayItem'

const GiveawayTab = () => {
  const userData = useSelector((state) => state.user?.userData)
  const app_id = useSelector((state) => state.user?.app_id)

  const navigate = useNavigate()

  const [ShowCommunityModel, setShowCommunityModel] = useState(false)
  const [inputFields, setInputFields] = useState({
    app_id: [],
    file: [],
    title: '',
    time_left: '',
    brand_id: 0,
    type_id: 0,
    email: '',
    phone: '',
    address: '',
    tag_friends: [],
    description: ''
  })
  const [loading, setLoading] = useState(false)
  const [giveawayProduct, setGiveawayProduct] = useState([])
  const [delGiveawayId, setDelGiveawayId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [giveawayList, setGiveawayList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [commentSection, setCommentSection] = useState(false)
  const [comments, setComments] = useState([])
  const [newComment, setNewComment] = useState('')
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [currentCommentPage, setCurrentCommentPage] = useState(1)
  const [selectedId, setSelectedId] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)
  const [editGiveawayDate, setEditGiveawayData] = useState(null)
  const [giveawayCurrentPage, setGiveawayCurrentPage] = useState(1)
  const [giveawayTotalPages, setGiveawayTotalPages] = useState(1)
  const [searchText, setSearchText] = useState('')

  const commentClear = () => {
    setComments([])
    setSelectedId([])
    setNewComment('')
    setShowReplied('')
    setEditedCommentText('')
    setEditedReplyText('')
  }

  // const replaceImage = (index) => {
  //   const input = document.createElement('input')
  //   input.type = 'file'

  //   const handleChange = (e) => {
  //     handleImageChange(e, index)
  //     input.removeEventListener('change', handleChange)
  //   }

  //   if (input) {
  //     input.value = ''
  //     input.removeEventListener('change', handleChange)
  //     input.click()
  //     input.addEventListener('change', handleChange, { once: true })
  //     input.remove()
  //   }
  // }

  // eslint-disable-next-line no-unused-vars
  const ShowCommunityGiveaway = () => {
    setShowCommunityModel(true)
  }

  const handleCancel = () => {
    setShowConfirmModel(false)
  }

  const handleCancelAddModal = () => {
    setShowCommunityModel(false)
  }
  const ShowConfirm = (giveawayId) => {
    setDelGiveawayId(giveawayId)
    setShowConfirmModel(true)
  }

  const clearSearch = () => {
    setSearchText('')
    handleSearch2('')
  }

  const handleSearch = (value) => {
    setSearchText(value)
    handleSearch2(value)
  }

  const getGiveawayLibraryData = (search, giveawayCurrentPage) => {
    setIsLoading(true)
    const data = {
      search
    }
    APICall('post', data || {}, `${EndPoints.getGiveawayLibrary}?page=${giveawayCurrentPage}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setGiveawayProduct(res?.decryptedData.data)
          setGiveawayTotalPages(res?.decryptedData?.last_page)
          setGiveawayCurrentPage(res?.decryptedData?.current_page)
          const { data } = dataDecrypt(res.data.data)
          if (data.length !== 0) {
            // data.forEach((giveaway) => {
            //   giveawayParticipate(giveaway.giveaway_id)
            // })
            // giveawayParticipateList(data[0].giveaway_id)
          }
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const deleteGiveaway = () => {
    const data = {
      giveaway_id: delGiveawayId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.deleteGiveaway}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            getGiveawayLibraryData(null, giveawayCurrentPage)
          }, 3000)
          setShowConfirmModel(false)
          setLoading(false)
          toast.success(res?.data?.message)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const handleSearch2 = useCallback(
      debounce((inputVal) => getGiveawayLibraryData(inputVal, giveawayCurrentPage), 500),
      []
    )

  const giveawayParticipateList = (giveaway_id) => {
    const platFormData = {
      giveaway_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayParticipateList}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setGiveawayList(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const onClickEditGiveaway = (videoData) => {
    setShowCommunityModel(true)
    setEditGiveawayData(videoData)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      time_left: videoData.time_left,
      brand_id: videoData.brand_id,
      type_id: videoData.type_id,
      email: videoData.email,
      phone: videoData.phone,
      address: videoData.address,
      tag_friends: videoData.tag_friends,
      description: videoData.description,
      file: videoData.images,
      app_id: videoData.app_data,
      giveaway_id: videoData.giveaway_id
    })
  }

  const handleItemClick = (videoData) => {
    // setParticularGiveaway(true)
    // setParticularGiveawayData(videoData)
    navigate('/giveaway-detail', { state: videoData })
  }

  // giveaway participate list pagination

  const videosPerPage = 5
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const giveawayListData = giveawayList.slice(
    indexOfFirstVideo,
    indexOfLastVideo
  )
  const totalVideos = giveawayList.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  // giveaway comment

  const onClickComment = (giveaway_id) => {
    setSelectedId(giveaway_id)
    if (!isLoadingComments) {
      setIsLoadingComments(true)
      giveawayComments(giveaway_id)
      setCommentSection(true)
    }
  }

  const fetchPollComments = (giveaway_id, delay = 3000) => {
    setTimeout(() => {
      giveawayComments(giveaway_id)
    }, delay)
  }

  const giveawayComments = (giveaway_id) => {
    const platFormData = {
      app_id: app_id?.id,
      giveaway_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayComment}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const dataComment = res?.decryptedData?.data?.map((d, i) => ({
            id: i + 1,
            text: d.comment,
            poll_id: d.poll_id,
            is_like: d.is_like,
            comment_id: d.comment_id,
            total_like: d.total_like,
            replies: d.reply_data.map((subData, subIndex) => ({
              id: subIndex + 1,
              text: subData.comment,
              reply_id: subData.comment_id,
              total_like: subData.total_like,
              is_like: subData.is_like,
              user: {
                name: subData.user_name,
                profilePic: s3ImageProfile + subData.profile_icon
              }
            })),
            user: {
              name: d.user_name,
              profilePic: s3ImageProfile + d.profile_icon
            }
          }))
          setComments(dataComment)
          setIsLoadingComments(false)
        } else {
          setIsLoadingComments(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsLoadingComments(false)
        toast.error(error?.data?.message)
      })
  }

  const pollAddComment = (comment) => {
    const platFormData = {
      giveaway_id: selectedId,
      comment
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollAddReply = (reply_id, replyText) => {
    const platFormData = {
      giveaway_id: selectedId,
      comment_id: reply_id,
      comment: replyText
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }
  const pollEditComment = (reply_id, comment) => {
    const platFormData = {
      comment_id: reply_id,
      comment
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollEditReply = (reply_id, replyId) => {
    const platFormData = {
      comment_id: reply_id,
      comment: replyId
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const deletePollComment = (reply_id) => {
    setIsDeleting(true)
    const data = {
      comment_id: reply_id
    }
    APICall(
      'post',
      data,
      `${EndPoints.giveawayCommentDelete}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }
  const deletePollReply = (replyId) => {
    setIsDeleting(true)
    const data = {
      comment_id: replyId
    }
    APICall(
      'post',
      data,
      `${EndPoints.giveawayCommentDelete}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const pollCommentLike = (reply_id) => {
    const platFormData = {
      app_id: app_id?.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments(
            comments.map((item) => (item.comment_id === reply_id
                ? { ...item, is_like: !item.is_like }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollReplyLike = (reply_id) => {
    const platFormData = {
      app_id: app_id?.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments(
            comments.map((comment) => ({
              ...comment,
              replies: comment.replies.map((reply) => (reply.reply_id === reply_id
                  ? {
                      ...reply,
                      is_like: !reply.is_like,
                      total_like: reply.is_like
                        ? reply.total_like - 1
                        : reply.total_like + 1
                    }
                  : reply))
            }))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleAddComment = (reply, profilePic, name) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        poll_id: selectedId,
        total_like: 0,
        replies: [],
        user: {
          name,
          profilePic
        }
      }
      setComments([...comments, newCommentObj])
      setNewComment('')
    }
    pollAddComment(reply)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    pollCommentLike(commentId)
  }

  const handleLikeReply = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    pollReplyLike(commentId)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [
            ...comment.replies,
            {
              id: comment.replies.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: userData.user_name,
                profilePic: s3ImageProfile + userData.profile_image
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    pollAddReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: comment.replies.filter((reply) => reply.id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    deletePollReply(replyId)
  }

  const toggleReplies = (commentId, forceCloseAll = false) => {
    if (forceCloseAll) {
      setShowReplies({})
    } else {
      setShowReplies((prevState) => ({
        // ...prevState,
        [commentId]: !prevState[commentId]
      }))
    }
    setShowReplied('')
  }

  const handleCommentCancel = () => {
    getGiveawayLibraryData(null, giveawayCurrentPage)
    setCommentSection(false)
    commentClear()
    toggleReplies(null, true)
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? { ...comment, text: editedCommentText }
        : comment))
    pollEditComment(commentId, reply)
    setComments(updatedComments)
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? {
            ...comment,
            replies: comment.replies.map((reply) => (reply.id === replyId ? { ...reply, text: editedReplyText } : reply))
          }
        : comment))
    pollEditReply(commentId, replyId)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  const commentPerPage = 5
  const indexOfLastComment = currentCommentPage * commentPerPage
  const indexOfFirstComment = indexOfLastComment - commentPerPage
  const commentsList = comments.slice(indexOfFirstComment, indexOfLastComment)
  const totalComment = comments.length
  const totalCommentPages = Math.ceil(totalComment / commentPerPage)

  const handlePageCommentChange = (pageNumber) => {
    setCurrentCommentPage(pageNumber)
  }

  useEffect(() => {
    if (currentCommentPage > totalCommentPages) {
      setCurrentCommentPage(totalCommentPages > 0 ? totalCommentPages : 1)
    }
  }, [totalCommentPages, currentCommentPage])

  useEffect(() => {
    getGiveawayLibraryData(null, giveawayCurrentPage)
  }, [])

  const enterGiveaway = (giveaway_id, index) => {
    // const data = {
    //   giveaway_id
    // }
    const temp = [...giveawayProduct]
    temp[index].is_participate = true
    setGiveawayProduct(temp)
    giveawayParticipateList(giveaway_id).then((res) => {
      if (res.data.code === 200) {
        toast.success(res?.data?.message)
      }
    })
  }

  const giveawayHandlePageChange = (pageNumber) => {
    getGiveawayLibraryData(null, pageNumber)
    setGiveawayCurrentPage(pageNumber)
  }

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    if (
      ShowCommunityModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [
    ShowCommunityModel
  ])

  return (
    <div className="content-manager-giveaways-wrapper">
      <div>
        <div className="sticky_bar !pb-[2px] z-10 bg-white">
          <ContentMangerCommonTitle
            title={English.G230}
            // buttonName={displayedGiveaways?.length === 0 ? null : English.B112}
            buttonName={English.B112}
            onButtonClick={ShowCommunityGiveaway}
            className="!mb-0"
          />

          <div className="add-video-row">
            <div className="items-filter justify-between w-full">
              <div className="position-relative input-position">
                <CommonInput
                  placeholder="Search Giveaway"
                  className="!text-black"
                  onChange={(e) => {
                      handleSearch(e.target.value)
                    }}
                  value={searchText}
                />
                {searchText && (
                <button
                  type="button"
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-black"
                  onClick={clearSearch}
                >
                  <X className="w-5 h-5" />
                </button>
                  )}
              </div>
              <div className="flex flex-row gap-2">
                <div className="drop-row !mb-0 gap-4">
                  <div className="drop-sub-row">
                    <Dropdown
                      title="Categories"
                      Data={[
                        { title: 'Scaloo' },
                        { title: 'Spacure' },
                        { title: 'PETZINO' },
                        { title: 'A-Z FIT' },
                        { title: 'TESTIEE' }
                      ]}
                      customClass="content-filter disabledDropdown"
                    />
                    <Dropdown
                      title="Ratings"
                      Data={[
                        { title: 'Scaloo' },
                        { title: 'Spacure' },
                        { title: 'PETZINO' },
                        { title: 'A-Z FIT' },
                        { title: 'TESTIEE' }
                      ]}
                      customClass="content-filter disabledDropdown"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {giveawayProduct.length === 0 && !isLoading && (
        <CommonEmptyData
          image={Images.giveaway_icon}
          mainTitle={English.B113}
          descriptionText={English.B114}
          onClick={() => ShowCommunityGiveaway()}
          addIcon={Images.plusrounded}
          btnText={English.B115}
          className="!border-solid"
          classNameDescription="font-normal text-[#7A7474] text-[16px]"
        />
          )}

        {isLoading ? (
          <div className="add-giveways-item mb-30">
            <div className="add-giveways-cover">
              <button type="button" className="bg-[#ffff] w-[30px] h-[30px] rounded-full">
                <img className="!mx-auto" src={Images.Plus} alt="add giveaway" />
              </button>
            </div>
            <div className="add-giveways-details">
              <h4 className="add-value">
                <Skeleton className="add-value" width={155} height={29} />
              </h4>
              <div className="d-flex align-items-center justify-content-between">
                <ul className="d-flex align-items-center">
                  <li className="add-value">
                    <Skeleton className="add-value" width={110} height={29} />
                  </li>
                  <li className="add-value">
                    <Skeleton className="add-value" width={110} height={29} />
                  </li>
                </ul>
                <span className="add-value">
                  <Skeleton className="add-value" width={121} height={29} />
                </span>
              </div>
              <p className="add-value">
                <Skeleton className="add-value" width={580} height={110} />
              </p>
              <h5 className="add-value">
                <Skeleton className="add-value" width={149} height={48} />
              </h5>
            </div>
          </div>
          ) : (
            <div className="add-giveways-container">
              {giveawayProduct.map((item, index) => {
                const timeLeftText = formatDateTimeLeft(item.expire_date)
                const isTimeUp = timeLeftText === 'Time is up'
                return (
                  <>
                    <GiveawayItem
                      key={index}
                      item={item}
                      index={index}
                      text="contentManager"
                      editBtn
                      onClick={(e) => {
                        onClickEditGiveaway(item)
                      }}
                      itemClick={() => handleItemClick(item)}
                      onClickDelete={() => {
                        setUpdatingIndex(index)
                        ShowConfirm(item.giveaway_id)
                      }}
                    />
                    <ConfirmationModal
                      isVisible={ShowConfirmModel && updatingIndex === index}
                      onConfirm={deleteGiveaway}
                      onCancel={handleCancel}
                      isLoading={loading}
                    />
                  </>
                )
              })}
              {giveawayTotalPages > 1 && (
                <Pagination
                  currentPage={giveawayCurrentPage}
                  totalPages={giveawayTotalPages}
                  onPageChange={giveawayHandlePageChange}
                />
              )}
            </div>
          )}
      </div>
      {commentSection && (
        <div className="add-item-modal mh-100">
          <div className="add-item-contents comment-container">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="mb-0">{English.G177}</h4>
              <button
                type="button"
                className="secondary-btn-modal"
                onClick={handleCommentCancel}
              >
                <img
                  className="close-btns"
                  src={Images.CloseIco}
                  alt="clock icon"
                />
              </button>
            </div>
            <div className="comment-section">
              {isLoadingComments ? (
                <div>
                  <div className="comments-container">
                    <ul className="comments-list">
                      <li className="comment-item">
                        <button type="button" className="comment-delete">
                          <Skeleton
                            className="rounded-3"
                            width={20}
                            height={20}
                          />
                        </button>
                        <div className="comment-header mb-0 gap-2">
                          <Skeleton circle width={45} height={45} />
                          <span className="user-name">
                            <Skeleton width={150} height={20} />
                          </span>
                        </div>
                        <div className="comment-btns">
                          <button type="button">
                            <Skeleton width={20} height={20} />
                          </button>
                          <Skeleton width={20} height={20} />
                          <button type="button">
                            <Skeleton width={45} height={20} />
                          </button>
                          <button type="button">
                            <Skeleton width={45} height={20} />
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="add-comment">
                    <input
                      type="text"
                      placeholder={English.G151}
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      name="reply"
                    />
                    <button
                      type="button"
                      onClick={() => handleAddComment(newComment)}
                    >
                      {English.G152}
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="comments-container">
                    {commentsList?.length === 0 ? (
                      <div className="no-comments-message">{English.G148}</div>
                    ) : (
                      <ul className="comments-list">
                        {commentsList.map((comment, index) => (
                          <li key={comment.id} className="comment-item">
                            {isDeleting && (
                              <div className="loader-overlay">
                                {commentIndex === index && <Loader />}
                              </div>
                            )}
                            {comment.user.name === userData.user_name && (
                              <button
                                type="button"
                                className="comment-delete"
                                onClick={() => {
                                  setCommentIndex(index)
                                  deletePollComment(comment.comment_id)
                                }}
                              >
                                <img src={Images.Delete} alt="delete icon" />
                              </button>
                            )}
                            <div className="comment-header mb-0">
                              <img
                                src={comment.user.profilePic}
                                alt={`${comment.user.name}'s profile`}
                                className="profile-pic"
                              />
                              <span className="user-name">
                                {comment.user.name}
                              </span>
                            </div>
                            {editCommentId === comment.id ? (
                              <div className="edit-reply">
                                <input
                                  type="text"
                                  value={editedCommentText}
                                  onChange={(e) => setEditedCommentText(e.target.value)}
                                />
                                <button
                                  type="button"
                                  onClick={() => handleSaveComment(
                                      comment.comment_id,
                                      editedCommentText
                                    )}
                                >
                                  {English.G117}
                                </button>
                              </div>
                            ) : (
                              <div className="comment-text">{comment.text}</div>
                            )}
                            <div className="comment-btns">
                              <button
                                type="button"
                                onClick={() => handleLikeComment(comment.comment_id)}
                              >
                                {comment.is_like ? (
                                  <img src={Images.Likes} alt="Unlike" />
                                ) : (
                                  <img src={Images.UnLikes} alt="Like" />
                                )}
                                {' '}
                                {comment.total_like}
                              </button>
                              {comment.user.name === userData.user_name && (
                                <button
                                  type="button"
                                  onClick={() => handleEditComment(comment.id, comment.text)}
                                >
                                  {English.G81}
                                </button>
                              )}
                              <button
                                type="button"
                                onClick={() => toggleReplies(comment.id)}
                              >
                                {showReplies[comment.id]
                                  ? 'Hide Replies'
                                  : `Show Replies (${comment?.replies?.length})`}
                              </button>
                            </div>
                            {showReplies[comment.id] && (
                              <ul className="replies-list">
                                {comment.replies.map((reply) => (
                                  <li key={reply.id} className="reply-item">
                                    <div className="reply-header mb-0">
                                      <img
                                        src={reply.user.profilePic}
                                        alt={`${reply.user.name}'s profile`}
                                        className="profile-pic"
                                      />
                                      <span className="user-name">
                                        {reply.user.name}
                                      </span>
                                      {reply.user.name ===
                                        userData.user_name && (
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setCommentIndex(index)
                                            handleDeleteReply(
                                              comment.reply_id,
                                              reply.reply_id
                                            )
                                          }}
                                          className="comment-delete"
                                        >
                                          <img
                                            src={Images.Delete}
                                            alt="delete icon"
                                          />
                                        </button>
                                      )}
                                    </div>
                                    {editReplyId === reply.id ? (
                                      <div className="edit-reply">
                                        <input
                                          type="text"
                                          value={editedReplyText}
                                          onChange={(e) => setEditedReplyText(e.target.value)}
                                        />
                                        <button
                                          type="button"
                                          onClick={() => handleSaveReply(
                                              reply.reply_id,
                                              editedReplyText
                                            )}
                                        >
                                          {English.G117}
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="reply-text">
                                        {reply.text}
                                      </div>
                                    )}
                                    <div className="comment-btns">
                                      <button
                                        type="button"
                                        onClick={() => handleLikeReply(reply.reply_id)}
                                      >
                                        {reply.is_like ? (
                                          <img
                                            src={Images.Likes}
                                            alt="Unlike"
                                          />
                                        ) : (
                                          <img
                                            src={Images.UnLikes}
                                            alt="Like"
                                          />
                                        )}
                                        {' '}
                                        {reply.total_like}
                                      </button>
                                      {reply.user.name ===
                                        userData.user_name && (
                                        <button
                                          type="button"
                                          onClick={() => handleEditReply(
                                              reply.id,
                                              reply.text
                                            )}
                                        >
                                          {English.G81}
                                        </button>
                                      )}
                                    </div>
                                  </li>
                                ))}
                                <li className="add-reply">
                                  <input
                                    type="text"
                                    placeholder={English.G149}
                                    value={Replied}
                                    onChange={(e) => setShowReplied(e.target.value)}
                                  />
                                  <button
                                    type="button"
                                    onClick={() => handleAddReply(
                                        comment.comment_id,
                                        Replied
                                      )}
                                  >
                                    {English.G150}
                                  </button>
                                </li>
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={currentCommentPage}
                      totalPages={totalCommentPages}
                      onPageChange={handlePageCommentChange}
                    />
                  )}
                  <div className="add-comment">
                    <input
                      type="text"
                      placeholder={English.G151}
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      name="reply"
                    />
                    {commentsList?.length === 0 && (
                      <button
                        type="button"
                        className="comment_add_btn"
                        onClick={() => handleAddComment(newComment, '', '')}
                      >
                        {English.G152}
                      </button>
                    )}
                    {commentsList?.map((comment, i) => (
                      <button
                        key={i}
                        type="button"
                        onClick={() => handleAddComment(
                            newComment,
                            comment.user.profilePic,
                            comment.user.name
                          )}
                      >
                        {English.G152}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {ShowCommunityModel && (
        <GiveawayAddModal
          handleCancel={handleCancelAddModal}
          editData={editGiveawayDate}
          onSubmit={(giveaway_id) => {
            // if (video_id) {
            //   const video = getParticularVideosData(video_id)
            //   const temp = [...videoData]
            //   videoData.forEach((item, index) => {
            //     if (item.video_id === video_id) {
            //       temp[index] = video
            //     }
            //   })
            //   setVideoData(temp)
            // } else {
            getGiveawayLibraryData(null, giveawayCurrentPage)
            // }
          }}
        />
      )}
    </div>
  )
}
export default GiveawayTab

import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import './Settings.css'
import { Link } from 'react-router-dom'
import ContentManagerSettings from './ContentManagerSettings'
import StoreSettings from './StoreSettings'
import LocalSettings from './LocalSettings'
import TeamSettings from './TeamSettings'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const Setings = () => {
  const items = [
    {
      name: 'Profile',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh rutrum adipiscing sed venenatis, vel massa sagittis facilisi. Felis at varius urna,',
      link: ''
    },
    {
      name: 'Content Manager',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh rutrum adipiscing sed venenatis, vel massa sagittis facilisi. Felis at varius urna,',
      link: ''
    },
    {
      name: 'Store',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh rutrum adipiscing sed venenatis, vel massa sagittis facilisi. Felis at varius urna,',
      link: ''
    },
    {
      name: 'Local',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh rutrum adipiscing sed venenatis, vel massa sagittis facilisi. Felis at varius urna,',
      link: ''
    },
    {
      name: 'Team',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh rutrum adipiscing sed venenatis, vel massa sagittis facilisi. Felis at varius urna,',
      link: ''
    },
    {
      name: 'Ads',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh rutrum adipiscing sed venenatis, vel massa sagittis facilisi. Felis at varius urna,',
      link: ''
    }
  ]

  return (
    <div className="settings">
      <div className="settings-overview">
        <div className="tab-dashboard">
          <Tabs>
            <TabList>
              <Tab>
                <img alt="" src={Images.ProfileIco} />
                <span>{English.G232}</span>
              </Tab>
              <Tab>
                <img alt="" src={Images.HomeIco} />
                <span>{English.G234}</span>
              </Tab>
              <Tab>
                <img alt="" src={Images.StoreIco} />
                <span>{English.G224}</span>
              </Tab>
              <Tab>
                <img alt="" src={Images.LocalIco} />
                <span>{English.G236}</span>
              </Tab>
              <Tab>
                <img alt="" src={Images.CommunityIco} />
                <span>{English.G456}</span>
              </Tab>
              <Tab>
                <img alt="" src={Images.CommunityIco} />
                <span>{English.G457}</span>
              </Tab>
            </TabList>

            <TabPanel>
              <div className="item-wrapper">
                <h2>Settings Overview</h2>
                {items.map((item) => (
                  <div key={item.name} className="profile-overview-item">
                    <div className="profile-item-detail">
                      <h3>{item.name}</h3>
                      <p>{item.text}</p>
                    </div>
                    <Link to={item.link}>View Settings</Link>
                  </div>
                ))}
              </div>
            </TabPanel>
            <TabPanel>
              <ContentManagerSettings />
            </TabPanel>
            <TabPanel>
              <StoreSettings />
            </TabPanel>
            <TabPanel>
              <LocalSettings />
            </TabPanel>
            <TabPanel>
              <TeamSettings />
            </TabPanel>
            <TabPanel />
          </Tabs>
        </div>
      </div>
    </div>
  )
}
export default Setings

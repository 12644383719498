/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { X } from 'lucide-react'
import Dropdown from '../Dropdown/Dropdown'
import { s3ImageVideo, s3ImageProfile } from '../../services/Service'
import Loader from '../Loader/Loader'
import {
  debounce,
  formatDurations,
  formatViews,
  lockScroll,
  timeDifference,
  unlockScroll
} from '../../helpers/Utility'
import Pagination from '../Pagination/Pagination'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommentPagination from '../Pagination/CommentPagination'
import CommonInput from '../InputComponet/CommonInput'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import ContentMangerCommonTitle from './SubComponent/ContentMangerCommon.js/ContentMangerCommonTitle'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import VideoAddModal from './Modal/VideoAddModal'

const VideoTab = () => {
  const userData = useSelector((state) => state.user?.userData)
  const app_id = useSelector((state) => state.user?.app_id)
  const [delLoading, setDelLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [editVideoDate, setEditVideoData] = useState(null)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [inputFields, setInputFields] = useState({
    title: '',
    description: '',
    duration: '',
    attachment: '',
    cover_attachment: '',
    category_id: '',
    app_id: [],
    search: ''
  })
  const [showAddVideoModal, setShowAddVideoModal] = useState(false)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [videoData, setVideoData] = useState([])
  const [delVideoId, setDelVideoId] = useState()
  const [comments, setComments] = useState([])
  const [selectedId, setSelectedId] = useState('')
  const [commentSection, setCommentSection] = useState(false)
  const [newComment, setNewComment] = useState('')
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [currentPageComment, setCurrentPageComment] = useState(1)
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)
  const [category, setCategory] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [expandedIndex, setExpandedIndex] = useState(null)
  const [searchText, setSearchText] = useState('')

  const commentClear = () => {
    setComments([])
    setSelectedId([])
    setNewComment('')
    setShowReplied('')
    setEditedCommentText('')
    setEditedReplyText('')
  }

  const clearValue = () => {
    setInputFields({
      ...inputFields,
      title: '',
      description: '',
      duration: '',
      attachment: '',
      cover_attachment: '',
      app_id: '',
      video_id: ''
    })
  }

  const clearSearch = () => {
    setSearchText('')
    handleSearch2('')
  }

  const handleSearch = (value) => {
    setSearchText(value)
    handleSearch2(value)
  }
  const handleButtonClick = () => {
    clearValue()
    setShowAddVideoModal(true)
  }

  const handleCancel = () => {
    setEditVideoData(null)
    clearValue()
    setShowAddVideoModal(false)
    setShowConfirmModel(false)
  }

  const ShowConfirm = (videoId) => {
    setDelVideoId(videoId)
    setShowConfirmModel(true)
  }

  const onClickComment = (video_id) => {
    setSelectedId(video_id)
    if (!isLoadingComments) {
      setIsLoadingComments(true)
      videoComment(video_id)
      setCommentSection(true)
    }
  }

  const HandleCancel = () => {
    // getVideosLibraryData(null, currentPage)
    setCommentSection(false)
    commentClear()
    toggleReplies(null, true)
  }

  const getVideosLibraryData = (search, currentPage, category_id) => {
    setIsLoading(true)
    const data = {
      search,
      category_id: category_id || inputFields?.category_id
    }
    APICall(
      'post',
      data,
      `${EndPoints.getVideoLibrary}?page=${currentPage}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setVideoData(res?.decryptedData.data)
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const handleSearch2 = useCallback(
    debounce((inputVal) => getVideosLibraryData(inputVal, currentPage), 500),
    []
  )

  const deleteVideo = () => {
    const data = {
      video_id: delVideoId
    }
    setDelLoading(true)
    APICall('post', data, `${EndPoints.deleteVideo}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setTimeout(() => {
            getVideosLibraryData(null, currentPage)
          }, 3000)
          setDelLoading(false)
          toast.success(res?.data?.message)
        } else {
          setDelLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setDelLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const videoLikeData = (video_id) => {
    const platFormData = {
      video_id
    }
    APICall('post', platFormData, `${EndPoints.videoLike}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setVideoData(
            videoData.map((item) => (item.video_id === video_id
                ? {
                    ...item,
                    is_like: !item.is_like,
                    total_like: !item.is_like
                      ? Number(item?.total_like || 0) + 1
                      : Number(item?.total_like || 1) - 1
                  }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const onClickEditPodcast = (videoData) => {
    setEditVideoData(videoData)
    setShowAddVideoModal(true)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      description: videoData.description,
      duration: videoData.duration,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      video_id: videoData.video_id
    })
  }

  const navigate = useNavigate()
  const handleItemClick = (videoData) => {
    navigate('/video-detail', { state: { data: videoData } })
  }

  const fetchVideoComments = (video_id, delay = 3000) => {
    setTimeout(() => {
      videoComment(video_id)
    }, delay)
  }

  const videoComment = (video_id) => {
    const platFormData = {
      app_id: 2,
      video_id
    }
    APICall('post', platFormData, `${EndPoints.videoComment}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const dataComment = res?.decryptedData?.data?.map((d, i) => ({
            id: i + 1,
            text: d.comment,
            video_id: d.video_id,
            is_like: d.is_like,
            reply_id: d.comment_id,
            total_like: d.total_like,
            replies: d.reply_data.map((subData, subIndex) => ({
              id: subIndex + 1,
              text: subData.comment,
              reply_id: subData.comment_id,
              total_like: subData.total_like,
              is_like: subData.is_like,
              user: {
                name: subData.user_name,
                profilePic: s3ImageProfile + subData.profile_icon
              }
            })),
            user: {
              name: d.user_name,
              profilePic: s3ImageProfile + d.profile_icon
            }
          }))
          setComments(dataComment)
          setIsLoadingComments(false)
        } else {
          setIsLoadingComments(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsLoadingComments(false)
        toast.error(error?.data?.message)
      })
  }

  const shotAddComment = (comment) => {
    const platFormData = {
      video_id: selectedId,
      comment
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.videoCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchVideoComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotAddCommentReply = (comment_id, replyText) => {
    const platFormData = {
      video_id: selectedId,
      comment_id,
      comment: replyText
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.videoCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchVideoComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotCommentEdit = (reply_id, comment) => {
    const platFormData = {
      comment_id: reply_id,
      comment
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.videoCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchVideoComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotCommentEditReply = (comment_id, replyText) => {
    const platFormData = {
      video_id: selectedId,
      comment_id,
      comment: replyText
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.videoCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchVideoComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotCommentDelete = (reply_id) => {
    setIsDeleting(true)
    const data = {
      comment_id: reply_id
    }
    APICall('post', data, `${EndPoints.videoCommentDelete}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchVideoComments(selectedId)
          setIsDeleting(false)
          setTimeout(() => {
            toast.success(res?.data?.message)
          }, 3000)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const shotCommentDeleteReply = (commentId, replyId) => {
    setIsDeleting(true)
    const data = {
      comment_id: commentId,
      reply_id: replyId
    }
    APICall('post', data, `${EndPoints.videoCommentDelete}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchVideoComments(selectedId)
          setIsDeleting(false)
          setTimeout(() => {
            toast.success(res?.data?.message)
          }, 3000)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const shotCommentLike = (reply_id) => {
    const platFormData = {
      app_id: app_id?.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.videoCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          //
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotCommentReplyLike = (reply_id) => {
    const platFormData = {
      app_id: app_id?.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.videoCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          //
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleAddComment = (comment, profilePic, name) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        video_id: selectedId,
        total_like: 0,
        replies: [],
        user: {
          name,
          profilePic
        }
      }
      setComments([...comments, newCommentObj])
      setNewComment('')
    }
    shotAddComment(comment)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    shotCommentLike(commentId)
  }

  const handleLikeReply = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    shotCommentReplyLike(commentId)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [
            ...comment.replies,
            {
              id: comment.replies.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: userData.user_name,
                profilePic: s3ImageProfile + userData.profile_image
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    shotAddCommentReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: comment.replies.filter((reply) => reply.id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    shotCommentDeleteReply(replyId, commentId)
  }

  const toggleReplies = (commentId, forceCloseAll = false) => {
    if (forceCloseAll) {
      setShowReplies({})
    } else {
      setShowReplies((prevState) => ({
        // ...prevState,
        [commentId]: !prevState[commentId]
      }))
    }
    setShowReplied('')
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? { ...comment, text: editedCommentText }
        : comment))
    shotCommentEdit(commentId, reply)
    setComments(updatedComments)
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? {
            ...comment,
            replies: comment.replies.map((reply) => (reply.id === replyId ? { ...reply, text: editedReplyText } : reply))
          }
        : comment))
    shotCommentEditReply(commentId, replyId)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  const MainCategory = () => {
    APICall('post', {}, `${EndPoints.getCategory}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setCategory(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  useEffect(() => {
    MainCategory()
  }, [])

  // videos pagination

  const handlePageChange = (pageNumber) => {
    getVideosLibraryData(null, pageNumber)
    setCurrentPage(pageNumber)
  }

  const commentsPerPage = 5
  const indexOfLastComment = currentPageComment * commentsPerPage
  const indexOfFirstComment = indexOfLastComment - commentsPerPage
  const commentsList = comments.slice(indexOfFirstComment, indexOfLastComment)
  const totalCommentPages = Math.ceil(comments.length / commentsPerPage)

  const handlePageChanges = (pageNumber) => {
    setCurrentPageComment(pageNumber)
  }

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    if (currentPageComment > totalCommentPages) {
      setCurrentPageComment(totalCommentPages || 1)
    }
  }, [comments, currentPageComment, totalCommentPages])

  useEffect(() => {
    getVideosLibraryData(null, currentPage)
  }, [])

  useEffect(() => {
    if (commentSection || showAddVideoModal) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [commentSection, showAddVideoModal])

  return (
    <div className="content-manager-video-wrapper">
      {/* {_.map(videoProgress, (pro, videoId) => {
        if (pro === 100) {
          return null
        }
        return (
          <div className="custom-progress" key={videoId}>
            <Progress
              percent={pro}
              status="active"
              strokeColor={{
                '3.03%': '#001597',
                '47.1%': '#01104d',
                '80.14%': '#020c1c',
                '96.45%': '#020b09'
              }}
              trailColor="#d9d9d9"
              strokeWidth={10}
            />
          </div>
        )
      })} */}

      <div>
        <div className="sticky_bar !pb-[2px] z-10 bg-white">
          <ContentMangerCommonTitle
            title={English.G225}
            buttonName={videoData?.length === 0 ? null : English.G153}
            onButtonClick={videoData?.length === 0 ? null : handleButtonClick}
            className="!mb-0"
          />
          <div className="add-video-row">
            <div className="items-filter justify-between w-full">
              <div className="position-relative input-position">
                <CommonInput
                  placeholder="Search Video"
                  className="!text-black"
                  onChange={(e) => {
                  handleSearch(e.target.value)
                }}
                  value={searchText}
                />
                {searchText && (
                <button
                  type="button"
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-black"
                  onClick={clearSearch}
                >
                  <X className="w-5 h-5" />
                </button>
              )}
              </div>
              <div className="flex flex-row gap-2">
                <div className="drop-row !mb-0 gap-4">
                  <div className="drop-sub-row">
                    <Dropdown
                      title={
                      category?.find((it) => it.id === inputFields.category_id)
                        ?.category_name || 'Categories'
                    }
                      onChanged={(d) => {
                      if (d.id === 'all') {
                        getVideosLibraryData(null, 1)
                        setInputFields({
                          ...inputFields,
                          category_id: null
                        })
                      } else {
                        getVideosLibraryData(null, 1, d.id)
                        setInputFields({
                          ...inputFields,
                          category_id: d.id
                        })
                      }
                    }}
                      Data={[
                      ...(inputFields.category_id
                        ? [{ title: 'All Video', id: 'all' }]
                        : []),
                      ...(category || []).map((d) => ({
                        title: d.category_name,
                        id: d.id
                      }))
                    ]}
                      customClass="store_dropdown"
                    />
                    <Dropdown
                      title="Ratings"
                      Data={[
                      { title: 'Scaloo' },
                      { title: 'Spacure' },
                      { title: 'PETZINO' },
                      { title: 'A-Z FIT' },
                      { title: 'TESTIEE' }
                    ]}
                      customClass="disabledDropdown"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {videoData?.length === 0 && !isLoading && (
          <CommonEmptyData
            image={Images.media_business_gray}
            mainTitle={English.B106}
            descriptionText={English.B107}
            onClick={() => handleButtonClick()}
            addIcon={Images.FileUploadWhite}
            btnText={English.G153}
            className="!bg-[#FAFAFA] !border-solid"
            classNameDescription="font-normal text-[#7A7474] text-[16px]"
          />
        )}

        <div className="video-detail-manager grid grid-cols-4 max-[1440px]:grid-cols-3 max-[1100px]:!grid-cols-2">
          {isLoading ? (
            <div className="video-detail-item ml-[10px]">
              <div className="video-detail-cover">
                <div className="absolute !right-[11px] !top-[11px]">
                  <button type="button" className="video_like">
                    <Skeleton width={25} height={25} circle={12} />
                  </button>
                </div>
                <div className="absolute !right-[11px] !bottom-[11px]">
                  <button type="button" className="video_like">
                    <Skeleton width={30} height={15} />
                  </button>
                </div>
              </div>
              <div className="video-content">
                <p>
                  <Skeleton width={250} height={12} />
                  <Skeleton width={175} height={12} />
                </p>
                <div className="views-and-time">
                  <div className="flex flex-row gap-3">
                    <span className="flex gap-1">
                      <Skeleton width={25} />
                    </span>
                    <span className="flex gap-1">
                      <Skeleton width={25} />
                    </span>
                    <span className="flex gap-1">
                      <Skeleton width={25} />
                    </span>
                  </div>
                  <span className="!text-textSecondary !font-medium">
                    <Skeleton width={90} />
                  </span>
                </div>
              </div>
            </div>
          ) : (
            videoData?.map((item, index) => (
              <div
                className="video-detail-item"
                key={s3ImageVideo + item.cover_attachment}
              >
                <div
                  className="video-detail-cover"
                  onClick={() => handleItemClick(item)}
                  style={{
                    backgroundImage: `url(${
                      s3ImageVideo + item.cover_attachment
                    })`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                >
                  {/* <div className="video_like_bg">
                  <button
                    type="button"
                    className="video_like"
                    onClick={(e) => {
                      e.stopPropagation()
                      videoLikeData(item.video_id)
                    }}
                  >
                    {item.is_like ? (
                      <img src={Images.Likes} alt="Unlike" />
                    ) : (
                      <img src={Images.UnLikes} alt="Like" />
                    )}
                  </button>
                </div> */}
                  {/* {item?.app_data?.length > 0 && (
                  <span className="activity-badge">{English.G274}</span>
                )} */}
                  <div className="time-badge">
                    <span className="text-[#0B0A0A] text-[11px] font-black">
                      {formatDurations(item?.duration)}
                    </span>
                  </div>
                </div>
                <div
                  className={`video-content ${expandedIndex === index ? 'expanded' : ''}`}
                >
                  <p>
                    {expandedIndex === index
                      ? item?.title
                      : `${item?.title?.slice(0, 45)}`}
                    {item?.title?.length > 45 && (
                      <span
                        className="see-more"
                        onClick={(e) => {
                          e.stopPropagation()
                          setExpandedIndex(
                            expandedIndex === index ? null : index
                          )
                        }}
                      >
                        {expandedIndex === index ? English.G145 : English.G146}
                      </span>
                    )}
                  </p>
                  <div className="views-and-time">
                    <div className="flex flex-row gap-3">
                      <span>
                        <img src={Images.eye_line} alt="" />
                        {formatViews(item?.views || 0)}
                      </span>
                      <span
                        onClick={(e) => {
                          e.stopPropagation()
                          videoLikeData(item.video_id)
                        }}
                      >
                        <img
                          className={`${item?.is_like ? 'rotate-180' : 'rotate-0'}`}
                          src={Images.thumb_up}
                          alt=""
                        />
                        {formatViews(item?.total_like || 0)}
                      </span>
                      <span
                        className="pointer-events-none"
                        onClick={() => onClickComment(item.video_id)}
                      >
                        <img src={Images.comment_new} alt="" />
                        {formatViews(item?.total_comment || 0)}
                      </span>
                    </div>
                    <span className="!text-textSecondary !font-medium">
                      {/* <img src={Images.ClockFillIco} alt="" /> */}
                      {timeDifference(item.created_at)}
                    </span>
                  </div>
                </div>
                <div className="image-buttons z-1">
                  <label>
                    <button
                      type="button"
                      onClick={() => onClickEditPodcast(item)}
                      className="d-none"
                    />
                  </label>
                  <button
                    type="button"
                    className="p-0"
                    onClick={() => {
                      setUpdatingIndex(index)
                      ShowConfirm(item.video_id)
                    }}
                  />
                  {/* <div
                  type="button"
                  className="button1"
                  onClick={() => onClickComment(item.video_id)}
                /> */}
                </div>
                <ConfirmationModal
                  isVisible={ShowConfirmModel && updatingIndex === index}
                  onConfirm={deleteVideo}
                  onCancel={handleCancel}
                  isLoading={delLoading}
                />
                {delLoading && updatingIndex === index && <Loader />}
              </div>
            ))
          )}
          {commentSection && (
            <div className="add-item-modal mh-100">
              <div className="add-item-contents comment-container">
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className="mb-0">{English.G147}</h3>
                  <button
                    type="button"
                    className="secondary-btn-modal"
                    onClick={HandleCancel}
                  >
                    <img
                      className="close-btns"
                      src={Images.CloseIco}
                      alt="clock icon"
                    />
                  </button>
                </div>
                <div className="comment-section">
                  {isLoadingComments ? (
                    <div>
                      <div className="comments-container">
                        <ul className="comments-list">
                          <li className="comment-item">
                            <button type="button" className="comment-delete">
                              <Skeleton
                                className="rounded-3"
                                width={20}
                                height={20}
                              />
                            </button>
                            <div className="comment-header mb-0 gap-2">
                              <Skeleton circle width={45} height={45} />
                              <span className="user-name">
                                <Skeleton width={150} height={20} />
                              </span>
                            </div>
                            <div className="comment-btns">
                              <button type="button">
                                <Skeleton width={20} height={20} />
                              </button>
                              <Skeleton width={20} height={20} />
                              <button type="button">
                                <Skeleton width={45} height={20} />
                              </button>
                              <button type="button">
                                <Skeleton width={45} height={20} />
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="add-comment">
                        <input
                          type="text"
                          placeholder={English.G151}
                          value={newComment}
                          onChange={(e) => setNewComment(e.target.value)}
                          name="reply"
                        />
                        <button
                          type="button"
                          onClick={() => handleAddComment(newComment)}
                        >
                          {English.G152}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="comments-container">
                        {commentsList?.length === 0 ? (
                          <div className="no-comments-message">
                            {English.G148}
                          </div>
                        ) : (
                          <ul className="comments-list">
                            {commentsList.map((comment, index) => (
                              <li key={comment.id} className="comment-item">
                                {isDeleting && (
                                  <div className="loader-overlay">
                                    {commentIndex === index && <Loader />}
                                  </div>
                                )}
                                {comment.user.name === userData.user_name && (
                                  <button
                                    type="button"
                                    className="comment-delete"
                                    onClick={() => {
                                      setCommentIndex(index)
                                      shotCommentDelete(comment.reply_id)
                                    }}
                                  >
                                    <img
                                      src={Images.Delete}
                                      alt="delete icon"
                                    />
                                  </button>
                                )}
                                <div className="comment-header mb-0">
                                  <img
                                    src={comment.user.profilePic}
                                    alt={`${comment.user.name}'s profile`}
                                    className="profile-pic"
                                  />
                                  <span className="user-name">
                                    {comment.user.name}
                                  </span>
                                </div>
                                {editCommentId === comment.id ? (
                                  <div className="edit-reply">
                                    <input
                                      type="text"
                                      value={editedCommentText}
                                      onChange={(e) => setEditedCommentText(e.target.value)}
                                    />
                                    <button
                                      type="button"
                                      onClick={() => handleSaveComment(
                                          comment.reply_id,
                                          editedCommentText
                                        )}
                                    >
                                      {English.G117}
                                    </button>
                                  </div>
                                ) : (
                                  <div className="comment-text">
                                    {comment.text}
                                  </div>
                                )}
                                <div className="comment-btns">
                                  <button
                                    type="button"
                                    onClick={() => handleLikeComment(comment.reply_id)}
                                  >
                                    {comment.is_like ? (
                                      <img src={Images.Likes} alt="Unlike" />
                                    ) : (
                                      <img src={Images.UnLikes} alt="Like" />
                                    )}
                                    {' '}
                                    {comment.total_like}
                                  </button>
                                  {comment.user.name === userData.user_name && (
                                    <button
                                      type="button"
                                      onClick={() => handleEditComment(
                                          comment.id,
                                          comment.text
                                        )}
                                    >
                                      {English.G81}
                                    </button>
                                  )}
                                  <button
                                    type="button"
                                    onClick={() => toggleReplies(comment.id)}
                                  >
                                    {showReplies[comment.id]
                                      ? 'Hide Replies'
                                      : `Show Replies (${comment?.replies?.length})`}
                                    {/* {' '}
                                  Replies */}
                                  </button>
                                </div>
                                {showReplies[comment.id] && (
                                  <ul className="replies-list">
                                    {comment.replies.map((reply) => (
                                      <li key={reply.id} className="reply-item">
                                        <div className="reply-header mb-0">
                                          <img
                                            src={reply.user.profilePic}
                                            alt={`${reply.user.name}'s profile`}
                                            className="profile-pic"
                                          />
                                          <span className="user-name">
                                            {reply.user.name}
                                          </span>
                                          {reply.user.name ===
                                            userData.user_name && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                setCommentIndex(index)
                                                handleDeleteReply(
                                                  comment.reply_id,
                                                  reply.reply_id
                                                )
                                              }}
                                              className="comment-delete"
                                            >
                                              <img
                                                src={Images.Delete}
                                                alt="delete icon"
                                              />
                                            </button>
                                          )}
                                        </div>
                                        {editReplyId === reply.id ? (
                                          <div className="edit-reply">
                                            <input
                                              type="text"
                                              value={editedReplyText}
                                              onChange={(e) => setEditedReplyText(
                                                  e.target.value
                                                )}
                                            />
                                            <button
                                              type="button"
                                              onClick={() => handleSaveReply(
                                                  reply.reply_id,
                                                  editedReplyText
                                                )}
                                            >
                                              {English.G117}
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="reply-text">
                                            {reply.text}
                                          </div>
                                        )}
                                        <div className="comment-btns">
                                          <button
                                            type="button"
                                            onClick={() => handleLikeReply(reply.reply_id)}
                                          >
                                            {reply.is_like ? (
                                              <img
                                                src={Images.Likes}
                                                alt="Unlike"
                                              />
                                            ) : (
                                              <img
                                                src={Images.UnLikes}
                                                alt="Like"
                                              />
                                            )}
                                            {' '}
                                            {reply.total_like}
                                          </button>
                                          {reply.user.name ===
                                            userData.user_name && (
                                            <button
                                              type="button"
                                              onClick={() => handleEditReply(
                                                  reply.id,
                                                  reply.text
                                                )}
                                            >
                                              {English.G81}
                                            </button>
                                          )}
                                        </div>
                                      </li>
                                    ))}
                                    <li className="add-reply">
                                      <input
                                        type="text"
                                        placeholder={English.G149}
                                        value={Replied}
                                        onChange={(e) => setShowReplied(e.target.value)}
                                      />
                                      <button
                                        type="button"
                                        onClick={() => handleAddReply(
                                            comment.reply_id,
                                            Replied
                                          )}
                                      >
                                        {English.G150}
                                      </button>
                                    </li>
                                  </ul>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      {totalCommentPages > 1 && (
                        <CommentPagination
                          currentPage={currentPageComment}
                          totalPages={totalCommentPages}
                          onPageChange={handlePageChanges}
                        />
                      )}
                      <div className="add-comment">
                        <input
                          type="text"
                          placeholder={English.G151}
                          value={newComment}
                          onChange={(e) => setNewComment(e.target.value)}
                          name="reply"
                        />
                        {commentsList?.length === 0 && (
                          <button
                            type="button"
                            className="comment_add_btn"
                            onClick={() => handleAddComment(newComment, '', '')}
                          >
                            {English.G152}
                          </button>
                        )}
                        {commentsList.map((comment, i) => (
                          <button
                            key={i}
                            type="button"
                            onClick={() => handleAddComment(
                                newComment,
                                comment.user.profilePic,
                                comment.user.name
                              )}
                          >
                            {English.G152}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>

      {showAddVideoModal && (
        <VideoAddModal
          handleCancel={handleCancel}
          editData={editVideoDate}
          onSubmit={(video_id) => {
            // if (video_id) {
            //   const video = getParticularVideosData(video_id)
            //   const temp = [...videoData]
            //   videoData.forEach((item, index) => {
            //     if (item.video_id === video_id) {
            //       temp[index] = video
            //     }
            //   })
            //   setVideoData(temp)
            // } else {
            clearValue()
            getVideosLibraryData(null, 1)
            // }
          }}
        />
      )}
    </div>
  )
}
export default VideoTab

/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
import { SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import { toast } from 'react-toastify'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Images } from '../../helpers'
import ImageSlider from './Swiper/ImageSlider'
import { APICall, EndPoints } from '../../services'
import Loader from '../Loader/Loader'
import ShotComponent from './ShotComponent/ShotComponent'
import { emitter, EventType } from '../../helpers/Emitter'
import ShotsMessageBox from './ShotComponent/ShotsMessageBox'
import useClickOutside from '../../hooks/useClickOutside'

const ShotsDetails = () => {
  const location = useLocation()
  const { text, shotIndex = 0 } = location.state || {}
  const navigate = useNavigate()
  const messageBoxRef = useRef(null)
  const [showMessageBox, setShotMessageBox] = useState(false)
  const [shotsArray, setShotsArray] = useState([])
  const [messageRefId, setMessageRefId] = useState(0)
  const commentCount = useRef(null)
  const [nextUrl, setNextUrl] = useState('')
  const [activeIndex, setActiveIndex] = useState(0)
  const swiperRef = useRef(null)
  const [loader, setLoading] = useState(false)

  window.onkeydown = (e) => {
    if (e.key === 'ArrowRight' && swiperRef.current) {
      swiperRef.current?.swiper?.slideNext()
    }
    if (e.key === 'ArrowLeft' && swiperRef.current) {
      swiperRef.current?.swiper?.slidePrev()
    }
  }

  const getTotalShots = useCallback(() => {
    const payload = {
      search: ''
    }
    APICall(
      'post',
      payload,
      nextUrl || EndPoints.getShotLibrary,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShotsArray((data) => [...data, ...res.decryptedData.data])
          setNextUrl(res?.decryptedData?.next_page_url)
        } else {
          toast.error(res.data?.message)
        }
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.data?.message)
      })
  }, [nextUrl])

  const onAddMessage = () => {
    setShotsArray((previousItems) => {
      const newArray = [...previousItems]
      const particularShotIndex = previousItems.findIndex(
        (item) => item.shot_id === messageRefId
      )
      newArray[particularShotIndex] = {
        ...newArray[particularShotIndex],
        total_comments: newArray[particularShotIndex].total_comments + 1
      }
      return newArray
    })
  }

  const firTimeLoad = useRef(false)
  useEffect(() => {
    if (!firTimeLoad?.current) {
      firTimeLoad.current = true
      setLoading(true)
      getTotalShots()
    }
  }, [])

  useEffect(() => {
    if (shotsArray?.length > 0 && swiperRef.current) {
      setActiveIndex(swiperRef.current.swiper?.activeIndex)
    }
  }, [shotsArray?.length])

  useClickOutside({
    refArray: [messageBoxRef],
    onPressOutside: () => setShotMessageBox(false)
  })

  const onEdit = (item) => {
    if (text === 'shotsTab') {
      navigate(-1)
      setTimeout(() => {
        emitter.emit(EventType.contentMangerTabs, {
          tabIndex: 2
        })
      }, 50)
    } else {
      navigate(-1)
    }
    setTimeout(() => {
      emitter.emit(EventType.editShot, {
        item
      })
    }, 500)
  }

  return (
    <div className="w-full h-screen relative flex flex-col bg-mainColor">
      {loader && <Loader />}
      {showMessageBox && (
        <div ref={messageBoxRef}>
          <ShotsMessageBox
            onClose={setShotMessageBox}
            shot_id={messageRefId}
            onAddMessage={onAddMessage}
            total_comments={
              commentCount.current === null ? 0 : commentCount.current
            }
          />
        </div>
      )}
      <div className="w-full flex items-center justify-between px-8 pt-10 fixed top-0 left-0 z-10">
        <div className="flex items-center gap-2.5">
          <img
            src={Images.gbn_business_plat}
            alt="logo-icon"
            className="white-filter"
          />
          {/* <img src={Images.FyerdateText} alt="logo-text-icon" className="white-filter" /> */}
        </div>
        <img
          src={Images.CloseIco}
          className="white-filter cursor-pointer"
          onClick={() => {
            if (text === 'shotsTab') {
              navigate(-1)
              setTimeout(() => {
                emitter.emit(EventType.contentMangerTabs, {
                  tabIndex: 2
                })
              }, 50)
            } else {
              navigate(-1)
            }
          }}
          alt=""
        />
      </div>
      <div className="h-full w-full flex items-center justify-center shots-modal max-h-[cacl(100vh-120px)]">
        <ImageSlider
          grabCursorType
          isCenteredSlideType
          className="w-full"
          spaceBetweenProp={10}
          slidesPerViewType={5}
          initialSlides={shotIndex}
          showNavigation={!showMessageBox}
          modulesType={[Navigation]}
          onSlideChange={(item) => {
            setActiveIndex(item.activeIndex)
          }}
          onSlideNextTransitionEnd={(item) => {
            if (item.realIndex + 1 === shotsArray?.length && nextUrl) {
              getTotalShots()
            }
          }}
          ref={swiperRef}
        >
          {shotsArray?.length > 0 ? (
            shotsArray?.map((item, index) => {
              return (
                <SwiperSlide className="shots-slide" key={item?.shot_id}>
                  <ShotComponent
                    isActive={activeIndex === index}
                    item={item}
                    setShowMessage={(state) => {
                      setShotMessageBox(state)
                      commentCount.current = item.total_comments
                    }}
                    setMessageRefId={setMessageRefId}
                    shotsArray={shotsArray}
                    setShotsArray={setShotsArray}
                    onEdit={onEdit}
                  />
                </SwiperSlide>
              )
            })
          ) : (
            <div>nodata</div>
          )}
        </ImageSlider>
      </div>
    </div>
  )
}

export default ShotsDetails

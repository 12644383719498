import React, { forwardRef } from 'react'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import { Images } from '../../../helpers'

const ReplyInput = forwardRef(
  ({ textContent, setTextContent, id, onPressSend, className }, ref) => {
    return (
      <div className={`reply-input relative ${className}`} ref={ref}>
        <input
          className="h-[50px] w-full px-[54px] rounded-[35px] border border-secondaryBorder bg-primaryBg"
          placeholder="Add a reply ..."
          value={textContent.id === id ? textContent.text : ''}
          onChange={(e) => {
            if (textContent.id !== id) {
              return
            }
            setTextContent(textContent.id, e.target.value)
          }}
        />
        <CommonButton
          type="button"
          className="!absolute !top-1/2 !-translate-y-1/2 !left-[18px] !bg-transparent !border-none !p-0 !justify-start !w-auto"
          label=""
          disabled={false}
          context=""
          imgSrc={Images.SmileEmoji_icon}
        />
        <CommonButton
          type="button"
          className="!absolute !top-1/2 !-translate-y-1/2 !right-[18px] !bg-transparent !border-none !p-0 !justify-end !w-auto"
          label=""
          disabled={textContent.text?.length <= 0}
          context=""
          imgSrc={Images.Send_btn}
          onClick={onPressSend}
        />
      </div>
    )
  }
)

export default ReplyInput

/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { English, Images } from '../../helpers'
import { timeDifference } from '../../helpers/Utility'
import { APICall, dataDecrypt, EndPoints } from '../../services'
import { s3ImageProfile } from '../../services/Service'
import DiscussionReplyModal from '../CommanComponent/DiscussionReplyModal'
import CommonButton from '../ButtonComponent/ButtonCommon'
import TopDiscussionList from './TopDiscussionList'
import HotTopicDiscussion from './HotTopicDiscussion'
import { emitter, EventType } from '../../helpers/Emitter'
import DiscussionAddModal from './Modal/DiscussionAddModal'
import BreadCrumbHeader from './SubComponent/BreadCrumbHeader'

const DiscussionDetailPage = () => {
  const location = useLocation()
  const data = location?.state?.data
  const tabIndex = location?.state?.tabIndex
  const userData = useSelector((state) => state.user?.userData)
  const profileData = useSelector((state) => state.user?.profileData)
  const [expandedIndex, setExpandedIndex] = useState(null)
  const [ShowDiscussionModel, setShowDiscussionModel] = useState(false)

  const [discussion, setDiscussion] = useState([])
  const [comments, setComments] = useState([])
  const [newComment, setNewComment] = useState('')
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const [currentPageComment, setCurrentPageComment] = useState(1)

  const handleCancel = () => {
    // setCommentSection(false)
    commentClear()
    toggleReplies(null, true)
  }

  const commentClear = () => {
    setComments([])
    setSelectedId([])
    setNewComment('')
    setShowReplied('')
    setEditedCommentText('')
    setEditedReplyText('')
  }
  const fetchDiscussionComments = (discussion_id, delay = 3000) => {
    setTimeout(() => {
      discussionReply(discussion_id)
    }, delay)
  }

  const discussionReply = (discussion_id) => {
    const platFormData = {
      app_id: 2,
      discussion_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const dataComment = res?.decryptedData?.data?.map((d, i) => ({
            id: i + 1,
            text: d.reply,
            discussion_id: d.discussion_id,
            is_like: d.is_like,
            reply_id: d.reply_id,
            total_like: d.total_like,
            replies: d.reply_data.map((subData, subIndex) => ({
              id: subIndex + 1,
              text: subData.reply,
              reply_id: subData.reply_id,
              total_like: subData.total_like,
              is_like: subData.is_like,
              user: {
                name: subData.user_name,
                profilePic: s3ImageProfile + subData.profile_icon
              }
            })),
            user: {
              name: d.user_name,
              profilePic: s3ImageProfile + d.profile_icon
            }
          }))
          setComments(dataComment)
          setIsLoadingComments(false)
        } else {
          setIsLoadingComments(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsLoadingComments(false)
        toast.error(error?.data?.message)
      })
  }

  const discussionAddComment = (reply) => {
    const platFormData = {
      discussion_id: selectedId,
      reply,
      app_id: 2
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionAddReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const discussionAddReply = (reply_id, replyText) => {
    const platFormData = {
      discussion_id: selectedId,
      reply_id,
      reply: replyText,
      app_id: 2
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionAddReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const discussionEditComment = (reply_id, reply) => {
    const platFormData = {
      reply_id,
      reply
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionEditReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const discussionEditReply = (reply_id, replyId) => {
    const platFormData = {
      reply_id,
      reply: replyId
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionEditReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const deleteDiscussionComment = (reply_id) => {
    setIsDeleting(true)
    const data = {
      reply_id
    }
    APICall(
      'post',
      data,
      `${EndPoints.discussionDeleteReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const deleteDiscussionReply = (replyId) => {
    setIsDeleting(true)
    const data = {
      reply_id: replyId
    }
    APICall(
      'post',
      data,
      `${EndPoints.discussionDeleteReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const discussionCommentLike = (reply_id) => {
    const platFormData = {
      app_id: 2,
      reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionReplyLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments(
            comments.map((item) => (item.comment_id === reply_id
                ? { ...item, is_like: !item.is_like }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const discussionReplyLike = (reply_id) => {
    const platFormData = {
      app_id: 2,
      reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionReplyLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments(
            comments.map((comment) => ({
              ...comment,
              replies: comment.replies.map((reply) => (reply.reply_id === reply_id
                  ? {
                      ...reply,
                      is_like: !reply.is_like,
                      total_like: reply.is_like
                        ? reply.total_like - 1
                        : reply.total_like + 1
                    }
                  : reply))
            }))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleAddComment = (reply) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        discussion_id: selectedId,
        total_like: 0,
        replies: [],
        user: {
          name: userData.user_name,
          profilePic: s3ImageProfile + userData.profile_image
        }
      }
      setComments([newCommentObj, ...comments])
      setNewComment('')
    }
    discussionAddComment(reply)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    discussionCommentLike(commentId)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [
            ...comment.replies,
            {
              id: comment.replies.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: userData.user_name,
                profilePic: s3ImageProfile + userData.profile_image
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    discussionAddReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: comment.replies.filter((reply) => reply.id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    deleteDiscussionReply(replyId)
  }

  const toggleReplies = (commentId, forceCloseAll = false) => {
    if (forceCloseAll) {
      setShowReplies({})
    } else {
      setShowReplies((prevState) => ({
        // ...prevState,
        [commentId]: !prevState[commentId]
      }))
    }
    setShowReplied('')
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? { ...comment, text: editedCommentText }
        : comment))
    discussionEditComment(commentId, reply)
    setComments(updatedComments)
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? {
            ...comment,
            replies: comment.replies.map((reply) => (reply.id === replyId ? { ...reply, text: editedReplyText } : reply))
          }
        : comment))
    discussionEditReply(commentId, replyId)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  useEffect(() => {
    if (data) {
      fetchDiscussionData()
      fetchDiscussionComments(data?.discussion_id)
    }
  }, [data])

  const fetchDiscussionData = () => {
    const platFormData = {
      discussion_id: data.discussion_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.particularDiscussion}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const disData = dataDecrypt(res.data.data)
          setDiscussion(disData[0])
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const commentsPerPage = 5
  const indexOfLastComment = currentPageComment * commentsPerPage
  const indexOfFirstComment = indexOfLastComment - commentsPerPage
  const commentsList = comments.slice(indexOfFirstComment, indexOfLastComment)
  const totalCommentPages = Math.ceil(comments.length / commentsPerPage)

  useEffect(() => {
    setSelectedId(data.discussion_id)
  }, [selectedId])

  useEffect(() => {
    if (currentPageComment > totalCommentPages) {
      setCurrentPageComment(totalCommentPages || 1)
    }
  }, [comments, currentPageComment, totalCommentPages])

  const [editData, setEditData] = useState(null)
  const onClickEdit = () => {
    setShowDiscussionModel(true)
    setEditData(discussion)
  }

  const onClickAdd = () => {
    setEditData(null)
    setShowDiscussionModel(true)
  }

  return (
    <div className="content-manager">
      <div className="flex flex-row !gap-4">
        <div className="common-card-body !pt-0 !overflow-auto no-scrollbar !h-[calc(100vh-133px)] flex flex-1">
          <div className="w-[100%] flex flex-row">
            <div className="bg-white w-[100%]">
              <div className="sticky top-0 bg-white !pt-5">
                <BreadCrumbHeader
                  isPublish
                  homeLable="Go Back"
                  onClickEdit={() => onClickEdit()}
                  onClickHome={() => {
                    window.history.back()
                    setTimeout(() => {
                      emitter.emit(EventType.contentMangerTabs, { tabIndex: tabIndex !== undefined ? tabIndex : 4, subTabIndex: tabIndex ? 0 : 2 })
                    }, 100)
                  }}
                />
              </div>
              <div>
                <div className="w-full border-b-[1px] border-BorderLight !pb-3 !mb-2">
                  <div className="discussion-tag mb-2">
                    <span>{discussion?.category_name}</span>
                  </div>
                  <div className="discussion-profile-profile mb-3.5">
                    <div className="discussion-detail ">
                      <div className="flex-1">
                        <h5 className="text-Main font-black text-lg mb-3.5 italic discussion_topic">
                          {discussion?.topic}
                        </h5>
                        <p className="mb-0 text-main text-base">
                          {expandedIndex === 0
                          ? data?.description
                          : `${data?.description?.slice(0, 400)}`}
                          {data?.description?.length > 400 && (
                          <span
                            className="see-more"
                            onClick={(e) => {
                              e.stopPropagation()
                              setExpandedIndex(expandedIndex === 0 ? null : 0)
                            }}
                          >
                            ...
                            {' '}
                            {expandedIndex === 0 ? English.G145 : English.G146}
                          </span>
                        )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-[#5A5A5A] text-xs font-normal">
                      {English.A212}
                      {' '}
                      {timeDifference(discussion.created_at)}
                    </span>
                    <div className="flex gap-3">
                      <div
                        className="flex gap-2 items-center cursor-pointer"
                      >
                        <img src={Images.comments_icon} alt="" />
                        <span className="text-[#7A7474] font-normal text-sm">
                          {discussion.total_replies}
                        </span>
                      </div>
                      <div className="follower-shows">
                        <div className="discussion-img-group d-flex">
                          {/* {visiblePerformers?.map((performer, index) => (
                          <img
                            key={performer?.performer_id}
                            alt={`speaker ${index + 1}`}
                            src={s3ImageProfile + performer}
                            className="rounded-full border-2 border-white shadow-sm"
                          />
                                          ))}
                        {hiddenPerformersCount > 0 && (
                        <div className="w-[40px] h-[40px] rounded-full flex items-center ml-[-6px] justify-center bg-[#01104D] from-gray-800 to-blue-900 text-white text-sm font-semibold">
                          <img src={Images.discussion_more_icon} alt="" />
                        </div>
                                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <DiscussionReplyModal
                  title={English.G177}
                  comments={comments}
                  setComments={setComments}
                  HandleCancel={handleCancel}
                  commentIndex={commentIndex}
                  commentsList={commentsList}
                  editCommentId={editCommentId}
                  editReplyId={editReplyId}
                  editedCommentText={editedCommentText}
                  editedReplyText={editedReplyText}
                  handleAddComment={handleAddComment}
                  handleAddReply={handleAddReply}
                  handleDeleteReply={handleDeleteReply}
                  handleEditComment={handleEditComment}
                  handleEditReply={handleEditReply}
                  handleLikeComment={handleLikeComment}
                  shotCommentReplyLike={discussionReplyLike}
                  handleSaveComment={handleSaveComment}
                  handleSaveReply={handleSaveReply}
                  isLoadingComments={isLoadingComments}
                  newComment={newComment}
                  profileData={profileData}
                  setCommentIndex={setCommentIndex}
                  setEditedCommentText={setEditedCommentText}
                  setEditedReplyText={setEditedReplyText}
                  setNewComment={setNewComment}
                  setShowReplied={setShowReplied}
                  shotCommentDelete={deleteDiscussionComment}
                  showReplies={showReplies}
                  toggleReplies={toggleReplies}
                  isDeleting={isDeleting}
                  currentPageComment={currentPageComment}
                  setCurrentPageComment={setCurrentPageComment}
                  Replied={Replied}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-[290px] flex flex-col !gap-4 h-[calc(100vh-133px)] overflow-y-auto no-scrollbar">
          <div className="sticky top-0">
            <CommonButton
              label="start a topic"
              type="button"
              isPrimary
              className="w-full"
              context={English.G232}
              onClick={() => onClickAdd()}
            />
          </div>
          <div className="common-card-body">
            <div className="flex !gap-2 items-center justify-center">
              <img src={Images.Fire_icon_blue} alt="" />
              <h6 className="mb-0 text-Main text-base font-black italic">Top Contributors</h6>
            </div>
            <div>
              <TopDiscussionList />
            </div>
          </div>
          <div className="common-card-body">
            <div className="flex !gap-2 items-center justify-center">
              <img className="" src={Images.Fire_icon_blue} alt="" />
              <h6 className="mb-0 text-[#151515] text-[16px] italic font-black">This Week Hot topics</h6>
            </div>
            <div>
              <HotTopicDiscussion />
            </div>
          </div>
        </div>
      </div>
      {ShowDiscussionModel && (
        <DiscussionAddModal
          handleCancel={() => setShowDiscussionModel(false)}
          editData={editData}
          onSubmit={(discussion_id, app_data) => {
            fetchDiscussionData(app_data?.[0]?.id)
            setShowDiscussionModel(false)
          }}
        />
      )}
    </div>
  )
}
export default DiscussionDetailPage

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import './AddDiscussions.css'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { s3ImageProfile } from '../../services/Service'
import {
  lockScroll,
  unlockScroll
} from '../../helpers/Utility'
// import CommentPagination from '../Pagination/CommentPagination'
import CommonButton from '../ButtonComponent/ButtonCommon'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import AddDiscussionModal from './AddDiscussionModal'
import DiscussionReplyModal from '../CommanComponent/DiscussionReplyModal'
import DisscussionItem from './DisscussionItem'

const DisscussionComponent = () => {
  const profileData = useSelector((state) => state.user?.profileData)
  const app_id = useSelector((state) => state.user?.app_id)

  const [ShowDiscussionModel, setShowDiscussionModel] = useState(false)
  const [ShowPreviewDiscussionModel, setShowPreviewDiscussionModel] =
    useState(false)
  const [Platformtags, setPlatformTags] = useState([])
  const [inputFields, setInputFields] = useState({
    app_id: [],
    topic: '',
    description: '',
    tags: [],
    category_id: ''
  })
  const [comments, setComments] = useState([])
  const [newComment, setNewComment] = useState('')
  const [commentSection, setCommentSection] = useState(false)
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [delDiscussionDataId, setDelDiscussionId] = useState()
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const [currentPageComment, setCurrentPageComment] = useState(1)
  const [discussions, setDiscussions] = useState([])
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [expandedIndex, setExpandedIndex] = useState(-1)
  // const [particularDiscussionData, setParticularDiscussionData] = useState([])
  const [expandedDiscussion, setExpandedDiscussion] = useState(null)
  const [category, setCategory] = useState([])

  const HandleDiscussion = () => {
    setShowDiscussionModel(true)
    MainCategory()
    // navigate('/add-component', { state: { defaultIndex: 5 } })
  }

  const HandleCancel = () => {
    clearValue()
    setShowDiscussionModel(false)
    setShowPreviewDiscussionModel(false)
    setIsEditing(false)
  }

  const handleCancels = () => {
    setShowConfirmModel(false)
  }

  const HandlePublish = () => {
    if (isEditing) {
      editDiscussionDataApi()
    } else {
      addDiscussionDataSubmit()
    }
    setShowDiscussionModel(false)
    setShowPreviewDiscussionModel(false)
  }

  const ShowConfirm = (discussion_id) => {
    setDelDiscussionId(discussion_id)
    setShowConfirmModel(true)
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const clearValue = () => {
    setInputFields({
      ...inputFields,
      topic: '',
      description: '',
      app_id: [],
      tags: [],
      category_id: ''
    })
    setPlatformTags([])
    setCategory([])
  }

  const commentClear = () => {
    setComments([])
    setSelectedId([])
    setNewComment('')
    setShowReplied('')
    setEditedCommentText('')
    setEditedReplyText('')
  }

  const getDiscussion = (search, currentPage) => {
    // setIsLoading(true)
    const platFormData = {
      app_id: app_id.id,
      search
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.getDiscussion}?page=${currentPage}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const list = res?.decryptedData.data
          setDiscussions(currentPage === 1 ? list : [...discussions, ...list])
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
          // const discussionId = discussions[0]?.discussion_id
          // console.log(discussionId)

          // const discussionIds = decryptedData?.data
          //   ?.map((discussion) => discussion?.discussion_id)
          //   ?.filter(Boolean)

          // if (discussionIds?.length > 0) {
          //   getParticularDiscussion(discussionIds)
          // }
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  // const getParticularDiscussion = (id) => {
  //   const platFormData = {
  //     app_id: app_id.id,
  //     discussion_id: id
  //   }
  //   particularDiscussion(platFormData).then((res) => {
  //     if (res?.data?.success) {
  //       setParticularDiscussionData(dataDecrypt(res.data.data))
  //     }
  //   })
  // }

  const MainCategory = () => {
      APICall('post', {}, `${EndPoints.getDiscussionCategory}`, 'contentManager')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setCategory(res?.decryptedData)
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message)
        })
    }

    const getCategoryName = (categoryId) => {
      const categoryObj = category.find((cat) => cat.id === categoryId)
      return categoryObj ? categoryObj.category_name : English.G134
    }

  const updateDiscussionData = (id) => {
    const platFormData = {
      app_id: app_id.id,
      discussion_id: id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.particularDiscussion}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const d = res?.decryptedData
          const oldData = [...discussions]
          const index = oldData.findIndex((item) => item.discussion_id === id)
          oldData[index] = d.find((item) => item.discussion_id === id)
          setDiscussions(oldData)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const addDiscussionDataSubmit = () => {
    setLoading(true)
    const data = {
      app_id: [app_id?.id],
      topic: inputFields.topic,
      description: inputFields.description,
      category_id: inputFields.category_id
    }
    APICall('post', data, `${EndPoints.addDiscussion}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowPreviewDiscussionModel(false)
          setTimeout(() => {
            getDiscussion(null, currentPage)
          }, 3000)
          setIsEditing(false)
          setLoading(false)
          clearValue()
          toast.success(res?.data?.message)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const deleteDiscussion = () => {
    const data = {
      discussion_id: delDiscussionDataId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.deleteDiscussion}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setTimeout(() => {
            getDiscussion(null, currentPage)
          }, 3000)
          setLoading(false)
          toast.success(res?.data?.message)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const editDiscussionDataApi = () => {
    const data = {
      topic: inputFields.topic,
      description: inputFields.description,
      app_id: Platformtags.map((d) => d.id),
      discussion_id: inputFields.discussion_id,
      category_id: inputFields.category_id
    }
    APICall('post', data, `${EndPoints.editDiscussion}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsEditing(false)
          clearValue()
          setLoading(false)
          toast.success(res?.data?.message)
          setTimeout(() => {
            getDiscussion(null, currentPage)
          }, 3000)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  // const discussionLikes = (discussion_id) => {
  //   const platFormData = {
  //     app_id: app_id.id,
  //     discussion_id
  //   }
  //   APICall(
  //     'post',
  //     platFormData,
  //     `${EndPoints.discussionLike}`,
  //     'contentManager'
  //   )
  //     .then((res) => {
  //       if (res.status === 200 && res.data?.code === 200) {
  //         setDiscussions(
  //           discussions?.map((item) => (item.discussion_id === discussion_id
  //               ? { ...item, is_like: !item.is_like }
  //               : item))
  //         )
  //       } else {
  //         toast.error(res?.data?.message)
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error?.data?.message)
  //     })
  // }

  // Discussion pagination

  // const totalVideos = discussions?.length

  const handlePageChange = (pageNumber) => {
    getDiscussion(null, pageNumber)
    setCurrentPage(pageNumber)
  }

  // discussion comment
  const fetchDiscussionComments = (discussion_id, delay = 3000) => {
    setTimeout(() => {
      discussionReply(discussion_id)
    }, delay)
  }

  const discussionReply = (discussion_id) => {
    const platFormData = {
      app_id: app_id.id,
      discussion_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const dataComment = res?.decryptedData?.data?.map((d, i) => ({
            id: i + 1,
            text: d.reply,
            discussion_id: d.discussion_id,
            is_like: d.is_like,
            reply_id: d.reply_id,
            total_like: d.total_like,
            created_at: d.created_at,
            replies: d.reply_data.map((subData, subIndex) => ({
              id: subIndex + 1,
              text: subData.reply,
              reply_id: subData.reply_id,
              total_like: subData.total_like,
              is_like: subData.is_like,
              created_at: subData.created_at,
              user: {
                name: subData.user_name,
                profilePic: s3ImageProfile + subData.profile_icon
              }
            })),
            user: {
              name: d.user_name,
              profilePic: s3ImageProfile + d.profile_icon
            }
          }))
          setComments(dataComment)
          setIsLoadingComments(false)
        } else {
          setIsLoadingComments(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsLoadingComments(false)
        toast.error(error?.data?.message)
      })
  }

  const discussionAddComment = (reply) => {
    const platFormData = {
      discussion_id: selectedId,
      reply,
      app_id: app_id.id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionAddReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
          setTimeout(() => {
            updateDiscussionData(selectedId)
          }, 2500)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const discussionAddReply = (reply_id, replyText) => {
    const platFormData = {
      discussion_id: selectedId,
      reply_id,
      reply: replyText,
      app_id: app_id.id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionAddReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const discussionEditComment = (reply_id, reply) => {
    const platFormData = {
      reply_id,
      reply
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionEditReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const discussionEditReply = (reply_id, replyId) => {
    const platFormData = {
      discussion_id: selectedId,
      reply_id,
      reply: replyId
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionEditReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const deleteDiscussionComment = (reply_id) => {
    setIsDeleting(true)
    const data = {
      reply_id
    }
    APICall(
      'post',
      data,
      `${EndPoints.discussionDeleteReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
          setTimeout(() => {
            updateDiscussionData(selectedId)
          }, 2500)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const deleteDiscussionReply = (replyId) => {
    setIsDeleting(true)
    const data = {
      reply_id: replyId
    }
    APICall(
      'post',
      data,
      `${EndPoints.discussionDeleteReply}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchDiscussionComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const discussionCommentLike = (reply_id) => {
    const platFormData = {
      app_id: app_id.id,
      reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionReplyLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments(
            comments.map((item) => (item.reply_id === reply_id
                ? { ...item, is_like: !item.is_like, total_like: item.is_like ? item.total_like - 1 : item.total_like + 1 }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const discussionReplyLike = (reply_id) => {
    const platFormData = {
      app_id: app_id.id,
      reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.discussionReplyLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments(
            comments.map((comment) => ({
              ...comment,
              replies: comment.replies.map((reply) => (reply.reply_id === reply_id
                  ? {
                      ...reply,
                      is_like: !reply.is_like,
                      total_like: reply.is_like
                        ? reply.total_like - 1
                        : reply.total_like + 1
                    }
                  : reply))
            }))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleAddComment = (reply) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        discussion_id: selectedId,
        total_like: 0,
        replies: [],
        user: {
          name: profileData.user_name,
          profilePic: s3ImageProfile + profileData.profile_icon
        }
      }
      setComments([newCommentObj, ...comments])
      setNewComment('')
    }
    discussionAddComment(reply)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    discussionCommentLike(commentId)
  }

  // const handleLikeReply = (commentId) => {
  //   const updatedComments = comments.map((comment) => {
  //     if (comment.id === commentId) {
  //       return { ...comment, total_like: comment.total_like + 1 }
  //     }
  //     return comment
  //   })
  //   setComments(updatedComments)
  //   discussionReplyLike(commentId)
  // }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [
            ...comment.replies,
            {
              id: comment.replies.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: profileData.user_name,
                profilePic: s3ImageProfile + profileData.profile_icon
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    discussionAddReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: comment.replies.filter((reply) => reply.id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    deleteDiscussionReply(replyId)
  }

  const toggleReplies = (commentId, forceCloseAll = false) => {
    if (forceCloseAll) {
      setShowReplies({})
    } else {
      setShowReplies((prevState) => ({
        // ...prevState,
        [commentId]: !prevState[commentId]
      }))
    }
    setShowReplied('')
  }

  const onClickComment = (discussion_id, index) => {
    if (expandedDiscussion === index) {
      setExpandedDiscussion(null)
    } else {
      setExpandedDiscussion(index)
    }
    setSelectedId(discussion_id)
    if (!isLoadingComments) {
      setIsLoadingComments(true)
      discussionReply(discussion_id)
      setCommentSection(true)
    }
  }

  const onGoBack = () => {
    setExpandedDiscussion(null)
    setCommentSection(false)
  }

  const handleCancel = () => {
    setCommentSection(false)
    commentClear()
    toggleReplies(null, true)
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? { ...comment, text: editedCommentText }
        : comment))
    discussionEditComment(commentId, reply)
    setComments(updatedComments)
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => (comment.reply_id === commentId
        ? {
            ...comment,
            replies: comment.replies.map((reply) => (reply.reply_id === replyId ? { ...reply, text: editedReplyText } : reply))
          }
        : comment))
    discussionEditReply(commentId, replyId)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  const onClickEditDiscussion = (videoData) => {
    setShowDiscussionModel(true)
    setIsEditing(true)
    setInputFields({
      ...inputFields,
      topic: videoData.topic,
      description: videoData.description,
      app_id: videoData.app_data,
      category_id: videoData.category_id,
      discussion_id: videoData.discussion_id
    })
    setInitialValues({
      topic: videoData.topic,
      description: videoData.description,
      app_id: videoData.app_data,
      category_id: videoData.category_id,
      discussion_id: videoData.discussion_id
    })
    setPlatformTags(videoData.app_data)
    MainCategory({ category_id: videoData.category_id })
  }

  const validateForm = () => {
    const { topic, description, category_id } = inputFields
    if (topic && description && category_id) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  // comment pagination

  const commentsPerPage = 5
  const indexOfLastComment = currentPageComment * commentsPerPage
  const indexOfFirstComment = indexOfLastComment - commentsPerPage
  const commentsList = comments.slice(indexOfFirstComment, indexOfLastComment)
  const totalCommentPages = Math.ceil(comments.length / commentsPerPage)

  // const handlePageChanges = (pageNumber) => {
  //   setCurrentPageComment(pageNumber)
  // }

  useEffect(() => {
    setIsLoading(true)
    getDiscussion(null, currentPage)
    // MainCategory()
  }, [])

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    if (currentPageComment > totalCommentPages) {
      setCurrentPageComment(totalCommentPages || 1)
    }
  }, [comments, currentPageComment, totalCommentPages])

  useEffect(() => {
    if (!isEditing) {
      validateForm()
    }
  }, [inputFields])

  useEffect(() => {
    if (isEditing) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEditing])

  useEffect(() => {
    if (
      commentSection ||
      ShowDiscussionModel ||
      ShowPreviewDiscussionModel ||
      ShowConfirmModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [
    commentSection,
    ShowDiscussionModel,
    ShowPreviewDiscussionModel,
    ShowConfirmModel
  ])

  return (
    <div className="">
      <div className="recent-video-component recent-shots-component add-shots-component">
        {discussions?.length > 0 && (
          <>
            {expandedDiscussion !== null &&
              expandedDiscussion !== undefined && (
                <div
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={onGoBack}
                >
                  <img src={Images.left_arrow_icon} alt="" />
                  <span className="font-medium text-[16px]">Go Back</span>
                </div>
              )}
            <div className="discussions-wrapper-header common-contnt-wrapper">
              <h2 className="uppercase">{English.G207}</h2>
              <div className="add-video-rows">
                <CommonButton
                  label="Create Topic"
                  onClick={HandleDiscussion}
                  isPrimary
                  className="add_new_content"
                />
              </div>
            </div>
          </>
        )}

        {discussions?.length === 0 && !isLoading ? (
          <CommonEmptyData
            image={Images.discussion_icon}
            mainTitle="No discussions"
            descriptionText="Add discussions to show here"
            onClick={HandleDiscussion}
            addIcon={Images.plusrounded}
            btnText={English.A213}
            className="!bg-[#FAFAFA]"
            classNameDescription="font-normal text-[#7A7474] text-[16px]"
          />
        ) : isLoading ? (
          <div className="discussions-item position-relative">
            <div className="discussion-profile">
              <Skeleton className="discssion-profile-image border-0" />
              <div className="discssion-detail">
                <h5>
                  <Skeleton max-width={700} height={20} />
                </h5>
                <span className="mb-3">
                  <Skeleton max-width={200} height={10} />
                </span>
                <p>
                  <Skeleton max-width={700} height={30} />
                </p>
              </div>
            </div>
            <div className="discussion-group">
              <Skeleton circle width={30} height={30} />
            </div>
            <div className="discussion-comment">
              <Skeleton width={25} height={25} />
              <Skeleton width={25} />
            </div>
            <div className="discussion-button">
              <button type="button">
                <Skeleton width={10} height={35} />
              </button>
            </div>
          </div>
        ) : (
          <div className="content-manager-discussions-wrapper mt-20">
            {discussions.map((item, index) => {
              return (
                <DisscussionItem
                  ShowConfirm={ShowConfirm}
                  ShowConfirmModel={ShowConfirmModel}
                  deleteDiscussion={deleteDiscussion}
                  expandedDiscussion={expandedDiscussion}
                  expandedIndex={expandedIndex}
                  handleCancels={handleCancels}
                  index={index}
                  item={item}
                  loading={loading}
                  onClickComment={onClickComment}
                  onClickEditDiscussion={onClickEditDiscussion}
                  setExpandedIndex={setExpandedIndex}
                  setUpdatingIndex={setUpdatingIndex}
                  updatingIndex={updatingIndex}
                  key={index}
                />
              )
            })}
            {expandedDiscussion !== null && (
              <DiscussionReplyModal
                title={English.G177}
                comments={comments}
                setComments={setComments}
                HandleCancel={handleCancel}
                commentIndex={commentIndex}
                commentsList={commentsList}
                editCommentId={editCommentId}
                editReplyId={editReplyId}
                editedCommentText={editedCommentText}
                editedReplyText={editedReplyText}
                handleAddComment={handleAddComment}
                handleAddReply={handleAddReply}
                handleDeleteReply={handleDeleteReply}
                handleEditComment={handleEditComment}
                handleEditReply={handleEditReply}
                handleLikeComment={handleLikeComment}
                shotCommentReplyLike={discussionReplyLike}
                handleSaveComment={handleSaveComment}
                handleSaveReply={handleSaveReply}
                isLoadingComments={isLoadingComments}
                newComment={newComment}
                profileData={profileData}
                setCommentIndex={setCommentIndex}
                setEditedCommentText={setEditedCommentText}
                setEditedReplyText={setEditedReplyText}
                setNewComment={setNewComment}
                setShowReplied={setShowReplied}
                shotCommentDelete={deleteDiscussionComment}
                showReplies={showReplies}
                toggleReplies={toggleReplies}
                isDeleting={isDeleting}
                currentPageComment={currentPageComment}
                setCurrentPageComment={setCurrentPageComment}
                Replied={Replied}
              />
            )}
            {totalPages > 1 && currentPage <= totalPages - 1 && (
            <div
              className="p-5 cursor-pointer transition-transform duration-300 ease-in-out hover:scale-150"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <img
                className="justify-self-center"
                src={Images.down_arrow_icon}
                alt="load more"
              />
            </div>
          )}
          </div>
        )}
      </div>

      {ShowDiscussionModel && (
        <AddDiscussionModal
          HandleCancel={HandleCancel}
          HandlePublish={HandlePublish}
          isFormComplete={isFormComplete}
          handleChange={handleChange}
          inputFields={inputFields}
          isEditing={isEditing}
          category={category}
          getCategoryName={getCategoryName}
          setInputFields={setInputFields}
        />
      )}
    </div>
  )
}

export default DisscussionComponent

import React from 'react'
import { Images } from '../../../helpers'

const LocalDetailGrid = ({ data, type, baseImageUrl }) => {
  return (
    <div className="w-full !my-5 flex flex-row gap-3">
      <div className="w-1/2 h-full relative">
        <img
          className="rounded-2xl aspect-[2.2/1] w-full object-cover"
          alt="asdas"
          src={baseImageUrl + data.logo}
        />
        <div className="absolute w-full h-full rounded-2xl bg-backDrop top-0 left-0" />
        {type === 'Event' ? (
          <div className="absolute z-10 bottom-3 gap-2.5 left-4 flex flex-row items-center">
            <div className="bg-electricWhite rounded-xl px-3 py-2 flex flex-col content-center items-center">
              <span className="text-black text-[20px] font-black leading-none">05</span>
              <span className="text-black text-[14px] font-normal leading-none">May</span>
            </div>
            <div className="flex flex-col">
              <span className="text-white text-[28px] italic font-black leading-none">
                {data.name}
              </span>
              <span className="text-white text-[14px] italic font-bold leading-none">
                {data.address}
              </span>
            </div>
          </div>
        )
        : (
          <div className="absolute z-10 bottom-0 gap-2.5 pb-2 pl-4">
            <div className="flex flex-row items-center content-center gap-2.5">
              <div className="text-white text-[28px] italic font-black">
                {data.name}
              </div>
              <img
                className="h-[20px] w-[20px]"
                src={Images.FillStar}
                alt="rating"
              />
              <div className="font-normal text-white text-[20px]">
                0
              </div>
            </div>
            <div className="text-white text-[14px] font-bold">
              {data.address}
            </div>
          </div>
         )}
      </div>
      <div className="grid grid-cols-1 gap-2.5 w-1/2 auto-rows-auto overflow-hidden relative">
        {data.images.slice(0, 2).map((image, index) => {
          return (
            <div key={`${image}-${index}`} className="relative aspect-[4.78/1] rounded-2xl">
              <img
                className="w-full object-cover h-full rounded-2xl"
                alt={image}
                src={baseImageUrl + image}
              />
              <div className="absolute w-full h-full rounded-2xl bg-backDrop top-0 left-0" />
            </div>
          )
        })}
        <img
          src={Images.squre6}
          alt="squre"
          className="absolute !bottom-5 !right-5"
        />
      </div>
    </div>
  )
}

export default LocalDetailGrid

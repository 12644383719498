/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-tabs */
import React from '@babel/traverse'
import { useEffect, useRef, useState } from 'react'
import { s3ImageShots } from '../../../services/Service'
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal'
import ShotVideo from './ShotVideo'

const ShotItem = ({ text, item, index, setPlayingIndex, playingIndex, setUpdatingIndex, onClickEditPodcast, ShowConfirm, ShowConfirmModel, updatingIndex, deleteShot, handleCancel, delLoading }) => {
	const videoRefd = useRef(null)
	const [activeIndex, setActiveIndex] = useState(playingIndex)
	useEffect(() => {
		setActiveIndex(playingIndex)
	}, [playingIndex])
	return (
		<>
      <div
        className="relative scale-95 transition-all hover:scale-100"
        key={item.profileName}
        onMouseEnter={() => {
          setPlayingIndex(index)
        }}
        onMouseLeave={() => {
          setPlayingIndex(-1)
        }}
      >
        <ShotVideo
          ref={videoRefd}
          text={text}
          item={item}
          index={index}
          defaultPlay={activeIndex === index}
          key={item.attachment}
          lastPlayingIndex={activeIndex}
          onPlay={() => setPlayingIndex(index)}
          videoSrc={s3ImageShots + item.attachment}
          posterSrc={s3ImageShots + item.cover_attachment}
          videoClassName="!aspect-[9/16] bg-black"
          className="custom-video-player !h-full !aspect-[9/16] relative !overflow-hidden rounded-2xl"
        />
        <div className={`image-buttons px-2 z-50 !bottom-2 !top-[unset] !right-[unset] gap-2 items-center justify-between w-full ${activeIndex === index ? '!flex' : 'hidden'}`}>
          <span className={`text-sm font-semibold text-ColorGreen bg-ColorBGGreen rounded-full py-2 px-3 ${item.publish === 1 ? 'opacity-100' : 'opacity-0'}`}>Published</span>
          <div className="flex gap-2">
            <label>
              <button
                type="button"
                onClick={() => onClickEditPodcast(item)}
                className="d-none"
              />
            </label>
            <button
              type="button"
              className="p-0"
              onClick={() => {
                setUpdatingIndex(index)
                ShowConfirm(item.shot_id)
              }}
            />
          </div>
        </div>
      </div>
      <ConfirmationModal
        isVisible={ShowConfirmModel && updatingIndex === index}
        onConfirm={deleteShot}
        onCancel={handleCancel}
        isLoading={delLoading}
      />
		</>
	)
}

export default ShotItem

import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Images } from '../../helpers'
import { APICall, EndPoints } from '../../services'
import { s3ImageProfile } from '../../services/Service'

const TopDiscussionList = () => {
  const [topContributor, setTopContributor] = useState([])

  const fetchTopDiscussionData = () => {
    APICall('post', {}, `${EndPoints.topContributors}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTopContributor(res?.decryptedData?.data)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  useEffect(() => {
    fetchTopDiscussionData()
  }, [])
  return (
    <div className="flex flex-col !gap-2.5 !mt-2.5">
      {topContributor.map((data, i) => (
        <div key={i}>
          <div className="flex flex-col p-1.5">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-[14px]">
                <img className="w-[40px] h-[40px] rounded-full border-2 border-[#001597]" src={s3ImageProfile + data.profile_icon} alt="" />
                <p className="mb-0 text-[#0B0A0A] text-[14px] font-semibold">{data.user_name}</p>
              </div>
              <div className="flex flex-col gap-1 items-center justify-center">
                <img
                  className="w-[20px] h-[19px]"
                  src={Images.star_blue}
                  alt=""
                />
                <p className="mb-0 text-[#7A7474] text-[14px] font-normal leading-none">{data.total_comments}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
export default TopDiscussionList

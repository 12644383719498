/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { English, Images } from '../../../helpers'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import Loader from '../../Loader/Loader'
import { lockScroll, unlockScroll } from '../../../helpers/Utility'
import UploadingComponent from '../../UploadingComponent/UploadingComponent'

const CommonCMModal = ({
  step,
  totalStep,
  setStep,
  loading,
  handleCancel,
  title,
  children,
  onClickNext,
  nextBtnStatus,
  nextBtnLable,
  cancelBtnStatus,
  closeBtnStatus,
  isButtonLoading,
  isVideoUploading,
  uploadPercentage,
  stepClassName,
  buttonSticky
}) => {
  useEffect(() => {
    lockScroll()
    return () => {
      unlockScroll()
    }
  }, [])
  return (
    <div className="add-item-model">
      <div className="add-item-content local_type_modal !max-w-[1200px] !m-5 !max-h-[88vh]">
        {isVideoUploading && (
          <UploadingComponent uploadPercentage={uploadPercentage} />
        )}
        {isButtonLoading && <Loader />}
        <div className="flex items-center justify-between local_sticky_header">
          <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
            {title}
          </div>
          <button
            className="cancel_icon border-0 p-0"
            type="button"
            onClick={handleCancel}
            disabled={closeBtnStatus}
          >
            <img src={Images.RemoveLocal} alt="" />
          </button>
        </div>
        <div className={`${stepClassName} flex items-center justify-center bg-[#E4E4E480] rounded-[10px] py-2.5 !px-5 mt-3`}>
          <div className="bg-primaryGradient bg-clip-text text-transparent text-sm flex gap-1 items-end leading-none">
            <span className="font-black text-xl leading-none italic">
              0
              {step}
            </span>
            of 0
            {totalStep}
          </div>
        </div>
        {children}
        {/* <div className="local_button_row"> */}
        <div className={`${buttonSticky} local_button_row ${step === 1 ? '!justify-end' : '!justify-between'}`}>
          {step !== 1 && (
            <CommonButton
              label={English.A116}
              onClick={() => {
                setStep(step - 1)
              }}
              isPrimary={false}
              context={English.A197}
              disabled={cancelBtnStatus}
              className="!w-[150px] !py-[12px]"
            />
          )}
          <CommonButton
            // label={step === 4 ? English.G128 : English.G69}
            label={nextBtnLable}
            type="button"
            isPrimary
            onClick={() => {
              onClickNext()
            }}
            context={English.A197}
            disabled={nextBtnStatus}
            className="!w-[150px] !px-0 !py-[16px]"
          />
        </div>
      </div>
    </div>
  )
}

export default CommonCMModal

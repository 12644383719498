import React, { useEffect } from 'react'
import './CreatorProfile.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Feeds from '../Feeds/Feeds'
import GivewayComponent from '../AddGiveway/GivewayComponent'
import PollsComponent from '../AddPolls/PollsComponent'
import DisscussionComponent from '../AddDiscussions/DisscussionComponent'
import AwardsComponent from '../AddAwards/AwardsComponent'
import ProfileHomePage from './profileHomePage'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const CommunityBuilder = () => {
  useEffect(() => {
  window.scroll(0, 0)
  }, [])
  const { state } = useLocation()
  const TabsComponents = () => {
    return (
      <Tabs defaultIndex={state?.defaultIndex ? state?.defaultIndex : 0}>
        <TabList>
          <Tab>
            <img className="component_icon" src={Images.HomeIco} alt="home icon" />
            <span>{English.G222}</span>
          </Tab>
          <Tab>
            <img className="component_icon" src={Images.FeedIco} alt="feed icon" />
            <span>{English.G223}</span>
          </Tab>
          <Tab>
            <img className="component_icon" src={Images.GiveawayIco} alt="giveawy icon" />
            <span>{English.G230}</span>
          </Tab>
          <Tab>
            <img className="component_icon" src={Images.PollsIco} alt="poll icon" />
            <span>{English.G195}</span>
          </Tab>
          <Tab>
            <img className="component_icon" src={Images.DiscussionIco} alt="discussions icon" />
            <span>{English.G207}</span>
          </Tab>
          {/* <Tab>
            <img src={AwardsIco} alt="award icon" />
            Awards
          </Tab> */}
        </TabList>

        <TabPanel>
          <ProfileHomePage />
        </TabPanel>
        <TabPanel>
          <div>
            <Feeds />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <GivewayComponent />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <PollsComponent />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <DisscussionComponent />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <AwardsComponent />
          </div>
        </TabPanel>
      </Tabs>
    )
  }
  const navigate = useNavigate()
  const { id } = useParams()
  useEffect(() => {
    const handleBeforeUnload = () => {
      navigate(`/community-builder/${id}`)
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])
  return (
    <div className="creator-profile-main creator-profile-create">
      {/* <ProfileHeadCreator /> */}
      <div className="details-tab-view pt-0 mt-15">
        <div className="tabs-for-details-view">
          <TabsComponents />
        </div>
      </div>
    </div>
  )
}
export default CommunityBuilder

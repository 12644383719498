/* eslint-disable max-len */
import React, { useState, useEffect } from 'react'
import 'swiper/css'
import './GridImage.css'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import English from '../../helpers/English'
import CommonModalMain from '../CommonModalMain'
import { Images } from '../../helpers'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import { compressImage, dataDecrypt } from '../../services/http-services'
import { APICall, EndPoints } from '../../services'
import { s3ImagePostFeed } from '../../services/Service'
import Loader from '../Loader/Loader'
import { emitter, EventType } from '../../helpers/Emitter'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import AddFeedModal from '../Feeds/Component/AddFeedModal'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'

const GridImage = () => {
  const app_id = useSelector((state) => state.user?.app_id)
  const [addModalModel, setAddModalModel] = useState(false)
  const [addFeedModalModel, setAddFeedModalModel] = useState(false)
  // const [selectedImages, setSelectedImages] = useState([])
  const [inputFields, setInputFields] = useState({
    attachment: ''
  })
  // eslint-disable-next-line no-unused-vars
  const [getImage, setGetImage] = useState([])
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [addLoading, setAddLoading] = useState(false)

  const [imageObj, setImageObj] = useState()
  const [images, setImages] = useState()
  const [deleteImage, setDeleteImage] = useState('')

  const ShowConfirm = (post_id) => {
    setDeleteImage(post_id)
    setShowConfirmModel(true)
  }

  const ResetValue = () => {
    setInputFields({
      attachment: ''
    })
  }

  const HandleCancelConfirm = () => {
    setShowConfirmModel(false)
    setUpdatingIndex(null)
  }

  const fetchPhotos = (delay = 4000) => {
    setTimeout(() => {
      // getPostData(postCurrentPage)
      emitter.emit(EventType.ProfileUpdate)
    }, delay)
  }

  const addNewImage = () => {
    // setAddModalModel(true);
    // emitter.emit(EventType.feedAddModal)
    setAddFeedModalModel(true)
  }

  const onPressClose = () => {
    setAddModalModel(false)
    ResetValue()
  }

  const onPressFeedClose = () => {
    setAddFeedModalModel(false)
  }

  const handleImageChanges = (file) => {
    const maxFileSize = 8 * 1024 * 1024

    if (file) {
      if (file.size > maxFileSize) {
        toast.error('Image size should not exceed 8 MB')
        return
      }

      const imageUrl = URL.createObjectURL(file)
      setImages(imageUrl)
      setImageObj(file)
      // setIsFormModified(true)
      // validateFields({ ...inputFields, attachment: file })
    }
  }

  const getPostData = (postCurrentPage) => {
    // setIsLoading(true)
    const data = {
      app_id: app_id.id
      // creator_user_id: params.id
    }
    APICall('post', data, `${EndPoints.getPost}?page=${postCurrentPage}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const list = res?.decryptedData.data
          setGetImage(postCurrentPage === 1 ? list : [...getImage, ...list])
          setpostCurrentPage(res?.decryptedData.current_page)
          setpostTotalPages(res?.decryptedData.last_page)
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const [isLoading, setIsLoading] = useState(false)
  const [postCurrentPage, setpostCurrentPage] = useState(1)
  const [postTotalPages, setpostTotalPages] = useState(1)

  useEffect(() => {
    setIsLoading(true)
    getPostData(postCurrentPage)
  }, [])

  const handlePageChange = (pageNumber) => {
    getPostData(pageNumber)
    setpostCurrentPage(pageNumber)
  }

  const deletePhoto = () => {
    const data = {
      app_id: app_id.id,
      post_id: deleteImage
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.deletePost}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          toast.success(res?.data?.message)
          setShowConfirmModel(false)
          fetchPhotos()
          const temp = [...getImage]
          const newImages = temp.filter((item) => item.post_id !== deleteImage)
          setGetImage(newImages)
          setDeleteImage('')
          setLoading(false)
        } else {
          setLoading(false)
          setDeleteImage('')
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        setDeleteImage('')
        toast.error(error?.data?.message)
      })
  }

  const handleSubmit = async () => {
    setAddLoading(true)
    const imageFile = await compressImage(imageObj)
    const coverFormData = new FormData()
    coverFormData.append('file', imageFile)
    coverFormData.append('type', 'post')
    const res = await APICall(
      'post',
      coverFormData,
      EndPoints.uploadImage,
      'creator',
      true
    )
    if (res.status === 200 && res.data?.code === 200) {
      const decryptedCoverData = dataDecrypt(res.data.data)
      const data = {
        app_id: app_id.id,
        attachment: decryptedCoverData
      }
      APICall('post', data, `${EndPoints.addPost}`, 'creator')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setTimeout(() => {
              ResetValue()
              getPostData()
              setAddLoading(false)
              setAddModalModel(false)
              toast.success(res?.data?.message)
            }, 2500)
          } else {
            setAddLoading(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setAddLoading(false)
          toast.error(error?.data?.message)
        })
    } else {
      toast.error(res?.data?.message || 'Error uploading image')
    }
  }

  return (
    <div className="stories-component add-stories mt-4">
      {isLoading && <Loader />}
      {getImage?.length === 0 ? (
        <CommonEmptyData
          image={Images.GridImage_icon}
          mainTitle={English.A204}
          descriptionText={English.A205}
          onClick={addNewImage}
          addIcon={Images.plusrounded}
          btnText={English.G216}
        />
      ) : (
        <>
          <div>
            <div className="add-video-rows main_btn">
              <CommonButton
                label="Upload Photo"
                imgSrc={Images.plusrounded}
                onClick={addNewImage}
                isPrimary
                className="add_new_content"
              />
            </div>
          </div>
          <div className="photos-grid-container">
            {getImage?.map((image, index) => {
              return (
                <div
                  className="photos-grid-item-added overflow-hidden"
                  key={image.post_id}
                >
                  <img
                    key={index}
                    src={s3ImagePostFeed + image.attachment}
                    alt={`Uploaded ${index}`}
                    className="w-full aspect-square rounded-[5px] object-cover"
                  />
                  <div className="image-buttons">
                    <button
                      type="button"
                      onClick={() => {
                          setUpdatingIndex(index)
                          ShowConfirm(image.post_id)
                        }}
                    />
                    {console.log(image.schedule)}
                  </div>
                  {(image.schedule && image.schedule !== '0000-00-00 00:00:00') &&
                    <div className="absolute bottom-0 right-0 bg-primaryGradient rounded-tl-full font-normal text-white text-[10px] px-2">
                      {dayjs(image.schedule, 'YYYY-MM-DD HH:mm:ss').format('DD MMM, hh:mm a')}
                    </div>}
                  <ConfirmationModal
                    isVisible={ShowConfirmModel && updatingIndex === index}
                    onConfirm={deletePhoto}
                    onCancel={HandleCancelConfirm}
                    // isLoading={delLoading}
                  />
                  {loading && updatingIndex === index && <Loader />}
                </div>
              )
            })}
          </div>
          {(postTotalPages > 1 && postCurrentPage <= (postTotalPages - 1)) && (
            <div className="p-5 cursor-pointer transition-transform duration-300 ease-in-out hover:scale-150" onClick={() => handlePageChange(postCurrentPage + 1)}>
              <img className="justify-self-center" src={Images.down_arrow_icon} alt="load more" />
            </div>
          )}
        </>
      )}
      {addModalModel && (
        <CommonModalMain
          showCross
          title="Upload Grid Photos"
          isPrevious={English.G116}
          isNext={English.G117}
          onPressClose={onPressClose}
          // className={className}
          onPressSave={handleSubmit}
          onPressBack={onPressClose}
          showFooter
        >
          {addLoading && <Loader />}
          <div className="w-full h-full flex flex-col gap-5 justify-between">
            <div className="input-image-show">
              {/* {renderImg} */}
              {inputFields.attachment.length < 1 && (
                <div className="input-img-label">
                  <CommonImageUpload
                    handleImageChange={handleImageChanges}
                    buttonText="Add Image"
                    selectedImg={images}
                    // selectedImg=""
                    defaultImg={Images.Plus}
                    className="plsimg border !border-[#303030] !h-[180px] !w-[180px]"
                  />
                </div>
              )}
            </div>
            <div className="flex items-center gap-2">
              <p className="text-[#0B0A0A] font-black italic text-[14px] uppercase">
                File formats
              </p>
              <p className="text-[#7A7474] text-[14px] font-normal">
                Recommended: “.JPG”. Other major formats are supported.
              </p>
            </div>
          </div>
        </CommonModalMain>
      )}
      {addFeedModalModel && (
        <AddFeedModal
          onSubmited={() => {
            setTimeout(() => {
              handlePageChange(1)
            }, 3000)
          }}
          onPressClose={onPressFeedClose}
          setAddModalModel={setAddFeedModalModel}
        />
      )}
    </div>
  )
}
export default GridImage

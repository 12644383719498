/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'
import { debounce } from 'lodash'
import { X } from 'lucide-react'
import { lockScroll, unlockScroll } from '../../helpers/Utility'
import CommonInput from '../InputComponet/CommonInput'
import { APICall, EndPoints } from '../../services'
import { English, Images } from '../../helpers'
import ContentMangerCommonTitle from './SubComponent/ContentMangerCommon.js/ContentMangerCommonTitle'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import Dropdown from '../Dropdown/Dropdown'
import DiscussionAddModal from './Modal/DiscussionAddModal'
import DiscussionDetailPage from './DiscussionDetailPage'
import Pagination from '../Pagination/Pagination'
import DisscussionItem from '../AddDiscussions/DisscussionItem'

const DiscussionTab = () => {
  const componentRef = useRef()
  const [ShowDiscussionModel, setShowDiscussionModel] = useState(false)
  const [ShowPreviewDiscussionModel, setShowPreviewDiscussionModel] =
    useState(false)
  const [inputFields, setInputFields] = useState({
    topic: '',
    description: '',
    app_id: [],
    category: ''
  })
  const [discussionData, setDiscussionData] = useState([])
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [delDiscussionDataId, setDelDiscussionId] = useState()
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [expandedIndex, setExpandedIndex] = useState(null)
  const [editData, setEditData] = useState(null)
  const [particularDiscussionData, setParticularDiscussionData] = useState()
  const [particularDiscussion, setParticularDiscussion] = useState(false)
  const [discussionCurrentPage, setDiscussionCurrentPage] = useState(1)
  const [discussionTotalPages, setDiscussionTotalPages] = useState(1)
  const [searchText, setSearchText] = useState('')

  const navigate = useNavigate()
  const handleItemClick = (videoData) => {
    // setParticularDiscussion(true)
    // setParticularDiscussionData(videoData)
    navigate('/discussion-detail', { state: { data: videoData } })
  }

  const clearSearch = () => {
    setSearchText('')
    handleSearch2('')
  }

  const handleSearch = (value) => {
    setSearchText(value)
    handleSearch2(value)
  }

  const handleSearch2 = useCallback(
    debounce(
      (inputVal) => getDiscussionLibraryData(inputVal, discussionCurrentPage),
      500
    ),
    []
  )
  const ShowCommunityGiveaway = () => {
    setEditData(null)
    onBack()
    clearValue()
    setShowDiscussionModel(true)
  }

  const ShowConfirm = (discussion_id) => {
    setDelDiscussionId(discussion_id)
    setShowConfirmModel(true)
  }

  const handleCancel = () => {
    setShowConfirmModel()
    clearValue()
  }

  const handleCancelModal = () => {
    clearValue()
    setShowDiscussionModel(false)
    setShowPreviewDiscussionModel(false)
  }

  const onBack = () => {
    setEditData(null)
    clearValue()
    setParticularDiscussionData(null)
    setParticularDiscussion(false)
  }

  const clearValue = () => {
    setInputFields({
      ...inputFields,
      topic: '',
      description: '',
      app_id: [],
      category: ''
    })
  }

  const getDiscussionLibraryData = (search, discussionCurrentPage) => {
    setIsLoading(true)
    const data = {
      search
    }
    APICall(
      'post',
      data || {},
      `${EndPoints.getDiscussionLibrary}?page=${discussionCurrentPage}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setDiscussionData(res?.decryptedData.data)
          setDiscussionTotalPages(res?.decryptedData?.last_page)
          setDiscussionCurrentPage(res?.decryptedData?.current_page)
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const deleteDiscussion = () => {
    const data = {
      discussion_id: delDiscussionDataId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.deleteDiscussion}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setTimeout(() => {
            getDiscussionLibraryData(null, discussionCurrentPage)
          }, 3000)
          setLoading(false)
          toast.success(res?.data?.message)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const onClickEditDiscussion = (videoData) => {
    setShowDiscussionModel(true)
    setEditData(videoData)
    setInputFields({
      ...inputFields,
      topic: videoData.topic,
      description: videoData.description,
      app_id: videoData.app_data,
      discussion_id: videoData.discussion_id,
      tags: videoData.tags
    })
  }

  useEffect(() => {
    getDiscussionLibraryData(null, discussionCurrentPage)
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowDiscussionModel(false)
        setShowPreviewDiscussionModel(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (ShowDiscussionModel || ShowPreviewDiscussionModel) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [ShowDiscussionModel, ShowPreviewDiscussionModel])

  const discussionHandlePageChange = (pageNumber) => {
    getDiscussionLibraryData(null, pageNumber)
    setDiscussionCurrentPage(pageNumber)
  }

  return (
    <div className="content-manager-discussions-wrapper">
      {!particularDiscussion ? (
        <div>
          <div className="sticky_bar !pb-[2px] z-10 bg-white">
            <ContentMangerCommonTitle
              title={English.G207}
              buttonName={discussionData?.length === 0 ? null : English.A213}
              onButtonClick={
                discussionData?.length === 0 ? null : ShowCommunityGiveaway
              }
              className="!mb-0"
            />
            <div className="add-video-row">
              <div className="items-filter justify-between w-full">
                <div className="position-relative input-position">
                  <CommonInput
                    placeholder="Search Discussions"
                    className="!text-black"
                    onChange={(e) => {
                    handleSearch(e.target.value)
                  }}
                    value={searchText}
                  />
                  {searchText && (
                  <button
                    type="button"
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-black"
                    onClick={clearSearch}
                  >
                    <X className="w-5 h-5" />
                  </button>
                )}
                </div>
                <div className="flex flex-row gap-2">
                  <div className="drop-row !mb-0 gap-4">
                    <div className="drop-sub-row pointer-events-none opacity-50">
                      <Dropdown
                        title="Categories"
                        Data={[
                        { title: 'Scaloo' },
                        { title: 'Spacure' },
                        { title: 'PETZINO' },
                        { title: 'A-Z FIT' },
                        { title: 'TESTIEE' }
                      ]}
                        customClass="content-filter disabledDropdown"
                      />
                      <Dropdown
                        title="Ratings"
                        Data={[
                        { title: 'Scaloo' },
                        { title: 'Spacure' },
                        { title: 'PETZINO' },
                        { title: 'A-Z FIT' },
                        { title: 'TESTIEE' }
                      ]}
                        customClass="content-filter disabledDropdown"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {discussionData.length === 0 && !isLoading && (
            <CommonEmptyData
              image={Images.discussion_icon}
              mainTitle={English.B118}
              descriptionText={English.B119}
              onClick={() => ShowCommunityGiveaway()}
              addIcon={Images.plusrounded}
              btnText={English.A213}
              className="!bg-[#FAFAFA] !border-solid"
              classNameDescription="font-normal text-[#7A7474] text-[16px]"
            />
          )}
          {isLoading ? (
            <div className="discussions-item position-relative">
              <div className="discussion-profile">
                <Skeleton className="discssion-profile-image border-0" />
                <div className="discssion-detail">
                  <h5>
                    <Skeleton max-width={700} height={20} />
                  </h5>
                  <span className="mb-3">
                    <Skeleton max-width={200} height={10} />
                  </span>
                  <p>
                    <Skeleton max-width={700} height={30} />
                  </p>
                </div>
              </div>
              <div className="discussion-group">
                <Skeleton circle width={30} height={30} />
              </div>
              <div className="discussion-comment">
                <Skeleton width={25} height={25} />
                <Skeleton width={25} />
              </div>
              <div className="discussion-button">
                <button type="button">
                  <Skeleton width={10} height={35} />
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col !gap-4">
              {
                discussionData.map((item, index) => {
                  return (
                    <DisscussionItem
                      ShowConfirm={ShowConfirm}
                      ShowConfirmModel={ShowConfirmModel}
                      deleteDiscussion={deleteDiscussion}
                      expandedDiscussion={null}
                      expandedIndex={expandedIndex}
                      handleCancels={handleCancel}
                      index={index}
                      item={item}
                      loading={loading}
                      onClickComment={() => handleItemClick(item)}
                      onClickEditDiscussion={onClickEditDiscussion}
                      setExpandedIndex={setExpandedIndex}
                      setUpdatingIndex={setUpdatingIndex}
                      updatingIndex={updatingIndex}
                      key={index}
                    />
                  )
                })
              }
            </div>
          )}
          {discussionTotalPages > 1 && (
            <Pagination
              currentPage={discussionCurrentPage}
              totalPages={discussionTotalPages}
              onPageChange={discussionHandlePageChange}
            />
          )}
        </div>
      ) : (
        <DiscussionDetailPage
          onBack={onBack}
          data={particularDiscussionData}
          onClickEdit={() => onClickEditDiscussion(particularDiscussionData)}
          onClickAdd={() => ShowCommunityGiveaway()}
        />
      )}

      {ShowDiscussionModel && (
        <DiscussionAddModal
          handleCancel={handleCancelModal}
          editData={editData}
          onSubmit={() => {
            clearValue()
            getDiscussionLibraryData(null, discussionCurrentPage)
            handleCancelModal()
          }}
        />
      )}

      {/* <div className="link_view">
        <Link onClick={handleViewAll}>
          {showAll ? English.G193 : English.G194}
        </Link>
      </div> */}
    </div>
  )
}
export default DiscussionTab

/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import CommonInput from '../../InputComponet/CommonInput'
import CommonEmptyData from '../../CommonNoDataModal/CommonEmptyData'
import { English, Images } from '../../../helpers'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import { s3ImagePodcast } from '../../../services/Service'
import CommonCMModal from './CommonCMModal'
import VideoPlayer from '../CommonVideoPlayer'
import Dropdown from '../../Dropdown/Dropdown'
import {
  capitalizeFirstLetter,
  delay,
  formatDurationPodcast
} from '../../../helpers/Utility'
import { APICall, EndPoints } from '../../../services'
import PlatformSelect from './PlatformSelect'
import {
  compressImage,
  compressVideo,
  dataDecrypt
} from '../../../services/http-services'

const PodcastAddModal = ({ handleCancel, editData, onSubmit }) => {
  const [selectedAudio, setSelectedAudio] = useState('')
  const [selectedAudioObj, setSelectedAudioObj] = useState('')
  const [selectedFilePath, setSelectedFilePath] = useState('')
  const [selectedFileObj, setSelectedFileObj] = useState('')
  const [appData, setAppData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [hostName, setHostName] = useState([])
  const [inputFields, setInputFields] = useState({
    title: '',
    description: '',
    minutes: '',
    attachment: '',
    cover_attachment: '',
    app_id: [],
    host_id: 0,
    search: ''
  })

  const audioRef = useRef(null)
  const progressRef = useRef(null)
  const ffmpegRef = useRef()

  const [isPlaying, setIsPlaying] = useState(false)
  const [progress, setProgress] = useState(0)
  //   const [duration, setDuration] = useState(0);
  const [isMuted, setIsMuted] = useState(false)
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [isVideoUploading, setIsVideoUploading] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  useEffect(() => {
    if (editData) {
      setInputFields({
        title: editData.title || '',
        description: editData.description || '',
        minutes: editData.minutes || '',
        attachment: editData.attachment || '',
        cover_attachment: editData.cover_attachment || '',
        app_data: editData.app_data || 2,
        host_id: editData.host_id,
        podcast_id: editData.podcast_id
      })
      setSelectedAudio(s3ImagePodcast + editData.attachment)
      setSelectedFilePath(s3ImagePodcast + editData.cover_attachment)
      console.log(editData)
    }
  }, [editData])

  const toggleMute = () => {
    audioRef.current.muted = !audioRef.current.muted
    setIsMuted(audioRef.current.muted)
  }

  const handleProgressClick = (e) => {
    if (!audioRef?.current || !progressRef.current) return

    try {
      const rect = progressRef.current.getBoundingClientRect()
      const clickX = e.clientX - rect.left
      const barWidth = rect.width
      const clickProgress = clickX / barWidth
      const newTime = Number(audioRef?.current?.duration) * clickProgress
      audioRef.current.currentTime = newTime
    } catch (e) {
      console.log(e)
    }
  }

  const formatTime = (time) => {
    const mins = Math.floor(time / 60)
      .toString()
      .padStart(2, '0')
    const secs = Math.floor(time % 60)
      .toString()
      .padStart(2, '0')
    return `${mins}:${secs}`
  }

  const togglePlayPause = () => {
    if (!audioRef.current) return

    if (audioRef?.current?.paused) {
      audioRef.current.play()
      setIsPlaying(true)
    } else {
      audioRef.current.pause()
      setIsPlaying(false)
    }
  }

  const handleSeek = (time) => {
    if (audioRef?.current) {
      audioRef.current.currentTime = Math.max(0, Math.min(audioRef.current.duration, audioRef.current.currentTime + time))
    }
  }

  useEffect(() => {
    const audio = audioRef.current
    if (!audio) return

    const handlePlay = () => setIsPlaying(true)
    const handlePause = () => setIsPlaying(false)

    audio.addEventListener('play', handlePlay)
    audio.addEventListener('pause', handlePause)

    return () => {
      audio.removeEventListener('play', handlePlay)
      audio.removeEventListener('pause', handlePause)
    }
  }, [])

  const clearValue = () => {
    setSelectedFilePath('')
    setSelectedFileObj('')
    setSelectedAudio('')
    setSelectedAudioObj('')
    setInputFields({
      ...inputFields,
      title: '',
      description: '',
      minutes: '',
      attachment: '',
      cover_attachment: '',
      app_id: '',
      podcast_id: ''
    })
    setStep(1)
  }

  const handleCancelClick = () => {
    handleCancel()
    clearValue()
    if (ffmpegRef.current) {
      ffmpegRef.current.terminate()
    }
  }

  const podcastHostApiCall = () => {
    APICall('post', {}, `${EndPoints.hostList}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setHostName(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handlePublishClick = async () => {
    if (audioRef?.current?.duration) {
      const durationInSeconds = Math.trunc(audioRef?.current?.duration || 0)
      const formattedDuration = formatDurationPodcast(durationInSeconds)
      inputFields.minutes = formattedDuration
    }

    setLoading(true)

    try {
      if (selectedFileObj) {
        const imageFile = await compressImage(selectedFileObj)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'podcast')

        await APICall(
          'post',
          coverFormData,
          EndPoints.uploadImage,
          'creator',
          true
        )
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedCoverData = res?.decryptedData
              inputFields.cover_attachment = decryptedCoverData
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((e) => {
            toast.error(e?.data?.message)
          })
      }

      if (selectedAudioObj) {
        const audioFile = await compressVideo(
          selectedAudioObj,
          setUploadPercentage,
          setIsVideoUploading
        )
        const audioFormData = new FormData()
        audioFormData.append('file', audioFile)
        audioFormData.append('type', 'podcast')

        await APICall(
          'post',
          audioFormData,
          EndPoints.uploadImage,
          'creator',
          true,
        )
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedAudioData = dataDecrypt(res.data.data)
              inputFields.attachment = decryptedAudioData
              setIsButtonLoading(true)
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((e) => {
            toast.error(e?.data?.message)
          })
      }

      await delay(5000)
      await podcastAddApiCall()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.message || 'An error occurred')
    }
  }

  const podcastAddApiCall = async () => {
    setIsButtonLoading(true)
    let data = {
      title: inputFields.title,
      description: inputFields.description,
      attachment: inputFields.attachment,
      cover_attachment: inputFields.cover_attachment,
      minutes: inputFields?.minutes,
      app_id: inputFields?.app_data.map((d) => d.id),
      host_id: inputFields.host_id
    }
    if (editData) {
      data = {
        title: inputFields.title,
        description: inputFields.description,
        attachment: inputFields.attachment,
        cover_attachment: inputFields.cover_attachment,
        app_id: inputFields?.app_data.map((d) => d.id),
        podcast_id: inputFields.podcast_id,
        minutes: inputFields?.minutes,
        host_id: inputFields.host_id
      }
    }
    try {
      const res = await APICall(
        'post',
        data,
        `${editData ? EndPoints.editPodcast : EndPoints.addPodcast}`,
        'contentManager'
      )
      if (res.status === 200 && res.data?.code === 200) {
        await delay(4000)
        setLoading(false)
        clearValue()
        handleCancel()
        onSubmit(inputFields.podcast_id)
        setIsButtonLoading(false)
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    } catch (error) {
      toast.error(error?.data?.message)
    }
  }

  const [step, setStep] = useState(1)

  const handleFileChange = (file) => {
    const maxFileSizeMB = 10
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024
    if (file.size > maxFileSizeBytes) {
      toast.error(`File size exceeds ${maxFileSizeMB} MB`)
      return
    }
    const reader = new FileReader()
    reader.onload = (e) => {
      setSelectedFilePath(e.target.result)
      setSelectedFileObj(file)
    }
    reader.readAsDataURL(file)
  }

  const handleAudioChange = (event) => {
    const maxFileSizeMB = 500
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024
    const maxDurationMinutes = 90
    const fileInput = event.target
    const selectedFile = fileInput.files[0]

    if (selectedFile) {
      if (selectedFile.size > maxFileSizeBytes) {
        toast.error(`Podcast file size exceeds ${maxFileSizeMB} MB`)
        return
      }
      const audio = document.createElement('audio')
      audio.src = URL.createObjectURL(selectedFile)
      audio.onloadedmetadata = () => {
        const durationMinutes = audio.duration / 60
        if (durationMinutes > maxDurationMinutes) {
          toast.error(`Podcast duration exceeds ${maxDurationMinutes} minutes`)
          return
        }
        setSelectedAudioObj(selectedFile)
        setSelectedAudio(audio.src)
      }
    }
  }

  useEffect(() => {
    podcastHostApiCall()
  }, [])

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const getCategoryName = (categoryId) => {
    const categoryObj = hostName.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.host : English.G181
  }

  const podcastContent = useMemo(() => {
    return (
      <div className="item-details-input !w-full mt-3">
        <div>
          <label>{English.G161}</label>
          <CommonInput
            name="title"
            placeholder={English.G162}
            onChange={handleChange}
            value={capitalizeFirstLetter(inputFields.title)}
          />
        </div>
        <div className="mb-3">
          <label>{English.G163}</label>
          <Dropdown
            title={getCategoryName(inputFields.host_id)}
            onChanged={(d) => {
              setInputFields({
                ...inputFields,
                host_id: d.id
              })
            }}
            Data={hostName.map((d) => ({
              title: d.host,
              id: d.id
            }))}
            name="host_id"
            customClass={
              inputFields.host_id ? 'selected-class' : 'giveaway-dropdown'
            }
          />
        </div>
        <div>
          <label>{English.G112}</label>
          <CommonInput
            placeholder={English.G83}
            name="description"
            onChange={handleChange}
            value={inputFields.description}
            isTextArea
          />
        </div>
      </div>
    )
  }, [inputFields, handleChange, hostName])

  useEffect(() => {
    if (step !== 3 && audioRef?.current) {
      audioRef.current.pause()
      setIsPlaying(false)
    }
  }, [step])

  const audioUploadStep = useMemo(() => {
    return (
      <div className="!mt-5 rounded-[15px]">
        {step === 1 && (
          <div className="upload-input">
            <CommonImageUpload
              handleImageChange={handleFileChange}
              index={0}
              buttonText={English.G154}
              className="!bg-PrimaryBg !border-1 !border-SecondaryBorder rounded-2xl !w-full"
              selectedImg={selectedFilePath}
              defaultImg=""
              forImage
              aspect={1 / 1}
              forName="Podcast"
            />
          </div>
        )}
        {step === 2 && (
          <div className="video-wrapper modal-video relative gap-3 max-h-[calc(100vh-300px)] overflow-auto no-scrollbar">
            <div
              className={`video-wrapper modal-video flex flex-row relative gap-3 ${step !== 2 && '!hidden'}`}
            >
              <div className="w-[60%]">
                <div>
                  <CommonInput
                    type="file"
                    accept="audio/*"
                    id="upload-audio"
                    onChange={handleAudioChange}
                    className="d-none"
                  />
                  {!selectedAudio ? (
                    <CommonEmptyData
                      image={Images.FileUpload}
                      mainTitle={English.G160}
                      descriptionText={English.B128}
                      onClick={() => document.getElementById('upload-audio').click()}
                      addIcon={Images.FileUploadWhite}
                      btnText={English.A256}
                      className="!pb-2 pt-0"
                      classNameDescription="!text-[14px] !font-normal"
                    />
                  ) : (
                    <>
                      <div className="flex justify-between">
                        <div className="flex gap-2 items-center">
                          <img
                            className="rounded-[5px] h-[79px] w-[79px]"
                            src={selectedFilePath}
                            alt=""
                          />
                          <div className="flex flex-col">
                            <div className="flex gap-2 items-center">
                              <img className="cursor-pointer" src={Images.previous_btn_new} alt="" onClick={() => handleSeek(-5)} />
                              <div
                                onClick={togglePlayPause}
                                className="text-white !p-0 flex items-center justify-center !border-none focus:outline-none"
                              >
                                {isPlaying ? (
                                  <img
                                    className="h-full w-full cursor-pointer"
                                    src={Images.PodcastPauseIcoNew}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    className="h-full w-full cursor-pointer"
                                    src={Images.PodcastPlayIcoNew}
                                    alt=""
                                  />
                                )}
                              </div>
                              <img className="cursor-pointer" src={Images.Next_btn_new} alt="" onClick={() => handleSeek(5)} />
                            </div>
                          </div>
                        </div>
                        <div className="content-center">
                          <div className="flex justify-end gap-2 w-48">
                            <div
                              onClick={toggleMute}
                              className="content-center"
                              type="button"
                            >
                              {isMuted ? (
                                <img src={Images.podcast_mute_new} alt="" />
                              ) : (
                                <img src={Images.podcast_volume_new} alt="" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <div
                          ref={progressRef}
                          onClick={handleProgressClick}
                          className="relative w-full h-2 bg-[#E1E2E5] rounded cursor-pointer mb-1"
                        >
                          <div
                            className="absolute top-0 left-0 h-2 bg-[#1a1f87] rounded"
                            style={{ width: `${progress}%` }}
                          />
                        </div>

                        <div className="flex justify-between text-xs text-gray-500">
                          <span>
                            {formatTime(audioRef.current?.currentTime || 0)}
                          </span>
                          <span>
                            {formatTime(audioRef.current?.duration || 0)}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  {podcastContent}
                </div>
              </div>
              <div className="video_mb! aspect-square w-[40%]">
                <VideoPlayer
                  ref={audioRef}
                  key={selectedAudio}
                  videoSrc={selectedAudio}
                  posterSrc={selectedFilePath}
                  onProgres={setProgress}
                  videoClassName="!aspect-square"
                  className="custom-video-player !aspect-square relative !overflow-hidden rounded-2xl"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }, [
    selectedAudio,
    selectedFilePath,
    step,
    progress,
    isMuted,
    podcastContent
  ])

  const contentEnaled = () => {
    return !(
      inputFields.title &&
      inputFields.host_id &&
      inputFields.description &&
      selectedFilePath &&
      selectedAudio
    )
  }

  const appDataSelect = useMemo(() => {
    return (
      <PlatformSelect
        key={step}
        defaultIds={editData?.app_data}
        step={step}
        onSelectedIds={(data) => {
          setAppData(data)
          console.log(data)
        }}
      />
    )
  }, [])

  useEffect(() => {
    if (appData) {
      setInputFields({
        ...inputFields,
        app_data: appData
      })
    }
  }, [appData])

  return (
    <CommonCMModal
      setStep={setStep}
      totalStep={3}
      step={step}
      handleCancel={handleCancelClick}
      loading={loading}
      stepClassName={step === 2 ? 'podcast_sticky' : ''}
      buttonSticky={step === 2 ? 'podcast_button_sticky' : ''}
      isButtonLoading={isButtonLoading}
      isVideoUploading={isVideoUploading}
      uploadPercentage={uploadPercentage}
      nextBtnLable={step === 3 ? English.G128 : English.G69}
      nextBtnStatus={
        step === 1
          ? !selectedFilePath
          : step === 2
            ? !selectedAudio && contentEnaled()
            : inputFields?.app_data?.length === 0 || loading
      }
      title="Add Podcast"
      cancelBtnStatus={loading}
      onClickNext={() => {
        if (step === 3) {
          handlePublishClick()
        } else {
          setStep(step + 1)
        }
      }}
    >
      {audioUploadStep}
      {step === 3 && appDataSelect}
    </CommonCMModal>
  )
}

export default PodcastAddModal

import React from 'react'
import CommonInput from '../InputComponet/CommonInput'
import { English, Images } from '../../helpers'
import CommonButton from '../ButtonComponent/ButtonCommon'

const PollOptionView = ({
  onOptionChange,
  options,
  onPressRemoveOptions,
  onPressAddMoreOption
}) => {
  return (
    <div className="w-full">
      <div className="">
        {options.map((item, index) => {
          if (item.isDelete) {
            return
          }
          return (
            <div key={index}>
              <label className="text-mainColor text-sm text mb-2.5 block">{`Option${index + 1}`}</label>
              <div className="item-details-input pt-0 flex items-center !gap-2">
                <CommonInput
                  type="text"
                  className="w-full bg-lightBg rounded-[35px] px-3.5 py-3"
                  name="options"
                  placeholder={English.B129}
                  value={item.option}
                  onChange={(e) => {
                    onOptionChange(e.target.value, 'options', index)
                  }}
                />
                {options.length > 2 && index > 1 && (
                  <img
                    src={Images.Delete}
                    alt="error-icon-delete"
                    className="cursor-pointer !mb-[20px]"
                    onClick={(e) => {
                      e.stopPropagation()
                      onPressRemoveOptions(index)
                    }}
                  />
                )}
              </div>
            </div>
          )
        })}
        {options.filter((it) => !it?.isDelete).length < 4 && (
          <CommonButton
            type="button"
            label={English.A238}
            disabled={false}
            context="outline-primary"
            className="!italic uppercase !py-3 !px-3 mx-auto !w-full !rounded-full"
            spanClassName="!italic uppercase text-2xl bg-primaryGradient bg-clip-text text-transparent !font-black !pl-3"
            imgSrc={Images.plusGiveaway}
            onClick={(e) => {
              e.stopPropagation()
              onPressAddMoreOption()
            }}
          />
        )}
      </div>
    </div>
  )
}

export default PollOptionView

/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react'
import { English, Images } from '../../../helpers'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import { capitalizeFirstLetter } from '../../../helpers/Utility'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import Loader from '../../Loader/Loader'

const ContactImagePopup = ({
  onChange,
  // onCancel,
  onSubmit,
  disabled,
  handleBackClick,
  handleCancels,
  onUploadImage,
  text,
  s3Image,
  editFrom,
  renderImg,
  ImagesData,
  contactImage0,
  contactImage1,
  contactName1,
  contactName2,
  contactDesignation1,
  contactDesignation2,
  isLoader
}) => {
  return (
    <div className="add-item-model">
      {isLoader && (
      <Loader />
      )}
      <div className="add-item-content local_type_modal !max-w-[1200px] !m-5">
        <div className="flex items-center justify-between local_sticky_header">
          <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
            <img
              className="cursor-pointer"
              onClick={handleBackClick}
              src={Images.BackAerrow}
              alt="Back"
            />
            Add Location
          </div>
          <button
            className="cancel_icon border-0 p-0"
            type="button"
            onClick={handleCancels}
          >
            <img src={Images.RemoveLocal} alt="Close" />
          </button>
        </div>

        <div className="flex items-center justify-between bg-[#E4E4E480] rounded-[10px] py-2.5 !px-5 mt-3">
          <div className="text-main test-base font-bold">
            {text === 'Hotel'
              ? 'Hotel Details'
              : text === 'Restaurant'
                ? 'Restaurant Details'
                : text === 'Organizer'
                  ? 'Organizer Details'
                  : ''}
          </div>
          <div className="bg-primaryGradient bg-clip-text text-transparent text-sm flex gap-1 items-end leading-none">
            <span className="font-black text-xl leading-none italic">04</span>
            of 04
          </div>
        </div>
        <div className="item-details-input pt-0 local_contact_scroll_bars !mt-4">
          <div
            className={
                editFrom
                    ? 'h-[calc(80vh-220px)] overflow-y-auto sticky top-0'
                    : 'grid grid-cols-2 !gap-5'
                }
          >
            {!editFrom && (
            <div>
              <p className="mb-0 !text-start !text-base !font-bold !text-[#303030]">Contact Person</p>
              <div className="logo_btn mb-2.5">
                <div className="image_upload_section">
                  <div className="add_photos">
                    <div className="add_logo_text text-center align-self-center">
                      <CommonImageUpload
                        name="local_upload_logo"
                        handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic', 0)}
                        index={0}
                        forImage={English.G290}
                        className="profile-upload"
                        selectedImg={
                  typeof contactImage0 === 'string' &&
                  contactImage0?.length > 0
                    ? s3Image + contactImage0
                    : contactImage0
                      ? URL.createObjectURL(contactImage0)
                      : ''
                }
                        // selectedImg={
                        //     contactImage0
                        //           ? `${s3Image}${contactImage0}`
                        //           : ''
                        //       }
                        defaultImg={Images.local_add_img}
                      />
                    </div>
                  </div>
                </div>
                <div className="upload_btn_logo">
                  <p className="image_validation mb-0">
                    {English.G317}
                    <br />
                    {English.G318}
                  </p>
                </div>
              </div>
              <div className="person_detail_section">
                <div className="input-grid">
                  <div>
                    <label>{English.G130}</label>
                    <CommonInput
                      placeholder={English.A201}
                      onChange={onChange}
                      name="contact_name1"
                      // maxLength={20}
                      // allowTextOnly
                      value={capitalizeFirstLetter(
                        contactName1
                            )}
                    />
                  </div>
                  <div>
                    <label>{English.G291}</label>
                    <CommonInput
                      placeholder={English.A202}
                      onChange={onChange}
                      name="contact_designation1"
                      // allowTextOnly
                      // maxLength={20}
                      value={capitalizeFirstLetter(
                        contactDesignation1
                            )}
                    />
                  </div>
                </div>
              </div>
              <div className="logo_btn mb-2.5">
                <div className="image_upload_section">
                  <div className="add_photos">
                    <div className="add_logo_text text-center align-self-center">
                      <CommonImageUpload
                        name="local_upload_logo"
                        handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic', 1)}
                        index={1}
                        forImage={English.G290}
                        className="profile-upload"
                        selectedImg={
                  typeof contactImage1 === 'string' &&
                  contactImage1?.length > 0
                    ? s3Image + contactImage1
                    : contactImage1
                      ? URL.createObjectURL(contactImage1)
                      : ''
                }
                        // selectedImg={
                        //     contactImage1
                        //           ? `${s3Image}${contactImage1}`
                        //           : ''
                        //       }
                        defaultImg={Images.local_add_img}
                      />
                    </div>
                  </div>
                </div>
                <div className="upload_btn_logo">
                  <p className="image_validation mb-0">
                    {English.G317}
                    <br />
                    {English.G318}
                  </p>
                </div>
              </div>
              <div className="person_detail_section">
                <div className="input-grid">
                  <div>
                    <label>{English.G130}</label>
                    <CommonInput
                      placeholder={English.A201}
                      onChange={onChange}
                      name="contact_name2"
                      // allowTextOnly
                      // maxLength={20}
                      value={capitalizeFirstLetter(
                        contactName2
                            )}
                    />
                  </div>
                  <div>
                    <label>{English.G291}</label>
                    <CommonInput
                      placeholder={English.A202}
                      onChange={onChange}
                      name="contact_designation2"
                      // allowTextOnly
                      // maxLength={20}
                      value={capitalizeFirstLetter(
                        contactDesignation2
                            )}
                    />
                  </div>
                </div>
              </div>
            </div>
                )}
            <div className="bg-[#FAFAFA] border-[0.5px] border-[#bab8b8] border-dashed rounded-[15px] flex items-center justify-center h-[calc(80vh-220px)] sticky top-0 overflow-y-auto">
              <div className="text-center">
                {ImagesData?.length < 1 ? (
                  <CommonImageUpload
                    handleImageChange={(file) => onUploadImage(
                            file,
                            'images',
                            ImagesData?.length
                          )}
                    buttonText="Add Image"
                    selectedImg=""
                    defaultImg={Images.plusGiveaway}
                    className="plsimg"
                    forImage="hotel"
                  />
                    ) : (
                      <div className={`input-image-show !mt-0 ${editFrom ? '!pl-0' : 'image_local_last_popup'}`}>
                        {renderImg}
                        {ImagesData?.length < 6 && (
                          <div className="input-img-label">
                            <CommonImageUpload
                              handleImageChange={(file) => onUploadImage(
                                  file,
                                  'images',
                                  ImagesData?.length
                                )}
                              buttonText="Add Image"
                              selectedImg=""
                              defaultImg={Images.plusGiveaway}
                              className="plsimg mb-0"
                              forImage={
                                ImagesData?.length < 0 ? 'hotel' : null
                              }
                            />
                          </div>
                        )}
                      </div>
                    )}
              </div>
            </div>
          </div>
        </div>
        <div className="item-details-input pt-0">
          <div className="local_button_row local_sticky_btn !pt-0 !justify-end">
            {/* <CommonButton
              label={English.G333}
              // onClick={handleNextClick}
              onClick={onCancel}
              isPrimary={false}
              context={English.A197}
            /> */}
            <CommonButton
              label={English.G69}
              type="button"
              isPrimary
              onClick={onSubmit}
              context={English.A197}
              // disabled={!isStep4Valid() || !isStep5Valid()}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactImagePopup

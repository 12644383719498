/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lone-blocks */
/* eslint-disable max-len */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { s3ImageHotel, s3ImageOrganizer, s3ImageRestaurant } from '../../services/Service'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import Loader from '../Loader/Loader'
import {
  // ForMobileNumber,
  lockScroll,
  unlockScroll
} from '../../helpers/Utility'
import Pagination from '../Pagination/Pagination'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import { emitter, EventType } from '../../helpers/Emitter'
// import HotelProfileDetails from './HotelProfileDetails'
// import HotelDetails from './HotelDetails'

const HotelsProfile = ({ onDetailHotel, onDetail, initialId }) => {
  // const userData = useSelector((state) => state.user?.userData)
  const app_id = useSelector((state) => state.user?.app_id)
  const [isHotelData, setIsHotelData] = useState([])
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [loading, setLoading] = useState(false)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [ShowConfirmHotelModel, setShowConfirmHotelModel] = useState(false)
  const [delHotelId, setDelHotelId] = useState()
  const [isHotelLoading, setIsHotelLoading] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const initialized = useRef()

  const handleDeleteCancel = () => {
    setShowConfirmModel(false)
    setShowConfirmHotelModel(false)
  }

  const ShowConfirm = (videoId) => {
    setDelHotelId(videoId)
    setShowConfirmHotelModel(true)
  }

  useEffect(() => {
    const event = emitter.addListener(EventType.localConnect, () => {
      allHotelData(null, 1)
    })
    return () => event.remove()
  }, [app_id])

  const allHotelData = (id, currentPage) => {
    setIsHotelLoading(true)
    const payload = {
      app_id: app_id.id
    }
    APICall(
      'post',
      payload || {},
      `${EndPoints.getLocal}?page=${currentPage}`,
      'local'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsHotelData(res?.decryptedData.data)
          if (initialId) {
            onDetailHotel(res?.decryptedData.data[0].hotel_id)
          }
          setIsHotelLoading(false)
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
        } else {
          toast.error(res?.data?.message)
          setIsHotelLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsHotelLoading(false)
      })
  }

  const deleteHotel = () => {
    setLoading(true)
    const { hotelIds, restaurantIds, organizerIds } = [delHotelId].reduce(
      (acc, item) => {
        if (item.hotel_id !== undefined) acc.hotelIds.push(item.hotel_id)
        if (item.restaurant_id !== undefined) acc.restaurantIds.push(item.restaurant_id)
        if (item.organizer_id !== undefined) acc.organizerIds.push(item.organizer_id)
        return acc
      },
      { hotelIds: [], restaurantIds: [], organizerIds: [] }
    )
    const data = {
      app_id: [app_id.id],
      ...(hotelIds.length > 0 && { hotel_id: hotelIds }),
      ...(restaurantIds.length > 0 && { restaurant_id: restaurantIds }),
      ...(organizerIds.length > 0 && { organizer_id: organizerIds }),
      publish: false
    }
    APICall(
      'post',
      data,
      `${EndPoints.connectLocal}`,
      'local'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            setLoading(false)
            setShowConfirmHotelModel(false)
            emitter.emit(EventType.localConnect, 'true')
          }, 3000)
        } else {
          setShowConfirmHotelModel(false)
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setShowConfirmHotelModel(false)
        setLoading(false)
      })
  }

  const handlePageChange = (pageNumber) => {
    allHotelData(null, pageNumber)
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      allHotelData(null, currentPage)
    }
  }, [])

  useEffect(() => {
    if (
      ShowConfirmModel ||
      ShowConfirmHotelModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [
    ShowConfirmModel,
    ShowConfirmHotelModel
  ])

  const [visibleHotels, setVisibleHotels] = useState(6)
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleItems = () => {
    if (isExpanded) {
      setVisibleHotels(3)
    } else {
      setVisibleHotels(isHotelData.length)
    }
    setIsExpanded(!isExpanded)
  }

  return (
    <div>
      {isHotelData?.length > 0 && (
      <div className="mt-2">
        <div className="px-3">
          {/* <h4 className="bg-primaryGradient bg-clip-text text-transparent text-xl italic font-black uppercase pe-2">
            Hotel
          </h4> */}
          <div className="hotel-offer--grid grid-3 mt-2.5 !mb-5">
            {isHotelData?.length === 0 && !isHotelLoading ? (
              <div className="awards-item">
                <div className="add-profile-video-cover add-product-video-cover">
                  <button type="button">
                    {/* <img src={Images.Plus} alt="add library" /> */}
                  </button>
                </div>
                <div className="product-footer">
                  <p className="add-value" />
                  <span className="add-value" />
                </div>
              </div>
            ) : isHotelLoading ? (
              <div className="awards-item">
                <div className="add-profile-video-cover add-product-video-cover" />
                <div className="product-footer">
                  <p className="add-value">
                    <Skeleton className="add-value" width={202} height={35} />
                  </p>
                  <span className="add-value">
                    <Skeleton className="add-value" width={67} height={35} />
                  </span>
                </div>
              </div>
            ) : (
              isHotelData.slice(0, visibleHotels).map((item, index) => (
                <div className="hotel-offer-grid--item" key={item.name}>
                  <div
                    className="hotel-offer--cover position-relative height-184"
                    onClick={() => {
                      if (!ShowConfirmHotelModel) {
                        onDetailHotel(item.hotel_id)
                        onDetail(item)
                      }
                    }}
                    style={{
                      backgroundImage: `url(${item.event_id ? s3ImageOrganizer + item.images[0] : (item.restaurant_id ? s3ImageRestaurant : item?.organizer_id ? s3ImageOrganizer : s3ImageHotel) + item.images[0]})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  >
                    <div className="image-buttons z-1">
                      <button
                        type="button"
                        className="p-0"
                        onClick={(e) => {
                          e.stopPropagation()
                          setUpdatingIndex(index)
                          ShowConfirm(item)
                        }}
                      />
                    </div>
                    <ConfirmationModal
                      isVisible={
                        ShowConfirmHotelModel && updatingIndex === index
                      }
                      onConfirm={deleteHotel}
                      onCancel={handleDeleteCancel}
                      isLoading={loading}
                    />
                    {loading && updatingIndex === index && <Loader />}
                  </div>
                  <div className="hotel-offer-grid--detail">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <h6 className="local_item_name">{item.name}</h6>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="location d-flex align-items-center col-gap-10">
                        <span>{item.address}</span>
                      </span>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          {isHotelData?.length > 6 && (
            <button
              type="button"
              onClick={toggleItems}
              className="see-more-button"
            >
              {isExpanded ? (
                <img className="upIcon" alt="Show less" src={Images.DownIco} />
              ) : (
                <img
                  className="downIcon"
                  alt="Show more"
                  src={Images.DownIco}
                />
              )}
            </button>
          )}
          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
      )}
    </div>
  )
}
export default HotelsProfile

/* eslint-disable react/jsx-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react'
import './CreatorProfile.css'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { dataDecrypt } from '../../services/http-services'
import CommonButton from '../ButtonComponent/ButtonCommon'
import English from '../../helpers/English'
import { APICall, EndPoints } from '../../services'
import { Images } from '../../helpers'
import { emitter, EventType } from '../../helpers/Emitter'

const InstallAddons = () => {
  const app_ids = useSelector((state) => state.user?.app_id)
  const userType = useSelector((state) => state.user?.user_type)

  const params = useParams()
  // const navigate = useNavigate()
  const [addOnsData, setAddOnsData] = useState([])
  const [defaultAddOnData, setDefaultAddOnsData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    APICall('get', '', `${EndPoints.addons}?type=${userType}`, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const Arr = dataDecrypt(res.data.data)
          const defaultAddOns = Arr.filter((item) => item.default_add_ons)
          const additionaAddOns = Arr.filter((item) => !item.default_add_ons)
          additionaAddOns.forEach((item1, index) => {
            app_ids.add_ons_data.forEach((item2) => {
              if (item2.id === item1.id) {
                additionaAddOns[index].checked = true
              }
            })
          })
          setDefaultAddOnsData(defaultAddOns)
          setAddOnsData(additionaAddOns)
          setIsLoading(false)
        } else {
          toast.error(res.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }, [])

  const installClick = () => {
    const add_ons = addOnsData.filter(({ checked }) => checked)
    const data = {
      app_id: app_ids.id,
      add_ons: [...add_ons.map((item) => item.id), ...defaultAddOnData.map((item) => item.id)],
      type: userType
    }
    APICall('post', data, EndPoints.editAddons, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          emitter.emit(EventType.sidebarRoute, {
            product_id: params.id,
            action: 'Home'
          })
          toast.success(res?.data?.message)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handelClickCancel = (event) => {
    emitter.emit(EventType.sidebarRoute, {
      product_id: params.id,
      action: 'Home'
    })
    return () => event.remove()
  }

  const getImageSrc = (name) => {
    switch (name) {
      case 'Community':
        return Images.Community
      case 'Entertainment':
        return Images.Entertainment
      case 'Store':
        return Images.store
      case 'Local':
        return Images.local
      default:
        return ''
    }
  }

  const handleToggleAddon = (index) => {
    const newCheckboxes = [...addOnsData]
    newCheckboxes[index].checked = !newCheckboxes[index].checked
    setAddOnsData(newCheckboxes)
  }

  return (
    <div className="creator-profile-create !pt-0">
      <div>
        <div className="sticky_header_install_addOns !px-[20px]">
          <div className="d-flex align-items-center justify-content-between">
            <p className="text-[18px] italic font-black uppercase text-[#0B0A0A]">
              {English.A218}
            </p>
            <div onClick={(event) => handelClickCancel(event)}>
              <img src={Images.CloseIco} alt="" />
            </div>
          </div>
        </div>
        <div className="scroll_bars_install_addOns !h-[calc(100vh-183px)] !px-[20px] mt-3">
          <div className="flex flex-col gap-9">
            <div>
              <h3 className="uppercase text-[#5A5A5A] italic text-[12px] font-black">
                Default
              </h3>
              <div className="grid grid-cols-3 gap-[25px] max-[1200px]:grid-cols-2">
                <div className="flex border border-[#E1E2E5] border-l-0 rounded-[20px]">
                  <div>
                    <img
                      src={Images.Home_addons_icon}
                      alt="roomImage"
                      className="!w-[150px] !h-full object-cover rounded-tl-[20px] rounded-bl-[20px]"
                    />
                  </div>
                  <div className="py-2.5 px-[20px] flex flex-col flex-1 justify-center">
                    <div className="">
                      <div className="items-center">
                        <h6 className="mb-0 text-Colormain italic font-black text-sm">
                          {English.G222}
                        </h6>
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <p className="mb-0 text-Colormain font-medium text-[11px]">
                        Lorem ipsum dolor sit ame consectetur adipiscing
                        elit.Lorem ipsum dolor sit ame consectetur adipiscing
                        elit.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex border border-[#E1E2E5] border-l-0 rounded-[20px]">
                  <div>
                    <img
                      src={Images.Feed_addons_icon}
                      alt="roomImage"
                      className="!w-[150px] !h-full object-cover rounded-tl-[20px] rounded-bl-[20px]"
                    />
                  </div>
                  <div className="py-2.5 px-[20px] flex flex-col flex-1 justify-center">
                    <div className="">
                      <div className="items-center">
                        <h6 className="mb-0 text-Colormain italic font-black text-sm">
                          {English.G223}
                        </h6>
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <p className="mb-0 text-Colormain font-medium text-[11px]">
                        Lorem ipsum dolor sit ame consectetur adipiscing
                        elit.Lorem ipsum dolor sit ame consectetur adipiscing
                        elit.
                      </p>
                    </div>
                  </div>
                </div>
                {defaultAddOnData.map((i, index) => {
                  return (
                    <div key={index} className="flex border border-[#E1E2E5] border-l-0 rounded-[20px]">
                      <div>
                        <img
                          src={getImageSrc(i.name)}
                          alt="roomImage"
                          className="!w-[150px] !h-full object-cover rounded-tl-[20px] rounded-bl-[20px]"
                        />
                      </div>
                      <div className="py-2.5 px-[20px] flex flex-col flex-1 justify-center">
                        <div className="">
                          <div className="items-center">
                            <h6 className="mb-0 text-Colormain italic font-black text-sm">
                              {i.name}
                            </h6>
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <p className="mb-0 text-Colormain font-medium text-[11px]">
                            {i.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div>
              <h3 className="uppercase text-[#5A5A5A] italic text-[12px] font-black">
                Additional
              </h3>
              <div className="flex gap-[20px] items-center">
                {isLoading ? (
                  <div className="add-ons !w-[312px] !p-[10px]">
                    <div className="">
                      <Skeleton width={250} height={170} />
                    </div>
                    <div className="add-ons-detail !w-full">
                      <h4 className="w-full text-center">
                        <Skeleton width={150} height={20} />
                      </h4>
                      <p className="w-full text-center">
                        <Skeleton width={250} height={15} />
                        <Skeleton width={250} height={15} />
                        <Skeleton width={250} height={15} />
                      </p>
                      <div className="w-full text-center mt-[10px] mb-[10px]">
                        <Skeleton width={210} height={30} />
                      </div>
                    </div>
                  </div>
                ) : (
                  addOnsData.map((i, index) => (
                    <div key={index} className="add-ons overflow-hidden !w-[312px]">
                      <div className="">
                        <img className="" alt="" src={getImageSrc(i.name)} />
                      </div>
                      <div className="footer-row">
                        <div className="add-ons-detail !w-full">
                          <h4 className="w-full text-center">{i.name}</h4>
                          <p className="w-full text-center">{i.description}</p>
                          <div className="w-full text-center mt-[20px]">
                            <CommonButton
                              label={i.checked ? 'Uninstall' : English.G221}
                              type="button"
                              isPrimary
                              disabled={i.disabled}
                              onClick={() => {
                                handleToggleAddon(index)
                                installClick()
                              }}
                              context={English.G232}
                              className="w-full !font-black"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default InstallAddons

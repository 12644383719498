/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react'
import './REcentShots.css'
import { toast } from 'react-toastify'
import ReactPlayer from 'react-player'
import _ from 'lodash'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { s3ImageProfile, s3ImageShots } from '../../services/Service'
import { compressVideo, dataDecrypt } from '../../services/http-services'
import Loader from '../Loader/Loader'
// import PlatformTag from '../ContentManager/PlatformTag'
import {
  capitalizeFirstLetter,
  formatViews,
  lockScroll,
  unlockScroll
} from '../../helpers/Utility'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import Images from '../../helpers/Images'
import English from '../../helpers/English'
import CommentModal from '../CommanComponent/CommentModal'
import { APICall, EndPoints } from '../../services'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import CommonModalMain from '../CommonModalMain'
import UploadingComponent from '../UploadingComponent/UploadingComponent'

const ShotsComponent = ({ showCount }) => {
  const userData = useSelector((state) => state.user?.userData)
  const profileData = useSelector((state) => state.user?.profileData)
  const app_id = useSelector((state) => state.user?.app_id)
  const [shots, setShots] = useState([])
  const [ShowShotsModel, setShowShotsModel] = useState(false)
  const [ShowShotsPreviewModel, setShowShotsPreviewModel] = useState(false)
  const [inputFields, setInputFields] = useState({
    app_id: [],
    attachment: '',
    cover_attachment: '',
    title: '',
    tags: [],
    search: ''
  })

  const [selectedVideo, setSelectedVideo] = useState('')
  const [selectedVideoObj, setSelectedVideoObj] = useState('')
  const [selectedFilePath, setSelectedFilePath] = useState('')
  const [Platformtags, setPlatformTags] = useState([])
  const [loading, setLoading] = useState(false)
  const [delLoading, setDelLoading] = useState(false)
  const [delShotId, setDelShotId] = useState()
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [isUploading, setUploading] = useState(false)
  // const [tags, setTags] = useState([])
  // const componentRef = useRef()
  const videoRef = useRef(null)
  const [isMuted, setIsMuted] = useState(true)
  const [playing, setPlaying] = useState(false)
  const [comments, setComments] = useState([])
  const [selectedId, setSelectedId] = useState('')
  const [commentSection, setCommentSection] = useState(false)
  const [newComment, setNewComment] = useState('')
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [pause, setPause] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hoveredItem, setHoveredItem] = useState(null)
  const [currentPageComment, setCurrentPageComment] = useState(1)
  const [localStorageValue, setLocalStorageValue] = useState(app_id || null)
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [isVideoUploading, setIsVideoUploading] = useState(false)

  const [isPlaying, setIsPlaying] = useState(false)
  const [isFinished, setIsFinished] = useState(false)

  const handleEnded = () => {
    setIsPlaying(false)
    setPlaying(false)
    setIsFinished(true)
  }
  const AddVideo = () => {
    setShowShotsModel(true)
    clearValue()
    // navigate('/add-component', { state: { defaultIndex: 6 } })
  }

  const handlePlay = () => {
    const video = videoRef.current
    if (video) {
      video.play()
      setIsPlaying(true)
    }
  }
  const handleClickPlay = (shot_id) => {
    viewCount(shot_id)
    setPlaying(true)
  }
  const handlePlayPause = () => {
    setPlaying(true)
  }

  const handlePause = () => {
    setIsPlaying(false)
  }

  const pauseHandel = () => {
    if (videoRef.current.paused) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
    setPause(!pause)
  }

  const VolumeHandel = () => {
    videoRef.current.muted = !videoRef.current.muted
    setIsMuted(videoRef.current.muted)
  }

  const playPauseIcon = pause ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      viewBox="0 0 9 11"
      fill="none"
    >
      <path
        d="M8.00011 0C8.55233 0 9 0.447668 9 0.999895V9.99977C9 10.552 8.55233 10.9997 8.00011 10.9997V10.9997C7.44788 10.9997 7.00021 10.552 7.00021 9.99977V0.999895C7.00021 0.447668 7.44788 0 8.00011 0V0Z"
        fill="white"
      />
      <path
        d="M0.999895 0.000337601C1.55212 0.000337601 1.99979 0.352103 1.99979 0.786028V10.2143C1.99979 10.6482 1.55212 11 0.999895 11C0.447668 11 0 10.6482 0 10.2143V0.786028C0 0.352103 0.447668 0.000337601 0.999895 0.000337601Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      viewBox="0 0 10 11"
      fill="none"
    >
      <path
        d="M8.89402 6.53751C8.84168 6.58958 8.64356 6.81642 8.46036 7.00607C6.892 8.51701 5.08607 9.76303 3.11392 10.695C2.76929 10.8448 2.40584 10.9474 2.03346 11C1.74485 11.0019 1.46007 10.9343 1.20346 10.8029C0.869688 10.617 0.606863 10.3268 0.455712 9.97732C0.361719 9.6542 0.284307 9.32648 0.223866 8.99553C0.0568802 7.84053 -0.0169171 6.67413 0.00324612 5.50742C-0.0087408 4.37758 0.0562202 3.2482 0.197669 2.12709C0.26836 1.71381 0.373439 1.30707 0.511757 0.911065C0.646164 0.640598 0.853148 0.412361 1.10981 0.251575C1.36648 0.0907899 1.66279 0.00373468 1.96609 0H2.01849C2.44039 0.066061 2.85075 0.191263 3.23733 0.371871C5.16678 1.2973 6.93408 2.52528 8.47159 4.00878C8.63059 4.16774 8.77917 4.33662 8.91648 4.51447C9.12318 4.79749 9.23328 5.139 9.23057 5.48881C9.23435 5.86465 9.11485 6.23151 8.89028 6.53379"
        fill="white"
      />
    </svg>
  )

  const playVolumeIcon = isMuted ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      viewBox="0 0 15 11"
      fill="none"
    >
      <path
        d="M0.966462 5.50142C0.966462 6.3205 0.930479 7.43966 1.39751 7.85979C1.86453 8.27992 2.14213 8.14929 2.93902 8.21284C3.7359 8.27639 5.42769 10.8184 6.7308 10.0134C7.40684 9.4415 7.45909 8.24817 7.45909 5.50142C7.45909 2.75467 7.40685 1.55781 6.73733 0.989398C5.43096 0.184438 3.74571 2.72636 2.94555 2.78991C2.1454 2.85346 1.83841 2.75461 1.39751 3.14297C0.956607 3.53132 0.966462 4.67881 0.966462 5.50142Z"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 3.5C13.0598 4.08693 13.0359 4.28454 12.4995 4.99965C11.9632 5.71476 11.4397 6.41272 10.9995 6.99965"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 3.5C11.1087 4.10874 11.299 4.29904 12.0008 5.00078C12.7025 5.70253 13.3913 6.39126 14 7"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      viewBox="0 0 14 11"
      fill="none"
    >
      <path
        d="M0.966462 5.50142C0.966462 6.3205 0.930479 7.43966 1.39751 7.85979C1.86453 8.27992 2.14213 8.14929 2.93902 8.21284C3.7359 8.27639 5.42769 10.8184 6.7308 10.0134C7.40684 9.4415 7.45909 8.24817 7.45909 5.50142C7.45909 2.75467 7.40685 1.55781 6.73733 0.989398C5.43096 0.184438 3.74571 2.72636 2.94555 2.78991C2.1454 2.85346 1.83841 2.75461 1.39751 3.14297C0.956607 3.53132 0.966462 4.67881 0.966462 5.50142Z"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5059 1.43457C12.273 2.62607 12.6843 4.04296 12.6843 5.49468C12.6843 6.94639 12.273 8.36329 11.5059 9.55478"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.96289 3.04004C10.36 3.78848 10.569 4.6362 10.569 5.499C10.569 6.36179 10.36 7.20959 9.96289 7.95804"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )

  const handleMouseEnter = (itemIndex) => {
    setHoveredItem(itemIndex)
    // setPlaying(true)
  }

  const handleMouseLeave = () => {
    setHoveredItem(null)
    // setPlaying(false)
  }

  const HandleShotCover = () => {
    setShowShotsModel(false)
    setShowShotsPreviewModel(true)
  }

  const HandleCancel = () => {
    setShowShotsPreviewModel(false)
    setShowShotsModel(false)
    clearValue()
  }
  const HandleClose = () => {
    clearValue()
    setShowShotsModel(false)
    setShowShotsPreviewModel(false)
    setIsEditing(false)
  }
  // const HandleCancelPreview = () => {
  //   setShowShotsModel(true)
  //   setShowShotsPreviewModel(false)
  // }
  const ShowConfirm = (videoId) => {
    setDelShotId(videoId)
    setShowConfirmModel(true)
  }

  const handleCancels = () => {
    clearValue()
    setShowConfirmModel(false)
  }

  // const updateInputFieldsTags = (tagsArray) => {
  //   setInputFields((prevInputFields) => ({
  //     ...prevInputFields,
  //     tags: tagsArray
  //   }))
  // }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  // const getSelectedAppId = (app) => {
  //   setInputFields({ ...inputFields, app_id: app.map((a) => a.id) })
  // }

  // const isAppsSelected = inputFields.app_id?.length > 0

  const clearValue = () => {
    setSelectedFilePath('')
    setSelectedVideo('')
    setSelectedVideoObj('')
    setPlatformTags([])
    setInputFields({
      ...inputFields,
      title: '',
      attachment: '',
      cover_attachment: '',
      app_id: [],
      tags: []
    })
    setLoading(false)
    setUploadPercentage(0)
    setIsVideoUploading(false)
  }

  const commentClear = () => {
    setComments([])
    setSelectedId([])
    setNewComment('')
    setShowReplied('')
    setEditedCommentText('')
    setEditedReplyText('')
  }

  const fetchShotsData = (currentPage, delay = 5000) => {
    setTimeout(() => {
      getShots(currentPage)
    }, delay)
  }

  const getShots = (currentPage) => {
    // setIsLoading(true)
    const platFormData = {
      app_id: app_id.id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.getShots}?page=${currentPage}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const decoded = res?.decryptedData?.data?.map((d) => {
            return {
              cover: s3ImageShots + d.cover_attachment,
              url: s3ImageShots + d.attachment,
              type: 'video',
              header: {
                profileImage: s3ImageProfile + d.profile_icon,
                heading: d.user_name,
                created_at: d.created_at
              },
              shot_id: d.shot_id,
              views: d.views,
              tags: d.tags,
              title: d.title,
              total_likes: d.total_likes,
              is_like: d.is_like,
              is_save: d.is_save,
              app_data: d.app_data,
              total_comments: d.total_comments
            }
          })
          setShots(currentPage === 1 ? decoded : [...shots, ...decoded])
          setTotalPages(res?.decryptedData?.last_page)
          setCurrentPage(res?.decryptedData?.current_page)
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  // Shots pagination

  const totalVideos = shots?.length
  const handlePageChange = (pageNumber) => {
    getShots(pageNumber)
    setCurrentPage(pageNumber)
  }

  const viewCount = (videoId) => {
    const data = {
      shot_id: videoId
    }
    APICall('post', data, `${EndPoints.shotViewCount}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          //
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotLikeData = (shot_id) => {
    const platFormData = {
      app_id: app_id.id,
      shot_id
    }
    APICall('post', platFormData, `${EndPoints.shotLike}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShots(
            shots.map((item) => (item.shot_id === shot_id
                ? { ...item, is_like: !item.is_like }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleVideoChange = (event) => {
    const fileInput = event.target
    const selectedFile = fileInput.files[0]

    if (selectedFile) {
      const videoURL = URL.createObjectURL(selectedFile)
      const video = document.createElement('video')

      video.src = videoURL
      video.onloadedmetadata = () => {
        const videoDuration = video.duration
        // const { videoWidth } = video
        // const { videoHeight } = video
        if (videoDuration > 60) {
          toast.error('please shot upload under 1 min !')
          fileInput.value = ''
          return
        }

        // if (videoWidth / videoHeight !== 16 / 9) {
        //   toast.error("Please upload a video with a 16:9 aspect ratio!");
        //   fileInput.value = "";
        //   return;
        // }

        setSelectedVideoObj(selectedFile)
        setSelectedVideo(videoURL)
      }
    }
  }
  const delay = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }
  const handlePublishClick = async () => {
    setLoading(true)
    try {
      if (!isEditing || selectedVideoObj) {
        const videoFile = await compressVideo(
          selectedVideoObj,
          setUploadPercentage,
          setIsVideoUploading
        )
        const videoFormData = new FormData()
        videoFormData.append('file', videoFile)
        videoFormData.append('type', 'shot')

        await APICall(
          'post',
          videoFormData,
          EndPoints.uploadImage,
          'creator',
          true
        )
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedVideoData = dataDecrypt(res.data.data)
              inputFields.attachment = decryptedVideoData.result
              inputFields.cover_attachment = decryptedVideoData.thumbnail
              setUploading(true)
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((e) => {
            toast.error(e?.data?.message)
          })

        // inputFields.attachment = videoFile
      }

      await delay(5000)

      if (isEditing) {
        editShotApiCall()
      } else {
        addShotApiCall()
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.message || 'An error occurred')
    }
  }

  const addShotApiCall = () => {
    const data = {
      title: inputFields.title,
      attachment: inputFields.attachment,
      cover_attachment: inputFields.cover_attachment,
      app_id: [app_id?.id]
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.addShot}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            setShowShotsPreviewModel(false)
            clearValue()
            setIsEditing(false)
            toast.success(res?.data?.message)
            setShowShotsModel(false)
            getShots(currentPage)
            setUploading(false)
          }, 4500)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
          setUploading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
        setUploading(false)
      })
  }

  const deleteShot = () => {
    const data = {
      shot_id: delShotId
    }
    setDelLoading(true)
    APICall('post', data, `${EndPoints.deleteShot}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          fetchShotsData(currentPage)
          setDelLoading(false)
          toast.success(res?.data?.message)
        } else {
          setDelLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setDelLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const editShotApiCall = () => {
    const data = {
      title: inputFields.title,
      attachment: inputFields.attachment,
      cover_attachment: inputFields.cover_attachment,
      app_id: Platformtags.map((d) => d.id),
      shot_id: inputFields.shot_id
      // tags: inputFields.tags
    }
    APICall('post', data, `${EndPoints.editShot}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            setShowShotsModel(false)
            setShowShotsPreviewModel(false)
            clearValue()
            setIsEditing(false)
            toast.success(res?.data?.message)
            getShots(currentPage)
            setUploading(false)
          }, 4500)
        } else {
          toast.error(res?.data?.message)
          setUploading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setUploading(false)
      })
  }

  const onClickEditPodcast = (videoData) => {
    setShowShotsModel(true)
    setIsEditing(true)
    setSelectedVideo(videoData.url)
    setSelectedFilePath(videoData.cover)

    setInputFields({
      ...inputFields,
      title: videoData.title,
      attachment: videoData.url,
      cover_attachment: videoData.cover,
      shot_id: videoData.shot_id,
      app_id: videoData.app_data
      // tags: videoData.tags
    })
    setInitialValues({
      title: videoData.title,
      attachment: videoData.url,
      cover_attachment: videoData.cover,
      shot_id: videoData.shot_id,
      app_id: videoData.app_data,
      // tags: videoData.tags,
      search: ''
    })
    // setTags(videoData.tags)
    // setTags(
    //   Array.isArray(videoData.tags)
    //     ? videoData.tags
    //     : JSON.parse(videoData.tags || '[]')
    // )
    setPlatformTags(videoData.app_data)
  }

  const validateForm = () => {
    const { title, tags } = inputFields
    if (title && tags && selectedVideo) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  // comment section

  const fetchShotComments = (id, delay = 500) => {
    setTimeout(() => {
      shotComment(id)
    }, delay)
  }

  const onClickComment = (shot_id) => {
    setSelectedId(shot_id)
    if (!isLoadingComments) {
      setIsLoadingComments(true)
      shotComment(shot_id)
      setCommentSection(true)
    }
  }

  const shotComment = (shot_id) => {
    const platFormData = {
      app_id: app_id.id,
      shot_id
    }
    APICall('post', platFormData, `${EndPoints.shotComment}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const dataComment = res?.decryptedData.data.map((d, i) => ({
            id: i + 1,
            text: d.comment,
            shot_id: d.shot_id,
            is_like: d.is_like,
            reply_id: d.comment_id,
            total_like: d.total_like,
            reply: d.reply_data.map((subData, subIndex) => ({
              id: subIndex + 1,
              text: subData.comment,
              reply_id: subData.comment_id,
              total_like: subData.total_like,
              is_like: subData.is_like,
              user: {
                name: subData.user_name,
                profilePic: s3ImageProfile + subData.profile_icon
              }
            })),
            user: {
              name: d.user_name,
              profilePic: s3ImageProfile + d.profile_icon
            }
          }))
          setComments(dataComment)
          setIsLoadingComments(false)
        } else {
          setIsLoadingComments(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsLoadingComments(false)
        toast.error(error?.data?.message)
      })
  }

  const shotAddComment = (comment) => {
    const platFormData = {
      shot_id: selectedId,
      comment,
      app_id: app_id.id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.shotCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchShotComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotAddCommentReply = (comment_id, replyText) => {
    const platFormData = {
      shot_id: selectedId,
      comment_id,
      comment: replyText,
      app_id: app_id.id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.shotCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchShotComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotCommentEdit = (reply_id, comment) => {
    const platFormData = {
      comment_id: reply_id,
      comment
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.shotCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchShotComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotCommentEditReply = (comment_id, replyText) => {
    const platFormData = {
      shot_id: selectedId,
      comment_id,
      comment: replyText
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.shotCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchShotComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotCommentDelete = (reply_id) => {
    setIsDeleting(true)
    const data = {
      comment_id: reply_id
    }
    APICall('post', data, `${EndPoints.shotCommentDelete}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchShotComments(selectedId)
          toast.success(res?.data?.message)
          setIsDeleting(false)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const shotCommentDeleteReply = (commentId, replyId) => {
    const data = {
      comment_id: commentId,
      reply_id: replyId
    }
    APICall('post', data, `${EndPoints.shotCommentDelete}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchShotComments(selectedId)
          toast.success(res?.data?.message)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotCommentLike = (reply_id) => {
    const platFormData = {
      app_id: app_id.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.shotCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments(
            comments.map((item) => (item.reply_id === reply_id
                ? {
                    ...item,
                    is_like: !item.is_like,
                    total_like: item.is_like ? item.total_like - 1 : item.total_like + 1
                  }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotCommentReplyLike = (reply_id) => {
    const platFormData = {
      app_id: app_id.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.shotCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          // fetchhotComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleAddComment = (comment) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        shot_id: selectedId,
        total_like: 0,
        reply: [],
        user: {
          name: profileData.user_name,
          profilePic: s3ImageProfile + profileData.profile_icon
        }
      }
      setComments([newCommentObj, ...comments])
      setNewComment('')
    }
    shotAddComment(comment)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    shotCommentLike(commentId)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          reply: [
            ...comment.reply,
            {
              id: comment.reply.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: profileData.user_name,
                profilePic: s3ImageProfile + profileData.profile_icon
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    shotAddCommentReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          reply: comment.reply.filter((reply) => reply.id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    shotCommentDeleteReply(replyId, commentId)
  }

  const toggleReplies = (commentId, forceCloseAll = false) => {
    if (forceCloseAll) {
      setShowReplies({})
    } else {
      setShowReplies((prevState) => ({
        // ...prevState,
        [commentId]: !prevState[commentId]
      }))
    }
    setShowReplied('')
  }

  const handleCancel = () => {
    setCommentSection(false)
    commentClear()
    toggleReplies(null, true)
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.reply_id === commentId
        ? { ...comment, text: editedCommentText }
        : comment))
    shotCommentEdit(commentId, reply)
    setComments(updatedComments)
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => (comment.reply_id === commentId
        ? {
            ...comment,
            reply: comment.reply.map((reply) => (reply.reply_id === replyId
                ? { ...reply, text: editedReplyText }
                : reply))
          }
        : comment))
    shotCommentEditReply(replyId, editedReplyText)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  // const OnClickSave = (shot_id) => {
  //   const platFormData = {
  //     app_id: app_id.id,
  //     shot_id
  //   }
  //   APICall('post', platFormData, `${EndPoints.shotSave}`, 'contentManager')
  //     .then((res) => {
  //       if (res.status === 200 && res.data?.code === 200) {
  //         setShots(
  //           shots.map((item) => (item.shot_id === shot_id
  //               ? { ...item, is_save: !item.is_save }
  //               : item))
  //         )
  //         toast.success(res?.data?.message)
  //       } else {
  //         toast.error(res?.data?.message)
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error?.data?.message)
  //     })
  // }

  // comment pagination

  const commentsPerPage = 5
  const indexOfLastComment = currentPageComment * commentsPerPage
  const indexOfFirstComment = indexOfLastComment - commentsPerPage
  const commentsList = comments.slice(indexOfFirstComment, indexOfLastComment)
  const totalCommentPages = Math.ceil(comments.length / commentsPerPage)

  useEffect(() => {
    const handleStorageChange = () => {
      setLocalStorageValue(app_id || null)
    }
    window.addEventListener('storage', handleStorageChange)
    return () => window.removeEventListener('storage', handleStorageChange)
  }, [app_id])

  useEffect(() => {
    if (currentPageComment > totalCommentPages) {
      setCurrentPageComment(totalCommentPages || 1)
    }
  }, [comments, currentPageComment, totalCommentPages])

  useEffect(() => {
    if (localStorageValue !== null) {
      setIsLoading(true)
      getShots(currentPage)
    }
  }, [localStorageValue])

  useEffect(() => {
    if (!isEditing) {
      validateForm()
    }
  }, [inputFields, selectedVideo])

  useEffect(() => {
    if (isEditing) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEditing, selectedVideo])

  useEffect(() => {
    if (
      commentSection ||
      ShowShotsModel ||
      ShowShotsPreviewModel ||
      ShowConfirmModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [commentSection, ShowShotsModel, ShowShotsPreviewModel, ShowConfirmModel])

  return (
    <>
      <div className="discussions-wrapper-header common-contnt-wrapper">
        {showCount && (
          <div className="tag_link">
            <h2>{English.G164}</h2>
            <div className="add-video-rows">
              <CommonButton
                label={English.G165}
                imgSrc={Images.AddIco}
                onClick={AddVideo}
                isPrimary
                className="add_new_content"
              />
            </div>
          </div>
        )}
      </div>

      <div
        className={
          showCount !== 3 &&
          'recent-video-component recent-shots-component add-shots-component'
        }
      >
        {/* <div className="shots-grid"> */}
        <div className="">
          {shots?.length > 0 && (
            <div className="flex justify-between ">
              <h2 className="text-[#0B0A0A] !text-2xl italic !font-black uppercase">
                {English.A110}
              </h2>
              <div className="add-video-rows mb-3">
                <CommonButton
                  label={English.A209}
                  // imgSrc={Images.AddIco}
                  onClick={AddVideo}
                  isPrimary
                  className="add_new_content"
                />
              </div>
            </div>
          )}
          {shots?.length === 0 && !isLoading ? (
            <CommonEmptyData
              image={Images.shots_icon}
              mainTitle="No Shots Yet"
              descriptionText="Upload shot to show here"
              onClick={AddVideo}
              addIcon={Images.FileUploadWhite}
              btnText="Upload Shots"
              classNameDescription="font-normal text-[#7A7474] text-[16px]"
            />
          ) : isLoading ? (
            <div className="grid grid-cols-4">
              <div className="shots-item">
                <div className="shots-basic-detail text-center">
                  <Skeleton
                    className="shots-profile m-auto"
                    width={85}
                    height={85}
                  />
                  <h6>
                    <Skeleton width={100} />
                  </h6>
                  <span>
                    <Skeleton width={50} />
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-4 !gap-5 max-[1200px]:grid-cols-3">
              {shots?.map(
                (item, index) => index < (showCount || totalVideos) && (
                <div
                  className="shots-detail-items position-relative"
                  key={index}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="image-buttons z-2">
                    <label>
                      <button
                        type="button"
                        onClick={() => onClickEditPodcast(item)}
                        className="d-none"
                      />
                    </label>
                    <button
                      type="button"
                      className="p-0"
                      onClick={() => {
                            setUpdatingIndex(index)
                            ShowConfirm(item.shot_id)
                          }}
                    />
                    {/* <div
                      type="button"
                      className="button1"
                      onClick={() => onClickComment(item.shot_id)}
                    /> */}
                  </div>
                  <ConfirmationModal
                    isVisible={ShowConfirmModel && updatingIndex === index}
                    onConfirm={deleteShot}
                    onCancel={handleCancels}
                    isLoading={delLoading}
                  />
                  {delLoading && updatingIndex === index && <Loader />}
                  {hoveredItem === index ? (
                    <div className="shots-stories-react w-100 m-0">
                      {/* <div className="shots-header">
                        <div>
                          <img
                            src={item.header.profileImage}
                            className="image-shot-profile"
                            alt="Avatar"
                          />
                        </div>
                        <div className="shots-heading">
                          <p className="shots-p-heading">
                            {item.header.heading}
                          </p>
                          <p className="shots-creadted-at">
                            {timeDifference(item.header.created_at)}
                          </p>
                        </div>
                      </div> */}
                      <ReactPlayer
                        url={item.url}
                        playing={playing}
                        muted={isMuted}
                        width="100%"
                        height="100%"
                        controls={false}
                        onClick={() => setPlaying(false)}
                        onPlay={() => handleClickPlay(item.shot_id)}
                        onPause={handlePause}
                        onEnded={handleEnded}
                        playIcon
                      />
                      <div className="flex justify-evenly items-center shots-buttons">
                        <div className="flex flex-col">
                          <img src={Images.eyeOnWhite} alt="" />
                          <span>{formatViews(item.views)}</span>
                        </div>
                        <div className="!flex !flex-col">
                          <button
                            type="button"
                            className="shot_like"
                            onClick={() => shotLikeData(item.shot_id)}
                          >
                            {item.is_like ? (
                              <img src={Images.like_icon_blue} alt="Unlike" />
                              ) : (
                                <img src={Images.like_icon_white} alt="Like" />
                              )}
                          </button>
                          <span>{item.total_likes}</span>
                        </div>
                        <div className="flex flex-col">
                          <img src={Images.unLike_icon_white} alt="" />
                          <span>{formatViews(item.total_likes)}</span>
                        </div>
                        <div className="flex flex-col cursor-pointer" onClick={() => onClickComment(item.shot_id)}>
                          <img src={Images.commentIconWhite} alt="" />
                          <span>
                            {item.total_comments !== undefined
                                ? formatViews(item.total_comments)
                                : '0'}
                          </span>
                        </div>
                      </div>
                      <div className="video-player">
                        {!playing && !isFinished && (
                        <div className="play-overlay" onClick={handlePlayPause}>
                          <img src={Images.Video_play} alt="Play Button" />
                        </div>
                        )}

                        {isFinished && (
                        <div className="replay-overlay" onClick={handlePlayPause}>
                          <img src={Images.Video_play} alt="Replay Button" />
                        </div>
                        )}
                      </div>

                      {/* <div className="shot_save_bg">
                        <button
                          type="button"
                          onClick={() => OnClickSave(item.shot_id)}
                        >
                          {item.is_save === false ? (
                            <img src={Images.Save} alt="save" />
                              ) : (
                                <img src={Images.Saved} alt="unSave" />
                              )}
                        </button>
                      </div> */}
                    </div>
                  ) : (
                    <div
                      className="shots-default-content position-relative"
                      style={{
                            backgroundImage: `url(${item.cover})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                          }}
                    >
                      {/* <div className="position-absolute view">
                      <img src={Images.View_Icon} alt="" />
                      {formatViews(item.views)}
                    </div> */}
                    </div>
                  )}
                </div>
                  )
              )}
            </div>
          )}

          {commentSection && (
            <CommentModal
              title={English.G166}
              comments={comments}
              setComments={setComments}
              HandleCancel={handleCancel}
              commentIndex={commentIndex}
              commentsList={commentsList}
              editCommentId={editCommentId}
              editReplyId={editReplyId}
              editedCommentText={editedCommentText}
              editedReplyText={editedReplyText}
              handleAddComment={handleAddComment}
              handleAddReply={handleAddReply}
              handleDeleteReply={handleDeleteReply}
              handleEditComment={handleEditComment}
              handleEditReply={handleEditReply}
              handleLikeComment={handleLikeComment}
              shotCommentReplyLike={shotCommentReplyLike}
              handleSaveComment={handleSaveComment}
              handleSaveReply={handleSaveReply}
              isLoadingComments={isLoadingComments}
              newComment={newComment}
              profileData={profileData}
              setCommentIndex={setCommentIndex}
              setEditedCommentText={setEditedCommentText}
              setEditedReplyText={setEditedReplyText}
              setNewComment={setNewComment}
              setShowReplied={setShowReplied}
              shotCommentDelete={shotCommentDelete}
              showReplies={showReplies}
              toggleReplies={toggleReplies}
              currentPageComment={currentPageComment}
              setCurrentPageComment={setCurrentPageComment}
              isDeleting={isDeleting}
              Replied={Replied}
            />
          )}
        </div>

        {totalPages > 1 && currentPage <= totalPages - 1 && (
        <div
          className="p-5 cursor-pointer transition-transform duration-300 ease-in-out hover:scale-150"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <img
            className="justify-self-center"
            src={Images.down_arrow_icon}
            alt="load more"
          />
        </div>
          )}
        {ShowShotsModel && (
          <CommonModalMain
            showCross
            title={English.A209}
            isPrevious={English.G116}
            isNext={English.B58}
            onPressClose={HandleClose}
            // className={className}
            onPressSave={HandleShotCover}
            onPressBack={HandleCancel}
            showFooter
            isButtonDisabled={!isFormComplete && !selectedVideo}
          >
            <div className="w-full h-full flex flex-col px-20 overflow-y-auto">
              <div className="item-details-input">
                <label className="mb-1">{English.A226}</label>
                <CommonInput
                  name="title"
                  placeholder={English.A227}
                  onChange={handleChange}
                  // maxLength={100}
                  value={capitalizeFirstLetter(inputFields.title)}
                />
              </div>
              <div className="flex-1 !mb-5">
                <div className="upload-input upload-input-with-border shot_upload h-full flex items-center back">
                  {!selectedVideo && (
                    <div className="upload-input">
                      <CommonInput
                        type="file"
                        id="upload-file"
                        accept="video/*"
                        onChange={handleVideoChange}
                        className="d-none"
                      />
                      {!selectedVideo && (
                        <CommonEmptyData
                          image={Images.FileUpload}
                          mainTitle={English.A209}
                          descriptionText={English.A208}
                          onClick={() => document.getElementById('upload-file').click()}
                          addIcon={Images.plusrounded}
                          btnText={English.A210}
                          className="!border-none !bg-transparent"
                        />
                      )}
                    </div>
                  )}
                  {selectedVideo && (
                    <div className="h-full relative">
                      {/* <div className="shots-stories"> */}
                      <div className="flex h-full justify-center relative">
                        <video
                          className="!h-full !w-full"
                          ref={videoRef}
                          poster={selectedFilePath}
                          controls={false}
                          autoPlay
                          muted={isMuted}
                          playsInline
                          onClick={pauseHandel}
                        >
                          <source src={selectedVideo} type="video/mp4" />
                        </video>
                      </div>
                      <div className="shots-control1 !right-4 !top-4">
                        <div
                          className="shots-play-control"
                          onClick={pauseHandel}
                        >
                          {playPauseIcon}
                        </div>
                        <div
                          className="shots-volume-control"
                          onClick={VolumeHandel}
                        >
                          {playVolumeIcon}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </CommonModalMain>
        )}
        {ShowShotsPreviewModel && (
          <CommonModalMain
            showCross
            title={English.A209}
            isPrevious={English.G116}
            isNext={English.G117}
            onPressClose={HandleClose}
            // className={className}
            onPressSave={handlePublishClick}
            onPressBack={HandleCancel}
            showFooter
            isButtonDisabled={(!isFormComplete && !selectedVideo) || loading}
            cancelButtonDisabled={loading}
            closeIcon={loading}
          >
            <div className="w-full h-full flex flex-col gap-5 justify-between overflow-y-auto">
              <div className="px-20 !mb-5">
                <div className="upload-input h-full">
                  <div className="shots-stories-react !h-full">
                    <div className="h-full">
                      <video
                        className="!h-[71vh] !w-[330px]"
                        ref={videoRef}
                        poster={selectedFilePath}
                        controls={false}
                        autoPlay
                        muted={isMuted}
                        playsInline
                        onClick={pauseHandel}
                        onPlay={() => setIsPlaying(true)}
                        onPause={handlePause}
                        onEnded={handleEnded}
                      >
                        <source src={selectedVideo} type="video/mp4" />
                      </video>
                    </div>
                    <div
                      style={{
                          display: 'flex',
                          alignItems: 'center',
                          alignContent: 'center',
                          columnGap: '10px',
                          top: '11px',
                          left: '15px',
                          zIndex: '2',
                          position: 'absolute'
                        }}
                    >
                      <div className="w-[58px]">
                        <img
                          src={s3ImageProfile + userData.profile_image}
                          alt="Avatar"
                          style={{
                              'vertical-align': 'middle',
                              width: '42px',
                              height: '42px',
                              borderRadius: '50%',
                              border: '0.74px solid #FFF'
                            }}
                        />
                      </div>
                      <div className="w-full">
                        <p className="text-[#ffff] font-semibold text-[16px] m-0 items-start">
                          {userData.user_name}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                          display: 'flex',
                          alignItems: 'center',
                          alignContent: 'center',
                          columnGap: '10px',
                          bottom: '10px',
                          left: '15px',
                          zIndex: '2',
                          position: 'absolute'
                        }}
                      className="!pr-1"
                    >
                      <p
                        style={{
                            color: 'white',
                            fontWeight: '600',
                            fontSize: '14px',
                            margin: 0,
                            textAlign: 'start'
                          }}
                        className="shots_title !pr-1"
                      >
                        {inputFields?.title}
                      </p>
                    </div>
                    <div className="shots-control">
                      <div
                        className="shots-play-control"
                        onClick={pauseHandel}
                      >
                        {playPauseIcon}
                      </div>
                      <div
                        className="shots-volume-control"
                        onClick={VolumeHandel}
                      >
                        {playVolumeIcon}
                      </div>
                    </div>
                    <div className="video-player">
                      {!isPlaying && !isFinished && (
                      <div className="play-overlay" onClick={handlePlay}>
                        <img src={Images.Video_play} alt="Play Button" />
                      </div>
                        )}

                      {isFinished && (
                      <div className="replay-overlay" onClick={handlePlay}>
                        <img src={Images.Video_play} alt="Replay Button" />
                      </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isVideoUploading && (
              <UploadingComponent uploadPercentage={uploadPercentage} />
            )}
            {isUploading && <Loader />}
          </CommonModalMain>
        )}
      </div>
      {/* {shots?.length >= 2 && showCount && (
        <div
          className="view_all text-end mt-4"
          onClick={() => navigate(`/profile-builder/${id}`, { state: { defaultIndex: 3 } })}
        >
          {English.G144}
        </div>
      )} */}
    </>
  )
}
export default ShotsComponent

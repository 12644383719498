/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react'
import '../HotelComponent/Discount.css'
import { LoadScriptNext } from '@react-google-maps/api'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import {
  GOOGLE_MAP_LIBRARIES,
  locationSuggestion,
  s3ImageOrganizer
} from '../../../services/Service'
import MapComponent from '../../MapComponent/MapComponent'
import ReviewsSection from './RatingCards'
import { Images } from '../../../helpers'
import { APICall, EndPoints } from '../../../services'

const TicketsShow = ({ latitude, longitude, eventId }) => {
  const [inputFields, setInputFields] = useState({
    latitude: latitude || '',
    longitude: longitude || ''
  })
  const [markerPosition, setMarkerPosition] = useState(null)

  const ticketList = [
    {
      name: 'Standard Ticket',
      price: '89,95 €',
      discountPrice: '(112,99 €)',
      image: Images.ticketListImage
    },
    {
      name: 'Premium Ticket',
      price: '89,95 €',
      discountPrice: '(112,99 €)',
      image: Images.ticketListImage
    },
    {
      name: 'VIP Ticket',
      price: '89,95 €',
      discountPrice: '(112,99 €)',
      image: Images.ticketListImage
    }
  ]

  const app_id = useSelector((state) => state.user?.app_id)

  const [discountListData, setDiscountListData] = useState([])

  const similarEventData = () => {
    const payload = {
      app_id: app_id?.id,
      event_id: eventId
    }
    APICall('post', payload, `${EndPoints.similarEvent}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setDiscountListData(res?.decryptedData.data)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const eventLikeData = (event_id) => {
      const platFormData = {
        app_id: app_id?.id,
        event_id
      }
      APICall('post', platFormData, `${EndPoints.eventLike}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setDiscountListData(
              discountListData.map((item) => (item.event_id === event_id
                  ? { ...item, is_like: !item.is_like }
                  : item))
            )
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message)
        })
    }

  useEffect(() => {
    similarEventData()
  }, [])

  useEffect(() => {
    if (inputFields.latitude && inputFields.longitude) {
      setMarkerPosition({
        lat: Number(inputFields.latitude),
        lng: Number(inputFields.longitude)
      })
    }
  }, [inputFields.latitude, inputFields.longitude])

  const SimilarEventItem = ({ data, index }) => {
    return (
      <div key={index}>
        <div className="flex flex-col border border-[#E1E2E5] border-l-0 rounded-[20px]">
          <div className="relative">
            <img
              src={s3ImageOrganizer + data.images[0]}
              alt="roomImage"
              className="!w-full !h-[194px] object-center rounded-tl-[20px] rounded-tr-[20px]"
            />
          </div>
          <div className="flex items-center justify-center rounded-[10px] bg-[#fff] absolute left-[15px] !top-auto mt-3 !h-[28px] !w-[28px]">
            <button
              type="button"
              className="video_like"
              onClick={(e) => {
                e.stopPropagation()
                eventLikeData(data.event_id)
              }}
            >
              {data?.is_like ? (
                <img className="h-[20px] w-[20px]" src={Images.LikeFill_blue} alt="Unlike" />
              ) : (
                <img className="h-[20px] w-[20px]" src={Images.Feed_UnLike} alt="Like" />
              )}
            </button>
          </div>
          <div className="p-[10px] flex flex-col justify-between w-full">
            <div className="flex justify-between items-center">
              <h6 className="text-[14px] font-extrabold uppercase">
                {data.name}
              </h6>
              <div className="flex gap-1 items-center">
                <img src={Images.Fire_icon_blue} alt="a" />
                <div className="flex items-center gap-1">
                  <p className="mb-0 font-extrabold text-[#020B09] text-[10px] italic">
                    DEAL
                  </p>
                </div>
              </div>
            </div>
            <div className="flex gap-2 mb-2">
              <img className="h-[15px] w-[15px]" src={Images.Location} alt="" />
              <h6 className="text-[10px] font-medium text-[#151515] mb-0">
                {data.address}
              </h6>
            </div>
            <div className="flex gap-2 mb-2">
              <span key={index} className="text-blue-900 text-xl">
                ★
              </span>
              <h6 className="text-[10px] font-extrabold text-[#0B0A0A] mt-2">
                4.9
              </h6>
            </div>
            <div className="flex justify-between items-center mt-2">
              <div className="flex gap-2">
                <labe className="text-[#151515] font-medium text-[15px] capitalize">
                  see details
                </labe>
                <p className="mb-0 text-[#151515] font-bold text-[15px] uppercase">
                  {data.coupan_code}
                </p>
              </div>
              <img className="rotate-180" src={Images.left_arrow_icon} alt="" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="">
      <div className="statistic-chart-container position-relative">
        <div className="">
          <div className="">
            <div className="mb-3">
              <h3 className="text-[#151515] italic font-black text-[18px] uppercase mb-3">
                Ticket
              </h3>
              <div className="grid grid-cols-3 gap-[25px]">
                {ticketList.map((data, i) => (
                  <div key={i}>
                    <div className="flex border border-[#E1E2E5] border-l-0 rounded-[20px]">
                      <div>
                        <img
                          src={data.image}
                          alt="roomImage"
                          className="!w-[130px] !h-[100px] object-center rounded-tl-[20px] rounded-bl-[20px]"
                        />
                      </div>
                      <div className="py-2.5 px-[10px] flex flex-col w-full gap-3 flex-1">
                        <div className="">
                          <div className="items-center">
                            <h6 className="text-Main font-black text-sm mb-2">
                              {data.name}
                            </h6>
                            <div className="flex gap-2 items-center">
                              <div className="flex gap-2">
                                <img src={Images.money_icon} alt="" />
                                <p className="mb-0 text-[10px] font-extrabold">
                                  {data.price}
                                </p>
                              </div>
                              <p className="mb-0 font-normal text-[10px] line-through">
                                {data.discountPrice}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <p className="mb-0 text-[#151515] font-medium text-[10px]">
                            Book Now
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-3">
              <h3 className="text-[#151515] italic font-black text-[18px] uppercase mb-3">
                Location
              </h3>
              <div className="form-group h-[calc(80vh-245px)] top-0 rounded-[15px] overflow-hidden">
                <LoadScriptNext
                  googleMapsApiKey={locationSuggestion}
                  libraries={GOOGLE_MAP_LIBRARIES}
                >
                  <MapComponent
                    inputFields={inputFields}
                    setInputFields={setInputFields}
                    markerPosition={markerPosition}
                  />
                </LoadScriptNext>
              </div>
            </div>
            <div>
              <h3 className="text-[#151515] italic font-black text-[18px] uppercase mb-3 mt-3">
                Similar Events
              </h3>
              <div className="grid grid-cols-3 gap-[25px]">
                {discountListData.map((data, index) => (
                  <SimilarEventItem data={data} index={index} key={index} />
                ))}
              </div>
            </div>
            <div className="mt-3 h-full">
              <ReviewsSection />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TicketsShow

/* eslint-disable import/no-named-as-default-member */
/* eslint-disable eqeqeq */
/* eslint-disable no-multi-assign */
/* eslint-disable prefer-template */
import axios from 'axios'
import imageCompression from 'browser-image-compression'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile } from '@ffmpeg/util'
import { toast } from 'react-toastify'
import Utility from '../helpers/Utility'
import { setUserData } from '../redux/UserSlice/UserSlice'
import { ReduxStore } from '../redux'

export const getData = (url) => {
  const token = Utility.getToken()

  return axios({
    method: 'GET',
    url,
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => response)
    .catch((error) => console.log(error))
}

export const postData = (url, data) => {
  const formData = new FormData()
  formData.append('data', dataEncrypt(data))
  const token = Utility.getToken()

  return axios({
    method: 'POST',
    url,
    data: formData,
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => {
      if (response?.data?.code === 422 || response?.data?.code === 400) {
        toast.error('Something went wrong. Please try again later.')
      } else if (response?.data?.code === 410) {
        toast.error(response?.data?.message)
      } else if (response?.data?.code === 404) {
        toast.error('Page not found.')
      }
      return response
    })
    .catch((error) => {
      if (error?.response) {
        if (error?.response?.status === 422 || error?.response.status === 400) {
          toast.error('Something went wrong. Please try again later')
        } else if (error?.response?.status === 500) {
          toast.error('Server error. Please try again later.')
        } else if (error?.response?.status === 404) {
          toast.error('Page not found.')
        }
      } else {
        toast.error(error?.response?.message)
      }
      return {
        data: {
          success: false
        }
      }
    })
}

export const ImagePostData = (url, data) => {
  const formData = new FormData()
  formData.append('image', data)
  return axios.post(url, formData).then((res) => {
    return res
  })
}

export const ImagePostDataStore = (url, data, type) => {
  const formData = new FormData()
  formData.append('file', data)
  formData.append('type', type)
  const token = Utility.getToken()
  return axios({
    method: 'POST',
    url,
    data: formData,
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => response)
    .catch((error) => console.log(error))
}

export const compressImage = async (imageFile) => {
  // const options = {
  //   maxSizeMB: 0.05, // (50 KB)
  //   maxWidthOrHeight: 1024,
  //   initialQuality: 0.9,
  //   useWebWorker: true
  // }
  const options = {
    maxSizeMB: 2,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  }
  try {
    const compressedFile = await imageCompression(imageFile, options)
    return compressedFile
  } catch (error) {
    console.error('Image compression error', error)
  }
}

export const uploadFileData = (url, formData, onUploadProgress) => {
  const token = Utility.getToken()
  return axios
    .post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      onUploadProgress: (progressEvent) => {
        if (typeof onUploadProgress === 'function') {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          onUploadProgress(percentCompleted)
        }
      }
    })
    .then((res) => {
      return res
    })
}

export const compressVideo = async (file, setPercentage, ProgressStatus) => {
  const ffmpeg = new FFmpeg({ log: true })

  try {
    await ffmpeg.load()

    // ffmpeg.on('progress', (progress) => {
    //   if (progress) {
    //     const progressPercentage = Math.floor(progress.progress * 100)
    //     onProgress(progressPercentage)
    //   }
    // })
    ffmpeg.on('progress', (progress) => {
      if (progress) {
        const progressPercentage = Math.floor(progress.progress * 100)
        if (setPercentage && ProgressStatus) {
          setPercentage(progressPercentage)
          if (progressPercentage !== 100) {
            ProgressStatus(true)
          } else {
            ProgressStatus(false)
          }
        }
      }
    })

    ffmpeg.off()

    await ffmpeg.writeFile('input', await fetchFile(file))

    const isAudio = file.type == 'audio/mpeg'

    const outputFile = isAudio ? 'output.mp3' : 'output.mp4'
    const ffmpegArgs = isAudio
      ? ['-i', 'input', '-b:a', '128k', outputFile]
      : [
          '-i',
          'input',
          '-c:v',
          'libx264',
          '-b:v',
          '800k',
          '-crf',
          '28',
          '-preset',
          'slow',
          '-c:a',
          'aac',
          '-b:a',
          '128k',
          outputFile
        ]

    await ffmpeg.exec(ffmpegArgs)

    const fileData = await ffmpeg.readFile(outputFile)
    const compressedFile = new Blob([fileData.buffer], {
      type: isAudio ? 'audio/mp3' : 'video/mp4'
    })

    const newFile = new File([compressedFile], outputFile, {
      type: isAudio ? 'audio/mp3' : 'video/mp4'
    })

    return newFile
  } catch (err) {
    throw new Error(`Media compression failed: ${err.message}`)
  }
}

const generateRandomString = () => {
  const randomString = Math.random().toString(36).substring(2, 18)
  const remainrandomString = Math.random().toString(36).substring(2, 18)
  return randomString.padEnd(16, remainrandomString).substring(0, 16)
}

export const dataEncrypt = (data) => {
  const jsonString = JSON.stringify(data)
  const key = generateRandomString(16)

  let result = ''
  const stringChars = Array.from(jsonString)
  const keyChars = Array.from(key)

  for (let i = 0; i < stringChars.length; i++) {
    const char = stringChars[i]
    let index = (i % keyChars.length) - 1
    index = (index + keyChars.length) % keyChars.length
    const keychar = keyChars[index]

    const encryptedChar = String.fromCodePoint(
      char.codePointAt(0) + keychar.codePointAt(0)
    )

    result += encryptedChar
  }

  // Encode the encrypted result in Base64 (for safe transmission)
  const base64EncodedResult = base64EncodeUtf8(result)

  return base64EncodedResult + key
}

const base64EncodeUtf8 = (str) => {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => String.fromCharCode('0x' + p1)))
}


export const dataDecrypt = (encryptedString) => {
  if (typeof encryptedString !== 'string' || encryptedString.length <= 16) {
    return encryptedString
  }
  try {
    const key = encryptedString.slice(-16)
    const encryptedData = encryptedString.slice(0, -16)
    const decodedData = base64DecodeUtf8(encryptedData)

    let result = ''
    const decodedChars = Array.from(decodedData)

    for (let i = 0; i < decodedChars.length; i++) {
      const char = decodedChars[i]
      let index = (i % key.length) - 1
      index = (index + key.length) % key.length
      const keychar = key[index]

      const decryptedChar = String.fromCodePoint(
        char.codePointAt(0) - keychar.codePointAt(0)
      )

      result += decryptedChar
    }

    return JSON.parse(result)
  } catch (e) {
    console.error('Decryption error:', e)
    return encryptedString
  }
}

const base64DecodeUtf8 = (str) => {
  return decodeURIComponent(
    atob(str)
      .split('')
      .map((c) => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
      .join('')
  )
}

export const setUserSliceData = (data, type) => {
  ReduxStore.dispatch(setUserData({ data, type }))
}

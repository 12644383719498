import React, { memo, useEffect, useState } from 'react'
import { RotatingLines } from 'react-loader-spinner'
import { Images } from '../../helpers'

const ImageComponent = ({ imageUrl, className, type, onClickImage }) => {
  const [image, setImage] = useState('')

  useEffect(() => {
    const image = new Image()
    image.src = imageUrl

    image.onload = () => {
      setImage(imageUrl)
    }
    image.onerror = () => {
      switch (type) {
        case 'cardImage':
          setImage(Images.CardImageOverlay)
          break
        case 'profileImage':
          setImage(Images.Friend_image1)
          break

        default:
          setImage(Images.CardImageOverlay)
          break
      }
    }
  }, [imageUrl, type])

  return (
    <div className="relative">
      <img
        src={image}
        alt=""
        className={className}
        loading="lazy"
        key={imageUrl}
        onClick={(e) => {
          if (onClickImage) {
            e.stopPropagation()
            onClickImage()
          }
        }}
      />
      <div className={`absolute top-0 left-0 h-full w-full ${!image ? 'flex' : '!hidden'} bg-white`}>
        <div className="loader-container visible">
          <RotatingLines
            visible
            height="40"
            width="40"
            color="#001597"
            strokeColor="#001597"
            strokeWidth="4"
            animationDuration="0.90"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      </div>
    </div>
  )
}

export default memo(ImageComponent)

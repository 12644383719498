import React, { useRef, useState, useEffect, forwardRef } from 'react'
import Images from '../../helpers/Images'

const VideoPlayer = forwardRef(({ videoSrc, posterSrc, onVideoEnd, onProgres, onPlay, className = '', videoClassName }, ref) => {
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const [progress, setProgress] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [isMuted, setIsMuted] = useState(false)

  const handlePlay = () => {
    if (!videoSrc) {
      return
    }
    const video = videoRef.current
    if (video) {
      video.play()
      setIsPlaying(true)
    }
  }

  const handlePause = () => {
    setIsPlaying(false)
  }

  const handleEnded = () => {
    setIsPlaying(false)
    setIsFinished(true)
    if (onVideoEnd) onVideoEnd()
  }

  const togglePlayPause = () => {
    if (!videoSrc) {
      return
    }
    const video = videoRef.current
    if (video.paused) {
      video.play()
      setIsPlaying(true)
    } else {
      video.pause()
      setIsPlaying(false)
    }
  }

  const toggleMute = () => {
    if (!videoSrc) {
      return
    }
    const video = videoRef.current
    video.muted = !video.muted
    setIsMuted(video.muted)
  }

  const handleProgress = () => {
    if (!videoSrc) {
      return
    }
    const video = videoRef.current
    const progress = (video.currentTime / video.duration) * 100
    setProgress(progress)
    if (onProgres) {
      onProgres(progress)
    }
    setCurrentTime(video.currentTime)
    setDuration(video.duration)
  }

  const handleTimelineClick = (e) => {
    if (!videoSrc) {
      return
    }
    const video = videoRef.current
    const timelineWidth = e.target.offsetWidth
    const clickX = e.nativeEvent.offsetX
    const newTime = (clickX / timelineWidth) * video.duration
    video.currentTime = newTime
  }

  const toggleFullscreen = () => {
    if (!videoSrc) {
      return
    }
    const video = videoRef.current
    if (!document.fullscreenElement) {
      video.requestFullscreen()
    } else {
      document.exitFullscreen()
    }
  }

  // const formatTime = (time) => {
  //   const minutes = Math.floor(time / 60)
  //   const seconds = Math.floor(time % 60)
  //   return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  // }

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time % 3600) / 60)
    const seconds = Math.floor(time % 60)

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }

  useEffect(() => {
    const video = videoRef.current
    if (video) {
      video.addEventListener('loadedmetadata', () => {
        setDuration(video.duration)
      })
    }
  }, [])

  useEffect(() => {
    if (ref) {
      if (typeof ref === 'function') {
        ref(videoRef.current)
      } else {
        ref.current = videoRef.current
      }
    }
  }, [ref])


  const playVolumeIcon = isMuted ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15"
      viewBox="0 0 15 11"
      fill="none"
    >
      <path
        d="M0.966462 5.50142C0.966462 6.3205 0.930479 7.43966 1.39751 7.85979C1.86453 8.27992 2.14213 8.14929 2.93902 8.21284C3.7359 8.27639 5.42769 10.8184 6.7308 10.0134C7.40684 9.4415 7.45909 8.24817 7.45909 5.50142C7.45909 2.75467 7.40685 1.55781 6.73733 0.989398C5.43096 0.184438 3.74571 2.72636 2.94555 2.78991C2.1454 2.85346 1.83841 2.75461 1.39751 3.14297C0.956607 3.53132 0.966462 4.67881 0.966462 5.50142Z"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 3.5C13.0598 4.08693 13.0359 4.28454 12.4995 4.99965C11.9632 5.71476 11.4397 6.41272 10.9995 6.99965"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 3.5C11.1087 4.10874 11.299 4.29904 12.0008 5.00078C12.7025 5.70253 13.3913 6.39126 14 7"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15"
      viewBox="0 0 14 11"
      fill="none"
    >
      <path
        d="M0.966462 5.50142C0.966462 6.3205 0.930479 7.43966 1.39751 7.85979C1.86453 8.27992 2.14213 8.14929 2.93902 8.21284C3.7359 8.27639 5.42769 10.8184 6.7308 10.0134C7.40684 9.4415 7.45909 8.24817 7.45909 5.50142C7.45909 2.75467 7.40685 1.55781 6.73733 0.989398C5.43096 0.184438 3.74571 2.72636 2.94555 2.78991C2.1454 2.85346 1.83841 2.75461 1.39751 3.14297C0.956607 3.53132 0.966462 4.67881 0.966462 5.50142Z"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5059 1.43457C12.273 2.62607 12.6843 4.04296 12.6843 5.49468C12.6843 6.94639 12.273 8.36329 11.5059 9.55478"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.96289 3.04004C10.36 3.78848 10.569 4.6362 10.569 5.499C10.569 6.36179 10.36 7.20959 9.96289 7.95804"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )

  return (
    // <div className={`video-player aspect-video ${className}`}>
    <div className={`video_player aspect-video ${className}`}>
      <video
        width="100%"
        height="394"
        className={`aspect-video ${videoClassName}`}
        // ref={(vRef) => {
        //   videoRef.current = vRef
        //   if (ref) {
        //     ref.current = vRef
        //   }
        // }}
        ref={videoRef}
        // width="100%"
        // height="394"
        poster={posterSrc}
        controlsList="nodownload noplaybackrate"
        disablePictureInPicture
        allowFullScreen
        onPlay={() => {
          if (onPlay) {
            onPlay()
          }
          setIsPlaying(true)
        }}
        onPause={handlePause}
        onEnded={handleEnded}
        onClick={togglePlayPause}
        onTimeUpdate={handleProgress}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>

      <div className="custom-controls">
        <div className="timeline" onClick={handleTimelineClick}>
          <div className="progress" style={{ width: `${progress}%` }} />
          <div
            className="progress-dot"
            style={{
              left: `calc(${progress}% - 7.5px)`
            }}
          />
        </div>
        <div className="play-time-full">
          <div className="play_icon">
            <div className="play_screen">
              <button
                onClick={togglePlayPause}
                type="button"
                className="play-pause-btn"
              >
                {isPlaying ? '⏸' : '▶'}
              </button>
              <button
                onClick={toggleMute}
                type="button"
                className="mute-unmute-btn"
              >
                {playVolumeIcon}
              </button>
              <span className="time">
                {formatTime(currentTime)}
                {' '}
                /
                {formatTime(duration)}
              </span>
            </div>
            <div>
              <button
                onClick={toggleFullscreen}
                type="button"
                className="fullscreen-btn"
              >
                ⛶
              </button>
            </div>
          </div>
        </div>
      </div>

      {!isPlaying && !isFinished && (
        <div className="play-overlay" onClick={handlePlay}>
          <img src={Images.Video_play} alt="Play Button" />
        </div>
      )}

      {isFinished && (
        <div className="replay-overlay" onClick={handlePlay}>
          <img src={Images.Video_play} alt="Replay Button" />
        </div>
      )}
    </div>
  )
})

export default VideoPlayer

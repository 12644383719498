/* eslint-disable max-len */
/* eslint-disable no-tabs */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react'
import { English, Images } from '../../../helpers'
import Loader from '../../Loader/Loader'
import { urlImageProductPathStore } from '../../../services/Service'
import Utility from '../../../helpers/Utility'

const ProductItem = ({ data, i, isDeal, EditLibrary, HandleCancelConfirm, setShowConfirmModel, ShowConfirmModel, setUpdatingIndex, deleteTrending, loading, updatingIndex, productLikeData }) => {
  return (
    <div
      key={data.id}
      className="position-relative rounded-xl overflow-hidden border-[1px] border-BorderLight"
    >
      <div className={`image-buttons z-1 ${isDeal && '!hidden'}`}>
        <label>
          <button
            type="button"
            className="d-none"
            onClick={() => EditLibrary(data)}
          />
        </label>
        <button
          type="button"
          className="p-0"
          onClick={() => {
            setShowConfirmModel(data)
            setUpdatingIndex(i)
          }}
        />
      </div>
      {ShowConfirmModel && (
        <div className="add-item-model z-50">
          <div className="add-item-contents h-auto w-auto">
            <div className="item-details-input pt-0">
              <h3 className="mb-0 text-center">{English.G119}</h3>
              <div className="button-row justify-content-center">
                <button
                  type="button"
                  className="secondary-btn-modelConfirm"
                  onClick={HandleCancelConfirm}
                >
                  <span>{English.G116}</span>
                </button>
                <button
                  type="button"
                  disabled={loading}
                  className="primary-btn-modelConfirm"
                  onClick={() => {
                    deleteTrending(ShowConfirmModel)
                  }}
                >
                  {English.G120}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && updatingIndex === i && <Loader />}
      <div
        className="product-item-cover w-full aspect-[0.77/1] !rounded-none"
        style={{
          backgroundImage: `url(${urlImageProductPathStore + data.image})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        {isDeal
					? <div className="absolute bottom-2 right-2 bg-white rounded-full flex flex-row">
						<div className="font-bold text-[16px] px-2 py-1">{`${Utility.calculateDiscountPercentage(data.regular_price, data.discount_price)}% OFF`}</div>
					</div>
					: null}
      </div>
      <div className="product-item-details justify-content-between">
        <div className="flex flex-row flex-1 items-center content-center">
          <div className="flex-1 font-bold text-[18px]">
            {data.regular_price}
						€
          </div>
          <button
            type="button"
            className="h-[20px] w-[20px]"
            disabled={isDeal}
            onClick={productLikeData}
          >
            {data?.is_like ? (
              <img className="h-full w-full" src={Images.LikeFill_blue} alt="Unlike" />
            ) : (
              <img className="h-full w-full" src={Images.Feed_UnLike} alt="Like" />
            )}
          </button>
        </div>
        <span>{data.name}</span>
      </div>
    </div>
  )
}

export default ProductItem

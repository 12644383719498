/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react'
import './RecentPodcast.css'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import { s3ImagePodcast, s3ImageProfile } from '../../services/Service'
import {
  compressImage,
  compressVideo,
  dataDecrypt
} from '../../services/http-services'
import PlatformTag from '../ContentManager/PlatformTag'
import Loader from '../Loader/Loader'
import {
  formatDurationPodcast,
  formatMinutes,
  lockScroll,
  timeDifference,
  unlockScroll
} from '../../helpers/Utility'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import CommonModalMain from '../CommonModalMain'
import UploadingComponent from '../UploadingComponent/UploadingComponent'
import AudioPlayer from './AudioPlayer'

const PodcastComponent = ({ showCount }) => {
  const userData = useSelector((state) => state.user?.userData)
  const app_id = useSelector((state) => state.user?.app_id)
  const audioRef = useRef(null)
  const audioRefs = useRef({})
  const [duration, setDuration] = useState(0)

  const [podcast, setPodcast] = useState([])
  const [ShowPodcastModel, setShowPodcastModel] = useState(false)
  const [ShowPodcastCoverModel, setShowPodcastCoverModel] = useState(false)
  const [ShowPodcastPreviewModel, setShowPodcastPreviewModel] = useState(false)
  const [inputFields, setInputFields] = useState({
    title: '',
    description: '',
    minutes: '',
    attachment: '',
    cover_attachment: '',
    app_id: [],
    host_id: 0,
    search: ''
  })
  const [selectedFilePath, setSelectedFilePath] = useState('')
  const [selectedAudioName, setSelectedAudioName] = useState('')
  const [selectedFileObj, setSelectedFileObj] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState('')
  const [selectedVideoObj, setSelectedVideoObj] = useState('')
  const [hostName, setHostName] = useState([])
  const [Platformtags, setPlatformTags] = useState([])
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [delPodcastId, setDelPodcastId] = useState()
  const [loading, setLoading] = useState(false)
  const [delLoading, setDelLoading] = useState(false)
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [previewModel, setPreviewModel] = useState(false)
  const [plays, setPlays] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [initialPlatformTags, setInitialPlatformTags] = useState([])
  const [initialDescription, setInitialDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  // const [hoveredIndex, setHoveredIndex] = useState(null)
  const [isPlaying, setIsPlaying] = useState({})
  const [localStorageValue, setLocalStorageValue] = useState(app_id || null)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [expandedDescription, setExpandedDescription] = useState(false)
  const [expandedTitle, setExpandedTitle] = useState(false)
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [isVideoUploading, setIsVideoUploading] = useState(false)

  const AddPodcast = () => {
    setShowPodcastModel(true)
    clearValue()
  }
  const AddPodcastCover = () => {
    setShowPodcastCoverModel(true)
    setShowPodcastModel(false)
  }

  const HandleClose = () => {
    clearValue()
    setPreviewModel(false)
    setShowPodcastCoverModel(false)
    setShowPodcastModel(false)
    setShowPodcastPreviewModel(false)
    setIsEditing(false)
  }
  const handleCancel = () => {
    clearValue()
    setShowConfirmModel(false)
    // setIsModalVisible(false)
  }
  const HandleCancelCover = () => {
    setShowPodcastModel(true)
    setShowPodcastCoverModel(false)
  }
  // const HandleCancelPreview = () => {
  //   setShowPodcastPreviewModel(false)
  //   setShowPodcastCoverModel(true)
  // }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const getSelectedAppId = (app) => {
    setInputFields({ ...inputFields, app_id: app.map((a) => a.id) })
  }
  const isAppsSelected = inputFields.app_id?.length > 0

  const clearValue = () => {
    setSelectedFilePath('')
    setSelectedFileObj('')
    setSelectedVideo('')
    setSelectedVideoObj('')
    setSelectedAudioName('')
    setPlatformTags([])
    setInputFields({
      ...inputFields,
      title: '',
      description: '',
      minutes: '',
      attachment: '',
      cover_attachment: '',
      app_id: [],
      host_id: 0
    })
    setLoading(false)
    setIsVideoUploading(false)
    setUploadPercentage(0)
  }

  const addPodcastApiCall = () => {
    const data = {
      title: inputFields.title,
      description: inputFields.description,
      attachment: inputFields.attachment,
      cover_attachment: inputFields.cover_attachment,
      app_id: [app_id?.id],
      video_id: inputFields.video_id,
      minutes: inputFields.minutes,
      host_id: inputFields.host_id
    }
    APICall('post', data, `${EndPoints.addPodcast}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            clearValue()
            setLoading(false)
            getPodcast(null, currentPage)
          }, 5000)
          setShowPodcastCoverModel(false)
          setShowPodcastModel(false)
          toast.success(res?.data?.message)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const delay = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  const handlePublishClick = async () => {
    setShowPodcastPreviewModel(false)
    setPreviewModel(false)

    const durationInSeconds = Math.trunc(duration)
    const formattedDuration = formatDurationPodcast(durationInSeconds)

    inputFields.minutes = formattedDuration

    setLoading(true)

    try {
      if (selectedFileObj) {
        const imageFile = await compressImage(selectedFileObj)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'podcast')

        await APICall(
          'post',
          coverFormData,
          EndPoints.uploadImage,
          'creator',
          true
        )
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedCoverData = res?.decryptedData
              inputFields.cover_attachment = decryptedCoverData
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((e) => {
            toast.error(e?.data?.message)
          })
      }

      if (!isEditing || selectedVideoObj) {
        const videoFile = await compressVideo(
          selectedVideoObj,
          setUploadPercentage,
          setIsVideoUploading
        )

        const videoFormData = new FormData()
        videoFormData.append('file', videoFile)
        videoFormData.append('type', 'podcast')

        await APICall(
          'post',
          videoFormData,
          EndPoints.uploadImage,
          'creator',
          true
        )
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedVideoData = dataDecrypt(res?.data?.data)
              inputFields.attachment = decryptedVideoData
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((e) => {
            toast.error(e?.data?.message)
          })
      }

      await delay(5000)

      if (isEditing) {
        editPodcastApiCall()
      } else {
        addPodcastApiCall()
      }
    } catch (error) {
      setLoading(false)
      toast.error(error.message || 'An error occurred')
    }
  }

  const handleFileChange = (file) => {
    const maxFileSizeMB = 10
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024

    if (file.size > maxFileSizeBytes) {
      toast.error(`File size exceeds ${maxFileSizeMB} MB`)
      return
    }

    const reader = new FileReader()
    reader.onload = (e) => {
      setSelectedFilePath(e.target.result)
      setSelectedFileObj(file)
    }
    reader.readAsDataURL(file)
  }

  const handleVideoChange = (event) => {
    const maxFileSizeMB = 500
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024
    const maxDurationMinutes = 90

    const fileInput = event.target
    const selectedFile = fileInput.files[0]

    if (selectedFile) {
      if (selectedFile.size > maxFileSizeBytes) {
        toast.error(`Video file size exceeds ${maxFileSizeMB} MB`)
        return
      }

      const video = document.createElement('video')
      video.src = URL.createObjectURL(selectedFile)

      video.onloadedmetadata = () => {
        const durationMinutes = video.duration / 60

        if (durationMinutes > maxDurationMinutes) {
          toast.error(`Video duration exceeds ${maxDurationMinutes} minutes`)
          return
        }

        setSelectedVideoObj(selectedFile)
        setSelectedAudioName(selectedFile.name)
        setSelectedVideo(video.src)
      }
    }
  }

  const podcastHostApiCall = () => {
    APICall('post', {}, `${EndPoints.hostList}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setHostName(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  // const [isModalVisible, setIsModalVisible] = useState(false)

  const ShowConfirm = (videoId) => {
    setDelPodcastId(videoId)
    setShowConfirmModel(true)
    // setIsModalVisible(true)
    Object.keys(audioRefs.current).forEach((index) => {
      const audio = audioRefs.current[index]
      if (audio) {
        audio.pause()
        audio.currentTime = 0
        setIsPlaying((prev) => ({ ...prev, [index]: false }))
      }
    })
  }

  const deletePodcast = () => {
    const data = {
      podcast_id: delPodcastId
    }
    setDelLoading(true)
    APICall('post', data, `${EndPoints.deletePodcast}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setTimeout(() => {
            getPodcast(null, currentPage)
            // setIsModalVisible(false)
          }, 3000)
          clearValue()
          setDelLoading(false)
          toast.success(res?.data?.message)
        } else {
          setDelLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setDelLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const editPodcastApiCall = () => {
    const data = {
      title: inputFields.title,
      description: inputFields.description,
      host_id: inputFields.host_id,
      attachment: inputFields.attachment,
      cover_attachment: inputFields.cover_attachment,
      app_id: Platformtags.map((d) => d.id),
      podcast_id: inputFields.podcast_id
    }
    APICall('post', data, `${EndPoints.editPodcast}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            setShowConfirmModel(false)
            setPreviewModel(false)
            clearValue()
            getPodcast(null, currentPage)
            setLoading(false)
          }, 5000)
          setShowPodcastCoverModel(false)
          setShowPodcastModel(false)
          setIsEditing(false)
          toast.success(res?.data?.message)
        } else {
          setIsEditing(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsEditing(false)
        toast.error(error?.data?.message)
      })
  }

  const onClickEditPodcast = (videoData) => {
    setShowPodcastModel(true)
    setIsEditing(true)
    setSelectedVideo(s3ImagePodcast + videoData.attachment)
    setSelectedFilePath(s3ImagePodcast + videoData.cover_attachment)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      description: videoData.description,
      minutes: videoData.minutes,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      host_id: videoData.host_id,
      podcast_id: videoData.podcast_id
    })
    setInitialValues({
      title: videoData.title,
      description: videoData.description,
      minutes: videoData.minutes,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      host_id: videoData.host_id,
      podcast_id: videoData.podcast_id,
      search: ''
    })
    setPlatformTags(videoData.app_data)
  }

  const handleItemClick = (videoData) => {
    setPreviewModel(true)
    setIsEditing(true)
    setSelectedVideo(s3ImagePodcast + videoData.attachment)
    setSelectedFilePath(s3ImagePodcast + videoData.cover_attachment)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      description: videoData.description,
      minutes: videoData.minutes,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      host_id: videoData.host_id,
      podcast_id: videoData.podcast_id
    })
    setPlatformTags(videoData.app_data)
    setInitialPlatformTags(videoData.app_data)
    setInitialDescription(videoData.description)
  }

  const getCategoryName = (categoryId) => {
    const categoryObj = hostName.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.host : 'Select a host'
  }

  const validateForm = () => {
    const { title, host_id, description } = inputFields
    if (title && host_id && description && selectedVideo) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const getPodcast = (search, currentPage) => {
    // setIsLoading(true)
    const platFormData = {
      app_id: app_id.id,
      search
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.getPodcasts}?page=${currentPage}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const list = res?.decryptedData.data
          setPodcast(currentPage === 1 ? list : [...podcast, ...list])
          setTotalPages(res?.decryptedData?.last_page)
          setCurrentPage(res?.decryptedData?.current_page)
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  // const videoLikeData = (podcast_id) => {
  //   const platFormData = {
  //     app_id: app_id.id,
  //     podcast_id
  //   }
  //   APICall('post', platFormData, `${EndPoints.podcastLike}`, 'contentManager')
  //     .then((res) => {
  //       if (res.status === 200 && res.data?.code === 200) {
  //         setPodcast(
  //           podcast?.map((item) => (item.podcast_id === podcast_id
  //               ? { ...item, is_like: !item.is_like }
  //               : item))
  //         )
  //       } else {
  //         toast.error(res?.data?.message)
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error?.data?.message)
  //     })
  // }

  // Podcasts pagination

  const totalVideos = podcast?.length

  const handlePageChange = (pageNumber) => {
    getPodcast(null, pageNumber)
    setCurrentPage(pageNumber)
  }

  // on hover play pause

  useEffect(() => {
    return () => {
      Object.keys(audioRefs.current).forEach((index) => {
        const audio = audioRefs.current[index]
        if (audio) {
          audio.pause()
          audio.src = ''
        }
      })
    }
  }, [])

  // const handleMouseEnter = (index, attachment) => {
  //   if (isModalVisible) return
  //   setHoveredIndex(index)

  //   if (!audioRefs.current[index]) {
  //     const newAudio = new Audio(attachment)
  //     newAudio.onerror = (e) => {
  //       console.error('Audio playback error:', e)
  //     }
  //     audioRefs.current[index] = newAudio
  //   }

  //   audioRefs.current[index]
  //     .play()
  //     .then(() => {
  //       setIsPlaying((prev) => ({ ...prev, [index]: true }))
  //     })
  //     .catch((error) => {
  //       console.error('Error playing audio:', error)
  //     })
  // }

  // const handleMouseLeave = (index) => {
  //   setHoveredIndex(null)

  //   if (audioRefs.current[index]) {
  //     audioRefs.current[index].pause()
  //     audioRefs.current[index].currentTime = 0
  //     setIsPlaying((prev) => ({ ...prev, [index]: false }))
  //   }
  // }

  const togglePlayPause = (index, attachment) => {
    if (!audioRefs.current[index]) {
      const newAudio = new Audio(attachment)
      newAudio.onerror = (e) => {
        console.error('Audio playback error:', e)
      }
      newAudio.onended = () => {
        setIsPlaying((prev) => ({ ...prev, [index]: false }))
      }
      audioRefs.current[index] = newAudio
    }

    const audio = audioRefs.current[index]

    if (audio.paused) {
      audio
        .play()
        .then(() => {
          setIsPlaying((prev) => ({ ...prev, [index]: true }))
        })
        .catch((error) => {
          console.error('Error playing audio:', error)
        })
    } else {
      audio.pause()
      setIsPlaying((prev) => ({ ...prev, [index]: false }))
    }
  }

  const playVolumeIcon = (index, attachment) => (
    <img
      src={isPlaying[index] ? Images.PodcastPauseIco : Images.PodcastPlayIco}
      alt={isPlaying[index] ? 'Pause' : 'Play'}
      onClick={(e) => {
        e.stopPropagation()
        togglePlayPause(index, attachment)
      }}
      className="h-full w-full cursor-pointer items-center"
    />
  )

  const togglePlayPauses = () => {
    if (audioRef?.current) {
      if (audioRef?.current?.paused) {
        audioRef?.current?.play()
        setPlays(true)
      } else {
        audioRef?.current?.pause()
        setPlays(false)
      }
    }
  }

  const podcastApiInitialized = useRef(false)

  useEffect(() => {
    if (!podcastApiInitialized.current) {
      podcastApiInitialized.current = true
      podcastHostApiCall()
    }
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!loading) {
        if (
          componentRef.current &&
          !componentRef.current.contains(event.target)
        ) {
          // setShowPodcastCoverModel(false)
          setShowPodcastModel(false)
          setShowPodcastPreviewModel(false)
          setPreviewModel(false)
          clearValue()
          setIsEditing(false)
        }
      }
    }
    if (
      !loading &&
      (ShowPodcastModel ||
        // ShowPodcastCoverModel ||
        ShowPodcastPreviewModel ||
        previewModel ||
        ShowConfirmModel)
    ) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const handleStorageChange = () => {
      setLocalStorageValue(app_id || null)
    }
    window.addEventListener('storage', handleStorageChange)
    return () => window.removeEventListener('storage', handleStorageChange)
  }, [app_id])

  useEffect(() => {
    if (localStorageValue !== null) {
      setIsLoading(true)
      getPodcast(null, currentPage)
    }
  }, [localStorageValue])

  useEffect(() => {
    if (!isEditing) {
      validateForm()
    }
  }, [inputFields, selectedVideo])

  useEffect(() => {
    if (isEditing) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEditing, selectedVideo])
  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowPodcastModel(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (
      previewModel ||
      ShowPodcastModel ||
      ShowPodcastCoverModel ||
      ShowPodcastPreviewModel ||
      ShowConfirmModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [
    previewModel,
    ShowPodcastModel,
    ShowPodcastCoverModel,
    ShowPodcastPreviewModel,
    ShowConfirmModel
  ])

  return (
    <>
      <div
        className={
          showCount !== 3 &&
          'recent-video-component recent-podcast add-podcaest-video'
        }
      >
        {podcast?.length > 0 && (
          <div className="flex justify-between ">
            <h2 className="text-[#0B0A0A] !text-2xl italic !font-black uppercase">
              Podcasts
            </h2>
            <div className="add-video-rows main_btn mb-2">
              <CommonButton
                label={English.G160}
                onClick={AddPodcast}
                isPrimary
                className="add_new_content"
              />
            </div>
          </div>
        )}
        {/* <div className="Podcasts-item"> */}
        <div className="">
          {podcast?.length === 0 && !isLoading ? (
            <CommonEmptyData
              image={Images.podcast_icon}
              mainTitle="No podcasts"
              descriptionText="Add podcasts to show here"
              onClick={AddPodcast}
              addIcon={Images.FileUploadWhite}
              btnText={English.G160}
              classNameDescription="font-normal text-[#7A7474] text-[16px]"
            />
          ) : isLoading ? (
            <div className="Podcasts-item">
              <div className="Podcasts-item-detail">
                <div className="podacast-item">
                  <div className="podcast-cover">
                    <div className="play play_button_skeleton">
                      <Skeleton circle width={25} height={25} />
                    </div>
                  </div>
                  <div className="video-details podcast-detail">
                    <div className="d-flex justify-content-between">
                      <h6>
                        <img src="" alt="" />
                        <span />
                      </h6>
                    </div>
                    <p className="podcast-name">
                      <Skeleton className="podcast-name" width={332} />
                    </p>
                    <span className="podcast-upload-time">
                      <Skeleton className="podcast-upload-time" width={89} />
                    </span>
                    <div className="other-info mt-0">
                      <Skeleton className="other-info mt-0" width={332} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="Podcasts-item">
              {podcast?.map(
                (item, index) => index < (showCount || totalVideos) && (
                <div
                  className="awards-item-podcast position-relative"
                  key={index}
                >
                  <div
                    className="recent-podcast-item"
                    key={item.name}
                    style={{
                          backgroundImage: `url(${`${s3ImagePodcast}${item.cover_attachment}`})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center'
                        }}
                    onClick={(e) => {
                          e.stopPropagation()
                          handleItemClick(item)
                        }}
                  >
                    {/* <div className="video_like_bg">
                      <button
                        type="button"
                        className="video_like"
                        onClick={(e) => {
                              e.stopPropagation()
                              videoLikeData(item.podcast_id)
                            }}
                      >
                        {item.is_like ? (
                          <img src={Images.Likes} alt="Unlike" />
                            ) : (
                              <img src={Images.UnLikes} alt="Like" />
                            )}
                      </button>
                    </div> */}
                    {/* <span className="minutes-badge">{item.minutes}</span> */}
                    <div className="video_time">
                      <span className="!text-[#0B0A0A] !text-[10px] !font-black !italic">
                        {formatMinutes(item.minutes)}
                      </span>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-between user_image">
                    <h6 className="mb-0">
                      <img
                        src={s3ImageProfile + item.profile_icon}
                        alt=""
                      />
                      <span className="ml-2">{item.user_name}</span>
                    </h6>
                    <div className="rating">
                      <img src={Images.RatingStar} alt="" />
                      <span>4.9</span>
                    </div>
                  </div> */}

                  <div className="px-3 pt-3 pb-1">
                    <p className="text-[#151515] text-[17px] font-bold mb-0 min-h-[51px]">
                      {expandedTitle
                            ? item?.title
                            : `${item?.title?.slice(0, 50)}`}
                      {item?.title?.length > 50 && (
                      <span
                        className="see_more_local"
                        onClick={(e) => {
                                e.stopPropagation()
                                setExpandedTitle(!expandedTitle)
                              }}
                      >
                        ...
                        {' '}
                        {expandedTitle ? English.G193 : English.G194}
                      </span>
                          )}
                    </p>
                    <span className="timer">
                      {timeDifference(item.created_at)}
                    </span>
                  </div>
                  <div className="play_icons">
                    {playVolumeIcon(
                          index,
                          `${s3ImagePodcast}${item.attachment}`
                        )}
                  </div>
                  <div className="image-buttons z-1">
                    <label>
                      <button
                        type="button"
                        onClick={() => onClickEditPodcast(item)}
                        className="d-none"
                      />
                    </label>
                    <button
                      type="button"
                      className="p-0"
                      onClick={(e) => {
                            e.stopPropagation()
                            setUpdatingIndex(index)
                            ShowConfirm(item.podcast_id)
                          }}
                    />
                  </div>
                  <ConfirmationModal
                    isVisible={ShowConfirmModel && updatingIndex === index}
                    onConfirm={(e) => {
                          e.stopPropagation()
                          deletePodcast()
                        }}
                    onCancel={(e) => {
                          e.stopPropagation()
                          handleCancel()
                        }}
                    isLoading={delLoading}
                  />
                  {delLoading && updatingIndex === index && <Loader />}
                </div>
                  )
              )}
            </div>

          )}
          {previewModel && (
            <div className="add-item-model preview-model-podcast">
              <div className="add-item-content" ref={componentRef}>
                <div className="d-flex justify-content-between header">
                  <h3 className="shot_upload_file_text">{English.G143}</h3>
                  <button
                    type="button"
                    className="border-0 bg-none p-0"
                    onClick={HandleClose}
                  >
                    <span>
                      <img
                        className="close-btns"
                        src={Images.CloseIco}
                        alt=""
                      />
                    </span>
                  </button>
                </div>
                <div className="scroll-bar pb-0">
                  <div className="podcast-overview">
                    <div className="podcast-cover-img">
                      <div className="audio-container">
                        <img
                          src={selectedFilePath}
                          alt="Poster"
                          className="audio-poster"
                        />
                      </div>

                      <div className="podcast-profile">
                        <div className="podcast-profile-img-content border-0">
                          <img
                            src={s3ImageProfile + userData.profile_image}
                            alt=""
                          />
                        </div>
                        <div className="podcast-profile-detail">
                          <span className="podcast_profile_user_name">
                            {userData.user_name}
                          </span>
                          <p className="designation designation_color ">
                            {English.G291}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="podcast-detail">
                      <h3>{inputFields.title}</h3>
                      <p>The Demo Podcast</p>
                      <div className="bg_play_pause">
                        <img
                          src={
                            plays
                              ? Images.PodcastPauseIco
                              : Images.PodcastPlayIco
                          }
                          onClick={togglePlayPauses}
                          style={{ cursor: 'pointer' }}
                          alt="Play/Pause"
                          className="play_pause_icon"
                        />
                      </div>
                      <audio
                        ref={audioRef}
                        width="100%"
                        height="200"
                        className="audio-element"
                      >
                        <source src={selectedVideo} type="audio/mp3" />
                      </audio>
                    </div>
                  </div>
                  <div className="podcast-discription">
                    <h5 className="podcast_profile_user_name">
                      {English.G112}
                    </h5>
                    <textarea
                      className="preview_text"
                      onChange={(e) => setInputFields({
                          ...inputFields,
                          description: e.target.value
                        })}
                      value={inputFields.description}
                    >
                      <p>{inputFields.description}</p>
                    </textarea>
                  </div>
                  <PlatformTag
                    setPlatformTags={setPlatformTags}
                    Platformtags={Platformtags}
                    onItemSelect={(value) => getSelectedAppId(value)}
                  />
                </div>
                <div className="item-details-input pt-0">
                  <div className="button-row video_sticky_btn">
                    <CommonButton
                      label={English.G116}
                      type="button"
                      onClick={HandleClose}
                      isPrimary={false}
                      disabled={loading}
                      context={English.G232}
                    />
                    <CommonButton
                      label={isAppsSelected ? English.G128 : English.G89}
                      loading={loading}
                      type="button"
                      onClick={handlePublishClick}
                      className={`primary-btn-model-store ${loading ? 'loading' : ''}`}
                      isPrimary
                      disabled={
                        loading ||
                        (_.isEqual(initialPlatformTags, Platformtags) &&
                          initialDescription === inputFields.description)
                      }
                      context={English.G232}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {totalPages > 1 && currentPage <= totalPages - 1 && (
        <div
          className="p-5 cursor-pointer transition-transform duration-300 ease-in-out hover:scale-150"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <img
            className="justify-self-center"
            src={Images.down_arrow_icon}
            alt="load more"
          />
        </div>
          )}
        {ShowPodcastModel && (
          <CommonModalMain
            showCross
            title={English.G160}
            isPrevious={English.G78}
            isNext={English.B58}
            onPressClose={HandleClose}
            onPressSave={AddPodcastCover}
            onPressBack={HandleClose}
            showFooter
            isButtonDisabled={!isFormComplete}
          >
            <div className="w-full h-full flex flex-col gap-5 justify-between overflow-y-auto">
              <div className="item-details-input !p-[10px]">
                <div className="">
                  <div>
                    <label>{English.A228}</label>
                    <CommonInput
                      name="title"
                      placeholder={English.A230}
                      onChange={handleChange}
                      value={inputFields.title}
                    />
                  </div>
                  <div className="podcast-dropdown">
                    <label>{English.A229}</label>
                    <Dropdown
                      title={getCategoryName(inputFields.host_id)}
                      onChanged={(d) => {
                        setInputFields({
                          ...inputFields,
                          host_id: d.id
                        })
                      }}
                      Data={hostName.map((d) => ({
                        title: d.host,
                        id: d.id
                      }))}
                      name="host_id"
                      customClass={
                        inputFields.host_id
                          ? 'selected-class'
                          : 'giveaway-dropdown'
                      }
                    />
                  </div>
                </div>
                <div>
                  <label>{English.G112}</label>
                  <CommonInput
                    name="description"
                    placeholder={English.A231}
                    onChange={handleChange}
                    value={inputFields.description}
                    isTextArea
                    className="pt-3"
                  />
                </div>
                <div className="upload-input upload-input-with-border !py-20">
                  <CommonInput
                    type="file"
                    accept="audio/*"
                    id="upload-video"
                    onChange={handleVideoChange}
                    className="d-none"
                  />
                  {selectedVideo && (
                    <div>
                      <p>{selectedAudioName}</p>
                      <button
                        type="button"
                        className={`edit-button-model content-meneger-edit ${
                          isEditing ? 'hidden' : ''
                        }`}
                        onClick={() => {
                          setIsEditing(true)
                          document.getElementById('upload-video').click()
                        }}
                      />
                    </div>
                  )}
                  {!selectedVideo && (
                    <div className="upload-div mt-3 text-center">
                      <div className="upload-img">
                        <img
                          src={Images.FileUpload}
                          className="mx-auto"
                          alt=""
                        />
                      </div>
                      <h4 className="text-xl font-black italic text-[#4E4949] !mt-5">
                        {English.G160}
                      </h4>
                      <p className="drag-drop-text text-sm text-[#7A7474] !mb-5 max-w-[330px] mx-auto">
                        {English.A232}
                      </p>
                      <CommonButton
                        label={English.A256}
                        htmlFor="upload-video"
                        type="button"
                        className="w-auto !rounded-[999px] !font-black gap-2"
                        isPrimary
                        onClick={() => document.getElementById('upload-video').click()}
                        imgSrc={Images.plusrounded}
                      />
                    </div>
                  )}
                  {selectedVideo && (
                    <div className="">
                      <audio
                        key={selectedVideo}
                        ref={audioRef}
                        width="100%"
                        height="394"
                        controls
                        controlsList="nodownload noplaybackrate"
                        className="mx-auto"
                      >
                        <source src={selectedVideo} type="audio/mp3" />
                      </audio>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </CommonModalMain>
        )}
        {ShowPodcastCoverModel && (
          <CommonModalMain
            showCross
            title={English.G143}
            isPrevious={English.G78}
            isNext={English.G117}
            onPressClose={HandleClose}
            onPressSave={handlePublishClick}
            onPressBack={HandleCancelCover}
            showFooter
            isButtonDisabled={!selectedFilePath || loading}
            cancelButtonDisabled={loading}
            closeIcon={loading}
          >
            <div className="w-full h-full flex flex-col gap-5 justify-between overflow-y-auto">
              <div className="item-details-input">
                <div className="">
                  <div>
                    <h3 className="text-Colormain text-lg italic font-black uppercase !mb-[10px]">
                      {inputFields?.title}
                    </h3>
                  </div>
                  <div className="">
                    <AudioPlayer
                      src={selectedVideo}
                      ref={audioRef}
                      setDuration={setDuration}
                    />
                  </div>
                </div>
                <div>
                  <p className="text-[#4E4949] text-[16px] font-normal !my-5">
                    {expandedDescription
                      ? inputFields?.description
                      : `${inputFields?.description?.slice(0, 180)}`}
                    {inputFields?.description?.length > 180 && (
                      <span
                        className="see_more_local"
                        onClick={(e) => {
                          e.stopPropagation()
                          setExpandedDescription(!expandedDescription)
                        }}
                      >
                        ...
                        {' '}
                        {expandedDescription ? English.G193 : English.G194}
                      </span>
                    )}
                  </p>
                </div>
                <div>
                  <div className="upload-input upload-input-with-border !mb-0">
                    <CommonImageUpload
                      handleImageChange={handleFileChange}
                      index={0}
                      buttonText={English.A225}
                      selectedImg={selectedFilePath}
                      className="video_img"
                      defaultImg=""
                      forImage
                      forName="Podcast"
                    />
                  </div>
                </div>
              </div>
            </div>
            {isVideoUploading && (
              <UploadingComponent uploadPercentage={uploadPercentage} />
            )}
          </CommonModalMain>
        )}
      </div>
      {/* {podcast?.length >= 2 && showCount && (
        <div
          className="view_all text-end mt-4"
          onClick={() => navigate(`/profile-builder/${id}`, {
              state: { defaultIndex: 4 }
            })}
        >
          {English.G144}
        </div>
      )} */}
    </>
  )
}
export default PodcastComponent

/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
// import { useNavigate } from 'react-router-dom'
import './style.css'
import { useSelector } from 'react-redux'
import { s3ImageProfile } from '../../services/Service'
import {
  setUserSliceData
} from '../../services/http-services'
import { formatViews, lockScroll, unlockScroll } from '../../helpers/Utility'
import { emitter, EventType } from '../../helpers/Emitter'
import FollowerListModel from './FollowerListModel'
import English from '../../helpers/English'
import ProfileSkeleton from './ProfileSkeleton'
import Images from '../../helpers/Images'
import EditProfileModel from './EditProfileModel/EditProfileModel'
import { APICall, EndPoints } from '../../services'

const ProfileHeadStore = () => {
  // const navigate = useNavigate()
  const app_id = useSelector((state) => state.user?.app_id)
  const [profile, setProfile] = useState([])
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [profileEditData, setProfileEditData] = useState(null)
  const [showFollowerList, setShowFollowList] = useState(false)
  const [isFollowerListLoading, setIsFollowerListLoading] = useState(false)
  const [allFollowerListData, setAllFollowerListData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const componentRef = useRef()

  const getStoreLink = (storeLink) => {
    if (!storeLink) return undefined
    return storeLink.startsWith('http') ? storeLink : `http://${storeLink}`
  }

  const profileGet = async () => {
    setIsLoading(true)
    const platFormData = {
      app_id: app_id.id
    }
    await APICall('post', platFormData, EndPoints.storeProfileDetails, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const data = res?.decryptedData
          setProfile(data)
          if (Array.isArray(data) && data.length > 0) {
            setUserSliceData(data[0], 'profile_Data')
          } else {
            console.error('Data array is empty or undefined.')
          }
          allFollowerList(data[0]?.id)
          // setInputFields({
          //   icon: data[0]?.profile_icon,
          //   description: data[0]?.description,
          //   store_link: data[0]?.store_link
          // })
          // setProfileImg(data[0]?.profile_icon)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const allFollowerList = (user_id) => {
    setIsFollowerListLoading(true)
    const platform = {
      app_id: app_id.id,
      store_user_id: user_id
    }
    APICall('post', platform, EndPoints.followerList, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setAllFollowerListData(res?.decryptedData)
          setIsFollowerListLoading(false)
        } else {
          toast.error(res.data?.message)
          setIsFollowerListLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsFollowerListLoading(false)
      })
  }

  useEffect(() => {
    const event = emitter.addListener(EventType.editProfileModal, () => {
      setShowProfileModel(true)
      setProfileEditData(profile[0])
    })

    return () => event.remove()
  }, [app_id, profile])

  // const EditProfile = (profileData) => {
  //   setShowProfileModel(true)
  //   setProfileEditData(profileData)
  // }

  const HandleCancel = () => {
    setShowProfileModel(false)
    setProfileEditData(null)
  }

  // const handleGoBack = () => {
  //   navigate(-1)
  // }

  const displayedFollowers = allFollowerListData.slice(0, 4)
  const displayedNames = allFollowerListData.slice(0, 2)
  const profileInitialized = useRef(false)
  useEffect(() => {
    if (!profileInitialized.current) {
      profileInitialized.current = true
      profileGet()
    }

    const update = emitter.addListener(EventType.StoreUpdate, () => {
      profileGet()
    })

    return () => {
      update.remove()
    }
  }, [])

  useEffect(() => {
    if (ShowProfileModel || showFollowerList) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [ShowProfileModel, showFollowerList])

  return (
    <div>
      <div className="profile-builder-main-header position-relative mb-0">
        {/* <div className="view-button">
          <button type="button" className="active">
            <img alt="" src={Images.Desk} />
          </button>
          <button type="button">
            <img alt="" src={Images.Tab} />
          </button>
          <button type="button">
            <img alt="" src={Images.Mob} />
          </button>
          <button type="button">
            <img alt="" src={Images.View} />
          </button>
        </div> */}
        {/* <div className="general-settings">
          <Link className="gredient-txt">{English.G245}</Link>
        </div> */}
      </div>
      {isLoading ? (
        <ProfileSkeleton />
      ) : (
        profile.map((data, i) => (
          <div key={i} className="profile-head-builder">
            {/* <div className="profile-builder-header mb-40">
              <button type="button" onClick={handleGoBack}>
                <img alt="" src={Images.LeftIco} />
              </button>
              <div className="profile-heading">
                <img alt="" src={Images.Building} />
                <span>{data.user_name}</span>
              </div>
              <button type="button" onClick={() => EditProfile(data)}>
                <img alt="" src={Images.MenuIcon} />
              </button>
            </div> */}

            <div className="flex gap-5 justify-center items-center text-center">
              <div>
                <div className="profile-personal-detail !w-auto">
                  <div className="profile-build-image w-[150px] h-[150px]">
                    <img className="w-full h-full object-cover" alt="" src={s3ImageProfile + data.profile_icon} />
                  </div>
                </div>
              </div>
              <div>
                <div className="profile-heading mb-[20px] justify-center">
                  <img className="!w-[16px] !h-[16px]" alt="" src={Images.Building} />
                  <span>{data.user_name}</span>
                </div>
                <ul className="about-account-insignt buttons-group !gap-[60px] !w-[100%]">
                  <li className="flex justify-center items-center gap-1">
                    <h4 className="profile_post_count mb-0">
                      {formatViews(data.total_post)}
                    </h4>
                    <p className="post-follow-like">{English.G104}</p>
                  </li>
                  <li className="flex justify-center items-center gap-1">
                    <h4 className="profile_follower_count mb-0">
                      {formatViews(data.total_followers)}
                    </h4>
                    <p
                      className="Follower_text post-follow-like"
                      onClick={() => setShowFollowList(true)}
                    >
                      {English.G105}
                    </p>
                  </li>
                  <li className="flex justify-center items-center gap-1">
                    <h4 className="mb-0">
                      {formatViews(data.total_likes)}
                    </h4>
                    <p className="post-follow-like">{English.G106}</p>
                  </li>
                </ul>
                <p className="text-sm font-medium text-[#0B0A0A] mt-3">
                  {data.description.slice(0, 65)}
                  {data.description.length > 65 && '...'}
                </p>
                {data?.store_link && (
                  <div className="profile-redirect">
                    <a
                      className="gredient-txt justify-center"
                      target="_blank"
                      href={getStoreLink(data?.store_link)}
                      rel="noreferrer"
                    >
                      <img alt="" src={Images.Redirect} />
                      {data.store_link}
                    </a>
                  </div>
                )}

                <div className="follow-connenct !w-[450px]">
                  <div className="flex gap-4">
                    <button
                      type="button"
                      className="primary-btn success-btn !w-[174px] disabled"
                    >
                      {English.G107}
                    </button>
                    <button type="button" className="px-10 py-7 !w-[174px] bg-[#FAFAFA]">
                      <span className="text-[#0B0A0A] text-xs font-semibold">
                        Other Socials
                      </span>
                    </button>
                  </div>
                  <div>
                    <img alt="" src={Images.GbnLogo} />
                  </div>
                </div>
                <div className="follwer-shows">
                  {/* <div className="follower-img-group">
                    {displayedFollowers.map((follower, index) => (
                      <img
                        key={index}
                        alt={`Follower ${index + 1}`}
                        src={s3ImageProfile + follower.user_profile_icon}
                      />
                    ))}
                  </div> */}
                  {allFollowerListData.length > 0 && (
                    <div className="follower-img-group flex">
                      {displayedFollowers.map((follower, index) => (
                        <img
                          key={index}
                          alt={`Follower ${index + 1}`}
                          src={s3ImageProfile + follower.user_profile_icon}
                        />
                      ))}
                    </div>
                  )}
                  {allFollowerListData.length > 0 && (
                    <p>
                      <span className="gredient-txt">
                        {displayedNames.map((follower, index) => (
                          <React.Fragment key={index}>
                            <b>{follower.user_name}</b>
                            {index < displayedNames.length - 1 && ', '}
                          </React.Fragment>
                        ))}
                        {' und '}
                        <b>
                          {allFollowerListData.length - 2}
                          {' '}
                          weitere Personen
                        </b>
                        {' '}
                        sind Follower
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))
      )}
      {showFollowerList && (
        <FollowerListModel
          allFollowerListData={allFollowerListData}
          onClose={() => setShowFollowList(false)}
          isFollowerListLoading={isFollowerListLoading}
        />
      )}
      {ShowProfileModel && (
        <EditProfileModel
          type="store"
          title={English.G109}
          linkTitle={English.G114}
          profileEditData={profileEditData}
          onCancel={HandleCancel}
          componentRef={componentRef}
          onSubmite={(data) => {
            APICall('post', data, EndPoints.editStoreProfile, 'store')
              .then((res) => {
                if (res.status === 200 && res.data?.code === 200) {
                  setTimeout(() => {
                    profileGet()
                    toast.success(res?.data?.message)
                    setShowProfileModel(false)
                    setProfileEditData(null)
                  }, 3000)
                } else {
                  setShowProfileModel(false)
                setProfileEditData(null)
                }
              })
              .catch((error) => {
                toast.error(error?.data?.message)
                // setIsEdited(false)
              })
          }}
        />
      )}
    </div>
  )
}
export default ProfileHeadStore

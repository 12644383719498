/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-return-await */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useMemo, useRef } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import Loader from '../Loader/Loader'
import { urlImageProductPathStore } from '../../services/Service'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import { compressImage, dataDecrypt } from '../../services/http-services'
import CommonInput from '../InputComponet/CommonInput'
import CommonButton from '../ButtonComponent/ButtonCommon'
import Dropdown from '../Dropdown/Dropdown'
import { emitter, EventType } from '../../helpers/Emitter'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import CommonModalMain from '../CommonModalMain'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import Utility from '../../helpers/Utility'

const AddEditProductLibaryModal = ({
  setShowProduct,
  setIsProductEdit,
  isProductEdit,
  editData,
  onComplete
}) => {
  const app_id = useSelector((state) => state.user?.app_id)
  const modelheaderRef = useRef(null)
  const modelFooterRef = useRef(null)
  const componentRef = useRef()
  const [modalHeight, setModalHeight] = useState(0)
  const [isEdited, setIsEdited] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [category, setCategory] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [newImageLoading, setNewImageLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [inputFields, setInputFields] = useState(
    isProductEdit
      ? {
          ...editData,
          discount_percentage: Utility.calculateDiscountPercentage(
            editData.regular_price,
            editData.discount_price
          )
        }
      : {
          name: '',
          regular_price: '',
          discount_price: '',
          discount_percentage: '',
          category_id: '',
          sub_category_id: '',
          product_link: '',
          description: '',
          main_image: '',
          image_1: '',
          image_2: '',
          image_3: '',
          search: '',
          id: ''
        }
  )
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })

  const urlRegex =
    /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i

  const [error, setError] = useState({})
  const [errors, setErrors] = useState('')

  const ProductDataSubmit = (images) => {
    inputFields.main_image = images[0] ?? ''

    inputFields.image_1 = images[1] ?? ''

    inputFields.image_2 = images[2] ?? ''

    inputFields.image_3 = images[3] ?? ''

    if (!inputFields.discount_price) {
      inputFields.discount_price = 0
    }
    if (!inputFields.discount_percentage) {
      inputFields.discount_percentage = 0
    }
    APICall(
      'post',
      {
        ...inputFields,
        main_image: images[0] ?? '',
        image_1: images[1] ?? '',
        image_2: images[2] ?? '',
        image_3: images[3] ?? ''
      },
      `${EndPoints.addProductLibrary}`,
      'store'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            onComplete()
            setIsEdited(false)
            toast.success(res?.data?.message)
            setLoading(false)
            setShowProduct(false)
            setIsProductEdit(false)
          }, 3000)
        } else {
          setIsEdited(false)
          setLoading(false)
          setShowProduct(false)
          setIsProductEdit(false)
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const ResetValue = () => {
    setSelectedImages([])
    setInputFields({
      name: '',
      regular_price: '',
      discount_price: '',
      discount_percentage: '',
      category_id: '',
      sub_category_id: '',
      product_link: '',
      description: '',
      main_image: '',
      image_1: '',
      image_2: '',
      image_3: '',
      search: '',
      id: ''
    })
    setSubCategory([])
  }

  const handleSubmit = async () => {
    // event.preventDefault()
    if (!inputFields.product_link || !urlRegex.test(inputFields.product_link)) {
      setErrors(English.G73)
      return
    }
    if (isFormValid) {
      setLoading(true)
      const newFile = [
        inputFields?.main_image,
        inputFields?.image_1,
        inputFields?.image_2,
        inputFields?.image_3
      ]

      const uploadedImages =
        newFile.length !== 0 ? await onUpload(newFile, 'images') : []
      const images = [
        inputFields?.main_image,
        inputFields?.image_1,
        inputFields?.image_2,
        inputFields?.image_3
      ].map((item) => (typeof item === 'string' ? item : uploadedImages?.shift()))
      if (isProductEdit) {
        EditProductLib(inputFields, images)
      } else {
        ProductDataSubmit(images)
      }
    }
  }

  const ProductAddCancel = () => {
    setShowProduct(false)
    setIsProductEdit(false)
    ResetValue()
  }

  const onUpload = async (file, fieldName, index) => {
    try {
      // const imageFile = await compressImage(file)
      const coverFormData = new FormData()

      if (fieldName === 'images') {
        const compressedImages = await Promise.all(
          Array.from(file)
            .filter((file) => file instanceof File)
            .map(async (file) => await compressImage(file))
        )
        if (compressedImages.length === 0) {
          return file
        }
        compressedImages.forEach((imageFile) => {
          coverFormData.append('files[]', imageFile)
        })
      }

      coverFormData.append('type', 'product')

      try {
        const res = await APICall(
          'post',
          coverFormData,
          EndPoints.uploadFileMultiple,
          'store',
          true
        )
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedImageData = dataDecrypt(res.data.data)
          if (fieldName === 'images') {
            return [
              ...decryptedImageData,
              ...file.filter((item) => !(item instanceof File))
            ]
          }
        } else {
          toast.error(res?.data?.message || 'Error uploading image')
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || 'Upload failed')
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const handleImageChange = async (file, index = -1) => {
    if (file) {
      setInputFields((prev) => {
        const updatedFields = { ...prev }
        const uploadedImages = prev.uploadedImages || []

        let fieldName = 'main_image'
        if (uploadedImages.length === 1) fieldName = 'image_1'
        else if (uploadedImages.length === 2) fieldName = 'image_2'
        else if (uploadedImages.length === 3) fieldName = 'image_3'

        if (index >= 0) {
          fieldName =
            index === 0
              ? 'main_image'
              : index === 1
                ? 'image_1'
                : index === 2
                  ? 'image_2'
                  : index === 3
                    ? 'image_3'
                    : 'main_image'
          updatedFields.uploadedImages[index] = file
          const newSM = [...selectedImages]
          newSM[index] = file
          setSelectedImages(newSM)
        } else {
          updatedFields.uploadedImages = [...uploadedImages, file]
          setSelectedImages([...selectedImages, file])
        }

        updatedFields[fieldName] = file
        return updatedFields
      })
    }
  }

  // const finishSubmit = async (file) => {
  //   const imageType = 'product'
  //   try {
  //     const compressedImage = await compressImage(file)
  //     const formData = new FormData()
  //     formData.append('file', compressedImage)
  //     formData.append('type', imageType)
  //     return new Promise((resolve) => {
  //       APICall('post', formData, EndPoints.uploadImage, 'store', true)
  //         .then((res) => {
  //           if (res.status === 200 && res.data?.code === 200) {
  //             resolve(dataDecrypt(res.data.data))
  //           } else {
  //             toast.error(res?.data?.message)
  //             resolve(null)
  //           }
  //         })
  //         .catch((e) => {
  //           toast.error(e?.data?.message)
  //           resolve(null)
  //         })
  //     })
  //   } catch (error) {
  //     toast.error('Image compression failed!')
  //     return Promise.resolve(null)
  //   }
  // }

  const removeImage = (index) => {
    const updatedImages = [...selectedImages]
    updatedImages.splice(index, 1)
    setSelectedImages(updatedImages)
    let fieldName = 'main_image'
    if (updatedImages.length === 1) fieldName = 'image_1'
    else if (updatedImages.length === 2) fieldName = 'image_2'
    else if (updatedImages.length === 3) fieldName = 'image_3'
    setInputFields({
      ...inputFields,
      uploadedImages: updatedImages,
      [fieldName]: ''
    })
    setIsEdited(true)
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setSelectedImages((state) => {
        const copy = [...state]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b

        return copy
      })
      setIsEdited(true)
      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {selectedImages?.map((image, index) => (
          <div
            className={`image position-relative ${
              index === 0 ? 'first-image' : ''
            }`}
            key={index}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={(event) => event.preventDefault()}
            onDrop={(event) => {
              event.preventDefault()
              handleDrop(index)
            }}
          >
            {isImageLoading.index === index && isImageLoading.loading && (
              <Loader />
            )}
            <img
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              src={
                typeof image === 'string'
                  ? urlImageProductPathStore + image
                  : URL.createObjectURL(image)
              }
              alt={`image-${index}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              onLoad={() => handleLoad(index)}
              onError={() => {
                setIsImageLoading({
                  index: null,
                  loading: false
                })
              }}
            />
            <button type="button" onClick={() => removeImage(index)}>
              <img src={Images.Remove} alt="remove" />
            </button>
            <button type="button" className="plus-img !w-[40px]">
              <CommonImageUpload
                handleImageChange={(file) => {
                  handleImageChange(file, index)
                }}
                selectedImg=""
                defaultImg={Images.plusimg}
                className="upload_label"
              />
            </button>
          </div>
        ))}
        {newImageLoading && (
          <div className="image position-relative">
            <Loader />
          </div>
        )}
      </>
    )
  }, [selectedImages, draggedIndex])

  const MainCategory = () => {
    APICall('post', {}, EndPoints.category, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setCategory(res?.decryptedData)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const SubCategory = (data) => {
    APICall('post', data, EndPoints.subCategory, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setSubCategory(res?.decryptedData[0]?.sub_category_data)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const mainCategoryInitialized = useRef(false)

  useEffect(() => {
    if (!mainCategoryInitialized.current) {
      mainCategoryInitialized.current = true
      MainCategory()
    }
  }, [])

  const getCategoryName = (categoryId) => {
    const categoryObj = category?.find((cat) => cat?.id === categoryId)
    return categoryObj ? categoryObj?.name : English.G134
  }

  const getSubCategoryNameById = (subCategoryId) => {
    const subCategoryItem = subCategory?.find(
      (sc) => sc?.sub_category_id === subCategoryId
    )
    return subCategoryItem ? subCategoryItem?.sub_category_name : English.A242
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'regular_price' || name === 'discount_price') {
      if (/^\d*\.?\d*$/.test(value) && !value.startsWith('.')) {
        setInputFields((prev) => ({
          ...prev,
          [name]: value
        }))
      }

      if (name === 'regular_price') {
        const dis_price =
          value - (value * inputFields.discount_percentage) / 100
        setInputFields((prev) => ({
          ...prev,
          discount_price:
            inputFields.discount_percentage == 0 ? '0' : dis_price
        }))
      }
      if (
        name === 'discount_price' &&
        parseFloat(value) > parseFloat(inputFields.regular_price)
      ) {
        setError((prev) => ({
          ...prev,
          discount_price: 'Discount price cannot be higher than regular price'
        }))
      } else {
        setError((prev) => ({
          ...prev,
          discount_price: ''
        }))
      }

      return
    }
    if (name === 'product_link' && urlRegex.test(value)) {
      setErrors('')
    }
    setInputFields((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  const PlatformPreview = () => {
    setShowProduct(false)
    setIsProductEdit(false)
    ResetValue()
  }

  const validateForm = () => {
    const {
      name,
      regular_price,
      category_id,
      sub_category_id,
      product_link,
      description
    } = inputFields

    const isValid =
      name &&
      regular_price &&
      category_id &&
      sub_category_id &&
      product_link &&
      description &&
      selectedImages.length > 0 &&
      !error.discount_price &&
      !error.discount_percentage

    setIsFormValid(isValid)
  }

  useEffect(() => {
    validateForm()
  }, [inputFields, selectedImages, error, isEdited])

  const oneProductDetails = (data) => {
    const platFormData = {
      app_id: app_id.id,
      plateform: 'web',
      product_id: data.id
    }
    APICall('post', platFormData, `${EndPoints.productDetail}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const productDetails = dataDecrypt(res.data.data)
          const Urls = productDetails?.images?.map((i) => i.image)
          SubCategory({ category_id: productDetails.category_id })
          setInputFields({
            ...productDetails,
            discount_percentage: Utility.calculateDiscountPercentage(
              productDetails.regular_price,
              productDetails.discount_price
            ),
            main_image: Urls?.[0] || '',
            image_1: Urls?.[1] || '',
            image_2: Urls?.[2] || '',
            image_3: Urls?.[3] || '',

            uploadedImages: productDetails?.images
          })
          setSelectedImages(Urls)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const EditProductLib = (data, images) => {
    setLoading(true)
    const platFormData = {
      product_libraries_id: data?.id,
      name: data.name,
      regular_price: data?.regular_price,
      discount_price: data?.discount_price || 0,
      discount_percentage: data?.discount_percentage || 0,
      category_id: data?.category_id,
      sub_category_id: data?.sub_category_id,
      product_link: data?.product_link,
      description: data?.description,
      main_image: images?.[0] || '',
      image_1: images?.[1] || '',
      image_2: images?.[2] || '',
      image_3: images?.[3] || ''
    }
    if (!data?.image) {
      platFormData.main_image = images[0] ?? ''
    }
    if (!data?.image_1) {
      platFormData.image_1 = images[1] ?? ''
    }
    if (!data?.image_2) {
      platFormData.image_2 = images[2] ?? ''
    }
    if (!data?.image_3) {
      platFormData.image_3 = images[3] ?? ''
    }
    APICall('post', platFormData, `${EndPoints.editProductLibrary}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            onComplete()
            emitter.emit(EventType.ProductLibraryDelete, {
              product_id: data.id,
              action: 'update'
            })
            toast.success(res?.data?.message)
            ResetValue()
            setIsEdited(false)
            setIsProductEdit(false)
            setLoading(false)
            setShowProduct(false)
          }, 3000)
        } else {
          toast.error(res?.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (isProductEdit) {
      oneProductDetails(editData)
    }
  }, [isProductEdit, editData])

  const updateModalHeight = () => {
    if (componentRef.current) {
      setModalHeight(
        componentRef.current.offsetHeight -
          (modelheaderRef.current.offsetHeight +
            modelFooterRef.current.offsetHeight)
      )
    }
  }

  useEffect(() => {
    updateModalHeight()
    window.addEventListener('resize', updateModalHeight)
    return () => {
      window.removeEventListener('resize', updateModalHeight)
    }
  }, [])

  return (
    <CommonModalMain
      showCross
      title={isProductEdit ? English.G138 : English.G129}
      isPrevious={English.G78}
      isNext={isProductEdit ? English.G128 : English.B71}
      onPressClose={ProductAddCancel}
      onPressSave={() => handleSubmit()}
      onPressBack={PlatformPreview}
      showFooter
      isButtonDisabled={!isFormValid}
      buttonClassNamePrimary="add_product_btn"
      buttonClassName="!w-[175px]"

    >
      {loading && <Loader />}
      <div ref={componentRef} className="h-full w-full">
        <div className="product-add-image">
          <div
            ref={modelheaderRef}
            className="product-label store_header_sticky hidden"
          >
            <h3 className="edit-new-label">
              {isProductEdit ? English.G138 : English.G137}
            </h3>
          </div>
          <div
            style={{ maxHeight: `${modalHeight}px` }}
            className="store_scroll_bar_add"
          >
            <form className="item-details-input">
              <div className="name-field">
                <div>
                  <label>{English.G162}</label>
                  <CommonInput
                    name="name"
                    value={inputFields.name}
                    placeholder={English.A240}
                    onChange={handleChange}
                    // style={{ textTransform: 'capitalize' }}
                  />
                </div>
              </div>
              <div className="input-grid">
                <div>
                  <label>{English.G124}</label>
                  <Dropdown
                    title={getCategoryName(inputFields.category_id)}
                    onChanged={(d) => {
                      SubCategory({ category_id: d.id })
                      setInputFields({
                        ...inputFields,
                        category_id: d.id
                      })
                    }}
                    textClass="selected-dropdown-text"
                    Data={category?.map((d) => ({
                      title: d.name,
                      id: d.id
                    }))}
                    name="category_id"
                    customClass={
                      inputFields.category_id
                        ? 'selected-class'
                        : 'giveaway-dropdown'
                    }
                  />
                </div>
                <div>
                  <label>{English.A241}</label>
                  <Dropdown
                    title={getSubCategoryNameById(inputFields.sub_category_id)}
                    onChanged={(d) => setInputFields({
                        ...inputFields,
                        sub_category_id: d.id
                      })}
                    Data={
                      subCategory && subCategory?.length > 0
                        ? subCategory.map((d) => ({
                            title: d.sub_category_name,
                            id: d.sub_category_id
                          }))
                        : [{ title: 'No Data Found', id: null }]
                    }
                    textClass="selected-dropdown-text"
                    name="sub_category_id"
                    customClass={
                      inputFields.sub_category_id
                        ? 'selected-class'
                        : 'giveaway-dropdown'
                    }
                  />
                </div>
              </div>
              <div className="input-grid">
                <div>
                  <label>{English.G131}</label>
                  <CommonInput
                    name="regular_price"
                    type="number"
                    value={inputFields.regular_price}
                    placeholder={English.A243}
                    onChange={handleChange}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
                <div>
                  <label>{English.B82}</label>
                  <CommonInput
                    name="discount_percentage"
                    type="number"
                    value={inputFields.discount_percentage || ''}
                    placeholder={English.B83}
                    onChange={(e) => {
                      let { value } = e.target
                      if (value > 100) value = 100
                      if (value < 0) value = 0
                      const dis_price =
                        inputFields.regular_price -
                        (inputFields.regular_price * value) / 100
                      handleChange({
                        target: { name: 'discount_percentage', value }
                      })
                      setInputFields((prev) => ({
                        ...prev,
                        discount_price:
                          inputFields.discount_percentage == 0
                            ? '0'
                            : dis_price
                      }))
                    }}
                    onWheel={(e) => e.target.blur()}
                    className={`${error.discount_percentage ? 'mb-0' : 'mb-2'}`}
                  />
                  {error.discount_percentage && (
                    <span className="error-message">
                      {error.discount_percentage}
                    </span>
                  )}
                </div>
              </div>
              <div className="input-grid">
                <div>
                  <label>{English.G126}</label>
                  <CommonInput
                    name="product_link"
                    value={inputFields.product_link}
                    placeholder={English.G133}
                    onChange={handleChange}
                    className={`${errors ? 'mb-0' : 'mb-3'}`}
                  />
                  {errors && <div className="error-message">{errors}</div>}
                </div>
              </div>
              <div>
                <label>{English.G112}</label>
                <CommonInput
                  name="description"
                  placeholder={English.A244}
                  onChange={handleChange}
                  value={inputFields.description}
                  className="description-input"
                  // maxLength={150}
                  isTextArea
                />
              </div>
            </form>
            {/* <div className="vertical-divider mt-4" /> */}
            <div className="input-image-show align-items-end">
              <div className="input-img-label">
                <div className={`${selectedImages.length === 0 ? '' : ''}`}>
                  <CommonImageUpload
                    handleImageChange={handleImageChange}
                    buttonText="Upload Image"
                    selectedImg=""
                    id="upload-p-image"
                    defaultImg={Images.upload}
                    className={`upload_label ${selectedImages.length === 0 ? '!hidden' : 'flex'}`}
                  />
                  {selectedImages.length === 0 && (
                    <CommonEmptyData
                      image={Images.FileUpload}
                      mainTitle={English.G190}
                      descriptionText="Recommended: “.JPG”  (1400 x 1400 pixels) Other major formats are supported with aspect ratio of 1:1"
                      onClick={() => document.getElementById('upload-p-image').click()}
                      addIcon={Images.plusrounded}
                      btnText="Select Image"
                    />
                  )}
                </div>
              </div>
              {renderImg}
            </div>
          </div>
        </div>
        <div
          ref={modelFooterRef}
          className="button-row align-items-center justify-content-between store_sticky_btn pb-20 !hidden"
        >
          <div>
            <CommonButton
              label={English.G78}
              type="button"
              onClick={PlatformPreview}
              isPrimary={false}
              context={English.G232}
              disabled={loading}
              className="me-4"
            />
            <CommonButton
              label={English.G127}
              type="button"
              isPrimary={false}
              context={English.G232}
              className="common-button"
              disabled
            />
          </div>
          <CommonButton
            label={isProductEdit ? English.G128 : English.G129}
            type="submit"
            isPrimary
            disabled={!isFormValid || loading}
            context={English.G232}
          />
        </div>
      </div>
    </CommonModalMain>
  )
}

export default AddEditProductLibaryModal

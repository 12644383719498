/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import { s3ImageVideo } from '../../services/Service'
import { timeDifference } from '../../helpers/Utility'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import ContentMangerCommonTitle from './SubComponent/ContentMangerCommon.js/ContentMangerCommonTitle'
import ContentMangerCommonSeeAll from './SubComponent/ContentMangerCommon.js/ContentMangerCommonSeeAll'
import { emitter, EventType } from '../../helpers/Emitter'
import VideoItem from './SubComponent/VideoItem'
import VideoAddModal from './Modal/VideoAddModal'
import PreviewVideoModal from './Modal/PreviewVideoModal'

const Video = () => {
  // const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [videoData, setVideoData] = useState([])
  const [showAddVideoModal, setShowAddVideoModal] = useState(false)
  const [previewModal, setPreviewModal] = useState(null)
  const [editVideoDate, setEditVideoData] = useState(null)

  const getVideosLibraryData = () => {
    setIsLoading(true)
    APICall('post', {}, `${EndPoints.getVideoLibrary}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setVideoData(res?.decryptedData.data)
          setIsLoading(false)
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const handleCancel = () => {
    setEditVideoData(null)
    setShowAddVideoModal(false)
  }

  const handleButtonClick = () => {
    clearValue()
    setShowAddVideoModal(true)
  }

  const clearValue = () => {
    setEditVideoData(null)
  }

  const onClickEditPodcast = (videoData) => {
    setEditVideoData(videoData)
    setShowAddVideoModal(true)
  }

  useEffect(() => {
    getVideosLibraryData()
  }, [])

  return (
    <div className="content-manager-video-wrapper">
      <ContentMangerCommonTitle
        title={English.G225}
        buttonName={videoData?.length === 0 ? null : English.G153}
        onButtonClick={videoData?.length === 0 ? null : handleButtonClick}
      />
      {(videoData?.length === 0 && !isLoading) &&
        <CommonEmptyData
          image={Images.media_business_gray}
          mainTitle={English.B106}
          descriptionText={English.B107}
          onClick={() => { handleButtonClick() }}
          addIcon={Images.FileUploadWhite}
          btnText={English.G153}
          className="!bg-[#FAFAFA] !border-solid"
          classNameDescription="font-normal text-[#7A7474] text-[16px]"
        />}
      <div className="video-items">
        {isLoading ? (
          <div className="awards-item-video">
            <Skeleton className="add-profile-video-cover" />
            <div className="video-content">
              <p>
                <Skeleton width={250} />
              </p>
              <div className="views-and-time">
                <span>
                  <Skeleton width={50} />
                </span>
                <span>
                  <Skeleton width={60} />
                </span>
              </div>
            </div>
          </div>
        ) : (
          videoData?.slice(0, 4)?.map((item, index) => (
            <VideoItem
              key={index}
              item={item}
              index={index}
              onClick={() => onClickEditPodcast(item)}
              itemClick={() => setPreviewModal(item)}
            />
          ))
        )}
      </div>
      {previewModal && <PreviewVideoModal videoData={previewModal} handleCancel={() => setPreviewModal(null)} />}
      {showAddVideoModal && (
        <VideoAddModal
          handleCancel={handleCancel}
          editData={editVideoDate}
          onSubmit={(video_id) => {
            // if (video_id) {
            //   const video = getParticularVideosData(video_id)
            //   const temp = [...videoData]
            //   videoData.forEach((item, index) => {
            //     if (item.video_id === video_id) {
            //       temp[index] = video
            //     }
            //   })
            //   setVideoData(temp)
            // } else {
            getVideosLibraryData()
            // }
          }}
        />
      )}
      {(!isLoading && videoData.length > 4) && <ContentMangerCommonSeeAll onClick={() => emitter.emit(EventType.contentMangerTabs, { tabIndex: 1 })} />}
    </div>
  )
}
export default Video

import React from 'react'
import Skeleton from 'react-loading-skeleton'

const particularSkeleton = () => {
  return (
    <div className="">
      <div className="particular_skeleton">
        <div className="w-50">
          <div className="details_section_profile_header">
            <div className="name_logo">
              <Skeleton circle height={50} width={50} />
              <div>
                <Skeleton count={1} height={30} width={200} />
                <Skeleton count={1} height={20} width={300} />
              </div>
            </div>
          </div>
          <div className="details_section_button_row d-flex gap-2">
            <Skeleton count={1} height={40} width={80} />
            <Skeleton count={1} height={40} width={80} />
          </div>

          <div className="details_section p-3">
            <Skeleton count={4} height={20} width="100%" />
            <Skeleton count={1} height={20} width={150} />
          </div>

          <div className="contact-details">
            <h4>
              <Skeleton width={100} />
            </h4>
            <div className="mt-3 mb-3">
              <Skeleton count={3} height={50} width="100%" />
            </div>
          </div>
        </div>
        <div className="w-50">
          <div className="d-flex gap-3 w-100 mt-5">
            <div className="image-placeholder w-50">
              <Skeleton height={150} width="100%" />
            </div>
            <div className="image-placeholder w-50">
              <Skeleton height={150} width="100%" />
            </div>
          </div>
          <div className="d-flex gap-3 w-100">
            <div className="image-placeholder w-50">
              <Skeleton height={150} width="100%" />
            </div>
            <div className="image-placeholder w-50">
              <Skeleton height={150} width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default particularSkeleton

/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-tabs */
/* eslint-disable import/no-duplicates */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { s3ImageProfile } from '../../../services/Service'
import { timeDifference } from '../../../helpers/Utility'

const ShotVideo = ({
  text,
  index,
  defaultPlay,
  ref,
  className,
  videoClassName,
  videoSrc,
  posterSrc,
  item,
  onVideoEnd,
  onProgres,
  onPlay
}) => {
  const videoRef = useRef(null)
  const userData = useSelector((state) => state.user?.userData)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const [progress, setProgress] = useState(0)
  const [isMuted, setIsMuted] = useState(false)

  useEffect(() => {
    setIsPlaying(defaultPlay)
  }, [defaultPlay])

  const handlePlay = () => {
    const video = videoRef.current
    if (video) {
      video.play()
      setIsPlaying(true)
    }
  }

  const handlePause = () => {
    setIsPlaying(false)
  }

  const handleEnded = () => {
    setIsPlaying(false)
    setIsFinished(true)
    if (onVideoEnd) onVideoEnd()
  }
  const navigate = useNavigate()
  const togglePlayPause = (e) => {
    e.stopPropagation()
    const video = videoRef.current
    if (video.paused) {
      video.play()
      setIsPlaying(true)
    } else {
      video.pause()
      setIsPlaying(false)
    }
  }

  const toggleMute = (e) => {
    e.stopPropagation()
    const video = videoRef.current
    video.muted = !video.muted
    setIsMuted(video.muted)
  }

  const handleProgress = () => {
    const video = videoRef.current
    const progress = (video.currentTime / video.duration) * 100
    setProgress(progress)
    if (onProgres) {
      onProgres(progress)
    }
    // setCurrentTime(video.currentTime)
    // setDuration(video.duration)
  }

  useEffect(() => {
    if (ref) {
      if (typeof ref === 'function') {
        ref(videoRef.current)
      } else {
        ref.current = videoRef.current
      }
    }
  }, [ref])

  const playVolumeIcon = isMuted ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15"
      viewBox="0 0 15 11"
      fill="none"
    >
      <path
        d="M0.966462 5.50142C0.966462 6.3205 0.930479 7.43966 1.39751 7.85979C1.86453 8.27992 2.14213 8.14929 2.93902 8.21284C3.7359 8.27639 5.42769 10.8184 6.7308 10.0134C7.40684 9.4415 7.45909 8.24817 7.45909 5.50142C7.45909 2.75467 7.40685 1.55781 6.73733 0.989398C5.43096 0.184438 3.74571 2.72636 2.94555 2.78991C2.1454 2.85346 1.83841 2.75461 1.39751 3.14297C0.956607 3.53132 0.966462 4.67881 0.966462 5.50142Z"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 3.5C13.0598 4.08693 13.0359 4.28454 12.4995 4.99965C11.9632 5.71476 11.4397 6.41272 10.9995 6.99965"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 3.5C11.1087 4.10874 11.299 4.29904 12.0008 5.00078C12.7025 5.70253 13.3913 6.39126 14 7"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15"
      viewBox="0 0 14 11"
      fill="none"
    >
      <path
        d="M0.966462 5.50142C0.966462 6.3205 0.930479 7.43966 1.39751 7.85979C1.86453 8.27992 2.14213 8.14929 2.93902 8.21284C3.7359 8.27639 5.42769 10.8184 6.7308 10.0134C7.40684 9.4415 7.45909 8.24817 7.45909 5.50142C7.45909 2.75467 7.40685 1.55781 6.73733 0.989398C5.43096 0.184438 3.74571 2.72636 2.94555 2.78991C2.1454 2.85346 1.83841 2.75461 1.39751 3.14297C0.956607 3.53132 0.966462 4.67881 0.966462 5.50142Z"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5059 1.43457C12.273 2.62607 12.6843 4.04296 12.6843 5.49468C12.6843 6.94639 12.273 8.36329 11.5059 9.55478"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.96289 3.04004C10.36 3.78848 10.569 4.6362 10.569 5.499C10.569 6.36179 10.36 7.20959 9.96289 7.95804"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )

  return (
    <div className={`video_player relative aspect-[9/16] ${className}`}>
      <div className="play-time-full flex flex-row top-0 p-2 gap-2 !bottom-[none] absolute justify-between items-center w-full">
        <div className="flex flex-row gap-2 items-center justify-content-center">
          <img
            className="h-7 w-7 rounded-full border-2 border-background"
            src={s3ImageProfile + userData.profile_image}
            alt=""
          />
          <div className="flex flex-col justify-between gap-1">
            <span className="font-semibold text-[12px] text-white leading-none">
              {userData.full_name}
            </span>
            <span className="font-normal text-[12px] text-white leading-none">
              {timeDifference(item.created_at)}
            </span>
          </div>
        </div>
        <div className="justify-content-end">
          <div className="flex flex-row gap-2 justify-content-center">
            <button
              onClick={togglePlayPause}
              type="button"
              className="play-pause-btn shots_play_pause !flex items-center h-4"
            >
              {isPlaying ? '⏸' : '▶'}
            </button>
            <button
              onClick={toggleMute}
              type="button"
              className="mute-unmute-btn shots_play_pause !flex items-center h-4"
            >
              {playVolumeIcon}
            </button>
          </div>
        </div>
      </div>
      <video
        width="100%"
        height="394"
        className={`aspect-[9/16] ${videoClassName}`}
        ref={videoRef}
        autoPlay={isPlaying}
        key={isPlaying}
        poster={posterSrc}
        controlsList="nodownload noplaybackrate"
        disablePictureInPicture
        allowFullScreen
        onPlay={() => {
          if (onPlay) {
            onPlay()
          }
          setIsPlaying(true)
        }}
        onPause={handlePause}
        onEnded={handleEnded}
        // onClick={() => navigate('/shots/shots-detail', { state: { text } })}
        onClick={(e) => {
          if (!e.target.closest('button')) {
            navigate('/shots/shots-detail', { state: { text, shotIndex: index } })
          }
        }}
        onTimeUpdate={handleProgress}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
    </div>
  )
}

export default ShotVideo

/* eslint-disable radix */
import _ from 'lodash'
import dayjs from 'dayjs'
import { ReduxStore } from '../redux'

/* eslint-disable no-control-regex */
export const isEmpty = (value) => {
  if (value === null || value === undefined) {
    return true
  }
  if (typeof value === 'string' && value.trim() === '') {
    return true
  }
  return false
}

const deepClone = (val) => {
  return _.cloneDeep(val)
}

export const capitalizeFirstLetter = (string) => {
  if (!string) return ''
  const cleanedString = string.replace(/^\s+/, '').replace(/\s+/g, ' ')
  return cleanedString.charAt(0).toUpperCase() + cleanedString.slice(1)
}

export const timeDifference = (createdAt) => {
  const createdDate = new Date(createdAt)
  const now = new Date()
  let difference = now - createdDate

  if (difference < 0) {
    difference = 0
  }

  const seconds = Math.floor(difference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (seconds < 60) {
    return `${seconds} seconds`
  }
  if (minutes < 60) {
    return `${minutes} minutes`
  }
  if (hours < 24) {
    return `${hours} hours`
  }
  return `${days} days`
}

export const formatViews = (views) => {
  if (views >= 1_000_000_000) {
    return `${(views / 1_000_000_000).toFixed(1)}B`
  }
  if (views >= 1_000_000) {
    return `${(views / 1_000_000).toFixed(1)}M`
  }
  if (views >= 1_000) {
    return `${(views / 1_000).toFixed(1)}k`
  }
  return views.toString()
}

export const debounce = (func, wait) => {
  let timeout

  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func(...args), wait)
  }
}

// export const formatTimeLeft = (timeLeft) => {
//   const [hours, minutes] = timeLeft.split(':').map(Number)

//   if (hours > 0) {
//     return `${hours} hour${hours > 1 ? 's' : ''} left`
//   }
//   if (minutes > 0) {
//     return `${minutes} minute${minutes > 1 ? 's' : ''} left`
//   }
//   return 'Time is up'
// }
export const formatTimeLeft = (timeLeft) => {
  if (!timeLeft || typeof timeLeft !== 'string' || !timeLeft.includes(':')) {
    return 'Time is up'
  }

  const [hoursStr, minutesStr] = timeLeft.split(':')
  const hours = Number(hoursStr)
  const minutes = Number(minutesStr)

  if (Number.isNaN(hours) || Number.isNaN(minutes)) {
    return 'Time is up'
  }

  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} left`
  }

  if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} left`
  }

  return 'Time is up'
}

export const formatDateTimeLeft = (targetDateTime) => {
  const targetDate = new Date(targetDateTime)
  const now = new Date()

  const timeDifference = targetDate - now // Difference in milliseconds

  if (timeDifference <= 0) {
    return 'Time is up'
  }

  const hours = Math.floor(timeDifference / (1000 * 60 * 60))
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))

  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} left`
  }
  if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} left`
  }
  return 'Less than a minute left'
}

export const formatDuration = (seconds) => {
  const hrs = Math.floor(seconds / 3600)
  const mins = Math.floor((seconds % 3600) / 60)
  const secs = Math.floor(seconds % 60)
  return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
}

export const formatDurationPodcast = (seconds) => {
  const mins = Math.floor(seconds / 60)
  const secs = Math.floor(seconds % 60)
  return `${mins} M : ${secs.toString().padStart(2, '0')} S`
}

export const formatMinutes = (time) => {
  return time.replace(/[MS]/g, '').trim()
}

export const lockScroll = () => {
  document.body.style.overflow = 'hidden'
}

export const unlockScroll = () => {
  document.body.style.overflow = 'auto'
}

export const preventSpaceInput = (e) => {
  if (e.key === ' ') {
    e.preventDefault()
  }
}

export const preventSpacePaste = (e) => {
  if (e.clipboardData.getData('Text').includes(' ')) {
    e.preventDefault()
  }
}

export const preventMultipleAtSign = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  if ((pastedText.match(/@/g) || []).length > 1) {
    e.preventDefault()
  }
}

export const preventMultipleAtSignKeydown = (e, emailValue) => {
  if (e.key === '@' && (emailValue.match(/@/g) || []).length >= 1) {
    e.preventDefault()
  }

  const invalidChars = /[^a-zA-Z0-9@._]/
  if (invalidChars.test(e.key)) {
    e.preventDefault()
  }
}

export const preventMultipleAtSignPaste = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  if ((pastedText.match(/@/g) || []).length > 1) {
    e.preventDefault()
  }

  const invalidChars = /[^a-zA-Z0-9@._]/
  if (invalidChars.test(pastedText)) {
    e.preventDefault()
  }
}

export const preventNonAlphabeticKeydown = (e) => {
  const regex = /^[A-Za-z]+$/
  if (
    !regex.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight'
  ) {
    e.preventDefault()
  }
}

export const preventNonAlphabeticPaste = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  const regex = /^[A-Za-z]+$/
  if (!regex.test(pastedText)) {
    e.preventDefault()
  }
}

export const preventInvalidKeydown = (e) => {
  const regex = /^[A-Za-z0-9._]+$/
  if (
    !regex.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight' &&
    e.key !== 'Tab'
  ) {
    e.preventDefault()
  }
}

export const preventInvalidPaste = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  const regex = /^[A-Za-z0-9._]+$/
  if (!regex.test(pastedText)) {
    e.preventDefault()
  }
}

// space allow
export const preventNonAlphabeticKeydownWithSpace = (e) => {
  const regex = /^[A-Za-z ]+$/
  if (
    !regex.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight'
  ) {
    e.preventDefault()
  }
}

export const preventNonAlphabeticPasteWithSpace = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  const regex = /^[A-Za-z ]+$/
  if (!regex.test(pastedText)) {
    e.preventDefault()
  }
}

export const preventNonAlphabeticKeydownWithSingleSpace = (e) => {
  const regex = /^[A-Za-z ]$/
  const inputElement = e.target
  const cursorPosition = inputElement.selectionStart

  if (
    e.key === ' ' &&
    (cursorPosition === 0 || inputElement.value[cursorPosition - 1] === ' ')
  ) {
    e.preventDefault()
  } else if (
    !regex.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight'
  ) {
    e.preventDefault()
  }
}

export const preventNonAlphabeticPasteWithSingleSpace = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  const regex = /^[A-Za-z ]+$/

  if (!regex.test(pastedText) || /\s{2,}/.test(pastedText)) {
    e.preventDefault()
  }
}

export const preventNonAlphanumericKeydownWithSingleSpace = (e) => {
  const regex = /^[A-Za-z0-9 ]$/
  const inputElement = e.target
  const cursorPosition = inputElement.selectionStart

  if (
    e.key === ' ' &&
    (cursorPosition === 0 || inputElement.value[cursorPosition - 1] === ' ')
  ) {
    e.preventDefault()
  } else if (
    !regex.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight'
  ) {
    e.preventDefault()
  }
}

export const preventNonAlphanumericPasteWithSingleSpace = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  const regex = /^[A-Za-z0-9 ]+$/

  if (!regex.test(pastedText) || /\s{2,}/.test(pastedText)) {
    e.preventDefault()
  }
}

export const normalizeAddress = (address) => {
  let normalized = address.normalize('NFKD')

  normalized = normalized
    .replace(/é/g, 'e')
    .replace(/ñ/g, 'n')
    .replace(/ë/g, 'e')
    .replace(/ç/g, 'c')
    .replace(/ü/g, 'u')
    .replace(/ß/g, 'ss')

  normalized = normalized.replace(/[^\u0000-\u00FF]/g, '')

  return normalized
}

export const ForMobileNumber = (e) => {
  const { key } = e
  const inputValue = e.target.value
  if (!/[0-9]/.test(key) && !(key === '+' && inputValue.length === 0)) {
    e.preventDefault()
    return
  }
  if (key === '+' && inputValue.includes('+')) {
    e.preventDefault()
    return
  }
  const maxLength = inputValue.startsWith('+') ? 13 : 12
  if (inputValue.length >= maxLength && key !== 'Backspace') {
    e.preventDefault()
  }
}

export const getToken = () => {
  const token = ReduxStore.getState().user?.token
  return token
}

export const preventNonAlphabeticKeydownNoSpaces = (e) => {
  const regex = /^[A-Za-z]$/

  if (
    !regex.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight'
  ) {
    e.preventDefault()
  }
}

export const preventNonAlphabeticPasteNoSpaces = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  const regex = /^[A-Za-z]+$/

  if (!regex.test(pastedText)) {
    e.preventDefault()
  }
}

export const allowAlphaNumericDashSingleSpaceKeydown = (e) => {
  const regex = /^[A-Za-z0-9-]$/
  const inputElement = e.target
  const cursorPosition = inputElement.selectionStart

  if (
    e.key === ' ' &&
    (cursorPosition === 0 || inputElement.value[cursorPosition - 1] === ' ')
  ) {
    e.preventDefault()
  } else if (
    !regex.test(e.key) &&
    e.key !== ' ' &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight'
  ) {
    e.preventDefault()
  }
}

export const allowAlphaNumericDashSingleSpacePaste = (e) => {
  const pastedText = e.clipboardData.getData('Text')
  const regex = /^[A-Za-z0-9 -]+$/
  const invalidSpaces = /\s{2,}/

  if (!regex.test(pastedText) || invalidSpaces.test(pastedText)) {
    e.preventDefault()
  }
}
export const isValidUserName = (value) => {
  return !value.match(/^[A-Za-z0-9_]*$/)
}

const formatLikeFollowCount = (number) => {
  if (number < 1e3) return number
  if (number >= 1e3 && number < 1e6) return `${+(number / 1e3).toFixed(1)}K`
  if (number >= 1e6 && number < 1e9) return `${+(number / 1e6).toFixed(1)}M`
  if (number >= 1e9 && number < 1e12) return `${+(number / 1e9).toFixed(1)}B`
  if (number >= 1e12) return `${+(number / 1e12).toFixed(1)}T`
}

export const formatDurations = (duration) => {
  if (!duration) return '00:00'

  const parts = duration.split(':')
  const minutes = parts.length > 1 ? parts[1] : '00'
  const seconds = parts.length > 2 ? parts[2] : '00'

  return `${minutes}:${seconds}`
}

const calculateTimePeriod = (value) => {
  let result
  const diffInMinutes = dayjs().diff(dayjs(value), 'minute')
  const diffInHours = dayjs().diff(dayjs(value), 'hour')
  const diffInDays = dayjs().diff(dayjs(value), 'day')
  const diffInMonths = dayjs().diff(dayjs(value), 'month')
  const diffInYears = dayjs().diff(dayjs(value), 'year')

  if (diffInYears >= 1) {
    result = `${diffInYears} year${diffInYears > 1 ? 's' : ''} ago`
  } else if (diffInMonths >= 1) {
    result = `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} ago`
  } else if (diffInDays >= 1) {
    result = `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`
  } else if (diffInHours >= 1) {
    result = `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`
  } else if (diffInMinutes >= 1) {
    result = `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`
  } else {
    result = 'Just now'
  }
  return result
}
const calculateDiscountPercentage = (regularPrice, discountPrice) => {
  if (regularPrice <= 0) return 0
  return Math.round(((regularPrice - discountPrice) / regularPrice) * 100)
}

export function convertTo24HourFormat(time) {
  const timeRegex = /^(\d{1,2}):(\d{2})\s?(AM|PM)?$/i

  if (!timeRegex.test(time)) {
    console.error(`Invalid time format: "${time}". Expected HH:mm (24-hour) or hh:mm AM/PM.`)
    return null
  }

  const [, rawHours, rawMinutes, period] = time.match(timeRegex)
  let hours = parseInt(rawHours, 10)
  const minutes = parseInt(rawMinutes, 10)

  if (period) {
    if (period.toUpperCase() === 'PM' && hours !== 12) {
      hours += 12
    } else if (period.toUpperCase() === 'AM' && hours === 12) {
      hours = 0
    }
  }

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}

export const delay = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

const createTimeObject = (duration, format) => {
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration % 3600) / 60)
  const seconds = Math.floor(duration % 60)

  const finalHour = String(hours).padStart(2, '0')
  const finalMinutes =
    format === 'hh:mm:ss'
      ? String(minutes).padStart(2, '0')
      : String(hours * 60 + minutes).padStart(2, '0')
  const finalSeconds = String(seconds).padStart(2, '0')
  if (format === 'hh:mm:ss') {
    return `${finalHour}:${finalMinutes}:${finalSeconds}`
  }
  return `${finalMinutes}:${finalSeconds}`
}
export function cleanTimeString(timeStr) {
  return timeStr.replace(/[A-Za-z]/g, '').replace(/\s+/g, '').trim()
}

const calculateDayTime = (value) => {
  return dayjs(value).format('h:mm A')
}

export const onScrollDiv = (
  divRef,
  isCallingNext,
  scrollFunction,
  nextUrl
) => {
  if (divRef.current) {
    const { scrollTop } = divRef.current
    const { scrollHeight } = divRef.current
    const { clientHeight } = divRef.current

    if (scrollHeight - scrollTop <= clientHeight + 200) {
      if (!isCallingNext.current && nextUrl !== null) {
        scrollFunction()
      }
    }
  }
}


const Utility = {
  deepClone,
  isEmpty,
  capitalizeFirstLetter,
  timeDifference,
  formatViews,
  debounce,
  formatTimeLeft,
  formatDuration,
  formatDurationPodcast,
  lockScroll,
  unlockScroll,
  preventSpaceInput,
  preventSpacePaste,
  preventMultipleAtSign,
  preventMultipleAtSignKeydown,
  preventMultipleAtSignPaste,
  preventNonAlphabeticKeydown,
  preventNonAlphabeticPaste,
  preventNonAlphabeticPasteWithSingleSpace,
  preventNonAlphabeticKeydownWithSingleSpace,
  preventInvalidKeydown,
  preventInvalidPaste,
  preventNonAlphabeticKeydownWithSpace,
  preventNonAlphabeticPasteWithSpace,
  preventNonAlphanumericKeydownWithSingleSpace,
  preventNonAlphanumericPasteWithSingleSpace,
  normalizeAddress,
  ForMobileNumber,
  getToken,
  preventNonAlphabeticKeydownNoSpaces,
  preventNonAlphabeticPasteNoSpaces,
  allowAlphaNumericDashSingleSpaceKeydown,
  allowAlphaNumericDashSingleSpacePaste,
  isValidUserName,
  formatLikeFollowCount,
  formatDurations,
  formatMinutes,
  calculateTimePeriod,
  calculateDiscountPercentage,
  convertTo24HourFormat,
  delay,
  createTimeObject,
  cleanTimeString,
  formatDateTimeLeft,
  calculateDayTime,
  onScrollDiv
}

export default Utility

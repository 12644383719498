/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import CommonInput from '../../InputComponet/CommonInput'
import { English } from '../../../helpers'
import { capitalizeFirstLetter } from '../../../helpers/Utility'
import Dropdown from '../../Dropdown/Dropdown'
import { APICall, EndPoints } from '../../../services'
import CommonCMModal from './CommonCMModal'
import CommonDatePicker from '../../CommonDatePicker/CommonDatePicker'
import PollOptionView from '../../CommanComponent/PollOptionView'
import PlatformSelect from './PlatformSelect'

const PollAddModal = ({ handleCancel, editData, onSubmit }) => {
  const [loading, setLoading] = useState(false)
  const [appData, setAppData] = useState(null)
  const [inputFields, setInputFields] = useState({
    app_id: [],
    topic: '',
    category_id: 0,
    sub_category_id: 0,
    options: [{ option: '', id: '' }, { option: '', id: '' }],
    expire_date: ''
  })

  const [category, setCategory] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [currentStep, setCurrentStep] = useState(1)

  useEffect(() => {
    if (editData) {
      setInputFields({
        topic: editData.topic || '',
        category_id: editData.category_id || '',
        sub_category_id: editData.sub_category_id || '',
        expire_date: editData.expire_date || '',
        app_id: editData?.app_data.map((d) => d.id) || [],
        options: editData?.options.length > 0 ? editData.options : [{ option: '', id: '' }, { option: '', id: '' }],
        poll_id: editData.poll_id
      })
      SubCategory({ category_id: editData.category_id })
    }
  }, [editData])

  console.log(editData, inputFields)

  const MainCategory = () => {
    APICall('post', {}, `${EndPoints.getCategory}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setCategory(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const SubCategory = (data) => {
    APICall('post', data, `${EndPoints.getSubCategory}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setSubCategory(res?.decryptedData[0]?.sub_category)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const getCategoryName = (categoryId) => {
    const categoryObj = category.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.category_name : English.G181
  }

  const getSubCategoryNameById = (subCategoryId) => {
    const subCategoryItem = subCategory.find(
      (sc) => sc.sub_category_id === subCategoryId
    )
    return subCategoryItem ? subCategoryItem.sub_category_name : English.G181
  }

  const clearValue = () => {
    setInputFields({
      ...inputFields,
      app_id: [],
      topic: '',
      category_id: '',
      sub_category_id: '',
      options: ['', ''],
      expire_date: ''
    })
    setCurrentStep(1)
  }

  useEffect(() => {
    MainCategory()
    // SubCategory()
  }, [])

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const videoText = useMemo(() => {
    return (
      <div className="item-details-input pt-0">
        <div>
          <label>{English.A234}</label>
          <CommonInput
            name="topic"
            placeholder={English.A235}
            onChange={handleChange}
            className="mb-3"
            value={capitalizeFirstLetter(inputFields?.topic)}
          />
        </div>
        <div className="poll-dropdown">
          <label className="text-Colormain text-sm mb-[6px]">
            {English.G124}
          </label>
          <Dropdown
            title={getCategoryName(inputFields.category_id)}
            onChanged={(d) => {
              SubCategory({
                category_id: d.id
              })
              setInputFields({
                ...inputFields,
                category_id: d.id
              })
            }}
            Data={category.map((d) => ({
              title: d.category_name,
              id: d.id
            }))}
            name="category_id"
            customClass={
              inputFields.category_id
                ? 'selected-class !mb-[16px] !mt-0 !font-normal !text-[#a5a1a1] !text-[14px]'
                : 'giveaway-dropdown !mb-[16px] !mt-0 !font-normal !text-[#a5a1a1] !text-[14px]'
            }
            customClassHeight="dropdown-height-poll-category"
          />
        </div>
        <div className="poll-dropdown">
          <label className="text-Colormain text-sm mb-[6px]">
            {English.A236}
          </label>
          <Dropdown
            title={getSubCategoryNameById(inputFields.sub_category_id)}
            onChanged={(d) => setInputFields({
                ...inputFields,
                sub_category_id: d.id
              })}
            Data={subCategory.map((d) => ({
              title: d.sub_category_name,
              id: d.sub_category_id
            }))}
            name="sub_category_id"
            customClass={
              inputFields.sub_category_id
                ? 'selected-class !mb-[16px] !mt-0 !font-normal !text-[#a5a1a1] !text-[14px]'
                : 'giveaway-dropdown !mb-[16px] !mt-0 !font-normal !text-[#a5a1a1] !text-[14px]'
            }
            customClassHeight="dropdown-height-poll-category"
          />
        </div>
        <div className="">
          <div>
            <label className="text-Colormain text-sm mb-[6px] ">
              {English.A233}
            </label>
            <div className="feed_calender">
              <div className="calendar-clock poll_section">
                <CommonDatePicker
                  inputFields={inputFields}
                  setInputFields={setInputFields}
                  fieldName="expire_date"
                  placeholder={English.A237}
                  minDate={new Date()}
                  maxDate={new Date(new Date().getFullYear() + 10, 11, 31)}
                  //   popperPlacement="top"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }, [inputFields, handleChange, category, subCategory])

  const onPressRemoveOptions = (index) => {
    setInputFields((data) => {
      const newOptionsData = { ...data }
      const Data = []
      newOptionsData?.options?.forEach((item, arrayIndex) => {
        if (arrayIndex === index && item?.id) {
          Data.push({ ...item, isDelete: true })
        } else if (arrayIndex !== index) {
          Data.push(item)
        }
      })
      return { ...newOptionsData, options: Data }
    })
  }

  const optionText = useMemo(() => {
    return (
      <PollOptionView
        onOptionChange={(value, field, index) => {
          setInputFields((data) => {
            const newData = {
              ...data,
              options: [...data.options]
            }
            newData.options[index] = { id: newData.options[index]?.id, option: value }
            return newData
          })
        }}
        onPressAddMoreOption={() => {
          setInputFields((data) => {
            const newData = { ...data }
            newData.options.push({ option: '', id: '' })
            return newData
          })
        }}
        onPressRemoveOptions={onPressRemoveOptions}
        options={inputFields.options}
      />
    )
  }, [inputFields])

  const videoUploadStep = useMemo(() => {
    if (currentStep === 3) {
      return null
    }

    return (
      <div className="!mt-5 rounded-[15px] local_contact_scroll_bars !max-h-max duration-100">
        {currentStep === 1 && videoText}
        {currentStep === 2 && optionText}
      </div>
    )
  }, [inputFields, currentStep, videoText])

  const addPollDataSubmit = () => {
    setLoading(true)
    const options = inputFields.options.map((i) => i.option)
    const data = {
      app_id: inputFields?.app_data?.map((d) => d.id),
      topic: inputFields.topic,
      category_id: inputFields.category_id,
      sub_category_id: inputFields.sub_category_id,
      options,
      expire_date: inputFields.expire_date
    }
    APICall('post', data, `${EndPoints.addPoll}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            handleCancel()
            onSubmit(inputFields.poll_id)
            clearValue()
            setLoading(false)
            toast.success(res?.data?.message)
          }, 3000)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }
  const editPollDataApi = () => {
    setLoading(true)
    const options = inputFields.options.map((i) => ({
      id: i?.id ?? null,
      option: i.isDelete ? null : i.option
    }))
    const data = {
      app_id: inputFields?.app_id,
      topic: inputFields.topic,
      category_id: inputFields.category_id,
      sub_category_id: inputFields.sub_category_id,
      options,
      expire_date: inputFields.expire_date,
      poll_id: inputFields.poll_id
    }
    APICall('post', data, `${EndPoints.editPoll}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            clearValue()
            setLoading(false)
            handleCancel()
            toast.success(res?.data?.message)
            onSubmit(inputFields.poll_id)
          }, 3000)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }
  const publishPoll = () => {
    if (editData) {
      editPollDataApi()
    } else {
      addPollDataSubmit()
    }
    // setShowPollPreview(false)
    // setShowPollModel(false)
    // setIsEditing(false)
    // clearValue()
  }

  useEffect(() => {
    if (appData) {
      setInputFields({
        ...inputFields,
        app_data: appData
      })
    }
  }, [appData])

  const publishEnaled = () => {
    return !(
      inputFields.topic &&
      inputFields.category_id &&
      inputFields.sub_category_id &&
      inputFields.expire_date
    )
  }

  const publishOptionsEnabled = () => {
    return (inputFields.options.filter((item) => !item?.isDelete).some((item) => item?.option?.trim() === ''))
  }

  const appDataSelect = useMemo(() => {
    return (
      <PlatformSelect
        key={currentStep}
        step={currentStep}
        defaultIds={editData?.app_data}
        onSelectedIds={(data) => {
          setAppData(data)
        }}
      />
    )
  }, [])

  return (
    <CommonCMModal
      setStep={setCurrentStep}
      totalStep={3}
      step={currentStep}
      handleCancel={handleCancel}
      loading={loading}
      nextBtnLable={currentStep === 3 ? English.G128 : English.G69}
      nextBtnStatus={
        currentStep === 1
          ? publishEnaled()
          : currentStep === 2
            ? publishOptionsEnabled()
            : currentStep === 3
              ? inputFields?.app_data?.length === 0
              : false
      }
      cancelBtnStatus={loading}
      closeBtnStatus={loading}
      title="Create A Poll"
      onClickNext={() => {
        if (currentStep === 3) {
          publishPoll()
        } else {
          setCurrentStep(currentStep + 1)
        }
      }}
    >
      {videoUploadStep}
      {currentStep === 3 && appDataSelect}
    </CommonCMModal>
  )
}

export default PollAddModal

/* eslint-disable react/jsx-closing-tag-location */
import React, { useState } from 'react'
import './Local.css'
import English from '../../helpers/English'
import HotelsProfile from './HotelsProfile'
import HotelProfileDetails from './HotelProfileDetails'
// import RestaurantProfile from './RestaurantProfile'
import RestaurantProfileDetails from './RestaurantProfileDetails'
// import OrganizerProfile from './OrganizerProfile'
import OrganizerProfileDetails from './OrganizerProfileDetails'
import { Images } from '../../helpers'
// import { APICall, EndPoints } from '../../services'

// eslint-disable-next-line no-unused-vars
const LocalLocationHome = ({ singlePublished, onConnectClick }) => {
  const [isShow, setIsShow] = useState(false)
  const [hotelId, setHotelId] = useState('')
  const [restaurantId, setRestaurantId] = useState('')
  const [organizerId, setOrganizerId] = useState('')

  const addType = () => {
    onConnectClick()
  }

  return (
    <div className="feed-container mt-40 d-block">
      {(!isShow || singlePublished) && (
        <div className="details-tab-view">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="bg-primaryGradient bg-clip-text text-transparent text-lg italic font-black uppercase pe-2">
              {English.G259}
            </div>
            <button
              type="button"
              className="uppercase bg-primaryGradient text-white text-sm italic font-black rounded-[100px] !py-2 !px-5"
              onClick={addType}
            >
              {English.B91}
            </button>
          </div>
          {!isShow && (
            <>
              <HotelsProfile
                initialId={singlePublished === 'Hotel'}
                onDetailHotel={() => {
                  // setIsShow(true)
                  // setHotelId(hotelId)
                }}
                onDetail={(item) => {
                  setRestaurantId(item?.restaurant_id || '')
                  setOrganizerId(item?.organizer_id || '')
                  setHotelId(item?.hotel_id || '')
                  setIsShow(true)
                }}
              />
              {/* <RestaurantProfile
              initialId={singlePublished === 'Restaurant'}
              onDetailHotel={(restaurantId) => {
                setIsShow(true)
                setRestaurantId(restaurantId)
              }}
            />
            <OrganizerProfile
              initialId={singlePublished === 'Event'}
              onDetailHotel={(OrganizerId) => {
                setIsShow(true)
                setOrganizerId(OrganizerId)
              }}
            /> */}
            </>
          )}
        </div>
      )}
      {isShow && (
      <img
        src={Images.Black_back}
        alt="back"
        className="w-[15px] h-[15px] cursor-pointer mt-2"
        onClick={() => {
                setIsShow(false)
              }}
      />
          )}
      {isShow && hotelId && <HotelProfileDetails hotelId={hotelId} />}
      {isShow && restaurantId && (
        <RestaurantProfileDetails restaurantId={restaurantId} />
      )}
      {isShow && organizerId && (
        <OrganizerProfileDetails organizerId={organizerId} />
      )}
    </div>
  )
}

export default LocalLocationHome

import React from 'react'

const EmptyComponent = () => {
    return (
      <div className="bg-white shadow-sm text-center rounded-[15px] py-5 px-2 w-full border-lightBorder border-[1px]">
        <h6 className="text-textPrimary text-base font-black italic">No Activity</h6>
      </div>
    )
  }

  export default EmptyComponent

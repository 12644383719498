/* eslint-disable func-names */
/* eslint-disable import/no-named-as-default-member */
import axios from 'axios'
import { English } from '../helpers'
import BaseURL from './BaseUrl'
import Utility from '../helpers/Utility'
import { dataDecrypt, dataEncrypt } from './http-services'

export const getHeader = () => {
  return {
    accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  }
}

const axiosInstance = axios.create({
  baseURL: ''
})

axiosInstance.interceptors.request.use(
  (config) => {
    const tempConfig = config
    const token = Utility.getToken()

    if (token) {
      config.headers = { ...config?.headers, Authorization: `Bearer ${token}` }
    }
    return tempConfig
  },
  async (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (res) => res,
  async (error) => {
    return Promise.reject(error)
  }
)

const RequestData = (data) => {
  const formData = new FormData()
  formData.append('data', dataEncrypt(data))
  return formData
}

const APICall = async (
  method,
  body,
  url,
  urlType,
  formData,
  onUploadProgress,
  headers = {},
) => {
  const config = {
    method
  }
  if (url) {
    config.url = url
  }
  config.data = formData ? body : RequestData(body)

  if (onUploadProgress) {
    config.onUploadProgress = function (progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      onUploadProgress(percentCompleted)
    }
  }

  if (headers) {
    config.headers = headers
  }
  if (urlType) {
    switch (urlType) {
      case 'auth':
        config.baseURL = BaseURL.AuthURL
        break

      case 'imageUpload':
        config.baseURL = BaseURL.ImageUploadURL
        break

      case 'profile':
        config.baseURL = BaseURL.ProfileURL
        break

      case 'store':
        config.baseURL = BaseURL.StoreURL
        break

      case 'creator':
        config.baseURL = BaseURL.CreatorURL
        break

      case 'contentManager':
        config.baseURL = BaseURL.ContentManagerURL
        break

      case 'local':
        config.baseURL = BaseURL.LocalURL
        break

      case 'imageUploadLocal':
        config.baseURL = BaseURL.ImageUploadURLLocal
        break

      default:
        break
    }
  }

  return new Promise((resolve, reject) => {
    axiosInstance(config)
      .then((res) => {
        return resolve({
          status: res.status,
          data: res.data,
          decryptedData: dataDecrypt(res.data.data)
        })
      })
      .catch((error) => {
        if (
          error?.response?.status === 500 ||
          error?.status === 500 ||
          error?.response.status === 429 ||
          error.status === 429 ||
          error?.response?.status === 400 ||
          error?.status === 400 ||
          error?.response?.status === 502 ||
          error?.status === 502 ||
          error?.response?.status === 402 ||
          error?.status === 402 ||
          error?.response?.status === 404 ||
          error?.status === 404 ||
          error?.response?.status === 422 ||
          error?.status === 422 ||
          error?.code === 'ERR_NETWORK' ||
          error?.code === 'ECONNABORTED'
        ) {
          const errorData = {
            ...error,
            status: 500,
            data: {
              message: English.G462
            }
          }
          return reject(errorData || errorData?.response)
        }
      })
  })
}

export default APICall

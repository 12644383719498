/* eslint-disable no-confusing-arrow */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import './profile.css'
import { toast } from 'react-toastify'
import { English } from '../../helpers'
import CommonInput from '../InputComponet/CommonInput'
import { APICall, EndPoints } from '../../services'
import { urlSystemIcon } from '../../services/Service'
import CommonButton from '../ButtonComponent/ButtonCommon'

const CreateProfileModal = ({ closeModal }) => {
  //   const [isAppLoading, setIsAppLoading] = useState(false)
  const [apps, setApps] = useState([])
  const [search, setSearch] = useState()
  const [selectedApp, setSelectedApp] = useState(null)

  const getApplicationsData = async () => {
    // setIsAppLoading(true)
    APICall('get', '', EndPoints.applications, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedData = res?.decryptedData
          setApps(decryptedData)
          //   setIsAppLoading(false)
        } else {
          toast.error(res.data?.message)
          //   setIsAppLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        // setIsAppLoading(false)
      })
  }

  useEffect(() => {
    getApplicationsData()
  }, [])
  console.log(apps.filter((item) => item.name.toLowerCase().includes(search?.toLowerCase() || '')))
  return (
    <div className="add-item-model backdrop-blur-sm">
      <div className="add-item-content !h-[95%] !w-[95%] !pr-0">
        <div className="flex justify-between items-center gap-4 w-full pt-[10px] px-[20px]">
          <h3 className="!text-[#0B0A0A] !italic !text-[18px] !font-black uppercase">
            Create Profile
          </h3>
          <button
            type="button"
            className="text-black text-lg !border-none"
            onClick={closeModal}
          >
            ✖
          </button>
        </div>
        <div className="px-4">
          <div>
            <div className="flex flex-col justify-center items-center">
              <h3 className="text-Main !text-[32px] italic !font-black uppercase mb-0">
                {English.A219}
              </h3>
              <p className="!text-Main !text-[24px] italic !font-black uppercase">
                {English.A220}
              </p>
            </div>
          </div>
          <div>
            <div className="pb-3">
              <div className="w-full relative mb-7">
                <CommonInput
                  placeholder="Search profile to filter"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="!rounded-full bg-PrimaryBg !px-[20px] !py-[15px] !border-none before:"
                />
              </div>
              <div className="grid grid-cols-4 gap-[20px]">
                {apps.filter((item) => item.name.toLowerCase().includes(search?.toLowerCase() || '')).map((i, index) => (
                  <div
                    key={i.id || index}
                    className={`border-[0.5px] rounded-[15px] !p-4 cursor-pointer transition-all duration-300 hover:bg-PrimaryBg ${
                    selectedApp === i.id ? 'border-[#020C1C]' : 'border-BorderLight'
                  }`}
                    onClick={() => {
                    setSelectedApp(i.id)
                  }}
                  >
                    <div className="mb-[5px]">
                      <img src={urlSystemIcon + i.new_icon} alt="" />
                    </div>
                    <div className="!font-extrabold !italic !text-xl !text-Colormain uppercase mb-[4px]">
                      {i.name}
                    </div>
                    <p className="!text-[14px] !font-normal !text-[#7A7474] text-start mb-0">
                      Lorem ipsum dolor sit amet, cons ectetur adipiscing elit.
                      Amet et lobortis at.
                    </p>
                  </div>
                ))}
              </div>
              <div className="text-end absolute bottom-[40px] right-[70px]">
                <CommonButton
                  label={English.B58}
                  type="button"
                  isPrimary
                  disabled
                  context={English.G232}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CreateProfileModal

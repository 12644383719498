/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react'
import './recent-video.css'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Skeleton from 'react-loading-skeleton'
// import { Progress } from 'antd'
import { useSelector } from 'react-redux'
import { s3ImageProfile, s3ImageVideo } from '../../services/Service'
import {
  compressImage,
  compressVideo,
  dataDecrypt
} from '../../services/http-services'
// import PlatformTag from '../ContentManager/PlatformTag'
import Loader from '../Loader/Loader'
import {
  capitalizeFirstLetter,
  formatDuration,
  formatDurations,
  formatViews,
  lockScroll,
  timeDifference,
  unlockScroll
} from '../../helpers/Utility'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
// import { useVideoProgress } from '../../providers/VideoProgressProvider'
import { emitter, EventType } from '../../helpers/Emitter'
import VideoPlayer from '../ContentManager/CommonVideoPlayer'
import CommentModal from '../CommanComponent/CommentModal'
import { APICall, EndPoints } from '../../services'
import { English, Images } from '../../helpers'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import CommonModalMain from '../CommonModalMain'
import UploadingComponent from '../UploadingComponent/UploadingComponent'
import Dropdown from '../Dropdown/Dropdown'

const VideoComponent = () => {
  // const userData = useSelector((state) => state.user?.userData)
  const profileData = useSelector((state) => state.user?.profileData)
  const app_id = useSelector((state) => state.user?.app_id)

  const videoRef = useRef(null)
  const componentRef = useRef()

  const [loading, setLoading] = useState(false)
  const [delLoading, setDelLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [inputFields, setInputFields] = useState({
    title: '',
    description: '',
    duration: '',
    attachment: '',
    cover_attachment: '',
    app_id: [app_id.id],
    category_id: 0,
    search: ''
  })
  const [Platformtags, setPlatformTags] = useState([])
  const [showAddVideoModal, setShowAddVideoModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [previewModel, setPreviewModel] = useState(false)
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [selectedFilePath, setSelectedFilePath] = useState('')
  const [selectedFileObj, setSelectedFileObj] = useState('')
  const [selectedVideo, setSelectedVideo] = useState('')
  const [selectedVideoObj, setSelectedVideoObj] = useState('')
  const [delVideoId, setDelVideoId] = useState()
  // const [initialPlatformTags, setInitialPlatformTags] = useState([])
  // const [initialDescription, setInitialDescription] = useState('')
  // const [hasPlayed, setHasPlayed] = useState(false)
  // const [hasFinished, setHasFinished] = useState(false)
  const [videos, setVideos] = useState([])
  const [comments, setComments] = useState([])
  const [selectedId, setSelectedId] = useState('')
  const [commentSection, setCommentSection] = useState(false)
  const [newComment, setNewComment] = useState('')
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPageComment, setCurrentPageComment] = useState(1)
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)
  const [localStorageValue, setLocalStorageValue] = useState(app_id || null)
  // const [particularData, setParticularVideoData] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [expandedIndex, setExpandedIndex] = useState(null)

  // const { videoComProgress, updateVideoComProgress } = useVideoProgress()
  const [expandedDescription, setExpandedDescription] = useState(false)
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [isVideoUploading, setIsVideoUploading] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [category, setCategory] = useState([])

  const commentClear = () => {
    setComments([])
    setSelectedId([])
    setNewComment('')
    setShowReplied('')
    setEditedCommentText('')
    setEditedReplyText('')
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  // const getSelectedAppId = (app) => {
  //   setInputFields({ ...inputFields, app_id: app.map((a) => a.id) })
  // }

  // const isAppsSelected = inputFields.app_id?.length > 0

  const VideoDetailModel = () => {
    setShowModal(true)
    setShowAddVideoModal(false)
  }

  const cancelHandle = () => {
    setShowAddVideoModal(true)
    setShowModal(false)
    // setHasFinished(true)
  }

  // const handleBackImage = () => {
  //   // setHasFinished(true)
  // }

  // const cancelPreviewHandle = () => {
  //   setPreviewModel(false)
  //   clearValue()
  // }

  const handleCancel = () => {
    clearValue()
    setShowModal(false)
    setShowAddVideoModal(false)
    setShowConfirmModel(false)
    setPreviewModel(false)
    setIsEditing(false)
    // setHasFinished(true)
  }

  const clearValue = () => {
    setSelectedFilePath('')
    setSelectedFileObj('')
    setSelectedVideo('')
    setSelectedVideoObj('')
    setPlatformTags([])
    setUploadPercentage(0)
    setIsVideoUploading(false)
    setInputFields({
      ...inputFields,
      title: '',
      description: '',
      duration: '',
      attachment: '',
      cover_attachment: '',
      app_id: '',
      video_id: '',
      category_id: ''
    })
    setCategory([])
    setLoading(false)
  }

  const deleteVideo = () => {
    const data = {
      video_id: delVideoId
    }
    setDelLoading(true)
    APICall('post', data, `${EndPoints.deleteVideo}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setTimeout(() => {
            getVideos(currentPage)
            emitter.emit(EventType.ProfileUpdate)
          }, 3500)
          setDelLoading(false)
          toast.success(res?.data?.message)
        } else {
          setDelLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setDelLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const editVideoApiCall = () => {
    const data = {
      title: inputFields.title,
      description: inputFields.description,
      attachment: inputFields.attachment,
      cover_attachment: inputFields.cover_attachment,
      app_id: Platformtags.map((d) => d.id),
      video_id: inputFields.video_id,
      category_id: inputFields.category_id
    }
    APICall('post', data, `${EndPoints.editVideo}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            clearValue()
            setLoading(false)
            toast.success(res?.data?.message)
            getVideos(currentPage)
            setShowConfirmModel(false)
            setShowPreviewModal(false)
            setPreviewModel(false)
            setIsEditing(false)
            setShowModal(false)
            setLoading(false)
            setIsButtonLoading(false)
            // setHasFinished(true)
          }, 4500)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const delay = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  const handlePublishClick = async () => {
    // const videoId = Date.now()

    // updateVideoComProgress(videoId, 0)

    setShowPreviewModal(false)
    setPreviewModel(false)
    const durationInSeconds = Math.trunc(videoRef.current.duration)
    const formattedDuration = formatDuration(durationInSeconds)

    inputFields.duration = formattedDuration

    setLoading(true)

    try {
      if (selectedFileObj) {
        const imageFile = await compressImage(selectedFileObj)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'video')

        await APICall(
          'post',
          coverFormData,
          EndPoints.uploadImage,
          'creator',
          true
        )
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedCoverData = res?.decryptedData
              inputFields.cover_attachment = decryptedCoverData
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((e) => {
            toast.error(e?.data?.message)
          })
      }

      if (!isEditing || selectedVideoObj) {
        const videoFile = await compressVideo(
          selectedVideoObj,
          setUploadPercentage,
          setIsVideoUploading
        )
        const videoFormData = new FormData()
        videoFormData.append('file', videoFile)
        videoFormData.append('type', 'video')

        await APICall(
          'post',
          videoFormData,
          EndPoints.uploadImage,
          'creator',
          true
        )
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedVideoData = dataDecrypt(res.data.data)
              inputFields.attachment = decryptedVideoData
              setIsButtonLoading(true)
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((e) => {
            toast.error(e?.data?.message)
          })
      }

      await delay(5000)
      if (isEditing) {
        setIsButtonLoading(true)
        editVideoApiCall()
      } else {
        addVideoApiCall()
      }

      // updateVideoComProgress(videoId, 100)
    } catch (error) {
      setLoading(false)
      toast.error(error.message || 'An error occurred')
    }
  }

  const addVideoApiCall = () => {
    const data = {
      title: inputFields.title,
      description: inputFields.description,
      attachment: inputFields.attachment,
      cover_attachment: inputFields.cover_attachment,
      app_id: [app_id?.id],
      video_id: inputFields.video_id,
      duration: inputFields.duration,
      category_id: inputFields.category_id
    }
    APICall('post', data, `${EndPoints.addVideo}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            getVideos(currentPage)
            emitter.emit(EventType.ProfileUpdate)
            clearValue()
            setLoading(false)
            setShowModal(false)
            toast.success(res?.data?.message)
            setIsButtonLoading(false)
          }, 4500)
          setIsEditing(false)
          // setHasFinished(true)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  // const handleEditClick = () => {
  //   document.getElementById('upload-cover').click()
  // }

  const handleFileChange = (file) => {
    const maxFileSizeMB = 10
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024
    if (file.size > maxFileSizeBytes) {
      toast.error(`File size exceeds ${maxFileSizeMB} MB`)
      return
    }
    const reader = new FileReader()
    reader.onload = (e) => {
      setSelectedFilePath(e.target.result)
      setSelectedFileObj(file)
    }
    reader.readAsDataURL(file)
  }

  const handleVideoChange = (event) => {
    const maxFileSizeMB = 500
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024
    const maxDurationMinutes = 15

    const fileInput = event.target
    const selectedFile = fileInput.files[0]

    if (selectedFile) {
      if (selectedFile.size > maxFileSizeBytes) {
        toast.error(`Video file size exceeds ${maxFileSizeMB} MB`)
        return
      }
      const video = document.createElement('video')
      video.src = URL.createObjectURL(selectedFile)
      video.onloadedmetadata = () => {
        const durationMinutes = video.duration / 60
        if (durationMinutes > maxDurationMinutes) {
          toast.error(`Video duration exceeds ${maxDurationMinutes} minutes`)
          return
        }
        setSelectedVideoObj(selectedFile)
        const videoURL = URL.createObjectURL(selectedFile)
        setSelectedVideo(videoURL)
      }
    }
  }

  const ShowConfirm = (videoId) => {
    setDelVideoId(videoId)
    setShowConfirmModel(true)
  }

  const onClickEditPodcast = (videoData) => {
    getParticularVideosData(videoData.video_id)
    setShowAddVideoModal(true)
    setIsEditing(true)
    setSelectedVideo(s3ImageVideo + videoData.attachment)
    setSelectedFilePath(s3ImageVideo + videoData.cover_attachment)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      description: videoData.description,
      duration: videoData.duration,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      video_id: videoData.video_id,
      category_id: videoData.category_id
    })
    setInitialValues({
      title: videoData.title,
      description: videoData.description,
      duration: videoData.duration,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      video_id: videoData.video_id,
      category_id: videoData.category_id,
      search: ''
    })
    setPlatformTags(videoData.app_data)
    MainCategory({ category_id: videoData.category_id })
  }

  const handleItemClick = (videoData) => {
    getParticularVideosData(videoData.video_id)
    setPreviewModel(true)
    setIsEditing(true)
    setSelectedVideo(s3ImageVideo + videoData.attachment)
    setSelectedFilePath(s3ImageVideo + videoData.cover_attachment)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      description: videoData.description,
      duration: videoData.duration,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      video_id: videoData.video_id,
      category_id: videoData.category_id
    })
    setPlatformTags(videoData.app_data)
    // setInitialPlatformTags(videoData.app_data)
    // setInitialDescription(videoData.description)
  }

  const MainCategory = () => {
    APICall('post', {}, `${EndPoints.getCategory}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setCategory(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }
  const AddVideo = () => {
    setShowAddVideoModal(true)
    MainCategory()
    // navigate('/add-component', { state: { defaultIndex: 0 } })
  }

  const getCategoryName = (categoryId) => {
    const categoryObj = category.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.category_name : English.G134
  }

  const getVideos = (currentPage) => {
    // setIsLoading(true)
    const platFormData = {
      app_id: app_id.id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.getVideos}?page=${currentPage}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const list = res?.decryptedData.data
          setVideos(currentPage === 1 ? list : [...videos, ...list])
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const getParticularVideosData = (id) => {
    setIsLoading(true)
    const data = {
      video_id: id
    }
    APICall('post', data, `${EndPoints.particularVideo}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          // setParticularVideoData(res?.decryptedData.data)
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  // videos pagination

  const totalVideos = videos?.length

  const handlePageChange = (pageNumber) => {
    getVideos(pageNumber)
    setCurrentPage(pageNumber)
  }

  const validateForm = () => {
    const { title, description } = inputFields
    setIsFormComplete(!!title && !!description)
  }

  // const viewCount = (videoId) => {
  //   const data = {
  //     video_id: videoId
  //   }
  //   APICall('post', data, `${EndPoints.videoViewCount}`, 'contentManager')
  //     .then((res) => {
  //       if (res.status === 200 && res.data?.code === 200) {
  //         //
  //       } else {
  //         toast.error(res?.data?.message)
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error?.data?.message)
  //     })
  // }

  // const handleVideoPlay = (video_id) => {
  //   if (!hasPlayed || hasFinished) {
  //     viewCount(video_id)
  //     setHasPlayed(true)
  //     setHasFinished(false)
  //   }
  // }

  const handleVideoEnd = () => {
    // setHasFinished(true)
  }

  // const videoLikeData = (video_id) => {
  //   const platFormData = {
  //     app_id: app_id.id,
  //     video_id
  //   }
  //   APICall('post', platFormData, `${EndPoints.videoLike}`, 'contentManager')
  //     .then((res) => {
  //       if (res.status === 200 && res.data?.code === 200) {
  //         setVideos(
  //           videos.map((item) => (item.video_id === video_id
  //               ? { ...item, is_like: !item.is_like }
  //               : item))
  //         )
  //       } else {
  //         toast.error(res?.data?.message)
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error?.data?.message)
  //     })
  // }

  // comment section
  const fetchVideoComments = (video_id, delay = 1500) => {
    setTimeout(() => {
      videoComment(video_id)
    }, delay)
  }

  // const onClickComment = (video_id) => {
  //   setSelectedId(video_id)
  //   if (!isLoadingComments) {
  //     setIsLoadingComments(true)
  //     videoComment(video_id)
  //     setCommentSection(true)
  //   }
  // }

  const videoComment = (video_id) => {
    const platFormData = {
      app_id: app_id.id,
      video_id
    }
    APICall('post', platFormData, `${EndPoints.videoComment}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const dataComment = res?.decryptedData?.data?.map((d, i) => ({
            id: i + 1,
            text: d.comment,
            video_id: d.video_id,
            is_like: d.is_like,
            reply_id: d.comment_id,
            total_like: d.total_like,
            reply: d.reply_data.map((subData, subIndex) => ({
              id: subIndex + 1,
              text: subData.comment,
              reply_id: subData.comment_id,
              total_like: subData.total_like,
              is_like: subData.is_like,
              user: {
                name: subData.user_name,
                profilePic: s3ImageProfile + subData.profile_icon
              }
            })),
            user: {
              name: d.user_name,
              profilePic: s3ImageProfile + d.profile_icon
            }
          }))
          setComments(dataComment)
          setIsLoadingComments(false)
        } else {
          setIsLoadingComments(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsLoadingComments(false)
        toast.error(error?.data?.message)
      })
  }

  const shotAddComment = (comment) => {
    const platFormData = {
      video_id: selectedId,
      comment,
      app_id: app_id.id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.videoCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchVideoComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotAddCommentReply = (comment_id, replyText) => {
    const platFormData = {
      video_id: selectedId,
      comment_id,
      comment: replyText,
      app_id: app_id.id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.videoCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchVideoComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotCommentEdit = (reply_id, comment) => {
    const platFormData = {
      comment_id: reply_id,
      comment
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.videoCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchVideoComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotCommentEditReply = (comment_id, replyText) => {
    const platFormData = {
      video_id: selectedId,
      comment_id,
      comment: replyText
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.videoCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchVideoComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotCommentDelete = (reply_id) => {
    setIsDeleting(true)
    const data = {
      comment_id: reply_id
    }
    APICall('post', data, `${EndPoints.videoCommentDelete}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchVideoComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const shotCommentDeleteReply = (commentId, replyId) => {
    setIsDeleting(true)
    const data = {
      comment_id: commentId,
      reply_id: replyId
    }
    APICall('post', data, `${EndPoints.videoCommentDelete}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchVideoComments(selectedId)
          toast.success(res?.data?.message)
          setIsDeleting(false)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const shotCommentLike = (reply_id) => {
    const platFormData = {
      app_id: app_id.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.videoCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          //
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const shotCommentReplyLike = (reply_id) => {
    const platFormData = {
      app_id: app_id.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.videoCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          //
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleAddComment = (comment) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        video_id: selectedId,
        total_like: 0,
        reply: [],
        user: {
          name: profileData.user_name,
          profilePic: s3ImageProfile + profileData.profile_icon
        }
      }
      setComments([newCommentObj, ...comments])
      setNewComment('')
    }
    shotAddComment(comment)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.reply_id === commentId) {
        return {
          ...comment,
          total_like: comment.is_like
            ? comment.total_like - 1
            : comment.total_like + 1,
          is_like: !comment.is_like
        }
      }
      return comment
    })
    setComments(updatedComments)
    shotCommentLike(commentId)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          reply: [
            ...comment.reply,
            {
              id: comment.reply.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: profileData.user_name,
                profilePic: s3ImageProfile + profileData.profile_icon
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    shotAddCommentReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          reply: comment.reply.filter((reply) => reply.id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    shotCommentDeleteReply(replyId, commentId)
  }

  const toggleReplies = (commentId, forceCloseAll = false) => {
    if (forceCloseAll) {
      setShowReplies({})
    } else {
      setShowReplies((prevState) => ({
        // ...prevState,
        [commentId]: !prevState[commentId]
      }))
    }
    setShowReplied('')
  }

  const HandleCancel = () => {
    setCommentSection(false)
    commentClear()
    toggleReplies(null, true)
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.reply_id === commentId
        ? { ...comment, text: editedCommentText }
        : comment))
    shotCommentEdit(commentId, reply)
    setComments([...updatedComments])
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentReplayId, replayCommentId) => {
    const updatedComments = comments.map((comment) => (comment.reply_id === commentReplayId
        ? {
            ...comment,
            reply: comment.reply.map((reply) => (reply.reply_id === replayCommentId
                ? { ...reply, text: editedReplyText }
                : reply))
          }
        : comment))
    shotCommentEditReply(replayCommentId, editedReplyText)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  // comment pagination

  const commentsPerPage = 5
  const indexOfLastComment = currentPageComment * commentsPerPage
  const indexOfFirstComment = indexOfLastComment - commentsPerPage
  const commentsList = comments.slice(indexOfFirstComment, indexOfLastComment)
  const totalCommentPages = Math.ceil(comments.length / commentsPerPage)

  useEffect(() => {
    const handleStorageChange = () => {
      setLocalStorageValue(app_id || null)
    }

    window.addEventListener('storage', handleStorageChange)

    return () => window.removeEventListener('storage', handleStorageChange)
  }, [app_id])

  useEffect(() => {
    if (currentPageComment > totalCommentPages) {
      setCurrentPageComment(totalCommentPages || 1)
    }
  }, [comments, currentPageComment, totalCommentPages])

  useEffect(() => {
    if (localStorageValue !== null) {
      setIsLoading(true)
      getVideos(currentPage)
    }
  }, [localStorageValue])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!loading) {
        if (
          componentRef.current &&
          !componentRef.current.contains(event.target)
        ) {
          setShowModal(false)
          setShowPreviewModal(false)
          // setShowAddVideoModal(false)
          setShowConfirmModel(false)
          setPreviewModel(false)
          clearValue()
          setIsEditing(false)
        }
      }
    }
    if (
      !loading &&
      (showModal ||
        showPreviewModal ||
        // showAddVideoModal ||
        previewModel ||
        ShowConfirmModel)
    ) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    validateForm()
  }, [inputFields, isEditing])

  useEffect(() => {
    if (isEditing) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    } else {
      validateForm()
    }
  }, [inputFields, initialValues, isEditing])

  useEffect(() => {
    if (
      previewModel ||
      commentSection ||
      showAddVideoModal ||
      showModal ||
      showPreviewModal ||
      ShowConfirmModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [
    previewModel,
    commentSection,
    showAddVideoModal,
    showModal,
    showPreviewModal,
    ShowConfirmModel
  ])

  return (
    <>
      {videos?.length > 0 && (
        <div className="discussions-wrapper-header common-contnt-wrapper">
          {/* {showCount && ( */}
          <div className="tag_link">
            {/* <div className="video_button"> */}
            <h2 className="!text-2xl text-Colormain italic !font-black uppercase">
              {English.A108}
            </h2>

            <div className="add-video-rows">
              <CommonButton
                label={English.G153}
                // imgSrc={Images.AddIco}
                onClick={AddVideo}
                isPrimary
                className="add_new_content"
              />
            </div>
            {/* </div> */}
          </div>
          {/* )} */}
        </div>
      )}
      {/* <div
        className={
          showCount !== 3 &&
          'content-manager-awards-wrapper common-card-body add-profile-video video-detail-manager  grid grid-cols-3'
        }
      > */}
      {/* {!showCount &&
          _.map(videoComProgress, (pro, videoId) => {
            if (pro === 100) {
              return null
            }
            return (
              <div className="custom-progress" key={videoId}>
                <Progress
                  percent={pro}
                  status="active"
                  strokeColor={{
                    '3.03%': '#001597',
                    '47.1%': '#01104d',
                    '80.14%': '#020c1c',
                    '96.45%': '#020b09'
                  }}
                  trailColor="#d9d9d9"
                  size="100%"
                />
              </div>
            )
          })} */}
      {videos?.length === 0 && !isLoading ? (
        <CommonEmptyData
          image={Images.media_business_gray}
          mainTitle="No Videos Yet"
          descriptionText="Upload video to show here"
          onClick={AddVideo}
          addIcon={Images.FileUploadWhite}
          btnText="Upload Video"
          className="!bg-[#FAFAFA]"
          classNameDescription="font-normal text-[#7A7474] text-[16px]"
        />
      ) : isLoading ? (
        <div className="grid grid-cols-3">
          <div className="video-detail-item">
            <div className="video-detail-cover">
              <div className="video_like_bg">
                <button type="button" className="video_like">
                  <Skeleton width={25} height={25} />
                </button>
              </div>
            </div>
            <div className="text-center">
              <Skeleton width={250} height={250} />
            </div>
            <div className="video-content">
              <p>
                <Skeleton width={250} />
              </p>
              <div className="views-and-time">
                <span>
                  <Skeleton width={50} />
                </span>
                <span>
                  <Skeleton width={60} />
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="content-manager-awards-wrapper add-profile-video">
          <div className="video_data_show !grid !grid-cols-3 gap-3">
            {videos?.map(
              (item, index) => index < totalVideos && (
              <div
                className="video-detail-item overflow-hidden"
                key={s3ImageVideo + item.cover_attachment}
              >
                <div
                  className="video-detail-cover !aspect-[1.63/1] !w-full relative"
                  onClick={() => handleItemClick(item)}
                  style={{
                        backgroundImage: `url(${
                          s3ImageVideo + item.cover_attachment
                        })`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100%'
                      }}
                >
                  <div className="video_time">
                    <span className="!text-[#0B0A0A] !text-[10px] !font-black !italic">
                      {formatDurations(item.duration)}
                    </span>
                  </div>
                  {/* <div className="video_like_bg">
                    <button
                      type="button"
                      className="video_like"
                      onClick={(e) => {
                            e.stopPropagation()
                            videoLikeData(item.video_id)
                          }}
                    >
                      {item?.is_like ? (
                        <img src={Images.Likes} alt="Unlike" />
                          ) : (
                            <img src={Images.UnLikes} alt="Like" />
                          )}
                    </button>
                  </div> */}
                </div>
                {/* <div className="video-content"> */}
                <div
                  className={`video-content ${expandedIndex === index ? 'expanded' : ''}`}
                >
                  <p className="text-[#303030] text-sm font-bold">
                    {expandedIndex === index
                          ? item?.title
                          : `${item?.title?.slice(0, 60)}`}
                    {item?.title?.length > 60 && (
                    <span
                      className="see-more"
                      onClick={(e) => {
                              e.stopPropagation()
                              setExpandedIndex(
                                expandedIndex === index ? null : index
                              )
                            }}
                    >
                      {expandedIndex === index
                              ? English.G145
                              : English.G146}
                    </span>
                        )}
                  </p>
                  <div className="views-and-time">
                    <span>
                      <img src={Images.ViewIco} alt="" />
                      {formatViews(item.views)}
                    </span>
                    <span>
                      <img src={Images.ClockFillIco} alt="" />
                      {timeDifference(item.created_at)}
                    </span>
                  </div>
                </div>
                <div className="image-buttons z-1">
                  <label>
                    <button
                      type="button"
                      onClick={() => onClickEditPodcast(item)}
                      className="d-none"
                    />
                  </label>
                  <button
                    type="button"
                    className="p-0"
                    onClick={() => {
                          setUpdatingIndex(index)
                          ShowConfirm(item.video_id)
                        }}
                  />
                  {/* <div
                    type="button"
                    className="button1"
                    onClick={() => onClickComment(item.video_id)}
                  /> */}
                </div>
                <ConfirmationModal
                  isVisible={ShowConfirmModel && updatingIndex === index}
                  onConfirm={deleteVideo}
                  onCancel={handleCancel}
                  isLoading={delLoading}
                />
                {delLoading && updatingIndex === index && <Loader />}
              </div>
                )
            )}
          </div>
          {totalPages > 1 && currentPage <= totalPages - 1 && (
            <div
              className="p-5 cursor-pointer transition-transform duration-300 ease-in-out hover:scale-150"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <img
                className="justify-self-center"
                src={Images.down_arrow_icon}
                alt="load more"
              />
            </div>
          )}
        </div>
      )}

      {/* {previewModel && (
          <div className="add-item-model">
            <div className="add-item-content" ref={componentRef}>
              <div className="d-flex justify-content-between header">
                <h3>{English.G143}</h3>
                <button
                  type="button"
                  className="border-0 bg-none p-0"
                  onClick={handleCancel}
                >
                  <span>
                    <img className="close-btns" src={Images.CloseIco} alt="" />
                  </span>
                </button>
              </div>
              <div className="scroll-bar pb-0">
                <div className="common-card-body">
                  <div className="video-player-container">
                    <video
                      ref={videoRef}
                      width="100%"
                      height="394"
                      poster={selectedFilePath}
                      controls
                      className="video-player"
                      onPlay={() => handleVideoPlay(inputFields.video_id)}
                      onEnded={handleVideoEnd}
                      allowFullScreen
                    >
                      <source src={selectedVideo} type="video/mp4" />
                    </video>
                  </div>
                  <div className="title_views">
                    <div className="show-filled-details">
                      <h5>{inputFields.title}</h5>
                    </div>
                    {particularData.map((data, i) => (
                      <div className="view_count" key={i}>
                        <img src={Images.ViewIco} alt="" />
                        <h6>{data.views}</h6>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="discription common-card-body">
                  <h6>{English.G112}</h6>
                  <CommonInput
                    className="preview_text"
                    onChange={(e) => setInputFields({
                        ...inputFields,
                        description: e.target.value
                      })}
                    value={inputFields.description}
                    isTextArea
                  />
                </div>
                <PlatformTag
                  setPlatformTags={setPlatformTags}
                  Platformtags={Platformtags}
                  onItemSelect={(value) => getSelectedAppId(value)}
                />
              </div>
              <div className="item-details-input">
                <div className="button-row video_sticky_btn">
                  <CommonButton
                    label={English.G116}
                    type="button"
                    onClick={cancelPreviewHandle}
                    isPrimary={false}
                    disabled={loading}
                    context={English.G232}
                  />
                  <CommonButton
                    label={isAppsSelected ? English.G128 : English.G89}
                    loading={loading}
                    type="button"
                    onClick={handlePublishClick}
                    className={`primary-btn-model-store ${loading ? 'loading' : ''}`}
                    isPrimary
                    disabled={
                      loading ||
                      (_.isEqual(initialPlatformTags, Platformtags) &&
                        initialDescription === inputFields.description)
                    }
                    context={English.G232}
                  />
                </div>
              </div>
            </div>
          </div>
        )} */}
      {commentSection && (
        <CommentModal
          title={English.G147}
          comments={comments}
          setComments={setComments}
          HandleCancel={HandleCancel}
          commentIndex={commentIndex}
          commentsList={commentsList}
          editCommentId={editCommentId}
          editReplyId={editReplyId}
          editedCommentText={editedCommentText}
          editedReplyText={editedReplyText}
          handleAddComment={handleAddComment}
          handleAddReply={handleAddReply}
          handleDeleteReply={handleDeleteReply}
          handleEditComment={handleEditComment}
          handleEditReply={handleEditReply}
          handleLikeComment={handleLikeComment}
          shotCommentReplyLike={shotCommentReplyLike}
          handleSaveComment={handleSaveComment}
          handleSaveReply={handleSaveReply}
          isLoadingComments={isLoadingComments}
          newComment={newComment}
          profileData={profileData}
          setCommentIndex={setCommentIndex}
          setEditedCommentText={setEditedCommentText}
          setEditedReplyText={setEditedReplyText}
          setNewComment={setNewComment}
          setShowReplied={setShowReplied}
          shotCommentDelete={shotCommentDelete}
          showReplies={showReplies}
          toggleReplies={toggleReplies}
          currentPageComment={currentPageComment}
          setCurrentPageComment={setCurrentPageComment}
          isDeleting={isDeleting}
          Replied={Replied}
        />
      )}
      {/*
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )} */}
      {showAddVideoModal && (
        <CommonModalMain
          showCross
          title={English.G153}
          isPrevious={English.G116}
          isNext={English.B58}
          onPressClose={handleCancel}
          // className={className}
          onPressSave={VideoDetailModel}
          onPressBack={handleCancel}
          showFooter
          isButtonDisabled={!isFormComplete || !selectedVideo}
          currentCount={1}
          totalCount={2}
          showCount={1}
        >
          <div className="w-full h-full flex flex-col !gap-5 justify-between overflow-y-auto pr-2">
            <div className="item-details-input">
              <label className="mb-1">{English.A222}</label>
              <CommonInput
                name="title"
                placeholder={English.A223}
                onChange={handleChange}
                // maxLength={100}
                value={capitalizeFirstLetter(inputFields.title)}
              />
              <div className="poll-dropdown">
                <label>{English.G202}</label>
                <Dropdown
                  title={getCategoryName(inputFields.category_id)}
                  onChanged={(d) => {
                    setInputFields({
                      ...inputFields,
                      category_id: d.id
                    })
                  }}
                  Data={category.map((d) => ({
                    title: d.category_name,
                    id: d.id
                  }))}
                  name="category_id"
                  customClass={
                    inputFields.category_id
                      ? 'selected-class !mb-[16px] !mt-0'
                      : 'giveaway-dropdown !mb-[16px] !mt-0'
                  }
                />
              </div>
              <label className="mb-1">{English.G112}</label>
              <CommonInput
                name="description"
                placeholder={English.A224}
                onChange={handleChange}
                // maxLength={250}
                isTextArea
                value={capitalizeFirstLetter(inputFields.description)}
                className="pt-3"
              />
            </div>
            <div className="mb-3">
              {!selectedVideo && (
                <div className="upload-input">
                  <CommonInput
                    type="file"
                    accept="video/*"
                    id="upload-video"
                    onChange={handleVideoChange}
                    className="d-none"
                  />
                  {!selectedVideo && (
                    <CommonEmptyData
                      image={Images.FileUpload}
                      mainTitle={English.G153}
                      descriptionText={English.A208}
                      onClick={() => document.getElementById('upload-video').click()}
                      addIcon={Images.FileUploadWhite}
                      btnText={English.A210}
                      classNameDescription="max-w-[350px]"
                    />
                  )}
                </div>
              )}
              {selectedVideo && (
                <div className="video-wrapper modal-video">
                  <div className="video_mb">
                    <VideoPlayer
                      ref={videoRef}
                      videoSrc={selectedVideo}
                      posterSrc={selectedFilePath}
                      onVideoEnd={handleVideoEnd}
                      className="custom-video-player"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </CommonModalMain>
      )}
      {showModal && (
        <CommonModalMain
          showCross
          title={English.G153}
          isPrevious={English.G78}
          isNext={English.G117}
          onPressClose={handleCancel}
          // className={className}
          onPressSave={handlePublishClick}
          onPressBack={cancelHandle}
          showFooter
          isButtonDisabled={!selectedFilePath || loading}
          cancelButtonDisabled={loading}
          closeIcon={loading}
          currentCount={2}
          totalCount={2}
          showCount={2}
        >
          {/* {addLoading && <Loader />} */}
          <div className="w-full h-full flex flex-col gap-5 justify-between overflow-y-auto">
            <div>
              <div>
                <div className="video-wrapper rounded-[15px]">
                  <div className="video_mb">
                    <VideoPlayer
                      ref={videoRef}
                      videoSrc={selectedVideo}
                      posterSrc={selectedFilePath}
                      onVideoEnd={handleVideoEnd}
                      className="custom-video-player rounded-[15px] overflow-hidden"
                    />
                  </div>
                </div>
                <div className="item-details-input">
                  <h2 className="font-black italic text-Colormain text-xl">
                    {inputFields.title}
                  </h2>
                  <p className="text-[#5A5A5A]font-normal text-sm max-w-[540px]">
                    {expandedDescription
                      ? inputFields?.description
                      : `${inputFields?.description?.slice(0, 180)}`}
                    {inputFields?.description?.length > 180 && (
                      <span
                        className="see_more_local"
                        onClick={(e) => {
                          e.stopPropagation()
                          setExpandedDescription(!expandedDescription)
                        }}
                      >
                        ...
                        {' '}
                        {expandedDescription ? English.G193 : English.G194}
                      </span>
                    )}
                  </p>
                </div>
              </div>
              <div>
                <div className="upload-input upload_height border-1 border-dashed border-SecondaryBorder rounded-[15px] bg-PrimaryBg">
                  <CommonImageUpload
                    handleImageChange={handleFileChange}
                    index={0}
                    buttonText={English.A225}
                    selectedImg={selectedFilePath}
                    defaultImg=""
                    aspect={16 / 9}
                    className="video_img"
                    forImage="video"
                    forName="Videos"
                  />
                </div>
              </div>
            </div>
          </div>
          {isVideoUploading && (
            <UploadingComponent uploadPercentage={uploadPercentage} />
          )}
          {isButtonLoading && <Loader />}
        </CommonModalMain>
      )}
      {/* {showPreviewModal && (
          <div className="add-item-model">
            <div className="add-item-content" ref={componentRef}>
              <div className="d-flex justify-content-between header">
                <h3>{English.G143}</h3>
                <button
                  type="button"
                  className="border-0 bg-none p-0"
                  disabled={loading}
                  onClick={cancelHandle}
                >
                  <span>
                    <img className="close-btns" src={Images.CloseIco} alt="" />
                  </span>
                </button>
              </div>
              <div className="scroll-bar pb-0">
                <div className="video-wrapper">
                  <div className="video_mb">
                    <VideoPlayer
                      ref={videoRef}
                      videoSrc={selectedVideo}
                      posterSrc={selectedFilePath}
                      onVideoEnd={handleVideoEnd}
                      className="custom-video-player"
                    />
                  </div>
                </div>
                <div className="title_views">
                  <div className="show-filled-details">
                    <h5>{inputFields.title}</h5>
                  </div>
                  {particularData.map((data, i) => (
                    <div className="view_count" key={i}>
                      <img src={Images.ViewIco} alt="" />
                      <h6>{data.views}</h6>
                    </div>
                  ))}
                </div>
                <div className="show-filled-details">
                  <div className="d-flex streaming-data">
                    <p> 2,686 watching now</p>
                    <p> Started streaming on Mar 5, 2021</p>
                  </div>
                </div>
                <div className="podcast-profile mt-3">
                  <div className="podcast-profile-imgs border-0">
                    <img src={s3ImageProfile + userData.profile_image} alt="" />
                  </div>
                  <div className="podcast-profile-detail">
                    <span className="video_preview_user_name">
                      {userData.user_name}
                    </span>
                    <p className="video_preview_designation_p">
                      {English.G291}
                    </p>
                  </div>
                </div>
                <div className="discription common-card-body mb-30">
                  <h6>{English.G112}</h6>
                  <p>{inputFields.description}</p>
                </div>
                <PlatformTag
                  setPlatformTags={setPlatformTags}
                  Platformtags={Platformtags}
                  onItemSelect={(value) => getSelectedAppId(value)}
                />
              </div>
              <div className="item-details-input pt-0">
                <div className="button-row video_sticky_btn">
                  <CommonButton
                    label={English.G116}
                    type="button"
                    onClick={cancelHandle}
                    isPrimary={false}
                    disabled={loading}
                    context={English.G232}
                  />
                  <CommonButton
                    label={isAppsSelected ? English.G128 : English.G89}
                    loading={loading}
                    type="button"
                    onClick={handlePublishClick}
                    className={`primary-btn-model-store ${loading ? 'loading' : ''}`}
                    isPrimary
                    disabled={loading}
                    context={English.G232}
                  />
                </div>
              </div>
            </div>
          </div>
        )} */}
      {/* {videos?.length >= 2 && (
        <div
          className="view_all text-end mt-4"
          onClick={() => navigate(`/profile-builder/${id}`, {
              state: { defaultIndex: 2 }
            })}
        >
          {English.G144}
        </div>
      )} */}
    </>
  )
}
export default VideoComponent

/* eslint-disable react/react-in-jsx-scope */
import { useRef, useState } from 'react'
import { Play, Pause } from 'lucide-react'
import { Images } from '../../helpers'

const AudioPlayer = ({ src, ref, setDuration }) => {
  const localAudioRef = useRef(null)
  const audioRef = ref || localAudioRef
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [progress, setProgress] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)

  const togglePlay = () => {
    if (audioRef.current.paused) {
      audioRef.current.play()
      setIsPlaying(true)
    } else {
      audioRef.current.pause()
      setIsPlaying(false)
    }
  }

  const toggleMute = () => {
    audioRef.current.muted = !audioRef.current.muted
    setIsMuted(audioRef.current.muted)
  }


  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime)
    setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100)
  }

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration)
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  return (
    <div className="flex items-center gap-2 w-full p-1 rounded-lg ">
      <button onClick={togglePlay} className="p-3 bg-[#E4E4E4] rounded-full" type="button">
        {isPlaying ? <Pause fill="#020C1C" size={23} /> : <Play fill="#020C1C" size={23} />}
      </button>
      <span className="font-medium text-[16px] text-[#151515]">{formatTime(currentTime)}</span>
      <div className="flex-1 relative">
        <div className="h-2 bg-[#E1E2E5] rounded-full">
          <div
            className="h-2 bg-[#020C1C] rounded-full"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
      <button onClick={toggleMute} className="bg-gray-200 rounded-full" type="button">
        {isMuted ? <img src={Images.podcast_mute} alt="" /> : <img src={Images.podcast_volume} alt="" /> }
      </button>
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        onEnded={() => setIsPlaying(false)}
      />
    </div>
  )
}

export default AudioPlayer

/* eslint-disable react/button-has-type */
import React from 'react'
import { Images } from '../../../helpers'

const AudioPlaylist = () => {
  return (
    <div>
      <span className="text-base bg-primaryGradient bg-clip-text text-transparent font-bold">Episode</span>
      <div className="grid grid-cols-2 mt-2 !gap-5">
        <div className="flex items-center !gap-2.5">
          <button className="w-10 h-10">
            <img className="w-10 h-10" src={Images.PodcastPauseIcoNewDetail} alt="" loading="lazy" />
          </button>
          <div className="flex-1">
            <p className="capitalize text-baseBlackColor text-base font-semibold mb-0">
              e42 - World is too beautiful
            </p>
            <p className="text-sm font-medium text-secondaryText2 mb-0">Sep 23, 2024 - 37:12</p>
          </div>
        </div>
        <div className="flex items-center !gap-2.5">
          <button className="w-10 h-10">
            <img className="w-10 h-10" src={Images.PodcastPauseIcoNewDetail} alt="" loading="lazy" />
          </button>
          <div className="flex-1">
            <p className="capitalize text-baseBlackColor text-base font-semibold mb-0">
              e42 - World is too beautiful
            </p>
            <p className="text-sm font-medium text-secondaryText2 mb-0">Sep 23, 2024 - 37:12</p>
          </div>
        </div>
        <div className="flex items-center !gap-2.5">
          <button className="w-10 h-10">
            <img className="w-10 h-10" src={Images.PodcastPauseIcoNewDetail} alt="" loading="lazy" />
          </button>
          <div className="flex-1">
            <p className="capitalize text-baseBlackColor text-base font-semibold mb-0">
              e42 - World is too beautiful
            </p>
            <p className="text-sm font-medium text-secondaryText2 mb-0">Sep 23, 2024 - 37:12</p>
          </div>
        </div>
        <div className="flex items-center !gap-2.5">
          <button className="w-10 h-10">
            <img className="w-10 h-10" src={Images.PodcastPauseIcoNewDetail} alt="" loading="lazy" />
          </button>
          <div className="flex-1">
            <p className="capitalize text-baseBlackColor text-base font-semibold mb-0">
              e42 - World is too beautiful
            </p>
            <p className="text-sm font-medium text-secondaryText2 mb-0">Sep 23, 2024 - 37:12</p>
          </div>
        </div>
        <div className="flex items-center !gap-2.5">
          <button className="w-10 h-10">
            <img className="w-10 h-10" src={Images.PodcastPauseIcoNewDetail} alt="" loading="lazy" />
          </button>
          <div className="flex-1">
            <p className="capitalize text-baseBlackColor text-base font-semibold mb-0">
              e42 - World is too beautiful
            </p>
            <p className="text-sm font-medium text-secondaryText2 mb-0">Sep 23, 2024 - 37:12</p>
          </div>
        </div>
        <div className="flex items-center !gap-2.5">
          <button className="w-10 h-10">
            <img className="w-10 h-10" src={Images.PodcastPauseIcoNewDetail} alt="" loading="lazy" />
          </button>
          <div className="flex-1">
            <p className="capitalize text-baseBlackColor text-base font-semibold mb-0">
              e42 - World is too beautiful
            </p>
            <p className="text-sm font-medium text-secondaryText2 mb-0">Sep 23, 2024 - 37:12</p>
          </div>
        </div>
        <div className="flex items-center !gap-2.5">
          <button className="w-10 h-10">
            <img className="w-10 h-10" src={Images.PodcastPauseIcoNewDetail} alt="" loading="lazy" />
          </button>
          <div className="flex-1">
            <p className="capitalize text-baseBlackColor text-base font-semibold mb-0">
              e42 - World is too beautiful
            </p>
            <p className="text-sm font-medium text-secondaryText2 mb-0">Sep 23, 2024 - 37:12</p>
          </div>
        </div>
        <div className="flex items-center !gap-2.5">
          <button className="w-10 h-10">
            <img className="w-10 h-10" src={Images.PodcastPauseIcoNewDetail} alt="" loading="lazy" />
          </button>
          <div className="flex-1">
            <p className="capitalize text-baseBlackColor text-base font-semibold mb-0">
              e42 - World is too beautiful
            </p>
            <p className="text-sm font-medium text-secondaryText2 mb-0">Sep 23, 2024 - 37:12</p>
          </div>
        </div>
        <div className="flex items-center !gap-2.5">
          <button className="w-10 h-10">
            <img className="w-10 h-10" src={Images.PodcastPauseIcoNewDetail} alt="" loading="lazy" />
          </button>
          <div className="flex-1">
            <p className="capitalize text-baseBlackColor text-base font-semibold mb-0">
              e42 - World is too beautiful
            </p>
            <p className="text-sm font-medium text-secondaryText2 mb-0">Sep 23, 2024 - 37:12</p>
          </div>
        </div>
      </div>
      <button className="w-full !mt-5 flex items-center justify-center gap-2.5 rounded-[5px] bg-PrimaryBg py-2.5">
        <img src={Images.LoadMore} alt="" loading="lazy" />
        <span className="bg-primaryGradient bg-clip-text text-transparent text-base font-bold">
          Load More
        </span>
      </button>
    </div>
  )
}

export default AudioPlaylist

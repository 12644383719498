/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ProfileHeadCreator from '../components/ProfileBuilder/ProfileHeadCreator'
import ProfileHeadStore from '../components/ProfileBuilder/ProfileHeadStore'
import ProfileHeadLocal from '../components/ProfileBuilder/ProfileHeadLocal'
import './MainEditModal.css'
import SidebarNew from '../components/sidebar/SidebarNew'
// import ProfileHomePage from '../components/CreatorProfile/profileHomePage'
import { emitter, EventType } from '../helpers/Emitter'
// import ProfileBuilder from '../components/CreatorProfile/ProfileBuilder'
// import CommunityBuilder from '../components/CreatorProfile/CommunityBuilder'
import ProfileHeader from '../components/CreatorProfile/ProfileHeader'
// import LocalBuilder from '../components/CreatorProfile/LocalBuilder'
// import StoreBuilder from '../components/CreatorProfile/StoreBuilder'
import InstallAddons from '../components/CreatorProfile/installAddons'
import { English, Images } from '../helpers'
import ProfileHomePage from '../components/CreatorProfile/profileHomePage'
import VideoComponent from '../components/RecentVideos/VideoComponent'
import ShotsComponent from '../components/RecentShots/ShotsComponent'
import PodcastComponent from '../components/RecentPodcast/PodcastComponent'
import Feeds from '../components/Feeds/Feeds'
import StoreBannerComponent from '../components/UploadComponent/StoreBannerComponent'
import AddProduct from '../components/AddProduct/AddProduct'
import AddProductFilter from '../components/AddProduct/AddProductFilter'
import LatestDeal from '../components/AddProduct/LatestDeal'
import LatestReviews from '../components/AddProduct/LatestReviews'
import LocalHomePage from '../components/CreatorProfile/LocalHomePage'
import AwardsComponent from '../components/AddAwards/AwardsComponent'
import DisscussionComponent from '../components/AddDiscussions/DisscussionComponent'
import PollsComponent from '../components/AddPolls/PollsComponent'
import GivewayComponent from '../components/AddGiveway/GivewayComponent'
import ProfileCommonHead from '../components/ProfileBuilder/ProfileCommonHeader'

const MainEditModal = ({ closeModal }) => {
  const { state } = useLocation()
  const [activePlatform, setActivePlatform] = useState({})
  const [sidebarData, setSidebarData] = useState('')
  // const [sidebarDataProduct, setSidebarDataProduct] = useState('')
  const [addOns, setAddOns] = useState([])
  const app_id = useSelector((state) => state.user?.app_id)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isSticky, setIsSticky] = useState(false)
  const scrollRef = useRef(null)
  const headerRef = useRef(null)

  const isExisting = (name) => {
    return addOns.some((item) => item.name === name)
  }

  const tabsToRender = [
    { name: English.G222, icon: Images.HomeIco, show: true },
    { name: English.G223, icon: Images.FeedIco, show: true },
    { name: English.G443, icon: Images.VideoIcon1, show: isExisting('Entertainment') },
    { name: English.G439, icon: Images.CommunityIco, show: isExisting('Community') },
    { name: English.G224, icon: Images.StoreIco, show: isExisting('Store') },
    { name: English.G236, icon: Images.LocationIco1, show: isExisting('Local') }
  ]

  useEffect(() => {
    const event = emitter.addListener(
      EventType.sidebarRoute,
      ({ product_id, action }) => {
        // setSidebarDataProduct(product_id)
        setSidebarData(action)
        const activeTabs = tabsToRender.filter((item) => item.show)
        // eslint-disable-next-line max-len
        const isdd = activeTabs.findIndex((item) => item?.name.toLowerCase().includes(action.toLowerCase()))
        setSelectedIndex(isdd)
      }
    )
    return () => event.remove()
  }, [addOns])

  useEffect(() => {
    if (app_id?.add_ons_data) {
      setAddOns(app_id.add_ons_data)
    }
  }, [app_id])

  const StoreComponent = () => (
    <>
      {/* <ProfileHomePage pageType="store" /> */}
      <div>
        <StoreBannerComponent />
      </div>
      <div className="mt-40">
        <AddProduct />
      </div>
      <div className="mt-40">
        <AddProductFilter />
      </div>
      <div className="mt-40">
        <LatestDeal />
      </div>
      <div className="mt-40">
        <LatestReviews />
      </div>
    </>
  )

  const CommunityTabsComponent = () => {
    return (
      <Tabs defaultIndex={state?.defaultIndex ? state?.defaultIndex : 0}>
        <TabList>
          <Tab>
            <img className="component_icon" src={Images.GiveawayIcon} alt="giveawy icon" />
            <span>{English.G230}</span>
          </Tab>
          <Tab>
            <img className="component_icon" src={Images.PollIcon} alt="poll icon" />
            <span>{English.G195}</span>
          </Tab>
          <Tab>
            <img className="component_icon" src={Images.DiscussionIcon} alt="discussions icon" />
            <span>{English.G207}</span>
          </Tab>
          {/* <Tab>
            <img src={AwardsIco} alt="award icon" />
            Awards
          </Tab> */}
        </TabList>

        <TabPanel>
          <div>
            <GivewayComponent />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <PollsComponent />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <DisscussionComponent />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <AwardsComponent />
          </div>
        </TabPanel>
      </Tabs>
    )
  }

  const EntertainmentTabsComponents = () => {
    return (
      <Tabs defaultIndex={state?.defaultIndex ? state?.defaultIndex : 0}>
        <TabList>
          <Tab>
            {/* <img className="component_icon" src={Images.VideoIco} alt="video icon" /> */}
            <span>{English.G225}</span>
          </Tab>
          <Tab>
            {/* <img className="component_icon" src={Images.ShotsIco} alt="shot icon" /> */}
            <span>{English.G164}</span>
          </Tab>
          <Tab>
            {/* <img className="component_icon" src={Images.PodcastIco} alt="podcast icon" /> */}
            <span>{English.G226}</span>
          </Tab>
        </TabList>
        <TabPanel>
          <div>
            <VideoComponent />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <ShotsComponent />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <PodcastComponent />
          </div>
        </TabPanel>
      </Tabs>
    )
  }

  const onSelect = (index) => {
    const activeTabs = tabsToRender.filter((item) => item.show)
    if (index === 0 || index === 1) {
      emitter.emit(EventType.sidebarRoute, {
        action: activeTabs[index].name,
        product_id: 0
      })
    } else {
      emitter.emit(EventType.sidebarRoute, {
        action: activeTabs[index].name,
        product_id: 0
      })
    }
  }

  const getProfileType = () => {
    return (sidebarData === '/community-builder/' || sidebarData === English.G439 || sidebarData === '/profile-builder/' ||
      sidebarData === English.G443 || sidebarData === English.G222 ||
      sidebarData === English.G223)
      ? 'Creator'
      : (sidebarData === '/store-builder/' || sidebarData === English.G224)
        ? 'Store'
        : (sidebarData === '/local-builder/' || sidebarData === English.G236)
          ? 'Local'
          : ''
  }

  const headerProfile = useMemo(() => {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {getProfileType() && <ProfileCommonHead type={sidebarData === English.G223 || sidebarData === English.G222 ? English.G223 : getProfileType()} addOns={sidebarData} />}
      </>
    )
  }, [sidebarData])

  // Dynamically determine which tabs exist (assuming 'isExisting' returns a boolean)
  const addonsTabs = useMemo(() => {
    return (
      <Tabs
        selectedIndex={selectedIndex}
        key={app_id.id}
        onSelect={onSelect}
      >
        <div className="top-0 flex bg-white flex-col z-50 headernamet">
          <div
            ref={headerRef}
            key={activePlatform?.id || 1}
            className="creator-profile-create !pt-5 overflow-hidden"
          >
            {headerProfile}
          </div>
          <TabList className="!mt-5 pl-0 flex flex-row">

            {tabsToRender.map((tab, index) => tab.show && (
              <Tab key={index}>
                <img className="component_icon" src={tab.icon} alt={tab.name} />
                <span>{tab.name}</span>
              </Tab>
            ))}

          </TabList>
        </div>

        <TabPanel>
          <ProfileHomePage />
        </TabPanel>

        <TabPanel>
          <Feeds />
        </TabPanel>

        {isExisting('Entertainment') && (
          <TabPanel>
            <div className="submenu">
              <EntertainmentTabsComponents />
            </div>
          </TabPanel>
        )}

        {isExisting('Community') && (
          <TabPanel>
            <div className="submenu">
              <CommunityTabsComponent />
            </div>
          </TabPanel>
        )}

        {isExisting('Store') && (
          <TabPanel>
            <div>
              <StoreComponent />
            </div>
          </TabPanel>
        )}

        {isExisting('Local') && (
          <TabPanel>
            <div>
              <LocalHomePage />
            </div>
          </TabPanel>
        )}
      </Tabs>
    )
  }, [app_id.id, selectedIndex, state, addOns, headerProfile])

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      if (scrollRef.current) {
        const scrollPosition = scrollRef.current.scrollTop
        console.log('Scroll position:', scrollPosition) // Log the scroll position to see if it's working

        // Optional: You can also implement sticky behavior based on scroll position
        if (scrollPosition > 0) {
          setIsSticky(true) // Add sticky class
        } else {
          setIsSticky(false) // Remove sticky class
        }
      }
    }

    // Attach scroll event listener to the scrollable element
    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', handleScroll)
    }

    // Cleanup function to remove event listener on unmount
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <div className="add-item-model-edit">
      <div className="add-item-content-edit relative">
        {sidebarData === '/install-addons/' ? (<InstallAddons />) : (
          <>
            <div className="fixed z-10 !w-[220px] h-[95%]">
              <SidebarNew onPlatformChange={setActivePlatform} />
            </div>
            <div className="ml-[220px] pb-0 sticky top-0 z-10">
              <ProfileHeader onClick={closeModal} />
            </div>
            <div className="tabs p-10 ml-[220px]">
              <div ref={scrollRef} className="h-[calc(100vh-200px)] overflow-auto !px-5 !pb-5 no-scrollbar bg-white rounded-[15px]">
                <div className={`details-tab-view profile_builder ${isSticky && 'headername'} pb-[195px]`}>
                  {addonsTabs}
                </div>
              </div>
            </div>
          </>
      ) }
      </div>
    </div>
  )
}
export default MainEditModal

/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */

import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import CommonInput from '../../InputComponet/CommonInput'
import { English } from '../../../helpers'
import CommonCMModal from './CommonCMModal'
import Dropdown from '../../Dropdown/Dropdown'
import { capitalizeFirstLetter } from '../../../helpers/Utility'
import { APICall, EndPoints } from '../../../services'
import PlatformSelect from './PlatformSelect'

const DiscussionAddModal = ({ handleCancel, editData, onSubmit }) => {
  const [appData, setAppData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [inputFields, setInputFields] = useState({
    app_id: [],
    topic: '',
    description: '',
    category_id: ''
  })

  const [category, setCategory] = useState([])

  useEffect(() => {
    if (editData) {
      let app_id = []
      if (editData?.app_data) {
        app_id = editData?.app_data.map((d) => d.id)
      } else if (editData?.app_id) {
        try {
          app_id = JSON.parse(editData?.app_id)
        } catch (e) {
          console.log(e)
        }
      }
      setInputFields({
        topic: editData.topic || '',
        description: editData.description || '',
        app_id,
        category_id: editData.category_id,
        discussion_id: editData.discussion_id
      })
    }
  }, [editData])

  const clearValue = () => {
    setInputFields({
      ...inputFields,
      topic: '',
      description: '',
      app_id: [],
      category_id: '',
      discussion_id: ''
    })
    setStep(1)
  }

  const HandlePublish = () => {
    if (editData) {
      editDiscussionDataApi()
    } else {
      addDiscussionDataSubmit()
    }
  }

  const addDiscussionDataSubmit = () => {
    setLoading(true)
    const data = {
      topic: inputFields.topic,
      description: inputFields.description,
      app_id: inputFields?.app_data.map((d) => d.id),
      discussion_id: inputFields.discussion_id,
      category: inputFields.category_id
    }

    APICall('post', data, `${EndPoints.addDiscussion}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          // setShowPreviewDiscussionModel(false)
          setTimeout(() => {
            //   getDiscussionLibraryData()
            onSubmit(inputFields.discussion_id, inputFields?.app_data)
          }, 3000)
          // setIsEditing(false)
          setLoading(false)
          clearValue()
          toast.success(res?.data?.message)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const editDiscussionDataApi = () => {
    const data = {
      topic: inputFields.topic,
      description: inputFields.description,
      app_id: inputFields?.app_data.map((d) => d.id),
      discussion_id: inputFields.discussion_id,
      category: inputFields.category_id
    }
    APICall('post', data, `${EndPoints.editDiscussion}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          //   setIsEditing(false)
          clearValue()
          setLoading(false)
          toast.success(res?.data?.message)
          setTimeout(() => {
            // getDiscussionLibraryData()
            onSubmit(inputFields.discussion_id, inputFields?.app_data)
          }, 3000)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const [step, setStep] = useState(1)

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    MainCategory()
  }, [])

  const MainCategory = () => {
    APICall('post', {}, `${EndPoints.getDiscussionCategory}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setCategory(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }
  const getCategoryName = (categoryId) => {
    const categoryObj = category.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.category_name : English.G134
  }

  const podcastContent = useMemo(() => {
    return (
      <div className="item-details-input pt-0">
        <div>
          <label>{English.A234}</label>
          <CommonInput
            placeholder={English.A258}
            name="topic"
            onChange={handleChange}
            value={capitalizeFirstLetter(inputFields?.topic)}
            disabled={editData}
          />
        </div>
        <div className="poll-dropdown">
          <label>{English.G124}</label>
          <Dropdown
            title={getCategoryName(inputFields.category_id)}
            onChanged={(d) => {
              setInputFields({
                ...inputFields,
                category_id: d.id
              })
            }}
            Data={category.map((d) => ({
              title: d.category_name,
              id: d.id
            }))}
            name="category_id"
            customClass={
              inputFields.category_id
                ? 'selected-class !mb-[16px] !mt-0'
                : 'giveaway-dropdown !mb-[16px] !mt-0'
            }
          />
        </div>
        <div>
          <label>{English.G112}</label>
          <CommonInput
            placeholder={English.A259}
            name="description"
            onChange={handleChange}
            value={capitalizeFirstLetter(inputFields.description)}
            className="disscussion_description pt-3"
            isTextArea
          />
        </div>
      </div>
    )
  }, [inputFields, handleChange, category])

  const videoUploadStep = useMemo(() => {
    return (
      <div className="!mt-5 rounded-[15px] local_contact_scroll_bars !max-h-max">
        {step === 1 && podcastContent}
      </div>
    )
  }, [step, podcastContent])

  const contentEnaled = () => {
    return !(
      inputFields.topic &&
      inputFields.category_id &&
      inputFields.description
    )
  }

  const appDataSelect = useMemo(() => {
    return (
      <PlatformSelect
        key={step}
        defaultIds={editData?.app_data || inputFields?.app_id?.map((item) => { return { id: item } })}
        step={step}
        onSelectedIds={(data) => {
          setAppData(data)
        }}
      />
    )
  }, [inputFields])

  useEffect(() => {
    if (appData) {
      setInputFields({
        ...inputFields,
        app_data: appData
      })
    }
  }, [appData])

  return (
    <CommonCMModal
      setStep={setStep}
      totalStep={2}
      step={step}
      handleCancel={handleCancel}
      loading={loading}
      nextBtnLable={step === 2 ? English.G128 : English.G69}
      nextBtnStatus={
        step === 1
          ? contentEnaled()
          : step === 2
            ? inputFields?.app_data?.length === 0
            : false || loading
      }
      cancelBtnStatus={loading}
      closeBtnStatus={loading}
      title="Start a topic"
      onClickNext={() => {
        if (step === 2) {
          HandlePublish()
        } else {
          setStep(step + 1)
        }
      }}
    >
      {videoUploadStep}
      {step === 2 && appDataSelect}
    </CommonCMModal>
  )
}

export default DiscussionAddModal

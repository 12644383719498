/* eslint-disable no-return-await */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { TimePicker } from 'antd'
import dayjs from 'dayjs'
import CommonInput from '../../InputComponet/CommonInput'
import { English, Images } from '../../../helpers'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import {
  capitalizeFirstLetter
} from '../../../helpers/Utility'
import Dropdown from '../../Dropdown/Dropdown'
import { APICall, EndPoints } from '../../../services'
import {
  s3ImageGiveaway,
  urlSystemIcon
} from '../../../services/Service'
import {
  compressImage,
  dataDecrypt
} from '../../../services/http-services'
import Loader from '../../Loader/Loader'
import CommonCMModal from './CommonCMModal'
import GiveawayImage from '../GiveawayImage'
import CommonDateTimePicker from '../../CommonDateTimePicker/CommonDateTimePicker'
import PlatformSelect from './PlatformSelect'

const GiveawayAddModal = ({ handleCancel, editData, onSubmit }) => {
  const [loading, setLoading] = useState(false)
  const [appData, setAppData] = useState(null)
  const [inputFields, setInputFields] = useState({
    title: '',
    description: '',
    expire_date: '',
    brand_id: '',
    type_id: '',
    app_id: [],
    file: []
  })

  const [brandData, setBrandData] = useState([])
  const [typeData, setTypeData] = useState([])
  const [selectedImages, setSelectedImages] = useState([])
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })

  useEffect(() => {
    if (editData) {
      setInputFields({
        title: editData.title || '',
        description: editData.description || '',
        expire_date: editData.expire_date || '',
        brand_id: editData.brand_id || '',
        type_id: editData.type_id || '',
        app_id: editData?.app_data.map((d) => d.id) || [],
        file: editData.images || [],
        giveaway_id: editData.giveaway_id
      })
    }
  }, [editData])

  const [step, setStep] = useState(1)

  const getBrandListApiCall = () => {
    APICall('post', {}, `${EndPoints.getBrand}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setBrandData(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const getTypeListApiCall = () => {
    APICall('post', {}, `${EndPoints.getType}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTypeData(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const clearValue = () => {
    setInputFields({
      ...inputFields,
      title: '',
      description: '',
      duration: '',
      attachment: '',
      cover_attachment: '',
      app_id: '',
      video_id: ''
    })
    setStep(1)
  }

  useEffect(() => {
    getBrandListApiCall()
    getTypeListApiCall()
  }, [])

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const getBrandName = (categoryId) => {
    const categoryObj = brandData.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.brand : English.G181
  }
  const getTypeName = (categoryId) => {
    const categoryObj = typeData.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.type : English.G181
  }

  const onUpload = async (file, fieldName, index) => {
    try {
      const coverFormData = new FormData()
      if (fieldName === 'file') {
        const compressedImages = await Promise.all(
          Array.from(file)
            .filter((file) => file instanceof File)
            .map(async (file) => await compressImage(file))
        )
        compressedImages.forEach((imageFile) => {
          coverFormData.append('files[]', imageFile)
        })
      }

      coverFormData.append('type', 'giveaway')

      try {
        const res = await APICall(
          'post',
          coverFormData,
          EndPoints.uploadFileMultiple,
          'creator',
          true
        )
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedImageData = dataDecrypt(res.data.data)
          if (fieldName === 'file') {
            return [
              ...decryptedImageData,
              ...file.filter((item) => !(item instanceof File))
            ]
          }
        } else {
          toast.error(res?.data?.message || 'Error uploading image')
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || 'Upload failed')
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const handleImageChange = async (files, fieldName, index) => {
    if (!files) return

    const filesArray = Array.isArray(files) ? files : [files]

    setInputFields((prev) => {
      const prevData = prev[fieldName] || []
      const updatedData = [...prevData]

      filesArray.forEach((file, idx) => {
        if (updatedData.length >= 6) return
        if (index !== undefined && index !== null && Array.isArray(prevData)) {
          updatedData[index + idx] = file
        } else {
          updatedData.push(file)
        }
      })

      return {
        ...prev,
        [fieldName]: updatedData
      }
    })
  }

  const removeImage = (index) => {
    const updatedImages = [...selectedImages]
    updatedImages.splice(index, 1)
    setSelectedImages(updatedImages)

    const updatedFiles = [...inputFields.file]
    updatedFiles.splice(index, 1)
    setInputFields({ ...inputFields, file: updatedFiles })
  }
  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }
  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setSelectedImages((state) => {
        const copy = [...state];
        [copy[draggedIndex], copy[index]] = [copy[index], copy[draggedIndex]]
        return copy
      })

      setInputFields((prev) => ({
        ...prev,
        file: [...selectedImages]
      }))

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }
  const renderImg = useMemo(() => {
    return (
      <>
        {inputFields.file.map((image, index) => {
          return (
            <div
              className={`image position-relative !h-[150px] !w-[150px] ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative !h-[150px] !w-[150px] ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImageGiveaway + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button type="button" onClick={() => removeImage(index)}>
                <img src={Images.Remove} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    handleImageChange(file, 'file', index)
                  }}
                  className="plsimg"
                  selectedImg=""
                  defaultImg={Images.plusimg}
                  aspect={1 / 1}
                  multiple
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [inputFields?.file, draggedIndex])

  const videoText = useMemo(() => {
    return (
      <div className="item-details-input giveaway-inputfield pt-0 w-full flex-1">
        <div className="">
          <div>
            <label>{English.A257}</label>
            <CommonInput
              name="title"
              placeholder={English.G162}
              onChange={handleChange}
              value={capitalizeFirstLetter(inputFields.title)}
            />
          </div>
          <div className="time_picker">
            <label>{English.A233}</label>
            <div className="cooking-time-filed position-relative">
              <CommonDateTimePicker
                inputFields={inputFields}
                setInputFields={setInputFields}
                fieldName="expire_date"
                placeholder={English.A237}
                minDate={new Date()}
                maxDate={new Date(new Date().getFullYear() + 10, 11, 31)}
                popperPlacement="bottom"
              />
            </div>
          </div>
        </div>
        <div className="input-grid my-3">
          <div>
            <label>{English.G182}</label>
            <Dropdown
              title={getBrandName(inputFields.brand_id)}
              onChanged={(d) => {
                getTypeListApiCall()
                setInputFields({
                  ...inputFields,
                  brand_id: d.id,
                  type_id: null
                })
              }}
              Data={brandData.map((d) => ({
                id: d.id,
                title: d.brand
              }))}
              name="brand_id"
              customClass={
                inputFields.brand_id ? 'selected-class' : 'giveaway-dropdown'
              }
            />
          </div>
          <div>
            <label>{English.G183}</label>
            <Dropdown
              title={getTypeName(inputFields.type_id)}
              onChanged={(d) => {
                setInputFields({
                  ...inputFields,
                  type_id: d.id
                })
              }}
              Data={typeData.map((d) => ({
                id: d.id,
                title: d.type
              }))}
              name="type_id"
              customClass={
                inputFields.type_id ? 'selected-class' : 'giveaway-dropdown'
              }
            />
          </div>
        </div>
        <div>
          <label>{English.G112}</label>
          <CommonInput
            placeholder={English.G83}
            name="description"
            onChange={handleChange}
            value={capitalizeFirstLetter(inputFields.description)}
            isTextArea
            className="!pt-3"
          />
        </div>
      </div>
    )
  }, [inputFields, handleChange, brandData, typeData])

  const videoUploadStep = useMemo(() => {
    if (step === 4) {
      return null
    }

    return (
      <div className="!mt-5 rounded-[15px] local_contact_scroll_bars !max-h-max duration-100 !pl-0">
        {/* <div className="upload-input">
          <CommonInput
            type="file"
            accept="video/*"
            id="upload-video"
            onChange={handleVideoChange}
            className="d-none"
          />
          {!selectedVideo && (
            <CommonEmptyData
              image={Images.FileUpload}
              mainTitle={English.G153}
              descriptionText={English.B124}
              onClick={() => document.getElementById('upload-video').click()}
              addIcon={Images.FileUploadWhite}
              btnText={English.A210}
              classNameDescription="max-w-[350px] !text-[14px] !font-normal"
            />
          )}
        </div> */}
        {step === 1 && (
          <div className="text-center !p-[14px] flex-1 !bg-[#E4E4E480] upload-input-with-border rounded-[15px] !mb-5 h-[350px] !content-center">
            <div className="text-center">
              {inputFields?.file?.length < 1 ? (
                <CommonImageUpload
                  handleImageChange={(file) => handleImageChange(file, 'file', inputFields?.file?.length)}
                  buttonText="Add Image"
                  selectedImg=""
                  defaultImg={Images.plusGiveaway}
                  className="plsimg"
                  forImage="giveaways"
                  multiple
                />
              ) : (
                <div className="input-image-show !mt-0 justify-center h-[320px]">
                  {renderImg}
                  {inputFields?.file?.length < 6 && (
                    <div className="input-img-label">
                      <CommonImageUpload
                        handleImageChange={(file) => handleImageChange(
                            file,
                            'file',
                            inputFields?.file?.length
                          )}
                        buttonText="Add Image"
                        selectedImg=""
                        defaultImg={Images.plusGiveaway}
                        className="plsimg mb-0"
                        forImage={
                          inputFields?.file?.length < 0 ? 'giveaways' : null
                        }
                        multiple
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        {inputFields?.file?.length > 3 && (
          <div className="video-wrapper modal-video flex flex-row justify-between relative gap-3">
            {step === 2 && videoText}
            {step === 2 && (
              <div>
                <GiveawayImage inputField={inputFields?.file} />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }, [inputFields?.file, step, videoText])

  const addGiveawayDataSubmit = (images) => {
    const data = {
      title: inputFields.title,
      description: inputFields.description,
      expire_date: inputFields.expire_date,
      brand_id: inputFields.brand_id,
      type_id: inputFields.type_id,
      app_id: inputFields?.app_data.map((d) => d.id),
      file: images || inputFields.file
    }
    APICall('post', data, `${EndPoints.addGiveaway}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          // setShowGiveawayProduct(false)
          // setTimeout(() => {
          //   getGiveaways(null, currentPage)
          // }, 3000)
          // setIsEditing(false)
          setTimeout(() => {
            handleCancel()
            onSubmit(inputFields.giveaway_id)
            setLoading(false)
            clearValue()
            toast.success(res?.data?.message)
          }, 3000)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const editGiveawayDataApi = (file) => {
    const data = {
      title: inputFields.title,
      expire_date: inputFields.expire_date,
      brand_id: inputFields.brand_id,
      type_id: inputFields.type_id,
      description: inputFields.description,
      file: file || inputFields.file,
      app_id: inputFields?.app_id,
      giveaway_id: inputFields.giveaway_id
    }
    APICall('post', data, `${EndPoints.editGiveaway}`, 'contentManager')
    .then((res) => {
      if (res.status === 200 && res.data?.code === 200) {
          // setIsEditing(false)
          // setTimeout(() => {
            //   getGiveaways(null, currentPage)
            // }, 3000)
            setTimeout(() => {
            handleCancel()
            onSubmit(inputFields.giveaway_id)
            setLoading(false)
            clearValue()
            toast.success(res?.data?.message)
          }, 3000)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const HandleDiscussion = async () => {
    setLoading(true)
    const newFile = inputFields.file
      .map((item) => (typeof item !== 'string' ? item : null))
      .filter((item) => item !== null)

    const uploadedImages =
      newFile.length !== 0 ? await onUpload(newFile, 'file') : []

    const images = inputFields.file.map((item) => (typeof item === 'string' ? item : uploadedImages?.shift()))
    if (inputFields?.file?.length < 4) {
      toast.error('please select at least 4 images')
    } else if (editData) {
      editGiveawayDataApi(images)
      // setShowCommunityModel(false)
      // setShowCommunityGiveawayCover(false)
      // setShowGiveawayProduct(false)
      // setIsEditing(false)
    } else {
      addGiveawayDataSubmit(images)
      // setShowCommunityModel(false)
      // setShowCommunityGiveawayCover(false)
      // setShowGiveawayProduct(false)
      // setIsEditing(false)
    }
  }

  useEffect(() => {
    if (appData) {
      setInputFields({
        ...inputFields,
        app_data: appData
      })
    }
  }, [appData])

  const publishEnaled = () => {
    return !(
      inputFields.title &&
      inputFields.brand_id &&
      inputFields.type_id &&
      inputFields.expire_date &&
      inputFields.description
    )
  }

  const appDataSelect = useMemo(() => {
    return (
      <PlatformSelect
        key={step}
        step={step}
        defaultIds={editData?.app_data}
        onSelectedIds={(data) => {
          setAppData(data)
        }}
      />
    )
  }, [])

  return (
    <CommonCMModal
      setStep={setStep}
      totalStep={3}
      step={step}
      handleCancel={handleCancel}
      loading={loading}
      nextBtnLable={step === 3 ? English.G128 : English.G69}
      nextBtnStatus={
        step === 1
          ? inputFields?.file?.length <= 3
          : step === 2
            ? publishEnaled()
            : step === 3
              ? inputFields?.app_data?.length === 0
              : false || loading
      }
      cancelBtnStatus={loading}
      closeBtnStatus={loading}
      title="CREATE A GIVEAWAY"
      onClickNext={() => {
        if (step === 3) {
          HandleDiscussion()
        } else {
          setStep(step + 1)
        }
      }}
    >
      {videoUploadStep}
      {step === 3 && appDataSelect}
    </CommonCMModal>
  )
}

export default GiveawayAddModal

/* eslint-disable import/no-duplicates */
import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const Categories = () => {
  return (
    <div className="categories-admin">
      <div className="common-card-body">
        <h3>Platform Logo</h3>
        <div className="add-delete-logo">
          <div className="logo-image">
            <img alt="" src={Images.LogoAtoZ} />
          </div>
          <div className="logo-button">
            <button type="button" className="primary-btn">
              <span>Change Logo</span>
            </button>
            <button type="button" className="light-button">
              <span>Delete Logo</span>
            </button>
          </div>
        </div>
      </div>
      <div className="feed-container mt-30 d-block">
        <div className="details-tab-view common-card-body">
          <div className="tabs-for-details-view">
            <Tabs>
              <TabList>
                <Tab>
                  <img alt="" src={Images.AddIcoAdmin} />
                  <span>{English.G225}</span>
                </Tab>
                <Tab>
                  <img alt="" src={Images.AddIcoAdmin} />
                  <span>{English.G226}</span>
                </Tab>
                <Tab>
                  <img alt="" src={Images.AddIcoAdmin} />
                  <span>{English.G440}</span>
                </Tab>
                <Tab>
                  <img alt="" src={Images.AddIcoAdmin} />
                  <span>{English.G441}</span>
                </Tab>
                <Tab>
                  <img alt="" src={Images.AddIcoAdmin} />
                  <span>{English.G442}</span>
                </Tab>
                <Tab>
                  <img alt="" src={Images.AddIcoAdmin} />
                  <span>{English.G164}</span>
                </Tab>
              </TabList>

              <TabPanel>
                <div>
                  <div className="category-top">
                    <h3>Categories</h3>
                    <div>
                      <button type="button" className="light-button">
                        <span>
                          <img alt="" src={Images.Delete} />
                          {' '}
                          {English.G430}
                        </span>
                      </button>
                      <button type="button" className="primary-btn">
                        <span>
                          <img alt="" src={Images.AddIcoAdmin} />
                          {' '}
                          Add New Category
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="category-accordion">
                    <Accordion>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>Health</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <ul className="sub-cetegory">
                            <li>
                              <p>Sub category 1</p>
                              <button type="button">
                                <img alt="" src={Images.Delete} />
                              </button>
                            </li>
                            <li>
                              <p>Sub category 2</p>
                              <button type="button">
                                <img alt="" src={Images.Delete} />
                              </button>
                            </li>
                            <li>
                              <p>Sub category 3</p>
                              <button type="button">
                                <img alt="" src={Images.Delete} />
                              </button>
                            </li>
                          </ul>
                          <button type="button">
                            <span>Add Another</span>
                          </button>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>{English.G446}</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <ul className="sub-cetegory">
                            <li>
                              <p>Sub category 1</p>
                              <button type="button">
                                <img alt="" src={Images.Delete} />
                              </button>
                            </li>
                            <li>
                              <p>Sub category 2</p>
                              <button type="button">
                                <img alt="" src={Images.Delete} />
                              </button>
                            </li>
                            <li>
                              <p>Sub category 3</p>
                              <button type="button">
                                <img alt="" src={Images.Delete} />
                              </button>
                            </li>
                          </ul>
                          <button type="button">
                            <span>Add Another</span>
                          </button>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>Running</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <ul className="sub-cetegory">
                            <li>
                              <p>Sub category 1</p>
                              <button type="button">
                                <img alt="" src={Images.Delete} />
                              </button>
                            </li>
                            <li>
                              <p>Sub category 2</p>
                              <button type="button">
                                <img alt="" src={Images.Delete} />
                              </button>
                            </li>
                            <li>
                              <p>Sub category 3</p>
                              <button type="button">
                                <img alt="" src={Images.Delete} />
                              </button>
                            </li>
                          </ul>
                          <button type="button">
                            <span>Add Another</span>
                          </button>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>Organic</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <ul className="sub-cetegory">
                            <li>
                              <p>Sub category 1</p>
                              <button type="button">
                                <img alt="" src={Images.Delete} />
                              </button>
                            </li>
                            <li>
                              <p>Sub category 2</p>
                              <button type="button">
                                <img alt="" src={Images.Delete} />
                              </button>
                            </li>
                            <li>
                              <p>Sub category 3</p>
                              <button type="button">
                                <img alt="" src={Images.Delete} />
                              </button>
                            </li>
                          </ul>
                          <button type="button">
                            <span>Add Another</span>
                          </button>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </TabPanel>
              <TabPanel />
              <TabPanel />
              <TabPanel />
              <TabPanel />
              <TabPanel />
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Categories

import React from 'react'
import './Admin.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import Categories from './Categories'
import Cities from './Cities'
import PersonalityTest from './PersonalityTest'
import Clients from './Clients'
import English from '../../helpers/English'

const Admin = () => {
  return (
    <div className="creator-profile-main">
      <div className="feed-container mt-30 d-block">
        <div className="details-tab-view common-card-body">
          <div className="tabs-for-details-view">
            <Tabs>
              <TabList>
                <Tab><span>Categories</span></Tab>
                <Tab><span>{English.G432}</span></Tab>
                <Tab><span>Personality test</span></Tab>
                <Tab><span>{English.G429}</span></Tab>
                <Tab><span>Ingredients</span></Tab>
                <Tab><span>Subscription Model</span></Tab>
                <Tab><span>Notifications</span></Tab>
                <Tab><span>Games</span></Tab>
                <Tab><span>Offers</span></Tab>
                <Tab><span>Security</span></Tab>
                <Tab><span>{English.G240}</span></Tab>
                <Tab><span>Chat</span></Tab>
              </TabList>

              <TabPanel>
                <div>
                  <Categories />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <Cities />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <PersonalityTest />
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <Clients />
                </div>
              </TabPanel>
              <TabPanel />
              <TabPanel />
              <TabPanel />
              <TabPanel />
              <TabPanel />
              <TabPanel />
              <TabPanel />
              <TabPanel />
              <TabPanel />
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Admin

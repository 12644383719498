import React from 'react'
import CommonButton from '../../../ButtonComponent/ButtonCommon'
import { English } from '../../../../helpers'

const ContentMangerCommonTitle = ({ title, buttonName, onButtonClick, isPublish, className }) => {
return (
  <div className={`${className} discussions-wrapper-header common-contnt-wrapper flex-col mb-2.5 !justify-normal gap-2.5 w-full`}>
    <div className="flex items-center justify-between w-full">
      <h2 className="italic text-Main font-black text-lg uppercase mb-0">
        {title}
      </h2>
      <div className="flex !gap-5 items-center">
        {isPublish &&
          <div className="profile-tag tag-local position-relative">
            <span>{English.G274}</span>
          </div>}
        {onButtonClick &&
          <CommonButton
            label={buttonName}
            onClick={onButtonClick}
            isPrimary
            className="!text-base !italic !font-black add_new_content !rounded-[100px] !px-5 !py-2"
          />}
      </div>
    </div>
  </div>
)
}

export default ContentMangerCommonTitle

/* eslint-disable max-len */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable padded-blocks */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-tabs */
import React from 'react'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import Loader from '../Loader/Loader'
import { s3ImageProfile } from '../../services/Service'
import { English, Images } from '../../helpers'
import { timeDifference } from '../../helpers/Utility'

const DisscussionItem = ({ item, index, expandedDiscussion, expandedIndex, onClickComment, setUpdatingIndex, setExpandedIndex, onClickEditDiscussion, ShowConfirmModel, ShowConfirm, loading, updatingIndex, deleteDiscussion, handleCancels }) => {

	if (expandedDiscussion !== null && expandedDiscussion !== index) {
		return null
	}
	const visiblePerformers = item?.reply_by_image?.slice(0, 3)
	const hiddenPerformersCount = item?.reply_by_image?.length - visiblePerformers?.length
	return (
		<div
			className={`${expandedIndex === index ? 'expanded' : ''}`}
			key={item.Title}
		>
			<div
				className={`position-relative p-[15px] rounded-[14px] ${
					expandedDiscussion === index
						? 'border border-[#E1E2E5] shadow-none'
						: 'border border-[#E1E2E5] rounded-[14px]'
				}`}
			>
				<div className="image-buttons z-1">
					<label>
						<button
							type="button"
							onClick={() => onClickEditDiscussion(item)}
							className="d-none"
						/>
					</label>
					<button
						type="button"
						className="p-0"
						onClick={() => {
							setUpdatingIndex(index)
							ShowConfirm(item.discussion_id)
						}}
					/>
				</div>
				<ConfirmationModal
					isVisible={ShowConfirmModel && updatingIndex === index}
					onConfirm={deleteDiscussion}
					onCancel={handleCancels}
					isLoading={loading}
				/>
				{loading && updatingIndex === index && <Loader />}
				<div className="w-full">
					<div className="discussion-tag mb-2">
						<span>{item.category_name}</span>
					</div>
					<div className="discussion-profile-profile mb-7">
						<div
							className={`discussion-detail ${
								expandedDiscussion === index ? 'flex gap-2' : ''
							}`}
						>
							{expandedDiscussion === index && (
								<div>
									<img
										className="!w-[50px] !h-[50px] rounded-[50%]"
										src={s3ImageProfile + item.profile_icon}
										alt=""
									/>
								</div>
							)}
							<div className="flex-1">
								<h5 className="text-Main font-black text-lg mb-3.5 italic discussion_topic">{item.topic}</h5>
								<p className="mb-0 text-main text-base">
									{expandedIndex === index
										? item?.description
										: `${item?.description?.slice(0, 400)}`}
									{item?.description?.length > 400 && (
										<span
											className="see-more"
											onClick={(e) => {
												e.stopPropagation()
												setExpandedIndex(
													expandedIndex === index ? null : index
												)
											}}
										>
											...
											{' '}
											{expandedIndex === index
												? English.G145
												: English.G146}
										</span>
									)}
								</p>
							</div>
						</div>
					</div>
					<div className="flex justify-between">
						<span className="text-[#5A5A5A] text-xs font-normal mt-[15px]">
							{English.A212}
							{' '}
							{timeDifference(item.created_at)}
						</span>
						<div className="flex gap-3">
							<div
								className="flex gap-2 items-center cursor-pointer"
								onClick={() => onClickComment && onClickComment(item.discussion_id, index)}
							>
								<img src={Images.comments_icon} alt="" />
								<span className="text-[#7A7474] font-normal text-sm">
									{item.total_replies}
								</span>
							</div>
							<div className="follower-shows">
								<div className="discussion-img-group d-flex">
									{visiblePerformers?.map((performer, index) => (
										<img
											key={performer?.performer_id}
											alt={`speaker ${index + 1}`}
											src={s3ImageProfile + performer}
											className="rounded-full border-2 border-white shadow-sm"
										/>
									))}
									{hiddenPerformersCount > 0 && (
										<div className="w-[40px] h-[40px] rounded-full flex items-center ml-[-6px] justify-center bg-[#01104D] from-gray-800 to-blue-900 text-white text-sm font-semibold">
											<img
												src={Images.discussion_more_icon}
												alt=""
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)

}

export default DisscussionItem

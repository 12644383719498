/* eslint-disable max-len */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { English, Images } from '../../../helpers'
import CommonButton from '../../ButtonComponent/ButtonCommon'

const Campaigns = () => {
    const navigate = useNavigate()

  const initialApps = [
    {
      id: 1,
      name: 'campaign 1',
      number1: '5.204',
      number2: '2.453'
    },
    {
      id: 2,
      name: 'campaign 2',
      number1: '5.204',
      number2: '2.453'
    },
    {
      id: 3,
      name: 'campaign 3',
      number1: '5.204',
      number2: '2.453'
    },
    {
      id: 4,
      name: 'campaign 4',
      number1: '5.204',
      number2: '2.453'
    }
  ]

  const handleButtonClick = () => {
    navigate('/dashboard/campaigns')
  }

  return (
    <div className="p-0 bg-gray-50 ">
      <div className="flex justify-between items-center mb-3">
        <div className="text-gradient font-black pr-1 text-2xl italic uppercase">
          {English.G250}
        </div>
        <CommonButton
          label={English.A141}
          type="button"
          className="primary-btns w-auto"
          context="primary-btn"
          onClick={handleButtonClick}
          isPrimary
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
        {initialApps.map((i) => (
          <div
            key={i.id}
            className={`w-[100%] h-auto p-[12px] rounded-2xl bg-white ${
              i.isSelected ? 'selected' : 'selectable'
            }`}
          >
            <div className="flex flex-col gap-3">
              <div>
                <div className="flex items-center justify-between w-100">
                  <div className="flex gap-10 items-center w-100">
                    <div className="flex-row items-center gap-2 w-100">
                      <div className="flex gap-1 items-center justify-between">
                        <h3 className="text-base font-semibold mb-0 text-[#303030]">
                          {i.name}
                        </h3>
                        <div className="flex items-center gap-2">
                          <img src={Images.timer_icon} alt="" />
                          <p className="text-[#303030] text-xs font-semibold mb-0">Oct 20 - Nov 20</p>
                        </div>
                      </div>
                      <div className="flex gap-3 items-center">
                        <p className="text-[44px] font-semibold text-[#303030] mb-[12px]">
                          0%
                        </p>
                        <p className="italic text-[13px] font-normal bg-[#FFEFB8] text-[#ECB131] mb-[12px] w-[100px] text-center justify-center p-1 rounded-full">
                          In Progress
                        </p>
                      </div>
                      <p className="mb-2 text-[#9E9E9E] font-medium text-xs">Make a presentation about the marketing strategy for Q4.  </p>
                      <div className="flex items-center gap-3">
                        <CommonButton
                          label="Schedule"
                          type="button"
                          className="primary-btns !w-[100px] !p-2"
                          context="primary-btn"
                          isPrimary
                          spanClassName="!text-[12px] !text-[#FFFFFF] !font-medium"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Campaigns

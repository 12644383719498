import React from 'react'
import { English } from '../../../../helpers'

const ContentMangerCommonSeeAll = ({ onClick }) => {
return (
  <div className="flex items-center justify-center w-full pt-5">
    <h2 onClick={onClick} className="text-transparent bg-clip-text clip bg-primaryGradient font-bold text-base cursor-pointer">
      {English.G144}
    </h2>
  </div>
)
}

export default ContentMangerCommonSeeAll

/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-tabs */
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import VideoPlayer from '../CommonVideoPlayer'
import { s3ImageVideo } from '../../../services/Service'
import { APICall, EndPoints } from '../../../services'
import { Images } from '../../../helpers'
import { lockScroll, unlockScroll } from '../../../helpers/Utility'

const PreviewVideoModal = ({ handleCancel, videoData }) => {
	const videoRef = useRef(null)
	const [hasFinished, setHasFinished] = useState(false)
	const [hasPlayed, setHasPlayed] = useState(false)
	const handleVideoEnd = () => {
		setHasFinished(true)
	}
	const handleVideoPlay = () => {
		if (!hasPlayed || hasFinished) {
			viewCount(videoData.video_id)
			setHasPlayed(true)
			setHasFinished(false)
		}
	}
	const viewCount = (videoId) => {
    const data = {
      video_id: videoId
    }
    APICall('post', data, `${EndPoints.videoViewCount}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          //
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

	useEffect(() => {
		lockScroll()
		return () => {
			unlockScroll()
		}
	}, [])

	return (
		<div className="add-item-model">
			<div className="add-item-content local_type_modal !h-auto !max-w-[1200px] !m-5 !max-h-[95vh]">
				<div className="flex items-center justify-between local_sticky_header !mb-3">
					<div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
						Preview
					</div>
					<button
						className="cancel_icon border-0 p-0"
						type="button"
						onClick={handleCancel}
					>
						<img src={Images.RemoveLocal} alt="" />
					</button>
				</div>
				<div className="video-wrapper !aspect-video overflow-hidden">
					<VideoPlayer
						ref={videoRef}
						videoSrc={s3ImageVideo + videoData.attachment}
						posterSrc={s3ImageVideo + videoData.cover_attachment}
						onVideoEnd={handleVideoEnd}
						onPlay={() => handleVideoPlay()}
						className="custom-video-player !aspect-video rounded-2xl bg-PrimaryBorder"
						videoClassName="w-full h-full object-contain"
					/>
				</div>
			</div>
		</div>
	)
}

export default PreviewVideoModal

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import ContentMangerCommonTitle from './SubComponent/ContentMangerCommon.js/ContentMangerCommonTitle'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import ShotItem from './ShotComponent/ShotItem'
import ShotsAddModal from './Modal/ShotAddModal'
import ContentMangerCommonSeeAll from './SubComponent/ContentMangerCommon.js/ContentMangerCommonSeeAll'
import { emitter, EventType } from '../../helpers/Emitter'

const Shots = () => {
  // const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [shotData, setShotData] = useState([])
  const [playingIndex, setPlayingIndex] = useState(-1)
  const [delShotId, setDelShotId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [delLoading, setDelLoading] = useState(false)

  useEffect(() => {
    const event = emitter.addListener(EventType.editShot, ({ item }) => {
      onClickEditPodcast(item)
    })
    return () => event.remove()
  }, [])

  const ShowConfirm = (videoId) => {
    setDelShotId(videoId)
    setShowConfirmModel(true)
  }

  // const viewAll = () => {
  //   navigate('/add-component', { state: { defaultIndex: 3 } })
  // }

  const getShotLibraryData = () => {
    setIsLoading(true)
    APICall('post', {}, `${EndPoints.getShotLibrary}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShotData(res?.decryptedData.data)
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getShotLibraryData()
  }, [])

  const deleteShot = () => {
    const data = {
      shot_id: delShotId
    }
    setDelLoading(true)
    APICall('post', data, `${EndPoints.deleteShot}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          getShotLibraryData()
          setDelLoading(false)
          toast.success(res?.data?.message)
        } else {
          setDelLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setDelLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const handleCancel = () => {
    clearValue()
    setShowConfirmModel(false)
  }

  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [editData, setEditData] = useState(null)
  const [ShowShotsModel, setShowShotsModel] = useState(false)
  const [inputFields, setInputFields] = useState({
    app_id: [],
    attachment: '',
    cover_attachment: '',
    title: '',
    tags: [],
    search: ''
  })
  const clearValue = () => {
    setEditData(null)
    setInputFields({
      ...inputFields,
      title: '',
      attachment: '',
      cover_attachment: '',
      app_id: []
    })
  }

  const onClickEditPodcast = (videoData) => {
    setEditData(videoData)
    setShowShotsModel(true)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      shot_id: videoData.shot_id
    })
  }

  const HandleClose = () => {
    clearValue()
    setShowShotsModel(false)
  }

  return (
    <div className="content-manager-shots-wrapper">
      <ContentMangerCommonTitle
        title={English.G164}
        buttonName={shotData?.length === 0 ? null : English.A209}
        onButtonClick={
          shotData?.length === 0 ? null : () => setShowShotsModel(true)
        }
      />
      {shotData?.length === 0 && !isLoading && (
        <CommonEmptyData
          image={Images.shots_icon}
          mainTitle={English.B108}
          descriptionText={English.B109}
          onClick={() => { setShowShotsModel(true) }}
          addIcon={Images.FileUploadWhite}
          btnText={English.A265}
          className="!border-solid"
          classNameDescription="font-normal text-[#7A7474] text-[16px]"
        />
      )}
      <div className="video-items gap-4">
        {isLoading ? (
          <div className="awards-item-video">
            <Skeleton className="add-profile-video-cover" />
            <div className="video-content">
              <p>
                <Skeleton width={250} />
              </p>
              <div className="views-and-time">
                <span>
                  <Skeleton width={50} />
                </span>
                <span>
                  <Skeleton width={60} />
                </span>
              </div>
            </div>
          </div>
        ) : (
          shotData?.slice(0, 4)?.map((item, index) => (
            // <div className="video-item-detail h-[500px]" key={item.name}>
            //   <div
            //     className="video-cover"
            //     style={{
            //       background: `url(${s3ImageShots + item.cover_attachment})`,
            //       backgroundRepeat: 'no-repeat',
            //       backgroundSize: 'cover',
            //       backgroundPosition: 'center'
            //     }}
            //   >
            //     <div className="video_like_bg">
            //       <button
            //         type="button"
            //         className="video_like"
            //         onClick={(e) => {
            //           e.stopPropagation()
            //           shotLikeData(item.shot_id)
            //         }}
            //       >
            //         {item.is_like ? (
            //           <img src={Images.Likes} alt="Unlike" />
            //         ) : (
            //           <img src={Images.UnLikes} alt="Like" />
            //         )}
            //       </button>
            //     </div>
            //     {item?.app_data?.length > 0 && (
            //       <span
            //         className={`platform-badge ${
            //           item.app_data.length === 2 ? 'platform-badge-alt' : ''
            //         }`}
            //       >
            //         {item?.app_data?.map((data, i) => (
            //           <div
            //             key={i}
            //             className={`badge-cover ${
            //               item?.app_data?.length === 2 ? 'badge-cover-alt' : ''
            //             }`}
            //             style={{
            //               backgroundImage: `url(${urlSystemIcon + data.svg_icon})`,
            //               backgroundRepeat: 'no-repeat',
            //               backgroundSize: '90%',
            //               backgroundPosition: 'center'
            //             }}
            //           />
            //         ))}
            //       </span>
            //     )}
            //   </div>
            //   <div className="video-details video-1">
            //     <div className="d-flex justify-content-between">
            //       <h6>
            //         <img
            //           className="health_icon"
            //           src={Images.HealthIco}
            //           alt=""
            //         />
            //         <span className="content-video-title">{item.title}</span>
            //       </h6>
            //       <div className="rating">
            //         <img src={Images.RatingStar} alt="" />
            //         <span>4.9</span>
            //       </div>
            //     </div>
            //     {/* <p className="shot_home_page_tag">{JSON.parse(item.tags).toString()}</p> */}
            //     <div className="other-info">
            //       <span>
            //         <img src={Images.ClockIco} alt="" />
            //         {timeDifference(item.created_at)}
            //       </span>
            //       <span>{item.other}</span>
            //     </div>
            //   </div>
            // </div>
            <ShotItem
              key={item.shot_id}
              item={item}
              index={index}
              playingIndex={playingIndex}
              setPlayingIndex={setPlayingIndex}
              ShowConfirm={ShowConfirm}
              ShowConfirmModel={ShowConfirmModel}
              delLoading={delLoading}
              deleteShot={deleteShot}
              handleCancel={handleCancel}
              onClickEditPodcast={onClickEditPodcast}
              setUpdatingIndex={setUpdatingIndex}
              updatingIndex={updatingIndex}
            />
          ))
        )}
        {ShowShotsModel && (
          <ShotsAddModal
            editData={editData}
            handleCancel={HandleClose}
            onSubmit={() => {
              getShotLibraryData(null, 1)
              setEditData(null)
            }}
          />
        )}
      </div>
      {!isLoading && shotData?.length > 5 && (
        <ContentMangerCommonSeeAll
          onClick={() => emitter.emit(EventType.contentMangerTabs, { tabIndex: 2 })}
        />
      )}
    </div>
  )
}
export default Shots

import React from 'react'
import { useNavigate } from 'react-router-dom'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import { English, Images } from '../../../helpers'

const ContentAddonsScreen = () => {
   const navigate = useNavigate()

  return (
    <div className="bg-gray-100">
      <div className="bg-white rounded-[15px] !p-5 text-center h-[calc(100vh-133px)]">
        <div className="bg-PrimaryBg h-full w-full rounded-[15px] text-center flex justify-center items-center flex-col">
          <div className="flex justify-center mb-4">
            <img className="w-[130px] h-[130px]" src={Images.content_addons_icon} alt="" />
          </div>
          <h2 className="font-black italic text-lg text-textPrimary mb-0">{English.A262}</h2>
          <p className="font-normal text-base text-textPrimary mb-10">{English.A255}</p>
          <CommonButton
            label={English.A263}
            type="button"
            isPrimary
            context={English.G232}
            className="common-button"
            onClick={() => navigate('/profiles')}
          />
        </div>
      </div>
    </div>
  )
}
export default ContentAddonsScreen

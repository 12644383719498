/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import CommonInput from '../../InputComponet/CommonInput'
import CommonEmptyData from '../../CommonNoDataModal/CommonEmptyData'
import { English, Images } from '../../../helpers'
import { capitalizeFirstLetter, delay } from '../../../helpers/Utility'
import { APICall, EndPoints } from '../../../services'
import { s3ImageShots } from '../../../services/Service'
import { compressVideo, dataDecrypt } from '../../../services/http-services'
import CommonCMModal from './CommonCMModal'
import PlatformSelect from './PlatformSelect'

const ShotsAddModal = ({ handleCancel, editData, onSubmit }) => {
  const [selectedVideo, setSelectedVideo] = useState('')
  const [selectedVideoObj, setSelectedVideoObj] = useState('')
  const [loading, setLoading] = useState(false)
  const [appData, setAppData] = useState(null)
  const [inputFields, setInputFields] = useState({
    attachment: '',
    cover_attachment: '',
    app_id: 2,
    title: '',
    tags: [],
    search: ''
  })
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [isVideoUploading, setIsVideoUploading] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  useEffect(() => {
    if (editData) {
      setInputFields({
        title: editData.title || '',
        attachment: editData.attachment || '',
        cover_attachment: editData.cover_attachment || '',
        app_data: editData.app_data,
        shot_id: editData.shot_id
      })
      setSelectedVideo(s3ImageShots + editData.attachment)
      console.log(editData)
    }
  }, [editData])
  const [step, setStep] = useState(1)

  const clearValue = () => {
    setSelectedVideo('')
    setSelectedVideoObj('')
    setInputFields({
      ...inputFields,
      title: '',
      attachment: '',
      cover_attachment: '',
      app_data: '',
      shot_id: ''
    })
    setStep(1)
  }

  const handleCancelClick = () => {
    handleCancel()
    clearValue()
  }
  const videoRef = useRef(null)
  const handleVideoChange = (event) => {
    const maxFileSizeMB = 500
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024
    const maxDurationMinutes = 15

    const fileInput = event.target
    const selectedFile = fileInput.files[0]

    if (selectedFile) {
      if (selectedFile.size > maxFileSizeBytes) {
        toast.error(`Video file size exceeds ${maxFileSizeMB} MB`)
        return
      }
      const video = document.createElement('video')
      video.src = URL.createObjectURL(selectedFile)
      video.onloadedmetadata = () => {
        const durationMinutes = video.duration / 60
        if (durationMinutes > maxDurationMinutes) {
          toast.error(`Video duration exceeds ${maxDurationMinutes} minutes`)
          return
        }
        setSelectedVideoObj(selectedFile)
        const videoURL = URL.createObjectURL(selectedFile)
        setSelectedVideo(videoURL)
      }
    }
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const videoText = useMemo(() => {
    return (
      <div className="item-details-input !w-full mt-3 max-w-[100%]">
        <label className="mb-1 px-2">{English.A226}</label>
        <CommonInput
          name="title"
          placeholder={English.A227}
          onChange={handleChange}
          // maxLength={100}
          value={capitalizeFirstLetter(inputFields.title)}
        />
      </div>
    )
  }, [inputFields, handleChange])

  const ShotVideo = ({
    ref,
    className,
    videoClassName,
    videoSrc,
    posterSrc,
    onVideoEnd,
    onProgres,
    onPlay
  }) => {
    const videoRef = useRef(null)
    const [isPlaying, setIsPlaying] = useState(false)
    // const [isFinished, setIsFinished] = useState(false)
    const [progress, setProgress] = useState(0)
    // const [currentTime, setCurrentTime] = useState(0)
    // const [duration, setDuration] = useState(0)
    const [isMuted, setIsMuted] = useState(false)

    // const handlePlay = () => {
    //   const video = videoRef.current
    //   if (video) {
    //     video.play()
    //     setIsPlaying(true)
    //   }
    // }

    const handlePause = () => {
      setIsPlaying(false)
    }

    const handleEnded = () => {
      setIsPlaying(false)
      // setIsFinished(true)
      if (onVideoEnd) onVideoEnd()
    }

    const togglePlayPause = () => {
      const video = videoRef.current
      if (video.paused) {
        video.play()
        setIsPlaying(true)
      } else {
        video.pause()
        setIsPlaying(false)
      }
    }

    const toggleMute = () => {
      const video = videoRef.current
      video.muted = !video.muted
      setIsMuted(video.muted)
    }

    const handleProgress = () => {
      const video = videoRef.current
      const progress = (video.currentTime / video.duration) * 100
      setProgress(progress)
      if (onProgres) {
        onProgres(progress)
      }
      // setCurrentTime(video.currentTime)
      // setDuration(video.duration)
    }

    const handleTimelineClick = (e) => {
      const video = videoRef.current
      const timelineWidth = e.target.offsetWidth
      const clickX = e.nativeEvent.offsetX
      const newTime = (clickX / timelineWidth) * video.duration
      video.currentTime = newTime
    }

    useEffect(() => {
      const video = videoRef.current
      if (video) {
        video.addEventListener('loadedmetadata', () => {
          // setDuration(video.duration)
        })
      }
    }, [])

    useEffect(() => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(videoRef.current)
        } else {
          ref.current = videoRef.current
        }
      }
    }, [ref])

    const playVolumeIcon = isMuted ? Images.volume_mute : Images.volume_un_mute
    return (
      <div className={`video_player aspect-[9/16] ${className}`}>
        <div className="custom-controls top-2 !bottom-[unset]">
          <div className="timeline" onClick={handleTimelineClick}>
            <div className="progress" style={{ width: `${progress}%` }} />
          </div>
          <div className="play-time-full">
            <div className="play_icon justify-content-end">
              <div className="play_screen pt-2">
                <button
                  onClick={togglePlayPause}
                  type="button"
                  className="play-pause-btn h-[15px] !flex items-center"
                >
                  {isPlaying ? '⏸' : '▶'}
                </button>
                <button
                  onClick={toggleMute}
                  type="button"
                  className="mute-unmute-btn !flex items-center"
                >
                  <img src={playVolumeIcon} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <video
          width="100%"
          height="394"
          className={`aspect-[9/16] ${videoClassName}`}
          ref={videoRef}
          poster={posterSrc}
          controlsList="nodownload noplaybackrate"
          disablePictureInPicture
          allowFullScreen
          onPlay={() => {
            if (onPlay) {
              onPlay()
            }
            setIsPlaying(true)
          }}
          onPause={handlePause}
          onEnded={handleEnded}
          onClick={togglePlayPause}
          onTimeUpdate={handleProgress}
        >
          <source src={videoSrc} type="video/mp4" />
        </video>

        {/* {!isPlaying && !isFinished && (
          <div className="play-overlay" onClick={handlePlay}>
            <img src={Images.Video_play} alt="Play Button" />
          </div>
        )}

        {isFinished && (
          <div className="replay-overlay" onClick={handlePlay}>
            <img src={Images.Video_play} alt="Replay Button" />
          </div>
        )} */}
      </div>
    )
  }

  const videoUploadStep = useMemo(() => {
    if (step === 3) {
      return null
    }

    return (
      <div
        className={`!mt-5 rounded-[15px] local_contact_scroll_bars !max-h-max duration-100 ${step !== 3 && 'border-1 border-dashed border-BorderLight p-2 bg-background'}`}
      >
        <div className="upload-input">
          <CommonInput
            type="file"
            accept="video/*"
            id="upload-video"
            onChange={handleVideoChange}
            className="d-none"
          />
          {!selectedVideo && (
            <CommonEmptyData
              image={Images.FileUpload}
              mainTitle={English.A209}
              descriptionText={English.B126}
              onClick={() => document.getElementById('upload-video').click()}
              addIcon={Images.plusrounded}
              btnText={English.A210}
              className="!border-none"
              classNameDescription="max-w-[350px] !text-[14px] !font-normal !leading-5 !px-10"
            />
          )}
        </div>
        {selectedVideo && (
          <div className="video-wrapper modal-video flex flex-row relative gap-3 !h-[calc(100vh-350px)] justify-center">
            <div className="video_mb aspect-[9/16] !h-full relative rounded-2xl">
              <ShotVideo
                ref={videoRef}
                key={selectedVideo}
                videoSrc={selectedVideo}
                videoClassName="!aspect-[9/16]"
                className="custom-video-player !h-full !aspect-[9/16] relative !overflow-hidden rounded-2xl"
              />
              {(!editData && step !== 2) && (
                <button
                  type="button"
                  onClick={() => document.getElementById('upload-video').click()}
                  className="edit-button-model content-meneger-edit !top-[unset] !bottom-2 !right-2 z-10"
                />
              )}
            </div>
          </div>
        )}
      </div>
    )
  }, [selectedVideo, step])

  const publishEnaled = () => {
    return !(inputFields.title && selectedVideo)
  }

  const handlePublishClick = async () => {
    setLoading(true)

    let attachment = {
      result: inputFields.attachment,
      thumbnail: inputFields.cover_attachment
    }
    try {
      if (selectedVideoObj) {
        const videoFile = await compressVideo(
          selectedVideoObj,
          setUploadPercentage,
          setIsVideoUploading
          // loading
        )
        if (!videoFile) {
          return
        }
        const videoFormData = new FormData()
        videoFormData.append('file', videoFile)
        videoFormData.append('type', 'shot')

        attachment = await APICall(
          'post',
          videoFormData,
          EndPoints.uploadImage,
          'creator',
          true,
        )
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedVideoData = dataDecrypt(res.data.data)
              // inputFields.attachment = decryptedVideoData.result
              // inputFields.cover_attachment = decryptedVideoData.thumbnail
              setIsButtonLoading(true)
              setInputFields({
                ...inputFields,
                attachment: decryptedVideoData.result,
                cover_attachment: decryptedVideoData.thumbnail
              })
              return decryptedVideoData
            }
            toast.error(res?.data?.message)
            return null
          })
          .catch((e) => {
            toast.error(e?.data?.message)
          })
      }

      await delay(5000)
      await videoApiCall(attachment)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.message || 'An error occurred')
    }
  }

  const videoApiCall = async (attachment) => {
    setIsButtonLoading(true)
    let data = {
      title: inputFields.title,
      attachment: attachment.result,
      cover_attachment: attachment.thumbnail,
      app_id: inputFields?.app_data?.map((d) => d.id),
      shot_id: inputFields?.shot_id
    }
    if (editData) {
      data = {
        title: inputFields.title,
        attachment: attachment.result,
        cover_attachment: attachment.thumbnail,
        app_id: inputFields?.app_data.map((d) => d.id),
        shot_id: inputFields?.shot_id
      }
    }
    try {
      const res = await APICall(
        'post',
        data,
        `${editData ? EndPoints.editShot : EndPoints.addShot}`,
        'contentManager'
      )
      if (res.status === 200 && res.data?.code === 200) {
        await delay(4000)
        setLoading(false)
        clearValue()
        handleCancel()
        onSubmit(inputFields.shot_id)
        setIsButtonLoading(false)
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    } catch (error) {
      toast.error(error?.data?.message)
    }
  }

  useEffect(() => {
    if (appData) {
      setInputFields({
        ...inputFields,
        app_data: appData
      })
    }
  }, [appData])

  const appDataSelect = useMemo(() => {
    return (
      <PlatformSelect
        key={step}
        defaultIds={editData?.app_data}
        step={step}
        onSelectedIds={(data) => {
          setAppData(data)
          console.log(data)
        }}
      />
    )
  }, [])

  return (
    <CommonCMModal
      setStep={setStep}
      totalStep={3}
      step={step}
      handleCancel={handleCancelClick}
      loading={loading}
      isButtonLoading={isButtonLoading}
      isVideoUploading={isVideoUploading}
      uploadPercentage={uploadPercentage}
      nextBtnLable={step === 3 ? English.G128 : English.G69}
      nextBtnStatus={
        step === 1
          ? !selectedVideo
          : step === 2
            ? publishEnaled()
            : inputFields?.app_data?.length === 0 || loading
      }
      cancelBtnStatus={loading}
      closeBtnStatus={loading}
      title={English.B130}
      onClickNext={() => {
        if (step === 3) {
          handlePublishClick()
        } else {
          setStep(step + 1)
        }
      }}
    >
      <div className="!max-h-[calc(100vh-307px)] overflow-auto no-scrollbar">
        {step === 2 && videoText}
        {videoUploadStep}
        {step === 3 && appDataSelect}
      </div>
    </CommonCMModal>
  )
}

export default ShotsAddModal

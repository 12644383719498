/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-undef */
import { memo, useCallback } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { Images } from '../../../helpers'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import Utility from '../../../helpers/Utility'
import { APICall, EndPoints } from '../../../services'
import ShotsPreviewCard from './ShotsPreviewCard'
import { s3ImageProfile, s3ImageShots } from '../../../services/Service'
// import { CommonButton, ShotsPreviewCard } from '../../../components'
// import { APICall, BaseURL, Endpoint } from '../../../services'
// import { Images, Utility } from '../../../helpers'
// import { type ShotsVideoProps } from '../../VideosWeb/section/ShotsSection'

// interface ShotComponentProps {
//   item: ShotsVideoProps
//   setShowMessage: (state: boolean) => void
//   setMessageRefId: (state: number) => void
//   shotsArray: ShotsVideoProps[]
//   setShotsArray: (state: ShotsVideoProps[]) => void
//   isActive: boolean
// }

const ShotComponent = ({
  item,
  setShowMessage,
  setMessageRefId,
  shotsArray,
  setShotsArray,
  isActive,
  onEdit
}) => {
  const userData = useSelector((state) => state.user?.userData)
  const onPressLikeShots = useCallback(
    (id) => {
      const payload = {
        app_id: 2,
        shot_id: id
      }
      APICall('post', payload, EndPoints.shotLike, 'contentManager')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            const oldArray = [...shotsArray]
            const index = oldArray.findIndex((item) => {
              return item?.shot_id === id
            })
            oldArray[index] = {
              ...oldArray[index],
              total_likes:
                oldArray[index]?.is_like === true
                  ? oldArray[index].total_likes - 1
                  : oldArray[index].total_likes + 1,
              is_like: oldArray[index]?.is_like !== true
            }
            setShotsArray(oldArray)
          } else {
            toast.error(res.data?.message)
          }
        })
        .catch((e) => {
          toast.error(e?.data?.message)
        })
    },
    [setShotsArray, shotsArray]
  )

  const onPressBookMark = useCallback(
    (id) => {
      const payload = {
        app_id: 2,
        shot_id: id
      }
      APICall('post', payload, EndPoints.shotSave, 'contentManager')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            const oldArray = [...shotsArray]
            const index = oldArray.findIndex((item) => {
              return item?.shot_id === id
            })
            oldArray[index] = {
              ...oldArray[index],
              is_save: oldArray[index].is_save !== true
            }
            setShotsArray(oldArray)
          } else {
            toast.error(res.data?.message)
          }
        })
        .catch((e) => {
          toast.error(e?.data?.message)
        })
    },
    [setShotsArray, shotsArray]
  )

  const PressIcons = useCallback(
    (index) => {
      switch (index) {
        case 1:
          onPressLikeShots(item?.shot_id)
          break

        case 2:
          setMessageRefId(item?.shot_id)
          setShowMessage(true)
          break

        case 3:
          onPressBookMark(item?.shot_id)
          break
        default:
          break
      }
    },
    [item?.shot_id, onPressBookMark, onPressLikeShots, setMessageRefId, setShowMessage]
  )
  return (
    <>
      <ShotsPreviewCard
        CoverImage={
          item?.cover_attachment ? s3ImageShots + item.cover_attachment : ''
        }
        videoUrl={item?.attachment ? s3ImageShots + item.attachment : ''}
        ProfileImg={userData?.profile_image ? s3ImageProfile + userData.profile_image : ''}
        UserName={userData.full_name}
        UploadedTime={Utility.calculateTimePeriod(item?.created_at)}
        defaultPlay={isActive}
        onEdit={() => onEdit(item)}
      />
      <div className="flex justify-around pt-3 shots-engage items-start">
        {[Images.shot_like_fill_blue, Images.shot_comment_fill_blue, Images.shot_bookmark_fill_blue].map((element, index) => {
          return (
            <div className="flex flex-col !w-4 justify-center items-center gap-[6px]" key={element}>
              <CommonButton
                type="button"
                label=""
                disabled={false}
                context=""
                className="!rounded-full bg-redGradient !w-[45px] !h-[45px] !flex !justify-center !items-center !p-0"
                imgClassName="!object-scale-down"
                imgSrc={
                  index === 0
                    ? item.is_like === true
                      ? element
                      : Images.Feed_UnLike
                    : index === 2
                      ? item.is_save !== true
                        ? Images.Save
                        : element
                      : element
                }
                onClick={() => {
                  const type = index + 1
                  PressIcons(type)
                }}
              />
              <span className="!text-sm !text-white !not-italic">
                {index + 1 === 1
                  ? (Utility.formatLikeFollowCount(item.total_likes))
                  : index + 1 === 2
                    ? (Utility.formatLikeFollowCount(item.total_comments)?.toString())
                    : ''}
              </span>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default memo(ShotComponent)

import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
// import { useSelector } from 'react-redux'

import { APICall, EndPoints } from '../../services'
import { s3ImagePodcast } from '../../services/Service'
import { Images } from '../../helpers'
import ImageComponent from '../ImageComponent/ImageComponent'

const PopularPodcast = () => {
  // const app_id = useSelector((state) => state.user?.app_id)
  const [podcastPopularData, setPodcastPopularData] = useState([])

  const podcastPopular = () => {
    const payload = {}
    APICall('post', payload, `${EndPoints.popularPodcast}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setPodcastPopularData(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const podcastLikeData = (podcast_id) => {
      const platFormData = {
          app_id: 2,
          podcast_id
      }
      APICall('post', platFormData, `${EndPoints.podcastLike}`, 'contentManager')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setPodcastPopularData(
              podcastPopularData.map((item) => (item.podcast_id === podcast_id
                  ? { ...item, is_like: !item.is_like }
                  : item))
            )
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message)
        })
    }


  const PodcastList = ({ data, index }) => {
    return (
      <div key={index}>
        <div className="flex flex-col border border-[#E1E2E5] border-l-0 rounded-[20px]">
          <div className="relative">
            <ImageComponent
              className="w-full !h-[194px] object-center rounded-tl-[20px] rounded-tr-[20px]"
              imageUrl={s3ImagePodcast + data.cover_attachment}
              key={data.cover_attachment}
            />
            <div className="flex items-center justify-center rounded-[15px] bg-[#fff] absolute left-[15px] !top-[5px] mt-3 !h-[30px] !w-[30px]">
              <button
                type="button"
                className=""
                onClick={(e) => {
                  e.stopPropagation()
                  podcastLikeData(data.podcast_id)
                }}
              >
                {data?.is_like ? (
                  <img
                    className="h-[17px] w-[17px]"
                    src={Images.LikeFill_blue}
                    alt="Unlike"
                  />
              ) : (
                <img
                  className="h-[17px] w-[17px]"
                  src={Images.Feed_UnLike}
                  alt="Like"
                />
              )}
              </button>
            </div>
          </div>
          <div className="p-[10px] flex flex-col justify-between w-full">
            <div className="flex justify-between items-center mb-2.5">
              <h6 className="text-[14px] font-extrabold uppercase mb-0">
                {data.title}
              </h6>
              <div className="flex gap-1 items-center">
                <img src={Images.Fire_icon_blue} alt="a" />
                <div className="flex items-center gap-1">
                  <p className="mb-0 font-extrabold text-[#020B09] text-[10px] italic">
                    DEAL
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="flex gap-2 mb-2">
              <img className="h-[15px] w-[15px]" src={Images.Location} alt="" />
              <h6 className="text-[10px] font-medium text-[#151515] mb-0">
                {data.address}
              </h6>
            </div> */}
            <div className="flex gap-2 mb-2">
              <span key={index} className="text-blue-900 text-xl">
                ★
              </span>
              <h6 className="text-[10px] font-extrabold text-[#0B0A0A] mt-2">
                4.9
              </h6>
            </div>
            <div className="flex justify-between items-center mt-2">
              <div className="flex gap-2">
                <labe className="text-[#151515] font-medium text-[15px] capitalize">
                  see details
                </labe>
                <p className="mb-0 text-[#151515] font-bold text-[15px] uppercase">
                  {data.coupan_code}
                </p>
              </div>
              <img className="rotate-180" src={Images.left_arrow_icon} alt="" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    podcastPopular()
  }, [])

  return (
    <div className="!mt-7">
      <div className="flex items-center justify-between">
        <h3 className="text-[#151515] italic font-black text-[18px] uppercase">
          Popular
        </h3>
        <span className="text-base bg-primaryGradient bg-clip-text text-transparent font-bold">See All</span>
      </div>
      <div className="grid grid-cols-4 max-[1440px]:grid-cols-3 max-[1150px]:grid-cols-2 gap-[25px]">
        {podcastPopularData?.map((data, index) => (
          <PodcastList data={data} index={index} key={index} />
        ))}
      </div>
    </div>
  )
}

export default PopularPodcast

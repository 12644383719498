/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'
import { X } from 'lucide-react'
import Dropdown from '../Dropdown/Dropdown'
import { debounce, lockScroll, unlockScroll } from '../../helpers/Utility'
import Pagination from '../Pagination/Pagination'
import CommonInput from '../InputComponet/CommonInput'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import ContentMangerCommonTitle from './SubComponent/ContentMangerCommon.js/ContentMangerCommonTitle'
import PodcastAddModal from './Modal/PodcastAddModal'
import PodcastItem from './SubComponent/PodcastItem'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'

const PodcastTab = () => {
  // const userData = useSelector((state) => state.user?.userData)
  const audioRefs = useRef({})
  const navigate = useNavigate()
  const [ShowPodcastModel, setShowPodcastModel] = useState(false)
  const [podcastData, setPodcastData] = useState([])
  const [inputFields, setInputFields] = useState({
    title: '',
    description: '',
    minutes: '',
    attachment: '',
    cover_attachment: '',
    app_id: [],
    host_id: 0,
    search: ''
  })
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [delPodcastId, setDelPodcastId] = useState()
  const [delLoading, setDelLoading] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isPlaying, setIsPlaying] = useState({})

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [expandedIndex, setExpandedIndex] = useState(null)

  const [searchText, setSearchText] = useState('')
  const clearValue = () => {
    setEditData(null)
    setInputFields({
      ...inputFields,
      title: '',
      description: '',
      minutes: '',
      attachment: '',
      cover_attachment: '',
      app_id: [],
      host_id: 0
    })
  }

  const AddPodcast = () => {
    clearValue()
    setShowPodcastModel(true)
  }

  const handleCancel = () => {
    clearValue()
    setShowConfirmModel(false)
  }

  const clearSearch = () => {
    setSearchText('')
    handleSearch2('')
  }

  const handleSearch = (value) => {
    setSearchText(value)
    handleSearch2(value)
  }

  const handleCancelModal = () => {
    clearValue()
    setShowConfirmModel(false)
    setShowPodcastModel(false)
  }

  const ShowConfirm = (videoId) => {
    setDelPodcastId(videoId)
    setShowConfirmModel(true)
    Object.keys(audioRefs.current).forEach((index) => {
      const audio = audioRefs.current[index]
      if (audio) {
        audio.pause()
        audio.currentTime = 0
        setIsPlaying((prev) => ({ ...prev, [index]: false }))
      }
    })
  }

  const getPodcastLibraryData = (search, currentPage) => {
    setIsLoading(true)
    const data = {
      search
    }
    APICall(
      'post',
      data,
      `${EndPoints.getPodcastLibrary}?page=${currentPage}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setPodcastData(res?.decryptedData?.data)
          setTotalPages(res?.decryptedData?.last_page)
          setCurrentPage(res?.decryptedData?.current_page)
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const handleSearch2 = useCallback(
    debounce((inputVal) => getPodcastLibraryData(inputVal, currentPage), 500),
    []
  )

  const deletePodcast = () => {
    const data = {
      podcast_id: delPodcastId
    }
    setDelLoading(true)
    APICall('post', data, `${EndPoints.deletePodcast}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setTimeout(() => {
            getPodcastLibraryData(null, currentPage)
          }, 4000)
          setDelLoading(false)
          toast.success(res?.data?.message)
        } else {
          setDelLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setDelLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const [editData, setEditData] = useState(null)
  const onClickEditPodcast = (videoData) => {
    setEditData(videoData)
    setShowPodcastModel(true)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      description: videoData.description,
      duration: videoData.duration,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      host_id: videoData.host_id,
      podcast_id: videoData.podcast_id
    })
  }

  const handleItemClick = (videoData) => {
    navigate('/podcast-detail', { state: videoData })
  }

  // podcasts pagination

  const handlePageChange = (pageNumber) => {
    getPodcastLibraryData(null, pageNumber)
    setCurrentPage(pageNumber)
  }

  // hover play pause

  useEffect(() => {
    return () => {
      Object.keys(audioRefs.current).forEach((index) => {
        const audio = audioRefs.current[index]
        if (audio) {
          audio.pause()
          audio.src = ''
        }
      })
    }
  }, [])

  const togglePlayPause = (index) => {
    const audio = audioRefs.current[index]

    if (audio) {
      if (audio.paused) {
        audio
          .play()
          .then(() => {
            setIsPlaying((prev) => ({ ...prev, [index]: true }))
          })
          .catch((error) => {
            console.error('Error playing audio:', error)
          })
      } else {
        audio.pause()
        setIsPlaying((prev) => ({ ...prev, [index]: false }))
      }
    }
  }

  const playVolumeIcon = (index) => (
    <img
      src={isPlaying[index] ? Images.PodcastPauseIco : Images.PodcastPlayIco}
      alt={isPlaying[index] ? 'Pause' : 'Play'}
      onClick={(e) => {
        e.stopPropagation()
        togglePlayPause(index)
      }}
      className="volume-icon"
    />
  )

  useEffect(() => {
    getPodcastLibraryData(null, currentPage)
  }, [])

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    if (ShowPodcastModel) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [ShowPodcastModel])

  return (
    <div className="podcast-details-manage">
      <div>
        <div className="sticky_bar !pb-[2px] z-10 bg-white">
          <ContentMangerCommonTitle
            title={English.G226}
            buttonName={podcastData?.length === 0 ? null : English.G160}
            onButtonClick={podcastData?.length === 0 ? null : AddPodcast}
            className="!mb-0"
          />

          <div className="add-video-row">
            <div className="items-filter justify-between w-full">
              <div className="position-relative input-position">
                <CommonInput
                  placeholder="Search Podcast"
                  className="!text-black"
                  onChange={(e) => {
                    handleSearch(e.target.value)
                  }}
                  value={searchText}
                />
                {searchText && (
                  <button
                    type="button"
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-black"
                    onClick={clearSearch}
                  >
                    <X className="w-5 h-5" />
                  </button>
                )}
              </div>
              <div className="flex flex-row gap-2">
                <div className="drop-row !mb-0 gap-4">
                  <div className="drop-sub-row">
                    <Dropdown
                      title="Categories"
                      Data={[
                        { title: 'Scaloo' },
                        { title: 'Spacure' },
                        { title: 'PETZINO' },
                        { title: 'A-Z FIT' },
                        { title: 'TESTIEE' }
                      ]}
                      customClass="content-filter disabledDropdown"
                    />
                    <Dropdown
                      title="Ratings"
                      Data={[
                        { title: 'Scaloo' },
                        { title: 'Spacure' },
                        { title: 'PETZINO' },
                        { title: 'A-Z FIT' },
                        { title: 'TESTIEE' }
                      ]}
                      customClass="content-filter disabledDropdown"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {podcastData?.length === 0 && !isLoading ? (
          <CommonEmptyData
            image={Images.podcast_icon}
            mainTitle={English.B110}
            descriptionText={English.B111}
            onClick={() => {
                setShowPodcastModel(true)
              }}
            addIcon={Images.FileUploadWhite}
            btnText={English.G160}
            className="!border-solid"
            classNameDescription="font-normal text-[#7A7474] text-[16px]"
          />
          ) : isLoading ? (
            <div className="Podcasts-item-details">
              <div className="podacast-items position-relative">
                <div className="podcast-cover">
                  <div className="!text-end !mr-3 !mt-3">
                    <Skeleton circle width={30} height={30} />
                  </div>
                </div>
                <div className="!text-end !mr-1">
                  <Skeleton width={50} height={15} />
                </div>
                <div className="video-details podcast-detail">
                  <div className="d-flex justify-content-between user_images">
                    <h6>
                      <Skeleton
                        className="shots-profile m-auto"
                        width={30}
                        height={30}
                        circle={12}
                      />
                      <span className="skel-podcast">
                        <Skeleton width={100} height={20} />
                      </span>
                      <span className="skel-podcast">
                        <Skeleton width={20} height={15} />
                      </span>
                    </h6>
                  </div>
                  <p className="podcast-skeleton-height">
                    <Skeleton width={250} height={15} />
                    <Skeleton width={175} height={15} />
                  </p>
                  <p className="podcast-skeleton-height flex justify-between">
                    <Skeleton width={100} />
                    <Skeleton width={100} />
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="Podcasts-item-details">
              {podcastData.map((item, index) => (
                <PodcastItem
                  item={item}
                  index={index}
                  key={index}
                  ShowConfirm={ShowConfirm}
                  ShowConfirmModel={ShowConfirmModel}
                  delLoading={delLoading}
                  deletePodcast={deletePodcast}
                  expandedIndex={expandedIndex}
                  handleCancel={handleCancel}
                  handleItemClick={handleItemClick}
                  onClickEditPodcast={onClickEditPodcast}
                  playVolumeIcon={playVolumeIcon}
                  setExpandedIndex={setExpandedIndex}
                  setUpdatingIndex={setUpdatingIndex}
                  updatingIndex={updatingIndex}
                />
              ))}
            </div>
          )}
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>

      {ShowPodcastModel && (
        <PodcastAddModal
          handleCancel={handleCancelModal}
          editData={editData}
          onSubmit={() => {
            clearValue()
            getPodcastLibraryData(null, 1)
          }}
        />
      )}
    </div>
  )
}
export default PodcastTab

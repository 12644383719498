/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import ViewerComponent from '../../CommanComponent/ViewerComponent'
import { English, Images } from '../../../helpers'
import Dropdown from '../../Dropdown/Dropdown'
import CommonEmptyData from '../../CommonNoDataModal/CommonEmptyData'
import OrderChart from '../../Local/chart'
import Video from '../video'
import Shots from '../Shots'
import Podcasts from '../Podcasts'
import Giveaway from '../Giveaways'
import Polls from '../Polls'
import Discussions from '../Discussions'
// import Awards from '../Awards'

const ContentMangetOverview = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [showGraph, setShowGraph] = useState(false)
  const [endDate, setEndDate] = useState(new Date('2025/11/01'))
  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }
  return (
    <div>
      <div className="grid grid-cols-4 !gap-4 max-[1400px]:grid-cols-2">
        <ViewerComponent
          icon={Images.Visitor}
          title={English.G436}
          key={English.G436}
          value={25}
        />
        <ViewerComponent
          icon={Images.watchTime}
          title={English.G437}
          key={English.G437}
          value="2.5k"
          percentage={1.3}
        />
        <ViewerComponent
          icon={Images.subscriberPointer}
          title={English.G438}
          key={English.G438}
          value="385"
          percentage={1.3}
        />
        <ViewerComponent
          icon={Images.followersGroup}
          title={English.G105}
          key={English.G105}
          value="86.04%"
          percentage={1.3}
        />
      </div>
      <div className="content-manager-Statistics my-10">
        <div className="position-relative !px-5 !pt-5">
          <Dropdown
            title="Views"
            Data={[{ title: 'Views' }]}
            onChanged={() => {}}
            customClass={`!rounded-[100px] !border-BorderLight !bg-PrimaryBg !w-[165px] !py-3 ${!showGraph && 'opacity-20 pointer-events-none'}`}
          />
          <div className="absolute top-6 right-[210px] flex items-center gap-2.5 h-9">
            <div className="h-1 bg-[#ECE9F1] w-[50px] rounded-[999px]" />
            <div className="text-[10px] font-semibold text-[#D0D1D2]">
              Previous Month
            </div>
          </div>
          {/* <div className={`absolute top-6 right-6 ${!showGraph && 'opacity-20 pointer-events-none'}`}> */}
          <div
            className="absolute top-6 right-6 opacity-20 pointer-events-none"
          >
            <div className="month-range">
              <ReactDatePicker
                selected={startDate}
                onChange={handleChange}
                selectsRange
                startDate={startDate}
                endDate={endDate}
                dateFormat="MMM/yyyy"
                showMonthYearPicker
              />
            </div>
          </div>
          {showGraph ? (
            <OrderChart />
          ) : (
            <div>
              {/* <div onClick={() => setShowGraph(true)}> */}
              <CommonEmptyData
                className="w-full !border-solid !my-5"
                image={Images.autoGraph}
                mainTitle={English.B104}
                descriptionText={English.B105}
              />
            </div>
          )}
        </div>
      </div>
      <div className="content-manager-video mb-40">
        <Video />
      </div>
      <div className="content-manager-video mb-40">
        <Shots />
      </div>
      <div className="content-manager-video mb-40">
        <Podcasts />
      </div>
      <div className="content-manager-video mb-40">
        <Giveaway />
      </div>
      <div className="content-manager-video mb-40">
        <Polls />
      </div>
      <div className="content-manager-video mb-40">
        <Discussions />
      </div>
      <div className="content-manager-video mb-40">{/* <Awards /> */}</div>
    </div>
  )
}

export default ContentMangetOverview

import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import { English } from '../../../helpers'
import { APICall, EndPoints } from '../../../services'

const GiveawayParticipate = ({
  giveawayID,
  onSuccessParticipate,
  isParticipated
}) => {
  const [inputFields, setInputFields] = useState({
    giveaway_id: giveawayID,
    first_name: '',
    last_name: '',
    phone: '',
    address: '',
    city: ''
  })

  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    setInputFields((prevFields) => ({
      ...prevFields,
      giveaway_id: giveawayID
    }))
  }, [giveawayID])

  useEffect(() => {
    validateForm()
  }, [inputFields])

  const validateForm = () => {
    const { first_name, last_name, phone, address, city } = inputFields
    const phoneValid = /^\d{11,12}$/.test(phone)
    const allFieldsFilled =
      first_name && last_name && address && city && phoneValid

    setIsFormValid(allFieldsFilled)
  }

  useEffect(() => {
    if (isParticipated) {
      setInputFields({
        giveaway_id: giveawayID,
        first_name: '',
        last_name: '',
        phone: '',
        address: '',
        city: ''
      })
    }
  }, [isParticipated, giveawayID])

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  console.log('inputFields', inputFields)

  const addGiveawayDataSubmit = () => {
    if (isParticipated) return
    // setLoading(true)
    APICall(
      'post',
      inputFields,
      `${EndPoints.participateForm}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          if (onSuccessParticipate) {
            onSuccessParticipate()
          }
        } else {
          //   setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        // setLoading(false)
      })
  }

  return (
    <div>
      <h6 className="italic font-black text-black text-lg">participate in our Giveaway</h6>
      <p className="font-normal text-black text-[14px]">
        Fill in the entry form with your contact details to enter the
        giveaway.
      </p>
      <div className="item-details-input flex flex-col gap-2">
        <CommonInput
          name="first_name"
          placeholder="Enter first name"
          onChange={handleChange}
        />
        <CommonInput
          name="last_name"
          placeholder="Enter last name"
          onChange={handleChange}
        />
        <CommonInput
          name="address"
          placeholder="Enter address"
          onChange={handleChange}
        />
        <CommonInput
          name="phone"
          placeholder="Enter phone number"
          maxLength={12}
          onKeyPress={(e) => {
            const allowedKeys = /[0-9]/
            if (!allowedKeys.test(e.key)) {
              e.preventDefault()
            }
          }}
          onChange={handleChange}
        />
        <CommonInput
          name="city"
          placeholder="Enter city name"
          onChange={handleChange}
        />
      </div>
      <div className="mt-[10px]">
        <CommonButton
          label={isParticipated ? 'Already Participated' : 'Submit to Enter'}
          type="button"
          isPrimary
          className="w-auto"
          onClick={() => addGiveawayDataSubmit()}
          context={English.G232}
          disabled={isParticipated || !isFormValid}
        />
      </div>
    </div>
  )
}

export default GiveawayParticipate

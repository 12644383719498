/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { English, Images } from '../../helpers'
import DummyComment from './DummyComment'
import VideoPlayer from './CommonVideoPlayer'
import { s3ImageProfile, s3ImageVideo } from '../../services/Service'
import ViewerComponent from '../CommanComponent/ViewerComponent'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import Dropdown from '../Dropdown/Dropdown'
import OrderChart from '../Local/chart'
import PreviewVideoModal from './Modal/PreviewVideoModal'
import { APICall, EndPoints } from '../../services'
import { emitter, EventType } from '../../helpers/Emitter'
import VideoAddModal from './Modal/VideoAddModal'
import BreadCrumbHeader from './SubComponent/BreadCrumbHeader'
import ContentMangerCommonTitle from './SubComponent/ContentMangerCommon.js/ContentMangerCommonTitle'

const VideoDetailPage = () => {
  const location = useLocation()
  const data = location?.state?.data
  const userData = useSelector((state) => state.user?.userData)
  const [expandedDescription, setExpandedDescription] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [showGraph, setShowGraph] = useState(false)
  const [endDate, setEndDate] = useState(new Date('2025/11/01'))
  const [previewModal, setPreviewModal] = useState(null)
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [comments, setComments] = useState([])
  const [commentsCount, setCommentCount] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [hasMorePages, setHasMorePages] = useState(false)

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const videoRef = useRef(null)

  const handleVideoEnd = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0
    }
  }

  useEffect(() => {
    if (data) {
      videoComment(data?.video_id, 1)
    }
  }, [data])

  const handleLoadMore = () => {
    videoComment(data?.video_id, currentPage + 1)
  }

  const videoComment = (video_id, page = 1) => {
    const platFormData = {
      app_id: 2,
      video_id
    }
    setIsLoadingComments(true)
    APICall('post', platFormData, `${EndPoints.videoComment}?page=${page}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const dataComment = res?.decryptedData?.data?.map((d, i) => ({
            ...d,
            id: i + 1
          }))

          if (page === 1) {
            setComments(dataComment)
          } else {
            setComments((prev) => [...prev, ...dataComment])
          }

          setCommentCount(res?.decryptedData?.total)
          setCurrentPage(res?.decryptedData?.current_page)
          setHasMorePages(res?.decryptedData?.next_page_url !== null)

          setIsLoadingComments(false)
        } else {
          setIsLoadingComments(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsLoadingComments(false)
        toast.error(error?.data?.message)
      })
  }

  const handleAddComment = (comment, profilePic, name) => {
    if (comment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        comment,
        video_id: data?.video_id,
        total_like: 0,
        reply_data: [],
        user: {
          name,
          profilePic
        }
      }
      setComments([newCommentObj, ...comments])
    }
    shotAddComment(comment)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.comment_id === commentId) {
        return {
          ...comment,
          reply_data: [
            ...comment.reply_data,
            {
              id: comment.reply_data.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: userData.user_name,
                profilePic: s3ImageProfile + userData.profile_image
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    shotAddComment(replyText, commentId)
  }

  const shotAddComment = (comment, comment_id) => {
    let platFormData = {
      video_id: data?.video_id,
      comment,
      app_id: 2
    }
    if (comment_id) {
      platFormData = { ...platFormData, comment_id }
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.videoCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          videoComment(data?.video_id)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleLikeComment = (comment_id, commentData) => {
    const updatedComments = []
    comments.forEach((comment) => {
      if (!commentData?.reply_data) {
        const updatedCommentsReplay = []
        comment?.reply_data?.forEach((item) => {
          if (item?.comment_id === comment_id) {
            updatedCommentsReplay.push({
              ...item,
              is_like: !item.is_like,
              total_like: !item.is_like
                ? item.total_like + 1
                : item.total_like - 1
            })
          } else {
            updatedCommentsReplay.push(item)
          }
        })
        updatedComments.push({ ...comment, reply_data: updatedCommentsReplay })
      } else if (comment.comment_id === comment_id) {
        updatedComments.push({
          ...comment,
          is_like: !comment.is_like,
          total_like: !comment.is_like
            ? comment.total_like + 1
            : comment.total_like - 1
        })
      } else {
        updatedComments.push(comment)
      }
    })
    setComments(updatedComments)
    shotCommentLike(comment_id)
  }

  const shotCommentLike = (comment_id) => {
    const platFormData = {
      app_id: 2,
      comment_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.videoCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          //
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const commentMemo = useMemo(() => {
    return (
      <DummyComment
        key="Comments"
        commentsCount={commentsCount}
        data={comments}
        isLoadingComments={isLoadingComments}
        onNewComment={(comment) => handleAddComment(comment)}
        onCommentReplay={(comment, data) => handleAddReply(data.comment_id, comment)}
        onLikeClick={(comment) => handleLikeComment(comment.comment_id, comment)}
        onLoadMore={handleLoadMore}
        hasMore={hasMorePages}
      />
    )
  // }, [isLoadingComments, handleAddComment])
}, [comments, isLoadingComments, hasMorePages])

  useEffect(() => {
    if (data) {
      getParticularVideosData(data.video_id)
    }
  }, [data])

  const [editVideoDate, setEditVideoData] = useState(null)
  const [videoData, setVideoData] = useState(null)
  const [showAddVideoModal, setShowAddVideoModal] = useState(false)
  const handleCancel = () => {
    setEditVideoData(null)
    setShowAddVideoModal(false)
  }
  const onClickEdit = () => {
    setEditVideoData(data)
    setShowAddVideoModal(true)
  }

  const getParticularVideosData = async (id) => {
    const data = {
      video_id: id
    }
    APICall('post', data, `${EndPoints.particularVideo}`, 'contentManager')
      .then((res) => {
        if (res.status === 200) {
          setVideoData(res?.decryptedData[0])
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const onBack = (tabIndex) => {
    window.history.back()
    setTimeout(() => {
      emitter.emit(EventType.contentMangerTabs, { tabIndex })
    }, 100)
  }

  return (
    <div className="content-manager">
      <div className="common-card-body p-20">
        <div className="!overflow-auto no-scrollbar !h-[calc(100vh-173px)]">
          <div className="bg-white rounded-[15px]">
            <BreadCrumbHeader
              link1Lable="Videos"
              link2Lable={videoData?.title}
              onClickHome={() => onBack(0)}
              onClickLink={() => onBack(1)}
            />
            <ContentMangerCommonTitle
              isPublish
              title={English.G225}
              buttonName="EDIT"
              onButtonClick={onClickEdit}
            />
            <div>
              <div className="flex flex-row gap-3 ">
                <div
                  onClick={() => setPreviewModal(videoData)}
                  className="video_mb! aspect-video w-[380px] "
                >
                  <VideoPlayer
                    ref={videoRef}
                    videoSrc={s3ImageVideo + videoData?.attachment}
                    posterSrc={s3ImageVideo + videoData?.cover_attachment}
                    onVideoEnd={handleVideoEnd}
                    className="custom-video-player pointer-events-none !aspect-video relative !overflow-hidden rounded-2xl"
                  />
                </div>
                <div>
                  <div>
                    <h3 className="text-Main font-black italic text-lg max-w-[699px]">
                      {videoData?.title || ''}
                    </h3>
                  </div>
                  <div>
                    {videoData?.description && (
                      <p className="text-[#5A5A5A]font-normal text-sm max-w-[699px]">
                        {expandedDescription
                          ? videoData?.description
                          : `${videoData?.description?.slice(0, 300)}`}
                        {videoData?.description?.length > 300 && (
                          <span
                            className="see_more_local"
                            onClick={(e) => {
                              e.stopPropagation()
                              setExpandedDescription(!expandedDescription)
                            }}
                          >
                            ...
                            {' '}
                            {expandedDescription ? English.G193 : English.G194}
                          </span>
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="grid grid-cols-4 !gap-4 max-[1400px]:grid-cols-2">
                <ViewerComponent
                  icon={Images.Visitor}
                  title={English.G436}
                  key={English.G436}
                  value={25}
                />
                <ViewerComponent
                  icon={Images.watchTime}
                  title={English.G437}
                  key={English.G437}
                  value="2.5k"
                  percentage={1.3}
                />
                <ViewerComponent
                  icon={Images.subscriberPointer}
                  title={English.G438}
                  key={English.G438}
                  value="385"
                  percentage={1.3}
                />
                <ViewerComponent
                  icon={Images.followersGroup}
                  title={English.G105}
                  key={English.G105}
                  value="86.04%"
                  percentage={1.3}
                />
              </div>
              <div className="content-manager-Statistics my-2.5">
                <div className="position-relative !px-5 !pt-5">
                  <Dropdown
                    title="Views"
                    Data={[{ title: 'Views' }]}
                    onChanged={() => {}}
                    customClass={`!rounded-[100px] !border-BorderLight !bg-PrimaryBg !w-[165px] !py-3 ${!showGraph && 'opacity-20 pointer-events-none'}`}
                  />
                  <div className="absolute top-6 right-[210px] flex items-center gap-2.5 h-9">
                    <div className="h-1 bg-[#ECE9F1] w-[50px] rounded-[999px]" />
                    <div className="text-[10px] font-semibold text-[#D0D1D2]">
                      Previous Month
                    </div>
                  </div>
                  <div
                    className={`absolute top-6 right-6 ${!showGraph && 'opacity-20 pointer-events-none'}`}
                  >
                    <div className="month-range">
                      <ReactDatePicker
                        selected={startDate}
                        onChange={handleChange}
                        selectsRange
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="MMM/yyyy"
                        showMonthYearPicker
                      />
                    </div>
                  </div>
                  {showGraph ? (
                    <OrderChart />
                  ) : (
                    <div onClick={() => setShowGraph(true)}>
                      <CommonEmptyData
                        className="w-full !border-solid !my-5"
                        image={Images.autoGraph}
                        mainTitle={English.B104}
                        descriptionText={English.B105}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>{commentMemo}</div>
          {showAddVideoModal && (
            <VideoAddModal
              handleCancel={handleCancel}
              editData={editVideoDate}
              onSubmit={(video_id) => {
                // if (video_id) {
                //   const video = getParticularVideosData(video_id)
                //   const temp = [...videoData]
                //   videoData.forEach((item, index) => {
                //     if (item.video_id === video_id) {
                //       temp[index] = video
                //     }
                //   })
                //   setVideoData(temp)
                // } else {
                // clearValue()
                getParticularVideosData(video_id)
                handleCancel()
                // }
              }}
            />
          )}
          {previewModal && (
            <PreviewVideoModal
              videoData={previewModal}
              handleCancel={() => setPreviewModal(null)}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default VideoDetailPage

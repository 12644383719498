/* eslint-disable import/prefer-default-export */

import Images from './Images'

/* eslint-disable no-useless-escape */
export const UserType = {
  Member: 'member',
  Creator: 'creator',
  Business: 'business'
}

export const socialMediaOptions = [
  { img: '../images/socialicon/youtube.png', name: 'youtube' },
  { img: '../images/socialicon/twiter.png', name: 'twitter' },
  { img: '../images/socialicon/facebook.png', name: 'facebook' },
  { img: '../images/socialicon/instagram.png', name: 'instagram' },
  { img: '../images/socialicon/twitch.png', name: 'twitch' },
  { img: '../images/socialicon/tiktok.png', name: 'tiktok' },
  { img: '../images/socialicon/linkedin.png', name: 'linkedin' }
]

export const urlRegex = /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i

export const RegisterMarqueeArray = [
  Images.logo_1,
  Images.logo_2,
  Images.logo_3,
  Images.logo_4,
  Images.logo_5,
  Images.logo_6,
  Images.logo_7,
  Images.logo_8,
  Images.logo_9,
  Images.logo_10,
  Images.logo_11,
  Images.logo_12,
  Images.logo_13,
  Images.logo_14,
  Images.logo_15,
  Images.logo_16
]

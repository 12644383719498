/* eslint-disable no-return-assign */
import React, { useState, useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import './Stories.css'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { Pause, Play } from 'lucide-react'
import { s3ImageStory } from '../../services/Service'
import English from '../../helpers/English'
import { APICall, dataDecrypt, EndPoints } from '../../services'
import { Images } from '../../helpers'
import CommonModalMain from '../CommonModalMain'
import CommonInput from '../InputComponet/CommonInput'
import { compressVideo } from '../../services/http-services'
import UploadingComponent from '../UploadingComponent/UploadingComponent'
import Loader from '../Loader/Loader'

const AddStories = () => {
  const app_id = useSelector((state) => state.user?.app_id)
  const [storyId, setStoryId] = useState()
  const [stories, setStories] = useState([])
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [addModalModel, setAddModalModel] = useState(false)
  const [isUploading, setUploading] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState('')
  const [selectedVideoObj, setSelectedVideoObj] = useState('')
  const [inputFields, setInputFields] = useState({
    app_id: app_id?.id,
    attachment: '',
    access: 'public'
  })
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [isVideoUploading, setIsVideoUploading] = useState(false)
  const [isVideoUploadingDisable, setIsVideoUploadingDisable] = useState(false)

  const componentRef = useRef()
  const videoRefs = useRef({})
  const [pause, setPause] = useState(false)
  const [isMuted, setIsMuted] = useState(false)

  const clearValue = () => {
    setSelectedVideoObj('')
    setSelectedVideo('')
    setInputFields({
      ...inputFields,
      attachment: '',
      access: ''
    })
  }


  // const handleMouseEnter = (id) => {
  //   if (videoRefs?.current && videoRefs?.current[id]) {
  //     Object.values(videoRefs?.current)?.forEach((video) => video?.pause())
  //     videoRefs?.current[id].play()
  //   }
  // }
  const handleMouseEnter = (id) => {
    if (videoRefs?.current?.[id]) {
      try {
        Object.values(videoRefs.current)?.forEach((v) => v?.pause())
        videoRefs.current[id].play()
      } catch (error) {
        console.error('Video play error:', error)
      }
    }
  }

  // const handleMouseLeave = (id) => {
  //   if (videoRefs?.current && videoRefs?.current[id]) {
  //     videoRefs?.current[id]?.pause()
  //     videoRefs.current[id].currentTime = 0
  //   }
  // }
  const handleMouseLeave = (id) => {
    if (videoRefs?.current?.[id]) {
      const video = videoRefs.current[id]

      if (!video.paused) {
        video.pause()
        video.currentTime = 0
      }
    }
  }

  const storiesGet = () => {
    const platFormData = {
      app_id: app_id?.id
    }
    APICall('post', platFormData, `${EndPoints.getStories}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setStories(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const ShowConfirm = (story_id) => {
    setStoryId(story_id)
    setShowConfirmModel(true)
  }

  const deleteStory = () => {
    const data = {
      app_id: app_id?.id,
      story_id: storyId
    }
    APICall('post', data, `${EndPoints.deleteStories}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          // setStories((stories) => stories.filter((data) => data.story_id === storyId))
          setTimeout(() => {
            storiesGet()
          }, 2500)
          toast.success(res.data.message)
          setShowConfirmModel(false)
          setStoryId()
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const HandleCancelConfirm = () => {
    setShowConfirmModel(false)
    setStoryId()
  }
  const addStory = () => {
    setAddModalModel(true)
  }
  const onPressClose = () => {
    setAddModalModel(false)
    setSelectedVideoObj('')
    setSelectedVideo('')
    clearValue()
    setIsVideoUploading(false)
    setUploadPercentage(null)
    setUploading(false)
    setIsVideoUploadingDisable(false)
  }

  const pauseHandel = () => {
    if (videoRefs.current.paused) {
      videoRefs.current.play()
    } else {
      videoRefs.current.pause()
    }
    setPause(!pause)
  }

  const VolumeHandel = () => {
    videoRefs.current.muted = !videoRefs.current.muted
    setIsMuted(videoRefs.current.muted)
  }

  const playPauseIcon = pause ? (
    <Pause fill="#FFFFFF" color="#FFFFFF" size={23} />
  ) : (
    <Play fill="#FFFFFF" color="#FFFFFF" size={23} />
  )

  const playVolumeIcon = isMuted ? (
    <img src={Images.podcast_mute_new_white} alt="" />
  ) : (
    <img src={Images.podcast_volume_new_white} alt="" />
  )

  const handleVideoChange = (event) => {
    const fileInput = event.target
    const selectedFile = fileInput.files[0]

    if (selectedFile) {
      const videoURL = URL.createObjectURL(selectedFile)
      const video = document.createElement('video')

      video.src = videoURL
      video.onloadedmetadata = () => {
        const videoDuration = video.duration

        if (videoDuration > 60) {
          toast.error('please story upload under 1 min !')
          fileInput.value = ''
          return
        }

        setSelectedVideoObj(selectedFile)
        setSelectedVideo(videoURL)
      }
    }
  }

  const handlePublishClick = async () => {
    setIsVideoUploadingDisable(true)
    try {
      if (selectedVideoObj) {
        const videoFile = await compressVideo(
          selectedVideoObj,
          setUploadPercentage,
          setIsVideoUploading
        )
        const videoFormData = new FormData()
        videoFormData.append('file', videoFile)
        videoFormData.append('type', 'story')

        await APICall(
          'post',
          videoFormData,
          EndPoints.uploadImage,
          'creator',
          true
        )
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedVideoData = dataDecrypt(res.data.data)
              inputFields.attachment = decryptedVideoData
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((e) => {
            toast.error(e?.data?.message)
          })
      }
      setUploading(true)
      setTimeout(() => {
        addShotApiCall()
      }, 5000)
    } catch (error) {
      toast.error(error.message || 'An error occurred')
    }
  }

  const addShotApiCall = () => {
    APICall('post', inputFields, `${EndPoints.addStories}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setAddModalModel(false)
          setTimeout(() => {
            storiesGet()
            clearValue()
            setIsVideoUploadingDisable(false)
          }, 2500)
          toast.success(res?.data?.message)
          setUploading(false)
        } else {
          clearValue()
          toast.error(res?.data?.message)
          setUploading(false)
        }
      })
      .catch((error) => {
        clearValue()
        toast.error(error?.data?.message)
        setUploading(false)
      })
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowConfirmModel(false)
        setStoryId()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    storiesGet()
  }, [])

  return (
    <div className="stories-component add-stories">
      <div className="story-card justify-items-center">
        <div className="w-full">
          <Swiper
            slidesPerView="auto"
            spaceBetween={1}
            navigation
            modules={[Navigation]}
            className="position-static"
            centeredSlides={stories?.length === 0}
          >
            <SwiperSlide className="">
              <div className="story-item relative">
                <div className="flex flex-col gap-2 items-center">
                  <button
                    type="button"
                    className="add-item-btn bg-[#01104D] w-12 h-12 rounded-full flex items-center justify-center absolute"
                    onClick={addStory}
                  >
                    <img
                      className="mx-auto !p-1"
                      src={Images.plusrounded}
                      alt="add poll"
                    />
                  </button>
                  <div className="text-center absolute w-full bottom-2.5">
                    <h5 className="text-sm text-Main text-center">
                      Create Story
                    </h5>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {stories.map((story) => (
              <SwiperSlide key={story.id}>
                <div className="relative">
                  <div
                    className="relative story-slider-item cursor-pointer rounded-lg"
                    onMouseEnter={() => handleMouseEnter(story.story_id)}
                    onMouseLeave={() => handleMouseLeave(story.story_id)}
                  >
                    <video
                      ref={(el) => {
                        if (videoRefs.current) {
                          videoRefs.current[story.story_id] = el
                        }
                      }}
                      className="absolute inset-0 w-full h-full object-cover"
                      src={s3ImageStory + story.attachment}
                      loop
                      muted
                      playsInline
                    />
                    <img
                      src={story.thumbnail}
                      alt={story.title}
                      className="absolute inset-0 w-full h-full object-cover transition-opacity duration-300 hover:opacity-0"
                    />
                    <button
                      type="button"
                      onClick={() => ShowConfirm(story.story_id)}
                      className="delete-btn"
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      {ShowConfirmModel && (
        <div className="add-item-model">
          <div className="add-item-contents h-auto w-auto" ref={componentRef}>
            <div className="item-details-input pt-0">
              <h3 className="mb-0 text-center">{English.G119}</h3>
              <div className="button-row justify-content-center">
                <button
                  type="button"
                  className="secondary-btn-modelConfirm"
                  onClick={HandleCancelConfirm}
                >
                  <span>{English.G116}</span>
                </button>
                <button
                  type="button"
                  className="primary-btn-model"
                  onClick={() => {
                    deleteStory()
                  }}
                >
                  {English.G120}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {addModalModel && (
        <CommonModalMain
          showCross
          title="Upload Story"
          isPrevious={English.G116}
          isNext={English.G117}
          onPressClose={onPressClose}
          // className={className}
          onPressBack={onPressClose}
          onPressSave={handlePublishClick}
          isButtonDisabled={!selectedVideo || isVideoUploadingDisable}
          cancelButtonDisabled={isVideoUploadingDisable}
          closeIcon={isVideoUploadingDisable}
          showFooter
        >
          <div className="w-full h-full flex flex-col gap-5">
            <div className="upload-input flex gap-3">
              {selectedVideo ? (
                <div className="story_hight ">
                  <div className="relative">
                    <video
                      className="rounded-[14px] !max-h-[445px] !w-[250px]"
                      ref={videoRefs}
                      // poster={selectedFilePath}
                      controls={false}
                      autoPlay
                      // muted={isMuted}
                      playsInline
                    >
                      <source src={selectedVideo} type="video/mp4" />
                    </video>
                  </div>
                  <div className="story_play_icon">
                    <div className="shots-play-control" onClick={pauseHandel}>
                      {playPauseIcon}
                    </div>
                  </div>
                  <div className="story-control absolute">
                    <div
                      className="shots-volume-control"
                      onClick={VolumeHandel}
                    >
                      {playVolumeIcon}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="upload-input">
                  <CommonInput
                    type="file"
                    id="upload-file"
                    accept="video/*"
                    onChange={handleVideoChange}
                    className="d-none"
                  />
                  <div className="upload-img h-[431px] w-[270px] border-1 border-dashed border-SecondaryBorder rounded-[14px] bg-PrimaryBg">
                    <button
                      type="button"
                      className="rounded-full w-12 h-12 top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2  !bg-white flex items-center justify-center absolute"
                      onClick={() => {
                        document.getElementById('upload-file').click()
                      }}
                    >
                      <img
                        className="mx-auto"
                        src={Images.PlusRed}
                        alt="add poll"
                      />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {isVideoUploading && (
            <UploadingComponent uploadPercentage={uploadPercentage} />
          )}
          {isUploading && <Loader />}
          <div className="py-2" />
        </CommonModalMain>
      )}
    </div>
  )
}
export default AddStories

/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'
import Skeleton from 'react-loading-skeleton'
import { format, isBefore } from 'date-fns'
import { useSelector } from 'react-redux'
import { X } from 'lucide-react'
import Dropdown from '../Dropdown/Dropdown'
import { s3ImageProfile } from '../../services/Service'
import Loader from '../Loader/Loader'
import Pagination from '../Pagination/Pagination'
import { lockScroll, unlockScroll } from '../../helpers/Utility'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommonInput from '../InputComponet/CommonInput'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import ContentMangerCommonTitle from './SubComponent/ContentMangerCommon.js/ContentMangerCommonTitle'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import PollAddModal from './Modal/PollAddModal'
import PollItem from './SubComponent/PollItem'

const PollTab = () => {
  const userData = useSelector((state) => state.user?.userData)
  const app_id = useSelector((state) => state.user?.app_id)
  const [ShowPollPreview, setShowPollPreview] = useState(false)
  const [ShowPollModel, setShowPollModel] = useState(false)
  const [inputFields, setInputFields] = useState({
    app_id: [],
    topic: '',
    category_id: 0,
    sub_category_id: 0,
    expire_date: '',
    options: []
  })
  const [delPollId, setDelPollId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [loading, setLoading] = useState(false)
  const [pollsDetail, setPollsDetail] = useState([])
  const [voteList, setVoteList] = useState([])
  // const [votedPolls, setVotedPolls] =  useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [commentSection, setCommentSection] = useState(false)
  const [comments, setComments] = useState([])
  const [newComment, setNewComment] = useState('')
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [currentCommentPage, setCurrentCommentPage] = useState(1)
  const [selectedId, setSelectedId] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)
  const [editData, setEditData] = useState(null)
  const [pollCurrentPage, setPollCurrentPage] = useState(1)
  const [pollTotalPages, setPollTotalPages] = useState(1)
  const [searchText, setSearchText] = useState('')

  const handleCancel = () => {
    setShowConfirmModel(false)
  }

  const commentClear = () => {
    setComments([])
    setSelectedId([])
    setNewComment('')
    setShowReplied('')
    setEditedCommentText('')
    setEditedReplyText('')
  }

  // eslint-disable-next-line no-unused-vars
  const ShowCommunityGiveaway = () => {
    setShowPollModel(true)
  }

  const handleCancelModal = () => {
    clearValue()
    setShowPollPreview(false)
    setShowPollModel(false)
  }

  const ShowConfirm = (poll_id) => {
    setDelPollId(poll_id)
    setShowConfirmModel(true)
  }

  const clearValue = () => {
    setInputFields({
      ...inputFields,
      app_id: [],
      topic: '',
      category_id: '',
      sub_category_id: '',
      options: [],
      expire_date: ''
    })
  }

  const clearSearch = () => {
    setSearchText('')
    handleSearch2('')
  }

  const handleSearch = (value) => {
    setSearchText(value)
    handleSearch2(value)
  }

  const handleSearch2 = useCallback(
    debounce((inputVal) => getPollLibraryData(inputVal, pollCurrentPage), 500),
    []
  )

  const getPollLibraryData = (search, pollCurrentPage) => {
    setIsLoading(true)
    const data = {
      search
    }
    APICall(
      'post',
      data || {},
      `${EndPoints.getPollsLibrary}?page=${pollCurrentPage}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setPollsDetail(res?.decryptedData.data)
          setPollTotalPages(res?.decryptedData?.last_page)
          setPollCurrentPage(res?.decryptedData?.current_page)
          // const { data } = dataDecrypt(res?.data?.data)
          // if (data.length !== 0) {
          //   const poll_id = data.map((poll) => poll.poll_id)
          //   voterList(poll_id)
          // }
          // const { data } = dataDecrypt(res.data.data)
          // data.forEach((poll) => {
          //   voterList(poll.poll_id, poll)
          // })
          // if (data.length !== 0) {
          //   voterList(data[0].poll_id)
          // }
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const deletePoll = () => {
    const data = {
      poll_id: delPollId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.deletePoll}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setTimeout(() => {
            getPollLibraryData(null, pollCurrentPage)
          }, 3000)
          setLoading(false)
          toast.success(res?.data?.message)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const onClickEditPoll = (videoData) => {
    setShowPollModel(true)
    setEditData(videoData)
    setInputFields({
      ...inputFields,
      topic: videoData.topic,
      category_id: videoData.category_id,
      sub_category_id: videoData.sub_category_id,
      options: videoData.options,
      app_id: videoData.app_data,
      poll_id: videoData.poll_id,
      expire_date: formatDateToISO(videoData.expire_date)
    })
  }

  const voterList = async (pollIds) => {
    const platFormData = {
      app_id: app_id?.id,
      poll_id: pollIds
    }
    APICall('post', platFormData, `${EndPoints.voterList}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setVoteList(res?.decryptedData.data)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const voteAdd = (poll_id, optionIndex) => {
    const platFormData = {
      poll_id,
      option: optionIndex
    }
    setLoading(true)
    APICall('post', platFormData, `${EndPoints.addVote}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          // setVotedPolls(res?.decryptedData.data)
          // setPollsDetail(
          //   pollsDetail.map((poll) => (poll.poll_id === poll_id
          //       ? {
          //           ...poll,
          //           options: poll.options.map((option, idx) => (idx === optionIndex
          //               ? {
          //                   ...option,
          //                   is_vote: true
          //                 }
          //               : option))
          //         }
          //       : poll))
          // )
          setTimeout(() => {
            getPollLibraryData(null, pollCurrentPage)
            setLoading(false)
          }, 2000)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  // console.log(votedPolls, 'votedPolls')

  const formatDateToISO = (dateString) => {
    if (!dateString) return null

    const date = new Date(dateString)
    if (isNaN(date)) return null

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  // voter list pagination

  const videosPerPage = 5
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const voteListData = voteList.slice(indexOfFirstVideo, indexOfLastVideo)
  const totalVideos = voteList.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  // poll comment

  const onClickComment = (poll_id) => {
    setSelectedId(poll_id)
    if (!isLoadingComments) {
      setIsLoadingComments(true)
      pollComments(poll_id)
      setCommentSection(true)
    }
  }

  const fetchPollComments = (poll_id, delay = 3000) => {
    setTimeout(() => {
      pollComments(poll_id)
    }, delay)
  }

  const pollComments = (poll_id) => {
    const platFormData = {
      app_id: app_id?.id,
      poll_id
    }
    APICall('post', platFormData, `${EndPoints.pollComment}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const dataComment = res?.decryptedData?.data?.map((d, i) => ({
            id: i + 1,
            text: d.comment,
            poll_id: d.poll_id,
            is_like: d.is_like,
            comment_id: d.comment_id,
            total_like: d.total_like,
            replies: d.reply_data.map((subData, subIndex) => ({
              id: subIndex + 1,
              text: subData.comment,
              reply_id: subData.comment_id,
              total_like: subData.total_like,
              is_like: subData.is_like,
              user: {
                name: subData.user_name,
                profilePic: s3ImageProfile + subData.profile_icon
              }
            })),
            user: {
              name: d.user_name,
              profilePic: s3ImageProfile + d.profile_icon
            }
          }))
          setComments(dataComment)
          setIsLoadingComments(false)
        } else {
          setIsLoadingComments(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsLoadingComments(false)
        toast.error(error?.data?.message)
      })
  }

  const pollAddComment = (comment) => {
    const platFormData = {
      poll_id: selectedId,
      comment
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.pollCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollAddReply = (reply_id, replyText) => {
    const platFormData = {
      poll_id: selectedId,
      comment_id: reply_id,
      comment: replyText
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.pollCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }
  const pollEditComment = (reply_id, comment) => {
    const platFormData = {
      comment_id: reply_id,
      comment
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.pollCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollEditReply = (reply_id, replyId) => {
    const platFormData = {
      comment_id: reply_id,
      comment: replyId
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.pollCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const deletePollComment = (reply_id) => {
    setIsDeleting(true)
    const data = {
      comment_id: reply_id
    }
    APICall('post', data, `${EndPoints.pollCommentDelete}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }
  const deletePollReply = (replyId) => {
    setIsDeleting(true)
    const data = {
      comment_id: replyId
    }
    APICall('post', data, `${EndPoints.pollCommentDelete}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const pollCommentLike = (reply_id) => {
    const platFormData = {
      app_id: app_id?.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.pollCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments(
            comments.map((item) => (item.comment_id === reply_id
                ? { ...item, is_like: !item.is_like }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollReplyLike = (reply_id) => {
    const platFormData = {
      app_id: app_id?.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.pollCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments(
            comments.map((comment) => ({
              ...comment,
              replies: comment.replies.map((reply) => (reply.reply_id === reply_id
                  ? {
                      ...reply,
                      is_like: !reply.is_like,
                      total_like: reply.is_like
                        ? reply.total_like - 1
                        : reply.total_like + 1
                    }
                  : reply))
            }))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleAddComment = (reply, profilePic, name) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        poll_id: selectedId,
        total_like: 0,
        replies: [],
        user: {
          name,
          profilePic
        }
      }
      setComments([...comments, newCommentObj])
      setNewComment('')
    }
    pollAddComment(reply)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    pollCommentLike(commentId)
  }

  const handleLikeReply = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, total_like: comment.total_like + 1 }
      }
      return comment
    })
    setComments(updatedComments)
    pollReplyLike(commentId)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [
            ...comment.replies,
            {
              id: comment.replies.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: userData.user_name,
                profilePic: s3ImageProfile + userData.profile_image
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    pollAddReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: comment.replies.filter((reply) => reply.id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    deletePollReply(replyId)
  }

  const toggleReplies = (commentId, forceCloseAll = false) => {
    if (forceCloseAll) {
      setShowReplies({})
    } else {
      setShowReplies((prevState) => ({
        // ...prevState,
        [commentId]: !prevState[commentId]
      }))
    }
    setShowReplied('')
  }

  const handleCommentCancel = () => {
    getPollLibraryData(null, pollCurrentPage)
    setCommentSection(false)
    commentClear()
    toggleReplies(null, true)
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? { ...comment, text: editedCommentText }
        : comment))
    pollEditComment(commentId, reply)
    setComments(updatedComments)
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? {
            ...comment,
            replies: comment.replies.map((reply) => (reply.id === replyId ? { ...reply, text: editedReplyText } : reply))
          }
        : comment))
    pollEditReply(commentId, replyId)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  const commentPerPage = 5
  const indexOfLastComment = currentCommentPage * commentPerPage
  const indexOfFirstComment = indexOfLastComment - commentPerPage
  const commentsList = comments.slice(indexOfFirstComment, indexOfLastComment)
  const totalComment = comments.length
  const totalCommentPages = Math.ceil(totalComment / commentPerPage)

  const handlePageCommentChange = (pageNumber) => {
    setCurrentCommentPage(pageNumber)
  }

  useEffect(() => {
    if (currentCommentPage > totalCommentPages) {
      setCurrentCommentPage(totalCommentPages > 0 ? totalCommentPages : 1)
    }
  }, [totalCommentPages, currentCommentPage])

  useEffect(() => {
    getPollLibraryData(null, pollCurrentPage)
  }, [])

  useEffect(() => {
    if (ShowPollModel || ShowPollPreview) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [ShowPollModel, ShowPollPreview])

  const pollHandlePageChange = (pageNumber) => {
    getPollLibraryData(null, pageNumber)
    setPollCurrentPage(pageNumber)
  }

  const pollExpire = async (pollIds) => {
    const platFormData = {
      poll_id: pollIds
    }
    APICall('post', platFormData, `${EndPoints.expirePoll}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          // setVoteList(res?.decryptedData.data)
          setPollsDetail(
            pollsDetail.map((item) => (item.poll_id === pollIds
                ? {
                    ...item,
                    is_expire: !item.is_expire
                  }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }
  return (
    <div className="content-manager-polls-wrapper">
      <div className="sticky_bar !pb-[2px] z-10 bg-white">
        <ContentMangerCommonTitle
          title={English.G195}
          buttonName={pollsDetail?.length === 0 ? null : English.A211}
          onButtonClick={pollsDetail?.length === 0 ? null : ShowCommunityGiveaway}
          className="!mb-0"
        />
        <div className="add-video-row">
          <div className="items-filter justify-between w-full">
            <div className="position-relative input-position">
              <CommonInput
                placeholder="Search Poll"
                className="!text-black"
                onChange={(e) => {
                handleSearch(e.target.value)
              }}
                value={searchText}
              />
              {searchText && (
              <button
                type="button"
                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-black"
                onClick={clearSearch}
              >
                <X className="w-5 h-5" />
              </button>
            )}
            </div>
            <div className="flex flex-row gap-2">
              <div className="drop-row !mb-0 gap-4">
                <div className="drop-sub-row">
                  <Dropdown
                    title="Categories"
                    Data={[
                    { title: 'Scaloo' },
                    { title: 'Spacure' },
                    { title: 'PETZINO' },
                    { title: 'A-Z FIT' },
                    { title: 'TESTIEE' }
                  ]}
                    customClass="content-filter disabledDropdown"
                  />
                  <Dropdown
                    title="Ratings"
                    Data={[
                    { title: 'Scaloo' },
                    { title: 'Spacure' },
                    { title: 'PETZINO' },
                    { title: 'A-Z FIT' },
                    { title: 'TESTIEE' }
                  ]}
                    customClass="content-filter disabledDropdown"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pollsDetail?.length === 0 && !isLoading && (
        <CommonEmptyData
          image={Images.poll_icon}
          mainTitle={English.B116}
          descriptionText={English.B117}
          onClick={() => ShowCommunityGiveaway()}
          addIcon={Images.plusrounded}
          btnText={English.A211}
          className="!border-solid"
          classNameDescription="font-normal text-[#7A7474] text-[16px]"
        />
      )}

      {isLoading ? (
        <div className="polls-item common-card-body position-relative">
          <div className="">
            <Skeleton width={50} height={25} />
          </div>
          <div className="poll-header">
            <p className="poll_topic">
              <Skeleton width={600} height={30} />
            </p>
            <div className="discussion-group">
              <Skeleton width={25} height={25} />
            </div>
            <div className="discussion-comment">
              <Skeleton width={25} height={25} />
              <Skeleton width={25} />
            </div>
            <div className="discussion-button">
              <button type="button">
                <Skeleton width={10} height={35} />
              </button>
            </div>
          </div>

          <div className="polls-option mb-2">
            <p className="profress-line border-0 shadow-none rounded-0">
              <Skeleton width={850} height={32} />
            </p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
          <div className="polls-option mb-2">
            <p className="profress-line border-0 shadow-none rounded-0">
              <Skeleton width={850} height={32} />
            </p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
          <div className="polls-option mb-2">
            <p className="profress-line border-0 shadow-none rounded-0">
              <Skeleton width={850} height={32} />
            </p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
          <div className="polls-option mb-2">
            <p className="profress-line border-0 shadow-none rounded-0">
              <Skeleton width={850} height={32} />
            </p>
            <div className="discussion-comment count-vote-poll-skeleton">
              <Skeleton circle width={25} height={25} />
              <Skeleton width={50} />
            </div>
          </div>
        </div>
      ) : (
        pollsDetail?.map((item, index) => {
          const totalVotes = item.options.reduce(
            (acc, option) => acc + option.total_votes,
            0
          )

          const optionPercentages = item.options.map((option) => {
            return totalVotes > 0
              ? ((option.total_votes / totalVotes) * 100).toFixed(2)
              : '0.00'
          })

          const expireDate = new Date(item.expire_date)
          const currentDate = new Date()

          const expirationMessage = item.is_expire
            ? 'This poll has expired.'
            : `Poll will expire by ${format(new Date(item.expire_date), 'dd MMM, yyyy')}`
          return (
            <>
              <PollItem
                key={index}
                item={item}
                index={index}
                editBtn
                onClick={() => {
                  onClickEditPoll(item)
                }}
                onClickDelete={() => {
                  setUpdatingIndex(index)
                  ShowConfirm(item.poll_id)
                }}
                expirationMessage={expirationMessage}
                optionPercentages={optionPercentages}
                voteAdd={(poll_id, option_id) => voteAdd(poll_id, option_id)}
                pollExpire={(poll_id) => pollExpire(poll_id)}
              />
              <ConfirmationModal
                isVisible={ShowConfirmModel && updatingIndex === index}
                onConfirm={deletePoll}
                onCancel={handleCancel}
                isLoading={loading}
              />
              {loading && updatingIndex === index && <Loader />}
            </>
          )
        })
      )}
      {pollTotalPages > 1 && (
        <Pagination
          currentPage={pollCurrentPage}
          totalPages={pollTotalPages}
          onPageChange={pollHandlePageChange}
        />
      )}

      {commentSection && (
        <div className="add-item-modal mh-100">
          <div className="add-item-contents comment-container">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="mb-0">{English.G199}</h4>
              <button
                type="button"
                className="secondary-btn-modal"
                onClick={handleCommentCancel}
              >
                <img
                  className="close-btns"
                  src={Images.CloseIco}
                  alt="clock icon"
                />
              </button>
            </div>
            <div className="comment-section">
              {isLoadingComments ? (
                <div>
                  <div className="comments-container">
                    <ul className="comments-list">
                      <li className="comment-item">
                        <button type="button" className="comment-delete">
                          <Skeleton
                            className="rounded-3"
                            width={20}
                            height={20}
                          />
                        </button>
                        <div className="comment-header mb-0 gap-2">
                          <Skeleton circle width={45} height={45} />
                          <span className="user-name">
                            <Skeleton width={150} height={20} />
                          </span>
                        </div>
                        <div className="comment-btns">
                          <button type="button">
                            <Skeleton width={20} height={20} />
                          </button>
                          <Skeleton width={20} height={20} />
                          <button type="button">
                            <Skeleton width={45} height={20} />
                          </button>
                          <button type="button">
                            <Skeleton width={45} height={20} />
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="add-comment">
                    <input
                      type="text"
                      placeholder={English.G151}
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      name="reply"
                    />
                    <button
                      type="button"
                      onClick={() => handleAddComment(newComment)}
                    >
                      {English.G152}
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="comments-container">
                    {commentsList?.length === 0 ? (
                      <div className="no-comments-message">{English.G148}</div>
                    ) : (
                      <ul className="comments-list">
                        {commentsList.map((comment, index) => (
                          <li key={comment.id} className="comment-item">
                            {isDeleting && (
                              <div className="loader-overlay">
                                {commentIndex === index && <Loader />}
                              </div>
                            )}
                            {comment.user.name === userData.user_name && (
                              <button
                                type="button"
                                className="comment-delete"
                                onClick={() => {
                                  setCommentIndex(index)
                                  deletePollComment(comment.comment_id)
                                }}
                              >
                                <img src={Images.Delete} alt="delete icon" />
                              </button>
                            )}
                            <div className="comment-header mb-0">
                              <img
                                src={comment.user.profilePic}
                                alt={`${comment.user.name}'s profile`}
                                className="profile-pic"
                              />
                              <span className="user-name">
                                {comment.user.name}
                              </span>
                            </div>
                            {editCommentId === comment.id ? (
                              <div className="edit-reply">
                                <input
                                  type="text"
                                  value={editedCommentText}
                                  onChange={(e) => setEditedCommentText(e.target.value)}
                                />
                                <button
                                  type="button"
                                  onClick={() => handleSaveComment(
                                      comment.comment_id,
                                      editedCommentText
                                    )}
                                >
                                  {English.G117}
                                </button>
                              </div>
                            ) : (
                              <div className="comment-text">{comment.text}</div>
                            )}
                            <div className="comment-btns">
                              <button
                                type="button"
                                onClick={() => handleLikeComment(comment.comment_id)}
                              >
                                {comment.is_like ? (
                                  <img src={Images.Likes} alt="Unlike" />
                                ) : (
                                  <img src={Images.UnLikes} alt="Like" />
                                )}
                                {' '}
                                {comment.total_like}
                              </button>
                              {comment.user.name === userData.user_name && (
                                <button
                                  type="button"
                                  onClick={() => handleEditComment(comment.id, comment.text)}
                                >
                                  {English.G81}
                                </button>
                              )}
                              <button
                                type="button"
                                onClick={() => toggleReplies(comment.id)}
                              >
                                {showReplies[comment.id]
                                  ? 'Hide Replies'
                                  : `Show Replies (${comment?.replies?.length})`}
                              </button>
                            </div>
                            {showReplies[comment.id] && (
                              <ul className="replies-list">
                                {comment.replies.map((reply) => (
                                  <li key={reply.id} className="reply-item">
                                    <div className="reply-header mb-0">
                                      <img
                                        src={reply.user.profilePic}
                                        alt={`${reply.user.name}'s profile`}
                                        className="profile-pic"
                                      />
                                      <span className="user-name">
                                        {reply.user.name}
                                      </span>
                                      {reply.user.name ===
                                        userData.user_name && (
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setCommentIndex(index)
                                            handleDeleteReply(
                                              comment.reply_id,
                                              reply.reply_id
                                            )
                                          }}
                                          className="comment-delete"
                                        >
                                          <img
                                            src={Images.Delete}
                                            alt="delete icon"
                                          />
                                        </button>
                                      )}
                                    </div>
                                    {editReplyId === reply.id ? (
                                      <div className="edit-reply">
                                        <input
                                          type="text"
                                          value={editedReplyText}
                                          onChange={(e) => setEditedReplyText(e.target.value)}
                                        />
                                        <button
                                          type="button"
                                          onClick={() => handleSaveReply(
                                              reply.reply_id,
                                              editedReplyText
                                            )}
                                        >
                                          {English.G117}
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="reply-text">
                                        {reply.text}
                                      </div>
                                    )}
                                    <div className="comment-btns">
                                      <button
                                        type="button"
                                        onClick={() => handleLikeReply(reply.reply_id)}
                                      >
                                        {reply.is_like ? (
                                          <img
                                            src={Images.Likes}
                                            alt="Unlike"
                                          />
                                        ) : (
                                          <img
                                            src={Images.UnLikes}
                                            alt="Like"
                                          />
                                        )}
                                        {' '}
                                        {reply.total_like}
                                      </button>
                                      {reply.user.name ===
                                        userData.user_name && (
                                        <button
                                          type="button"
                                          onClick={() => handleEditReply(
                                              reply.id,
                                              reply.text
                                            )}
                                        >
                                          {English.G81}
                                        </button>
                                      )}
                                    </div>
                                  </li>
                                ))}
                                <li className="add-reply">
                                  <input
                                    type="text"
                                    placeholder={English.G149}
                                    value={Replied}
                                    onChange={(e) => setShowReplied(e.target.value)}
                                  />
                                  <button
                                    type="button"
                                    onClick={() => handleAddReply(
                                        comment.comment_id,
                                        Replied
                                      )}
                                  >
                                    {English.G150}
                                  </button>
                                </li>
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={currentCommentPage}
                      totalPages={totalCommentPages}
                      onPageChange={handlePageCommentChange}
                    />
                  )}
                  <div className="add-comment">
                    <input
                      type="text"
                      placeholder={English.G151}
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      name="reply"
                    />
                    {commentsList?.length === 0 && (
                      <button
                        type="button"
                        className="comment_add_btn"
                        onClick={() => handleAddComment(newComment, '', '')}
                      >
                        {English.G152}
                      </button>
                    )}
                    {commentsList?.map((comment, i) => (
                      <button
                        key={i}
                        type="button"
                        onClick={() => handleAddComment(
                            newComment,
                            comment.user.profilePic,
                            comment.user.name
                          )}
                      >
                        {English.G152}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {ShowPollModel && (
        <PollAddModal
          handleCancel={handleCancelModal}
          editData={editData}
          onSubmit={() => {
            clearValue()
            getPollLibraryData(null, pollCurrentPage)
          }}
        />
      )}
    </div>
  )
}

export default PollTab

import React, { useState } from 'react'
import '../Dashboard.css'
import 'react-tabs/style/react-tabs.css'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import MainCart from '../MainChart'
import { English, Images } from '../../../helpers'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import MyProfile from '../CreatorDashboard/MyProfile'
import Wallet from '../CreatorDashboard/Wallet'
import Campaigns from '../CreatorDashboard/Campaigns'
import Activity from '../Activity'
import LetestContent from '../LetestContent'
import Insights from '../Insights'

const BusinessDashboard = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [visibleTasks, setVisibleTasks] = useState(4)

  const handleShowMoreTask = () => {
    setVisibleTasks((prevVisibleTasks) => prevVisibleTasks + 3)
  }
  return (
    <div className="">
      <div className="flex w-100 gap-4 mb-5">
        <div className="w-[70%] h-[367px]">
          <div className="bg-inactive flex-row bg-white rounded-4 !p-[20px] shadow-sm">
            <MyProfile />
          </div>
        </div>
        <div className="w-[30%] h-[367px]">
          <Wallet text="business" />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 mb-4">
        <div className="local-manager-item">
          <div className="icon_local">
            <img src={Images.business_visitor} alt="visitor" />
          </div>
          <div className="local-manager-item-info">
            <p className="italic font-black !text-lg text-[#0B0A0A]">
              {English.G299}
            </p>
            <span className="flex justify-between">
              0
              <div className="flex gap-2">
                <img src={Images.UpPath} alt="" />
                <span className="positive-trend">0%</span>
              </div>
            </span>
          </div>
        </div>
        <div className="local-manager-item">
          <div className="icon_local">
            <img src={Images.business_order} alt="impression" />
          </div>
          <div className="local-manager-item-info">
            <p className="italic font-black !text-lg text-[#0B0A0A]">
              {English.A247}
            </p>
            <span className="flex justify-between">
              0
              <div className="flex gap-2">
                <img src={Images.UpPath} alt="" />
                <span className="positive-trend">0%</span>
              </div>
            </span>
          </div>
        </div>
        <div className="local-manager-item">
          <div className="icon_local">
            <img src={Images.business_conversion} alt="Clicks" />
          </div>
          <div className="local-manager-item-info">
            <p className="italic font-black !text-lg text-[#0B0A0A]">
              {English.G301}
            </p>
            <span className="flex justify-between">
              0
              <div className="flex gap-2">
                <img src={Images.UpPath} alt="" />
                <span className="positive-trend">0%</span>
              </div>
            </span>
          </div>
        </div>
        <div className="local-manager-item">
          <div className="icon_local">
            <img src={Images.business_reveue} alt="request" />
          </div>
          <div className="local-manager-item-info">
            <p className="italic font-black !text-lg text-[#0B0A0A]">
              {English.G302}
            </p>
            <span className="flex justify-between">
              0
              <div className="flex gap-2">
                <img src={Images.DownPath} alt="" />
                <span className="negetive-trend">0%</span>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="flex w-100 gap-4">
        <div className="w-[70%] flex flex-col gap-4">
          <div className="chart bg-inactive flex-row bg-white rounded-4 !p-[20px] shadow-sm">
            <div className="flex justify-between items-center mb-3">
              <div className="text-gradient font-black pr-1 text-2xl italic uppercase">
                {English.G252}
              </div>
              <CommonButton
                label={English.A141}
                type="button"
                // onClick={createProfileClick}
                className="primary-btns w-auto"
                context="primary-btn"
                isPrimary
              />
            </div>
            <MainCart />
          </div>
          <div className="tab-dashboard">
            <Tabs>
              <TabList>
                <Tab disabled>
                  <img src={Images.ActivityIco} alt="" />
                  <span>{English.G249}</span>
                </Tab>
                <Tab disabled>
                  <img src={Images.CampaignIco} alt="" />
                  <span>{English.G250}</span>
                </Tab>
                <Tab disabled>
                  <img src={Images.ContentIco} alt="" />
                  <span>{English.G251}</span>
                </Tab>
                <Tab disabled>
                  <img src={Images.InsightIco} alt="" />
                  <span>{English.G252}</span>
                </Tab>
              </TabList>

              <TabPanel>
                <Activity />
              </TabPanel>
              <TabPanel>
                <Campaigns />
              </TabPanel>
              <TabPanel>
                <LetestContent />
              </TabPanel>
              <TabPanel>
                <Insights />
              </TabPanel>
            </Tabs>
          </div>
        </div>
        <div className="w-[30%]">
          <div className="tasks">
            <div className="tasks-head">
              <h3>Tasks</h3>
              <span>
                {startDate.toLocaleDateString([], {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </span>
            </div>
            <div className="tasks-celender text-center">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                inline
              />
            </div>
            <div className="tasls-list">
              {visibleTasks >= 1 && (
                <div className="tasks-list-item">
                  <div
                    className="tasks-profile"
                    style={{
                      background: 'url("./images/task-profile1.png")',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  />
                  <div className="about-tasks">
                    <h5>Darrell Steward</h5>
                    <p>
                      Lorem ipsum dolor sit amet, conse adipiscing elit
                      adipiscing.
                    </p>
                    <ul>
                      <li>
                        Tasks
                        {' '}
                        <span>. 2</span>
                      </li>
                      <li>
                        Hours
                        {' '}
                        <span>. 4</span>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              {visibleTasks >= 2 && (
                <div className="tasks-list-item">
                  <div
                    className="tasks-profile"
                    style={{
                      background: 'url("./images/task-profile2.png")',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  />
                  <div className="about-tasks">
                    <h5>Robert Fox</h5>
                    <p>
                      Lorem ipsum dolor sit amet, conse adipiscing elit
                      adipiscing.
                    </p>
                    <ul>
                      <li>
                        Tasks
                        {' '}
                        <span>. 2</span>
                      </li>
                      <li>
                        Hours
                        {' '}
                        <span>. 4</span>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              {visibleTasks >= 3 && (
                <div className="tasks-list-item">
                  <div
                    className="tasks-profile"
                    style={{
                      background: 'url("./images/task-profile3.png")',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  />
                  <div className="about-tasks">
                    <h5>Jane Cooper</h5>
                    <p>
                      Lorem ipsum dolor sit amet, conse adipiscing elit
                      adipiscing.
                    </p>
                    <ul>
                      <li>
                        Tasks
                        {' '}
                        <span>. 2</span>
                      </li>
                      <li>
                        Hours
                        {' '}
                        <span>. 4</span>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              {visibleTasks >= 4 && (
                <div className="tasks-list-item">
                  <div
                    className="tasks-profile"
                    style={{
                      background: 'url("./images/task-profile4.png")',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  />
                  <div className="about-tasks">
                    <h5>Cameron Williamson</h5>
                    <p>
                      Lorem ipsum dolor sit amet, conse adipiscing elit
                      adipiscing.
                    </p>
                    <ul>
                      <li>
                        Tasks
                        {' '}
                        <span>. 2</span>
                      </li>
                      <li>
                        Hours
                        {' '}
                        <span>. 4</span>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              <div className="collapse-button text-center mt-3">
                <button type="button" onClick={handleShowMoreTask}>
                  <img src={Images.DownIco} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default BusinessDashboard

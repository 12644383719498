import React from 'react'
import { capitalizeFirstLetter } from '../../helpers/Utility'
import { English } from '../../helpers'
import CommonInput from '../InputComponet/CommonInput'
import CommonModalMain from '../CommonModalMain'
import CommonDatePicker from '../CommonDatePicker/CommonDatePicker'
import Dropdown from '../Dropdown/Dropdown'

const AddPollModalOne = ({
    HandleCancel,
    HandlePollsPreview,
    isFormComplete,
    handleChange,
    inputFields,
    getCategoryName,
    SubCategory,
    setInputFields,
    category,
    getSubCategoryNameById,
    subCategory
}) => {
  return (
    <div className="">
      <CommonModalMain
        showCross
        title={English.A211}
        isPrevious={English.G116}
        isNext={English.B58}
        onPressClose={HandleCancel}
        onPressSave={HandlePollsPreview}
        onPressBack={HandleCancel}
        showFooter
        isButtonDisabled={!isFormComplete}
        currentCount={1}
        totalCount={2}
        showCount={1}
      >
        <div className="w-full h-full flex flex-col gap-5 justify-between overflow-y-auto">
          <div className="p-[10px]">
            <div className="item-details-input">
              <label>{English.A234}</label>
              <CommonInput
                name="topic"
                placeholder={English.A235}
                onChange={handleChange}
                className="mb-3"
                value={capitalizeFirstLetter(inputFields?.topic)}
              />
            </div>
            <div className="poll-dropdown">
              <label className="text-Colormain text-sm mb-[6px]">{English.G124}</label>
              <Dropdown
                title={getCategoryName(inputFields.category_id)}
                onChanged={(d) => {
                    SubCategory({
                      category_id: d.id
                    })
                    setInputFields({
                      ...inputFields,
                      category_id: d.id
                    })
                  }}
                Data={category.map((d) => ({
                    title: d.category_name,
                    id: d.id
                  }))}
                name="category_id"
                customClass={
                    inputFields.category_id
                      ? 'selected-class !mb-[16px] !mt-0'
                      : 'giveaway-dropdown !mb-[16px] !mt-0'
                  }
              />
            </div>
            <div className="poll-dropdown">
              <label className="text-Colormain text-sm mb-[6px]">{English.A236}</label>
              <Dropdown
                title={getSubCategoryNameById(inputFields.sub_category_id)}
                onChanged={(d) => setInputFields({
                      ...inputFields,
                      sub_category_id: d.id
                    })}
                Data={subCategory.map((d) => ({
                    title: d.sub_category_name,
                    id: d.sub_category_id
                  }))}
                name="sub_category_id"
                customClass={
                    inputFields.sub_category_id
                      ? 'selected-class !mb-[16px] !mt-0'
                      : 'giveaway-dropdown !mb-[16px] !mt-0'
                  }
              />
            </div>
            <div className="">
              <div>
                <label className="text-Colormain text-sm mb-[6px]">{English.A233}</label>
                <div className="feed_calender">
                  <div className="calendar-clock">
                    <CommonDatePicker
                      inputFields={inputFields}
                      setInputFields={setInputFields}
                      fieldName="expire_date"
                      placeholder={English.A237}
                      minDate={new Date()}
                      maxDate={
                          new Date(new Date().getFullYear() + 10, 11, 31)
                        }
                      popperPlacement="top"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CommonModalMain>
    </div>
  )
}
export default AddPollModalOne

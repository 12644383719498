/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Images } from '../../helpers'
import { s3ImagePodcast, s3ImageProfile } from '../../services/Service'
import DummyComment from './DummyComment'
import PopularPodcast from './PopularPodcast'
import PodcastAddModal from './Modal/PodcastAddModal'
import { APICall, dataDecrypt, EndPoints } from '../../services'
import { emitter, EventType } from '../../helpers/Emitter'
import BreadCrumbHeader from './SubComponent/BreadCrumbHeader'
import AudioPlaylist from './SubComponent/AudioPlaylist'
import ImageComponent from '../ImageComponent/ImageComponent'

const PodcastDetailPage = () => {
  const location = useLocation()
  const data = location.state
  const userData = useSelector((state) => state.user?.userData)
  const audioRef = useRef(null)
  const progressRef = useRef(null)

  const [isPlaying, setIsPlaying] = useState(false)
  const [volume, setVolume] = useState(1)
  const [progress, setProgress] = useState(0)
  const [duration, setDuration] = useState(0)
  const [isMuted, setIsMuted] = useState(false)
  const [ShowPodcastModel, setShowPodcastModel] = useState(false)
  const [podcast, setPodcast] = useState(false)

  const [editData, setEditData] = useState(null)

  const onClickEdit = (data) => {
    setShowPodcastModel(true)
    setEditData(data)
  }

  const handleBarClick = (e) => {
    const bar = e.currentTarget
    const rect = bar.getBoundingClientRect()
    const offsetX = e.clientX - rect.left
    const newVolume = offsetX / rect.width
    setVolume(parseFloat(newVolume.toFixed(2)))
  }

  const toggleMute = () => {
    audioRef.current.muted = !audioRef.current.muted
    setIsMuted(audioRef.current.muted)
  }

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value)
    setVolume(newVolume)

    if (audioRef.current) {
      audioRef.current.volume = newVolume
    }
  }

  const handleTimeUpdate = () => {
    if (!audioRef.current) return
    const { currentTime } = audioRef.current
    const totalDuration = audioRef.current.duration
    setProgress((currentTime / totalDuration) * 100)
  }

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration)
      audioRef.current.volume = volume
    }
  }

  const handleProgressClick = (e) => {
    if (!audioRef.current || !progressRef.current) return

    const rect = progressRef.current.getBoundingClientRect()
    const clickX = e.clientX - rect.left
    const barWidth = rect.width

    const clickProgress = clickX / barWidth
    const newTime = duration * clickProgress

    audioRef.current.currentTime = newTime
  }

  const formatTime = (time) => {
    const mins = Math.floor(time / 60)
      .toString()
      .padStart(2, '0')
    const secs = Math.floor(time % 60)
      .toString()
      .padStart(2, '0')
    return `${mins}:${secs}`
  }

  const togglePlayPause = () => {
    if (!audioRef.current) return

    if (audioRef.current.paused) {
      audioRef.current.play()
      setIsPlaying(true)
    } else {
      audioRef.current.pause()
      setIsPlaying(false)
    }
  }

  useEffect(() => {
    const audio = audioRef.current
    if (!audio) return

    const handlePlay = () => setIsPlaying(true)
    const handlePause = () => setIsPlaying(false)

    audio.addEventListener('play', handlePlay)
    audio.addEventListener('pause', handlePause)

    return () => {
      audio.removeEventListener('play', handlePlay)
      audio.removeEventListener('pause', handlePause)
    }
  }, [])


  useEffect(() => {
    if (data) {
      podcastParticular()
    }
  }, [data])

  // eslint-disable-next-line no-unused-vars
  const podcastParticular = (app_id) => {
    const platFormData = {
      // app_id: app_id || data.app_data?.[0].id,
      podcast_id: data.podcast_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.particularPodcast}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const PodcastData = dataDecrypt(res.data.data)
          setPodcast(PodcastData[0])
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const onBack = (tabIndex) => {
    window.history.back()
    setTimeout(() => {
      emitter.emit(EventType.contentMangerTabs, { tabIndex })
    }, 100)
  }

  return (
    <div className="content-manager">
      <div className="common-card-body p-20 ">
        <div className="!overflow-auto !h-[calc(100vh-173px)] no-scrollbar">
          <div className="bg-white rounded-[15px] !py-5">
            <BreadCrumbHeader
              isPublish
              link1Lable="Podcast"
              link2Lable={data.title}
              onClickEdit={() => onClickEdit(data)}
              onClickHome={() => onBack(0)}
              onClickLink={() => onBack(3)}
            />
            <div>
              <div className="flex gap-[28px]">
                <div className="w-[300px] h-[300px] max-[1200px]:w-[200px] max-[1200px]:h-[200px]">
                  <ImageComponent
                    className="rounded-2xl w-full h-full object-cover"
                    imageUrl={s3ImagePodcast + podcast?.cover_attachment}
                    key={podcast?.cover_attachment}
                  />
                </div>
                <div className="flex flex-col !gap-5 flex-1">
                  <h3 className="text-[50px] font-black italic pr-1 text-black mb-0">UNLOCK</h3>
                  <h4 className="text-[18px] font-black italic pr-1 text-black mb-0">{podcast?.title}</h4>
                  <div className="flex !gap-5 items-center">
                    <ImageComponent
                      className="rounded-[999px] h-[60px] w-[60px] border border-[##C7D5E0]"
                      imageUrl={s3ImageProfile + userData?.profile_image}
                      key={userData?.profile_image}
                    />
                    <div className="flex flex-col">
                      <p className="mb-2 font-bold text-black text-[16px]">
                        {userData?.full_name}
                        <span className="mb-0 !text-textPrimary font-normal text-[12px] pt-1">{`(@${userData?.user_name})`}</span>
                      </p>
                      <div className="flex items-center gap-2">
                        <div className="bg-dodgerBlue w-36 p-[7px] flex self-start items-center justify-center gap-2 rounded-full">
                          <img src={Images.subscribe_user_star} alt="" />
                          <div className="text-white font-semibold">Subscribe</div>
                        </div>
                        <div className="bg-primaryGradient w-36 flex p-[7px] items-center justify-center gap-2 rounded-full">
                          <img src={Images.subscribe_user_star} alt="" />
                          <div className="text-white font-semibold">Follow+</div>
                        </div>
                        {/* <button type="button">Subscribe</button> */}
                        {/* <button type="button">Follow+</button> */}
                      </div>
                    </div>
                  </div>
                  <p className="mb-0">{podcast?.description}</p>
                </div>
              </div>
            </div>
            <div className="!mt-5">
              <div className="flex justify-between">
                <div className="flex gap-2 items-center">
                  <ImageComponent
                    className="rounded-[5px] h-[79px] w-[79px]"
                    imageUrl={s3ImagePodcast + podcast?.cover_attachment}
                    key={podcast?.cover_attachment}
                  />
                  <div className="flex flex-col">
                    <audio
                      ref={audioRef}
                      src={s3ImagePodcast + podcast?.attachment}
                      onTimeUpdate={handleTimeUpdate}
                      onLoadedMetadata={handleLoadedMetadata}
                    />
                    <h4 className="text-[18px] font-black italic pr-1 text-black">{podcast?.title}</h4>
                    <div className="flex !gap-5 items-center">
                      <img src={Images.previous_btn_new_detail} alt="" />
                      <button
                        type="button"
                        onClick={togglePlayPause}
                        className="rounded-full bg-blue-500 text-white flex items-center justify-center focus:outline-none"
                      >
                        {isPlaying ? (
                          <img src={Images.PodcastPauseIcoNewDetail} alt="" />
                      ) : (
                        <img src={Images.PodcastPlayIcoNewDetail} alt="" />
                      )}
                      </button>
                      <img src={Images.Next_btn_new_detail} alt="" />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-2 w-48">
                    <button
                      onClick={toggleMute}
                      className="bg-gray-200 rounded-full"
                      type="button"
                    >
                      {isMuted ? (
                        <img src={Images.podcast_mute} alt="" />
                  ) : (
                    <img src={Images.podcast_volume} alt="" />
                  )}
                    </button>
                    <div
                      className="relative !w-[140px] h-2 bg-[#E1E2E5] rounded-lg cursor-pointer group"
                      onClick={handleBarClick}
                    >
                      <div
                        className="absolute max-w-full top-0 left-0 h-2 bg-[#1a1f87] rounded-lg"
                        style={{ width: `${volume * 100}%` }}
                      />
                      <input
                        type="range"
                        min={0}
                        max={1}
                        step={0.01}
                        value={volume}
                        onChange={handleVolumeChange}
                        className="absolute !w-full top-0 left-0 h-3 opacity-0 cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center !gap-5">
                <div className="mt-3 flex flex-1 flex-col">
                  <div
                    ref={progressRef}
                    onClick={handleProgressClick}
                    className="relative w-full h-2 bg-[#E1E2E5] rounded cursor-pointer mb-1"
                  >
                    <div
                      className="absolute top-0 left-0 h-2 bg-[#1a1f87] rounded"
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                  <div className="flex justify-between text-xs text-gray-500">
                    <span>{formatTime(audioRef.current?.currentTime || 0)}</span>
                    <span>{formatTime(duration)}</span>
                  </div>
                </div>
                <h3 className="text-base font-medium text-Main">0 listeners</h3>
              </div>
            </div>
          </div>
          <div>
            <AudioPlaylist />
          </div>
          <div>
            <DummyComment />
          </div>
          <div>
            <PopularPodcast />
          </div>
          {ShowPodcastModel && (
          <PodcastAddModal
            handleCancel={() => setShowPodcastModel(false)}
            editData={editData}
            onSubmit={(podcast_id, app_data) => {
                podcastParticular(app_data[0].id)
                setShowPodcastModel(false)
              }}
          />
          )}
        </div>
      </div>
    </div>
  )
}
export default PodcastDetailPage

import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { English, Images } from '../../../helpers'
import CommonButton from '../../ButtonComponent/ButtonCommon'

const PollItem = ({
  item,
  index,
  onClick,
  onClickDelete,
  expirationMessage,
  optionPercentages,
  voteAdd,
  pollExpire,
  text
}) => {
  const [expandedIndex, setExpandedIndex] = useState(null)
  const [showResults, setShowResults] = useState(true)

//   const handleItemClick = (videoData) => {
//     console.log('asdasdasdasdas', videoData)
//     itemClick()
//   }


  const onClickEditPoll = () => {
    onClick()
  }
  const ShowConfirm = () => {
    // setUpdatingIndex()
    onClickDelete()
  }

  const toggleResults = () => {
    setShowResults(!showResults)
  }

  const resultsVisible = text === 'contentManagerOverview' ? showResults : true

  return (
    <div
      className="polls-item common-card-body position-relative"
      key={item.tag}
    >
      <div className="flex items-center justify-between">
        <div className="bg-BorderLight rounded-[16px] px-2.5 py-1">
          <span className="bg-primaryGradient bg-clip-text text-transparent text-[12px] font-medium">Web Design</span>
        </div>
        <div className="flex items-center gap-3 pr-3">
          <div>
            {item?.app_data?.length > 0 && (
              <div className="profile-tag tag-local position-relative">
                <span>{English.G274}</span>
              </div>
            )}
          </div>
          <div>
            <div className="discussion-comment">
              <img
                className="poll_comment_icon"
                src={Images.comments_icon}
                alt=""
              />
              {item.total_comments}
            </div>
          </div>
          <div onClick={() => onClickEditPoll(item)}>
            <img src={Images.EditIco} alt="" />
          </div>
        </div>
      </div>
      <div className="poll-header">
        <p className="poll_topic">
          {expandedIndex === index
            ? item?.topic
            : `${item?.topic?.slice(0, 130)}`}
          {item?.topic?.length > 130 && (
            <span
              className="see-more"
              onClick={(e) => {
                e.stopPropagation()
                setExpandedIndex(expandedIndex === index ? null : index)
              }}
            >
              {expandedIndex === index ? English.G145 : English.G146}
            </span>
          )}
        </p>
      </div>

      {item.options
        .filter((option) => option !== '')
        .map((option, optIndex) => {
          const percentage = optionPercentages[optIndex] || 0

          return (
            <div
              key={optIndex}
              onClick={() => {
                const anyOptionVoted = item.options.some(
                  (opt) => opt.is_vote === true
                )
                if (anyOptionVoted) {
                  toast.error('You have already voted for this poll.')
                } else if (item.is_expire) {
                  toast.error('This poll has expired')
                } else {
                  voteAdd(item.poll_id, option.id)
                }
              }}
              className="mb-[14px] cursor-pointer"
            >
              <div className="relative w-full h-12 bg-[#FAFAFA] rounded-3xl border border-gray-300 overflow-hidden">
                {resultsVisible && (
                  <div
                    className="absolute top-0 left-0 h-full rounded-xl transition-all duration-500 bg-[#E1E2E5]"
                    style={{
                      width: `${percentage}%`
                    }}
                  />
                )}
                <div className="relative flex justify-between items-center h-full px-3">
                  <div className="flex items-center gap-2">
                    <div
                      className={`w-5 h-5 flex items-center justify-center rounded-full border-2 ${
                        option.is_vote === true
                          ? 'border-blue-600'
                          : 'border-gray-400'
                      }`}
                    >
                      {option.is_vote === true && (
                        <div className="w-3 h-3 bg-[#0B0A0A] rounded-full" />
                      )}
                    </div>

                    <span className="text-[#0B0A0A] text-[14px] font-semibold">
                      {option.option}
                    </span>
                  </div>

                  {resultsVisible && (
                    <div className="flex items-center gap-2">
                      <span className="text-[#7A7474] text-[14px] font-normal">
                        {option.total_votes}
                        {' '}
                        votes
                      </span>
                      <img
                        src={Images.Group_discussion_white}
                        alt="Group Discussion"
                        className="w-6 h-6"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        })}

      <div className="flex items-center justify-between">
        <p className="expiration-message mb-0 text-base text-textPrimary">{expirationMessage}</p>
        {text === 'contentManagerOverview' ?
          <div className="flex items-center gap-2">
            <CommonButton
              label={resultsVisible ? 'HIDE RESULT' : 'SHOW RESULT'}
              onClick={toggleResults}
              isPrimary={false}
              context={English.A197}
              className="!py-2.5"
            //   disabled
            />
            <CommonButton
              label="Vote"
              type="button"
              isPrimary
              onClick={() => onClickEditPoll(item)}
              context={English.A197}
              className="!w-[150px] !px-0 !py-2.5"
            />
          </div> :
          <div className="flex items-center gap-2">
            <CommonButton
              label={English.A260}
              isPrimary={false}
              context={English.A197}
              onClick={() => pollExpire(item.poll_id)}
              className="!py-2.5"
              disabled={item?.is_expire}
            />
            <CommonButton
              label={English.A261}
              type="button"
              isPrimary
              onClick={() => onClickEditPoll(item)}
              context={English.A197}
              className="!w-[150px] !px-0 !py-2.5"
            />
          </div>}
      </div>
      <div className="image-buttons z-1">
        {/* <label>
                  <button
                    type="button"
                    onClick={() => onClickEditPoll(item)}
                    className="d-none"
                  />
                </label> */}
        <button
          type="button"
          className="p-0"
          onClick={() => {
            ShowConfirm(item.poll_id)
          }}
        />
      </div>
    </div>
  )
}

export default PollItem


import React, { useEffect } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { English, Images } from '../../../helpers'
import ContentMangetOverview from './ContentMangetOverview'
import { emitter, EventType } from '../../../helpers/Emitter'
import VideoTab from '../VideoTab'
import PodcastTab from '../PodcastTab'
import GiveawayTab from '../Giveawaytab'
import ShotsTab from '../ShotsTab'
import DiscussionTab from '../DiscussionTab'
import PollTab from '../PollTab'

const ContentManagerMenu = ({
  selectedTab,
  setSelectedTab,
  selectedSubTab,
  setSelectedSubTab
}) => {
  useEffect(() => {
    const event = emitter.addListener(EventType.contentMangerTabs, ({ tabIndex, subTabIndex }) => {
      setSelectedTab(tabIndex)
      setSelectedSubTab(subTabIndex || 0)
    })
    return () => event.remove()
  }, [])


  const CommunityTabsComponent = () => {
    return (
      <Tabs selectedIndex={selectedSubTab} onSelect={setSelectedSubTab}>
        <TabList>
          <Tab>
            <img className="component_icon" src={Images.GiveawayIcon} alt="giveawy icon" />
            <span className="community_tab_text">{English.G230}</span>
          </Tab>
          <Tab>
            <img className="component_icon" src={Images.PollIcon} alt="poll icon" />
            <span className="community_tab_text">{English.G195}</span>
          </Tab>
          <Tab>
            <img className="component_icon" src={Images.DiscussionIcon} alt="discussions icon" />
            <span className="community_tab_text">{English.G207}</span>
          </Tab>
          {/* <Tab>
            <img src={AwardsIco} alt="award icon" />
            Awards
          </Tab> */}
        </TabList>

        <TabPanel>
          <div className="no-scrollbar h-[calc(100vh-253px)] overflow-auto">
            <GiveawayTab />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="no-scrollbar h-[calc(100vh-253px)] overflow-auto">
            <PollTab />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="no-scrollbar h-[calc(100vh-253px)] overflow-auto">
            <DiscussionTab />
          </div>
        </TabPanel>
      </Tabs>
    )
  }

  return (
    <div className="tabs">
      <div className="details-tab-view">
        <Tabs className={`flex flex-col !gap-${selectedTab === 0 ? '5' : '0'}`} selectedIndex={selectedTab} onSelect={setSelectedTab}>
          <TabList>
            <Tab>
              <span>{English.G286}</span>
            </Tab>
            <Tab>
              <span>{English.G225}</span>
            </Tab>
            <Tab>
              <span>{English.G164}</span>
            </Tab>
            <Tab>
              <span>{English.G226}</span>
            </Tab>
            <Tab>
              <span>{English.G439}</span>
            </Tab>
          </TabList>

          <TabPanel>
            <div className="no-scrollbar h-[calc(100vh-304px)] overflow-auto">
              <ContentMangetOverview />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="no-scrollbar h-[calc(100vh-225px)] overflow-auto">
              <VideoTab />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="no-scrollbar h-[calc(100vh-225px)] overflow-auto">
              <ShotsTab />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="no-scrollbar h-[calc(100vh-225px)] overflow-auto">
              <PodcastTab />
            </div>
          </TabPanel>
          {/* <TabPanel>
            <CommunityDetails />
          </TabPanel> */}
          <TabPanel>
            <div className="submenu">
              <CommunityTabsComponent />
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

export default ContentManagerMenu

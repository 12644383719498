import React, { memo, useCallback, useEffect, useRef, useState } from 'react'

import { toast } from 'react-toastify'
import _ from 'lodash'
import { Images } from '../../../helpers'
import { APICall, EndPoints } from '../../../services'
import CommentCard from './CommentCard'
import ReplyInput from './ReplyInput'
import Loader from '../../Loader/Loader'
import EmptyComponent from '../../CommonNoDataModal/EmptyComponent'
import CommonButton from '../../ButtonComponent/ButtonCommon'
// import { onScrollDiv } from '../../../helpers/Utility'

const ShotsMessageBox = ({ onClose, shot_id, onAddMessage, className }) => {
  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(false)
  const [outerComment, setOuterComment] = useState({
    id: 0,
    text: ''
  })
  const [mainTextContent, setMainTextContent] = useState({
    id: 0,
    text: ''
  })
  const [nextUrl, setNextUrl] = useState(null)
  const scrollDivRef = useRef(null)
  const isCalling = useRef(false)

  const onPressSend = useCallback(
    (subCommentType, innerCommentId) => {
      let payload = {}
      if (subCommentType === 'outer') {
        payload = {
          app_id: 1,
          shot_id,
          comment: mainTextContent.text
        }
      } else if (subCommentType === 'comment') {
        payload = {
          app_id: 1,
          shot_id,
          comment_id: innerCommentId,
          comment: outerComment.text
        }
      }
      APICall('post', payload, EndPoints.shotCommentAdd, 'contentManager')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            // toast.success('Comment Added Successfully..')
            if (subCommentType === 'outer') {
              const oldArray = [...comments]
              const newReplyObj = { ...res.decryptedData, reply_data: [] }
              oldArray.push(newReplyObj)
              setComments(_.orderBy(oldArray, 'created_at', 'desc'))
            }
            if (subCommentType === 'comment') {
              const oldArray = [...comments]
              const index = oldArray.findIndex((item) => {
                return item?.comment_id === innerCommentId
              })
              const innerCommentNewReply = { ...res.decryptedData, reply_data: [] }
              oldArray[index] = {
                ...oldArray[index],
                reply_data: _.orderBy(
                  [...oldArray[index].reply_data, innerCommentNewReply],
                  ['created_at'],
                  'desc'
                )
              }
              setComments(oldArray)
            }
            setMainTextContent({ id: 0, text: '' })
            setOuterComment({ id: 0, text: '' })
            onAddMessage()
          } else {
            toast.error(res.data?.message)
          }
        })
        .catch((e) => {
          toast.error(e?.data?.message)
        })
    },
    [comments, mainTextContent.text, onAddMessage, outerComment.text, shot_id]
  )

  const onPressLike = useCallback(
    (id, type, mainCommentId) => {
      const payload = { app_id: 1, comment_id: id }
      APICall('post', payload, EndPoints.shotCommentLike, 'contentManager')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            if (type === 'innerComment' && mainCommentId) {
              const oldArray = [...comments]
              const index = oldArray.findIndex((item) => {
                return item?.comment_id === mainCommentId
              })
              const subCommentOldArray = [...oldArray[index].reply_data]
              const subCommentIndex = oldArray[index].reply_data.findIndex((item) => {
                return item?.comment_id === id
              })
              subCommentOldArray[subCommentIndex] = {
                ...subCommentOldArray[subCommentIndex],
                total_like:
                  subCommentOldArray[subCommentIndex]?.is_like === true
                    ? subCommentOldArray[subCommentIndex].total_like - 1
                    : subCommentOldArray[subCommentIndex].total_like + 1,
                is_like: subCommentOldArray[subCommentIndex]?.is_like !== true
              }
              oldArray[index] = {
                ...oldArray[index],
                reply_data: subCommentOldArray
              }
              setComments(oldArray)
              return
            }
            const oldArray = [...comments]
            const index = oldArray.findIndex((item) => {
              return item?.comment_id === id
            })
            oldArray[index] = {
              ...oldArray[index],
              total_like:
                oldArray[index]?.is_like === true
                  ? oldArray[index].total_like - 1
                  : oldArray[index].total_like + 1,
              is_like: oldArray[index]?.is_like !== true
            }
            setComments(oldArray)
          } else {
            toast.error(res.data?.message)
          }
        })
        .catch((e) => {
          toast.error(e?.data?.message)
        })
    },
    [comments]
  )

  const getComments = useCallback(() => {
    // isCalling.current = true
    if (isCalling.current) return
    isCalling.current = true
    setLoading(true)
    const payload = {
      app_id: 1,
      shot_id
    }
    APICall(
      'post',
      payload,
      nextUrl !== null ? nextUrl : EndPoints.shotComment,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments((data) => [...data, ...res.decryptedData.data])
          setNextUrl(res?.decryptedData?.next_page_url)
        } else {
          toast.error(res.data?.message)
        }
        setLoading(false)
        isCalling.current = false
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e?.data?.message)
        isCalling.current = false
      })
  }, [nextUrl, shot_id])

  useEffect(() => {
    setLoading(true)
    getComments()
  }, [])

  return (
    <div
      className={`bg-white rounded-[14px] absolute w-[calc(40%-40px)] h-[95%]  top-1/2 -translate-y-1/2 right-5  px-[14px] pb-[14px] z-50 ${className}`}
    >
      {!loading && (
        <div className="flex items-center justify-between w-full py-4 bg-white">
          <h3 className="mb-0 italic text-[16px] text-baseColor font-black">
            {comments?.length > 1
              ? `Comments (${comments?.length})`
              : `Comment (${comments?.length})`}
          </h3>
          <img
            src={Images.CloseIco}
            alt=""
            loading="lazy"
            onClick={() => onClose(false)}
            className="cursor-pointer w-[17px] h-[17px]"
          />
        </div>
      )}
      <div
        className="comment-main h-[calc(100%-135px)] overflow-y-auto pe-2 flex flex-col gap-6 relative"
        ref={scrollDivRef}
        // onScroll={() => onScrollDiv(scrollDivRef, isCalling, getComments, nextUrl)}
      >
        {loading ? <Loader /> : ''}
        {comments?.length === 0 && !loading && (
          <div className="h-full w-full flex items-center">
            <EmptyComponent />
          </div>
        )}
        {comments?.map((item) => {
          return (
            <div className="flex flex-col gap-0.5" key={item?.comment_id}>
              <CommentCard
                is_like={item?.is_like}
                main_id={item?.comment_id}
                userName={item?.user_name}
                profile_icon={item?.profile_icon}
                total_like={item?.total_like}
                comment={item?.comment}
                onPressSend={() => onPressSend('comment', item?.comment_id)}
                setTextContent={(id, text) => setOuterComment({ id, text })}
                textContent={{ id: item.comment_id, text: outerComment.text }}
                handleCommentLike={(id) => onPressLike(id, 'outerComment')}
                created_at={item?.created_at}
              />
              <div className="px-8">
                {item?.reply_data?.map((innerItem, index) => {
                  return (
                    <CommentCard
                      created_at={innerItem?.created_at}
                      is_like={innerItem?.is_like}
                      main_id={innerItem?.comment_id}
                      userName={innerItem?.user_name}
                      profile_icon={innerItem?.profile_icon}
                      total_like={innerItem?.total_like}
                      comment={innerItem?.comment}
                      onPressSend={() => {}}
                      setTextContent={() => {}}
                      textContent={{ id: 0, text: '' }}
                      handleCommentLike={(id) => onPressLike(id, 'innerComment', item?.comment_id)}
                      key={item.comment_id + index.toString()}
                      isSubReply
                    />
                  )
                })}
              </div>
            </div>
          )
        })}
        {nextUrl && !loading && (
        <div className="flex justify-center mb-4">
          <CommonButton
            type="button"
            label="Load More"
            disabled={false}
            context=""
            className="px-4 py-2 text-sm font-semibold text-white bg-primaryColor !border-none hover:!bg-none !w-[220px] flex gap-2"
            spanClassName="!text-brandText !text-sm !not-italic"
            onClick={getComments}
            imgSrc={Images.load_more_icon}
          />
        </div>
    )}

      </div>
      <div className="absolute bottom-0 left-0 w-full p-[14px] border-t border-secondaryBorder">
        <ReplyInput
          textContent={{
            id: shot_id,
            text: mainTextContent.text
          }}
          setTextContent={(id, text) => setMainTextContent({ id, text })}
          id={shot_id}
          onPressSend={() => onPressSend('outer')}
        />
      </div>
    </div>
  )
}

export default memo(ShotsMessageBox)

/* eslint-disable max-len */
/* eslint-disable no-tabs */
import React from 'react'
import { English, Images } from '../../../helpers'
import CommonButton from '../../ButtonComponent/ButtonCommon'

const BreadCrumbHeader = ({ homeLable, link1Lable, link2Lable, onClickHome, onClickLink, isPublish, onClickEdit }) => {
	return (
  <div
    className="flex justify-between items-center"
		>
    <div
      className="flex items-center !gap-3 link_click"
    >
      {homeLable && <img className="rotate-180" src={Images.breadcrumbAerrow} alt="" />}
      <span
        onClick={() => onClickHome()}
        className="text-[#4E4949] text-base font-medium cursor-pointer"
      >
        {homeLable || 'Home'}
      </span>
      {link1Lable &&
      <>
        <img src={Images.breadcrumbAerrow} alt="" />
        <span
          onClick={() => onClickLink()}
          className="text-[#4E4949] text-base font-medium cursor-pointer"
        >
          {link1Lable}
        </span>
      </>}
      {link2Lable &&
      <>
        <img src={Images.breadcrumbAerrow} alt="" />
        <span
          to=""
          className="bg-primaryGradient bg-clip-text text-transparent text-base font-bold"
        >
          {link2Lable}
        </span>
      </>}
    </div>
    <div className="flex gap-2 items-center">
      {isPublish &&
      <div className="profile-tag tag-local position-relative">
        <span>{English.G274}</span>
      </div>}
      {onClickEdit &&
        <CommonButton
          label={English.G276}
          onClick={() => onClickEdit()}
          isPrimary
          className="!text-base !italic !font-black add_new_content !rounded-[100px] !px-5 !py-2"
        />}
    </div>
  </div>
	)
}

export default BreadCrumbHeader

/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { DatePicker } from 'antd'
import { dataDecrypt } from '../../services/http-services'
import CommonInput from '../InputComponet/CommonInput'
import { emitter, EventType } from '../../helpers/Emitter'
import English from '../../helpers/English'
import { APICall, EndPoints } from '../../services'
import CommonModalMain from '../CommonModalMain'
import Loader from '../Loader/Loader'
import Dropdown from '../Dropdown/Dropdown'
import Utility from '../../helpers/Utility'

const AddDealModal = ({
  setShowProduct,
  setIsProductEdit,
  isProductEdit,
  editData,
  onComplete
}) => {
  const app_id = useSelector((state) => state.user?.app_id)
  const componentRef = useRef()
  const [isEdited, setIsEdited] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [inputFields, setInputFields] = useState(
    isProductEdit
      ? {
          ...editData,
          discount_percentage:
          Utility.calculateDiscountPercentage(editData.regular_price, editData.discount_price)
        }
      : {
          discount_name: '',
          coupon_code: '',
          start_date: '',
          end_date: '',
          discount_type: '',
          discount_type_id: '',
          discount_value: ''
        }
  )

  const [error, setError] = useState({})

  const ProductDataSubmit = () => {
    setLoading(true)
    APICall('post', inputFields, `${EndPoints.addProductLibrary}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            onComplete()
            setIsEdited(false)
            toast.success(res?.data?.message)
            setLoading(false)
            setShowProduct(false)
            setIsProductEdit(false)
          }, 3000)
        } else {
          setIsEdited(false)
          setLoading(false)
          setShowProduct(false)
          setIsProductEdit(false)
          setLoading(false)
           toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const ResetValue = () => {
    setSelectedImages([])
    setInputFields({
      discount_name: '',
      coupon_code: '',
      start_date: '',
      end_date: '',
      discount_type: '',
      discount_type_id: '',
      discount_value: ''
    })
  }

  const handleSubmit = () => {
    if (isFormValid) {
      if (isProductEdit) {
        EditProductLib(inputFields)
      } else {
        ProductDataSubmit()
      }
    }
  }

  const ProductAddCancel = () => {
    setShowProduct(false)
    setIsProductEdit(false)
    ResetValue()
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (value.length === 0) {
      setError({ discount_name: 'please enter name' })
    }
    setInputFields((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const PlatformPreview = () => {
    setShowProduct(false)
    setIsProductEdit(false)
    ResetValue()
  }

  const validateForm = () => {
    const {
      discount_name,
      coupon_code,
      discount_type_id,
      discount_value
    } = inputFields
    console.log(inputFields, startDate, endDate)
    const isValid =
      discount_name &&
      coupon_code &&
      discount_type_id &&
      discount_value &&
      startDate &&
      endDate
    setIsFormValid(isValid)
  }

  useEffect(() => {
    validateForm()
  }, [inputFields, selectedImages, error, isEdited, startDate, endDate])

  const oneProductDetails = (data) => {
    const platFormData = {
      app_id: app_id.id,
      plateform: 'web',
      product_id: data.id
    }
    APICall('post', platFormData, `${EndPoints.productDetail}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const productDetails = dataDecrypt(res.data.data)
          setInputFields(
            {
              ...productDetails,
              discount_percentage:
              Utility.calculateDiscountPercentage(
                productDetails.regular_price,
                productDetails.discount_price
              )
            }
          )
          const Urls = productDetails?.images?.map((i) => i.image)
          setSelectedImages(Urls)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const EditProductLib = (data) => {
    setLoading(true)
    const platFormData = {
      product_libraries_id: data?.id,
      name: data.name,
      regular_price: data?.regular_price,
      discount_price: data?.discount_price || 0,
      discount_percentage: data?.discount_percentage || 0,
      category_id: data?.category_id,
      sub_category_id: data?.sub_category_id,
      product_link: data?.product_link,
      description: data?.description,
      main_image: data?.image,
      image_1: data?.image_1,
      image_2: data?.image_2,
      image_3: data?.image_3
    }
    if (!data?.image) {
      platFormData.main_image = selectedImages[0] ?? ''
    }
    if (!data?.image_1) {
      platFormData.image_1 = selectedImages[1] ?? ''
    }
    if (!data?.image_2) {
      platFormData.image_2 = selectedImages[2] ?? ''
    }
    if (!data?.image_3) {
      platFormData.image_3 = selectedImages[3] ?? ''
    }
    APICall('post', platFormData, `${EndPoints.editProductLibrary}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            onComplete()
            emitter.emit(EventType.ProductLibraryDelete, {
              product_id: data.id,
              action: 'update'
            })
            toast.success(res?.data?.message)
            ResetValue()
            setIsEdited(false)
            setIsProductEdit(false)
            setLoading(false)
            setShowProduct(false)
          }, 3000)
        } else {
          toast.error(res?.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (isProductEdit) {
      oneProductDetails(editData)
    }
  }, [isProductEdit, editData])

  return (
    <CommonModalMain
      showCross
      title={isProductEdit ? English.G138 : English.B74}
      isPrevious={English.G78}
      isNext={isProductEdit ? English.G128 : English.B74}
      onPressClose={ProductAddCancel}
      onPressSave={() => handleSubmit()}
      onPressBack={PlatformPreview}
      showFooter
      isButtonDisabled={!isFormValid}
    >
      {loading && <Loader />}
      <div
        ref={componentRef}
        className="h-full w-full"
      >
        <div className="product-add-image">
          <div
            className="store_scroll_bar_add"
          >
            <form className="item-details-input">
              <div className="name-field">
                <div>
                  <label>{English.G353}</label>
                  <CommonInput
                    name="discount_name"
                    value={inputFields.discount_name}
                    placeholder={English.G353}
                    onChange={handleChange}
                    style={{ textTransform: 'capitalize' }}
                  />
                </div>
              </div>
              <div className="name-field">
                <div>
                  <label>{English.G354}</label>
                  <CommonInput
                    name="coupon_code"
                    value={inputFields.coupon_code}
                    placeholder={English.G354}
                    onChange={handleChange}
                    style={{ textTransform: 'capitalize' }}
                  />
                </div>
              </div>
              <div className="input-grid mb-4">
                <div className="flex flex-1 flex-col">
                  <label>{English.B85}</label>
                  <DatePicker
                    onChange={(date) => {
                      inputFields.start_date = date
                      setStartDate(date)
                    }}
                    value={startDate || inputFields?.start_date}
                    disabledDate={(current) => inputFields?.end_date && current.isAfter(inputFields?.end_date)}
                    format="YYYY-MM-DD"
                  />
                </div>
                <div className="flex flex-1 flex-col">
                  <label>{English.B86}</label>
                  <DatePicker
                    onChange={(date) => {
                      inputFields.end_date = date
                      setEndDate(date)
                    }}
                    disabledDate={(current) => inputFields?.start_date && current.isBefore(inputFields?.start_date)}
                    value={endDate || inputFields?.end_date}
                    format="YYYY-MM-DD"
                  />
                </div>
              </div>
              <div className="input-grid !grid-cols-none">
                <div>
                  <label>{English.B87}</label>
                  <Dropdown
                    title={[{ title: 'Type 1', id: 1 }, { title: 'Type 2', id: 2 }]?.find((cat) => cat?.id === inputFields?.discount_type_id)?.title || 'Select Typee'}
                    onChanged={(d) => {
                      setInputFields({
                        ...inputFields,
                        discount_type_id: d.id,
                        discount_type: d.title
                      })
                    }}
                    textClass="selected-dropdown-text"
                    Data={[{ title: 'Type 1', id: 1 }, { title: 'Type 2', id: 2 }]}
                    name="discount_type"
                    customClass={
                      inputFields.discount_type
                        ? 'selected-class'
                        : 'giveaway-dropdown'
                    }
                  />
                </div>
              </div>
              <div className="name-field">
                <div>
                  <label>{English.B88}</label>
                  <CommonInput
                    name="discount_value"
                    type="number"
                    value={inputFields.discount_value || ''}
                    placeholder={English.B89}
                    onChange={handleChange}
                    onWheel={(e) => e.target.blur()}
                    className={`${error.discount_value ? 'mb-0' : 'mb-2'}`}
                  />
                  {error.discount_value && (
                    <span className="error-message">
                      {error.discount_value}
                    </span>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </CommonModalMain>
  )
}

export default AddDealModal

/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { toast } from 'react-toastify'
import { cloneDeep } from 'lodash'
import { LoadScriptNext } from '@react-google-maps/api'
import ParticularSkeleton from './particularSkeleton'
import RestaurantPopup from './addPopup/restaurantPopup'
import CommonButton from '../ButtonComponent/ButtonCommon'
import { compressImage, dataDecrypt } from '../../services/http-services'
import {
  GOOGLE_MAP_LIBRARIES,
  locationSuggestion,
  s3ImageRestaurant,
  urlSystemIcon
} from '../../services/Service'
import CommonInput from '../InputComponet/CommonInput'
import { normalizeAddress } from '../../helpers/Utility'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import { emitter, EventType } from '../../helpers/Emitter'
import ContactModal from './ContactModal'
import MapComponent from '../MapComponent/MapComponent'
import MenuShow from './RestaurantComponent/MenuShow'
import DiscountShow from './RestaurantComponent/DiscountShow'
import RatingsReviews from './RatingReview'
import LocalDetailGrid from './CommonComponent/LocalDetailGrid'

const RestaurantProfileDetails = ({ restaurantId }) => {
  const [isRestaurantId, setIsRestaurantId] = useState(restaurantId)
  const [isPlatFormModal, setIsPlatFormModal] = useState(false)
  const [isRestaurant, setIsRestaurant] = useState(false)
  const [inputFields, setInputFields] = useState({
    city_id: '',
    contact_name: '',
    contact_designation: '',
    // contact_phone: '',
    contact_profile_pic: '',
    contact_id: '',
    restaurant_id: isRestaurantId
  })

  const [isParticularLoading, setIsParticularLoading] = useState(false)
  const [particularRestaurantDeatils, setParticularRestaurantDeatils] =
    useState([])
  const [appData, setAppData] = useState([])
  const [personDetails, setPersonDetails] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState({})
  const [isShow, setIsShow] = useState(false)
  const [isRestaurantEdit, setIsRestaurantEdit] = useState(false)
  const [expandedIndex, setExpandedIndex] = useState(null)
  const [isAddingNewRestaurant, setIsAddingNewRestaurant] = useState(false)
  const [contactLoading, setContactLoading] = useState(false)
  const [markerPosition, setMarkerPosition] = useState(null)

  const clearValue = () => {
    setInputFields({
      city_id: '',
      contact_name: '',
      contact_designation: '',
      // contact_phone: '',
      contact_profile_pic: '',
      contact_id: ''
    })
  }

  const handleCancels = () => {
    const resetAppData = appData.map((item) => ({
      ...item,
      checked: false
    }))
    setAppData(resetAppData)
    setIsPlatFormModal(false)
  }

  function formatTime(time) {
    return time.slice(0, 5)
  }

  const onClickEdit = (data) => {
    setIsRestaurant(true)
    setIsRestaurantEdit(true)
    setEditData({
      restaurant_id: data?.restaurant_id,
      location_title: data?.location_title,
      name: data?.name,
      address: normalizeAddress(data?.address),
      description: data?.description,
      country_id: data?.country_id,
      province_id: data?.province_id,
      city_id: data?.city_id,
      zip_code: data?.zip_code,
      longitude: data?.longitude,
      latitude: data?.latitude,
      website: data?.website,
      opening_time: formatTime(data?.opening_time),
      closing_time: formatTime(data?.closing_time),
      price_range:
        data?.min_price && data?.max_price
          ? `${data?.min_price} - ${data?.max_price}`
          : '',
      min_price: data?.min_price,
      max_price: data?.max_price,
      logo: data?.logo,
      images: data?.images,
      contact_name1: data?.contact_details?.[0]?.contact_name ?? '',
      contact_name2: data?.contact_details?.[1]?.contact_name ?? '',
      contact_designation1:
        data?.contact_details?.[0]?.contact_designation ?? '',
      contact_designation2:
        data?.contact_details?.[1]?.contact_designation ?? '',
      // contact_phone1: data?.contact_details?.[0]?.contact_phone ?? null,
      // contact_phone2: data?.contact_details?.[1]?.contact_phone ?? null,
      contact_profile_pic: [
        data?.contact_details?.[0]?.contact_profile_pic,
        data?.contact_details?.[1]?.contact_profile_pic
      ]
    })
  }

  const handleCancel = () => {
    setIsRestaurant(false)
    setIsAddingNewRestaurant(false)
  }

  const cancelContact = () => {
    setPersonDetails(false)
  }

  const clearValues = () => {
    setParticularRestaurantDeatils([])
  }

  const onChange = (e) => {
    setInputFields((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const onUploadImage = async (file, fieldName) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'restaurant_contact')

        try {
          const res = await APICall(
            'post',
            coverFormData,
            '',
            'imageUploadLocal',
            true
          )

          if (res.status === 200 && res.data?.code === 200) {
            const decryptedCoverData = dataDecrypt(res.data.data)
            setInputFields((prev) => {
              return {
                ...prev,
                [fieldName]: decryptedCoverData
              }
            })
          } else {
            toast.error(res?.data?.message || 'Error uploading image')
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || 'Upload failed')
        }
      }

      reader.readAsDataURL(file)
    }
  }

  const getApplicationsData = async () => {
    APICall('get', '', EndPoints.applications, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedData = res?.decryptedData
          setAppData(decryptedData)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const RestaurantPublishApi = async (app_id) => {
    const publishState = !app_id
    const data = {
      restaurant_id: isRestaurantId,
      app_id: app_id
        ? [app_id]
        : appData.filter(({ checked }) => checked).map((e) => e.id),
      publish: publishState
    }
    APICall('post', data, `${EndPoints.publishRestaurant}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          getApplicationsData()
          setIsPlatFormModal(false)
          toast.success(res?.data?.message)
        } else {
          toast.error(res?.data?.message)
          setIsPlatFormModal(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const particularRestaurantData = (id, type = null) => {
    setIsParticularLoading(true)
    setIsRestaurantId(id)
    const payload = {
      restaurant_id: id
    }
    APICall('post', payload, `${EndPoints.particularRestaurant}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsShow(true)
          setParticularRestaurantDeatils(res?.decryptedData)
          // setParticularReatroDeatils(true)
          setIsParticularLoading(false)
          getApplicationsData()
          if (type === 'isEdit') {
            onClickEdit(res?.decryptedData[0])
          }
        } else {
          setIsParticularLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsParticularLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const hotelContactEditApi = async () => {
    setContactLoading(true)
    const data = {
      restaurant_id: isRestaurantId,
      contact_name: inputFields.contact_name,
      contact_designation: inputFields.contact_designation,
      // contact_phone: inputFields.contact_phone,
      contact_profile_pic: inputFields.contact_profile_pic,
      contact_id: inputFields.contact_id
    }
    if (isEdit) {
      APICall('post', data, `${EndPoints.restaurantContactEdit}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setTimeout(() => {
              setPersonDetails(false)
              setIsEdit(false)
              clearValue()
              particularRestaurantData(inputFields.restaurant_id)
              toast.success(res?.data?.message)
              setContactLoading(false)
            }, 2500)
          } else {
            setIsEdit(false)
            setContactLoading(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setIsEdit(false)
          setContactLoading(false)
          toast.error(error?.data?.message)
        })
    } else {
      APICall('post', data, `${EndPoints.restaurantContactAdd}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setTimeout(() => {
              setPersonDetails(false)
              particularRestaurantData(isRestaurantId)
              toast.success(res?.data?.message)
              setContactLoading(false)
              clearValue()
            }, 2500)
          } else {
            setContactLoading(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setContactLoading(false)
          toast.error(error?.data?.message)
        })
    }
  }

  const getStoreLink = (website) => {
    if (!website) return undefined
    return website.startsWith('http') ? website : `http://${website}`
  }

  const isStep4Valid = () => {
    return (
      inputFields.contact_profile_pic.length > 0 &&
      inputFields.contact_name &&
      inputFields.contact_designation
      // inputFields.contact_phone.length > 10
    )
  }

  useEffect(() => {
    particularRestaurantData(restaurantId)
  }, [])

  useEffect(() => {
    const event = emitter.addListener(
      EventType.particularRestaurant,
      ({ action }) => {
        if (action === 'edit') {
          particularRestaurantData(restaurantId)
        }
      }
    )
    return () => event.remove()
  }, [particularRestaurantDeatils])

  useEffect(() => {
    if (inputFields.latitude && inputFields.longitude) {
      setMarkerPosition({
        lat: Number(inputFields.latitude),
        lng: Number(inputFields.longitude)
      })
    }
  }, [inputFields.latitude, inputFields.longitude])

  return (
    <div className="">
      <div className="bg-white rounded-[15px] !p-0">
        <div className="!mt-0">
          {isParticularLoading ? (
            <ParticularSkeleton />
          ) : (
            particularRestaurantDeatils.map((data, index) => (
              <div key={index}>
                <LocalDetailGrid data={data} baseImageUrl={s3ImageRestaurant} />
                <div className="grid grid-cols-2 !gap-5">
                  <div>
                    <div className="font-black text-lg italic text-Colormain uppercase">
                      About
                    </div>
                    <div
                      className={`description_link ${expandedIndex === index ? 'expanded' : ''}`}
                    >
                      <p className="text-Colormain text-base mb-2.5">
                        {expandedIndex === index
                          ? data?.description
                          : `${data?.description?.slice(0, 180)}`}
                        {data?.description?.length > 180 && (
                          <span
                            className="see_more_local"
                            onClick={(e) => {
                              e.stopPropagation()
                              setExpandedIndex(
                                expandedIndex === index ? null : index
                              )
                            }}
                          >
                            ...
                            {' '}
                            {expandedIndex === index
                              ? English.G193
                              : English.G194}
                          </span>
                        )}
                      </p>
                      <div className="profile-redirect">
                        <a
                          className="gredient-txt"
                          target="_blank"
                          href={getStoreLink(data.website)}
                          rel="noreferrer"
                        >
                          <img alt="" src={Images.Redirect} />
                          <u className="border-b border-[#001597]">
                            {data.website ? data.website : English.G278}
                          </u>
                        </a>
                      </div>
                    </div>
                    <div
                      className="contact-details mt-4"
                      style={{ width: '560px' }}
                    >
                      <div>
                        <div className="hotel-details row">
                          <div className="col">
                            <h5>{English.G295}</h5>
                            <div className="podcast-profile-detail">
                              <p className="designation">
                                {data.opening_time}
                                -
                                {data.closing_time}
                              </p>
                            </div>
                          </div>
                          <div className="col">
                            <h5>{English.G282}</h5>
                            <div className="podcast-profile-detail">
                              <p className="designation">
                                {English.G283}
                                {' '}
                                {data.min_price}
                                {' '}
                                -
                                {' '}
                                {English.G283}
                                {' '}
                                {data.max_price}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="hotel-details row">
                          <div className="col">
                            <h5>{English.G296}</h5>
                            <div className="podcast-profile-detail">
                              <p className="designation">{data.meal_types}</p>
                            </div>
                          </div>
                          <div className="col">
                            <h5>{English.G297}</h5>
                            <div className="podcast-profile-detail">
                              <p className="designation">{data.cuisines}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group w-full aspect-[1.99/1] sticky top-0 border-1 border-SecondaryBorder rounded-[15px] overflow-hidden">
                      <LoadScriptNext
                        googleMapsApiKey={locationSuggestion}
                        libraries={GOOGLE_MAP_LIBRARIES}
                      >
                        {/* {isParticularHotelData.map((data, index) => ( */}
                        <MapComponent
                          key={index}
                          inputFields={inputFields}
                          setInputFields={setInputFields}
                          // markerPosition={{
                          //   lat: data.latitude,
                          //   lng: data.longitude
                          // }}
                          markerPosition={markerPosition}
                        />
                        {/* ))} */}
                      </LoadScriptNext>
                    </div>
                  </div>
                </div>
                {isPlatFormModal && (
                  <div className="add-item-model">
                    <div className="add-item-content local_type_modal">
                      <div className="type_close mb-2  local_sticky_header">
                        <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                          {English.G277}
                        </div>
                        <button
                          className="cancel_icon"
                          type="button"
                          onClick={handleCancels}
                        >
                          <img src={Images.RemoveLocal} alt="" />
                        </button>
                      </div>
                      <div className="grid grid-cols-4 !gap-5">
                        {appData.map((i, index) => {
                          const isChecked =
                            particularRestaurantDeatils[0]?.app_data?.some(
                              (d) => d.id === i.id
                            )
                          return (
                            <div
                              className="profile-checkbox aspect-[3/2]"
                              key={index}
                            >
                              <img src={urlSystemIcon + i.icon} alt="" />
                              <div className="custom-check-box">
                                <CommonInput
                                  type="checkbox"
                                  value={i.id}
                                  checked={isChecked || i.checked}
                                  onChange={(e) => {
                                    const newCheckboxes = [...appData]
                                    newCheckboxes[index].checked =
                                      e.target.checked
                                    setAppData(newCheckboxes)
                                  }}
                                  className="!min-h-max"
                                />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <div className="local_button_row">
                        <CommonButton
                          label={English.G116}
                          onClick={handleCancels}
                          isPrimary={false}
                          context={English.G232}
                        />
                        <CommonButton
                          label={English.G128}
                          type="button"
                          isPrimary
                          context={English.G232}
                          onClick={() => {
                            const clone = cloneDeep(
                              particularRestaurantDeatils
                            )
                            setParticularRestaurantDeatils(clone)
                            RestaurantPublishApi()
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>

        {isShow && (
          <div className="position-relative local-tabs section-local">
            <Tabs>
              <div className="list_overview">
                <TabList>
                  <Tab><span>{English.G298}</span></Tab>
                  <Tab><span>{English.G288}</span></Tab>
                  <Tab><span>Ratings</span></Tab>
                </TabList>
              </div>
              <TabPanel>
                <MenuShow isRestaurantId={isRestaurantId} />
              </TabPanel>
              <TabPanel>
                <DiscountShow isRestaurantId={isRestaurantId} />
              </TabPanel>
              <TabPanel>
                <RatingsReviews />
              </TabPanel>
            </Tabs>
          </div>
        )}
      </div>

      {personDetails && (
        <ContactModal
          contactLoading={contactLoading}
          cancelContact={cancelContact}
          onUploadImage={onUploadImage}
          inputFields={inputFields}
          s3Image={s3ImageRestaurant}
          onChange={onChange}
          nextClickApiCall={hotelContactEditApi}
          disabled={!isStep4Valid()}
        />
      )}

      {isRestaurant && (
        <RestaurantPopup
          onCancel={handleCancel}
          defaultData={isAddingNewRestaurant ? {} : editData}
          apiSuccess={() => {
            clearValues()
            setIsRestaurant(false)
          }}
          setIsShow={setIsShow}
          editRestaurant={isRestaurantEdit}
        />
      )}
    </div>
  )
}
export default RestaurantProfileDetails

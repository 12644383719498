/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import './RoomAdd.css'
import { toast } from 'react-toastify'
import { s3ImageHotel } from '../../../services/Service'
import Pagination from '../../Pagination/Pagination'
import English from '../../../helpers/English'
import { APICall, EndPoints } from '../../../services'
import { Images } from '../../../helpers'
import CommonInput from '../../InputComponet/CommonInput'
import Dropdown from '../../Dropdown/Dropdown'

const RoomShow = ({ isHotelId }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [roomListData, setRoomListData] = useState([])

  const initialized = useRef()

  const allRoomData = (currentPage) => {
    const payload = {
      hotel_id: isHotelId
    }
    APICall(
      'post',
      payload,
      `${EndPoints.getRoom}?page=${currentPage}`,
      'local'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setRoomListData(res?.decryptedData.data)
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handlePageChange = (pageNumber) => {
    allRoomData(pageNumber)
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      allRoomData(currentPage)
    }
  }, [])

  const renderRoomList = useMemo(() => {
    return (
      <div className="room-lists">
        {roomListData.map((room, index) => {
          return (
            <div
              key={index}
              className="room-card position-relative !rounded-[15px]"
            >
              {/* <div className="discount-badge">{room.discount}</div> */}
              <img
                className="room-card-img !h-[194px] !w-auto !rounded-t-[15px]"
                src={s3ImageHotel + room.images[0]}
                alt={room.name}
                // onClick={() => roomType(room.room_id)}
              />
              <div className="local-room-detail">
                <div className="flex justify-between items-center">
                  <h4>{room.name}</h4>
                  <img
                    className="rotate-180"
                    src={Images.left_arrow_icon}
                    alt=""
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }, [roomListData])

  return (
    <div className="">
      <div className="">
        {roomListData.length === 0 ? (
          <div className="add_room_text">
            <h4>No Available Room</h4>
          </div>
        ) : (
          <div className="local-room-main">
            <div className="d-flex align-items-center justify-content-between mb-[15px]">
              <h3 className="uppercase pe-2 mb-0">{English.A215}</h3>
              <div className="flex items-center gap-2.5">
                <div className="items-filter flex flex-1 justify-end">
                  <div className="position-relative input-position">
                    <CommonInput placeholder="Search Products" className="!w-[300px]" />
                  </div>
                </div>
                <div className="drop-row !mb-0 gap-4">
                  <div className="drop-sub-row">
                    <Dropdown
                      title="Category"
                      Data={[
                        { title: 'Scaloo' },
                        { title: 'Spacure' },
                        { title: 'PETZINO' },
                        { title: 'A-Z FIT' },
                        { title: 'TESTIEE' }
                      ]}
                      customClass="disabledDropdown"
                    />
                    <Dropdown
                      title="Ratings"
                      Data={[
                        { title: 'Scaloo' },
                        { title: 'Spacure' },
                        { title: 'PETZINO' },
                        { title: 'A-Z FIT' },
                        { title: 'TESTIEE' }
                      ]}
                      customClass="disabledDropdown"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="room-list">
              {renderRoomList}
              {totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default RoomShow

import React from 'react'
import ImageComponent from '../../ImageComponent/ImageComponent'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import Utility from '../../../helpers/Utility'
import { Images } from '../../../helpers'

const ChatContent = ({
  profile_icon,
  user_name,
  comment,
  created_at,
  handleLikeComment,
  isLiked,
  likeCount
}) => {
  return (
    <div className="flex gap-2.5 items-center">
      {profile_icon && (
        <ImageComponent
          imageUrl={profile_icon}
          type="profileImage"
          className="rounded-full w-[36px] h-[36px] object-cover border-[1.5px] border-solid border-[var(--light-border)]"
        />
      )}
      <div className="flex items-center justify-between w-[calc(100%-46px)] flex-1">
        <div className="w-11/12">
          <h6 className="font-bold text-sm text-baseBlackColor mb-0">{user_name}</h6>
          <p className="text-xs text-textPrimary break-words mb-0">{comment}</p>
          {created_at && (
            <span className="text-xs text-textMuted">{Utility.calculateDayTime(created_at)}</span>
          )}
        </div>
        <CommonButton
          type="button"
          className="!w-5 !border-none !p-0 !flex !flex-col !gap-2 hover:!bg-transparent"
          label={likeCount?.toString()}
          disabled={false}
          context=""
          onClick={handleLikeComment}
          imgSrc={isLiked ? Images.shot_like_fill_blue : Images.Feed_UnLike}
          spanClassName="!text-textMuted !not-italic"
        />
      </div>
    </div>
  )
}

export default ChatContent

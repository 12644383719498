import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { APICall, EndPoints } from '../../services'
import { Images } from '../../helpers'

const HotTopicDiscussion = () => {
  const [topDiscussion, setTopDiscussion] = useState([])

  const fetchTopDiscussionData = () => {
    const platFormData = {
      app_id: 1
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.topWeekDiscussion}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTopDiscussion(res?.decryptedData?.data)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  useEffect(() => {
    fetchTopDiscussionData()
  }, [])
  return (
    <div className="">
      {topDiscussion.map((data, i) => (
        <div key={i}>
          <div className="flex flex-col m-3">
            <div className="flex justify-between !gap-2 items-center mb-2.5">
              <p className="flex flex-1 text-[14px] text-[#0B0A0A] font-semibold mb-0">{data.topic}</p>
              <div className="flex flex-col gap-1">
                <img className="w-[20px] h-[20px]" src={Images.comments_icon} alt="" />
                <p className="text-[14px] font-normal text-[#7A7474] mx-auto mb-0">{data.total_replies}</p>
              </div>
            </div>
            <div className="border-b border-[#E1E2E5]" />
          </div>
        </div>
      ))}
    </div>
  )
}
export default HotTopicDiscussion

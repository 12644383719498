/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// import { useSelector } from 'react-redux'
import { Skeleton } from 'antd'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import { Images } from '../../helpers'
import ConnectLocationModal from './Component/ConnectLocationModal'
// import AddHotel from '../AddHotel/AddHotel'
import LocalLocationHome from '../Local/LocalLocationHome'
import { emitter, EventType } from '../../helpers/Emitter'
// import AddRestaurant from '../AddRestaurant/AddRestaurant'
// import AddEvent from '../AddEvent/AddEvent'
// import StoreBannerComponent from '../UploadComponent/StoreBannerComponent'

const LocalHomePage = () => {
  const [isLoading, setIsLoading] = useState(true)
  // const app_id = useSelector((state) => state.user?.app_id)
  // const userData = useSelector((state) => state.user?.profileData)
  const navigate = useNavigate()
  const [showConnectModal, setShowConnectModal] = useState(false)
  const [totalLocal, setTotalLocal] = useState(0)
  const [totaPublishLocal, setTotalPublishLocal] = useState(0)
  const [totaPublishHotel, setTotalPublishHotel] = useState(0)
  const [totaPublishRestaurant, setTotalPublishRestaurant] = useState(0)
  const [totaPublishEvent, setTotalPublishEvent] = useState(0)
  useEffect(() => {
    const event = emitter.addListener(EventType.localProfile, (data) => {
      setTotalLocal((data?.total_hotel || 0) + (data?.total_restaurants || 0) + (data?.total_events || 0))
      setTotalPublishLocal((data?.total_publish_hotels || 0) + (data?.total_publish_restaurants || 0) + (data?.total_publish_events || 0))
      setTotalPublishHotel((data?.total_publish_hotels || 0))
      setTotalPublishRestaurant((data?.total_publish_restaurants || 0))
      setTotalPublishEvent((data?.total_publish_events || 0))
      setIsLoading(false)
    })
    return () => event.remove()
  }, [])
  const openLocationManager = () => {
    navigate('/local')
  }
  return (
    <>
      {isLoading ? (
        <div className="self-center flex flex-col gap-3 text-center my-5">
          <Skeleton.Button active size={60} shape block />
          <Skeleton.Input active style={{ width: 500 }} />
          <Skeleton.Input active style={{ width: 300 }} />
          <Skeleton.Button active size={60} shape style={{ width: 300, borderRadius: 150, marginTop: 30 }} />
        </div>
      )
      : totaPublishLocal === 0 && totalLocal > 0 ? (
        <CommonEmptyData
          image={Images.store_connect}
          mainTitle="Locations not connected to profile yet"
          descriptionText="Connect locations to show here"
          onClick={() => setShowConnectModal(true)}
          // addIcon={Images.plusrounded}
          btnText="Connect Locations"
        />
      ) : totalLocal === 0 && (
        <CommonEmptyData
          image={Images.store_setup}
          mainTitle="Location Manager not Setup"
          descriptionText="Manage locations through location manager"
          onClick={openLocationManager}
          // addIcon={Images.plusrounded}
          btnText="Setup Now"
        />
      )}
      {showConnectModal && (
        <ConnectLocationModal
          ShowProductsModel={showConnectModal}
          setShowProductsModel={setShowConnectModal}
          generalProduct={[]}
          productType="trending"
          onSubmited={() => {
            setTimeout(() => {
              emitter.emit(EventType.localConnect, 'true')
            }, 3000)
          }}
        />
      )}
      {totaPublishLocal > 0 &&
      <div>
        <LocalLocationHome singlePublished={totaPublishLocal === 1 ? (totaPublishHotel === 1 ? 'Hotel' : totaPublishEvent === 1 ? 'Event' : totaPublishRestaurant === 1 ? 'Restaurant' : null) : null} onConnectClick={() => setShowConnectModal(true)} />
      </div>}
    </>
  )
}
export default LocalHomePage

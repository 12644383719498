/* eslint-disable no-return-await */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-case-declarations */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import {
  locationSuggestion,
  s3ImageOrganizer
} from '../../../services/Service'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import Loader from '../../Loader/Loader'
import English from '../../../helpers/English'
import Images from '../../../helpers/Images'
import { APICall, EndPoints } from '../../../services'
import { emitter, EventType } from '../../../helpers/Emitter'
import LocationPopup from './LocationPopup'
import DetailsPopup from './DetailsPopup'
import ContactImagePopup from './ContactImagePopup'
import { unlockScroll } from '../../../helpers/Utility'

const eventPopup = ({
  onCancel,
  defaultData = {},
  apiSuccess = () => {},
  setIsShowEventList,
  setIsShow,
  // particularEventApiCall = () => {},
  editEvent,
  isFromOrganizerComponent,
  platformData
}) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const DataTrue = editEvent
  const [isCountryList, setIsCountryList] = useState([])
  const [isProvinceList, setIsProvinceList] = useState([])
  const [isCityListData, setIsCityListData] = useState([])
  const [inputFields, setInputFields] = useState({
    ...defaultData,
    location_title: '',
    name: '',
    description: '',
    address: '',
    city_id: '',
    zip_code: '',
    longitude: '',
    latitude: '',
    website: '',
    logo: '',
    images: [],
    contact_name1: '',
    contact_name2: '',
    contact_designation1: '',
    contact_designation2: '',
    // contact_phone1: '',
    // contact_phone2: '',
    contact_profile_pic: [],
    organizer_id: '',
    country_id: '',
    province_id: '',
    country: '',
    city_name: '',
    province_name: '',
    min_price: '',
    max_price: ''
  })
  const [markerPosition, setMarkerPosition] = useState(null)
  const [error, setError] = useState({
    min_price: '',
    max_price: ''
  })

  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [errors, setErrors] = useState('')
  const [isStep1Modified, setIsStep1Modified] = useState(false)
  const [isStep2Modified, setIsStep2Modified] = useState(false)
  const [isStep3Modified, setIsStep3Modified] = useState(false)
  const [imagesCleared, setImagesCleared] = useState(false)
  const [initialImages, setInitialImages] = useState(defaultData.images || [])
  const isEditMode = Object.keys(defaultData)?.length > 0
  const [isFieldChanged, setIsFieldChanged] = useState(false)
  const [isLoader, setIsLoader] = useState(false)

  const urlRegex =
    /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i

  const clearValue = () => {
    setInputFields({
      name: '',
      location_title: '',
      description: '',
      address: '',
      city_id: '',
      zip_code: '',
      longitude: '',
      latitude: '',
      website: '',
      logo: '',
      images: [],
      contact_name1: '',
      contact_name2: '',
      contact_designation1: '',
      contact_designation2: '',
      contact_phone1: '',
      contact_phone2: '',
      contact_profile_pic: [],
      organizer_id: '',
      country_id: '',
      province_id: '',
      country: '',
      city_name: '',
      province_name: '',
      min_price: '',
      max_price: ''
    })
    setIsFieldChanged(false)
  }

  const handleCancelStep2 = () => {
    onCancel()
  }

  const handleCancels = () => {
    setCurrentStep(0)
    onCancel()
    clearValue()
  }

  const onChange = (e) => {
    const { name, value } = e.target
    const cursorPosition = e.target.selectionStart

    if (name === 'price_range') {
      const cleanedValue = value.replace(/[^0-9-]/g, '')
      const priceParts = cleanedValue.split('-').map((p) => p.trim())
      if (!cleanedValue) {
        setInputFields((prev) => ({
          ...prev,
          price_range: '',
          min_price: '',
          max_price: ''
        }))
        return
      }

      if (priceParts.length === 1) {
        const min = priceParts[0]

        if (!min) {
          setInputFields((prev) => ({
            ...prev,
            price_range: '',
            min_price: ''
          }))
          return
        }

        if (min.length === 4 && !cleanedValue.includes('-')) {
          setInputFields((prev) => ({
            ...prev,
            price_range: `${min} - `,
            min_price: min
          }))
          setTimeout(
            () => e.target.setSelectionRange(min.length + 3, min.length + 3),
            0
          )
        } else {
          setInputFields((prev) => ({
            ...prev,
            price_range: min,
            min_price: min
          }))
        }

        // setTimeout(() => e.target.setSelectionRange(min.length, min.length), 0)
      }

      if (priceParts.length === 2) {
        const min = priceParts[0]
        const max = priceParts[1]

        if (
          cursorPosition === min.length + 3 &&
          e.nativeEvent.inputType === 'deleteContentBackward'
        ) {
          setInputFields((prev) => ({
            ...prev,
            price_range: min,
            min_price: min
          }))

          setTimeout(
            () => e.target.setSelectionRange(min.length, min.length),
            0
          )
          return
        }

        const minPrice = min.slice(0, 4)
        const maxPrice = max.slice(0, 4)

        setInputFields((prev) => ({
          ...prev,
          min_price: minPrice,
          max_price: maxPrice,
          price_range: maxPrice
            ? `${minPrice} - ${maxPrice}`
            : `${minPrice} - `
        }))

        validatePrices(parseFloat(minPrice), parseFloat(maxPrice))

        if (max) {
          setTimeout(
            () => e.target.setSelectionRange(cursorPosition, cursorPosition),
            0
          )
        } else {
          setTimeout(
            () => e.target.setSelectionRange(min.length + 3, min.length + 3),
            0
          )
        }
      }
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value
      }))

      if (name === 'website' && urlRegex.test(value)) {
        setErrors('')
      }
    }
    setIsFieldChanged(true)
  }

  const validatePrices = (minPrice, maxPrice) => {
    if (!isNaN(minPrice) && !isNaN(maxPrice)) {
      if (maxPrice <= minPrice) {
        setError((prev) => ({
          ...prev,
          max_price: English.G410
        }))
      } else {
        setError((prev) => ({
          ...prev,
          max_price: ''
        }))
      }
    }
  }

  const handleKeyDown = (e) => {
    const value = e.target.value.trim()
    if (e.key === 'Enter' && value.length <= 4 && !value.includes('-')) {
      setInputFields((prev) => ({
        ...prev,
        price_range: `${value} - `
      }))
      e.preventDefault()
    }
  }

  const handleOnClick = (e) => {
    const { value } = e.target
    const dashIndex = value.indexOf('-')
    if (dashIndex > -1) {
      const { selectionStart } = e.target
      if (selectionStart <= dashIndex) {
        return
      }
      if (selectionStart > dashIndex + 2) {
        return
      }
      e.target.setSelectionRange(dashIndex + 2, dashIndex + 2)
    }
  }

  const onUpload = async (file, fieldName, index) => {
    try {
      const imageFile = await compressImage(file)
      const coverFormData = new FormData()

      if (fieldName === 'images') {
        const compressedImages = await Promise.all(
          Array.from(file)
            .filter((file) => file instanceof File)
            .map(async (file) => await compressImage(file))
        )
        compressedImages.forEach((imageFile) => {
          coverFormData.append('files[]', imageFile)
        })
      } else {
        coverFormData.append('file', imageFile)
      }

      const apiEndpoint =
        fieldName === 'images' ? EndPoints.uploadFileMultipleLocal : ''
      const apiEndpointBase =
        fieldName === 'images' ? 'local' : 'imageUploadLocal'

      if (fieldName === 'contact_profile_pic') {
        coverFormData.append('type', 'organizer_contact')
      } else if (fieldName === 'logo') {
        coverFormData.append('type', 'organizer_logo')
      } else {
        coverFormData.append('type', 'organizer')
      }

      try {
        const res = await APICall(
          'post',
          coverFormData,
          apiEndpoint,
          apiEndpointBase,
          true
        )
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedImageData = dataDecrypt(res.data.data)
          if (fieldName === 'logo') {
            return decryptedImageData
          }
          if (fieldName === 'contact_profile_pic') {
            return decryptedImageData
          }
          if (fieldName === 'images') {
            return [
              ...decryptedImageData,
              ...file.filter((item) => !(item instanceof File))
            ]
          }
        } else {
          toast.error(res?.data?.message || 'Error uploading image')
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || 'Upload failed')
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const onUploadImage = async (file, fieldName, index) => {
    if (file) {
      setInputFields((prev) => {
        if (fieldName === 'logo') {
          return {
            ...prev,
            [fieldName]: file
          }
        }
        const prevData = prev[fieldName] || []
        const updatedData = [...prevData]

        if (index !== undefined && index !== null && Array.isArray(prevData)) {
          updatedData[index] = file
        } else {
          updatedData.push(file)
        }

        return {
          ...prev,
          [fieldName]: updatedData
        }
      })
      setIsStep3Modified(true)
      setIsFieldChanged(true)
    }
    // if (file) {
    //   const reader = new FileReader()
    //   reader.onload = async () => {
    //     try {
    //       const imageFile = await compressImage(file)
    //       const coverFormData = new FormData()
    //       coverFormData.append('file', imageFile)

    //       if (fieldName === 'contact_profile_pic') {
    //         coverFormData.append('type', 'organizer_contact')
    //       } else if (fieldName === 'logo') {
    //         coverFormData.append('type', 'organizer_logo')
    //       } else {
    //         coverFormData.append('type', 'organizer')
    //       }

    //       try {
    //         const res = await APICall(
    //           'post',
    //           coverFormData,
    //           '',
    //           'imageUploadLocal',
    //           true
    //         )
    //         if (res.status === 200 && res.data?.code === 200) {
    //           const decryptedImageData = dataDecrypt(res.data.data)
    //           setInputFields((prev) => {
    //             if (fieldName === 'logo') {
    //               return {
    //                 ...prev,
    //                 [fieldName]: decryptedImageData
    //               }
    //             }

    //             const prevData = prev[fieldName] || []
    //             const updatedData = [...prevData]

    //             if (
    //               index !== undefined &&
    //               index !== null &&
    //               Array.isArray(prevData)
    //             ) {
    //               updatedData[index] = decryptedImageData
    //             } else {
    //               updatedData.push(decryptedImageData)
    //             }

    //             return {
    //               ...prev,
    //               [fieldName]: updatedData
    //             }
    //           })

    //           setIsStep3Modified(true)
    //           setIsFieldChanged(true)
    //         } else {
    //           toast.error(res?.data?.message || 'Error uploading image')
    //         }
    //       } catch (err) {
    //         toast.error(err?.response?.data?.message || 'Upload failed')
    //       }
    //     } catch (error) {
    //       console.error('Error uploading image:', error)
    //     }
    //   }

    //   reader.readAsDataURL(file)
    //   setIsFieldChanged(true)
    // }
  }

  const handleMapPositionChange = (lat, lng) => {
    setMarkerPosition({ lat, lng })
    setInputFields((prev) => ({
      ...prev,
      latitude: lat,
      longitude: lng
    }))
  }

  const removeImage = (index) => {
    const updatedImages = [...inputFields.images]
    updatedImages.splice(index, 1)
    setInputFields((prev) => ({
      ...prev,
      images: updatedImages
    }))
    setIsStep3Modified(true)
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setInputFields((prev) => {
        const copy = [...prev.images]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        return {
          ...prev,
          images: copy
        }
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {inputFields.images.map((image, index) => {
          return (
            <div
              className={`image position-relative !shadow-none ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {/* {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )} */}
              <img
                className={`image position-relative !shadow-none ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImageOrganizer + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button
                className="image_remove_icon"
                type="button"
                onClick={() => removeImage(index)}
              >
                <img src={Images.RemoveLocal} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    onUploadImage(file, 'images', index)
                  }}
                  selectedImg=""
                  defaultImg={Images.plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [inputFields.images, draggedIndex])

  // api call

  const provinceListData = (data) => {
    APICall('post', data, `${EndPoints.provinceList}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsProvinceList(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const cityListData = (data) => {
    APICall('post', data, `${EndPoints.cityList}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsCityListData(res?.decryptedData.data)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const organizerAddApiCall = (logo, contact_profile_pic, images) => {
    let payload = {
      location_title: inputFields.location_title,
      name: inputFields.name,
      description: inputFields.description,
      address: inputFields.address,
      city_id: inputFields.city_id,
      zip_code: inputFields.zip_code,
      longitude: inputFields.longitude,
      latitude: inputFields.latitude,
      website: inputFields.website,
      min_price: inputFields.min_price,
      max_price: inputFields.max_price,
      logo: logo || inputFields.logo,
      images: images || inputFields.images,
      contact_name: [
        inputFields.contact_name1,
        inputFields.contact_name2
      ].filter((item) => item !== '' && item !== null),
      contact_designation: [
        inputFields.contact_designation1,
        inputFields.contact_designation2
      ].filter((item) => item !== '' && item !== null),
      // contact_phone: [
      //   inputFields.contact_phone1,
      //   inputFields.contact_phone2
      // ].filter((item) => item !== '' && item !== null),
      contact_profile_pic:
        contact_profile_pic || inputFields.contact_profile_pic,
      organizer_id: inputFields.organizer_id,
      ...(isFromOrganizerComponent && { app_id: [platformData[0]] })
    }
    payload = Object.fromEntries(
      Object.entries(payload).filter(
        ([key, value]) => !(
            value === '' ||
            (Array.isArray(value) &&
              (value.length === 0 || value.every((item) => item === '')))
          )
      )
    )

    if (Object.keys(defaultData).length > 0) {
      APICall('post', payload, `${EndPoints.editOrganizer}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            toast.success(res?.data?.message)
            setTimeout(() => {
              emitter.emit(EventType.particularOrganizer, {
                action: 'edit'
              })
              apiSuccess()
              setIsLoader(false)
              setCurrentStep(0)
            }, 3000)
            // particularEventApiCall(inputFields.organizer_id)
            setIsShowEventList(true)
            setIsShow(false)
          } else {
            setIsLoader(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setIsLoader(false)
          toast.error(error?.data?.message)
        })
    } else {
      APICall('post', payload, `${EndPoints.addOrganizer}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            toast.success(res?.data?.message)
            setTimeout(() => {
              emitter.emit(EventType.addOrganizerList, {
                action: 'add'
              })
              apiSuccess()
              if (isFromOrganizerComponent) {
                navigate(`/local-builder/${id}`)
              } else {
                navigate('/local')
              }
              setIsLoader(false)
              setCurrentStep(0)
            }, 3000)
          } else {
            setIsLoader(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setIsLoader(false)
          toast.error(error?.data?.message)
        })
    }
  }

  useEffect(() => {
    if (Object.keys(defaultData).length > 0) {
      setInputFields(defaultData)
      provinceListData({
        country_id: defaultData.country_id
      })
      cityListData({
        province_id: defaultData.province_id
      })
    }
  }, [defaultData])

  const [currentStep, setCurrentStep] = useState(1)

  const handleStartStep2 = () => {
    setErrors('')
    if (inputFields.website && !urlRegex.test(inputFields.website)) {
      setErrors(English.G73)
      return
    }
    if (currentStep === 1 && editEvent) {
      setCurrentStep(2)
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleNextClick = async () => {
    unlockScroll()
    if (currentStep === 2) {
      if (!inputFields.website || !urlRegex.test(inputFields.website)) {
        setErrors(English.G73)
        return
      }
    }
    if (currentStep < 3) {
      if (currentStep === 1 && editEvent) {
        setCurrentStep(2)
      } else {
        setCurrentStep(currentStep + 1)
      }
    } else {
       setIsLoader(true)
      const logo =
        typeof inputFields.logo === 'string'
          ? inputFields.logo
          : await onUpload(inputFields.logo, 'logo', 0)

      const contact_profile_pic = await Promise.all(
        inputFields.contact_profile_pic.map((item, index) => (typeof item === 'string'
            ? item
            : onUpload(item, 'contact_profile_pic', index)))
      )

      const newFile = inputFields.images
      .map((item) => (typeof item !== 'string' ? item : null))
      .filter((item) => item !== null)

    const uploadedImages =
      newFile.length !== 0 ? await onUpload(newFile, 'images') : []

    const images = inputFields.images.map((item) => (typeof item === 'string' ? item : uploadedImages?.shift()))

      organizerAddApiCall(logo, contact_profile_pic, images)
    }
  }

  const handleBackClick = () => {
    if (currentStep > 1) {
      if (currentStep === 3 && editEvent) {
        setCurrentStep(2)
      } else {
        setCurrentStep(currentStep - 1)
      }
    }
  }

  useEffect(() => {
    setIsStep1Modified(
      inputFields.location_title !== defaultData.location_title ||
        inputFields.country_id !== defaultData.country_id ||
        inputFields.province_id !== defaultData.province_id ||
        inputFields.city_id !== defaultData.city_id ||
        inputFields.zip_code !== defaultData.zip_code
    )
    setIsStep2Modified(
      inputFields.name !== defaultData.name ||
        inputFields.logo !== defaultData.logo ||
        inputFields.price_range !== defaultData.price_range ||
        inputFields.min_price !== defaultData.min_price ||
        inputFields.max_price !== defaultData.max_price ||
        inputFields.description !== defaultData.description ||
        inputFields.website !== defaultData.website
    )
    setIsStep3Modified(
      inputFields.contact_profile_pic !== defaultData.contact_profile_pic ||
        inputFields.contact_name1 !== defaultData.contact_name1 ||
        inputFields.contact_name2 !== defaultData.contact_name2 ||
        inputFields.contact_designation1 !== defaultData.contact_designation1 ||
        inputFields.contact_designation2 !== defaultData.contact_designation2 ||
        // inputFields.contact_phone1 !== defaultData.contact_phone1 ||
        // inputFields.contact_phone2 !== defaultData.contact_phone2 ||
        inputFields?.images?.length !== defaultData?.images?.length
    )
  }, [inputFields])

  const isStep1Valid = () => {
    return (
      (!isEditMode ? isStep1Modified : isEditMode) &&
      inputFields.country_id &&
      inputFields.province_id &&
      inputFields.city_id &&
      inputFields.zip_code.length > 4 &&
      markerPosition
    )
  }

  const isStep2Valid = () => {
    return (
      (!isEditMode ? isStep2Modified : isEditMode) &&
      inputFields.logo &&
      inputFields.name &&
      inputFields.price_range &&
      inputFields.website &&
      inputFields.min_price &&
      inputFields.max_price &&
      inputFields.description &&
      error.max_price === ''
    )
  }

  const isStep3Valid = () => {
    const hasImagesChanged =
      JSON.stringify(inputFields.images) !== JSON.stringify(initialImages)

    const isContactNameValid =
      inputFields.contact_name1 || inputFields.contact_name2

    const isContactDesignationValid =
      inputFields.contact_designation1 || inputFields.contact_designation2

    const isProfilePicValid = inputFields.contact_profile_pic.length >= 1

    return (
      isStep3Modified &&
      inputFields.images.length > 0 &&
      hasImagesChanged &&
      (editEvent ||
        (isProfilePicValid && isContactNameValid && isContactDesignationValid))
    )
  }

  useEffect(() => {
    if (inputFields.latitude && inputFields.longitude) {
      setMarkerPosition({
        lat: Number(inputFields.latitude),
        lng: Number(inputFields.longitude)
      })
    }
  }, [inputFields.latitude, inputFields.longitude])

  const clearCurrentStepData = (step) => {
    switch (step) {
      // case 2:
      //   setInputFields({
      //     ...inputFields,
      //     logo: '',
      //     name: '',
      //     price_range: '',
      //     min_price: '',
      //     max_price: '',
      //     description: '',
      //     website: ''
      //   })
      //   break
      case 3:
        setInputFields({
          ...inputFields,
          contact_name1: '',
          contact_designation1: '',
          // contact_phone1: '',
          contact_name2: '',
          contact_designation2: '',
          // contact_phone2: '',
          contact_profile_pic: ['', '']
        })
        const hasImages = inputFields.images?.length > 0
        setInputFields({
          ...inputFields,
          images: []
        })
        if (hasImages) {
          setImagesCleared(true)
        } else {
          organizerAddApiCall()
        }
        break
      default:
        break
    }
  }

  const handleSkipClick = () => {
    unlockScroll()
    if (currentStep >= 2 && currentStep <= 3) {
      clearCurrentStepData(currentStep)
      if (currentStep === 1 && editEvent) {
        setCurrentStep(2)
      } else if (currentStep < 3) {
        setCurrentStep(currentStep + 1)
      }
    }
  }

  useEffect(() => {
    if (imagesCleared) {
      organizerAddApiCall()
      setImagesCleared(false)
    }
  }, [imagesCleared])

  useEffect(() => {
    setInitialImages(defaultData.images || [])
  }, [])

  return (
    <div className="content-manager">
      {currentStep === 1 && (
        <LocationPopup
          inputFields={inputFields}
          setInputFields={setInputFields}
          onCancel={handleCancelStep2}
          onSubmit={handleStartStep2}
          countryList={isCountryList}
          provinceList={isProvinceList}
          cityList={isCityListData}
          locationSuggestion={locationSuggestion}
          onMapPositionChange={handleMapPositionChange}
          markerPosition={markerPosition}
          defaultData={defaultData}
          disabled={isEditMode ? false : !isStep1Valid()}
        />
      )}

      {currentStep === 2 && (
        <DetailsPopup
          inputFields={inputFields}
          errors={errors}
          error={error}
          text="Organizer"
          s3Image={s3ImageOrganizer}
          handleKeyDown={handleKeyDown}
          handleOnClick={handleOnClick}
          validatePrices={validatePrices}
          handleBackClick={handleBackClick}
          handleCancels={handleCancels}
          onChange={onChange}
          onUploadImage={onUploadImage}
          onCancel={handleSkipClick}
          onSubmit={handleNextClick}
          countryList={isCountryList}
          provinceList={isProvinceList}
          cityList={isCityListData}
          defaultData={defaultData}
          isEditMode={isEditMode}
          disabled={!isStep2Valid()}
        />
      )}

      {currentStep === 3 && (
        <ContactImagePopup
          text="Organizer"
          s3Image={s3ImageOrganizer}
          handleBackClick={handleBackClick}
          handleCancels={handleCancels}
          onChange={onChange}
          renderImg={renderImg}
          editFrom={DataTrue}
          contactImage0={inputFields.contact_profile_pic[0]}
          contactImage1={inputFields.contact_profile_pic[1]}
          contactName1={inputFields.contact_name1}
          contactName2={inputFields.contact_name2}
          contactDesignation1={inputFields.contact_designation1}
          contactDesignation2={inputFields.contact_designation2}
          ImagesData={inputFields?.images}
          onUploadImage={onUploadImage}
          onCancel={handleSkipClick}
          onSubmit={handleNextClick}
          defaultData={defaultData}
          isEditMode={isEditMode}
          isLoader={isLoader}
          disabled={isEditMode ? !isFieldChanged : !isStep3Valid()}
        />
      )}
    </div>
  )
}
export default eventPopup

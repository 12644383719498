import React from 'react'

const CommonEmptyData = (props) => {
  const {
    image,
    mainTitle,
    descriptionText,
    onClick,
    addIcon,
    btnText,
    className,
    titleClass,
    classNameDescription
  } = props
  return (
    <div className={`${className} grid_add_text !bg-[#E4E4E480] rounded-[15px] !border-1 !border-SecondaryBorder py-20`}>
      <img className="mb-3" src={image} alt="bedroom" />
      <h3 className={`!text-[#4E4949] !font-black !text-lg !italic mb-2 uppercase ${titleClass}`}>
        {mainTitle}
      </h3>
      <p className={`${classNameDescription} mb-0`}>{descriptionText}</p>
      {onClick &&
      <div className="add-video-rows mt-3">
        <div
          className="add_new_content uppercase italic !px-[20px] !py-[13px]"
          onClick={onClick}
        >
          {addIcon && <img src={addIcon} alt="" />}
          <span className="mr-2">{btnText}</span>
        </div>
      </div>}
    </div>
  )
}
export default CommonEmptyData

/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react'
import './profile.css'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { s3ImageProfile, urlSystemIcon } from '../../services/Service'
import { setUserSliceData } from '../../services/http-services'
import 'react-loading-skeleton/dist/skeleton.css'
import CommonButton from '../ButtonComponent/ButtonCommon'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import MainEditModal from '../../MainModal/MainEditModal'
import CreateProfileModal from './createProfileModal'

const Profiles = () => {
  const [userData, setUserData] = useState([])
  const [loading, setLoading] = useState(false)
  const [visibleCount, setVisibleCount] = useState(6)
  const user_type = useSelector((state) => state.user?.user_type)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCreateProfileOpen, setIsCreateProfileOpen] = useState(false)

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 6)
  }

  const profilesInitialized = useRef(false)

  const getUserProfiles = async () => {
    setLoading(true)
    APICall('get', '', `${EndPoints.userProfile}?type=${user_type}`, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setUserData(res?.decryptedData)
          setLoading(false)
        } else {
          toast.error(res.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const onEditClick = (userData) => {
    setUserSliceData(userData?.application_data, 'appId')
    setIsModalOpen(true)
    // if (userData.add_ons_data[0].name === 'Store') {
    //   navigate(`/store-builder/${userData.user_id}`)
    // } else if (userData.add_ons_data[0].name === 'Entertainment') {
    //   navigate(`/profile-builder/${userData.user_id}`)
    // } else if (userData.add_ons_data[0].name === 'Community') {
    //   navigate(`/community-builder/${userData.user_id}`)
    // } else if (userData.add_ons_data[0].name === 'Local') {
    //   navigate(`/local-builder/${userData.user_id}`)
    // }
  }

  const onClickProfileCreate = () => {
    setIsCreateProfileOpen(true)
  }

  useEffect(() => {
    if (!profilesInitialized.current) {
      profilesInitialized.current = true
      getUserProfiles()
    }
  }, [])

  return (
    <>
      {!isModalOpen && (
        <div className="profiles-section !p-[20px]">
          <div className="profiles-section-sub !p-[15px] !shadow-none">
            <div className="d-flex align-items-center justify-content-between profiles-btn">
              <h1 className="!text-[#0B0A0A] !font-black !italic !text-[36px] !uppercase">
                {English.A221}
              </h1>
              <div className="profile-button-group">
                <CommonButton
                  label={English.G76}
                  isPrimary
                  onClick={onClickProfileCreate}
                  className="add_new_content primary-btn"
                />
              </div>
            </div>
            <div className="profile_grid">
              {loading ? (
                <div className="add-ons">
                  <div className="add-ons-main-ing">
                    <Skeleton height={150} width={200} />
                  </div>
                  <div className="footer-row">
                    <div className="images-block profile_addons">
                      <Skeleton height={50} width={50} />
                    </div>
                    <div className="add-ons-detail">
                      <h4>
                        <Skeleton width={100} />
                      </h4>
                      <p>
                        <Skeleton width={150} />
                      </p>
                      <p className="addons_name">
                        <Skeleton width={120} />
                      </p>
                      <button
                        type="button"
                        disabled
                        className="secondary-btn-model border border-0 mt-3"
                      >
                        <Skeleton width={80} height={25} />
                      </button>
                    </div>
                  </div>

                  <div className="custome-badge shadow-none">
                    <Skeleton className="rounded-3" width={80} height={30} />
                  </div>

                  <button
                    type="button"
                    className="btn-white border-0 shadow-none"
                  >
                    <Skeleton className="rounded-3" width={100} height={30} />
                  </button>
                </div>
              ) : (
                <>
                  {userData.slice(0, visibleCount).map((i, index) => (
                    <div key={index} className="add-ons border-[0.5px] border-[#E4E4E4] !shadow-none">
                      <div className="flex justify-end">
                        <div className={`h-[35px] w-[48px] rounded-tr-[15px] rounded-bl-[15px] ${
                            i.application_data.id === 1
                              ? 'fyredate_bg'
                                : i.application_data.id === 3
                                  ? 'aToz_bg'
                                  : 'bg-[#CCCCCC]'
                          }`}
                        >
                          {/* <img className="h-[25px] w-[25px] text-center
                          mt-[6px] mx-auto" src={urlSystemIcon + i.application
                          _data.svg_icon} alt="" /> */}
                          <img
                            className="h-[25px] w-[25px] text-center mt-[6px] mx-auto tint-image"
                            alt=""
                            src={urlSystemIcon + i.application_data.svg_icon}
                          />
                        </div>
                      </div>
                      <div className="pt-0 pb-[20px] px-[6px] mx-auto">
                        <img
                          className="!w-[110px] !h-[110px] rounded-[60px] border-[2px] border-[#C7D5E0]"
                          alt=""
                          src={s3ImageProfile + i.icon}
                        />
                      </div>
                      <div className="px-[20px] mt-0 mb-3">
                        <div className="flex justify-between bg-[#FAFAFA] rounded-[47px] px-[20px] py-[10px]">
                          <div className="flex flex-col">
                            <p className="mb-0 text-[#0B0A0A] text-[14px] font-bold">{i?.posts}</p>
                            <p className="mb-0 text-[#BAB8B8] text-[10px] font-medium">{English.G104}</p>
                          </div>
                          <div className="flex flex-col">
                            <p className="mb-0 text-[#0B0A0A] text-[14px] font-bold">{i?.followers}</p>
                            <p className="mb-0 text-[#BAB8B8] text-[10px] font-medium">{English.G105}</p>
                          </div>
                          <div className="flex flex-col">
                            <p className="mb-0 text-[#0B0A0A] text-[14px] font-bold">{i?.following}</p>
                            <p className="mb-0 text-[#BAB8B8] text-[10px] font-medium">{English.G106}</p>
                          </div>
                        </div>
                      </div>
                      <div className="!px-[21px]">
                        <div className="flex items-center justify-center gap-2 w-full mb-2">
                          <h4 className="mb-0 !text-[14px] !font-semibold !text-[#0B0A0A] capitalize">
                            {i.full_name}
                          </h4>
                          <img src={Images.Gold_tick} alt="" />
                        </div>
                        <p className="!text-[#0B0A0A] !font-medium !text-[11px]">
                          {i?.description}
                        </p>
                      </div>
                      <div className="pb-[20px] !px-5">
                        <button
                          type="button"
                          className={`py-[10px] w-full rounded-[47px] ${
                            i.application_data.id === 1
                              ? 'fyredate_bg'
                                : i.application_data.id === 3
                                  ? 'aToz_bg'
                                  : 'bg-[#CCCCCC]'
                          }`}
                          onClick={() => onEditClick(i)}
                        >
                          <span className="uppercase text-[#FFFFFF] text-[14px] italic font-extrabold">
                            Edit Profile
                          </span>
                        </button>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            {visibleCount < userData.length && (
              <div className="collapse-button text-center mt-4">
                <button type="button" onClick={handleShowMore}>
                  <img
                    className="downIcon"
                    alt="Show more"
                    src={Images.DownIco}
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {isModalOpen && (
        <MainEditModal closeModal={() => setIsModalOpen(false)} />
      )}
      {isCreateProfileOpen && (
        <CreateProfileModal closeModal={() => setIsCreateProfileOpen(false)} />
      )}
    </>
  )
}
export default Profiles

/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable no-return-await */
/* eslint-disable max-len */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import './Store.css'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import CommonInput from '../InputComponet/CommonInput'
import { English, Images } from '../../helpers'
import Dropdown from '../Dropdown/Dropdown'
import CommonButton from '../ButtonComponent/ButtonCommon'
import { APICall, dataDecrypt, EndPoints } from '../../services'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import Loader from '../Loader/Loader'
import { compressImage } from '../../services/http-services'
import { urlImageProductPathStore } from '../../services/Service'
import { emitter, EventType } from '../../helpers/Emitter'
import Utility from '../../helpers/Utility'

const ProductAddModal = ({
  onClose,
  editData,
  setIsProductEdit,
  isProductEdit,
  isMainModalOpen
}) => {
  const app_id = useSelector((state) => state.user?.app_id)
  const [error, setError] = useState({})
  const [errors, setErrors] = useState('')
  const [category, setCategory] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [inputFields, setInputFields] = useState(
    isProductEdit
      ? {
          ...editData,
          discount_percentage: Utility.calculateDiscountPercentage(
            editData.regular_price,
            editData.discount_price
          )
        }
      : {
          name: '',
          regular_price: '',
          discount_price: '',
          discount_percentage: '',
          category_id: '',
          sub_category_id: '',
          product_link: '',
          description: '',
          main_image: '',
          image_1: '',
          image_2: '',
          image_3: '',
          search: '',
          id: ''
        }
  )
  const [selectedImages, setSelectedImages] = useState([])
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [isEdited, setIsEdited] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)

  const [newImageLoading, setNewImageLoading] = useState(false)
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [loading, setLoading] = useState(false)

  const ResetValue = () => {
    setSelectedImages([])
    setInputFields({
      name: '',
      regular_price: '',
      discount_price: '',
      discount_percentage: '',
      category_id: '',
      sub_category_id: '',
      product_link: '',
      description: '',
      main_image: '',
      image_1: '',
      image_2: '',
      image_3: '',
      search: '',
      id: ''
    })
    setSubCategory([])
  }

  const handleCancel = () => {
    ResetValue()
    onClose()
  }

  const urlRegex =
    /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'regular_price' || name === 'discount_price') {
      if (/^\d*\.?\d*$/.test(value) && !value.startsWith('.')) {
        setInputFields((prev) => ({
          ...prev,
          [name]: value
        }))
      }

      if (name === 'regular_price') {
        const dis_price =
          value - (value * inputFields.discount_percentage) / 100
        setInputFields((prev) => ({
          ...prev,
          discount_price:
            inputFields.discount_percentage == 0 ? '0' : dis_price
        }))
      }
      if (
        name === 'discount_price' &&
        parseFloat(value) > parseFloat(inputFields.regular_price)
      ) {
        setError((prev) => ({
          ...prev,
          discount_price: 'Discount price cannot be higher than regular price'
        }))
      } else {
        setError((prev) => ({
          ...prev,
          discount_price: ''
        }))
      }

      return
    }
    if (name === 'product_link' && urlRegex.test(value)) {
      setErrors('')
    }
    setInputFields((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const MainCategory = () => {
    APICall('post', {}, EndPoints.category, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setCategory(res?.decryptedData)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const SubCategory = (data) => {
    APICall('post', data, EndPoints.subCategory, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setSubCategory(res?.decryptedData[0]?.sub_category_data)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const oneProductDetails = (data) => {
    const platFormData = {
      app_id: app_id?.id,
      plateform: 'web',
      product_id: data?.id
    }
    APICall('post', platFormData, `${EndPoints.productDetail}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const productDetails = dataDecrypt(res.data.data)
          SubCategory({ category_id: productDetails.category_id })
          const Urls = productDetails?.images?.map((i) => i.image)
          setInputFields({
            ...productDetails,
            discount_percentage: Utility.calculateDiscountPercentage(
              productDetails.regular_price,
              productDetails.discount_price
            ),
            main_image: Urls?.[0] || '',
            image_1: Urls?.[1] || '',
            image_2: Urls?.[2] || '',
            image_3: Urls?.[3] || '',

            uploadedImages: productDetails?.images
          })
          setSelectedImages(Urls)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  useEffect(() => {
    if (isProductEdit) {
      oneProductDetails(editData)
    }
  }, [isProductEdit, editData])

  const ProductDataSubmit = (images) => {
    setLoading(true)
    inputFields.main_image = images[0] ?? ''

    inputFields.image_1 = images[1] ?? ''

    inputFields.image_2 = images[2] ?? ''

    inputFields.image_3 = images[3] ?? ''

    if (!inputFields.discount_price) {
      inputFields.discount_price = 0
    }
    if (!inputFields.discount_percentage) {
      inputFields.discount_percentage = 0
    }
    APICall(
      'post',
      {
        ...inputFields,
        main_image: images[0] ?? '',
        image_1: images[1] ?? '',
        image_2: images[2] ?? '',
        image_3: images[3] ?? ''
      },
      `${EndPoints.addProductLibrary}`,
      'store'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            //   onComplete()
            setIsEdited(false)
            toast.success(res?.data?.message)
            setLoading(false)
            //   setShowProduct(false)
             emitter.emit(EventType.productLibrary)
            setIsProductEdit(false)
            onClose()
          }, 3000)
        } else {
          setIsEdited(false)
          setLoading(false)
          // setShowProduct(false)
          setIsProductEdit(false)
          setLoading(false)
          toast.error(res?.data?.message)
          onClose()
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const mainCategoryInitialized = useRef(false)

  useEffect(() => {
    if (!mainCategoryInitialized.current) {
      mainCategoryInitialized.current = true
      MainCategory()
    }
  }, [])

  const getCategoryName = (categoryId) => {
    const categoryObj = category?.find((cat) => cat?.id === categoryId)
    return categoryObj ? categoryObj?.name : English.G134
  }

  const getSubCategoryNameById = (subCategoryId) => {
    const subCategoryItem = subCategory?.find(
      (sc) => sc?.sub_category_id === subCategoryId
    )
    return subCategoryItem ? subCategoryItem?.sub_category_name : English.A242
  }

  const onUpload = async (file, fieldName, index) => {
    try {
      // const imageFile = await compressImage(file)
      const coverFormData = new FormData()

      if (fieldName === 'images') {
        const compressedImages = await Promise.all(
          Array.from(file)
            .filter((file) => file instanceof File)
            .map(async (file) => await compressImage(file))
        )
        if (compressedImages.length === 0) {
          return file
        }
        compressedImages.forEach((imageFile) => {
          coverFormData.append('files[]', imageFile)
        })
      }

      coverFormData.append('type', 'product')

      try {
        const res = await APICall(
          'post',
          coverFormData,
          EndPoints.uploadFileMultiple,
          'store',
          true
        )
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedImageData = dataDecrypt(res.data.data)
          if (fieldName === 'images') {
            return [
              ...decryptedImageData,
              ...file.filter((item) => !(item instanceof File))
            ]
          }
        } else {
          toast.error(res?.data?.message || 'Error uploading image')
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || 'Upload failed')
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const handleImageChange = async (file, index = -1) => {
    if (file) {
      setInputFields((prev) => {
        const updatedFields = { ...prev }
        const uploadedImages = prev.uploadedImages || []

        let fieldName = 'main_image'
        if (uploadedImages.length === 1) fieldName = 'image_1'
        else if (uploadedImages.length === 2) fieldName = 'image_2'
        else if (uploadedImages.length === 3) fieldName = 'image_3'

        if (index >= 0) {
          fieldName = index === 0 ? 'main_image' : index === 1 ? 'image_1' : index === 2 ? 'image_2' : index === 3 ? 'image_3' : 'main_image'
          updatedFields.uploadedImages[index] = file
          const newSM = [...selectedImages]
          newSM[index] = file
          setSelectedImages(newSM)
        } else {
          updatedFields.uploadedImages = [...uploadedImages, file]
          setSelectedImages([...selectedImages, file])
        }

        updatedFields[fieldName] = file
        return updatedFields
      })
    }
  }

  // const handleImageChange = async (file, indexToReplace) => {
  //   if (!file) return

  //   const updatedImages = selectedImages.slice(0)
  //   setIsImageLoading({
  //     index: indexToReplace,
  //     loading: true
  //   })

  //   if (indexToReplace === undefined && updatedImages.length + 1 > 4) {
  //     toast.error('Please select a maximum of 4 images.')
  //     return
  //   }

  //   if (indexToReplace === undefined) {
  //     setNewImageLoading(true)
  //   }

  //   setIsEdited(true)

  //   const url = await finishSubmit(file)

  //   if (indexToReplace !== undefined) {
  //     updatedImages.splice(indexToReplace, 1, url)
  //   } else {
  //     updatedImages.push(url)
  //   }
  //   setSelectedImages(updatedImages)
  //   if (indexToReplace === undefined) {
  //     setNewImageLoading(false)
  //   }
  // }

  const EditProductLib = (data, images) => {
    setLoading(true)
    const platFormData = {
      product_libraries_id: data?.id,
      name: data.name,
      regular_price: data?.regular_price,
      discount_price: data?.discount_price || 0,
      discount_percentage: data?.discount_percentage || 0,
      category_id: data?.category_id,
      sub_category_id: data?.sub_category_id,
      product_link: data?.product_link,
      description: data?.description,
      main_image: images?.[0] || '',
      image_1: images?.[1] || '',
      image_2: images?.[2] || '',
      image_3: images?.[3] || ''
    }
    if (!data?.image) {
      platFormData.main_image = images[0] ?? ''
    }
    if (!data?.image_1) {
      platFormData.image_1 = images[1] ?? ''
    }
    if (!data?.image_2) {
      platFormData.image_2 = images[2] ?? ''
    }
    if (!data?.image_3) {
      platFormData.image_3 = images[3] ?? ''
    }
    APICall('post', platFormData, `${EndPoints.editProductLibrary}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            // onComplete()
            emitter.emit(EventType.productLibrary)
            toast.success(res?.data?.message)
            ResetValue()
            setIsEdited(false)
            setLoading(false)
            onClose()
            // setShowProduct(false)
          }, 3000)
        } else {
          toast.error(res?.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const handleSubmit = async () => {
    if (!inputFields.product_link || !urlRegex.test(inputFields.product_link)) {
      setErrors(English.G73)
      return
    }
    if (isFormValid) {
      const newFile = [
        inputFields?.main_image,
        inputFields?.image_1,
        inputFields?.image_2,
        inputFields?.image_3
      ]

      const uploadedImages =
        newFile.length !== 0 ? await onUpload(newFile, 'images') : []
      const images = [
        inputFields?.main_image,
        inputFields?.image_1,
        inputFields?.image_2,
        inputFields?.image_3
      ].map((item) => (typeof item === 'string' ? item : uploadedImages?.shift()))
      // setSelectedImages(images)

      if (isProductEdit) {
        EditProductLib(inputFields, images)
      } else {
        ProductDataSubmit(images)
      }
    }
  }

  const removeImage = (index) => {
    const updatedImages = [...selectedImages]
    updatedImages.splice(index, 1)
    setSelectedImages(updatedImages)
    let fieldName = 'main_image'
    if (updatedImages.length === 1) fieldName = 'image_1'
    else if (updatedImages.length === 2) fieldName = 'image_2'
    else if (updatedImages.length === 3) fieldName = 'image_3'
    setInputFields({
      ...inputFields,
      uploadedImages: updatedImages,
      [fieldName]: ''
    })
    setIsEdited(true)
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setSelectedImages((state) => {
        const copy = [...state]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b

        return copy
      })
      setIsEdited(true)
      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const validateForm = () => {
    const {
      name,
      regular_price,
      category_id,
      sub_category_id,
      product_link,
      description
    } = inputFields

    const isValid =
      name &&
      regular_price &&
      category_id &&
      sub_category_id &&
      product_link &&
      description &&
      // selectedImages.length > 0 &&
      !error.discount_price &&
      !error.discount_percentage

    setIsFormValid(isValid)
  }

  useEffect(() => {
    validateForm()
  }, [inputFields, selectedImages, error, isEdited])

  const renderImg = useMemo(() => {
    return (
      <>
        {selectedImages?.map((image, index) => (
          <div
            className={`position-relative h-[100px] w-[100px] rounded-2xl ${
              index === 0 ? 'first-image' : ''
            }`}
            key={index}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={(event) => event.preventDefault()}
            onDrop={(event) => {
              event.preventDefault()
              handleDrop(index)
            }}
          >
            {isImageLoading.index === index && isImageLoading.loading && (
              <Loader />
            )}
            <img
              className={`position-relative h-[100px] w-[100px] rounded-2xl ${
                index === 0 ? 'first-image' : ''
              }`}
              src={
                typeof image === 'string'
                  ? urlImageProductPathStore + image
                  : URL.createObjectURL(image)
              }
              alt={`image-${index}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              onLoad={() => handleLoad(index)}
              onError={() => {
                setIsImageLoading({
                  index: null,
                  loading: false
                })
              }}
            />
            <button
              type="button"
              onClick={() => removeImage(index)}
              className="absolute top-[-4px] right-[-4px]"
            >
              <img src={Images.Remove} alt="remove" />
            </button>
            <button
              type="button"
              className="plus-img !w-[40px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            >
              <CommonImageUpload
                handleImageChange={(file) => {
                  handleImageChange(file, index)
                }}
                selectedImg=""
                defaultImg={Images.plusimg}
                className="upload_label"
              />
            </button>
          </div>
        ))}
        {newImageLoading && (
          <div className="image position-relative">
            <Loader />
          </div>
        )}
      </>
    )
  }, [selectedImages, draggedIndex])

  return (
    <div className="">
      {isMainModalOpen && (
        <div className="add-item-model-store">
          <div className="add-item-content-store flex flex-col !bg-white">
            <div className="d-flex justify-content-between header pb-2 pt-4 sticky top-0 bg-white z-10">
              <h3 className="text-Main text-base italic font-black">{editData ? 'EDIT PRODUCT' : 'ADD PRODUCT'}</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                disabled={loading}
                onClick={handleCancel}
              >
                <span>
                  <img className="close-btns" src={Images.CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="flex w-full !gap-5 flex-1">
              <div
                className={`${selectedImages.length === 0 ? 'input-image-show-store w-[250px] pt-2.5' : 'input-image-show-store w-[250px] pt-2.5 !grid !grid-cols-2 gap-3 h-fit max-w-fit'}`}
              >
                {selectedImages.length !== 4 && <div className="input-img-label-store">
                  <div className={`${selectedImages.length === 0 ? '' : ''}`}>
                    <CommonImageUpload
                      handleImageChange={handleImageChange}
                      buttonText="Upload Image"
                      selectedImg=""
                      id="upload-p-image"
                      defaultImg={Images.upload}
                      className={`upload_label ${selectedImages.length === 0 ? '!hidden' : 'flex'}`}
                    />
                    {selectedImages.length === 0 && (
                      <div>
                        <CommonEmptyData
                          image={Images.ImgPlaceholder}
                          mainTitle={English.G220}
                          onClick={() => document.getElementById('upload-p-image').click()}
                          className="!h-[350px]"
                          titleClass="!font-normal !not-italic"
                          btnText="add photo"
                        />
                        <div className="text-center !pt-5">
                          <p className="text-Main text-sm italic font-black mb-0">File formats</p>
                          <p className="text-textSecondary text-[12px] mb-0">Recommended: “.JPG”. Other major formats are supported.</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>}
                {renderImg}
              </div>
              <form className="item-details-input w-[70%]">
                <div className="name-field">
                  <div>
                    <label>{English.G130}</label>
                    <CommonInput
                      name="name"
                      value={inputFields.name}
                      placeholder={English.A251}
                      onChange={handleChange}
                      className="bg-placeholder"
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>{English.G124}</label>
                    <Dropdown
                      title={getCategoryName(inputFields.category_id)}
                      onChanged={(d) => {
                        SubCategory({ category_id: d.id })
                        setInputFields({
                          ...inputFields,
                          category_id: d.id
                        })
                      }}
                      textClass="selected-dropdown-text"
                      Data={category?.map((d) => ({
                        title: d.name,
                        id: d.id
                      }))}
                      name="category_id"
                      customClass={
                        inputFields.category_id
                          ? 'selected-class border border-[#E4E4E4]'
                          : 'giveaway-dropdown border border-[#E4E4E4]'
                      }
                    />
                  </div>
                  <div>
                    <label>{English.A241}</label>
                    <Dropdown
                      title={getSubCategoryNameById(
                        inputFields.sub_category_id
                      )}
                      onChanged={(d) => setInputFields({
                          ...inputFields,
                          sub_category_id: d.id
                        })}
                      Data={
                        subCategory && subCategory?.length > 0
                          ? subCategory.map((d) => ({
                              title: d.sub_category_name,
                              id: d.sub_category_id
                            }))
                          : [{ title: 'No Data Found', id: null }]
                      }
                      textClass="selected-dropdown-text"
                      name="sub_category_id"
                      customClass={
                        inputFields.sub_category_id
                          ? 'selected-class border border-[#E4E4E4]'
                          : 'giveaway-dropdown border border-[#E4E4E4]'
                      }
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>{English.G131}</label>
                    <CommonInput
                      name="regular_price"
                      type="number"
                      value={inputFields.regular_price}
                      placeholder={English.A243}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                  <div>
                    <label>{English.B82}</label>
                    <CommonInput
                      name="discount_percentage"
                      type="number"
                      value={inputFields.discount_percentage || ''}
                      placeholder={English.B83}
                      onChange={(e) => {
                        let { value } = e.target
                        if (value > 100) value = 100
                        if (value < 0) value = 0
                        const dis_price =
                          inputFields.regular_price -
                          (inputFields.regular_price * value) / 100
                        handleChange({
                          target: { name: 'discount_percentage', value }
                        })
                        setInputFields((prev) => ({
                          ...prev,
                          discount_price:
                            inputFields.discount_percentage == 0
                              ? '0'
                              : dis_price
                        }))
                      }}
                      onWheel={(e) => e.target.blur()}
                      className={`${error.discount_percentage ? 'mb-0' : 'mb-2'}`}
                    />
                    {error.discount_percentage && (
                      <span className="error-message">
                        {error.discount_percentage}
                      </span>
                    )}
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>{English.G126}</label>
                    <CommonInput
                      name="product_link"
                      value={inputFields.product_link}
                      placeholder={English.G133}
                      onChange={handleChange}
                      className={`${errors ? 'mb-0' : 'mb-3'}`}
                    />
                    {errors && <div className="error-message">{errors}</div>}
                  </div>
                </div>
                <div>
                  <label>{English.A252}</label>
                  <CommonInput
                    name="description"
                    placeholder={English.A253}
                    onChange={handleChange}
                    value={inputFields.description}
                    className="description-input height_local_description border border-[#E4E4E4]"
                    isTextArea
                  />
                </div>
              </form>
            </div>
            <div className="flex justify-between items-center !py-5 bg-white sticky bottom-0 z-10">
              <CommonButton
                label={English.G78}
                type="button"
                onClick={handleCancel}
                isPrimary={false}
                context={English.G232}
                className="me-4"
              />
              <CommonButton
                label={English.B58}
                type="button"
                isPrimary
                context={English.G232}
                className="common-button"
                disabled={!isFormValid}
                onClick={() => handleSubmit()}
              />
            </div>
          </div>
          {loading && <Loader />}
        </div>
      )}
    </div>
  )
}
export default ProductAddModal

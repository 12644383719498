/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react'
import '../HotelComponent/Discount.css'
import './Menu.css'
import Input from 'antd/es/input/Input'
import { Table, Button, Image } from 'antd'
import { toast } from 'react-toastify'
import _ from 'lodash'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import {
  lockScroll,
  unlockScroll
} from '../../../helpers/Utility'
import {
  s3ImageRestaurant
} from '../../../services/Service'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal'
import Loader from '../../Loader/Loader'
import Pagination from '../../Pagination/Pagination'
import DiscountSkeleton from '../Skeleton/DiscountSkeleton'
import English from '../../../helpers/English'
import Images from '../../../helpers/Images'
import { APICall, EndPoints } from '../../../services'
import CommonDiscount from '../CommonDiscount'

const Discount = ({ isRestaurantId }) => {
  const [isAddDiscount, setIsAddDiscount] = useState(false)
  const [isRoomFeature, setIsRoomFeature] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [inputFields, setInputFields] = useState({
    discount_name: '',
    coupan_code: '',
    expiry_date: '',
    min_amount: '',
    discount: '',
    restaurant_id: isRestaurantId
  })
  const [discountListData, setDiscountListData] = useState([])
  const [loading, setLoading] = useState(false)
  const [delDiscountId, setDelDiscountId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [particularDiscount, setParticularDiscount] = useState([])
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [selectedFilePath, setSelectedFilePath] = useState('')
  const [selectedFileObj, setSelectedFileObj] = useState('')
  const [isImageUploaded, setIsImageUploaded] = useState(false)
  const [particularDiscountLoading, setParticularDiscountLoading] =
    useState(false)
  const [discountAddLoading, setDiscountAddLoading] = useState(false)

  const clearValue = () => {
    setInputFields({
      discount_name: '',
      coupan_code: '',
      expiry_date: '',
      min_amount: '',
      discount: ''
    })
    setSelectedFilePath('')
    setSelectedFileObj('')
    setIsImageUploaded(false)
    setIsEdit(false)
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    const updatedValue = name === 'coupan_code' ? value.toUpperCase() : value

    const filteredValue = name === 'coupan_code' ? updatedValue.replace(/[^a-zA-Z0-9-_]/g, '') : value

    setInputFields({ ...inputFields, [name]: filteredValue })
  }

  const addDiscount = () => {
    setIsAddDiscount(true)
  }
  const handleCancels = () => {
    setIsAddDiscount(false)
    setIsRoomFeature(false)
    clearValue()
  }

  const handleDeleteCancel = () => {
    setShowConfirmModel(false)
  }

  const ShowConfirm = (record) => {
    setDelDiscountId(record.discount_id)
    setShowConfirmModel(true)
  }

  const showDiscount = async (record) => {
    setParticularDiscountLoading(true)
    await particularDiscountData(record.discount_id)
    setIsRoomFeature(true)
  }

  const handleFileChange = (file) => {
    const maxFileSizeMB = 10
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024
    if (file.size > maxFileSizeBytes) {
      toast.error(`File size exceeds ${maxFileSizeMB} MB`)
      return
    }
    const reader = new FileReader()
    reader.onload = (e) => {
      setSelectedFilePath(e.target.result)
      setSelectedFileObj(file)
      setIsImageUploaded(true)
    }
    reader.readAsDataURL(file)
  }

  const EditDiscount = (record) => {
    if (Object.keys(record).length > 0) {
      setIsEdit(true)
      setInputFields({
        restaurant_id: record.restaurant_id,
        coupan_code: record.coupan_code,
        discount: record.discount.replace(/%/g, ''),
        discount_id: record.discount_id,
        discount_name: record.discount_name,
        min_amount: record.min_amount,
        expiry_date: formatDateToISO(record.expiry_date),
        image: record.image
      })
      setSelectedFilePath(s3ImageRestaurant + record.image)
    }
    setInitialValues({
      restaurant_id: record.restaurant_id,
      coupan_code: record.coupan_code,
      discount: record.discount.replace(/%/g, ''),
      discount_id: record.discount_id,
      discount_name: record.discount_name,
      min_amount: record.min_amount,
      expiry_date: formatDateToISO(record.expiry_date),
      image: record.image
    })
    setIsAddDiscount(true)
  }

  const clickOnParticularEdit = (data) => {
    EditDiscount(data)
    setIsRoomFeature(false)
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase())
  }

  const columns = [
    {
      title: 'DISCOUNT NAME ',
      dataIndex: 'discount_name',
      key: 'discount_name',
      render: (text, record) => (
        <div className="image_name">
          <Image
            width={100}
            src={s3ImageRestaurant + record.image}
            alt="img"
            style={{ marginRight: 10 }}
            preview={false}
          />
          <span>
            {record.discount_name.length > 15
              ? `${record.discount_name.substring(0, 15)}...`
              : record.discount_name}
          </span>
        </div>
      ),
      sorter: (a, b) => a.discount_name.localeCompare(b.discount_name),
      onFilter: (value, record) => record.discount_name.toLowerCase().includes(value.toLowerCase()),
      className: 'custom-column'
    },
    {
      title: 'CODE',
      dataIndex: 'coupan_code',
      key: 'coupan_code',
      sorter: (a, b) => a.coupan_code.localeCompare(b.coupan_code)
    },
    {
      title: 'EXPIRY',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      sorter: (a, b) => a.expiry_date.localeCompare(b.expiry_date)
    },
    {
      title: 'MIN AMOUNT',
      dataIndex: 'min_amount',
      key: 'min_amount',
      sorter: (a, b) => a.min_amount.localeCompare(b.min_amount)
    },
    {
      title: 'DISCOUNT',
      dataIndex: 'discount',
      key: 'discount',
      sorter: (a, b) => a.discount.localeCompare(b.discount)
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (text, record) => (
        <div className="image-buttons-discounts">
          <Button
            icon={<img src={Images.Edit} alt="Edit" />}
            onClick={(e) => {
              e.stopPropagation()
              EditDiscount(record)
            }}
          />
          <Button
            icon={<img src={Images.DeleteDiscount} alt="Delete" />}
            onClick={(e) => {
              e.stopPropagation()
              ShowConfirm(record)
            }}
          />
        </div>
      )
    }
  ]

  const filteredData = discountListData.filter((item) => item.discount_name.toLowerCase().includes(searchText))

  const formatDateToISO = (dateString) => {
    if (!dateString) return null

    const date = new Date(dateString)
    if (isNaN(date)) return null

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  const allDiscountData = (currentPage) => {
    const payload = {
      restaurant_id: isRestaurantId
    }
    APICall(
      'post',
      payload,
      `${EndPoints.resDiscountGet}?page=${currentPage}`,
      'local'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setDiscountListData(res?.decryptedData.data)
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const discountAddApiCall = async () => {
    setDiscountAddLoading(true)
    if (selectedFileObj) {
      const imageFile = await compressImage(selectedFileObj)
      const coverFormData = new FormData()
      coverFormData.append('file', imageFile)
      coverFormData.append('type', 'restaurant_discount')

      try {
        const res = await APICall(
          'post',
          coverFormData,
          '',
          'imageUploadLocal',
          true
        )
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedCoverData = dataDecrypt(res.data.data)
          inputFields.image = decryptedCoverData
          setIsImageUploaded(true)
        } else {
          toast.error(res?.data?.message || 'Error uploading image')
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || 'Upload failed')
      }
    }
    const payload = {
      discount_name: inputFields.discount_name,
      coupan_code: inputFields.coupan_code,
      expiry_date: inputFields.expiry_date,
      discount: inputFields.discount,
      restaurant_id: isRestaurantId,
      min_amount: inputFields.min_amount,
      discount_id: inputFields.discount_id,
      image: inputFields.image
    }
    if (isEdit) {
      APICall('post', payload, `${EndPoints.resDiscountEdit}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setTimeout(() => {
              setIsAddDiscount(false)
              clearValue()
              setIsEdit(false)
              allDiscountData(currentPage)
              toast.success(res?.data?.message)
              setDiscountAddLoading(false)
            }, 3000)
            if (res.code === 200) {
              toast.success(res?.data?.message)
            }
          } else {
            setIsEdit(false)
            setDiscountAddLoading(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setIsEdit(false)
          setDiscountAddLoading(false)
          toast.error(error?.data?.message)
        })
    } else {
      APICall('post', payload, `${EndPoints.resDiscountAdd}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setTimeout(() => {
              clearValue()
              setIsAddDiscount(false)
              allDiscountData(currentPage)
              toast.success(res?.data?.message)
            }, 3000)
          } else {
            toast.error(res?.data?.message)
            setDiscountAddLoading(false)
          }
        })
        .catch((error) => {
          setDiscountAddLoading(false)
          toast.error(error?.data?.message)
        })
    }
  }

  const deleteDiscount = () => {
    const data = {
      discount_id: delDiscountId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.resDiscountDelete}`, 'local')
    .then((res) => {
      if (res.status === 200 && res.data?.code === 200) {
        setShowConfirmModel(false)
        setLoading(false)
        setTimeout(() => {
          allDiscountData(currentPage)
          toast.success(res?.data?.message)
        }, 3000)
      } else {
        setLoading(false)
        toast.error(res?.data?.message)
      }
    })
    .catch((error) => {
      setLoading(false)
      toast.error(error?.data?.message)
    })
  }

  const particularDiscountData = (discount_id) => {
    const payload = {
      restaurant_id: isRestaurantId,
      discount_id
    }
    APICall('post', payload, `${EndPoints.resDiscountParticular}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setParticularDiscount(res?.decryptedData)
          setParticularDiscountLoading(false)
        } else {
          setParticularDiscountLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setParticularDiscountLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const validateForm = () => {
    const { discount_name, coupan_code, expiry_date, min_amount, discount } =
      inputFields

    if (discount_name && coupan_code && expiry_date && min_amount && discount) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const handlePageChange = (pageNumber) => {
    allDiscountData(pageNumber)
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    allDiscountData(currentPage)
  }, [])

  useEffect(() => {
    if (!isEdit) {
      validateForm()
    }
  }, [inputFields])

  useEffect(() => {
    if (isEdit) {
      setIsFormComplete(
        !_.isEqual(inputFields, initialValues) || isImageUploaded
      )
    }
  }, [inputFields, initialValues, isEdit, isImageUploaded])

  useEffect(() => {
    if (isAddDiscount || isRoomFeature || ShowConfirmModel) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [isAddDiscount, isRoomFeature, ShowConfirmModel])

  return (
    <div className="">
      <div className="statistic-chart-container position-relative">
        {discountListData.length === 0 ? (
          <div className="add_room_text">
            <img className="mb-3 !w-[70px] !h-[70px]" src={Images.tagsDiscount} alt="bedroom" />
            <h3>{English.G371}</h3>
            <p>{English.G372}</p>
            <div className="add-video-rows mt-3" onClick={addDiscount}>
              <div className="add_new_content uppercase italic">
                <img src={Images.plusrounded} alt="" />
                <span className="mr-2">
                  {English.G373}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="local-room-main">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className="uppercase pe-2">
                {English.G374}
              </h3>
              <button
                type="button"
                className="primary-btn uppercase italic"
                onClick={addDiscount}
              >
                {/* <img src={Images.PlusIco} alt="add" /> */}
                {English.G352}
              </button>
            </div>
            <div className="discount_list">
              <Input
                placeholder="Search by Discount Name"
                onChange={handleSearch}
                style={{ width: 200, marginBottom: '1rem' }}
              />
              <div className="discount_list">
                <Table
                  columns={columns}
                  dataSource={filteredData}
                  pagination={false}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        showDiscount(record)
                      }
                    }
                  }}
                />
              </div>
              <ConfirmationModal
                isVisible={ShowConfirmModel}
                onConfirm={deleteDiscount}
                onCancel={handleDeleteCancel}
                isLoading={loading}
              />
              {loading && <Loader />}
            </div>
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        )}

        {isAddDiscount && (
          <CommonDiscount
            text="Restaurant"
            discountAddLoading={discountAddLoading}
            isEdit={isEdit}
            handleCancels={handleCancels}
            handleChange={handleChange}
            inputFields={inputFields}
            setInputFields={setInputFields}
            handleFileChange={handleFileChange}
            selectedFilePath={selectedFilePath}
            disabled={isEdit ? !isImageUploaded && !isFormComplete : !isImageUploaded || !isFormComplete}
            discountAddApiCall={discountAddApiCall}
          />
        )}

        {isRoomFeature && (
          <div className="add-item-model">
            {particularDiscountLoading ? (
              <DiscountSkeleton />
            ) : (
              particularDiscount.map((data, index) => (
                <div
                  className="add-item-content add_room_modal !max-w-[1200px] !m-5"
                  key={index}
                >
                  <div className="type_close mb-2 local_sticky_header">
                    <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3 pl-[9px]">
                      {data.discount_name}
                    </div>
                    <button
                      className="cancel_icon"
                      type="button"
                      onClick={handleCancels}
                    >
                      <img src={Images.RemoveLocal} alt="" />
                    </button>
                  </div>
                  <div className="grid grid-cols-2 !gap-5 local_contact_scroll_bars">
                    <div className="item-details-input pt-0">
                      <div className="mb-3 mt-3">
                        <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 bg-[#E7EFFF]">
                          <p className="p_text">{English.G354}</p>
                          <label>
                            {' '}
                            {data.coupan_code}
                          </label>
                        </div>
                      </div>
                      <div className="detail-grid">
                        <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 !mb-5">
                          <p className="p_text">
                            {English.G353}
                            {' '}
                          </p>
                          <label>{data.discount_name}</label>
                        </div>
                        <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 !mb-5">
                          <p className="p_text">{English.G358}</p>
                          <label>{data.expiry_date}</label>
                        </div>
                      </div>
                      <div className="detail-grid">
                        <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 !mb-5">
                          <p className="p_text">{English.G376}</p>
                          <label>
                            {English.G283}
                            {data.min_amount}
                          </label>
                        </div>
                        <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 !mb-5">
                          <p className="p_text">{English.G356}</p>
                          <label>{data.discount}</label>
                        </div>
                      </div>
                    </div>
                    <div className="menu_image_show h-[360px]">
                      <img
                        src={s3ImageRestaurant + data.image}
                        alt="roomImage"
                        className="aspect-[1.12/1] w-full object-cover object-center rounded-[5px]"
                      />
                    </div>
                  </div>
                  <div className="item-details-input pt-0">
                    <div className="local_button_row local_sticky_btn">
                      <CommonButton
                        label={English.A196}
                        onClick={() => {
                          ShowConfirm(data)
                          setIsRoomFeature(false)
                        }}
                        isPrimary={false}
                        context={English.A197}
                      />
                      <CommonButton
                        label={English.G81}
                        type="button"
                        isPrimary
                        onClick={() => clickOnParticularEdit(data)}
                        context={English.A197}
                      />
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default Discount

/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { Select } from 'antd'
import { Option } from 'antd/es/mentions'
import React, { useRef } from 'react'
import { City, Country, State } from 'country-state-city'
import { Images } from '../../../helpers'

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.value === nextProps.value &&
    prevProps.type === nextProps.type &&
    prevProps.selectedCountry === nextProps.selectedCountry &&
    prevProps.selectedState === nextProps.selectedState &&
    prevProps.cityDepended === nextProps.cityDepended
  )
}
const CountryStateCityDropDown = React.memo(({ type, onValueSelected, value, selectedCountry, selectedState, cityDepended = false }) => {
  const selectRef = useRef(null)
  const handleSelectChange = (value) => {
    onValueSelected(value)
    selectRef.current?.blur()
  }
  const country = Country.getAllCountries()
  const countryCode = country.find((item) => item.name.toLowerCase() === selectedCountry?.toLowerCase())?.isoCode
  const stateCode = State.getAllStates().find((item) => item.name.toLowerCase() === selectedState?.toLowerCase())?.isoCode
  const state = selectedCountry
                ? State.getStatesOfCountry(countryCode)
                : State.getAllStates()
  const city = selectedState
                ? City.getCitiesOfState(countryCode, stateCode)
                : selectedCountry
                  ? City.getCitiesOfCountry(countryCode)
                  : !cityDepended ? City.getAllCities() : []
  let list = type === 'country' ? country : type === 'state' ? state : city

  list = Array.from(new Map(list.map((item) => [item.name, item])).values())

  console.log(list)

  return (
    <Select
      showSearch
      ref={selectRef}
      value={value}
      onChange={handleSelectChange}
      className="custom-country-select w-full h-[60px]"
      dropdownClassName="custom-dropdown"
      placeholder="Select a country"
      optionFilterProp="children"
      autoFocus={false}
      suffixIcon={
        <span className="custom-down-arrow">
          <img src={Images.down_arrow_icon} alt="down" />
        </span>
      }
      // onDropdownVisibleChange={(open) => setIsDropdownOpen(open)}
      filterOption={(input, option) => option.children
        .toLowerCase()
        .includes(input.toLowerCase())}
    >
      {list.map((item) => (
        <Option
          key={item.name}
          value={item.name}
          label={item.name}
        >
          {item.name}
        </Option>
      ))}
    </Select>
  )
}, areEqual)

export default CountryStateCityDropDown

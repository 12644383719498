/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import './AddGiveway.css'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import { compressImage, dataDecrypt } from '../../services/http-services'
import { s3ImageGiveaway, s3ImageProfile } from '../../services/Service'
import Loader from '../Loader/Loader'
import {
  capitalizeFirstLetter,
  lockScroll,
  unlockScroll
} from '../../helpers/Utility'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import CommentModal from '../CommanComponent/CommentModal'
import { APICall, EndPoints } from '../../services'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import CommonModalMain from '../CommonModalMain'
import GiveawayItem from '../ContentManager/SubComponent/GiveawayItem'
import CommonDateTimePicker from '../CommonDateTimePicker/CommonDateTimePicker'

const GivewayComponent = ({ showCount }) => {
  const profileData = useSelector((state) => state.user?.profileData)
  const app_id = useSelector((state) => state.user?.app_id)
  const componentRef = useRef()
  const [Giveaways, setGiveaways] = useState([])
  const [giveawayList, setGiveawayList] = useState([])
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [ShowCommunityModel, setShowCommunityModel] = useState(false)
  const [ShowCommunityGiveawayCover, setShowCommunityGiveawayCover] =
    useState(false)
  const [ShowGiveawayProduct, setShowGiveawayProduct] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [Platformtags, setPlatformTags] = useState([])
  const [inputFields, setInputFields] = useState({
    app_id: [],
    file: [],
    title: '',
    expire_date: '',
    brand_id: 0,
    type_id: 0,
    email: '',
    phone: '',
    address: '',
    tag_friends: [],
    description: ''
  })

  const [loading, setLoading] = useState(false)
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [brandData, setBrandData] = useState([])
  const [typeData, setTypeData] = useState([])
  const [delGiveawayId, setDelGiveawayId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [currentPageList, setCurrentPageList] = useState(1)
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [commentSection, setCommentSection] = useState(false)
  const [comments, setComments] = useState([])
  const [newComment, setNewComment] = useState('')
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [currentCommentPage, setCurrentCommentPage] = useState(1)
  const [selectedId, setSelectedId] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)

  // const showList = (giveaway_id) => {
  //   giveawayParticipateList(giveaway_id)
  //   setShowProfileModel(true)
  // }

  const AddGiveway = () => {
    setShowCommunityModel(true)
    // navigate('/add-component', { state: { defaultIndex: 5 } })
  }

  const commentClear = () => {
    setComments([])
    setSelectedId([])
    setNewComment('')
    setShowReplied('')
    setEditedCommentText('')
    setEditedReplyText('')
  }

  const handleCancel = () => {
    setShowConfirmModel(false)
  }
  const ShowConfirm = (giveawayId) => {
    setDelGiveawayId(giveawayId)
    setShowConfirmModel(true)
  }

  const handleClose = () => {
    setShowCommunityModel(false)
    setShowCommunityGiveawayCover(false)
    setShowGiveawayProduct(false)
    setIsEditing(false)
    clearValue()
  }

  const clearValue = () => {
    setSelectedImages([])
    setPlatformTags([])
    setInputFields({
      ...inputFields,
      app_id: [],
      file: [],
      title: '',
      expire_date: '',
      brand_id: 0,
      type_id: 0,
      email: '',
      phone: '',
      address: '',
      tag_friends: [],
      description: ''
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputFields({
      ...inputFields,
      [name]: value
    })
  }

  const getGiveaways = (search, currentPage) => {
    // setIsLoading(true)
    const platFormData = {
      app_id: app_id.id,
      search
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.getGiveaways}?page=${currentPage}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const list = res?.decryptedData.data
          setGiveaways(currentPage === 1 ? list : [...Giveaways, ...list])
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
          // if (res?.decryptedData?.data?.length !== 0) {
          //   giveawayParticipateList(
          //     res?.decryptedData.data.data[0].giveaway_id
          //   )
          // }
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  // Giveaways pagination

  const totalVideos = Giveaways.length

  const handlePageChange = (pageNumber) => {
    getGiveaways(null, pageNumber)
    setCurrentPage(pageNumber)
  }

  // const enterGiveaway = (giveaway_id, index) => {
  //   const data = {
  //     giveaway_id
  //   }
  //   const temp = [...Giveaways]
  //   temp[index].is_participate = true
  //   setGiveaways(temp)
  //   APICall('post', data, `${EndPoints.giveawayParticipate}`, 'contentManager')
  //     .then((res) => {
  //       if (res.status === 200 && res.data?.code === 200) {
  //         //
  //       } else {
  //         toast.error(res?.data?.message)
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error?.data?.message)
  //     })
  // }

  const giveawayParticipateList = (giveaway_id) => {
    const platFormData = {
      giveaway_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayParticipateList}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setGiveawayList(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }
  const close = () => {
    setShowProfileModel(false)
  }

  // giveaway list pagination

  const GiveawayList = 5
  const indexOfLastVideoList = currentPageList * GiveawayList
  const indexOfFirstVideoList = indexOfLastVideoList - GiveawayList
  const giveawayListData = giveawayList.slice(
    indexOfFirstVideoList,
    indexOfLastVideoList
  )
  const totalGiveaway = giveawayList.length
  const totalPagesList = Math.ceil(totalGiveaway / GiveawayList)

  const paginateList = (pageNumber) => setCurrentPageList(pageNumber)

  const renderPaginationButtonsList = () => {
    const numButtonsToShow = 3

    let startPage = currentPageList - Math.floor(numButtonsToShow / 2)
    startPage = Math.max(startPage, 1)

    let endPage = startPage + numButtonsToShow - 1
    if (endPage > totalPagesList) {
      endPage = totalPagesList
      startPage = Math.max(endPage - numButtonsToShow + 1, 1)
    }

    return [...Array(endPage - startPage + 1)].map((_, index) => {
      const pageNumber = startPage + index
      return (
        <button
          type="button"
          key={pageNumber}
          className={`pagination-buttons ${
            currentPageList === pageNumber ? 'active' : ''
          }`}
          onClick={() => paginateList(pageNumber)}
        >
          {pageNumber}
        </button>
      )
    })
  }

  const handlePrevPageList = () => {
    setCurrentPageList((prevPage) => Math.max(prevPage - 1, 1))
  }

  const handleNextPageList = () => {
    setCurrentPageList((prevPage) => Math.min(prevPage + 1, totalPagesList))
  }

  const onUpload = async (file, fieldName) => {
    try {
      const coverFormData = new FormData()
      if (fieldName === 'file') {
        const compressedImages = await Promise.all(
          Array.from(file)
            .filter((file) => file instanceof File)
            .map(async (file) => compressImage(file))
        )
        compressedImages.forEach((imageFile) => {
          coverFormData.append('files[]', imageFile)
        })
      }
      coverFormData.append('type', 'giveaway')

      try {
        const res = await APICall(
          'post',
          coverFormData,
          EndPoints.uploadFileMultiple,
          'creator',
          true
        )
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedImageData = dataDecrypt(res.data.data)
          if (fieldName === 'file') {
            return [
              ...decryptedImageData,
              ...file.filter((item) => !(item instanceof File))
            ]
          }
        } else {
          toast.error(res?.data?.message || 'Error uploading image')
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || 'Upload failed')
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const handleImageChange = async (files, fieldName, index) => {
    if (!files) return

    const filesArray = Array.isArray(files) ? files : [files]

    setInputFields((prev) => {
      const prevData = prev[fieldName] || []
      const updatedData = [...prevData]

      filesArray.forEach((file, idx) => {
        if (updatedData.length >= 6) return
        if (index !== undefined && index !== null && Array.isArray(prevData)) {
          updatedData[index + idx] = file
        } else {
          updatedData.push(file)
        }
      })

      return {
        ...prev,
        [fieldName]: updatedData
      }
    })
  }

  const removeImage = (index) => {
    const updatedImages = [...selectedImages]
    updatedImages.splice(index, 1)
    setSelectedImages(updatedImages)

    const updatedFiles = [...inputFields.file]
    updatedFiles.splice(index, 1)
    setInputFields({ ...inputFields, file: updatedFiles })
  }

  const [loadingAdd, setLoadingAdd] = useState(false)
  const HandleDiscussion = async () => {
    setLoadingAdd(true)
    const newFile = inputFields.file
      .map((item) => (typeof item !== 'string' ? item : null))
      .filter((item) => item !== null)

    const uploadedImages =
      newFile.length !== 0 ? await onUpload(newFile, 'file') : []

    const images = inputFields.file.map((item) => (typeof item === 'string' ? item : uploadedImages?.shift()))
    if (inputFields?.file?.length < 4) {
      toast.error('please select at least 4 images')
    } else if (isEditing) {
      editGiveawayDataApi(images)
      setShowCommunityModel(false)
      setShowCommunityGiveawayCover(false)
      setShowGiveawayProduct(false)
      setIsEditing(false)
    } else {
      addGiveawayDataSubmit(images)
      setShowCommunityModel(false)
      setShowCommunityGiveawayCover(false)
      setShowGiveawayProduct(false)
      setIsEditing(false)
    }
    setLoadingAdd(false)
  }

  const getBrandListApiCall = () => {
    APICall('post', {}, `${EndPoints.getBrand}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setBrandData(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const getTypeListApiCall = () => {
    APICall('post', {}, `${EndPoints.getType}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTypeData(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const addGiveawayDataSubmit = (images) => {
    const data = {
      title: inputFields.title,
      description: inputFields.description,
      expire_date: inputFields.expire_date,
      brand_id: inputFields.brand_id,
      type_id: inputFields.type_id,
      app_id: [app_id?.id],
      file: images || inputFields.file
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.addGiveaway}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowGiveawayProduct(false)
          setTimeout(() => {
            getGiveaways(null, currentPage)
          }, 3000)
          setIsEditing(false)
          setLoading(false)
          clearValue()
          toast.success(res?.data?.message)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const editGiveawayDataApi = (file) => {
    const data = {
      title: inputFields.title,
      brand_id: inputFields.brand_id,
      type_id: inputFields.type_id,
      // email: inputFields.email,
      // phone: inputFields.phone,
      // address: inputFields.address,
      // tag_friends: inputFields.tag_friends,
      description: inputFields.description,
      file: file || inputFields.file,
      app_id: Platformtags.map((d) => d.id),
      giveaway_id: inputFields.giveaway_id
    }

    APICall('post', data, `${EndPoints.editGiveaway}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsEditing(false)
          clearValue()
          setLoading(false)
          toast.success(res?.data?.message)
          setTimeout(() => {
            getGiveaways(null, currentPage)
          }, 3000)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const deleteGiveaway = () => {
    const data = {
      giveaway_id: delGiveawayId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.deleteGiveaway}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            getGiveaways(null, currentPage)
          }, 3000)
          setShowConfirmModel(false)
          setLoading(false)
          toast.success(res?.data?.message)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  // const giveawayLikeData = (giveaway_id) => {
  //   const platFormData = {
  //     app_id: app_id.id,
  //     giveaway_id
  //   }
  //   APICall('post', platFormData, `${EndPoints.giveawayLike}`, 'contentManager')
  //     .then((res) => {
  //       if (res.status === 200 && res.data?.code === 200) {
  //         setGiveaways(
  //           Giveaways?.map((item) => (item.giveaway_id === giveaway_id
  //               ? { ...item, is_like: !item.is_like }
  //               : item))
  //         )
  //       } else {
  //         toast.error(res?.data?.message)
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error?.data?.message)
  //     })
  // }

  const getBrandName = (categoryId) => {
    const categoryObj = brandData.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.brand : English.G171
  }
  const getTypeName = (categoryId) => {
    const categoryObj = typeData.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.type : English.G170
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  // const handleDrop = (index) => {
  //   if (index !== draggedIndex) {
  //     setSelectedImages((state) => {
  //       const copy = [...state]
  //       const b = copy[draggedIndex]
  //       copy[draggedIndex] = copy[index]
  //       copy[index] = b
  //       inputFields.file = copy
  //       return copy
  //     })

  //     setDraggedIndex(null)
  //   }
  // }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setSelectedImages((state) => {
        const copy = [...state];
        [copy[draggedIndex], copy[index]] = [copy[index], copy[draggedIndex]]
        return copy
      })

      setInputFields((prev) => ({
        ...prev,
        file: [...selectedImages]
      }))

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {inputFields.file.map((image, index) => {
          return (
            <div
              className={`image position-relative !h-[150px] !w-[150px] ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative !h-[150px] !w-[150px] ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImageGiveaway + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button type="button" onClick={() => removeImage(index)}>
                <img src={Images.Remove} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    handleImageChange(file, 'file', index)
                  }}
                  className="plsimg"
                  selectedImg=""
                  text="giveawayBuilder"
                  defaultImg={Images.plusimg}
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [inputFields?.file, draggedIndex])

  const validateForm = () => {
    const { title, expire_date, brand_id, type_id, description } = inputFields

    if (title && expire_date && brand_id && type_id && description) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const onClickEditGiveaway = (videoData) => {
    setShowCommunityModel(true)
    setIsEditing(true)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      expire_date: videoData.expire_date,
      brand_id: videoData.brand_id,
      type_id: videoData.type_id,
      email: videoData.email,
      phone: videoData.phone,
      address: videoData.address,
      tag_friends: videoData.tag_friends,
      description: videoData.description,
      file: videoData.images,
      app_id: videoData.app_data,
      giveaway_id: videoData.giveaway_id
    })
    setInitialValues({
      title: videoData.title,
      expire_date: videoData.expire_date,
      brand_id: videoData.brand_id,
      type_id: videoData.type_id,
      email: videoData.email,
      phone: videoData.phone,
      address: videoData.address,
      tag_friends: videoData.tag_friends,
      description: videoData.description,
      file: videoData.images,
      app_id: videoData.app_data,
      giveaway_id: videoData.giveaway_id
    })
    setPlatformTags(videoData.app_data)
    setSelectedImages(videoData.images)
  }

  // giveaway comment

  // const onClickComment = (giveaway_id) => {
  //   setSelectedId(giveaway_id)
  //   if (!isLoadingComments) {
  //     setIsLoadingComments(true)
  //     giveawayComments(giveaway_id)
  //     setCommentSection(true)
  //   }
  // }

  const fetchPollComments = (giveaway_id, delay = 3000) => {
    setTimeout(() => {
      giveawayComments(giveaway_id)
    }, delay)
  }

  const giveawayComments = (giveaway_id) => {
    const platFormData = {
      app_id: app_id?.id,
      giveaway_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayComment}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const dataComment = res?.decryptedData?.data?.map((d, i) => ({
            id: i + 1,
            text: d.comment,
            poll_id: d.poll_id,
            is_like: d.is_like,
            reply_id: d.comment_id,
            total_like: d.total_like,
            reply: d.reply_data.map((subData, subIndex) => ({
              id: subIndex + 1,
              text: subData.comment,
              reply_id: subData.comment_id,
              total_like: subData.total_like,
              is_like: subData.is_like,
              user: {
                name: subData.user_name,
                profilePic: s3ImageProfile + subData.profile_icon
              }
            })),
            user: {
              name: d.user_name,
              profilePic: s3ImageProfile + d.profile_icon
            }
          }))
          setComments(dataComment)
          setIsLoadingComments(false)
        } else {
          setIsLoadingComments(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsLoadingComments(false)
        toast.error(error?.data?.message)
      })
  }

  const pollAddComment = (comment) => {
    const platFormData = {
      giveaway_id: selectedId,
      comment,
      app_id: app_id?.id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollAddReply = (reply_id, replyText) => {
    const platFormData = {
      giveaway_id: selectedId,
      comment_id: reply_id,
      comment: replyText,
      app_id: app_id?.id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollEditComment = (reply_id, comment) => {
    const platFormData = {
      comment_id: reply_id,
      comment
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollEditReply = (reply_id, replyId) => {
    const platFormData = {
      comment_id: reply_id,
      comment: replyId
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentEdit}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const deletePollComment = (reply_id) => {
    setIsDeleting(true)
    const data = {
      comment_id: reply_id
    }
    APICall(
      'post',
      data,
      `${EndPoints.giveawayCommentDelete}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const deletePollReply = (replyId) => {
    setIsDeleting(true)
    const data = {
      comment_id: replyId
    }
    APICall(
      'post',
      data,
      `${EndPoints.giveawayCommentDelete}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchPollComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const pollCommentLike = (reply_id) => {
    const platFormData = {
      app_id: app_id?.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          // setComments(
          //   comments.map((item) => (item.comment_id === reply_id
          //       ? { ...item, is_like: !item.is_like }
          //       : item))
          // )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const pollReplyLike = (reply_id) => {
    const platFormData = {
      app_id: app_id?.id,
      comment_id: reply_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setComments(
            comments.map((comment) => ({
              ...comment,
              reply: comment.reply.map((reply) => (reply.reply_id === reply_id
                  ? {
                      ...reply,
                      is_like: !reply.is_like,
                      total_like: reply.is_like
                        ? reply.total_like - 1
                        : reply.total_like + 1
                    }
                  : reply))
            }))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleAddComment = (reply) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        poll_id: selectedId,
        total_like: 0,
        reply: [],
        user: {
          name: profileData?.user_name,
          profilePic: s3ImageProfile + profileData.profile_icon
        }
      }
      setComments([newCommentObj, ...comments])
      setNewComment('')
    }
    pollAddComment(reply)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.reply_id === commentId) {
        return {
          ...comment,
          is_like: !comment.is_like,
          total_like: comment.is_like
            ? comment.total_like - 1
            : comment.total_like + 1
        }
      }
      return comment
    })
    setComments(updatedComments)
    pollCommentLike(commentId)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.reply_id === commentId) {
        return {
          ...comment,
          reply: [
            {
              id: (comment?.reply?.length || 0) + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: profileData.user_name,
                profilePic: s3ImageProfile + profileData.profile_icon
              }
            },
            ...comment.reply
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    pollAddReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.reply_id === commentId) {
        return {
          ...comment,
          reply: comment.reply.filter((reply) => reply.reply_id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    deletePollReply(replyId)
  }

  const toggleReplies = (commentId, forceCloseAll = false) => {
    if (forceCloseAll) {
      setShowReplies({})
    } else {
      setShowReplies((prevState) => ({
        // ...prevState,
        [commentId]: !prevState[commentId]
      }))
    }
    setShowReplied('')
  }

  const handleCommentCancel = () => {
    // getGiveaways()
    setCommentSection(false)
    commentClear()
    toggleReplies(null, true)
    setIsLoadingComments(false)
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.reply_id === commentId
        ? { ...comment, text: editedCommentText }
        : comment))
    pollEditComment(commentId, reply)
    setComments(updatedComments)
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => (comment.reply_id === commentId
        ? {
            ...comment,
            reply: comment.reply.map((reply) => (reply.reply_id === replyId
                ? { ...reply, text: editedReplyText }
                : reply))
          }
        : comment))
    pollEditReply(replyId, editedReplyText)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  const commentPerPage = 5
  const indexOfLastComment = currentCommentPage * commentPerPage
  const indexOfFirstComment = indexOfLastComment - commentPerPage
  const commentsList = comments.slice(indexOfFirstComment, indexOfLastComment)
  const totalComment = comments.length
  const totalCommentPages = Math.ceil(totalComment / commentPerPage)

  useEffect(() => {
    if (currentCommentPage > totalCommentPages) {
      setCurrentCommentPage(totalCommentPages > 0 ? totalCommentPages : 1)
    }
  }, [totalCommentPages, currentCommentPage])

  const initialized = useRef(false)

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      window.scroll(0, 0)
      setIsLoading(true)
      getGiveaways(null, currentPage)
      getBrandListApiCall()
      getTypeListApiCall()
    }
  }, [])

  useEffect(() => {
    if (!isEditing) {
      validateForm()
    }
  }, [inputFields])

  useEffect(() => {
    if (isEditing) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEditing])

  useEffect(() => {
    if (
      ShowCommunityModel ||
      ShowCommunityGiveawayCover ||
      ShowGiveawayProduct ||
      ShowProfileModel ||
      commentSection ||
      ShowConfirmModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [
    ShowCommunityModel,
    ShowCommunityGiveawayCover,
    ShowGiveawayProduct,
    ShowProfileModel,
    commentSection,
    ShowConfirmModel
  ])

  return (
    <div className="giveaway">
      {Giveaways?.length > 0 && (
        <div className="discussions-wrapper-header common-contnt-wrapper">
          <div className="tag_link">
            <h2 className="uppercase">{English.G172}</h2>
            <div className="add-video-rows">
              <CommonButton
                label="Create Giveaway"
                onClick={AddGiveway}
                isPrimary
                className="add_new_content"
              />
            </div>
          </div>
        </div>
      )}
      <div
        className={
          showCount !== 1 &&
          'recent-video-component recent-shots-component common-card-body add-shots-component'
        }
      >
        {Giveaways?.length === 0 && !isLoading ? (
          <CommonEmptyData
            image={Images.giveaway_icon}
            mainTitle="No giveaways Yet"
            descriptionText="Add giveaways to show here"
            onClick={AddGiveway}
            addIcon={Images.plusrounded}
            btnText="Create Giveaway"
            classNameDescription="font-normal text-[#7A7474] text-[16px]"
          />
        ) : isLoading ? (
          <div className="add-giveways-item mb-30">
            <div className="add-giveways-cover">
              {/* <Skeleton className="add-giveways-cover" width={502} height={390} /> */}
            </div>
            <div className="add-giveways-details">
              <h4 className="add-value">
                <Skeleton className="add-value" width={155} height={29} />
              </h4>
              <div className="d-flex align-items-center justify-content-between">
                <ul className="d-flex align-items-center">
                  <li className="add-value">
                    <Skeleton className="add-value" width={110} height={29} />
                  </li>
                  <li className="add-value">
                    <Skeleton className="add-value" width={110} height={29} />
                  </li>
                </ul>
                <span className="add-value">
                  <Skeleton className="add-value" width={121} height={29} />
                </span>
              </div>
              <p className="add-value">
                <Skeleton className="add-value" width={580} height={110} />
              </p>
              <h5 className="add-value">
                <Skeleton className="add-value" width={149} height={48} />
              </h5>
            </div>
          </div>
        ) : (
          <div className="add-giveways-container">
            {Giveaways?.map((item, index) => {
              if (index < (showCount || totalVideos)) {
                return (
                  <>
                    <GiveawayItem
                      key={index}
                      item={item}
                      index={index}
                      text="contentManager"
                      editBtn={false}
                      onClick={(e) => {
                        e?.stopPropagation()
                        onClickEditGiveaway(item)
                      }}
                      // itemClick={() => {}}
                      onClickDelete={() => {
                        setUpdatingIndex(index)
                        ShowConfirm(item.giveaway_id)
                      }}
                    />
                    <ConfirmationModal
                      isVisible={ShowConfirmModel && updatingIndex === index}
                      onConfirm={deleteGiveaway}
                      onCancel={handleCancel}
                      isLoading={loading}
                    />
                    {loading && updatingIndex === index && <Loader />}
                  </>
                )
              }
              return null
            })}
          </div>
        )}
        {totalPages > 1 && currentPage <= totalPages - 1 && (
          <div
            className="mx-auto p-5 cursor-pointer transition-transform duration-300 ease-in-out hover:scale-150"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <img
              className="justify-self-center"
              src={Images.down_arrow_icon}
              alt="load more"
            />
          </div>
        )}
      </div>

      {ShowProfileModel && (
        <div className="add-item-model edit-profile">
          <div className="add-item-content" ref={componentRef}>
            <div className="d-flex justify-content-between header">
              <h3>{English.G175}</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={close}
              >
                <span>
                  <img
                    className="close-btns"
                    src={Images.CloseIco}
                    alt="clock icon"
                  />
                </span>
              </button>
            </div>

            <div className="scroll-bars">
              <div className="item-details-input pt-0">
                <div className="list_data">
                  {giveawayListData.map((list, index) => {
                    const calculatedIndex =
                      (currentPageList - 1) * GiveawayList + index + 1
                    return (
                      <div
                        className="d-flex justify-content-start list_table"
                        key={index}
                      >
                        <span className="number_index">{calculatedIndex}</span>
                        <img
                          src={s3ImageProfile + list.icon}
                          className="profile-pic"
                          alt="profile"
                        />
                        <span className="user-name">{list.user_name}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            {totalPagesList > 1 && (
              <div className="pagination-containers-list">
                <button
                  type="button"
                  className={`pagination-button ${
                    currentPageList === 1 ? 'disabled' : ''
                  }`}
                  onClick={handlePrevPageList}
                  disabled={currentPageList === 1}
                >
                  {English.G176}
                </button>
                {renderPaginationButtonsList()}
                <button
                  type="button"
                  className={`pagination-button ${
                    currentPageList === totalPagesList ? 'disabled' : ''
                  }`}
                  onClick={handleNextPageList}
                  disabled={currentPageList === totalPagesList}
                >
                  {English.G69}
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {commentSection && (
        <CommentModal
          title={English.G177}
          comments={comments}
          setComments={setComments}
          HandleCancel={handleCommentCancel}
          commentIndex={commentIndex}
          commentsList={commentsList}
          editCommentId={editCommentId}
          editReplyId={editReplyId}
          editedCommentText={editedCommentText}
          editedReplyText={editedReplyText}
          handleAddComment={handleAddComment}
          handleAddReply={handleAddReply}
          handleDeleteReply={handleDeleteReply}
          handleEditComment={handleEditComment}
          handleEditReply={handleEditReply}
          handleLikeComment={handleLikeComment}
          shotCommentReplyLike={pollReplyLike}
          handleSaveComment={handleSaveComment}
          handleSaveReply={handleSaveReply}
          isLoadingComments={isLoadingComments}
          newComment={newComment}
          profileData={profileData}
          setCommentIndex={setCommentIndex}
          setEditedCommentText={setEditedCommentText}
          setEditedReplyText={setEditedReplyText}
          setNewComment={setNewComment}
          setShowReplied={setShowReplied}
          shotCommentDelete={deletePollComment}
          showReplies={showReplies}
          toggleReplies={toggleReplies}
          isDeleting={isDeleting}
          currentPageComment={currentCommentPage}
          setCurrentPageComment={setCurrentCommentPage}
          Replied={Replied}
        />
      )}

      {ShowCommunityModel && (
        <CommonModalMain
          showCross
          title={English.A214}
          isPrevious={English.G116}
          isNext={English.G117}
          onPressClose={handleClose}
          onPressSave={HandleDiscussion}
          onPressBack={handleClose}
          showFooter
          isButtonDisabled={!isFormComplete || !selectedImages.length === 4}
          loading={loadingAdd}
        >
          <div className="w-full h-full flex flex-col !gap-5 justify-between overflow-y-auto">
            <div className="item-details-input pt-0">
              <div className="input-grid">
                <div>
                  <label>{English.G179}</label>
                  <CommonInput
                    name="title"
                    placeholder={English.G162}
                    onChange={handleChange}
                    value={capitalizeFirstLetter(inputFields.title)}
                  />
                </div>
                <div>
                  <label>{English.A233}</label>
                  <div className="cooking-time-filed position-relative">
                    <CommonDateTimePicker
                      inputFields={inputFields}
                      setInputFields={setInputFields}
                      fieldName="expire_date"
                      placeholder={English.A237}
                      minDate={new Date()}
                      maxDate={new Date(new Date().getFullYear() + 10, 11, 31)}
                      popperPlacement="bottom"
                    />
                  </div>
                </div>
              </div>
              <div className="input-grid">
                <div>
                  <label>{English.G182}</label>
                  <Dropdown
                    title={getBrandName(inputFields.brand_id)}
                    onChanged={(d) => {
                      getTypeListApiCall()
                      setInputFields({
                        ...inputFields,
                        brand_id: d.id,
                        type_id: null
                      })
                    }}
                    Data={brandData.map((d) => ({
                      id: d.id,
                      title: d.brand
                    }))}
                    name="brand_id"
                    customClass={
                      inputFields.brand_id
                        ? 'selected-class'
                        : 'giveaway-dropdown'
                    }
                  />
                </div>
                <div>
                  <label>{English.G183}</label>
                  <Dropdown
                    title={getTypeName(inputFields.type_id)}
                    onChanged={(d) => {
                      setInputFields({
                        ...inputFields,
                        type_id: d.id
                      })
                    }}
                    Data={typeData.map((d) => ({
                      id: d.id,
                      title: d.type
                    }))}
                    name="type_id"
                    customClass={
                      inputFields.type_id
                        ? 'selected-class'
                        : 'giveaway-dropdown'
                    }
                  />
                </div>
              </div>
              <div>
                <label>{English.G112}</label>
                <CommonInput
                  placeholder={English.G83}
                  name="description"
                  onChange={handleChange}
                  value={capitalizeFirstLetter(inputFields.description)}
                  isTextArea
                  className="!pt-3"
                />
              </div>
            </div>
            <div className="text-center !p-[14px] flex-1 bg-[#E4E4E480] upload-input-with-border rounded-[15px] !mb-5 h-[350px]">
              <div className="text-center">
                {inputFields?.file?.length < 1 ? (
                  <CommonImageUpload
                    handleImageChange={(file) => handleImageChange(file, 'file', inputFields?.file?.length)}
                    buttonText="Add Image"
                    selectedImg=""
                    defaultImg={Images.plusGiveaway}
                    className="plsimg"
                    forImage="giveaways"
                    text="giveawayBuilder"
                    multiple
                  />
                ) : (
                  <div className="input-image-show !mt-0 justify-center h-[320px]">
                    {renderImg}
                    {inputFields?.file?.length < 6 && (
                      <div className="input-img-label !m-[15px]">
                        <CommonImageUpload
                          handleImageChange={(file) => handleImageChange(
                              file,
                              'file',
                              inputFields?.file?.length
                            )}
                          buttonText="Add Image"
                          selectedImg=""
                          defaultImg={Images.plusGiveaway}
                          className="plsimg mb-0"
                          forImage={
                            inputFields?.file?.length < 0 ? 'giveaways' : null
                          }
                          text="giveawayBuilder"
                          multiple
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </CommonModalMain>
      )}
    </div>
  )
}
export default GivewayComponent

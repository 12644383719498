/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import './ContentManager.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ReactDatePicker from 'react-datepicker'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import Video from './video'
import Podcasts from './Podcasts'
import Courses from './Courses'
import Recipies from './Recipies'
import Megazines from './Megazines'
import CommunityMegazines from './CommunityMegazines'
import Shots from './Shots'
import Views from './views'
import WatchTime from './WatchTime'
import Subscriber from './Subscribers'
// import Awards from './Awards'
import DiscussionsDetails from './DiscussionsDetails'
import PollsDetails from './PollsDetails'
import GiveawayDetails from './GiveawayDetails'
import CommonButton from '../ButtonComponent/ButtonCommon'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import ContentManagerMenu from './SubComponent/ContentManagerMenu'
import ContentAddonsScreen from './SubComponent/ContentAddonsScreen'

const ContentManager = () => {
  const storeInfo = useSelector((state) => state.user?.storeInfo)
  const navigate = useNavigate()

  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedSubTab, setSelectedSubTab] = useState(0)

  const handleNavigation = (index) => {
    navigate('/add-component', { state: { defaultIndex: index } })
  }

  const hasStoreAddOn = storeInfo?.some((item) => item?.add_ons_data?.some((addOn) => ['Community', 'Entertainment'].includes(addOn.name?.trim())))

  return (
    <div className="content-manager">
      {/* <div className="content-manager-head"> */}
      {hasStoreAddOn &&
      <div className="common-card-body p-20 !shadow-none">
        <div className="">
          {selectedTab === 0 && (
          <div className="d-flex align-items-center justify-content-between flex-wrap !mb-5">
            <div className="text-Main text-[36px] italic font-black mb-0 uppercase">
              {English.G234}
            </div>
          </div>
          )}
          <ContentManagerMenu
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            selectedSubTab={selectedSubTab}
            setSelectedSubTab={setSelectedSubTab}
          />
        </div>
      </div>}
      {!hasStoreAddOn && <ContentAddonsScreen />}
    </div>
  )
}
export default ContentManager

/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lone-blocks */
/* eslint-disable max-len */
// /* eslint-disable import/no-duplicates */
// import React, { useState } from 'react'
// import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
// import ReactDatePicker from 'react-datepicker'
// import { Link } from 'react-router-dom'
// import { Swiper, SwiperSlide } from 'swiper/react'
// import { Navigation, Pagination } from 'swiper/modules'
// import OrderChart from './chart'
// import CustomerChart from './chart'
// import ProductChart from './chart'
// import RatingStar from '../../images/rating-start.svg'
// import Location from '../../images/Locations.svg'
// import threedot from '../../images/three-dot.svg'

// const Hotels = () => {
//   const items = [
//     {
//       cover: './images/hotels/3.jpg',
//       name: 'Hotel Name',
//       offerPrice: '€30',
//       location: '3517 W. Gray St. Utica, ',
//       rating: '4.9'
//     },
//     {
//       cover: './images/hotels/1.jpg',
//       name: 'Hotel Name',
//       offerPrice: '€30',
//       location: '3517 W. Gray St. Utica, ',
//       rating: '4.9'
//     },
//     {
//       cover: './images/hotels/2.jpg',
//       name: 'Hotel Name',
//       offerPrice: '€30',
//       location: '3517 W. Gray St. Utica, ',
//       rating: '4.9'
//     },
//     {
//       cover: './images/hotels/3.jpg',
//       name: 'Hotel Name',
//       offerPrice: '€30',
//       location: '3517 W. Gray St. Utica, ',
//       rating: '4.9'
//     }
//   ]
//   const itemsSlide = [
//     {
//       img: './images/hotels/2.jpg'
//     },
//     {
//       img: './images/hotels/3.jpg'
//     },
//     {
//       img: './images/hotels/6.jpg'
//     },
//     {
//       img: './images/hotels/7.jpg'
//     }
//   ]
//   const itemsRooms = [
//     {
//       img: './images/rooms/5.jpg',
//       name: 'Bedroom 3',
//       detail: '1 sofa bed'
//     },
//     {
//       img: './images/rooms/5.jpg',
//       name: 'Bedroom 3',
//       detail: '1 sofa bed'
//     }
//   ]
//   const booking = [
//     {
//       profile: './images/task-profile1.png',
//       name: 'Jacob Jones',
//       guest: '3',
//       date: '9/23/16',
//       status: 'Paid'
//     },
//     {
//       profile: './images/task-profile1.png',
//       name: 'Jacob Jones',
//       guest: '3',
//       date: '9/23/16',
//       status: 'Pending'
//     },
//     {
//       profile: './images/task-profile1.png',
//       name: 'Jacob Jones',
//       guest: '3',
//       date: '9/23/16',
//       status: 'Paid'
//     },
//     {
//       profile: './images/task-profile1.png',
//       name: 'Jacob Jones',
//       guest: '3',
//       date: '9/23/16',
//       status: 'Paid'
//     },
//     {
//       profile: './images/task-profile1.png',
//       name: 'Jacob Jones',
//       guest: '3',
//       date: '9/23/16',
//       status: 'Paid'
//     },
//     {
//       profile: './images/task-profile1.png',
//       name: 'Jacob Jones',
//       guest: '3',
//       date: '9/23/16',
//       status: 'Paid'
//     },
//     {
//       profile: './images/task-profile1.png',
//       name: 'Jacob Jones',
//       guest: '3',
//       date: '9/23/16',
//       status: 'Paid'
//     }
//   ]
//   const travel = [
//     {
//       profile: './images/hotels/1.jpg',
//       period: '10 march - 18 march',
//       place: 'Kamula Villa',
//       name: 'Ankit Muller',
//       text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Faucibus vestibulum sagittis  sit amet consectet.'
//     },
//     {
//       profile: './images/hotels/3.jpg',
//       period: '10 march - 18 march',
//       place: 'Kamula Villa',
//       name: 'Ankit Muller',
//       text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Faucibus vestibulum sagittis  sit amet consectet.'
//     },
//     {
//       profile: './images/hotels/1.jpg',
//       period: '10 march - 18 march',
//       place: 'Kamula Villa',
//       name: 'Ankit Muller',
//       text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Faucibus vestibulum sagittis  sit amet consectet.'
//     },
//     {
//       profile: './images/hotels/1.jpg',
//       period: '10 march - 18 march',
//       place: 'Kamula Villa',
//       name: 'Ankit Muller',
//       text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Faucibus vestibulum sagittis  sit amet consectet.'
//     }
//   ]
//   const [startDate, setStartDate] = useState(new Date())

//   const generateStars = (rating) => {
//     const totalStars = 5
//     const filledStars = Math.min(Math.floor(parseFloat(rating)), totalStars)
//     const stars = []
//     for (let i = 0; i < filledStars; i++) {
//       stars.push(<img alt="" key={i} src="./images/star.svg" />)
//     }
//     for (let i = filledStars; i < totalStars; i++) {
//       stars.push(<img alt="" key={i} src="./images/star-blank.svg" />)
//     }
//     return stars
//   }

//   const [visibleItems1, setVisibleItems1] = useState(6)
//   const [visibleItems2, setVisibleItems2] = useState(3)
//   const [visibleItems3, setVisibleItems3] = useState(1)

//   const showMoreItems1 = () => {
//     setVisibleItems1((prevVisibleItems) => prevVisibleItems + 3)
//   }
//   const showMoreItems2 = () => {
//     setVisibleItems2((prevVisibleItems) => prevVisibleItems + 3)
//   }
//   const showMoreItems3 = () => {
//     setVisibleItems3((prevVisibleItems) => prevVisibleItems + 3)
//   }

//   return (
//     <div className="content-manager">
//       <div className="content-manager-Statistics">
//         <div className="content-manager-Statistics-chart">
//           <div className="statistic-chart-container position-relative">
//             <h4>Local Overview</h4>
//             <div>
//               <Tabs>
//                 <div>
//                   <TabList>
//                     <Tab>Hotels</Tab>
//                     <Tab>Restaurants</Tab>
//                     <Tab>Gym</Tab>
//                   </TabList>
//                   <div className="drop-and-date top-40">
//                     <ReactDatePicker
//                       selected={startDate}
//                       onChange={(date) => setStartDate(date)}
//                       showYearPicker
//                       dateFormat="yyyy"
//                     />
//                   </div>
//                 </div>

//                 <TabPanel>
//                   <OrderChart />
//                 </TabPanel>
//                 <TabPanel>
//                   <ProductChart />
//                 </TabPanel>
//                 <TabPanel>
//                   <CustomerChart />
//                 </TabPanel>
//               </Tabs>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="offers--hotel common-card-body">
//         <div className="services-container recent-video-component ">
//           <h3 className="m-0 d-flex align-items-center justify-content-between">
//             Hotels
//             {' '}
//             <Link>view all</Link>
//           </h3>
//         </div>
//         <div className="hotel-offer--grid grid-4 mt-30">
//           {items.map((item) => (
//             <div className="hotel-offer-grid--item" key={item.name}>
//               <div
//                 className="hotel-offer--cover height-184"
//                 style={{
//                   background: `url(${item.cover})`,
//                   backgroundRepeat: 'no-repeat',
//                   backgroundSize: 'cover',
//                   backgroundPosition: 'center'
//                 }}
//               >
//                 <div className="rating">
//                   <img alt="" src={RatingStar} />
//                   <span>{item.rating}</span>
//                 </div>
//               </div>
//               <div className="hotel-offer-grid--detail">
//                 <div className="d-flex align-items-center justify-content-between">
//                   <h6>{item.name}</h6>
//                   <p>
//                     {item.offerPrice}
//                     <span> / Night</span>
//                   </p>
//                 </div>
//                 <div className="d-flex align-items-center justify-content-between">
//                   <span className="location d-flex align-items-center col-gap-10">
//                     <img alt="" src={Location} />
//                     <span>{item.location}</span>
//                   </span>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>

//       <div className="d-flex justify-content-between mt-30">
//         <div className="common-card-body local-left-sec d-flex col-gap-20">
//           <div className="common-shadow w-50 d-flex flex-column justify-content-between">
//             <div>
//               <div className="services-container recent-video-component mb-30">
//                 <h3 className="m-0 d-flex align-items-center justify-content-between">
//                   Booking List
//                   {' '}
//                   <Link className="border-0">
//                     <img
//                       alt=""
//                       src={threedot}
//                       style={{ transform: 'rotate(90deg)' }}
//                     />
//                   </Link>
//                 </h3>
//               </div>
//               <div className="booking--list">
//                 {booking.slice(0, visibleItems1).map((item) => (
//                   <div className="booking-list--item" key={item.status}>
//                     <div className="booking-list--profile">
//                       <img alt="" src={item.profile} />
//                       <ul>
//                         <li className="list-profile--name">{item.name}</li>
//                         <li className="number-of-guest">
//                           {item.guest}
//                           {' '}
//                           guests
//                         </li>
//                       </ul>
//                     </div>
//                     <div className="text-end">
//                       <span className="booking-list--date">{item.date}</span>
//                       <p className="booking--status" id={item.status}>
//                         {item.status}
//                       </p>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//             {booking.length > visibleItems1 && (
//               <div className="hotel-btn mt-40">
//                 <button type="button" className="gredient-txt" onClick={showMoreItems1}>
//                   <span>View All</span>
//                 </button>
//               </div>
//             )}
//           </div>
//           <div className="common-shadow w-50 d-flex flex-column justify-content-between">
//             <div>
//               <div className="services-container recent-video-component mb-30">
//                 <h3 className="m-0 d-flex align-items-center justify-content-between">
//                   Previous Travel
//                   {' '}
//                   <Link className="border-0">
//                     <img
//                       alt=""
//                       src={threedot}
//                       style={{ transform: 'rotate(90deg)' }}
//                     />
//                   </Link>
//                 </h3>
//               </div>
//               <div className="travel--detail">
//                 {travel.slice(0, visibleItems2).map((item) => (
//                   <div className="travel-detail--item" key={item}>
//                     <div className="travel--profile">
//                       <img alt="" src={item.profile} />
//                       <ul>
//                         <li className="travel-place">{item.place}</li>
//                         <li className="travel-period">{item.period}</li>
//                       </ul>
//                     </div>
//                     <div className="about-travel">
//                       <span>{item.text}</span>
//                       <p>
//                         by
//                         {item.name}
//                       </p>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//             {travel.length > visibleItems2 && (
//               <div className="hotel-btn">
//                 <button type="button" className="gredient-txt" onClick={showMoreItems2}>
//                   <span>View All</span>
//                 </button>
//               </div>
//             )}
//           </div>
//         </div>
//         <div className="common-card-body local-right-sec recent-video-component">
//           <div className="booking-list--profile">
//             <img alt="" src="./images/task-profile1.png" />
//             <ul>
//               <li className="list-profile--name">Jacob Jones</li>
//               <li className="number-of-guest">@jacob_jones</li>
//             </ul>
//           </div>
//           <div className="devider mb-3- mt-30" />
//           <div className="hotel-swiper-slider">
//             <div className="d-flex justify-content-between align-items-center">
//               <h3 className="m-0">Shikara Hotel</h3>
//               <div className="rating-star-container">
//                 {generateStars(4.5)}
//                 {' '}
//                 4.5 rating
//               </div>
//             </div>
//             <div className="slider-for-reviews slider-local-- position-relative slider-for-local">
//               <Swiper
//                 slidesPerView={1}
//                 navigation
//                 pagination={false}
//                 modules={[Navigation, Pagination]}
//                 className="mySwiper-story review-slider position-static"
//               >
//                 {itemsSlide.map((item) => (
//                   <SwiperSlide key={item}>
//                     <div>
//                       <img alt="" src={item.img} />
//                     </div>
//                   </SwiperSlide>
//                 ))}
//               </Swiper>
//             </div>
//             <div className="local-tabs">
//               <Tabs>
//                 <TabList>
//                   <Tab>Overview</Tab>
//                   <Tab>{English.G287}</Tab>
//                   <Tab>Reviews</Tab>
//                 </TabList>

//                 <TabPanel>
//                   <div className="local-component">
//                     <p>
//                       Lorem ipsum dolor sit amet, consecte adipiscing elit.
//                       Maecenas purus varius ullamcorper consequat, ullamcorper a
//                       nulla enim. Fusce quis turpis pretium amet. Iaculis morbi
//                       diam nunc vestibulum, nec sed. Ac accumsan nunc augue amet
//                       odio et. Adipiscing vestibulum scelerisque integer ut diam
//                       cras. Viverra proin ut arcu aliquam at odio orci, cras.
//                       Tristique aliquam.
//                     </p>
//                     <h3 className="m-0 mt-30">Location</h3>
//                     <div className="map mb-4 mt-20">
//                       <iframe
//                         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d118147.82113802907!2d70.7387235299145!3d22.273466080337368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959c98ac71cdf0f%3A0x76dd15cfbe93ad3b!2sRajkot%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1706178917842!5m2!1sen!2sin"
//                         width="100%"
//                         height="197"
//                         allowfullscreen=""
//                         loading="lazy"
//                         referrerPolicy="no-referrer-when-downgrade"
//                         style={{ borderRadius: '15px' }}
//                         title="location"
//                       />
//                     </div>
//                   </div>
//                 </TabPanel>
//                 <TabPanel>
//                   <div className="local-component">
//                     <h3 className="m-0 gredient-txt">{English.G287}</h3>
//                     {itemsRooms.slice(0, visibleItems3).map((item) => (
//                       <div key={item} className="mt-3">
//                         <img alt="" src={item.img} />
//                         <h3 className="m-0">{item.name}</h3>
//                         <span>{item.detail}</span>
//                       </div>
//                     ))}
//                   </div>
//                   {itemsRooms.length > visibleItems3 && (
//                     <div className="hotel-btn">
//                       <button type="button" className="gredient-txt" onClick={showMoreItems3}>
//                         <span>View All</span>
//                       </button>
//                     </div>
//                   )}
//                 </TabPanel>
//                 <TabPanel>3</TabPanel>
//               </Tabs>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }
// export default Hotels

/* eslint-disable import/no-duplicates */
import React, { useEffect, useRef, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useNavigate } from 'react-router-dom'
import ReactDatePicker from 'react-datepicker'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import _, { cloneDeep } from 'lodash'
import { useSelector } from 'react-redux'
import OrderChart from './chart'
import Dropdown from '../Dropdown/Dropdown'
import RoomAdd from './HotelComponent/RoomAdd'
import Discount from './HotelComponent/Discount'
import HotelPopup from './addPopup/hotelPopup'
import CommonButton from '../ButtonComponent/ButtonCommon'
import { s3ImageHotel, urlSystemIcon } from '../../services/Service'
import { compressImage, dataDecrypt } from '../../services/http-services'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import Loader from '../Loader/Loader'
import CommonInput from '../InputComponet/CommonInput'
import {
  capitalizeFirstLetter,
  // ForMobileNumber,
  lockScroll,
  normalizeAddress,
  unlockScroll
} from '../../helpers/Utility'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import Pagination from '../Pagination/Pagination'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import { emitter, EventType } from '../../helpers/Emitter'
// import HotelDetails from './HotelDetails'

const Hotels = () => {
  const userData = useSelector((state) => state.user?.userData)
  const [startDate, setStartDate] = useState(new Date())

  const [isPlatFormModal, setIsPlatFormModal] = useState(false)
  const [isHotel, setIsHotel] = useState(false)
  const [isHotelLocationList, setIsHotelLocationList] = useState([])
  const [isHotelData, setIsHotelData] = useState([])
  const [isHotelId, setIsHotelId] = useState()
  // const [isHotelIds, setIsHotelIds] = useState()
  const [inputFields, setInputFields] = useState({
    city_id: '',
    contact_name: '',
    contact_designation: '',
    contact_phone: '',
    contact_profile_pic: '',
    contact_id: '',
    hotel_id: isHotelId
  })
  const [isParticularHotelData, setIsParticularHotelData] = useState([])
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [loading, setLoading] = useState(false)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [ShowConfirmHotelModel, setShowConfirmHotelModel] = useState(false)
  const [delHotelId, setDelHotelId] = useState()
  const [editData, setEditData] = useState({})
  const [appData, setAppData] = useState([])
  // const [particularHotelDeatils, setParticularHotelDeatils] = useState(false)
  const [personDetails, setPersonDetails] = useState(false)
  const [contactIndex, setContactIndex] = useState(null)
  const [delContactId, setDelContactId] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const [isHotelEdit, setIsHotelEdit] = useState(false)
  const [isHotelLoading, setIsHotelLoading] = useState(false)
  // const [isParticularLoading, setIsParticularLoading] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [isShowHotelList, setIsShowHotelList] = useState(true)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [isStep4Modified, setIsStep4Modified] = useState(false)
  const [initialInputFields, setInitialInputFields] = useState({
    contact_name: '',
    contact_designation: '',
    // contact_phone: '',
    contact_profile_pic: ''
  })
  const [expandedIndex, setExpandedIndex] = useState(null)
  const [isPublish, setIsPublish] = useState(true)
  const [isAddingNewHotel, setIsAddingNewHotel] = useState(false)

  const initialized = useRef()
  const navigate = useNavigate()

  const handleCancels = () => {
    const resetAppData = appData.map((item) => ({
      ...item,
      checked: false
    }))
    setAppData(resetAppData)
    setIsPlatFormModal(false)
  }

  const onClickPublish = () => {
    setIsPublish(true)
    setIsPlatFormModal(true)
  }

  const onClickUnpublish = () => {
    setIsPublish(false)
    setIsPlatFormModal(true)
  }
  const clearValues = () => {
    setIsParticularHotelData([])
  }

  const clearContactValue = () => {
    setInputFields({
      ...inputFields,
      city_id: '',
      contact_name: '',
      contact_designation: '',
      // contact_phone: '',
      contact_profile_pic: '',
      contact_id: ''
    })
    setIsEdit(false)
  }

  const onClickEdit = (data) => {
    setIsHotel(true)
    setIsHotelEdit(true)
    setEditData({
      hotel_id: data?.hotel_id,
      location_title: data?.location_title,
      name: data?.name,
      description: data?.description,
      country_id: data?.country_id,
      province_id: data?.province_id,
      city_id: data?.city_id,
      zip_code: data?.zip_code,
      longitude: data?.longitude,
      latitude: data?.latitude,
      website: data?.website,
      address: normalizeAddress(data?.address),
      amenities: data?.amenities?.map((amenity) => amenity.amenities_id) || [],
      price_range:
        data?.min_price && data?.max_price
          ? `${data?.min_price} - ${data?.max_price}`
          : '',
      min_price: data?.min_price,
      max_price: data?.max_price,
      logo: data?.logo,
      images: data?.images,
      contact_name1: data?.contact_details?.[0]?.contact_name ?? '',
      contact_name2: data?.contact_details?.[1]?.contact_name ?? '',
      contact_designation1:
        data?.contact_details?.[0]?.contact_designation ?? '',
      contact_designation2:
        data?.contact_details?.[1]?.contact_designation ?? '',
      // contact_phone1: data?.contact_details?.[0]?.contact_phone ?? null,
      // contact_phone2: data?.contact_details?.[1]?.contact_phone ?? null,
      contact_profile_pic: [
        data?.contact_details?.[0]?.contact_profile_pic,
        data?.contact_details?.[1]?.contact_profile_pic
      ]
    })
  }

  const clickNewHotelAdd = () => {
    setIsHotel(true)
    setIsAddingNewHotel(true)
    setEditData({})
  }

  const handleCancel = () => {
    setIsHotel(false)
    setIsAddingNewHotel(false)
  }
  const handleDeleteCancel = () => {
    setShowConfirmModel(false)
    setShowConfirmHotelModel(false)
  }

  const editOnClick = (item) => {
    particularHotelData(item.hotel_id, 'isEdit')
  }

  const onChange = (e) => {
    setInputFields((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const onClickContactEdit = (item = {}) => {
    setPersonDetails(true)
    if (Object.keys(item).length > 0) {
      setIsEdit(true)
      const updatedInputFields = {
        hotel_id: isHotelId,
        contact_name: item.contact_name,
        contact_designation: item.contact_designation,
        // contact_phone: item.contact_phone,
        contact_profile_pic: item.contact_profile_pic,
        contact_id: item.contact_id
      }
      setInputFields(updatedInputFields)
      setInitialInputFields(updatedInputFields)
    }
  }

  const cancelContact = () => {
    setPersonDetails(false)
    clearContactValue()
  }

  const ShowConfirm = (videoId) => {
    setDelHotelId(videoId)
    setShowConfirmHotelModel(true)
  }

  const ShowContactConfirm = (videoId) => {
    setDelContactId(videoId)
    setShowConfirmModel(true)
  }
  const showAllHotelList = () => {
    setIsShowHotelList(true)
    setIsShow(false)
  }

  const onUploadImage = async (file, fieldName) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'hotel_contact')

        try {
          const res = await APICall(
            'post',
            coverFormData,
            '',
            'imageUploadLocal',
            true
          )

          if (res.status === 200 && res.data?.code === 200) {
            const decryptedCoverData = dataDecrypt(res.data.data)
            setInputFields((prev) => {
              return {
                ...prev,
                [fieldName]: decryptedCoverData
              }
            })
          } else {
            toast.error(res?.data?.message || 'Error uploading image')
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || 'Upload failed')
        }
      }

      reader.readAsDataURL(file)
    }
  }

  const hotelLocationListData = () => {
    const payload = {
      user_id: userData.user_id
    }
    APICall('post', payload, `${EndPoints.hotelLocation}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsHotelLocationList(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const allHotelData = (id, currentPage) => {
    setIsHotelLoading(true)
    const payload = {
      city_id: id
    }
    APICall(
      'post',
      payload || {},
      `${EndPoints.getHotel}?page=${currentPage}`,
      'local'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsHotelData(res?.decryptedData.data)
          setIsHotelLoading(false)
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
        } else {
          toast.error(res?.data?.message)
          setIsHotelLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsHotelLoading(false)
      })
  }

  const particularHotelData = (id) => {
    // setIsParticularLoading(true)
    setIsHotelId(id)

    navigate('/local/hotel-details', { state: { hotelId: id } })
    // const payload = {
    //   hotel_id: id
    // }
    // APICall('post', payload, `${EndPoints.particularHotel}`, 'local')
    //   .then((res) => {
    //     if (res.status === 200 && res.data?.code === 200) {
    //       setIsParticularHotelData(res?.decryptedData)
    //       setParticularHotelDeatils(true)
    //       getApplicationsData()
    //       setIsParticularLoading(false)
    //       if (type === 'isEdit') {
    //         onClickEdit(res?.decryptedData[0])
    //       }
    //     } else {
    //       setIsParticularLoading(false)
    //       toast.error(res?.data?.message)
    //     }
    //   })
    //   .catch((error) => {
    //     setIsParticularLoading(false)
    //     toast.error(error?.data?.message)
    //   })
  }

  const deleteHotel = () => {
    const data = {
      hotel_id: delHotelId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.deleteHotel}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmHotelModel(false)
          setTimeout(() => {
            allHotelData(null, currentPage)
            toast.success(res?.data?.message)
          }, 3000)
          setLoading(false)
        } else {
          toast.error(res?.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  // const hotelLikeData = (hotel_id) => {
  //   const platFormData = {
  //     app_id: parsedData?.id,
  //     hotel_id
  //   }
  //   hotelLike(platFormData).then((res) => {
  //     if (res.data.success) {
  //       toast.success(res?.data?.message)
  //     }
  //   })
  // APICall('post', platFormData, `${EndPoints.hotelLike}`, 'local')
  //     .then((res) => {
  //       if (res.status === 200 && res.data?.code === 200) {
  //         toast.success(res?.data?.message)
  //       } else {
  //         toast.error(res?.data?.message)
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error?.data?.message)
  //     })
  // }

  const getApplicationsData = async () => {
    APICall('get', '', EndPoints.applications, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedData = res?.decryptedData
          setAppData(decryptedData)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const HotelPublishApi = async (app_id) => {
    const publishState = app_id ? false : isPublish
    const data = {
      hotel_id: isHotelId,
      app_id: app_id
        ? [app_id]
        : appData.filter(({ checked }) => checked).map((e) => e.id),
      publish: publishState
    }
    APICall('post', data, `${EndPoints.publishHotel}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          getApplicationsData()
          setIsPlatFormModal(false)
          toast.success(res?.data?.message)
        } else {
          toast.error(res?.data?.message)
          setIsPlatFormModal(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const hotelContactEditApi = async () => {
    const data = {
      hotel_id: isHotelId,
      contact_name: inputFields.contact_name,
      contact_designation: inputFields.contact_designation,
      // contact_phone: inputFields.contact_phone,
      contact_profile_pic: inputFields.contact_profile_pic,
      contact_id: inputFields.contact_id
    }
    if (isEdit) {
      APICall('post', data, `${EndPoints.hotelContactEdit}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setPersonDetails(false)
            setIsEdit(false)
            clearContactValue()
            setTimeout(() => {
              particularHotelData(inputFields.hotel_id)
              toast.success(res?.data?.message)
            }, 3000)
          } else {
            setIsEdit(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setIsEdit(false)
          toast.error(error?.data?.message)
        })
    } else {
      APICall('post', data, `${EndPoints.hotelContactAdd}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setPersonDetails(false)
            setTimeout(() => {
              particularHotelData(isHotelId)
              toast.success(res?.data?.message)
            }, 3000)
            clearContactValue()
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message)
        })
    }
  }

  const hotelContactDeleteApi = async () => {
    const data = {
      contact_id: delContactId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.hotelContactDelete}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setLoading(false)
          setTimeout(() => {
            particularHotelData(isHotelId)
            toast.success(res?.data?.message)
          }, 3000)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const getStoreLink = (website) => {
    if (!website) return undefined
    return website.startsWith('http') ? website : `http://${website}`
  }

  const handlePageChange = (pageNumber) => {
    allHotelData(null, pageNumber)
    setCurrentPage(pageNumber)
  }

  const isStep4Valid = () => {
    return (
      isStep4Modified &&
      inputFields.contact_profile_pic.length > 0 &&
      inputFields.contact_name &&
      inputFields.contact_designation
      // inputFields.contact_phone.length > 10
    )
  }

  const getCityName = (city_id) => {
    const categoryObj = isHotelLocationList.find(
      (cat) => cat.city_id === city_id
    )
    return categoryObj ? categoryObj.city_name : English.G272
  }

  useEffect(() => {
    setIsStep4Modified(
      inputFields.contact_profile_pic !==
        initialInputFields.contact_profile_pic ||
        inputFields.contact_name !== initialInputFields.contact_name ||
        inputFields.contact_designation !==
          initialInputFields.contact_designation
      // inputFields.contact_phone !== initialInputFields.contact_phone
    )
  }, [inputFields])

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      hotelLocationListData()
      allHotelData(null, currentPage)
    }
  }, [])

  useEffect(() => {
    if (
      personDetails ||
      isHotel ||
      ShowConfirmModel ||
      ShowConfirmHotelModel ||
      isPlatFormModal
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [
    personDetails,
    isHotel,
    ShowConfirmModel,
    ShowConfirmHotelModel,
    isPlatFormModal
  ])

  const [visibleHotels, setVisibleHotels] = useState(3)
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleItems = () => {
    if (isExpanded) {
      setVisibleHotels(3)
    } else {
      setVisibleHotels(isHotelData.length)
    }
    setIsExpanded(!isExpanded)
  }

  useEffect(() => {
    const event = emitter.addListener(EventType.addHotelList, ({ action }) => {
      if (action === 'add') {
        allHotelData(null, currentPage)
      }
    })
    return () => event.remove()
  }, [isHotelData])

  return (
    // <div className="content-manager">
    <div>
      {isHotelData?.length > 0 && (
        <div className="mt-2">
          <div className="offers--hotel p-2 mb-3 hidden">
            <div className="location_section">
              <Dropdown
                title={getCityName(inputFields.city_id)}
                name="type_id"
                onChanged={(d) => {
                  setInputFields({
                    ...inputFields,
                    city_id: d.id
                  })
                  allHotelData(d.id, currentPage)
                }}
                Data={isHotelLocationList.map((d) => ({
                  title: d.city_name,
                  id: d.city_id
                }))}
                customClass="hotels_dropdown"
              />
              {isShow ? (
                <h1 className="hotel_text" onClick={() => showAllHotelList()}>
                  {English.G270}
                </h1>
              ) : (
                <h1 className="hotel_text" onClick={clickNewHotelAdd}>
                  {English.G271}
                </h1>
              )}
            </div>
          </div>

          {isShowHotelList && (
            <div className="px-3">
              {/* <div className="offers--hotel common-card-body"> */}
              <h4 className="bg-primaryGradient bg-clip-text text-transparent text-xl italic font-black uppercase pe-2">
                Hotel
              </h4>
              <div className="hotel-offer--grid grid-3 mt-2.5 !mb-5">
                {isHotelData?.length === 0 && !isHotelLoading ? (
                  <div className="awards-item">
                    <div className="add-profile-video-cover add-product-video-cover">
                      <button type="button" onClick={clickNewHotelAdd}>
                        <img src={Images.Plus} alt="add library" />
                      </button>
                    </div>
                    <div className="product-footer">
                      <p className="add-value" />
                      <span className="add-value" />
                    </div>
                  </div>
                ) : isHotelLoading ? (
                  <div className="awards-item">
                    <div className="add-profile-video-cover add-product-video-cover" />
                    <div className="product-footer">
                      <p className="add-value">
                        <Skeleton
                          className="add-value"
                          width={202}
                          height={35}
                        />
                      </p>
                      <span className="add-value">
                        <Skeleton
                          className="add-value"
                          width={67}
                          height={35}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  isHotelData.slice(0, visibleHotels).map((item, index) => (
                    <div className="hotel-offer-grid--item" key={item.name}>
                      <div
                        className="hotel-offer--cover position-relative height-184"
                        onClick={() => {
                          if (!ShowConfirmHotelModel) {
                            setIsShow(true)
                            setIsShowHotelList(false)
                            particularHotelData(item.hotel_id)
                          }
                        }}
                        style={{
                          background:
                            item.images && item.images.length > 0
                              ? `url(${s3ImageHotel + item.images[0]}) no-repeat center/cover`
                              : 'none'
                        }}
                      >
                        {(!item.images || item.images.length === 0) && (
                          <span className="absolute text-center w-full h-full flex items-center justify-center">
                            No images available
                          </span>
                        )}
                        {item?.app_data?.length > 0 && (
                          <span
                            className={`shots-badge ${
                              item?.app_data?.length === 2
                                ? 'shots-badge-alt'
                                : ''
                            }`}
                          >
                            {item.app_data.map((data, i) => (
                              <div
                                key={i}
                                className={`badge-picture ${
                                  item?.app_data?.length === 2
                                    ? 'badge-picture-alt'
                                    : ''
                                }`}
                                style={{
                                  backgroundImage: `url(${urlSystemIcon + data.svg_icon})`,
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: '100%',
                                  backgroundPosition: 'center'
                                }}
                              />
                            ))}
                          </span>
                        )}
                        <div className="image-buttons z-1">
                          <label className="edit_event_pencil">
                            <button
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation()
                                editOnClick(item)
                              }}
                            />
                          </label>
                          <button
                            type="button"
                            className="p-0"
                            onClick={(e) => {
                              e.stopPropagation()
                              setUpdatingIndex(index)
                              ShowConfirm(item.hotel_id)
                            }}
                          />
                        </div>
                        <ConfirmationModal
                          isVisible={
                            ShowConfirmHotelModel && updatingIndex === index
                          }
                          onConfirm={deleteHotel}
                          onCancel={handleDeleteCancel}
                          isLoading={loading}
                        />
                        {loading && updatingIndex === index && <Loader />}
                      </div>
                      <div className="hotel-offer-grid--detail">
                        <div className="d-flex align-items-center justify-content-between mb-1">
                          <h6 className="local_item_name">{item.name}</h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="location d-flex align-items-center col-gap-10">
                            <span>{item.address}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
              {isHotelData?.length > 3 && (
                <button
                  type="button"
                  onClick={toggleItems}
                  className="see-more-button"
                >
                  {isExpanded ? (
                    <img
                      className="upIcon"
                      alt="Show less"
                      src={Images.DownIco}
                    />
                  ) : (
                    <img
                      className="downIcon"
                      alt="Show more"
                      src={Images.DownIco}
                    />
                  )}
                </button>
              )}
              {totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          )}
          <div className="bg-white rounded-[15px] !p-3">
            {isShow && particularHotelDeatils && (
              <div className="mt-40">
                {isParticularLoading ? (
                  <div className="particular_skeleton">
                    <div className="w-50">
                      <div className="details_section_profile_header">
                        <div className="name_logo">
                          <Skeleton circle height={50} width={50} />
                          <div>
                            <Skeleton count={1} height={30} width={200} />
                            <Skeleton count={1} height={20} width={300} />
                          </div>
                        </div>
                      </div>
                      <div className="details_section_button_row d-flex gap-2">
                        <Skeleton count={1} height={40} width={80} />
                        <Skeleton count={1} height={40} width={80} />
                      </div>

                      <div className="details_section p-3">
                        <Skeleton count={4} height={20} width="100%" />
                        <Skeleton count={1} height={20} width={150} />
                      </div>

                      <div className="contact-details">
                        <h4>
                          <Skeleton width={100} />
                        </h4>
                        <div className="mt-3 mb-3">
                          <Skeleton count={3} height={50} width="100%" />
                        </div>
                      </div>
                    </div>
                    <div className="w-50">
                      <div className="d-flex gap-3 w-100 mt-5">
                        <div className="image-placeholder w-50">
                          <Skeleton height={150} width="100%" />
                        </div>
                        <div className="image-placeholder w-50">
                          <Skeleton height={150} width="100%" />
                        </div>
                      </div>
                      <div className="d-flex gap-3 w-100">
                        <div className="image-placeholder w-50">
                          <Skeleton height={150} width="100%" />
                        </div>
                        <div className="image-placeholder w-50">
                          <Skeleton height={150} width="100%" />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  isParticularHotelData.map((data, index) => (
                    <div key={index}>
                      <div className="details_section_profile_header">
                        <div className="name_logo items-center gap-2.5">
                          {data.logo ? (
                            <img src={s3ImageHotel + data.logo} alt="profile" />
                          ) : null}
                          <div>
                            <div className="d-flex gap-3 align-items-center">
                              <h1 className="mb-0 pe-2 text-2xl leading-none italic text-Main font-black">
                                {data.name}
                              </h1>
                              {data?.app_data?.length > 0 && (
                                <div className="profile-tag tag-local position-relative">
                                  <span>{English.G274}</span>
                                  <div className="published-tooltip-main">
                                    <div className="published-tooltip">
                                      <div className="tooltip-ico" />
                                      {data.app_data.map((d, i) => (
                                        <div className="published-item" key={i}>
                                          <img
                                            src={urlSystemIcon + d.icon}
                                            alt="the-test-club"
                                            className="publish_icon"
                                          />
                                          <div
                                            className="unpublished-btn"
                                            type="button"
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              setIsPublish(false)
                                              const clone = cloneDeep(
                                                isParticularHotelData
                                              )
                                              clone[index].app_data = clone[
                                                index
                                              ].app_data.filter(
                                                (item) => item.id !== d.id
                                              )

                                              setAppData((state) => {
                                                const clone = cloneDeep(state)
                                                clone[i].checked = false
                                                return clone
                                              })
                                              setIsParticularHotelData(clone)
                                              setTimeout(() => {
                                                HotelPublishApi(d.id)
                                              }, 1000)
                                            }}
                                          >
                                            {English.G275}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <p className="mb-0 text-base font-semibold text-Main">
                              {data.address}
                            </p>
                          </div>
                        </div>
                        <div className="details_section_button_row d-flex gap-2">
                          <button
                            type="button"
                            className="local_type_cancel"
                            onClick={() => onClickEdit(data)}
                          >
                            <span>{English.G276}</span>
                          </button>
                          <button
                            type="button"
                            className="local_type_next position-relative"
                            onClick={onClickPublish}
                          >
                            <span className="d-flex align-items-center gap-3">
                              {English.G277}
                              <span className="unpublished-drop" type="button">
                                <div className="unpublished-drop-main">
                                  <div
                                    className="uppublished-drop-btn"
                                    type="button"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      onClickUnpublish()
                                    }}
                                  >
                                    {English.G275}
                                  </div>
                                </div>
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="grid grid-cols-2 pt-2.5 !gap-5">
                        <div>
                          <div
                            className={`description_link ${expandedIndex === index ? 'expanded' : ''}`}
                          >
                            <p className="text-[#303030] text-base mb-2.5">
                              {expandedIndex === index
                                ? data?.description
                                : `${data?.description?.slice(0, 180)}...`}
                              {data?.description?.length > 180 && (
                                <span
                                  className="see_more_local"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setExpandedIndex(
                                      expandedIndex === index ? null : index
                                    )
                                  }}
                                >
                                  {expandedIndex === index
                                    ? English.G193
                                    : English.G194}
                                </span>
                              )}
                            </p>
                            <div className="profile-redirect">
                              <a
                                className="gredient-txt"
                                target="_blank"
                                href={getStoreLink(data.website)}
                                rel="noreferrer"
                              >
                                <img alt="" src={Images.Redirect} />
                                <u className="border-b border-[#001597]">
                                  {data.website ? data.website : English.G278}
                                </u>
                              </a>
                            </div>
                          </div>
                          <div
                            className="contact-details"
                            style={{ width: '560px' }}
                          >
                            <h4>{English.G279}</h4>
                            <div className="mt-3 mb-3">
                              <div className="d-flex row">
                                {data?.contact_details?.map((item, index) => (
                                  <div
                                    className="col position-relative"
                                    key={index}
                                  >
                                    <div className="podcast-profile-img">
                                      <img
                                        src={
                                          s3ImageHotel +
                                          item.contact_profile_pic
                                        }
                                        alt=""
                                      />
                                      <div className="podcast-profile-detail">
                                        <span>
                                          {item?.contact_name?.slice(0, 17)}
                                          {item?.contact_name?.length > 17 &&
                                            '...'}
                                        </span>
                                        <p className="contact_designation">
                                          {item?.contact_designation?.slice(
                                            0,
                                            17
                                          )}
                                          {item?.contact_designation?.length >
                                            17 && '...'}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="local_contact_buttons z-1">
                                      <label>
                                        <button
                                          type="button"
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            onClickContactEdit(item)
                                          }}
                                          className="d-none"
                                        />
                                      </label>
                                      <button
                                        type="button"
                                        className="p-0"
                                        onClick={() => {
                                          setContactIndex(index)
                                          ShowContactConfirm(item.contact_id)
                                        }}
                                      />
                                    </div>
                                    <ConfirmationModal
                                      isVisible={
                                        ShowConfirmModel &&
                                        contactIndex === index
                                      }
                                      onConfirm={hotelContactDeleteApi}
                                      onCancel={handleDeleteCancel}
                                      isLoading={loading}
                                    />
                                    {loading && contactIndex === index && (
                                      <Loader />
                                    )}
                                  </div>
                                ))}
                                {(!data?.contact_details ||
                                  data.contact_details.length <= 1) && (
                                  <div className="col position-relative">
                                    <div
                                      className="podcast-profile-img"
                                      onClick={() => onClickContactEdit({})}
                                    >
                                      <button
                                        type="button"
                                        className="add_contact_person"
                                        onClick={() => onClickContactEdit({})}
                                      >
                                        <img
                                          className="add_image_local"
                                          src={Images.Plus}
                                          alt="add library"
                                        />
                                      </button>
                                      <div className="podcast-profile-detail new_contact_add">
                                        <span className="new_contact_add">
                                          {English.G280}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div />
                            </div>
                            <div>
                              <div className="hotel-details row">
                                <div className="col">
                                  <h5>{English.G281}</h5>
                                  <div className="podcast-profile-detail">
                                    <p className="designation">
                                      12:00 PM - 10:30 PM
                                    </p>
                                  </div>
                                </div>
                                <div className="col">
                                  <h5>{English.G282}</h5>
                                  <div className="podcast-profile-detail">
                                    <p className="designation">
                                      {English.G283}
                                      {' '}
                                      {data.min_price}
                                      {' '}
                                      -
                                      {' '}
                                      {English.G283}
                                      {' '}
                                      {data.max_price}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="hotel-details row">
                                <div className="col">
                                  <h5>{English.G284}</h5>
                                  <div className="podcast-profile-detail">
                                    <p className="designation">
                                      {data.room_types}
                                    </p>
                                  </div>
                                </div>
                                {/* <div className="col">
                              <h5>Bed Options</h5>
                              <div className="podcast-profile-detail">
                                <p className="designation">
                                  King, Queen, Twin beds
                                </p>
                              </div>
                            </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="grid grid-cols-2 gap-2.5">
                            {data.images && data.images.length > 0 ? (
                              data.images.slice(0, 4).map((item, index) => (
                                <div
                                  className="aspect-[5/3] w-full"
                                  key={index}
                                >
                                  <img
                                    key={index}
                                    src={s3ImageHotel + item}
                                    alt="hotel img"
                                    className="rounded-[10px] h-full w-full object-cover"
                                  />
                                </div>
                              ))
                            ) : (
                              <p className="no_images_text">{English.G285}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      {isPlatFormModal && (
                        <div className="add-item-model">
                          <div className="add-item-content local_type_modal">
                            <div className="type_close mb-2  local_sticky_header">
                              <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                                {English.G277}
                              </div>
                              <button
                                className="cancel_icon"
                                type="button"
                                onClick={handleCancels}
                              >
                                <img src={Images.RemoveLocal} alt="" />
                              </button>
                            </div>
                            <div className="grid grid-cols-4 !gap-5">
                              {appData.map((i, index) => {
                                const isChecked =
                                  isParticularHotelData[0]?.app_data?.some(
                                    (d) => d.id === i.id
                                  )
                                return (
                                  <div
                                    className="profile-checkbox aspect-[3/2]"
                                    key={index}
                                  >
                                    <img src={urlSystemIcon + i.icon} alt="" />
                                    <div className="custom-check-box">
                                      <CommonInput
                                        type="checkbox"
                                        value={i.id}
                                        checked={isChecked || i.checked}
                                        onChange={(e) => {
                                          const newCheckboxes = [...appData]
                                          newCheckboxes[index].checked =
                                            e.target.checked
                                          setAppData(newCheckboxes)
                                        }}
                                        className="!min-h-max"
                                      />
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                            <div className="local_button_row">
                              <CommonButton
                                label={English.G116}
                                onClick={handleCancels}
                                isPrimary={false}
                                context={English.G232}
                              />
                              <CommonButton
                                label={isPublish ? English.G128 : 'UnPublish'}
                                type="button"
                                isPrimary
                                // disabled={appData.every((i) => !i.checked)}
                                onClick={() => {
                                  const clone = cloneDeep(
                                    isParticularHotelData
                                  )
                                  const filter = appData.filter(
                                    (d) => d.checked
                                  )
                                  if (isPublish) {
                                    const isAllAlreadyExist = filter.every(
                                      (i) => _.some(
                                          clone[index].app_data,
                                          (j) => j.id === i.id
                                        )
                                    )

                                    if (isAllAlreadyExist) {
                                      return
                                    }
                                    clone[index].app_data = [
                                      ...new Set([
                                        ...clone[index].app_data,
                                        ...filter
                                      ])
                                    ]
                                  } else {
                                    clone[index].app_data = clone[
                                      index
                                    ].app_data.filter(
                                      (item) => !filter.some(
                                          (filteredItem) => filteredItem.id === item.id
                                        )
                                    )
                                  }
                                  setIsParticularHotelData(clone)
                                  HotelPublishApi()
                                }}
                                context={English.G232}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                )}
              </div>
            )}

            {isShow && (
              <div className="local-tabs section-local">
                <Tabs>
                  <div className="list_overview">
                    <TabList>
                      <Tab>
                        <span>{English.G286}</span>
                      </Tab>
                      <Tab>
                        <span>{English.G287}</span>
                      </Tab>
                      <Tab>
                        <span>{English.G288}</span>
                      </Tab>
                    </TabList>
                  </div>

                  <TabPanel>
                    {' '}
                    <div className="grid grid-cols-4 !gap-4 !mb-5">
                      <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
                        <div className="w-[50px] h-[50px]">
                          <img src={Images.Visitor} alt="visitor" />
                        </div>
                        <div className="">
                          <p className="text-Main text-base italic font-black mb-[5px] uppercase">
                            Locations
                          </p>
                          <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                            25
                            {/* <img src={Images.UpPath} alt="" />
                        <span>0%</span> */}
                          </span>
                        </div>
                      </div>
                      <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
                        <div className="">
                          <img src={Images.impressionLocal} alt="impression" />
                        </div>
                        <div className="">
                          <p className="text-Main text-base italic font-black mb-[5px] uppercase">
                            Footfall
                          </p>
                          <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                            2.5k
                            <img src={Images.UpPath} alt="" />
                            <span className="text-[#00B69B] text-sm font-semibold">
                              1.3%
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
                        <div className="">
                          <img src={Images.ClicksLocal} alt="Clicks" />
                        </div>
                        <div className="">
                          <p className="text-Main text-base italic font-black mb-[5px] uppercase">
                            Clicks
                          </p>
                          <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                            358
                            <img src={Images.UpPath} alt="" />
                            <span className="text-[#00B69B] text-sm font-semibold">
                              1.3%
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
                        <div className="">
                          <img src={Images.RequestsLocal} alt="request" />
                        </div>
                        <div className="">
                          <p className="text-Main text-base italic font-black mb-[5px] uppercase">
                            engagement
                          </p>
                          <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                            86.04%
                            <img src={Images.UpPath} alt="" />
                            <span className="text-[#00B69B] text-sm font-semibold">
                              1.3%
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="content-manager-Statistics">
                      <div className="statistic-chart-container position-relative">
                        <h4>{English.G286}</h4>
                        <div className="drop-and-date !top-2.5">
                          <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showYearPicker
                            dateFormat="yyyy"
                          />
                        </div>
                        <OrderChart />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <RoomAdd isHotelId={isHotelId} />
                  </TabPanel>
                  <TabPanel>
                    <Discount isHotelIds={isHotelId} />
                  </TabPanel>
                </Tabs>
              </div>
            )}
          </div>

          {personDetails && (
            <div className="add-item-model">
              <div className="add-item-content local_type_modal">
                <div className="type_close mb-2  local_sticky_header">
                  <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                    {English.G289}
                  </div>
                  <button
                    className="cancel_icon"
                    type="button"
                    onClick={cancelContact}
                  >
                    <img src={Images.RemoveLocal} alt="" />
                  </button>
                </div>
                <div className="item-details-input pt-0 local_contact_scroll_bars">
                  <div className="logo_btn">
                    <div className="image_upload_section">
                      <div className="add_photos mb-4 mt-2">
                        <div className="add_logo_text text-center align-self-center">
                          <CommonImageUpload
                            name="local_upload_logo"
                            handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic')}
                            index={0}
                            forImage={English.G290}
                            className="profile-upload"
                            selectedImg={
                              inputFields.contact_profile_pic
                                ? `${s3ImageHotel}${inputFields.contact_profile_pic}`
                                : ''
                            }
                            defaultImg={Images.local_add_img}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="person_detail_section">
                      <div className="input-grid">
                        <div>
                          <label>{English.G130}</label>
                          <CommonInput
                            placeholder={English.G130}
                            onChange={onChange}
                            name="contact_name"
                            value={capitalizeFirstLetter(
                              inputFields.contact_name
                            )}
                          />
                        </div>
                        <div>
                          <label>{English.G291}</label>
                          <CommonInput
                            placeholder={English.G291}
                            onChange={onChange}
                            name="contact_designation"
                            value={capitalizeFirstLetter(
                              inputFields.contact_designation
                            )}
                          />
                        </div>
                      </div>
                      {/* <div>
                    <label>{English.G186}</label>
                    <CommonInput
                      placeholder={English.G292}
                      name="contact_phone"
                      type="text"
                      pattern="/^-?\d+\.?\d*$/"
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(
                          /[^0-9+]/g,
                          ''
                        )
                        const maxLength = sanitizedValue.startsWith('+')
                          ? 13
                          : 12
                        if (sanitizedValue.length <= maxLength) {
                          onChange(e)
                        }
                      }}
                      onKeyPress={ForMobileNumber}
                      value={inputFields.contact_phone}
                    />
                  </div> */}
                    </div>
                  </div>
                </div>
                <div className="local_button_row">
                  <CommonButton
                    label={English.G116}
                    onClick={cancelContact}
                    isPrimary={false}
                    context={English.G232}
                  />
                  <CommonButton
                    label={English.G69}
                    type="button"
                    isPrimary
                    onClick={hotelContactEditApi}
                    disabled={!isStep4Valid()}
                    context={English.G232}
                  />
                </div>
              </div>
            </div>
          )}

          {isHotel && (
            <HotelPopup
              onCancel={handleCancel}
              defaultData={isAddingNewHotel ? {} : editData}
              apiSuccess={() => {
                allHotelData(null, currentPage)
                clearValues()
                setIsHotel(false)
              }}
              // particularHotelApiCall={(id) => {
              //   particularHotelData(id)
              // }}
              setIsShowHotelList={setIsShowHotelList}
              setIsShow={setIsShow}
              editHotel={isHotelEdit}
            />
          )}
          {/* <HotelDetails isHotelId={isHotelIds} /> */}
        </div>
      )}
    </div>
  )
}
export default Hotels

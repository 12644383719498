/* eslint-disable max-len */
/* eslint-disable no-tabs */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TimePicker } from 'antd'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import CommonModalMain from '../../CommonModalMain'
import { English, Images } from '../../../helpers'
import Loader from '../../Loader/Loader'
import { s3ImageProfile } from '../../../services/Service'
import CommonDatePicker from '../../CommonDatePicker/CommonDatePicker'
import CommonInput from '../../InputComponet/CommonInput'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import { compressImage } from '../../../services/http-services'
import { APICall, EndPoints } from '../../../services'
import CommonButton from '../../ButtonComponent/ButtonCommon'

const AddFeedModal = ({ onPressClose, setAddModalModel, onSubmited }) => {
  const profileData = useSelector((state) => state.user?.profileData)
  const app_id = useSelector((state) => state.user?.app_id)
  const [loader, setLoader] = useState(false)
  const [images, setImages] = useState()
  const [imageObj, setImageObj] = useState()
  const [postSchedule, setPostSchedule] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [allowComments, setAllowComments] = useState(true)
  const [visibility, setVisibility] = useState('public')
  const [tempSettings, setTempSettings] = useState({
    allowComments,
    visibility
  })
  const [inputFields, setInputFields] = useState({
    attachment: '',
    description: '',
    app_id: app_id.id,
    schedule: '',
    allow_comments: true,
    visibility: 'public'
    // location: ''
  })

  const toggleAllowComments = () => {
    setTempSettings((prev) => ({
      ...prev,
      allowComments: !prev.allowComments
    }))
  }

  const updateSchedule = (date, time) => {
    if (date && time) {
      const formattedSchedule = `${dayjs(date).format('YYYY-MM-DD')} ${time}:00`
      setInputFields((prevState) => ({
        ...prevState,
        schedule: formattedSchedule
      }))
    }
  }
  useEffect(() => {
    if (inputFields.schedule_date) {
      updateSchedule(
        inputFields.schedule_date,
        inputFields?.opening_time || '00:00'
      )
    }
  }, [inputFields.schedule_date])

  const onChangeOpenTime = (time, timeString) => {
    updateSchedule(
      inputFields.schedule.split(' ')[0] || dayjs().format('YYYY-MM-DD'),
      timeString
    )
  }

  useEffect(() => {
    setInputFields((prevState) => ({
      ...prevState,
      visibility
    }))
  }, [visibility])

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const handleSaveSettings = () => {
    setVisibility(tempSettings.visibility)
    setAllowComments(tempSettings.allowComments)
    setShowSettings(false)
    setInputFields((prevState) => ({
      ...prevState,
      allow_comments: tempSettings.allowComments ? 1 : 0
    }))
  }

  const handleImageChange = (file) => {
    const maxFileSize = 8 * 1024 * 1024

    if (file) {
      if (file.size > maxFileSize) {
        toast.error('Image size should not exceed 8 MB')
        return
      }

      const imageUrl = URL.createObjectURL(file)
      setImages(imageUrl)
      setImageObj(file)
      // setIsFormModified(true)
      // validateFields({ ...inputFields, attachment: file })
    }
  }
  const handleSubmit = async () => {
    // event.preventDefault()
    // if (isSaveButtonDisabled) return

    setLoader(true)
    const formData = new FormData()
    formData.append('file', imageObj)
    formData.append('type', 'post')

    const compressedImage = await compressImage(imageObj)
    formData.set('file', compressedImage)
    await APICall('post', formData, `${EndPoints.uploadImage}`, 'creator', true)
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          inputFields.attachment = res?.decryptedData
          APICall('post', inputFields, `${EndPoints.addPost}`, 'creator')
            .then((res) => {
              if (res.status === 200 && res.data?.code === 200) {
                toast.success(res?.data?.message)
                // setShowVideoModel({ show: false, isEditing: false })
                setLoader(false)
                // ResetValue()
                setAddModalModel(false)
                onSubmited()
              } else {
                toast.error(res?.data?.message)
                setLoader(false)
              }
            })
            .catch((error) => {
              setLoader(false)
              setAddModalModel(false)
              toast.error(error?.data?.message)
            })
        } else {
          setLoader(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((e) => {
        setLoader(false)
        toast.error(e?.data?.message)
      })
  }
  return (
    <CommonModalMain
      showCross
      title="Create Post"
      isPrevious={English.G116}
      isNext="POST"
      onPressClose={onPressClose}
      onPressSave={handleSubmit}
      onPressBack={onPressClose}
      showFooter
    >
      {loader && <Loader />}
      <div className="w-full h-full overflow-y-auto pe-1">
        <div className="flex flex-col h-full">
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <img
                className="w-[40px] h-[40px] rounded-[24px]"
                src={s3ImageProfile + profileData.profile_icon}
                alt=""
              />
              <p className="mb-0 text-Main text-base font-semibold">
                {profileData.user_name}
              </p>
            </div>
            <div className="flex items-center gap-4">
              <img
                className="cursor-pointer"
                src={Images.watch_icon}
                alt=""
                onClick={() => setPostSchedule(!postSchedule)}
              />
              <img
                className="cursor-pointer"
                onClick={() => setShowSettings(!showSettings)}
                src={Images.Settings}
                alt=""
              />
            </div>
          </div>
          {showSettings && (
            <div className="p-4 !pb-0 !mt-5 border-t border-b border-SecondaryBorder">
              <div className="flex items-center justify-between">
                <h3 className="!font-black !text-base !italic uppercase !text-Colormain !mb-0">
                  Post Settings
                </h3>
                <img
                  onClick={() => setShowSettings(!showSettings)}
                  src={Images.Error}
                  className="cursor-pointer"
                  alt=""
                />
              </div>
              <div className="flex justify-between items-center mt-2">
                <span className="text-Colormain font-semibold text-sm">
                  Allow Comments
                </span>
                <div
                  className={`w-12 h-6 flex items-center bg-${tempSettings.allowComments ? '[#01104D]' : '[#E1E2E5]'} rounded-full p-1 cursor-pointer`}
                  onClick={toggleAllowComments}
                >
                  <div
                    className={`bg-white w-5 h-5 rounded-full shadow-md transform duration-300 ${tempSettings.allowComments ? 'translate-x-[22px]' : 'translate-x-0'}`}
                  />
                </div>
              </div>
              <div className="mt-2">
                <p className="text-Colormain font-semibold text-sm">
                  Who can see your post?
                </p>
                <div className="mt-2">
                  <div className="flex items-center gap-2 cursor-pointer justify-between !mb-5">
                    <div className="flex gap-3">
                      <div className="bg-PrimaryBg h-[40px] w-[40px] rounded-full flex items-center justify-center">
                        <img
                          className="h-[20px] w-[20px]"
                          src={Images.public_icon}
                          alt=""
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-Colormain font-semibold text-sm">
                          Public
                        </span>
                        <span className="text-[#A5A1A1] font-normal text-sm">
                          Anyone can see
                        </span>
                      </div>
                    </div>
                    <input
                      type="radio"
                      name="visibility"
                      value="public"
                      checked={visibility === 'public'}
                      onChange={() => setVisibility('public')}
                      className="accent-[#020C1C] h-[20px] w-[20px]"
                    />
                  </div>
                  <div className="flex items-center gap-2 cursor-pointer justify-between">
                    <div className="flex gap-3">
                      <div className="bg-PrimaryBg h-[40px] w-[40px] rounded-full flex items-center justify-center">
                        <img
                          className="h-[20px] w-[20px]"
                          src={Images.lock_icon}
                          alt=""
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-Colormain font-semibold text-sm">
                          Private
                        </span>
                        <span className="text-[#A5A1A1] font-normal text-sm">
                          Only your matches or followers
                        </span>
                      </div>
                    </div>
                    <input
                      type="radio"
                      name="visibility"
                      value="private"
                      checked={visibility === 'private'}
                      onChange={() => setVisibility('private')}
                      className="accent-[#020C1C] h-[20px] w-[20px]"
                    />
                  </div>
                </div>
              </div>

              <CommonButton
                label="SAVE"
                onClick={handleSaveSettings}
                isPrimary
                className="!w-full !rounded-[50px] mt-[20px] !font-black"
              />
            </div>
          )}
          {postSchedule && (
            <div className="p-4 !pb-0 !mt-5 border-t border-b border-SecondaryBorder">
              <div className="flex items-center justify-between">
                <h3 className="!font-black !text-base !italic uppercase !text-Colormain !mb-0">
                  Post Schedule
                </h3>
                <img
                  onClick={() => setPostSchedule(!postSchedule)}
                  src={Images.Error}
                  alt=""
                  className="cursor-pointer"
                />
              </div>
              <div className="mt-2">
                <div className="mb-2.5">
                  <label className="text-Colormain text-sm mb-2.5">Date</label>
                  <div className="feed_calender">
                    <div className="calendar-clock feed_section">
                      <CommonDatePicker
                        inputFields={inputFields}
                        setInputFields={setInputFields}
                        fieldName="schedule_date"
                        placeholder={English.G57}
                        minDate={new Date()}
                        maxDate={
                          new Date(new Date().getFullYear() + 10, 11, 31)
                        }
                        // popperPlacement="top"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="mb-2.5">
                  <label className="block text-Colormain text-sm mb-2.5">
                    {English.G323}
                  </label>
                  <div className="calendar-clock">
                    <TimePicker
                      name="opening_time"
                      onChange={onChangeOpenTime}
                      showNow={false}
                      showOk={false}
                      suffixIcon={null}
                      placeholder={English.G324}
                      value={
                        inputFields?.opening_time
                          ? dayjs(inputFields?.opening_time, 'HH:mm')
                          : null
                      }
                      // use12Hours
                      format="HH:mm"
                      // format="HH:mm A"
                      className="ant-design event_time w-full"
                      style={{ 'margin-top': '0px' }}
                      onSelect={(time) => {
                        const timeString = time ? time.format('HH:mm') : ''
                        setInputFields((prevState) => ({
                          ...prevState,
                          opening_time: timeString
                        }))
                        updateSchedule(
                          inputFields.schedule.split(' ')[0] ||
                            dayjs().format('YYYY-MM-DD'),
                          timeString
                        )
                      }}
                      popupClassName="custom-timepicker-dropdown"
                    />
                  </div>
                </div>
              </div>
              <CommonButton
                label="SAVE"
                onClick={() => {
                  setPostSchedule(false)
                }}
                isPrimary
                className="!w-full !rounded-[50px] mt-[20px] !font-black"
              />
            </div>
          )}

          <div className="!pt-5 flex-1">
            <CommonInput
              name="description"
              required
              placeholder={English.A217}
              isTextArea
              onChange={handleChange}
              value={inputFields.description}
              className="bg-PrimaryBg rounded-[15px] border-0 h-full"
              MainClass="h-full"
            />
          </div>
          <div className="!mt-[10px]">
            <CommonImageUpload
              handleImageChange={handleImageChange}
              buttonText=""
              selectedImg={images}
              // selectedImg=""
              defaultImg=""
              className="max-w-[250px] max-h-[250px]"
              id="Add_Img"
              imgClass="hidden"
            />
          </div>
          <div className="flex justify-between items-center !mb-5">
            <div
              className="cursor-pointer"
              onClick={() => document.getElementById('Add_Img').click()}
            >
              <img src={Images.upload_post_icon} alt="" />
            </div>
            <div className="cursor-pointer">
              <img src={Images.SmileEmoji_icon} alt="" />
            </div>
          </div>
        </div>
      </div>
    </CommonModalMain>
  )
}

export default AddFeedModal

import React from 'react'
import './Store.css'
import { Link } from 'react-router-dom'
import StoreProductAllView from './StoreProductAllView'
import { Images } from '../../helpers'

const ProductList = ({ productListDataTrue, onBack, productData }) => {
  return (
    <div className="bg-white rounded-[15px] !px-5 !pb-5">
      {!productData && productListDataTrue && (
        <div className="flex items-center !gap-3 link_click bg-white">
          <Link
            onClick={onBack}
            className="text-[#4E4949] text-base font-medium"
          >
            Home
          </Link>
          <img src={Images.breadcrumbAerrow} alt="" />
          <Link
            onClick={onBack}
            className="bg-primaryGradient bg-clip-text text-transparent text-base font-bold"
          >
            Product
          </Link>
        </div>
      )}
      <StoreProductAllView />
      {/* <ParticularProductShow
        ProductDetails={productData}
        onBack={onBackClick}
      /> */}
    </div>
  )
}
export default ProductList

/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react'
import '../HotelComponent/Discount.css'
import './Event.css'
import { s3ImageOrganizer } from '../../../services/Service'

const Guest = ({ ParticularEvent }) => {
  const [particularEvent, setParticularEvent] = useState([])

  useEffect(() => {
    setParticularEvent(ParticularEvent)
  }, [ParticularEvent])

  return (
    <div className="">
      <div className="statistic-chart-container position-relative">
        {particularEvent.map((data, index) => (
          <div className="add-item-content" key={index}>
            <div className="item-details-input pt-0 local_contact_scroll_bars">
              <div className="mb-3">
                <label className="!text-[#151515] !text-[18px] !font-black !italic !uppercase">Guests</label>
                <div className="mb-3">
                  <div className="grid grid-cols-5 !gap-[6px]">
                    {data.performer_details.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="relative border-[1px] border-SecondaryBorder rounded-[15px]"
                        >
                          <div className="flex flex-col !gap-5 p-2.5">
                            <img
                              src={
                                s3ImageOrganizer + item.performer_profile_pic
                              }
                              alt=""
                              className="w-[100px] h-[100px] rounded-full mx-auto border-[3px] border-[#01104D]"
                            />
                            <div className="podcast-profile-detail mx-auto text-center">
                              <span className="!text-Colormain !text-[16px] !font-semibold !mx-auto !w-auto">{item.performer_name}</span>
                              <p className="designation !text-center">
                                {item.performer_type_name}
                              </p>
                            </div>
                            <div className="text-center">
                              <button className="bg-[#F9F9F9] text-sm text-Colormain font-semibold !py-2 rounded-[999px] w-full" type="button">
                                View Profile
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Guest

/* eslint-disable no-unreachable */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import CommonInput from '../../InputComponet/CommonInput'
import CommonEmptyData from '../../CommonNoDataModal/CommonEmptyData'
import { English, Images } from '../../../helpers'
import VideoPlayer from '../CommonVideoPlayer'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import {
  capitalizeFirstLetter,
  delay,
  formatDuration
} from '../../../helpers/Utility'
import Dropdown from '../../Dropdown/Dropdown'
import { APICall, EndPoints } from '../../../services'
import { s3ImageVideo } from '../../../services/Service'
import {
  compressImage,
  compressVideo,
  dataDecrypt
} from '../../../services/http-services'
import { useVideoProgress } from '../../../providers/VideoProgressProvider'
import CommonCMModal from './CommonCMModal'
import PlatformSelect from './PlatformSelect'

const VideoAddModal = ({ handleCancel, editData, onSubmit }) => {
  const [selectedVideo, setSelectedVideo] = useState('')
  const [selectedVideoObj, setSelectedVideoObj] = useState('')
  const [selectedFilePath, setSelectedFilePath] = useState('')
  const [category, setCategory] = useState([])
  const { updateVideoProgress } = useVideoProgress()
  const [loading, setLoading] = useState(false)
  const [appData, setAppData] = useState(null)
  const [inputFields, setInputFields] = useState({
    title: '',
    description: '',
    duration: '',
    attachment: '',
    cover_attachment: '',
    app_id: [],
    category_id: 0,
    search: ''
  })

  useEffect(() => {
    if (editData) {
      setInputFields({
        title: editData.title || '',
        description: editData.description || '',
        duration: editData.duration || '',
        attachment: editData.attachment || '',
        cover_attachment: editData.cover_attachment || '',
        category_id: editData.category_id || 0,
        app_id: editData.app_data || 2,
        video_id: editData.video_id
      })
      setSelectedVideo(s3ImageVideo + editData.attachment)
      setSelectedFilePath(s3ImageVideo + editData.cover_attachment)
    }
  }, [editData])
  const [selectedFileObj, setSelectedFileObj] = useState('')
  const [step, setStep] = useState(1)
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [isVideoUploading, setIsVideoUploading] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  console.log('uploadPercentage', uploadPercentage)
  console.log('isVideoUploading', isVideoUploading)
  console.log('isButtonLoading', isButtonLoading)

  const MainCategory = () => {
    APICall('post', {}, `${EndPoints.getCategory}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setCategory(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const clearValue = () => {
    setSelectedFilePath('')
    setSelectedFileObj('')
    setSelectedVideo('')
    setSelectedVideoObj('')
    setInputFields({
      ...inputFields,
      title: '',
      description: '',
      duration: '',
      attachment: '',
      cover_attachment: '',
      app_id: '',
      video_id: ''
    })
    setStep(1)
  }
  const handleCancelClick = () => {
    handleCancel()
    clearValue()
  }

  const handleFileChange = (file) => {
    const maxFileSizeMB = 10
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024
    if (file.size > maxFileSizeBytes) {
      toast.error(`File size exceeds ${maxFileSizeMB} MB`)
      return
    }
    const reader = new FileReader()
    reader.onload = (e) => {
      setSelectedFilePath(e.target.result)
      setSelectedFileObj(file)
    }
    reader.readAsDataURL(file)
  }

  const videoRef = useRef(null)
  const handleVideoChange = (event) => {
    const maxFileSizeMB = 500
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024
    const maxDurationMinutes = 15

    const fileInput = event.target
    const selectedFile = fileInput.files[0]

    if (selectedFile) {
      if (selectedFile.size > maxFileSizeBytes) {
        toast.error(`Video file size exceeds ${maxFileSizeMB} MB`)
        return
      }
      const video = document.createElement('video')
      video.src = URL.createObjectURL(selectedFile)
      video.onloadedmetadata = () => {
        const durationMinutes = video.duration / 60
        if (durationMinutes > maxDurationMinutes) {
          toast.error(`Video duration exceeds ${maxDurationMinutes} minutes`)
          return
        }
        setSelectedVideoObj(selectedFile)
        const videoURL = URL.createObjectURL(selectedFile)
        setSelectedVideo(videoURL)
      }
    }
  }

  useEffect(() => {
    if (step === 2) {
      MainCategory()
    }
  }, [step])

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const getCategoryName = (categoryId) => {
    const categoryObj = category.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.category_name : English.G134
  }

  const videoText = useMemo(() => {
    return (
      <div className="item-details-input !w-[400px]">
        <label className="mb-1">{English.A222}</label>
        <CommonInput
          name="title"
          placeholder={English.A223}
          onChange={handleChange}
          // maxLength={100}
          value={capitalizeFirstLetter(inputFields.title)}
        />
        <div className="poll-dropdown">
          <label>{English.G124}</label>
          <Dropdown
            title={getCategoryName(inputFields.category_id)}
            onChanged={(d) => {
              setInputFields({
                ...inputFields,
                category_id: d.id
              })
            }}
            Data={category.map((d) => ({
              title: d.category_name,
              id: d.id
            }))}
            name="category_id"
            customClass={
              inputFields.category_id
                ? 'selected-class !mb-[16px] !mt-0'
                : 'giveaway-dropdown !mb-[16px] !mt-0'
            }
            customClassHeight="dropdown-height-small"
          />
        </div>
        <label className="mb-1">{English.G112}</label>
        <CommonInput
          name="description"
          placeholder={English.A224}
          onChange={handleChange}
          // maxLength={250}
          isTextArea
          value={capitalizeFirstLetter(inputFields.description)}
          className="pt-3"
        />
      </div>
    )
  }, [inputFields, handleChange, category])

  const videoUploadStep = useMemo(() => {
    if (step === 4) {
      return null
    }

    return (
      <div className="!mt-5 rounded-[15px] local_contact_scroll_bars !pl-0 duration-100">
        <div className="upload-input">
          <CommonInput
            type="file"
            accept="video/*"
            id="upload-video"
            onChange={handleVideoChange}
            className="d-none"
          />
          {!selectedVideo && (
            <CommonEmptyData
              image={Images.FileUpload}
              mainTitle={English.G153}
              descriptionText={English.B124}
              onClick={() => document.getElementById('upload-video').click()}
              addIcon={Images.plusrounded}
              btnText={English.A210}
              classNameDescription="max-w-[278px] !text-[14px] !font-normal !leading-normal"
            />
          )}
        </div>
        {selectedVideo && (
          <div className="video-wrapper modal-video flex flex-row relative gap-3">
            {step === 2 && (
              <div className="upload-input !w-[400px]">
                <CommonImageUpload
                  handleImageChange={handleFileChange}
                  index={0}
                  buttonText={English.A225}
                  className="!bg-PrimaryBg  !border-dashed !border !border-SecondaryBorder rounded-2xl !w-full h-full flex items-center justify-center"
                  selectedImg={selectedFilePath}
                  defaultImg=""
                  aspect={16 / 9}
                  forImage="video"
                  forName="video_content"
                  editButtonClass="!top-2 !right-2"
                />
              </div>
            )}
            {step === 3 && videoText}
            <div
              className={`h-[calc(80vh-220px)] object-cover ${step === 2 || step === 3 ? 'w-[calc(100%-400px)]' : 'w-full'}`}
            >
              <VideoPlayer
                ref={videoRef}
                key={selectedVideo}
                videoSrc={selectedVideo}
                posterSrc={selectedFilePath}
                className="custom-video-player !w-full !h-full relative !overflow-hidden rounded-2xl"
                videoClassName="h-full"
              />
              {step === 1 && !editData && (
                <button
                  type="button"
                  onClick={() => document.getElementById('upload-video').click()}
                  className="edit-button-model absolute w-8 h-8 !top-2 !right-2"
                />
              )}
            </div>
          </div>
        )}
      </div>
    )
  }, [selectedVideo, selectedFilePath, step, videoText])

  const publishEnaled = () => {
    return !(
      inputFields.title &&
      inputFields.category_id &&
      inputFields.description &&
      selectedFilePath &&
      selectedVideo
    )
  }

  const handlePublishClick = async () => {
    if (videoRef?.current?.duration) {
      const durationInSeconds = Math.trunc(videoRef?.current?.duration || 0)
      const formattedDuration = formatDuration(durationInSeconds)
      inputFields.duration = formattedDuration
    }

    setLoading(true)

    try {
      if (selectedFileObj) {
        const imageFile = await compressImage(selectedFileObj)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'video')

        await APICall(
          'post',
          coverFormData,
          EndPoints.uploadImage,
          'creator',
          true
        )
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedCoverData = res?.decryptedData
              inputFields.cover_attachment = decryptedCoverData
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((e) => {
            toast.error(e?.data?.message)
          })
      }

      if (selectedVideoObj) {
        const videoFile = await compressVideo(
          selectedVideoObj,
          setUploadPercentage,
          setIsVideoUploading
        )
        const videoFormData = new FormData()
        videoFormData.append('file', videoFile)
        videoFormData.append('type', 'video')

        await APICall(
          'post',
          videoFormData,
          EndPoints.uploadImage,
          'creator',
          true
        )
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedVideoData = dataDecrypt(res.data.data)
              inputFields.attachment = decryptedVideoData
              setIsButtonLoading(true)
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((e) => {
            toast.error(e?.data?.message)
          })
      }

      await delay(5000)
      await videoApiCall()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.message || 'An error occurred')
    }
  }

  const videoApiCall = async () => {
    setIsButtonLoading(true)
    let data = {
      title: inputFields.title,
      description: inputFields.description,
      attachment: inputFields.attachment,
      cover_attachment: inputFields.cover_attachment,
      duration: inputFields?.duration,
      app_id: inputFields?.app_data.map((d) => d.id),
      video_id: inputFields.video_id,
      category_id: inputFields.category_id
    }
    if (editData) {
      data = {
        title: inputFields.title,
        description: inputFields.description,
        attachment: inputFields.attachment,
        cover_attachment: inputFields.cover_attachment,
        app_id: inputFields?.app_data.map((d) => d.id),
        video_id: inputFields.video_id,
        duration: inputFields?.duration,
        category_id: inputFields.category_id
      }
    }
    try {
      const res = await APICall(
        'post',
        data,
        `${editData ? EndPoints.editVideo : EndPoints.addVideo}`,
        'contentManager'
      )
      if (res.status === 200 && res.data?.code === 200) {
        await delay(5000)
        setLoading(false)
        clearValue()
        handleCancel()
        setIsButtonLoading(false)
        onSubmit(inputFields.video_id)
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    } catch (error) {
      toast.error(error?.data?.message)
    }
  }

  useEffect(() => {
    if (appData) {
      setInputFields({
        ...inputFields,
        app_data: appData
      })
    }
  }, [appData])

  const appDataSelect = useMemo(() => {
    return (
      <PlatformSelect
        key={step}
        defaultIds={editData?.app_data}
        step={step}
        onSelectedIds={(data) => {
          setAppData(data)
        }}
      />
    )
  }, [])

  return (
    <CommonCMModal
      setStep={setStep}
      totalStep={4}
      step={step}
      handleCancel={handleCancelClick}
      loading={loading}
      isButtonLoading={isButtonLoading}
      isVideoUploading={isVideoUploading}
      uploadPercentage={uploadPercentage}
      nextBtnLable={step === 4 ? English.G128 : English.G69}
      nextBtnStatus={
        step === 1
          ? !selectedVideo
          : step === 2
            ? !selectedFilePath
            : step === 3
              ? publishEnaled()
              : !inputFields?.app_data ||
                inputFields?.app_data?.length === 0 ||
                loading
      }
      cancelBtnStatus={loading}
      closeBtnStatus={loading}
      title="UPLOAD A VIDEO"
      onClickNext={() => {
        if (step === 4) {
          handlePublishClick()
        } else {
          setStep(step + 1)
        }
      }}
    >
      {videoUploadStep}
      {step === 4 && appDataSelect}
    </CommonCMModal>
  )
}

export default VideoAddModal

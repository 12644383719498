/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { English, Images } from '../../helpers'
import DummyComment from './DummyComment'
import { formatTimeLeft } from '../../helpers/Utility'
import { s3ImageGiveaway, s3ImageProfile } from '../../services/Service'
import GiveawayAddModal from './Modal/GiveawayAddModal'
import { APICall, dataDecrypt, EndPoints } from '../../services'
import { emitter, EventType } from '../../helpers/Emitter'
import GiveawayParticipate from './SubComponent/GiveawayParticipate'
import BreadCrumbHeader from './SubComponent/BreadCrumbHeader'
import ContentMangerCommonTitle from './SubComponent/ContentMangerCommon.js/ContentMangerCommonTitle'
import ImageComponent from '../ImageComponent/ImageComponent'

const GiveawayDetailPage = () => {
  const location = useLocation()
  const userData = useSelector((state) => state.user?.userData)
  const data = location.state

  const [giveaway, setGiveaway] = useState([])
  const [giveawayId, setGiveawayId] = useState()
  const [expandedDescription, setExpandedDescription] = useState(false)
  const [ShowGiveawayModel, setShowGiveawayModel] = useState(false)

  const [editData, setEditData] = useState(null)
  const [comments, setComments] = useState([])
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [commentsCount, setCommentCount] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [hasMorePages, setHasMorePages] = useState(false)

  const onClickEdit = (data) => {
    setShowGiveawayModel(true)
    setEditData(data)
  }

  // const onClickAdd = () => {
  //   setEditData(null)
  //   setShowGiveawayModel(true)
  // }

  useEffect(() => {
    if (data) {
      giveawayParticular()
      setGiveawayId(data?.giveaway_id)
    }
  }, [data])

  // eslint-disable-next-line no-unused-vars
  const giveawayParticular = (app_id) => {
    const platFormData = {
      // app_id: app_id || data.app_data?.[0].id,
      giveaway_id: data.giveaway_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.particularGiveaway}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const GiveawayData = dataDecrypt(res.data.data)
          setGiveaway(GiveawayData[0])
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  useEffect(() => {
    if (data) {
      videoComment(data?.giveaway_id)
    }
  }, [data])

  const handleLoadMore = () => {
    videoComment(data?.giveaway_id, currentPage + 1)
  }

  const videoComment = (giveaway_id, page = 1) => {
    const platFormData = {
      app_id: 2,
      giveaway_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayComment}?page=${page}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const dataComment = res?.decryptedData?.data?.map((d, i) => ({
            ...d,
            id: i + 1
          }))

          if (page === 1) {
            setComments(dataComment)
          } else {
            setComments((prev) => [...prev, ...dataComment])
          }
          setCurrentPage(res?.decryptedData?.current_page)
          setHasMorePages(res?.decryptedData?.next_page_url !== null)
          setCommentCount(res?.decryptedData?.total)
          setIsLoadingComments(false)
        } else {
          setIsLoadingComments(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsLoadingComments(false)
        toast.error(error?.data?.message)
      })
  }

  const handleAddComment = (comment) => {
    if (comment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        comment,
        giveaway_id: data?.giveaway_id,
        total_like: 0,
        reply_data: [],
        user: {
          name: userData.user_name,
          profilePic: s3ImageProfile + userData.profile_image
        }
      }
      setComments([newCommentObj, ...comments])
    }
    shotAddComment(comment)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.comment_id === commentId) {
        return {
          ...comment,
          reply_data: [
            ...comment.reply_data,
            {
              id: comment.reply_data.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: userData.user_name,
                profilePic: s3ImageProfile + userData.profile_image
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    shotAddComment(replyText, commentId)
  }

  const shotAddComment = (comment, comment_id) => {
    let platFormData = {
      giveaway_id: data?.giveaway_id,
      comment
    }
    if (comment_id) {
      platFormData = { ...platFormData, comment_id }
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentAdd}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          videoComment(data?.giveaway_id)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleLikeComment = (comment_id, commentData) => {
    const updatedComments = []
    comments.forEach((comment) => {
      if (!commentData?.reply_data) {
        const updatedCommentsReplay = []
        comment?.reply_data?.forEach((item) => {
          if (item?.comment_id === comment_id) {
            updatedCommentsReplay.push({
              ...item,
              is_like: !item.is_like,
              total_like: !item.is_like
                ? item.total_like + 1
                : item.total_like - 1
            })
          } else {
            updatedCommentsReplay.push(item)
          }
        })
        updatedComments.push({ ...comment, reply_data: updatedCommentsReplay })
      } else if (comment.comment_id === comment_id) {
        updatedComments.push({
          ...comment,
          is_like: !comment.is_like,
          total_like: !comment.is_like
            ? comment.total_like + 1
            : comment.total_like - 1
        })
      } else {
        updatedComments.push(comment)
      }
    })
    setComments(updatedComments)
    shotCommentLike(comment_id)
  }

  const shotCommentLike = (comment_id) => {
    const platFormData = {
      app_id: 2,
      comment_id
    }
    APICall(
      'post',
      platFormData,
      `${EndPoints.giveawayCommentLike}`,
      'contentManager'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          //
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const commentMemo = useMemo(() => {
    return (
      <DummyComment
        key="Comments"
        commentsCount={commentsCount}
        data={comments}
        isLoadingComments={isLoadingComments}
        onNewComment={(comment) => handleAddComment(comment)}
        onCommentReplay={(comment, data) => handleAddReply(data.comment_id, comment)}
        onLikeClick={(comment) => handleLikeComment(comment.comment_id, comment)}
        onLoadMore={handleLoadMore}
        hasMore={hasMorePages}
      />
    )
}, [comments, isLoadingComments, hasMorePages])

  const handleParticipateSuccess = () => {
    setGiveaway((prevGiveaway) => ({
      ...prevGiveaway,
      is_participate: true
    }))
  }

  const onBack = (tabIndex, subTabIndex) => {
    window.history.back()
    setTimeout(() => {
      emitter.emit(EventType.contentMangerTabs, { tabIndex, subTabIndex })
    }, 100)
  }

  return (
    <div className="content-manager">
      <div className="common-card-body p-20 ">
        <div className="!overflow-auto !h-[calc(100vh-173px)] !overflow-x-hidden no-scrollbar">
          <div className="bg-white rounded-[15px] !pt-5">
            <BreadCrumbHeader
              link1Lable="Giveaways"
              link2Lable={giveaway.title}
              onClickHome={() => onBack(0)}
              onClickLink={() => onBack(4, 0)}
            />
            <ContentMangerCommonTitle
              isPublish
              title="Giveaways"
              buttonName="EDIT"
              onButtonClick={() => onClickEdit(data)}
            />
            <div>
              <div className="add-giveways-container">
                <div className="added-giveways-item position-relative">
                  <div className="flex flex-col !gap-5">
                    <div className="w-full">
                      <ImageComponent
                        className="w-[500px] h-[300px] object-cover rounded-lg shadow-lg border"
                        imageUrl={s3ImageGiveaway + giveaway?.images?.[0]}
                        key={giveaway?.images?.[0]}
                      />
                    </div>

                    <div className="flex gap-[14px] overflow-x-auto">
                      {(giveaway?.images || [])
                        .slice(1, 4)
                        .map((file, index) => (
                          <div
                            key={index}
                            className="flex-shrink-0 w-[157px] h-[120px] border rounded-lg overflow-hidden hover:ring-2 hover:ring-blue-400 transition-all"
                          >
                            <ImageComponent
                              className="w-full h-full object-cover"
                              imageUrl={s3ImageGiveaway + file}
                              key={`${file}-${index}`}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="giveways-item-details flex flex-col pb-2">
                    <h4>{giveaway?.title}</h4>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="giveways-profile-detail">
                        <div className="giveways-com-rating">
                          <img
                            className="profile-img-giveway"
                            src={Images.Dyson}
                            alt=""
                          />
                          <span className="giveway-profile-name">
                            {giveaway?.brand_name}
                          </span>
                        </div>
                        <div className="vertical-devider" />
                        <div className="giveways-com-rating">
                          <div className="rating-block-container flex items-center">
                            <img src={Images.FillStar} alt="" />
                            <img src={Images.FillStar} alt="" />
                            <img src={Images.FillStar} alt="" />
                            <img src={Images.FillStar} alt="" />
                            <img src={Images.EmptyStar} alt="" />
                          </div>
                          <span>
                            {giveaway?.rating}
                            {' '}
                            4.5 rating
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr className="bg-[#E1E2E5]" />
                    <div className="flex gap-3 h-[25px]">
                      <h6 className="mb-0 text-Main opacity-60 text-base font-medium">
                        Type:
                      </h6>
                      <p className="mt-0 mb-0 !h-[25px] text-Main text-base font-medium">
                        {giveaway?.type_name}
                      </p>
                    </div>
                    <hr className="bg-[#E1E2E5]" />
                    <p className="text-[#5A5A5A]font-normal text-sm max-w-[540px] !flex !flex-1">
                      {expandedDescription
                        ? giveaway?.description
                        : `${giveaway?.description?.slice(0, 300)}`}
                      {giveaway?.description?.length > 300 && (
                        <span
                          className="see_more_local"
                          onClick={(e) => {
                            e.stopPropagation()
                            setExpandedDescription(!expandedDescription)
                          }}
                        >
                          ...
                          {' '}
                          {expandedDescription ? English.G193 : English.G194}
                        </span>
                      )}
                    </p>
                    <div className="giveway-time-left">
                      <img src={Images.ClockBlue} alt="" />
                      <span>{formatTimeLeft(giveaway?.time_left)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row w-[100%] !gap-5">
            <div className="min-w-[500px] h-fit border-[0.5px] border-BorderLight rounded-2xl p-[14px] mt-3.5">
              <GiveawayParticipate
                giveawayID={giveawayId}
                onSuccessParticipate={handleParticipateSuccess}
                isParticipated={giveaway?.is_participate}
              />
            </div>
            <div className="w-full">{commentMemo}</div>
          </div>
          {ShowGiveawayModel && (
            <GiveawayAddModal
              handleCancel={() => setShowGiveawayModel(false)}
              editData={editData}
              onSubmit={(giveaway_id, app_data) => {
                giveawayParticular(app_data[0].id)
                setShowGiveawayModel(false)
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default GiveawayDetailPage

/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useCallback, useEffect, useState } from 'react'
import './Feeds.css'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { s3ImagePostFeed, s3ImageProfile } from '../../services/Service'
import Utility, {
  debounce,
  lockScroll,
  unlockScroll
} from '../../helpers/Utility'
import CommonInput from '../InputComponet/CommonInput'
import Pagination from '../Pagination/Pagination'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import CommentModal from '../CommanComponent/CommentModal'
import { APICall, EndPoints } from '../../services'
import ImageComponent from '../ImageComponent/ImageComponent'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import { compressImage, dataDecrypt } from '../../services/http-services'
import { emitter, EventType } from '../../helpers/Emitter'
import AddFeedModal from './Component/AddFeedModal'
import CommonButton from '../ButtonComponent/ButtonCommon'

const Feeds = () => {
  const profileData = useSelector((state) => state.user?.profileData)
  const creatorData = useSelector((state) => state.user?.creatorData)
  const app_id = useSelector((state) => state.user?.app_id)

  const [feedData, setFeedData] = useState([])
  const [comments, setComments] = useState([])
  const [selectedId, setSelectedId] = useState('')
  const [commentSection, setCommentSection] = useState(false)
  const [newComment, setNewComment] = useState('')
  const [showReplies, setShowReplies] = useState({})
  const [Replied, setShowReplied] = useState('')
  const [editCommentId, setEditCommentId] = useState(null)
  const [editReplyId, setEditReplyId] = useState(null)
  const [editedCommentText, setEditedCommentText] = useState('')
  const [editedReplyText, setEditedReplyText] = useState('')
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupData, setPopupData] = useState([])
  const [shareData, setShareData] = useState([])
  const [listData, setListData] = useState(false)
  const [postId, setPostId] = useState([])
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [isFeedLoading, setIsFeedLoading] = useState(false)
  const [isFeedReportLoading, setIsFeedReportLoading] = useState(false)
  const [isCreatorListDataLoading, setIsCreatorListDataLoading] =
    useState(false)
  const [currentPageComment, setCurrentPageComment] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [isDeleting, setIsDeleting] = useState(false)
  const [commentIndex, setCommentIndex] = useState(null)

  const [feedCurrentPage, setFeedCurrentPage] = useState(1)
  const [feedTotalPages, setFeedTotalPages] = useState(1)
  const [addModalModel, setAddModalModel] = useState(false)

  const handleFeedAddModal = () => {
    setAddModalModel(true)
  }

  const onPressClose = () => {
    setAddModalModel(false)
    // ResetValue()
  }

  const getFeedData = (feedCurrentPage) => {
    setIsFeedLoading(true)
    const data = { app_id: app_id.id }
    APICall(
      'post',
      data,
      // `${EndPoints.getFeed}?page=${feedCurrentPage}`,
      `${EndPoints.getPost}?page=${feedCurrentPage}`,
      'creator'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setFeedData(res?.decryptedData.data)
          setFeedCurrentPage(res?.decryptedData.current_page)
          setFeedTotalPages(res?.decryptedData.last_page)
          setIsFeedLoading(false)
        } else {
          setIsFeedLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsFeedLoading(false)
      })
  }

  const onClickLike = (post_id, index) => {
    const data = {
      app_id: app_id.id,
      post_id
    }
    APICall('post', data, `${EndPoints.likeFeed}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const data = dataDecrypt(res.data.data)
          const newArr = [...feedData]
          newArr[index].is_like = !newArr[index].is_like
          if (newArr[index].is_like) {
            newArr[index].like_by_image = data?.like_by_image || []
            newArr[index].like_by_text = data?.like_by_text || ''
            newArr[index].like_by_other = data?.like_by_other || ''
            newArr[index].total_likes = (newArr[index].total_likes || 0) + 1
          } else {
            newArr[index].like_by_image = []
            newArr[index].like_by_text = ''
            newArr[index].like_by_other = ''
            newArr[index].total_likes = Math.max(
              (newArr[index].total_likes || 1) - 1,
              0
            )
          }
          console.log('Updated Feed Data:', newArr)
          setFeedData(newArr)
          // getFeedData(feedCurrentPage)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsFeedLoading(false)
      })
  }

  const onClickSave = (post_id, index) => {
    const data = {
      app_id: app_id.id,
      post_id
    }
    APICall('post', data, `${EndPoints.saveFeed}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const newArr = [...feedData]
          newArr[index].is_save = !newArr[index].is_save
          setFeedData(newArr)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const commentClear = () => {
    setComments([])
    setSelectedId([])
    setNewComment('')
    setShowReplied('')
    setEditedCommentText('')
    setEditedReplyText('')
  }

  const handlePageChange = (pageNumber) => {
    getFeedData(pageNumber)
    setFeedCurrentPage(pageNumber)
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }, 100)
  }

  const fetchFeedComments = (post_id, delay = 1500) => {
    setTimeout(() => {
      feedComment(post_id)
    }, delay)
  }

  const onClickComment = (post_id) => {
    setSelectedId(post_id)
    if (!isLoadingComments) {
      setIsLoadingComments(true)
      feedComment(post_id)
      setCommentSection(true)
    }
  }

  const feedComment = (post_id) => {
    const platFormData = {
      app_id: app_id.id,
      post_id
    }
    APICall('post', platFormData, `${EndPoints.feedComment}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const dataComment = res?.decryptedData?.data.map((d, i) => ({
            id: i + 1,
            text: d.comment,
            post_id: d.post_id,
            is_like: d.is_like,
            reply_id: d.comment_id,
            total_like: d.total_like,
            reply: d.reply_data.map((subData, subIndex) => ({
              id: subIndex + 1,
              text: subData.comment,
              reply_id: subData.comment_id,
              total_like: subData.total_like,
              is_like: subData.is_like,
              user: {
                name: subData.user_name,
                profilePic: s3ImageProfile + subData.profile_icon
              }
            })),
            user: {
              name: d.user_name,
              profilePic: s3ImageProfile + d.profile_icon
            }
          }))
          setComments(dataComment)
          setIsLoadingComments(false)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsLoadingComments(false)
        toast.error(error?.data?.message)
      })
  }

  const feedAddComment = (comment, selectedId) => {
    const platFormData = {
      app_id: app_id.id,
      post_id: selectedId,
      comment
    }
    APICall('post', platFormData, `${EndPoints.feedCommentAdd}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchFeedComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsLoadingComments(false)
        toast.error(error?.data?.message)
      })
  }

  const feedAddCommentReply = (comment_id, replyText) => {
    const platFormData = {
      app_id: app_id.id,
      comment_id,
      comment: replyText,
      post_id: selectedId
    }
    APICall('post', platFormData, `${EndPoints.feedCommentAdd}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchFeedComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const feedCommentEdit = (reply_id, comment) => {
    const platFormData = {
      app_id: app_id.id,
      comment_id: reply_id,
      comment
    }
    APICall('post', platFormData, `${EndPoints.feedCommentEdit}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchFeedComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const feedCommentEditReply = (comment_id, replyText) => {
    const platFormData = {
      app_id: app_id.id,
      post_id: selectedId,
      comment_id,
      comment: replyText
    }
    APICall('post', platFormData, `${EndPoints.feedCommentEdit}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchFeedComments(selectedId)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const feedCommentDelete = (reply_id) => {
    setIsDeleting(true)
    const platFormData = {
      app_id: app_id.id,
      comment_id: reply_id
    }
    APICall('post', platFormData, `${EndPoints.feedCommentDelete}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchFeedComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.success(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const feedCommentDeleteReply = (commentId, replyId) => {
    setIsDeleting(true)
    const platFormData = {
      app_id: app_id.id,
      comment_id: commentId,
      reply_id: replyId
    }
    APICall('post', platFormData, `${EndPoints.feedCommentDelete}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          fetchFeedComments(selectedId)
          setIsDeleting(false)
          toast.success(res?.data?.message)
        } else {
          setIsDeleting(false)
          toast.success(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsDeleting(false)
        toast.error(error?.data?.message)
      })
  }

  const feedCommentLike = (reply_id) => {
    const platFormData = {
      app_id: app_id.id,
      comment_id: reply_id
    }
    APICall('post', platFormData, `${EndPoints.feedCommentLike}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          //
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const feedCommentReplyLike = (reply_id) => {
    const platFormData = {
      app_id: app_id.id,
      comment_id: reply_id
    }
    APICall('post', platFormData, `${EndPoints.feedCommentLike}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          //
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleAddComment = (comment) => {
    if (newComment.trim() !== '') {
      const newCommentObj = {
        id: comments.length + 1,
        is_like: false,
        text: newComment,
        post_id: selectedId,
        total_like: 0,
        reply: [],
        user: {
          name: profileData?.user_name,
          profilePic: creatorData
            ? s3ImageProfile + creatorData.profile_icon
            : ''
        }
      }
      setComments([newCommentObj, ...comments])
      setNewComment('')
    }
    feedAddComment(comment, selectedId)
  }

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.reply_id === commentId) {
        return {
          ...comment,
          is_like: !comment.is_like,
          total_like: comment.is_like
            ? comment.total_like - 1
            : comment.total_like + 1
        }
      }
      return comment
    })
    setComments(updatedComments)
    feedCommentLike(commentId)
  }

  const handleAddReply = (commentId, replyText) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          reply: [
            ...comment.reply,
            {
              id: comment.reply.length + 1,
              text: replyText,
              total_like: 0,
              user: {
                name: profileData.user_name,
                profilePic: creatorData
                  ? s3ImageProfile + creatorData.profile_icon
                  : ''
              }
            }
          ]
        }
      }
      return comment
    })
    setComments(updatedComments)
    setShowReplied('')
    feedAddCommentReply(commentId, replyText)
  }

  const handleDeleteReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          reply: comment.reply.filter((reply) => reply.id !== replyId)
        }
      }
      return comment
    })
    setComments(updatedComments)
    feedCommentDeleteReply(replyId, commentId)
  }

  const toggleReplies = (commentId, forceCloseAll = false) => {
    if (forceCloseAll) {
      setShowReplies({})
    } else {
      setShowReplies((prevState) => ({
        // ...prevState,
        [commentId]: !prevState[commentId]
      }))
    }
    setShowReplied('')
  }

  const HandleCancel = () => {
    setCommentSection(false)
    commentClear()
    toggleReplies(null, true)
  }

  const handleEditComment = (commentId, text) => {
    setEditCommentId(commentId)
    setEditedCommentText(text)
  }

  const handleSaveComment = (commentId, reply) => {
    const updatedComments = comments.map((comment) => (comment.id === commentId
        ? { ...comment, text: editedCommentText }
        : comment))
    feedCommentEdit(commentId, reply)
    setComments(updatedComments)
    setEditCommentId(null)
    setEditedCommentText('')
  }

  const handleEditReply = (replyId, text) => {
    setEditReplyId(replyId)
    setEditedReplyText(text)
  }

  const handleSaveReply = (commentId, replyId) => {
    const updatedComments = comments.map((comment) => (comment.reply_id === commentId
        ? {
            ...comment,
            reply: comment.reply.map((reply) => (reply.reply_id === replyId
                ? { ...reply, text: editedReplyText }
                : reply))
          }
        : comment))
    feedCommentEditReply(replyId, editedReplyText)
    setComments(updatedComments)
    setEditReplyId(null)
    setEditedReplyText('')
  }

  const close = () => {
    setIsPopupOpen(false)
  }

  const showReportList = (post_id) => {
    setPostId(post_id)
    setIsPopupOpen(true)
    getReportReasonsData()
  }

  const getReportReasonsData = async () => {
    setIsFeedReportLoading(true)
    APICall('post', {}, `${EndPoints.getReportReasons}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setPopupData(res?.decryptedData)
          setIsFeedReportLoading(false)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsFeedReportLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const feedReport = (report_id) => {
    const platFormData = {
      post_id: postId,
      reason_id: report_id
    }
    APICall('post', platFormData, `${EndPoints.feedReport}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          toast.success(res?.data?.message)
          setIsPopupOpen(false)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const clearValue = () => {
    setShareData([])
  }

  const showShareList = () => {
    setListData(true)
    creatorListData()
  }

  const handleCancel = () => {
    setListData(false)
    clearValue()
  }

  const creatorListData = (search) => {
    setIsCreatorListDataLoading(true)
    const platform = {
      app_id: app_id.id,
      search
    }
    APICall('post', platform, `${EndPoints.creatorList}`, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShareData(res?.decryptedData)
          setIsCreatorListDataLoading(false)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsCreatorListDataLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const handleSearch = useCallback(
    debounce((inputVal) => creatorListData(inputVal), 500),
    []
  )

  // share list pagination
  const videosPerPage = 6
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const shareDataList = shareData.slice(indexOfFirstVideo, indexOfLastVideo)
  const totalVideos = shareData.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const renderPaginationButtons = () => {
    const numButtonsToShow = 3

    let startPage = currentPage - Math.floor(numButtonsToShow / 2)
    startPage = Math.max(startPage, 1)

    let endPage = startPage + numButtonsToShow - 1
    if (endPage > totalPages) {
      endPage = totalPages
      startPage = Math.max(endPage - numButtonsToShow + 1, 1)
    }

    return [...Array(endPage - startPage + 1)].map((_, index) => {
      const pageNumber = startPage + index
      return (
        <button
          type="button"
          key={pageNumber}
          className={`pagination-buttons ${
            currentPage !== pageNumber ? 'disable' : ''
          }`}
          onClick={() => paginate(pageNumber)}
        >
          {pageNumber}
        </button>
      )
    })
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
  }

  // comment pagination
  const commentsPerPage = 5
  const indexOfLastComment = currentPageComment * commentsPerPage
  const indexOfFirstComment = indexOfLastComment - commentsPerPage
  const commentsList = comments.slice(indexOfFirstComment, indexOfLastComment)
  const totalCommentPages = Math.ceil(comments.length / commentsPerPage)

  useEffect(() => {
    getFeedData(feedCurrentPage)
  }, [])

  useEffect(() => {
    if (currentPageComment > totalCommentPages) {
      setCurrentPageComment(totalCommentPages || 1)
    }
  }, [comments, currentPageComment, totalCommentPages])

  useEffect(() => {
    if (listData || isPopupOpen || commentSection) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [listData, isPopupOpen, commentSection])

  useEffect(() => {
    const update = emitter.addListener(EventType.feedAddModal, () => {
      handleFeedAddModal()
    })

    return () => {
      update.remove()
    }
  }, [])

  return (
    <div className="feeds-main">
      <div className="feed-section">
        {isFeedLoading ? (
          <div className="feeds-item common-card-body !max-w-[500px] !mx-auto mt-30 !p-[20px]">
            <div className="feeds-profile">
              <div className="feeds-profile-main">
                <Skeleton circle width={45} height={45} />
                <ul>
                  <li className="name">
                    <Skeleton width={150} height={10} />
                  </li>
                  <li className="designation">
                    <Skeleton width={50} height={10} />
                  </li>
                </ul>
              </div>
              <div className="open_report">
                <Skeleton width={30} height={10} />
              </div>
            </div>

            <Skeleton
              className="mt-3 mb-3 rounded-3"
              max-width={1118}
              height={348}
            />
            <div className="feeds-detail">
              {/* <h6><Skeleton width={20} height={20} /></h6> */}
              {/* <p><Skeleton width={20} height={20} /></p> */}
            </div>
            <div className="feeds-button">
              <div className="feed-btn-group">
                <button type="button">
                  <Skeleton className="rounded-3" width={20} height={20} />
                </button>
                <button type="button">
                  <Skeleton className="rounded-3" width={20} height={20} />
                </button>
                <button type="button">
                  <Skeleton className="rounded-3" width={20} height={20} />
                </button>
              </div>
              <div className="save-btn">
                <button type="button">
                  <Skeleton className="rounded-3" width={20} height={20} />
                </button>
              </div>
            </div>
          </div>
        ) : feedData.length === 0 ? (
          <CommonEmptyData
            image={Images.post_add}
            mainTitle="No Posts Yet"
            descriptionText="Create a new post and dive into the world of Business Manager."
            onClick={handleFeedAddModal}
            addIcon={Images.plusrounded}
            btnText="Create A Post"
            classNameDescription="font-normal text-[#7A7474] text-[16px] max-w-[350px]"
          />
        ) : (
          <>
            {/* <div>
              <div className="add-video-rows main_btn !justify-end">
                <CommonButton
                  label="Add Post"
                  imgSrc={Images.AddIco}
                  onClick={handleFeedAddModal}
                  isPrimary
                  className="add_new_content"
                />
              </div>
            </div> */}
            <div className="!max-w-[500px] mx-auto pb-3">
              {feedData.map((item, index) => (
                <div
                  className="feeds-item common-card-body my-[15px] !p-[20px] border-[0.5px] border-[#E1E2E5]"
                  key={index}
                >
                  <div className="feeds-profile mb-[20px]">
                    <div className="feeds-profile-main">
                      <ImageComponent
                        imageUrl={s3ImageProfile + item.profile_icon}
                        type="cardImage"
                        key={s3ImageProfile + item.profile_icon}
                        className="feeds-profile-main-profile"
                      />
                      <ul className="flex items-center gap-2">
                        <li className="name !text-[#151515] !text-[14px] !font-semibold">{item.user_name}</li>
                        <li className="h-[12px] w-[12px]">
                          <img
                            className="h-[12px] w-[12px]"
                            src={Images.feed_profile_icon}
                            alt=""
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="open_report">
                      <img
                        alt=""
                        src={Images.DropLink}
                        onClick={() => showReportList(item.post_id)}
                      />
                    </div>
                  </div>
                  {/* <img
                  src={`${s3ImagePostFeed + item.attachment}`}
                  className="feeds-video !rounded-none"
                  style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    objectFit: 'cover',
                    width: '100%'
                  }}
                  alt={item.attachment}
                /> */}
                  <div className="aspect-[5/4]">
                    <ImageComponent
                      imageUrl={`${s3ImagePostFeed + item.attachment}`}
                      key={`${s3ImagePostFeed + item.attachment}`}
                      type="cardImage"
                      className="!w-full !h-full !object-cover"
                    />
                  </div>
                  <div className="feeds-detail">
                    <h6>{item.feed}</h6>
                  </div>
                  <div className="feeds-button mt-[15px]">
                    <div className="feed-btn-group">
                      {item.is_like === true ? (
                        <button
                          type="button"
                          onClick={() => onClickLike(item.post_id, index)}
                        >
                          <img alt="" src={Images.LikeFill_blue} />
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => onClickLike(item.post_id, index)}
                        >
                          <img alt="" src={Images.Feed_UnLike} />
                        </button>
                      )}
                      {item.allow_comments && (
                        <button
                          type="button"
                          onClick={() => onClickComment(item.post_id)}
                        >
                          <img alt="" src={Images.Comment} />
                        </button>
                      )}
                      <button type="button" onClick={showShareList}>
                        <img alt="" src={Images.Share} />
                      </button>
                    </div>
                    <div className="">
                      {item.is_save === true ? (
                        <button
                          type="button"
                          onClick={() => onClickSave(item.post_id, index)}
                        >
                          <img alt="" src={Images.Saved} />
                          {' '}
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => onClickSave(item.post_id, index)}
                        >
                          <img alt="" src={Images.Save} />
                          {' '}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="text-textPrimary text-sm flex items-center gap-2.5 py-3">
                    <div className="flex items-center">
                      {item?.like_by_image?.map((item) => {
                        return (
                          <ImageComponent
                            imageUrl={s3ImageProfile + item}
                            key={item}
                            type="cardImage"
                            className="!w-[30px] !h-[30px] !rounded-full"
                          />
                        )
                      })}
                    </div>
                    <span className="text-[#505050] text-[14px] font-normal">
                      {item?.total_likes > 3 ? 'Liked by' : ''}
                    </span>
                    <span className="text-[#505050] text-[14px] font-semibold">
                      {item?.total_likes === 1
                      ? item?.like_by_text
                      : item?.like_by_other}
                    </span>
                    <span className="text-[#505050] text-[14px] font-normal">
                      {item?.total_likes > 3 ? 'and' : ''}
                    </span>
                    {' '}
                    <b className="text-baseBlackColor">
                      {item?.total_likes > 3 &&
                        Utility.formatLikeFollowCount(item.total_likes)}
                      {item?.total_likes > 3 && 'others'}
                      {' '}
                      {/* {item?.total_likes > 3 && 'Likes'} */}
                      {/* {item?.total_likes === 1 && 'Like'} */}
                    </b>
                  </div>
                  <div className="feeds-detail">
                    <p className="mb-0 text-[#151515] text-[14px] font-normal">
                      {item.description.slice(0, 65)}
                      {item.description.length > 65 && '...'}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {feedTotalPages > 1 && (
          <Pagination
            currentPage={feedCurrentPage}
            totalPages={feedTotalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
      {addModalModel && (
        <AddFeedModal
          onSubmited={() => {}}
          onPressClose={onPressClose}
          setAddModalModel={setAddModalModel}
        />
      )}
      {listData && (
        <div className="add-item-model edit-profile">
          <div className="add-item-content p-3">
            <div className="d-flex justify-content-between header">
              <h3>{English.G405}</h3>
              <div className="items-filter">
                <div className="position-relative input-position">
                  <CommonInput
                    placeholder="Search Follower"
                    onChange={(e) => {
                      handleSearch(e.target.value)
                    }}
                  />
                </div>
              </div>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={handleCancel}
              >
                <span>
                  <img className="" src={Images.CloseIco} alt="" />
                </span>
              </button>
            </div>

            <div className="scroll-bars feed-scroll">
              <div className="item-details-input pt-0">
                <div className="list_data">
                  {isCreatorListDataLoading
                    ? Array(6)
                        .fill(0)
                        .map((_, index) => (
                          <div
                            className="d-flex justify-content-start list_table"
                            key={index}
                          >
                            <span className="number_index">
                              <Skeleton
                                className="rounded-3"
                                width={20}
                                height={20}
                              />
                            </span>
                            <Skeleton circle width={45} height={45} />
                            <span className="user-name">
                              <Skeleton
                                className="rounded-3"
                                width={200}
                                height={25}
                              />
                            </span>
                          </div>
                        ))
                    : shareDataList.map((item, index) => {
                        const calculatedIndex =
                          (currentPage - 1) * videosPerPage + index + 1
                        return (
                          <div
                            className="d-flex justify-content-start list_table"
                            key={index}
                          >
                            <span className="number_index">
                              {calculatedIndex}
                            </span>
                            <img
                              src={s3ImageProfile + item.profile_icon}
                              className="profile-pic"
                              alt="profile"
                            />
                            <span className="user-name">{item.user_name}</span>
                          </div>
                        )
                      })}
                </div>
              </div>
            </div>
            {totalPages > 1 && (
              <div className="pagination-containers-list-comment">
                <button
                  type="button"
                  className={`pagination-button-comment ${
                    currentPage === 1 ? 'disabled' : ''
                  }`}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  {English.G176}
                </button>
                {renderPaginationButtons()}
                <button
                  type="button"
                  className={`pagination-button ${
                    currentPage === totalPages ? 'disabled' : ''
                  }`}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  {English.G69}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {isPopupOpen && (
        <div className="add-item-model">
          <div className="add-item-content feed-report">
            <div className="d-flex justify-content-between header">
              <h3>{English.G406}</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={close}
              >
                <span>
                  <img className="" src={Images.CloseIco} alt="clock icon" />
                </span>
              </button>
            </div>

            <div className="scroll-bars">
              <div className="item-details-input pt-0">
                {isFeedReportLoading ? (
                  Array(8)
                    .fill(0)
                    .map((_, index) => (
                      <div
                        className="d-flex justify-content-start list_table gap-3 mb-4"
                        key={index}
                      >
                        <Skeleton
                          className="rounded-3"
                          width={20}
                          height={20}
                        />
                        <Skeleton
                          className="rounded-3"
                          width={200}
                          height={20}
                        />
                      </div>
                    ))
                ) : (
                  <div className="list_data">
                    {popupData.map((list, index) => {
                      return (
                        <div
                          className="d-flex justify-content-start list_table"
                          key={index}
                        >
                          <div
                            onClick={() => feedReport(list.report_id)}
                            className="table_data"
                          >
                            <span className="number_index">
                              {list.report_id}
                            </span>
                            <span className="user-name">{list.reason}</span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {commentSection && (
        <CommentModal
          title={English.G407}
          comments={comments}
          setComments={setComments}
          HandleCancel={HandleCancel}
          commentIndex={commentIndex}
          commentsList={commentsList}
          editCommentId={editCommentId}
          editReplyId={editReplyId}
          editedCommentText={editedCommentText}
          editedReplyText={editedReplyText}
          handleAddComment={handleAddComment}
          handleAddReply={handleAddReply}
          handleDeleteReply={handleDeleteReply}
          handleEditComment={handleEditComment}
          handleEditReply={handleEditReply}
          handleLikeComment={handleLikeComment}
          shotCommentReplyLike={feedCommentReplyLike}
          handleSaveComment={handleSaveComment}
          handleSaveReply={handleSaveReply}
          isLoadingComments={isLoadingComments}
          newComment={newComment}
          profileData={profileData}
          setCommentIndex={setCommentIndex}
          setEditedCommentText={setEditedCommentText}
          setEditedReplyText={setEditedReplyText}
          setNewComment={setNewComment}
          setShowReplied={setShowReplied}
          shotCommentDelete={feedCommentDelete}
          showReplies={showReplies}
          toggleReplies={toggleReplies}
          isDeleting={isDeleting}
          currentPageComment={currentPageComment}
          setCurrentPageComment={setCurrentPageComment}
          Replied={Replied}
        />
      )}
    </div>
  )
}
export default Feeds

import React from 'react'
import { Images } from '../../helpers'

const ViewerComponent = ({ icon, title, value, percentage }) => {
    return (
      <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
        <div className="w-[50px] h-[50px]">
          <img src={icon} alt="visitor" />
        </div>
        <div className="">
          <p className="text-Main text-base italic font-black mb-[5px] uppercase">
            {title}
          </p>
          <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
            {value}
            {percentage &&
            <>
              <img src={Images.UpPath} alt="" />
              <span className="text-[#00B69B] text-sm font-semibold">
                {percentage}
                %
              </span>
            </>}
          </span>
        </div>
      </div>
    )
}

export default ViewerComponent

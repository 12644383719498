/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { timeDifference } from '../../../helpers/Utility'
import { Images } from '../../../helpers'

const ShotsPreviewCard = ({ defaultPlay, className, videoClassName, videoUrl, CoverImage, UploadedTime, ProfileImg, UserName, onEdit }) => {
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [duration, setDuration] = useState(0)
  const handlePause = () => {
    setIsPlaying(false)
  }

  useEffect(() => {
    setIsPlaying(defaultPlay)
  }, [defaultPlay])

  useEffect(() => {
    const videoElement = document.createElement('video')
    videoElement.src = videoUrl
    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', () => {
        setDuration(videoElement.duration)
        videoElement.volume = 0
        if (defaultPlay) {
          videoElement.play()
        }
      })
    }
  }, [videoUrl, defaultPlay])

  const handleEnded = () => {
    setIsPlaying(false)
  }

  const togglePlayPause = (e) => {
    e.stopPropagation()
    const video = videoRef.current
    if (video.paused) {
      video.play()
      setIsPlaying(true)
    } else {
      video.pause()
      setIsPlaying(false)
    }
  }

  const toggleMute = (e) => {
    e.stopPropagation()
    const video = videoRef.current
    video.muted = !video.muted
    setIsMuted(video.muted)
  }

  const handleProgress = () => {
    const video = videoRef.current
    const progress = (video.currentTime / video.duration) * 100
  }

  const playVolumeIcon = isMuted ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15"
      viewBox="0 0 15 11"
      fill="none"
    >
      <path
        d="M0.966462 5.50142C0.966462 6.3205 0.930479 7.43966 1.39751 7.85979C1.86453 8.27992 2.14213 8.14929 2.93902 8.21284C3.7359 8.27639 5.42769 10.8184 6.7308 10.0134C7.40684 9.4415 7.45909 8.24817 7.45909 5.50142C7.45909 2.75467 7.40685 1.55781 6.73733 0.989398C5.43096 0.184438 3.74571 2.72636 2.94555 2.78991C2.1454 2.85346 1.83841 2.75461 1.39751 3.14297C0.956607 3.53132 0.966462 4.67881 0.966462 5.50142Z"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 3.5C13.0598 4.08693 13.0359 4.28454 12.4995 4.99965C11.9632 5.71476 11.4397 6.41272 10.9995 6.99965"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 3.5C11.1087 4.10874 11.299 4.29904 12.0008 5.00078C12.7025 5.70253 13.3913 6.39126 14 7"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15"
      viewBox="0 0 14 11"
      fill="none"
    >
      <path
        d="M0.966462 5.50142C0.966462 6.3205 0.930479 7.43966 1.39751 7.85979C1.86453 8.27992 2.14213 8.14929 2.93902 8.21284C3.7359 8.27639 5.42769 10.8184 6.7308 10.0134C7.40684 9.4415 7.45909 8.24817 7.45909 5.50142C7.45909 2.75467 7.40685 1.55781 6.73733 0.989398C5.43096 0.184438 3.74571 2.72636 2.94555 2.78991C2.1454 2.85346 1.83841 2.75461 1.39751 3.14297C0.956607 3.53132 0.966462 4.67881 0.966462 5.50142Z"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5059 1.43457C12.273 2.62607 12.6843 4.04296 12.6843 5.49468C12.6843 6.94639 12.273 8.36329 11.5059 9.55478"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.96289 3.04004C10.36 3.78848 10.569 4.6362 10.569 5.499C10.569 6.36179 10.36 7.20959 9.96289 7.95804"
        stroke="white"
        strokeWidth="1.11746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )

  return (
    <div className={`video_player rounded-2xl overflow-hidden relative aspect-[9/16] ${className}`}>
      <div className="bg-shotTop absolute top-0 w-full h-1/2" />
      {defaultPlay &&
      <div className="play-time-full flex flex-row top-0 px-2 pb-2 pt-3 gap-2 !bottom-[none] absolute justify-between items-center w-full z-50">
        <div className="flex flex-row gap-2 items-center justify-content-center">
          <img className="h-7 w-7 rounded-full border-2 border-background" src={ProfileImg} alt="" />
          <div className="flex flex-col justify-between gap-1">
            <span className="font-semibold text-[12px] text-white leading-none">{UserName}</span>
            <span className="font-normal text-[12px] text-white leading-none">{UploadedTime}</span>
          </div>
        </div>
        <div className="justify-content-end">
          <div className="flex flex-row gap-2 justify-content-center items-center">
            <button
              onClick={togglePlayPause}
              type="button"
              className="play-pause-btn !flex items-center h-4 bg-inactive"
            >
              {isPlaying ? '⏸' : '▶'}
            </button>
            <button
              onClick={toggleMute}
              type="button"
              className="mute-unmute-btn !flex items-center h-4"
            >
              {playVolumeIcon}
            </button>
            <div className="image-buttons !flex !static">
              <label>
                <button
                  type="button"
                  onClick={() => onEdit && onEdit()}
                  className="d-none"
                />
              </label>
            </div>
          </div>
        </div>
      </div>}
      <video
        width="100%"
        height="394"
        className={`aspect-[9/16] ${videoClassName}`}
        ref={videoRef}
        autoPlay={isPlaying}
        poster={CoverImage}
        controlsList="nodownload noplaybackrate"
        disablePictureInPicture
        allowFullScreen
        onPlay={() => {
          setIsPlaying(true)
        }}
        key={`id-${videoUrl}-${isPlaying}`}
        onPause={handlePause}
        onEnded={handleEnded}
        onClick={togglePlayPause}
        onTimeUpdate={handleProgress}
      >
        {duration > 0 && <source src={videoUrl} type="video/mp4" />}
      </video>
      <div className="bg-shotBottom absolute bottom-0 pb-3 w-full h-1/2 justify-items-center content-end">
        <img className="-rotate-90 py-3" src={Images.breadcrumbAerrow} alt="" />
        <span className="text-white font-semibold text-base">
          Replay
        </span>
      </div>
      {!defaultPlay &&
        <div className="absolute top-0 left-0 flex items-center justify-center bg-[#151515CC] w-full h-full">
          <div className="flex flex-col gap-2 items-center justify-content-center">
            <img className="h-[86px] w-[86px] rounded-full border-2 border-background" src={ProfileImg} alt="" />
            <div className="flex flex-col justify-between gap-1 items-center">
              <span className="font-semibold text-[16px] text-white">{UserName}</span>
              <span className="font-normal text-[14px] text-white">{UploadedTime}</span>
            </div>
          </div>
        </div>}
    </div>
  )
}

export default ShotsPreviewCard

import React from 'react'
import { English } from '../../helpers'
import HeadingComponent from './HeadingComponent'

const UploadingComponent = ({ uploadPercentage }) => {
  return (
    <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-[9999999] mt-10">
      <HeadingComponent
        title={English.A207}
        className="!text-lg !text-[#303030]"
        parentClass="!mt-0 !mb-0"
      />
      <h2 className="text-[#303030] font-medium text-center text-lg">
        {uploadPercentage}
        %
      </h2>
      <hr
        style={{ width: `${uploadPercentage}%` }}
        className="border-solid !border-[#303030]"
      />
    </div>
  )
}

export default UploadingComponent

import React from 'react'
import { RotatingLines } from 'react-loader-spinner'
// import { RotatingLines } from 'react-loader-spinner'
import CommonButton from './ButtonComponent/ButtonCommon'
import { English, Images } from '../helpers'
import './CommonModalMain.css'

const CommonModalMain = (props) => {
  //   const [isLoading, setIsLoading] = useState(false)
  const {
    onPressClose,
    children,
    showCross,
    title,
    className,
    titleClassName,
    currentCount,
    totalCount,
    showCount,
    parentClassName,
    buttonClassName,
    isPrevious,
    isNext,
    onPressBack,
    isButtonDisabled,
    cancelButtonDisabled,
    onPressSave,
    showFooter,
    buttonClassNamePrimary,
    loading = false,
    closeIcon
  } = props

  return (
    <div className="custom-model-overlay">
      <div
        className={`success-modals !pt-0 !p-[20px] max-h-[100%] overflow-y-auto flex flex-col items-center relative ${className}`}
      >
        {loading &&
        <div className="loader-container visible">
          <RotatingLines
            visible
            width="40"
            strokeColor="#0B0A0A"
            strokeWidth="2"
            animationDuration="0.90"
            ariaLabel="rotating-lines-loading"
          />
        </div>}
        <div className="flex items-center justify-between w-full sticky top-0 z-10 bg-white py-[20px]">
          <h3
            className={`mb-0 italic !text-[16px] !text-[#0B0A0A] !font-black uppercase ${titleClassName}`}
          >
            {title}
          </h3>
          <div className="flex justify-normal gap-6">
            {showCross && (
              <CommonButton
                type="button"
                disabled={closeIcon || false}
                onClick={() => {
                  if (onPressClose) {
                    onPressClose(false)
                  }
                }}
                className="!border-none !p-0 !justify-end !bg-transparent !w-[18px] !h-[18px]"
                imgSrc={Images.CloseIco}
              />
            )}
          </div>
        </div>
        {(currentCount !== null || currentCount !== -1) &&
          (totalCount !== null || totalCount !== -1) &&
          showCount && (
            <div className="bg-[#E4E4E4]  text-[#151515] w-full p-[10px] rounded-[10px] !mb-5">
              <div className="flex items-end gap-1.5 text-[#151515] font-medium text-base leading-none justify-center">
                <span className="text-[#151515] font-medium text-[20px]">
                  0
                  {currentCount}
                </span>
                {' '}
                of
                {' '}
                <span className="text-[#151515] font-medium text-[14px]">
                  0
                  {totalCount}
                </span>
              </div>
            </div>
          )}
        {children}
        {showFooter === true && (
          <div
            className={`flex items-center justify-between w-full gap-6 pt-[20px] ${parentClassName}`}
          >
            <CommonButton
              className={`!leading-none !py-[16px] ${buttonClassName}`}
              type="button"
              label={isPrevious ?? ''}
              disabled={cancelButtonDisabled || false}
              onClick={onPressBack}
              spanClassName="uppercase"
              isPrimary={false}
              context={English.A197}
            />
            <CommonButton
              className={buttonClassNamePrimary}
              type="button"
              label={isNext ?? ''}
              disabled={isButtonDisabled || false}
              onClick={(e) => {
                e.stopPropagation()
                if (onPressSave) {
                  onPressSave()
                }
              }}
              spanClassName="uppercase"
              isPrimary
              context={English.A197}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CommonModalMain

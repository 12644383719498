/* eslint-disable react/react-in-jsx-scope */
import { English, Images } from '../../helpers'
import CommonButton from '../ButtonComponent/ButtonCommon'
import './Comment.css'

const ReplyInput = ({ newComment, setNewComment, handleAddComment, className }) => {
  return (
    <div className={`add-comment-discussion reply_input ${className}`}>
      <img className="absolute ml-4 mt-[12px]" src={Images.SmileEmoji_icon} alt="" />
      <input
        type="text"
        className="h-[50px] w-full !px-[54px] rounded-[35px] border border-secondaryBorder bg-primaryBg "
        placeholder={English.G151}
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        name="reply"
      />
      <CommonButton
        type="button"
        className="comment_add_btn top-0 !rounded-r-[25px]"
        // label={English.G152}
        disabled={newComment.trim().length === 0}
        onClick={() => handleAddComment(newComment)}
        imgSrc={Images.Send_btn}
      />
    </div>
  )
}

export default ReplyInput

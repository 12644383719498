/* eslint-disable react/react-in-jsx-scope */
import { Swiper } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { forwardRef } from 'react'
// import { PaginationOptions, SwiperModule } from 'swiper/types'

// interface ImageSliderProps {
//   paginationType?: PaginationOptions['type']
//   className?: string
//   children: ReactNode
//   clickable?: boolean
//   isAutoPlay?: boolean
//   delay?: number
//   modulesType?: SwiperModule[]
//   showNavigation?: boolean
//   slidesPerViewType?: SwiperProps['slidesPerView']
//   spaceBetweenProp?: number
//   grabCursorType?: boolean
//   isCenteredSlideType?: boolean
//   initialSlides?: number
//   onSlideChange?: (item: SwiperClass) => void
//   onSlideNextTransitionEnd?: (item: SwiperClass) => void
//   coverflowEffect?: SwiperProps['coverflowEffect']
//   effectType?: SwiperProps['effect']
// }

const ImageSlider = forwardRef((props, ref) => {
  const {
    modulesType,
    paginationType,
    className = '',
    children,
    clickable,
    isAutoPlay,
    delay,
    showNavigation,
    slidesPerViewType,
    spaceBetweenProp,
    grabCursorType,
    initialSlides,
    isCenteredSlideType,
    onSlideChange,
    onSlideNextTransitionEnd,
    coverflowEffect,
    effectType
  } = props

  return (
    <Swiper
      className={className || 'mySwiper'}
      pagination={{ type: paginationType, clickable }}
      modules={modulesType}
      rewind
      navigation={showNavigation}
      slidesPerView={slidesPerViewType || 'auto'}
      autoplay={isAutoPlay ? { delay, disableOnInteraction: false } : false}
      spaceBetween={spaceBetweenProp}
      grabCursor={grabCursorType || false}
      initialSlide={initialSlides}
      centeredSlides={isCenteredSlideType || false}
      coverflowEffect={coverflowEffect}
      effect={effectType}
      onSlideChange={(item) => {
        if (onSlideChange) {
          onSlideChange(item)
        }
      }}
      onSlideNextTransitionEnd={(item) => {
        if (onSlideNextTransitionEnd) {
          onSlideNextTransitionEnd(item)
        }
      }}
      ref={ref}
    >
      {children}
    </Swiper>
  )
})

export default ImageSlider

/* eslint-disable no-empty */
import React, { useEffect, useState } from 'react'
import './sidebar.css'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import { setUserSliceData } from '../../services/http-services'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import { emitter, EventType } from '../../helpers/Emitter'
import { MenuHeader } from './Sidebar'

export const pathMapping = {
  Store: 'Store',
  Community: 'Community',
  Entertainment: 'Entertainment',
  Local: 'Local'
}

const SidebarNew = ({ onPlatformChange = () => {} }) => {
  const app_id = useSelector((state) => state.user?.app_id)
  const userType = useSelector((state) => state.user?.user_type)
  const params = useParams()

  const [inputFields, setInputFields] = useState({
    id: '',
    name: ''
  })

  const [userTypesData, setUserTypesData] = useState([])
  const [addOns, setAddOns] = useState([])
  const [defaultAddsOns, setDefaultAddsOns] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [activeSidebar, setActiveSidebar] = useState('')
  const defaultOns = [
    {
      name: English.G222,
      id: -1
    },
    {
      name: English.G223,
      id: -2
    }
  ]

  const getPlatformData = async () => {
    setIsLoading(true)
    await APICall(
      'get',
      '',
      `${EndPoints.applicationsAddons}?type=${userType}`,
      'profile'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const DecodedData = res?.decryptedData
          setUserTypesData(DecodedData)
          const FinalData = DecodedData.find((d) => d.id === app_id.id)
          setUserSliceData(FinalData, 'appId')
          const additionalAddOns = FinalData.add_ons_data.filter((item) => !item.default_add_ons)
          const defaultAdd = FinalData.add_ons_data.filter((item) => item.default_add_ons)
          setDefaultAddsOns([...defaultOns, ...defaultAdd])
          setAddOns(additionalAddOns)
          onPlatformChange(FinalData)
          setInputFields({
            ...inputFields,
            id: FinalData.id,
            name: FinalData.name
          })
          setIsLoading(false)
        } else {
          setIsLoading(false)
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.data?.message)
      })
  }

  useEffect(() => {
    const event = emitter.addListener(
      EventType.sidebarRoute,
      ({ product_id, action }) => {
        // setSidebarDataProduct(product_id)
        // eslint-disable-next-line no-unused-expressions
        product_id && console.log(product_id)
        setActiveSidebar(action)
      }
    )
    return () => event.remove()
  }, [activeSidebar])

  const changeAddOns = (id) => {
    const FinalData = userTypesData.find((d) => d.id === id)
    const additionalAddOns = FinalData.add_ons_data.filter((item) => !item.default_add_ons)
    const defaultAdd = FinalData.add_ons_data.filter((item) => item.default_add_ons)
    setDefaultAddsOns([...defaultOns, ...defaultAdd])
    setAddOns(additionalAddOns)
    const AppData = userTypesData.filter((item) => item.id === id)[0]
    setUserSliceData(AppData, 'appId')
    window.dispatchEvent(new Event('storage'))
    emitter.emit(EventType.sidebarRoute, {
      action: 'Home',
      product_id: params.id
    })
  }

  const [localStorageValue, setLocalStorageValue] = useState(app_id || null)

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event?.key !== 'bis_data' && app_id) {
        setLocalStorageValue(app_id?.id || null)
      }
      if (event.key === 'addOns') {
        getPlatformData()
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [app_id])

  useEffect(() => {
    if (localStorageValue !== null) {
      getPlatformData()
    }
  }, [])

  useEffect(() => {
    if (defaultAddsOns && defaultAddsOns.length > 0) {
      const action = defaultAddsOns[0].name
      const product_id = params.id
      if (activeSidebar !== action) {
        setActiveSidebar(action)
        setTimeout(() => {
          emitter.emit(EventType.sidebarRoute, {
            action,
            product_id
          })
        }, 1000)
      }
    }
  }, [defaultAddsOns, addOns])

  return (
    <div className="custom-sidebar custom-sidebar-builder !w-[220px] p-0 !rounded-l-[15px]">
      <div className="sidebar-right-parts">
        <label>{English.G243}</label>
        <Dropdown
          title={inputFields.name}
          activeItem={inputFields}
          onChanged={(d) => {
            changeAddOns(d.id)
            setInputFields({
              ...inputFields,
              id: d.id,
              name: d.title
            })
          }}
          Data={userTypesData.map((d) => {
            return {
              title: d.name,
              id: d.id
            }
          })}
        />

        <div className="links-ends">
          <ul>
            {isLoading
              ? Array.from({ length: 3 }).map((_, index) => (
                <Skeleton
                  key={index}
                  className="rounded-3 mb-1"
                  height={48}
                  width={181}
                />
                ))
              :
              <>
                <MenuHeader name={English.B101} user_type="creator" />
                {
                  defaultAddsOns.map((i, index) => {
                    return (
                      <div key={index}>
                        <li>
                          <span
                            className={activeSidebar === i.name ? 'active' : ''}
                            onClick={() => {
                              emitter.emit(EventType.sidebarRoute, {
                                product_id: params.id,
                                action: i.name
                              })
                              setActiveSidebar(i.name)
                            }}
                          >
                            <div className="justify-between">
                              {i.name}
                              <div className="cursor-pointer image-block">
                                <img src={Images.RightAerrow} alt="" />
                              </div>
                            </div>
                          </span>
                        </li>
                      </div>
                    )
                  })
                }
                <MenuHeader name={English.B102} user_type="creator" />
                {
                  addOns.map((i, index) => {
                    return (
                      <div key={index}>
                        <li>
                          <span
                            className={activeSidebar === i.name ? 'active' : ''}
                            onClick={() => {
                              emitter.emit(EventType.sidebarRoute, {
                                product_id: params.id,
                                action:
                                  (i.name === 'Store' &&
                                    `${pathMapping.Store}`) ||
                                  (i.name === 'Community' &&
                                    `${pathMapping.Community}`) ||
                                  (i.name === 'Local' &&
                                    `${pathMapping.Local}`) ||
                                  (i.name === 'Entertainment' &&
                                    `${pathMapping.Entertainment}`)
                              })
                              setActiveSidebar(i.name)
                            }}
                          >
                            <div className="justify-between">
                              {i.name}
                              <div className="cursor-pointer image-block">
                                <img src={Images.RightAerrow} alt="" />
                              </div>
                            </div>
                          </span>
                        </li>
                      </div>
                    )
                  })
                }
              </>}
          </ul>
          <ul>
            <MenuHeader name={English.A176} user_type="creator" />
            <li>
              <span
                onClick={() => {
                  emitter.emit(EventType.sidebarRoute, {
                    product_id: 'installAddons' && `${params.id}`,
                    action: 'installAddons' && '/install-addons/'
                  })
                }}
              >
                <div className="">
                  {/* <div className="cursor-pointer image-block">
                    <img src={Images.plush_round} alt="" />
                  </div> */}
                  {English.G246}
                </div>
              </span>
            </li>
            <li>
              <span to="">
                <div className="">
                  {/* <div className="cursor-pointer image-block">
                    <img src={Images.setting_gen} alt="" />
                  </div> */}
                  {English.G245}
                </div>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default SidebarNew

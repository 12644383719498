/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
// import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { formatViews, lockScroll, unlockScroll } from '../../helpers/Utility'
import { s3ImageProfile } from '../../services/Service'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import ProfileSkeleton from './ProfileSkeleton'
import { APICall, EndPoints } from '../../services'
import EditProfileModel from './EditProfileModel/EditProfileModel'
import { emitter, EventType } from '../../helpers/Emitter'

const ProfileHeadLocal = () => {
  const app_id = useSelector((state) => state.user?.app_id)
  const componentRef = useRef()
  // const navigate = useNavigate()
  const [profile, setProfile] = useState([])
  const [profileEditData, setProfileEditData] = useState(null)
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const profileGet = async () => {
    setIsLoading(true)
    const platFormData = {
      app_id: app_id.id
    }
    await APICall('post', platFormData, EndPoints.getLocalProfile, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const data = res?.decryptedData
          setProfile(data)
          emitter.emit(EventType.localProfile, data[0])
          // setInputFields({
          //   icon: data[0]?.profile_icon,
          //   description: data[0]?.description,
          //   website_link: data[0]?.website_link
          // })
          // setProfileImg(data[0]?.profile_icon)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.data?.message)
      })
  }

  // const EditProfile = (profileData) => {
  //   setShowProfileModel(true)
  //   setProfileEditData(profileData)
  //   // setInputFields({
  //   //   ...inputFields,
  //   //   description: profileData.description,
  //   //   website_link: profileData.website_link,
  //   //   icon: profileData.profile_icon
  //   // })
  //   // setProfileImg(profileData.profile_icon)
  // }
  useEffect(() => {
    const event = emitter.addListener(EventType.editProfileModal, () => {
      setShowProfileModel(true)
      setProfileEditData(profile[0])
    })

    return () => event.remove()
  }, [app_id, profile])

  const getStoreLink = (storeLink) => {
    if (!storeLink) return undefined
    return storeLink.startsWith('http') ? storeLink : `http://${storeLink}`
  }

  const HandleCancel = () => {
    setShowProfileModel(false)
    // setIsEdited(false)
    // clearValue()
  }

  // const handleGoBack = () => {
  //   navigate(-1)
  // }

  const profileInitialized = useRef(false)

  useEffect(() => {
    if (!profileInitialized.current) {
      profileInitialized.current = true
      profileGet()
    }
  }, [])

  useEffect(() => {
    if (ShowProfileModel) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [ShowProfileModel])

  return (
    <div>
      {isLoading ? (
        <ProfileSkeleton />
      ) : (
        profile.map((data, i) => (
          <div key={i} className="profile-head-builder">
            {/* <div className="profile-builder-header mb-40">
              <button type="button" onClick={handleGoBack}>
                <img alt="" src={Images.LeftIco} />
              </button>
              <div className="profile-heading">
                <img alt="" src={Images.Building} />
                <span>{data.user_name}</span>
              </div>
              <button type="button" onClick={() => EditProfile(data)}>
                <img alt="" src={Images.MenuIcon} />
              </button>
            </div> */}

            <div className="flex gap-5 justify-center items-center text-center">
              <div>
                <div className="profile-personal-detail !w-auto">
                  <div className="profile-build-image w-[150px] h-[150px]">
                    <img className="w-full h-full object-cover" alt="" src={s3ImageProfile + data.profile_icon} />
                  </div>
                </div>
              </div>
              <div>
                <div className="profile-heading mb-[20px] justify-center">
                  <img className="w-[16px] h-[16px]" alt="" src={Images.Building} />
                  <span>{data.user_name}</span>
                </div>
                <ul className="about-account-insignt buttons-group !gap-[60px] !w-[100%]">
                  <li className="flex justify-center items-center gap-1">
                    <h4 className="profile_post_count mb-0">
                      {formatViews(data.total_post)}
                    </h4>
                    <p className="post-follow-like">{English.G104}</p>
                  </li>
                  <li className="flex justify-center items-center gap-1">
                    <h4 className="profile_follower_count mb-0">
                      {formatViews(data.total_followers)}
                    </h4>
                    <p
                      className="post-follow-like"
                    >
                      {English.G105}
                    </p>
                  </li>
                  <li className="flex justify-center items-center gap-1">
                    <h4 className="mb-0">
                      {formatViews(data.total_likes)}
                    </h4>
                    <p className="post-follow-like">{English.G106}</p>
                  </li>
                </ul>
                <p className="text-sm font-medium text-[#0B0A0A] mt-3">
                  {data.description.slice(0, 65)}
                  {data.description.length > 65 && '...'}
                </p>
                {data?.website_link && (
                  <div className="profile-redirect">
                    <a
                      className="gredient-txt justify-center"
                      target="_blank"
                      href={getStoreLink(data?.website_link)}
                      rel="noreferrer"
                    >
                      <img alt="" src={Images.Redirect} />
                      {data.website_link}
                    </a>
                  </div>
                )}

                <div className="follow-connenct !w-[450px]">
                  <div className="flex gap-4">
                    <button
                      type="button"
                      className="primary-btn success-btn !w-[174px] disabled"
                    >
                      {English.G107}
                    </button>
                    <button type="button" className="px-10 py-7 !w-[174px] bg-[#FAFAFA]">
                      <span className="text-[#0B0A0A] text-xs font-semibold">
                        Other Socials
                      </span>
                    </button>
                  </div>
                  <div>
                    <img alt="" src={Images.GbnLogo} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
      {ShowProfileModel && (
        <EditProfileModel
          type="local"
          title={English.G111}
          linkTitle={English.G113}
          profileEditData={profileEditData}
          onCancel={HandleCancel}
          componentRef={componentRef}
          onSubmite={(data) => {
            APICall('post', data, EndPoints.EditLocalProfile, 'local')
              .then((res) => {
                if (res.status === 200 && res.data?.code === 200) {
                  setTimeout(() => {
                    profileGet()
                    toast.success(res?.data?.message)
                    setShowProfileModel(false)
                    setProfileEditData(null)
                  }, 3000)
                } else {
                  setShowProfileModel(false)
                  setProfileEditData(null)
                }
              })
              .catch((error) => {
                toast.error(error?.data?.message)
                // setIsEdited(false)
              })
          }}
        />
      )}
    </div>
  )
}
export default ProfileHeadLocal

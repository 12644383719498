/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import './Discount.css'
import './RoomAdd.css'
import { toast } from 'react-toastify'
import Pagination from '../../Pagination/Pagination'
import English from '../../../helpers/English'
import { APICall, EndPoints } from '../../../services'
import { s3ImageHotel } from '../../../services/Service'
import { Images } from '../../../helpers'

const DiscountShow = ({ isHotelId }) => {
  const [discountListData, setDiscountListData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const allDiscountData = (currentPage) => {
    const payload = {
      hotel_id: isHotelId
    }
    APICall(
      'post',
      payload,
      `${EndPoints.getDiscount}?page=${currentPage}`,
      'local'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setDiscountListData(res?.decryptedData.data)
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handlePageChange = (pageNumber) => {
    allDiscountData(pageNumber)
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    allDiscountData(currentPage)
  }, [])

  return (
    <div className="">
      <div className="statistic-chart-container position-relative">
        {discountListData?.length === 0 ? (
          <div className="add_room_text">
            <h4>No Deal Available</h4>
          </div>
        ) : (
          <div>
            <div className="local-room-main">
              <h3 className="d-flex align-items-center justify-content-between uppercase italic">
                {English.G453}
              </h3>
            </div>
            <div className="discount_list">
              <div className="grid grid-cols-2 gap-[25px]">
                {discountListData.map((data, index) => (
                  <div key={index}>
                    <div className="flex border border-[#E1E2E5] border-l-0 rounded-[20px]">
                      <div>
                        <img
                          src={s3ImageHotel + data.image}
                          alt="roomImage"
                          className="!w-[149px] !h-[114px] object-center rounded-tl-[20px] rounded-bl-[20px]"
                        />
                      </div>
                      <div className="p-[10px] flex flex-col justify-between flex-1">
                        <div>
                          <div className="flex gap-[6px] items-center">
                            <img src={Images.Fire_icon_blue} alt="a" />
                            <h6 className="mb-0 bg-primaryGradient bg-clip-text text-transparent font-black text-sm italic pe-1">
                              {data.discount}
                              {' '}
                              OFF
                            </h6>
                          </div>
                          <h6 className="text-sm font-extrabold uppercase text-Main">{data.discount_name}</h6>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2">
                            <labe className="text-Main font-medium text-[15px]">{English.A216}</labe>
                            <p className="mb-0 text-Main font-bold text-[15px] uppercase">{data.coupan_code}</p>
                          </div>
                          <img
                            className="rotate-180"
                            src={Images.left_arrow_icon}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default DiscountShow

import React from 'react'
import { capitalizeFirstLetter } from '../../helpers/Utility'
import { English } from '../../helpers'
import CommonInput from '../InputComponet/CommonInput'
import CommonModalMain from '../CommonModalMain'
import Dropdown from '../Dropdown/Dropdown'

const AddDiscussionModal = ({
    HandleCancel,
    HandlePublish,
    isFormComplete,
    handleChange,
    inputFields,
    isEditing,
    getCategoryName,
    category,
    setInputFields
}) => {
  return (
    <div className="">
      <CommonModalMain
        showCross
        title={English.A213}
        isPrevious={English.G116}
        isNext={English.G117}
        onPressClose={HandleCancel}
        onPressSave={HandlePublish}
        onPressBack={HandleCancel}
        showFooter
        isButtonDisabled={!isFormComplete}
      >
        <div className="w-full h-full flex flex-col gap-5 justify-between overflow-y-auto">
          <div className="item-details-input pt-0">
            <div>
              <label>{English.A234}</label>
              <CommonInput
                placeholder={English.A235}
                name="topic"
                onChange={handleChange}
                value={capitalizeFirstLetter(inputFields?.topic)}
                disabled={isEditing}
              />
            </div>
            <div className="poll-dropdown">
              <label>{English.G124}</label>
              <Dropdown
                title={getCategoryName(inputFields.category_id)}
                onChanged={(d) => {
                    setInputFields({
                      ...inputFields,
                      category_id: d.id
                    })
                  }}
                Data={category.map((d) => ({
                    title: d.category_name,
                    id: d.id
                  }))}
                name="category_id"
                customClass={
                    inputFields.category_id
                      ? 'selected-class !mb-[16px] !mt-0'
                      : 'giveaway-dropdown !mb-[16px] !mt-0'
                  }
              />
            </div>

            <div>
              <label>{English.G112}</label>
              <CommonInput
                placeholder={English.A239}
                name="description"
                onChange={handleChange}
                value={capitalizeFirstLetter(inputFields.description)}
                  // maxLength={350}
                className="disscussion_description pt-3"
                isTextArea
              />
            </div>
          </div>
        </div>
      </CommonModalMain>
    </div>
  )
}
export default AddDiscussionModal

/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { Link, useLocation } from 'react-router-dom'
import ReactDatePicker from 'react-datepicker'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import _, { cloneDeep } from 'lodash'
import OrderChart from './chart'
import Discount from './RestaurantComponent/Discount'
import Menu from './RestaurantComponent/Menu'
import RestaurantPopup from './addPopup/restaurantPopup'
import CommonButton from '../ButtonComponent/ButtonCommon'
import { compressImage, dataDecrypt } from '../../services/http-services'
import { s3ImageRestaurant, urlSystemIcon } from '../../services/Service'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import Loader from '../Loader/Loader'
import CommonInput from '../InputComponet/CommonInput'
import {
  lockScroll,
  normalizeAddress,
  unlockScroll
} from '../../helpers/Utility'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import { emitter, EventType } from '../../helpers/Emitter'
import ContactModal from './ContactModal'

const RestaurantDetails = () => {
  const location = useLocation()
  const RestaurantId = location.state?.restaurantId
  const [startDate, setStartDate] = useState(new Date())
  const [isRestaurantId, setIsRestaurantId] = useState(RestaurantId)
  const [isPlatFormModal, setIsPlatFormModal] = useState(false)
  const [isRestaurant, setIsRestaurant] = useState(false)
  const [loading, setLoading] = useState(false)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [inputFields, setInputFields] = useState({
    city_id: '',
    contact_name: '',
    contact_designation: '',
    // contact_phone: '',
    contact_profile_pic: '',
    contact_id: '',
    restaurant_id: isRestaurantId
  })

  const [isParticularLoading, setIsParticularLoading] = useState(false)
  const [particularRestaurantDeatils, setParticularRestaurantDeatils] =
    useState([])
  const [appData, setAppData] = useState([])
  const [personDetails, setPersonDetails] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [contactIndex, setContactIndex] = useState(null)
  const [delContactId, setDelContactId] = useState()
  const [editData, setEditData] = useState({})
  const [isShow, setIsShow] = useState(false)
  const [isRestaurantEdit, setIsRestaurantEdit] = useState(false)
  const [isStep4Modified, setIsStep4Modified] = useState(false)
  const [initialInputFields, setInitialInputFields] = useState({
    contact_name: '',
    contact_designation: '',
    // contact_phone: '',
    contact_profile_pic: ''
  })
  const [expandedIndex, setExpandedIndex] = useState(null)
  const [isPublish, setIsPublish] = useState(true)
  const [isAddingNewRestaurant, setIsAddingNewRestaurant] = useState(false)
  const [contactLoading, setContactLoading] = useState(false)
  const [contactImage, setContactImage] = useState(false)

  const clearValue = () => {
    setInputFields({
      city_id: '',
      contact_name: '',
      contact_designation: '',
      // contact_phone: '',
      contact_profile_pic: '',
      contact_id: ''
    })
  }

  // const onClickPublish = () => {
  //   setIsPlatFormModal(true)
  // }

  const onClickPublish = () => {
    setIsPublish(true)
    setIsPlatFormModal(true)
  }

  const handleCancels = () => {
    const resetAppData = appData.map((item) => ({
      ...item,
      checked: false
    }))
    setAppData(resetAppData)
    setIsPlatFormModal(false)
  }

  function formatTime(time) {
    return time.slice(0, 5)
  }

  const onClickEdit = (data) => {
    setIsRestaurant(true)
    setIsRestaurantEdit(true)
    setEditData({
      restaurant_id: data?.restaurant_id,
      location_title: data?.location_title,
      name: data?.name,
      address: normalizeAddress(data?.address),
      description: data?.description,
      country_id: data?.country_id,
      province_id: data?.province_id,
      city_id: data?.city_id,
      zip_code: data?.zip_code,
      longitude: data?.longitude,
      latitude: data?.latitude,
      website: data?.website,
      opening_time: formatTime(data?.opening_time),
      closing_time: formatTime(data?.closing_time),
      price_range:
        data?.min_price && data?.max_price
          ? `${data?.min_price} - ${data?.max_price}`
          : '',
      min_price: data?.min_price,
      max_price: data?.max_price,
      logo: data?.logo,
      images: data?.images,
      contact_name1: data?.contact_details?.[0]?.contact_name ?? '',
      contact_name2: data?.contact_details?.[1]?.contact_name ?? '',
      contact_designation1:
        data?.contact_details?.[0]?.contact_designation ?? '',
      contact_designation2:
        data?.contact_details?.[1]?.contact_designation ?? '',
      // contact_phone1: data?.contact_details?.[0]?.contact_phone ?? null,
      // contact_phone2: data?.contact_details?.[1]?.contact_phone ?? null,
      contact_profile_pic: [
        data?.contact_details?.[0]?.contact_profile_pic,
        data?.contact_details?.[1]?.contact_profile_pic
      ]
    })
  }

  const handleCancel = () => {
    setIsRestaurant(false)
    setIsAddingNewRestaurant(false)
  }

  const handleDeleteCancel = () => {
    setShowConfirmModel(false)
  }

  const cancelContact = () => {
    setPersonDetails(false)
  }

  const ShowContactConfirm = (videoId) => {
    setDelContactId(videoId)
    setShowConfirmModel(true)
  }

  const clearValues = () => {
    setParticularRestaurantDeatils([])
  }

  const onChange = (e) => {
    setInputFields((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleEditContact = async () => {
    setContactLoading(true)
    let contact_profile_pic = ''

    if (inputFields.contact_profile_pic instanceof File) {
      contact_profile_pic = await onUploadImage(
        inputFields.contact_profile_pic,
        'contact_profile_pic'
      )
    } else {
      contact_profile_pic = inputFields.contact_profile_pic
    }
    await hotelContactEditApi(contact_profile_pic)
  }

  const onUploadImage = async (file, fieldName) => {
    if (file) {
      const reader = new FileReader()
      const readFileAsync = new Promise((resolve, reject) => {
        reader.onload = async () => {
          const imageFile = await compressImage(file)
          const coverFormData = new FormData()
          coverFormData.append('file', imageFile)
          coverFormData.append('type', 'restaurant_contact')

          try {
            const res = await APICall(
              'post',
              coverFormData,
              '',
              'imageUploadLocal',
              true
            )

            if (res.status === 200 && res.data?.code === 200) {
              const decryptedCoverData = dataDecrypt(res.data.data)
              if (fieldName === 'contact_profile_pic') {
                resolve(decryptedCoverData)
              }
            } else {
              toast.error(res?.data?.message || 'Error uploading image')
            }
          } catch (err) {
            toast.error(err?.response?.data?.message || 'Upload failed')
          }
        }
        reader.onerror = (error) => reject(error)
        reader.readAsDataURL(file)
      })
      return readFileAsync
    }
  }

  const onUploads = async (file, fieldName, index) => {
    if (file) {
      setInputFields((prev) => {
        if (fieldName === 'contact_profile_pic') {
          return {
            ...prev,
            [fieldName]: file
          }
        }
      })
      setContactImage(true)
    }
  }

  const onClickContactEdit = (item = {}) => {
    setPersonDetails(true)
    if (Object.keys(item).length > 0) {
      setIsEdit(true)
      const updatedInputFields = {
        restaurant_id: isRestaurantId,
        contact_name: item.contact_name,
        contact_designation: item.contact_designation,
        // contact_phone: item.contact_phone,
        contact_profile_pic: item.contact_profile_pic,
        contact_id: item.contact_id
      }
      setInputFields(updatedInputFields)
      setInitialInputFields(updatedInputFields)
    }
  }

  const getApplicationsData = async () => {
    APICall('get', '', EndPoints.applications, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedData = res?.decryptedData
          setAppData(decryptedData)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const RestaurantPublishApi = async (app_id) => {
    const publishState = app_id ? false : isPublish
    const data = {
      restaurant_id: isRestaurantId,
      app_id: app_id
        ? [app_id]
        : appData.filter(({ checked }) => checked).map((e) => e.id),
      publish: publishState
    }
    APICall('post', data, `${EndPoints.publishRestaurant}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          getApplicationsData()
          setIsPlatFormModal(false)
          toast.success(res?.data?.message)
        } else {
          toast.error(res?.data?.message)
          setIsPlatFormModal(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const particularRestaurantData = (id, type = null) => {
    setIsParticularLoading(true)
    setIsRestaurantId(id)
    const payload = {
      restaurant_id: id
    }
    APICall('post', payload, `${EndPoints.particularRestaurant}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsShow(true)
          setParticularRestaurantDeatils(res?.decryptedData)
          // setParticularReatroDeatils(true)
          setIsParticularLoading(false)
          getApplicationsData()
          if (type === 'isEdit') {
            onClickEdit(res?.decryptedData[0])
          }
        } else {
          setIsParticularLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsParticularLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const hotelContactEditApi = async (contact_profile_pic) => {
    setContactLoading(true)
    const data = {
      restaurant_id: isRestaurantId,
      contact_name: inputFields.contact_name,
      contact_designation: inputFields.contact_designation,
      // contact_phone: inputFields.contact_phone,
      contact_profile_pic:
        contact_profile_pic || inputFields.contact_profile_pic,
      contact_id: inputFields.contact_id
    }
    if (isEdit) {
      APICall('post', data, `${EndPoints.restaurantContactEdit}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setTimeout(() => {
              setPersonDetails(false)
              setIsEdit(false)
              clearValue()
              particularRestaurantData(inputFields.restaurant_id)
              toast.success(res?.data?.message)
              setContactLoading(false)
            }, 2500)
          } else {
            setIsEdit(false)
            setContactLoading(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setIsEdit(false)
          setContactLoading(false)
          toast.error(error?.data?.message)
        })
    } else {
      APICall('post', data, `${EndPoints.restaurantContactAdd}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setTimeout(() => {
              setPersonDetails(false)
              particularRestaurantData(isRestaurantId)
              toast.success(res?.data?.message)
              setContactLoading(false)
              clearValue()
            }, 2500)
          } else {
            setContactLoading(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setContactLoading(false)
          toast.error(error?.data?.message)
        })
    }
  }

  const hotelContactDeleteApi = async () => {
    const data = {
      contact_id: delContactId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.restaurantContactDelete}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setLoading(false)
          setTimeout(() => {
            particularRestaurantData(isRestaurantId)
            toast.success(res?.data?.message)
          }, 2500)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const getStoreLink = (website) => {
    if (!website) return undefined
    return website.startsWith('http') ? website : `http://${website}`
  }

  const isStep4Valid = () => {
    return (
      isStep4Modified &&
      (contactImage ||
        typeof inputFields.contact_profile_pic === 'string' ||
        inputFields.contact_profile_pic instanceof File) &&
      inputFields.contact_name &&
      inputFields.contact_designation
      // inputFields.contact_phone.length > 10
    )
  }

  useEffect(() => {
    if (personDetails || isRestaurant || ShowConfirmModel || isPlatFormModal) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [personDetails, isRestaurant, ShowConfirmModel, isPlatFormModal])

  useEffect(() => {
    setIsStep4Modified(
      inputFields.contact_profile_pic !==
        initialInputFields.contact_profile_pic ||
        inputFields.contact_name !== initialInputFields.contact_name ||
        inputFields.contact_designation !==
          initialInputFields.contact_designation
      // inputFields.contact_phone !== initialInputFields.contact_phone
    )
  }, [inputFields])

  useEffect(() => {
    particularRestaurantData(RestaurantId)
  }, [])

  useEffect(() => {
    const event = emitter.addListener(
      EventType.particularRestaurant,
      ({ action }) => {
        if (action === 'edit') {
          particularRestaurantData(RestaurantId)
        }
      }
    )
    return () => event.remove()
  }, [particularRestaurantDeatils])

  return (
    <div className="content-manager">
      <div className="bg-white rounded-[15px] !p-5">
        <div className="flex items-center !gap-3 link_click">
          <Link to="/local" className="text-[#4E4949] text-base font-medium">
            Home
          </Link>
          <img src={Images.breadcrumbAerrow} alt="" />
          <Link to="/local" className="text-[#4E4949] text-base font-medium">
            Restaurant
          </Link>
          <img src={Images.breadcrumbAerrow} alt="" />
          {particularRestaurantDeatils.length > 0 && (
            <Link
              to=""
              className="bg-primaryGradient bg-clip-text text-transparent text-base font-bold"
            >
              {particularRestaurantDeatils[0].name}
            </Link>
          )}
        </div>
        <div className="mt-[15px]">
          {isParticularLoading ? (
            <div className="particular_skeleton">
              <div className="w-50">
                <div className="details_section_profile_header">
                  <div className="name_logo">
                    <Skeleton circle height={50} width={50} />
                    <div>
                      <Skeleton count={1} height={30} width={200} />
                      <Skeleton count={1} height={20} width={300} />
                    </div>
                  </div>
                </div>
                <div className="details_section_button_row d-flex gap-2">
                  <Skeleton count={1} height={40} width={80} />
                  <Skeleton count={1} height={40} width={80} />
                </div>

                <div className="details_section p-3">
                  <Skeleton count={4} height={20} width="100%" />
                  <Skeleton count={1} height={20} width={150} />
                </div>

                <div className="contact-details">
                  <h4>
                    <Skeleton width={100} />
                  </h4>
                  <div className="mt-3 mb-3">
                    <Skeleton count={3} height={50} width="100%" />
                  </div>
                </div>
              </div>
              <div className="w-50">
                <div className="d-flex gap-3 w-100 mt-5">
                  <div className="image-placeholder w-50">
                    <Skeleton height={150} width="100%" />
                  </div>
                  <div className="image-placeholder w-50">
                    <Skeleton height={150} width="100%" />
                  </div>
                </div>
                <div className="d-flex gap-3 w-100">
                  <div className="image-placeholder w-50">
                    <Skeleton height={150} width="100%" />
                  </div>
                  <div className="image-placeholder w-50">
                    <Skeleton height={150} width="100%" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            particularRestaurantDeatils.map((data, index) => (
              <div key={index}>
                <div className="details_section_profile_header">
                  <div className="name_logo items-center gap-2.5">
                    {data.logo ? (
                      <img src={s3ImageRestaurant + data.logo} alt="profile" />
                    ) : null}
                    <div>
                      <div className="d-flex gap-[8px] align-items-center">
                        <h1 className="mb-0 pe-2 text-2xl leading-none italic text-Main font-black">
                          {data.name}
                        </h1>
                        {data?.app_data?.length > 0 && (
                          <div className="profile-tag tag-local position-relative">
                            <span>{English.G274}</span>
                            <div className="published-tooltip-main">
                              <div className="published-tooltip">
                                <div className="tooltip-ico" />
                                {data.app_data.map((d, i) => (
                                  <div className="published-item" key={i}>
                                    <img
                                      src={urlSystemIcon + d.svg_icon}
                                      alt="the-test-club"
                                      className="publish_icon"
                                    />
                                    <div
                                      className="unpublished-btn"
                                      type="button"
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        setIsPublish(false)
                                        const clone = cloneDeep(
                                          particularRestaurantDeatils
                                        )
                                        clone[index].app_data = clone[
                                          index
                                        ].app_data.filter(
                                          (item) => item.id !== d.id
                                        )

                                        setAppData((state) => {
                                          const clone = cloneDeep(state)
                                          clone[i].checked = false
                                          return clone
                                        })
                                        setParticularRestaurantDeatils(clone)
                                        setTimeout(() => {
                                          RestaurantPublishApi(d.id)
                                        }, 1000)
                                      }}
                                    >
                                      {English.G275}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <p className="mb-0 text-base font-semibold text-Main">
                        {data.address}
                      </p>
                    </div>
                  </div>
                  <div className="details_section_button_row d-flex gap-2">
                    <button
                      type="button"
                      className="local_type_cancel"
                      onClick={() => onClickEdit(data)}
                    >
                      <span>{English.G276}</span>
                    </button>
                    <button
                      type="button"
                      className="local_type_next position-relative"
                      onClick={onClickPublish}
                    >
                      <span className="d-flex align-items-center gap-3">
                        {English.G277}
                      </span>
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-2 pt-2.5 !gap-5">
                  <div>
                    <div
                      className={`description_link ${expandedIndex === index ? 'expanded' : ''}`}
                    >
                      <p className="text-[#303030] text-base mb-2.5">
                        {expandedIndex === index
                          ? data?.description
                          : `${data?.description?.slice(0, 180)}`}
                        {data?.description?.length > 180 && (
                          <span
                            className="see_more_local"
                            onClick={(e) => {
                              e.stopPropagation()
                              setExpandedIndex(
                                expandedIndex === index ? null : index
                              )
                            }}
                          >
                            ...
                            {' '}
                            {expandedIndex === index
                              ? English.G193
                              : English.G194}
                          </span>
                        )}
                      </p>
                      <div className="profile-redirect local_link">
                        <a
                          className="gredient-txt"
                          target="_blank"
                          href={getStoreLink(data.website)}
                          rel="noreferrer"
                        >
                          <img alt="" src={Images.Redirect} />
                          <u className="border-b border-[#001597]">
                            {data.website ? data.website : English.G278}
                          </u>
                        </a>
                      </div>
                    </div>
                    <div className="contact-details" style={{ width: '560px' }}>
                      <h4>{English.G279}</h4>
                      <div className="mt-3 mb-3">
                        <div className="d-flex row">
                          {data?.contact_details?.map((item, index) => {
                            return (
                              <div
                                className="col position-relative"
                                key={index}
                              >
                                <div className="podcast-profile-img">
                                  <img
                                    src={
                                      s3ImageRestaurant +
                                      item.contact_profile_pic
                                    }
                                    alt=""
                                  />
                                  <div className="podcast-profile-detail">
                                    <span>
                                      {item?.contact_name?.slice(0, 17)}
                                      {item?.contact_name?.length > 17 && '...'}
                                    </span>
                                    <p className="contact_designation">
                                      {item?.contact_designation?.slice(0, 17)}
                                      {item?.contact_designation?.length > 17 &&
                                        '...'}
                                    </p>
                                  </div>
                                </div>
                                <div className="local_contact_buttons z-1">
                                  <label>
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        onClickContactEdit(item)
                                      }}
                                      className="d-none"
                                    />
                                  </label>
                                  <button
                                    type="button"
                                    className="p-0"
                                    onClick={() => {
                                      setContactIndex(index)
                                      ShowContactConfirm(item.contact_id)
                                    }}
                                  />
                                </div>
                                <ConfirmationModal
                                  isVisible={
                                    ShowConfirmModel && contactIndex === index
                                  }
                                  onConfirm={hotelContactDeleteApi}
                                  onCancel={handleDeleteCancel}
                                  isLoading={loading}
                                />
                                {loading && contactIndex === index && (
                                  <Loader />
                                )}
                              </div>
                            )
                          })}
                          {(!data?.contact_details ||
                            data.contact_details.length <= 1) && (
                            <div className="col position-relative">
                              <div
                                className="podcast-profile-img"
                                onClick={() => onClickContactEdit({})}
                              >
                                <button
                                  type="button"
                                  className="add_contact_person"
                                  onClick={() => onClickContactEdit({})}
                                >
                                  <img
                                    className="add_image_local"
                                    src={Images.Plus}
                                    alt="add library"
                                  />
                                </button>
                                <div className="podcast-profile-detail new_contact_add">
                                  <span className="new_contact_add">
                                    {English.G280}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div />
                      </div>
                      <div>
                        <div className="hotel-details row">
                          <div className="col">
                            <h5>{English.G295}</h5>
                            <div className="podcast-profile-detail">
                              <p className="designation">
                                {data?.opening_time?.slice(0, 5)}
                                {' '}
                                -
                                {' '}
                                {data?.closing_time?.slice(0, 5)}
                              </p>
                            </div>
                          </div>
                          <div className="col">
                            <h5>{English.G282}</h5>
                            <div className="podcast-profile-detail">
                              <p className="designation">
                                {English.G283}
                                {' '}
                                {data.min_price}
                                {' '}
                                -
                                {' '}
                                {English.G283}
                                {' '}
                                {data.max_price}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="hotel-details row">
                          <div className="col">
                            <h5>{English.G296}</h5>
                            <div className="podcast-profile-detail">
                              <p className="designation">{data.meal_types}</p>
                            </div>
                          </div>
                          <div className="col">
                            <h5>{English.G297}</h5>
                            <div className="podcast-profile-detail">
                              <p className="designation">{data.cuisines}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="grid grid-cols-2 gap-2.5">
                      {data?.images && data?.images?.length > 0 ? (
                        data?.images.slice(0, 4).map((item, index) => (
                          <div className="aspect-[5/3] w-full" key={index}>
                            <img
                              key={index}
                              src={s3ImageRestaurant + item}
                              alt="hotel img"
                              className="rounded-[10px] h-full w-full object-cover"
                            />
                          </div>
                        ))
                      ) : (
                        <div className="flex items-center justify-center w-full col-span-2 min-h-[300px]">
                          <p className="no_images_text">{English.G285}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {isPlatFormModal && (
                  <div className="add-item-model">
                    <div className="add-item-content local_type_modal">
                      <div className="type_close mb-2  local_sticky_header">
                        <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                          {English.G277}
                        </div>
                        <button
                          className="cancel_icon"
                          type="button"
                          onClick={handleCancels}
                        >
                          <img src={Images.RemoveLocal} alt="" />
                        </button>
                      </div>
                      <div className="grid grid-cols-4 !gap-5">
                        {appData.map((i, index) => {
                          const isChecked =
                            particularRestaurantDeatils[0]?.app_data?.some(
                              (d) => d.id === i.id
                            )
                          return (
                            <div
                              className="profile-checkbox aspect-[3/2]"
                              key={index}
                            >
                              <img src={urlSystemIcon + i.icon} alt="" />
                              <div className="custom-check-box">
                                <CommonInput
                                  type="checkbox"
                                  value={i.id}
                                  checked={isChecked || i.checked}
                                  onChange={(e) => {
                                    const newCheckboxes = [...appData]
                                    newCheckboxes[index].checked =
                                      e.target.checked
                                    setAppData(newCheckboxes)
                                  }}
                                  className="!min-h-max"
                                />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <div className="local_button_row">
                        <CommonButton
                          label={English.G116}
                          onClick={handleCancels}
                          isPrimary={false}
                          context={English.G232}
                        />
                        <CommonButton
                          label={isPublish ? English.G128 : 'UnPublish'}
                          type="button"
                          isPrimary
                          context={English.G232}
                          onClick={() => {
                            const clone = cloneDeep(
                              particularRestaurantDeatils
                            )
                            const filter = appData.filter((d) => d.checked)
                            if (isPublish) {
                              const isAllAlreadyExist = filter.every((i) => _.some(
                                  clone[index].app_data,
                                  (j) => j.id === i.id
                                ))

                              if (isAllAlreadyExist) {
                                return
                              }
                              clone[index].app_data = [
                                ...new Set([
                                  ...clone[index].app_data,
                                  ...filter
                                ])
                              ]
                            } else {
                              clone[index].app_data = clone[
                                index
                              ].app_data.filter(
                                (item) => !filter.some(
                                    (filteredItem) => filteredItem.id === item.id
                                  )
                              )
                            }
                            setParticularRestaurantDeatils(clone)
                            RestaurantPublishApi()
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>

        {isShow && (
          <div className="position-relative local-tabs section-local">
            <Tabs>
              <div className="list_overview_local">
                <TabList>
                  <Tab>
                    <span>{English.G286}</span>
                  </Tab>
                  <Tab>
                    <span>{English.G298}</span>
                  </Tab>
                  <Tab>
                    <span>{English.G288}</span>
                  </Tab>
                </TabList>
              </div>

              <TabPanel>
                {' '}
                <div className="grid grid-cols-4 !gap-4 !mb-5">
                  <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
                    <div className="w-[50px] h-[50px]">
                      <img src={Images.Visitor} alt="visitor" />
                    </div>
                    <div className="">
                      <p className="text-Main text-base italic font-black mb-[5px] uppercase">
                        Locations
                      </p>
                      <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                        25
                        {/* <img src={Images.UpPath} alt="" />
                        <span>0%</span> */}
                      </span>
                    </div>
                  </div>
                  <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
                    <div className="">
                      <img src={Images.impressionLocal} alt="impression" />
                    </div>
                    <div className="">
                      <p className="text-Main text-base italic font-black mb-[5px] uppercase">
                        Footfall
                      </p>
                      <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                        2.5k
                        <img src={Images.UpPath} alt="" />
                        <span className="text-[#00B69B] text-sm font-semibold">
                          1.3%
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
                    <div className="">
                      <img src={Images.ClicksLocal} alt="Clicks" />
                    </div>
                    <div className="">
                      <p className="text-Main text-base italic font-black mb-[5px] uppercase">
                        Clicks
                      </p>
                      <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                        358
                        <img src={Images.UpPath} alt="" />
                        <span className="text-[#00B69B] text-sm font-semibold">
                          1.3%
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
                    <div className="">
                      <img src={Images.RequestsLocal} alt="request" />
                    </div>
                    <div className="">
                      <p className="text-Main text-base italic font-black mb-[5px] uppercase">
                        engagement
                      </p>
                      <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                        86.04%
                        <img src={Images.UpPath} alt="" />
                        <span className="text-[#00B69B] text-sm font-semibold">
                          1.3%
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="content-manager-Statistics">
                  <div className="statistic-chart-container position-relative">
                    <h4>{English.G286}</h4>
                    <div className="drop-and-date !top-2.5">
                      <ReactDatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showYearPicker
                        dateFormat="yyyy"
                      />
                    </div>
                    <OrderChart />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <Menu isRestaurantId={isRestaurantId} />
              </TabPanel>
              <TabPanel>
                <Discount isRestaurantId={isRestaurantId} />
              </TabPanel>
            </Tabs>
          </div>
        )}
      </div>

      {personDetails && (
        <ContactModal
          contactLoading={contactLoading}
          cancelContact={cancelContact}
          onUploadImage={onUploads}
          inputFields={inputFields}
          s3Image={s3ImageRestaurant}
          onChange={onChange}
          nextClickApiCall={handleEditContact}
          disabled={!isStep4Valid()}
        />
      )}

      {isRestaurant && (
        <RestaurantPopup
          onCancel={handleCancel}
          defaultData={isAddingNewRestaurant ? {} : editData}
          apiSuccess={() => {
            // allRestaurantData(null, currentPage)
            clearValues()
            setIsRestaurant(false)
          }}
          // particularRestaurantApiCall={(id) => {
          //   particularRestaurantData(id)
          // }}
          // setIsShowRestroList={setIsShowRestroList}
          setIsShow={setIsShow}
          editRestaurant={isRestaurantEdit}
        />
      )}
    </div>
  )
}
export default RestaurantDetails

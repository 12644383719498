/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import './sidebar.css'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { Logout, removeToken } from '../../redux/UserSlice/UserSlice'
import { APICall, EndPoints } from '../../services'
import { setUserSliceData } from '../../services/http-services'

export const MenuHeader = ({ name, user_type }) => (
  <div className={`font-black text-[12px] ml-[20px] py-2 pr-1 italic ${user_type === 'creator' ? 'text-black' : 'text-placeholder'}`}>
    {name}
  </div>
)

const Sidebar = () => {
  const UserData = useSelector((state) => state.user?.userData)
  const stayLogin = useSelector((state) => state.user.stay_loginData)
  const user_type = useSelector((state) => state.user?.user_type)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState()
  const location = useLocation()
  const isSideMenuDisable = !UserData?.user_type_id

  // isSideMenuDisable ? navigate('/create-profile') : null
  if (isSideMenuDisable) {
    // navigate('/create-profile')
  }

  const isRestrictedPath =
    location.pathname === '/creator-create-profile' ||
    location.pathname === '/create-profile'

  const [isOpen, setIsOpen] = useState(false)
  const [userData, setUserData] = useState([])
  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }
  const LogoutClick = () => {
    dispatch(removeToken())
    toast.success('User logged out successfully')
    if (stayLogin) {
      navigate('/re-login')
    } else {
      dispatch(Logout())
      navigate('/login')
    }
  }

  const getUserProfiles = async () => {
    APICall('get', '', `${EndPoints.userProfile}?type=${user_type}`, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setUserData(res?.decryptedData)
          setUserSliceData(res?.decryptedData, 'storeInfo')
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const isMenuItemActive = (name) => {
    const allUserTypeNames = userData.flatMap(
      (user) => user.user_type_data?.map((d) => d.name) || []
    )
    return allUserTypeNames.includes(name)
  }

  useEffect(() => {
    setTimeout(() => {
      getUserProfiles()
      setIsLoading(false)
    }, 500)
  }, [UserData])

  const HeaderLogo = () => {
    return (
      <div className="flex gap-2 items-center">
        <img src={Images.GbnLogo} className="w-[38px] h-[38px]" alt="Alt" />
        <div className="flex-col flex items-center">
          <div className="bg-primaryGradient inline-block md:text-xs lg:text-[13px] leading-0 font-black pr-1 italic bg-clip-text text-transparent !uppercase">
            {English.A1}
          </div>
          {user_type !== 0 && (
            <div className="bg-primaryGradient inline-block text-xs pr-1 font-normal leading-0 italic bg-clip-text text-transparent">
              {user_type === 'member'
                ? English.B57
                : user_type === 'creator'
                  ? English.A13
                  : English.A14}
            </div>
          )}
        </div>
      </div>
    )
  }

  const MenuItem = ({ to, image, name, enabled, hidden }) => (
    <li className={`${enabled ? 'disableSideMenu' : ''} ${hidden && 'hidden'}`}>
      <NavLink to={to}>
        <div className="home_profile">
          <div className="image-block img-width">
            <img src={image} alt="" />
          </div>
          {name}
        </div>
      </NavLink>
    </li>
  )

  return (
    <div className="custom-sidebar">
      {isLoading ? (
        <>
          <Skeleton className="rounded-5 mb-3" height={54} width={193} />
          <ul className="main-navigation mt-4">
            <Skeleton
              className="rounded-5 mb-3"
              height={54}
              width={193}
              count={9}
            />
          </ul>
        </>
      ) : (
        <>
          <div className="logo !flex-col d-block">
            <HeaderLogo />
          </div>
          <ul className="main-navigation flex-1 flex flex-col">
            {(user_type === 'member' || user_type === 'creator') && (
              <MenuHeader name={English.B92} user_type={user_type} />
            )}
            <MenuItem enabled={isRestrictedPath} to="/dashboard" image={Images.HomeNew} name={English.G222} />
            <MenuItem hidden={user_type !== 'member'} to="/about-me" image={Images.Profile} name={English.A133} />
            <MenuItem hidden={user_type !== 'member'} to="/friends" image={Images.Friends_Icon} name={English.A139} />
            <MenuItem hidden={user_type === 'member'} to="/profiles" image={Images.ProfileNew} name={English.B93} />
            <MenuItem enabled hidden={user_type === 'member'} to="/workspace" image={Images.Workspace} name={English.G233} />

            {(user_type === 'member' || user_type === 'creator') && <div className="saprator" />}
            {(user_type === 'creator' || user_type === 'business') && (<MenuHeader name={English.A174} user_type={user_type} />)}

            <MenuItem hidden={user_type === 'member'} to="/content-manager" image={Images.ContentManagerNew} name={English.B94} />
            <MenuItem hidden={user_type === 'member'} to="/store" image={Images.MyStoreNew} name={English.B95} />
            <MenuItem enabled hidden={user_type === 'member' || user_type === 'business'} to="/" image={Images.inspirations} name={English.B96} />

            <MenuItem hidden={user_type === 'member'} to="/local" image={Images.localNew} name={English.A193} />

            {(user_type === 'creator') && <div className="saprator" />}
            {(user_type === 'creator' || user_type === 'business') && (<MenuHeader name={English.A175} user_type={user_type} />)}

            <MenuItem enabled hidden={user_type === 'member' || user_type === 'business'} to="/cloud-store" image={Images.campaigns} name={English.B97} />
            <MenuItem enabled hidden={user_type === 'member' || user_type === 'creator'} to="/ads" image={Images.adSytem} name={English.B100} />
            <MenuItem enabled hidden={user_type === 'member'} to="/" image={Images.partner_pogram} name={English.B98} />
            <MenuItem enabled hidden={user_type === 'member'} to="/cloud-store" image={Images.CloudStore} name={English.G237} />

            <div className="flex flex-1" />
            <li
              className={`disableSideMenu ${(user_type === 'member' || user_type === 'creator' || user_type === 'business') && 'd-none'}`}
            >
              <NavLink to="/affiliate">
                <div>
                  <div className="image-block img-width">
                    <img src={Images.Affiliate} alt="" />
                  </div>
                  {English.G239}
                </div>
              </NavLink>
            </li>
            <li
              className={`disableSideMenu ${(user_type === 'member' || user_type === 'creator' || user_type === 'business') && 'd-none'}`}
            >
              <NavLink to="/service-center">
                <div>
                  <div className="image-block img-width">
                    <img src={Images.ServiceCenter} alt="" />
                  </div>
                  {English.G240}
                </div>
              </NavLink>
            </li>
            <li
              className={`disableSideMenu ${(user_type === 'member' || user_type === 'creator' || user_type === 'business') && 'd-none'}`}
            >
              <NavLink to="/settings">
                <div>
                  <div className="image-block img-width">
                    <img src={Images.Settings} alt="" />
                  </div>
                  {English.G241}
                </div>
              </NavLink>
            </li>
            <li className="">
              <NavLink
                to="/logout"
                onClick={(e) => {
                  e.preventDefault()
                  LogoutClick()
                }}
              >
                <div>
                  <div className="image-block img-width">
                    <img src={Images.Logout} alt="" />
                  </div>
                  {English.G242}
                </div>
              </NavLink>
            </li>
            <p className="hidden">
              <p className="mb-[4px] text-placeholder font-normal text-[11px]">
                PART OF
              </p>
              <div className="flex gap-[5px] border p-2 items-center rounded-xl">
                <div className="">
                  <img
                    className="!w-[30px] !h-[30px]"
                    src={Images.Withe_bg_logo}
                    alt=""
                  />
                </div>
                <p className="text-gradient font-bold italic text-[12px] m-0">
                  {English.A57}
                </p>
              </div>
            </p>
          </ul>
        </>
      )}
    </div>
  )
}
export default Sidebar

/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
// import { useNavigate } from 'react-router-dom'
import './style.css'
import { useSelector } from 'react-redux'
import {
  s3ImageProfile
} from '../../services/Service'
import {
  setUserSliceData
} from '../../services/http-services'
import { formatViews, lockScroll, unlockScroll } from '../../helpers/Utility'
import { emitter, EventType } from '../../helpers/Emitter'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
// import ProfileSkeleton from './ProfileSkeleton'
import EditProfileModel from './EditProfileModel/EditProfileModel'
import { APICall, EndPoints } from '../../services'

const ProfileCommonHead = ({ type, addOns }) => {
  // const navigate = useNavigate()
  const app_id = useSelector((state) => state.user?.app_id)
  const profileData = useSelector((state) => state.user?.profileData)
  const user_type = useSelector((state) => state.user?.user_type)
  const [profile, setProfile] = useState([])
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [allFollowerListData, setAllFollowerListData] = useState([])
  const [showFollowerList, setShowFollowList] = useState(false)
  const [profileEditData, setProfileEditData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isFollowing, setIsFollowing] = useState(false)
  const [isFollowerListLoading, setIsFollowerListLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [allProfileData, setAllProfileData] = useState()

  const componentRef = useRef()

  useEffect(() => {
    if (type === 'Creator' && allProfileData?.creator_profile) {
      setUserSliceData(allProfileData?.creator_profile, 'creator_Data')
      setUserSliceData(allProfileData?.creator_profile, 'profile_Data')
      setProfile(allProfileData?.creator_profile)
      allFollowerList(allProfileData?.creator_profile?.user_id)
    }
    if (type === 'Local' && allProfileData?.local_profile) {
      setUserSliceData(allProfileData?.local_profile, 'profile_Data')
      setProfile(allProfileData?.local_profile)
      allFollowerList(allProfileData?.local_profile.user_id)
      setTimeout(() => {
        emitter.emit(EventType.localProfile, allProfileData?.local_profile)
      }, 1000)
    }
    if (type === 'Store' && allProfileData?.store_profile) {
      setUserSliceData(allProfileData?.store_profile, 'profile_Data')
      setProfile(allProfileData?.store_profile)
      // allFollowerList(allProfileData?.store_profile.user_id)
    }
    if ((addOns === English.G223 || addOns === English.G222) && allProfileData?.profile) {
      const data = allProfileData?.profile?.filter((item) => item.application_data.id === app_id.id)
      console.log(data)
      setProfile(data[0])
      setUserSliceData(allProfileData?.creator_profile, 'creator_Data')
      setUserSliceData(allProfileData?.creator_profile, 'profile_Data')
    }
  }, [allProfileData, type])

  const profileGet = async () => {
    setIsLoading(true)
    if (!app_id) {
      setIsLoading(false)
      return
    }
    const platFormData = {
      app_id: app_id.id,
      type: user_type
    }
    await APICall(
      'post',
      platFormData,
      EndPoints.allProfileShow,
      'auth'
    )
    .then((res) => {
      if (res.status === 200 && res.data?.code === 200) {
        const data = res?.decryptedData
        setProfile(data)
        setAllProfileData(data)
        if (Array.isArray(data) && data.length > 0) {
          if (type === 'Local') {
            emitter.emit(EventType.localProfile, data[0])
          }
        }
        setIsLoading(false)
      } else {
        setIsLoading(false)
        toast.error(res.data?.message)
      }
    })
    .catch((error) => {
      setIsLoading(false)
      toast.error(error?.data?.message)
    })
  }

  // const getUserProfiles = async () => {
  //   setIsLoading(true)
  //   APICall('get', '', `${EndPoints.userProfile}?type=${user_type}`, 'profile')
  //     .then((res) => {
  //       if (res.status === 200 && res.data?.code === 200) {
  //         // setUserData(res?.decryptedData)
  //         const data = res.decryptedData.filter((item) => item.application_data.id === app_id.id)
  //         setProfile(data[0])
  //       } else {
  //         toast.error(res.data?.message)
  //       }
  //       setIsLoading(false)
  //     })
  //     .catch((error) => {
  //       toast.error(error?.data?.message)
  //       setIsLoading(false)
  //     })
  // }

  useEffect(() => {
    profileGet()
  }, [app_id])

  useEffect(() => {
    const event = emitter.addListener(EventType.editProfileModal, () => {
      setShowProfileModel(true)
      setProfileEditData(profile[0])
    })

    return () => event.remove()
  }, [app_id, profile])

  useEffect(() => {
    const event = emitter.addListener(EventType.localConnect, () => {
      profileGet()
    })
    return () => event.remove()
  }, [app_id, profile])

  const HandleCancel = () => {
    setShowProfileModel(false)
  }

  const allFollowerList = (user_id) => {
    setIsFollowerListLoading(true)
    const commonData = {
      url: type === 'Creator' ? EndPoints.creatorProfileDetails : type === 'Local' ? EndPoints.getLocalProfile : type === 'Store' ? EndPoints.storeProfileDetails : '',
      urlType: type === 'Creator' ? 'creator' : type === 'Local' ? 'local' : type === 'Store' ? 'store' : '',
      user_id: type === 'Creator' ? 'creator_user_id' : type === 'Local' ? 'local_user_id' : type === 'Store' ? 'store_user_id' : ''
    }
    const platform = {
      app_id: app_id.id,
      [commonData.user_id]: user_id
    }
    APICall('post', platform, EndPoints.followerList, commonData.urlType)
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setAllFollowerListData(res?.decryptedData)
          setIsFollowerListLoading(false)
        } else {
          toast.error(res.data?.message)
          setIsFollowerListLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsFollowerListLoading(false)
      })
  }

  const followCreatorData = (user_id) => {
    const platform = {
      app_id: app_id.id,
      creator_user_id: user_id
    }
    APICall('post', platform, EndPoints.followCreator, 'creator')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsFollowing(isFollowing)
          toast.success(res?.data?.message)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const followOpen = () => {
    setShowFollowList(true)
  }

  const handleCancel = () => {
    setShowFollowList(false)
  }

  const getStoreLink = (storeLink) => {
    if (!storeLink) return undefined
    return storeLink.linkContent?.startsWith('http') ? storeLink?.linkContent : `http://${storeLink?.linkContent}`
  }

  const videosPerPage = 6
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const followerList = allFollowerListData.slice(
    indexOfFirstVideo,
    indexOfLastVideo
  )
  const totalVideos = allFollowerListData.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const renderPaginationButtons = () => {
    const numButtonsToShow = 3

    let startPage = currentPage - Math.floor(numButtonsToShow / 2)
    startPage = Math.max(startPage, 1)

    let endPage = startPage + numButtonsToShow - 1
    if (endPage > totalPages) {
      endPage = totalPages
      startPage = Math.max(endPage - numButtonsToShow + 1, 1)
    }

    return [...Array(endPage - startPage + 1)].map((_, index) => {
      const pageNumber = startPage + index
      return (
        <button
          type="button"
          key={pageNumber}
          className={`pagination-buttons ${
            currentPage === pageNumber ? 'active' : ''
          }`}
          onClick={() => paginate(pageNumber)}
        >
          {pageNumber}
        </button>
      )
    })
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
  }

  const displayedFollowers = allFollowerListData.slice(0, 4)
  const displayedNames = allFollowerListData.slice(0, 2)
  const profileInitialized = useRef('')

  useEffect(() => {
    if (profileInitialized.current !== type) {
      profileInitialized.current = type
      setAllFollowerListData([])
        profileGet()
    }

    const update = emitter.addListener(EventType.ProfileUpdate, () => {
      profileGet()
    })

    return () => {
      update.remove()
    }
  }, [])

  useEffect(() => {
    if (ShowProfileModel || showFollowerList) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [ShowProfileModel, showFollowerList])

  return (
    <div>
      {/* {isLoading ? (
        <ProfileSkeleton />
      ) : ( */}

      <div className="profile-head-builder">
        <div className="flex gap-5 justify-center items-center text-center">
          <div>
            <div className="profile-personal-detail !w-auto">
              <div className="profile-build-image profileimage">
                {isLoading
                  ? <Skeleton className="w-full h-full !rounded-full -top-1" />
                  : <img className="w-full h-full object-cover" alt="" src={`${s3ImageProfile}${(profile?.profile_icon || profile?.icon)}`} />}
              </div>
            </div>
          </div>
          <div>
            {isLoading
              ? <Skeleton className="!w-32 !h-6 justify-center" />
              :
              <div className="profile-heading justify-center">
                <img className="w-[16px] h-[16px]" alt="" src={Images.Building} />
                <span>{profile?.user_name || profile?.full_name}</span>
                <img className="h-[16px] w-[16px]" src={Images.Gold_tick} alt="" />
              </div>}

            <div className="nocontent">

              {isLoading
                    ?
                      <div className="about-account-insignt buttons-group !gap-[60px] !w-[100%]">
                        <Skeleton className="!w-[90px] !h-6" />
                        <Skeleton className="!w-[90px] !h-6" />
                        <Skeleton className="!w-[90px] !h-6" />
                      </div>
                    :
                      <ul className="about-account-insignt buttons-group !gap-[60px] !w-[100%]">
                        <li className="flex justify-center items-center gap-1">
                          <h4 className="profile_post_count mb-0">
                            {formatViews(profile?.total_post || profile?.posts || 0)}
                          </h4>
                          <p className="post-follow-like">{English.G104}</p>
                        </li>
                        <li className="flex justify-center items-center gap-1">
                          <h4 className="profile_follower_count mb-0">
                            {formatViews(profile?.total_followers || profile?.followers || 0)}
                          </h4>
                          <p
                            className={`Follower_text post-follow-like ${allFollowerListData.length > 0 ? '!cursor-pointer' : '!cursor-default'}`}
                            onClick={() => {
                              if (allFollowerListData.length > 0) {
                                followOpen(profile.user_id)
                              }
                            }}
                          >
                            {English.G105}
                          </p>
                        </li>
                        <li className="flex justify-center items-center gap-1">
                          <h4 className="mb-0">
                            {formatViews(profile?.total_likes || profile?.following || 0)}
                          </h4>
                          <p className="post-follow-like">{profile?.total_likes ? English.G106 : English.B103}</p>
                        </li>
                      </ul>}
              {showFollowerList && (
                <div className="add-item-model edit-profile">
                  <div className="add-item-content">
                    <div className="d-flex justify-content-between header">
                      <h3>Follower List</h3>
                      <button
                        type="button"
                        className="border-0 bg-none p-0"
                        onClick={handleCancel}
                      >
                        <span>
                          <img
                            className="close-btns"
                            src={Images.CloseIco}
                            alt=""
                          />
                        </span>
                      </button>
                    </div>

                    <div className="scroll-bars feed-scroll">
                      <div className="item-details-input pt-0">
                        <div className="list_data">
                          {isFollowerListLoading ? (
                                        Array(6)
                                          .fill(0)
                                          .map((_, index) => (
                                            <div
                                              className="d-flex justify-content-start list_table"
                                              key={index}
                                            >
                                              <span className="number_index">
                                                <Skeleton
                                                  className="rounded-3"
                                                  width={20}
                                                  height={20}
                                                />
                                              </span>
                                              <Skeleton circle width={45} height={45} />
                                              <span className="user-name">
                                                <Skeleton
                                                  className="rounded-3"
                                                  width={200}
                                                  height={25}
                                                />
                                              </span>
                                            </div>
                                          ))
                                      ) : followerList.length > 0 ? (
                                        followerList.map((item, index) => {
                                          const calculatedIndex =
                                            (currentPage - 1) * videosPerPage + index + 1
                                          return (
                                            <div
                                              className="d-flex justify-content-start list_table"
                                              key={index}
                                            >
                                              <span className="number_index">
                                                {calculatedIndex}
                                              </span>
                                              <img
                                                src={s3ImageProfile + item.user_profile_icon}
                                                className="profile-pic"
                                                alt="profile"
                                              />
                                              <span className="user-name">
                                                {item.user_name}
                                              </span>
                                            </div>
                                          )
                                        })
                                      ) : (
                                        <div className="no-data-message">
                                          No one follows you
                                        </div>
                                      )}
                        </div>
                      </div>
                    </div>
                    {totalPages > 1 && (
                    <div className="pagination-containers-list">
                      <button
                        type="button"
                        className={`pagination-button ${
                                        currentPage === 1 ? 'disabled' : ''
                                      }`}
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                      >
                        {English.G176}
                      </button>
                      {renderPaginationButtons()}
                      <button
                        type="button"
                        className={`pagination-button ${
                                        currentPage === totalPages ? 'disabled' : ''
                                      }`}
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                      >
                        {English.G69}
                      </button>
                    </div>
                                )}
                  </div>
                </div>
                  )}
              {isLoading
                    ? <Skeleton className="!w-72 !h-6 !mt-3" />
                    :
                    <p className="text-sm font-medium text-[#0B0A0A] mt-3 max-w-md justify-self-center line-clamp-4">
                      {/* {data?.description?.slice(0, 65)} */}
                      {/* {data?.description?.length > 65 && '...'} */}
                      {profile?.description}
                    </p>}
              {isLoading
                    ? <Skeleton className="!w-64 !h-6 !mt-3 profile-redirect" />
                    : (profile?.social_media_links?.[0] || profile?.store_link || profile?.website_link) && (
                    <div className="profile-redirect">
                      <a
                        className="gredient-txt justify-center"
                        target="_blank"
                        href={getStoreLink(profile?.social_media_links?.[0] || profile?.store_link || profile?.website_link)}
                        rel="noreferrer"
                      >
                        <img alt="" src={Images.Redirect} />
                        {profile.social_media_links?.[0]?.linkContent || profile?.store_link || profile?.website_link}
                      </a>
                    </div>
                  )}

              <div className="follow-connenct !w-[450px] !gap-2 justify-center">
                {isLoading
                      ?
                        <div className="flex gap-2">
                          <Skeleton className="!w-44 !h-[35px] !rounded-3xl" />
                          <Skeleton className="!w-44 !h-[35px] !rounded-3xl" />
                        </div>
                      :
                        <div className="flex gap-2">
                          <button
                            type="button"
                            className={`primary-btn success-btn !w-[174px] ${
                              (profileData?.user_id === profile.user_id || type === 'Store') ? 'disabled' : ''
                            }`}
                            onClick={() => {
                              if (profileData?.user_id === profile.user_id) {
                                return
                              }
                              followCreatorData(profile.user_id)
                            }}
                          >
                            {English.G107}
                          </button>
                          <button type="button" className="px-10 py-7 !w-[174px] bg-[#FAFAFA]">
                            <span className="text-[#0B0A0A] text-xs font-semibold">
                              Other Socials
                            </span>
                          </button>
                        </div>}
                {isLoading
                      ? <Skeleton className="!w-[35px] !h-[35px] !rounded-3xl" />
                      :
                      <div>
                        <img alt="" className="w-8 h-8" src={Images.GbnLogo} />
                      </div>}
              </div>

              <div className="follwer-shows !mb-0">
                {allFollowerListData.length > 0 && (
                  <div className="follower-img-group flex flex-row">
                    {displayedFollowers.map((follower, index) => (
                      <img
                        key={index}
                        alt={`Follower ${index + 1}`}
                        src={s3ImageProfile + follower.user_profile_icon}
                      />
                    ))}
                  </div>
                    )}
                {allFollowerListData.length > 0 && (
                  <p>
                    <span className="gredient-txt">
                      {displayedNames.map((follower, index) => (
                        <React.Fragment key={index}>
                          <b>{follower.user_name}</b>
                          {index < displayedNames.length - 1 && ', '}
                        </React.Fragment>
                                  ))}
                      {' und '}
                      <b>
                        {allFollowerListData.length - 2}
                        {' '}
                        weitere Personen
                      </b>
                      {' '}
                      sind Follower
                    </span>
                  </p>
                    )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* )} */}
      {ShowProfileModel && (
        <EditProfileModel
          type={type === 'Creator' ? 'creator' : type === 'Local' ? 'local' : type === 'Store' ? 'store' : ''}
          title={English.G110}
          profileEditData={profileEditData}
          onCancel={HandleCancel}
          componentRef={componentRef}
          onSubmite={(data) => {
            const commonData = {
              url: type === 'Creator' ? EndPoints.editCreatorProfile : type === 'Local' ? EndPoints.EditLocalProfile : type === 'Store' ? EndPoints.editStoreProfile : '',
              urlType: type === 'Creator' ? 'creator' : type === 'Local' ? 'local' : type === 'Store' ? 'store' : '',
              user_id: type === 'Creator' ? 'creator_user_id' : type === 'Local' ? 'local_user_id' : type === 'Store' ? 'store_user_id' : ''
            }
            APICall('post', data, commonData.url, commonData.urlType)
              .then((res) => {
                if (res.status === 200 && res.data?.code === 200) {
                  setTimeout(() => {
                    profileGet()
                    toast.success(res?.data?.message)
                    setShowProfileModel(false)
                    setProfileEditData(null)
                  }, 3000)
                } else {
                  setShowProfileModel(false)
                  setProfileEditData(null)
                  toast.error(res?.data?.message)
                }
              })
              .catch((error) => {
                toast.error(error?.data?.message)
                // setIsEdited(false)
              })
          }}
        />
      )}
    </div>
  )
}
export default ProfileCommonHead

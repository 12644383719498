/* eslint-disable no-nested-ternary */
import React from 'react'
import { Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import CommonButton from '../ButtonComponent/ButtonCommon'
import './StoreImageCroper.css'
import English from '../../helpers/English'
import { Images } from '../../helpers'

const CommonImageUpload = ({
  handleImageChange,
  index,
  buttonText,
  forImage,
  forName,
  className,
  accept = '.jpg,.jpeg,.png',
  selectedImg,
  defaultImg,
  imgClass,
  defaultImgCamera,
  name,
  ref,
  id,
  multiple,
  aspect,
  editButtonClass,
  text
}) => {
  return (
    <label htmlFor={`input-img-${index}`} className={`${className}`}>
      {selectedImg ? (
        forImage === 'profile' ? (
          <>
            <img className="Profile_img mt-3" src={selectedImg} alt="Profile" />
            <div className="input-profile-img-label profile_add_icon cursor-pointer">
              <ImgCrop showGrid rotationSlider showReset>
                <Upload
                  id={id}
                  ref={ref}
                  name={name}
                  accept={accept}
                  showUploadList={false}
                  beforeUpload={(file) => {
                    handleImageChange(file, index)
                    return false
                  }}
                >
                  <img src={defaultImgCamera} alt="Upload Icon" />
                </Upload>
              </ImgCrop>
            </div>
          </>
        ) : forImage === 'create-profile' ? (
          <>
            <img className="Profile_img" src={selectedImg} alt="Profile" />
            <ImgCrop showGrid rotationSlider showReset>
              <Upload
                id={id}
                ref={ref}
                name={name}
                accept={accept}
                showUploadList={false}
                beforeUpload={(file) => {
                  handleImageChange(file, index)
                  return false
                }}
              >
                <div className="input-profile-img-label cursor-pointer">
                  <img src={defaultImgCamera} alt="Upload Icon" />
                </div>
              </Upload>
            </ImgCrop>
          </>
        ) : forImage === 'local_logo' ? (
          <>
            <img className="logo_show_img" src={selectedImg} alt="Preview" />
            <ImgCrop showGrid rotationSlider showReset>
              <Upload
                id={id}
                ref={ref}
                name={name}
                accept={accept}
                showUploadList={false}
                beforeUpload={(file) => {
                  handleImageChange(file, index)
                  return false
                }}
              >
                <div className="edit_logo_local z-1">
                  <label htmlFor="upload-file">
                    <button type="button" className="d-none" />
                  </label>
                </div>
              </Upload>
            </ImgCrop>
          </>
        ) : forImage === English.G290 ? (
          <>
            <img className="logo_show_imgs" src={selectedImg} alt="Preview" />
            <ImgCrop showGrid rotationSlider showReset>
              <Upload
                id={id}
                ref={ref}
                name={name}
                accept={accept}
                showUploadList={false}
                beforeUpload={(file) => {
                  handleImageChange(file, index)
                  return false
                }}
              >
                <div className="edit_logo_local z-1">
                  <label htmlFor="upload-file">
                    <button type="button" className="d-none" />
                  </label>
                </div>
              </Upload>
            </ImgCrop>
          </>
        ) : forImage === 'add_photo' ? (
          <div className="text-center image_view">
            <img src={selectedImg} alt="Uploaded" className="mt-0" />
          </div>
        ) : (
          <div className="text-center image_view !w-full !h-full">
            <img
              src={selectedImg}
              alt="Uploaded"
              className="!w-full !h-full overflow-hidden"
            />
            <ImgCrop
              className="hidden"
              showGrid
              rotationSlider
              aspect={aspect}
              showReset
            >
              <Upload
                id={id}
                ref={ref}
                name={name}
                accept={accept}
                rootClassName="!flex !h-0"
                showUploadList={false}
                beforeUpload={(file) => {
                  handleImageChange(file, index)
                  return false
                }}
              >
                <button
                  type="button"
                  className={`edit-button-model content-meneger-edit ${editButtonClass}`}
                />
              </Upload>
            </ImgCrop>
          </div>
        )
      ) : forName ? (
        <div className={forImage === 'image' ? '' : 'container-space'}>
          <div className="upload-img">
            <img
              className="mx-auto"
              src={Images.FileUpload}
              alt="Upload Icon"
            />
          </div>
          {forImage === 'video' ? (
            <h4 className="border-0 text-xl font-black italic text-[#4E4949] uppercase !mt-5">
              {English.G215}
            </h4>
          ) : forImage === 'shot' ? (
            <h4 className="border-0 text-xl font-black italic text-[#4E4949] uppercase !mt-5">
              {English.G214}
            </h4>
          ) : forImage === 'image' || forImage === 'add_photo' ? (
            <h4 className="border-0 mt-0 !pb-2">Upload Image</h4>
          ) : forImage === 'Hotel' ? (
            <h4 className="border-0">{English.G217}</h4>
          ) : (
            <h4 className="text-xl font-black italic text-[#4E4949] !mt-5 uppercase">
              {English.G218}
            </h4>
          )}
          <p
            className={`${forName === 'video_content' ? '!max-w-[330px]' : '!max-w-[350px]'} drag-drop-text text-sm text-[#7A7474] !mb-5`}
          >
            {forImage === 'video'
              ? 'Recommended: “.JPG”  (1280 × 720 pixels) Other major formats are supported with aspect ratio of 16:9'
              : forImage === 'video'
                ? 'Recommended: “.JPG”  (720 × 1280 pixels) Other major formats are supported with aspect ratio of 9:16'
                : 'Recommended: “.JPG” (1400 x 1400 pixels) Other major formats are supported with aspect ratio of 1:1'}
          </p>
          <ImgCrop showGrid rotationSlider aspect={aspect} showReset>
            <Upload
              id={id}
              ref={ref}
              name={name}
              accept={accept}
              showUploadList={false}
              beforeUpload={(file) => {
                handleImageChange(file, index)
                return false
              }}
            >
              {buttonText && (forImage === 'video' || forImage === 'shot') ? (
                <CommonButton
                  label={buttonText}
                  htmlFor={`input-img-${index}`}
                  type="button"
                  className="gap-[15px] !w-auto !rounded-[999px] !font-black !text-[14px] !italic"
                  imgSrc={
                    forName === 'video_content'
                      ? Images.plusrounded
                      : Images.FileUploadWhite
                  }
                  isPrimary
                />
              ) : (
                <CommonButton
                  label={buttonText}
                  htmlFor={`input-img-${index}`}
                  type="button"
                  className="w-auto !rounded-[999px] !font-blackd gap-2"
                  imgSrc={Images.plusrounded}
                  isPrimary
                />
              )}
            </Upload>
          </ImgCrop>
          {/* <button type="button" htmlFor={`input-img-${index}`}>
                <span>{buttonText}</span>
              </button> */}
        </div>
      ) : forImage === 'profile' ? (
        <div className="cropper-user mt-4">
          <img src={defaultImg} alt="uploading" />
          <ImgCrop showGrid rotationSlider showReset>
            <Upload
              id={id}
              ref={ref}
              name={name}
              accept={accept}
              showUploadList={false}
              beforeUpload={(file) => {
                handleImageChange(file, index)
                return false
              }}
            >
              <div className="input-profile-img-label profile_add_icon cursor-pointer">
                <img src={defaultImgCamera} alt="Upload Icon" />
              </div>
            </Upload>
          </ImgCrop>
        </div>
      ) : forImage === 'create-profile' ? (
        <div className="cropper-user">
          <img className="default" src={defaultImg} alt="uploading" />
          <ImgCrop showGrid rotationSlider showReset>
            <Upload
              id={id}
              ref={ref}
              name={name}
              accept={accept}
              showUploadList={false}
              beforeUpload={(file) => {
                handleImageChange(file, index)
                return false
              }}
            >
              <div className="input-profile-img-label cursor-pointer">
                <img src={defaultImgCamera} alt="" />
              </div>
            </Upload>
          </ImgCrop>
        </div>
      ) : forImage === 'local_logo' ? (
        <div className="cropper-user">
          <ImgCrop showGrid rotationSlider showReset>
            <Upload
              id={id}
              ref={ref}
              name={name}
              accept={accept}
              showUploadList={false}
              beforeUpload={(file) => {
                handleImageChange(file, index)
                return false
              }}
            >
              <img className="mx-auto" src={defaultImg} alt="" />
              <h6>{English.G219}</h6>
            </Upload>
          </ImgCrop>
        </div>
      ) : forImage === English.G290 ? (
        <div className="cropper-user">
          <ImgCrop showGrid rotationSlider showReset>
            <Upload
              id={id}
              ref={ref}
              name={name}
              accept={accept}
              showUploadList={false}
              beforeUpload={(file) => {
                handleImageChange(file, index)
                return false
              }}
            >
              <img className="mx-auto" src={defaultImg} alt="" />
              <h6 className="mb-0">{English.G220}</h6>
            </Upload>
          </ImgCrop>
        </div>
      ) : forImage === 'hotel' ? (
        <>
          <img className="mx-auto mb-3" src={Images.FileUpload} alt="" />
          <h6 className="text-xl font-black italic uppercase">Upload Image</h6>
          <h5 className="text-[#7A7474] text-sm max-w-[302px] mx-auto mb-8">
            Recommended: “.JPG” (1400 x 1400 pixels) Other major formats are
            supported with aspect ratio of 1:1
          </h5>
          <ImgCrop showGrid rotationSlider showReset>
            <Upload
              id={id}
              ref={ref}
              name={name}
              accept={accept}
              showUploadList={false}
              beforeUpload={(file) => {
                handleImageChange(file, index)
                return false
              }}
            >
              <button
                type="button"
                className="mx-auto text-white flex items-center gap-2.5 text-sm italic font-black !bg-primaryGradient !rounded-[999px] uppercase !py-[10px]"
              >
                <img src={Images.plusrounded} alt="" />
                SELECT Image
              </button>
            </Upload>
          </ImgCrop>
        </>
      ) : forImage === 'giveaways' ? (
        <>
          <img className="mx-auto mb-3" src={Images.FileUpload} alt="" />
          <h6 className="text-xl font-black italic uppercase">
            Upload At least 04 Image
          </h6>
          <h5 className="text-[#7A7474] text-sm max-w-[302px] mx-auto mb-8">
            Recommended: “.JPG” (1400 x 1400 pixels) Other major formats are
            supported with aspect ratio of 1:1
          </h5>
          {/* <ImgCrop showGrid rotationSlider showReset> */}
          <Upload
            id={id}
            ref={ref}
            name={name}
            accept={accept}
            showUploadList={false}
            multiple={multiple}
            beforeUpload={(file, fileList) => {
              if (multiple) {
                handleImageChange(fileList, index)
              } else {
                handleImageChange([file], index)
              }
              return false
            }}
          >
            <button
              type="button"
              className="mx-auto text-white flex items-center gap-2.5 text-sm italic font-black !bg-primaryGradient !rounded-[999px] uppercase !py-[10px] px-[25px]"
            >
              <img src={Images.plusrounded} alt="" />
              SELECT Image
            </button>
          </Upload>
          {/* </ImgCrop> */}
        </>
      ) : (
        <div className="!z-[9999]">
          <label htmlFor="input-img" className={`${text === 'giveawayBuilder' ? 'giveawayAddHeight' : className}`}>
            {multiple ? (
              <Upload
                id={id}
                ref={ref}
                name={name}
                accept={accept}
                showUploadList={false}
                multiple={multiple}
                beforeUpload={(file, fileList) => {
                  if (multiple) {
                    handleImageChange(fileList, index)
                  } else {
                    handleImageChange([file], index)
                  }
                  return false
                }}
              >
                <img
                  src={Images.ImgPlaceholder}
                  className={imgClass}
                  alt="uploading"
                />
              </Upload>
            ) : (
              <ImgCrop showGrid rotationSlider aspect={aspect} showReset>
                <Upload
                  id={id}
                  ref={ref}
                  name={name}
                  accept={accept}
                  showUploadList={false}
                  beforeUpload={(file) => {
                    handleImageChange(file, index)
                    return false
                  }}
                >
                  <img
                    src={Images.ImgPlaceholder}
                    className={imgClass}
                    alt="uploading"
                  />
                </Upload>
              </ImgCrop>
            )}
            <p className="m-0 text-sm text-center text-[#9e9e9e]">
              {buttonText}
            </p>
          </label>
        </div>
      )}
    </label>
  )
}

export default CommonImageUpload

/* eslint-disable no-nested-ternary */
/* eslint-disable no-lone-blocks */
/* eslint-disable max-len */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { toast } from 'react-toastify'
import { cloneDeep } from 'lodash'
import { LoadScriptNext } from '@react-google-maps/api'
import HotelPopup from './addPopup/hotelPopup'
import CommonButton from '../ButtonComponent/ButtonCommon'
import {
  GOOGLE_MAP_LIBRARIES,
  locationSuggestion,
  s3ImageHotel,
  urlSystemIcon
} from '../../services/Service'
import { compressImage, dataDecrypt } from '../../services/http-services'
import CommonInput from '../InputComponet/CommonInput'
import {
  lockScroll,
  normalizeAddress,
  unlockScroll
} from '../../helpers/Utility'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import { emitter, EventType } from '../../helpers/Emitter'
import ContactModal from './ContactModal'
import ParticularSkeleton from './particularSkeleton'
import MapComponent from '../MapComponent/MapComponent'
import RoomShow from './HotelComponent/RoomShow'
import DiscountShow from './HotelComponent/DiscountShow'
import RatingsReviews from './RatingReview'
import LocalDetailGrid from './CommonComponent/LocalDetailGrid'

const HotelProfileDetails = ({ hotelId }) => {
  const [isPlatFormModal, setIsPlatFormModal] = useState(false)
  const [isHotel, setIsHotel] = useState(false)
  const [isHotelId, setIsHotelId] = useState(hotelId)
  const [inputFields, setInputFields] = useState({
    city_id: '',
    contact_name: '',
    contact_designation: '',
    contact_phone: '',
    contact_profile_pic: '',
    contact_id: '',
    hotel_id: isHotelId
  })
  const [isParticularHotelData, setIsParticularHotelData] = useState([])
  const [editData, setEditData] = useState({})
  const [appData, setAppData] = useState([])
  const [personDetails, setPersonDetails] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isHotelEdit, setIsHotelEdit] = useState(false)
  const [isParticularLoading, setIsParticularLoading] = useState(false)
  const [isShow, setIsShow] = useState(false)

  const [expandedIndex, setExpandedIndex] = useState(null)
  const [isAddingNewHotel, setIsAddingNewHotel] = useState(false)
  const [contactLoading, setContactLoading] = useState(false)
  const [markerPosition, setMarkerPosition] = useState(null)

  const handleCancels = () => {
    const resetAppData = appData.map((item) => ({
      ...item,
      checked: false
    }))
    setAppData(resetAppData)
    setIsPlatFormModal(false)
  }

  const clearValues = () => {
    setIsParticularHotelData([])
  }

  const clearContactValue = () => {
    setInputFields({
      ...inputFields,
      city_id: '',
      contact_name: '',
      contact_designation: '',
      // contact_phone: '',
      contact_profile_pic: '',
      contact_id: ''
    })
    setIsEdit(false)
  }

  const onClickEdit = (data) => {
    setIsHotel(true)
    setIsHotelEdit(true)
    setEditData({
      hotel_id: data?.hotel_id,
      location_title: data?.location_title,
      name: data?.name,
      description: data?.description,
      country_id: data?.country_id,
      province_id: data?.province_id,
      city_id: data?.city_id,
      zip_code: data?.zip_code,
      longitude: data?.longitude,
      latitude: data?.latitude,
      website: data?.website,
      address: normalizeAddress(data?.address),
      amenities: data?.amenities?.map((amenity) => amenity.amenities_id) || [],
      price_range:
        data?.min_price && data?.max_price
          ? `${data?.min_price} - ${data?.max_price}`
          : '',
      min_price: data?.min_price,
      max_price: data?.max_price,
      logo: data?.logo,
      images: data?.images,
      contact_name1: data?.contact_details?.[0]?.contact_name ?? '',
      contact_name2: data?.contact_details?.[1]?.contact_name ?? '',
      contact_designation1:
        data?.contact_details?.[0]?.contact_designation ?? '',
      contact_designation2:
        data?.contact_details?.[1]?.contact_designation ?? '',
      contact_profile_pic: [
        data?.contact_details?.[0]?.contact_profile_pic,
        data?.contact_details?.[1]?.contact_profile_pic
      ]
    })
  }

  const handleCancel = () => {
    setIsHotel(false)
    setIsAddingNewHotel(false)
  }

  const onChange = (e) => {
    setInputFields((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const cancelContact = () => {
    setPersonDetails(false)
    clearContactValue()
  }

  const onUploadImage = async (file, fieldName) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'hotel_contact')

        try {
          const res = await APICall(
            'post',
            coverFormData,
            '',
            'imageUploadLocal',
            true
          )

          if (res.status === 200 && res.data?.code === 200) {
            const decryptedCoverData = dataDecrypt(res.data.data)
            setInputFields((prev) => {
              return {
                ...prev,
                [fieldName]: decryptedCoverData
              }
            })
          } else {
            toast.error(res?.data?.message || 'Error uploading image')
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || 'Upload failed')
        }
      }

      reader.readAsDataURL(file)
    }
  }

  const particularHotelData = (id, type = null) => {
    setIsParticularLoading(true)
    setIsHotelId(id)
    const payload = {
      hotel_id: id
    }
    APICall('post', payload, `${EndPoints.particularHotel}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsParticularHotelData(res?.decryptedData)
          // setParticularHotelDeatils(true)
          setIsShow(true)
          getApplicationsData()
          setIsParticularLoading(false)
          if (type === 'isEdit') {
            onClickEdit(res?.decryptedData[0])
          }
        } else {
          setIsParticularLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsParticularLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const getApplicationsData = async () => {
    APICall('get', '', EndPoints.applications, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedData = res?.decryptedData
          setAppData(decryptedData)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const HotelPublishApi = async (app_id) => {
    const publishState = !app_id
    const data = {
      hotel_id: isHotelId,
      app_id: app_id
        ? [app_id]
        : appData.filter(({ checked }) => checked).map((e) => e.id),
      publish: publishState
    }
    APICall('post', data, `${EndPoints.publishHotel}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          getApplicationsData()
          setIsPlatFormModal(false)
          toast.success(res?.data?.message)
        } else {
          toast.error(res?.data?.message)
          setIsPlatFormModal(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const hotelContactEditApi = async () => {
    setContactLoading(true)
    const data = {
      hotel_id: isHotelId,
      contact_name: inputFields.contact_name,
      contact_designation: inputFields.contact_designation,
      // contact_phone: inputFields.contact_phone,
      contact_profile_pic: inputFields.contact_profile_pic,
      contact_id: inputFields.contact_id
    }
    if (isEdit) {
      APICall('post', data, `${EndPoints.hotelContactEdit}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setTimeout(() => {
              setPersonDetails(false)
              setIsEdit(false)
              particularHotelData(inputFields.hotel_id)
              toast.success(res?.data?.message)
              setContactLoading(false)
              clearContactValue()
            }, 3000)
          } else {
            setIsEdit(false)
            setContactLoading(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setContactLoading(false)
          setIsEdit(false)
          toast.error(error?.data?.message)
        })
    } else {
      APICall('post', data, `${EndPoints.hotelContactAdd}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setTimeout(() => {
              setPersonDetails(false)
              particularHotelData(isHotelId)
              toast.success(res?.data?.message)
              setContactLoading(false)
              clearContactValue()
            }, 3000)
          } else {
            setContactLoading(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setContactLoading(false)
          toast.error(error?.data?.message)
        })
    }
  }

  const getStoreLink = (website) => {
    if (!website) return undefined
    return website.startsWith('http') ? website : `http://${website}`
  }

  const isStep4Valid = () => {
    return (
      inputFields.contact_profile_pic.length > 0 &&
      inputFields.contact_name &&
      inputFields.contact_designation
      // inputFields.contact_phone.length > 10
    )
  }

  useEffect(() => {
    if (personDetails || isHotel || isPlatFormModal) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [personDetails, isHotel, isPlatFormModal])

  useEffect(() => {
    particularHotelData(hotelId)
  }, [])

  useEffect(() => {
    const event = emitter.addListener(
      EventType.particularHotel,
      ({ action }) => {
        if (action === 'edit') {
          particularHotelData(hotelId)
        }
      }
    )
    return () => event.remove()
  }, [isParticularHotelData])

  useEffect(() => {
    if (inputFields.latitude && inputFields.longitude) {
      setMarkerPosition({
        lat: Number(inputFields.latitude),
        lng: Number(inputFields.longitude)
      })
    }
  }, [inputFields.latitude, inputFields.longitude])

  return (
    <div className="">
      <div className="bg-white rounded-[15px] !p-0">
        <div className="!mt-0">
          {isParticularLoading ? (
            <ParticularSkeleton />
          ) : (
            isParticularHotelData.map((data, index) => (
              <div key={index}>
                <LocalDetailGrid data={data} baseImageUrl={s3ImageHotel} />
                <div className="grid grid-cols-2 !gap-5">
                  <div>
                    <div className="font-black text-lg italic text-Colormain uppercase">
                      About
                    </div>
                    <div
                      className={`description_link ${expandedIndex === index ? 'expanded' : ''}`}
                    >
                      <p className="text-Colormain text-base mb-2.5">
                        {expandedIndex === index
                          ? data?.description
                          : `${data?.description?.slice(0, 180)}`}
                        {data?.description?.length > 180 && (
                          <span
                            className="see_more_local"
                            onClick={(e) => {
                              e.stopPropagation()
                              setExpandedIndex(
                                expandedIndex === index ? null : index
                              )
                            }}
                          >
                            ...
                            {' '}
                            {expandedIndex === index
                              ? English.G193
                              : English.G194}
                          </span>
                        )}
                      </p>
                      <div className="profile-redirect">
                        <a
                          className="gredient-txt"
                          target="_blank"
                          href={getStoreLink(data.website)}
                          rel="noreferrer"
                        >
                          <img alt="" src={Images.Redirect} />
                          <u className="border-b border-[#001597]">
                            {data.website ? data.website : English.G278}
                          </u>
                        </a>
                      </div>
                    </div>
                    <div
                      className="contact-details mt-4"
                      style={{ width: '560px' }}
                    >
                      <div>
                        <div className="hotel-details row">
                          <div className="col">
                            <h5>{English.G281}</h5>
                            <div className="podcast-profile-detail">
                              <p className="designation">12:00 PM - 10:30 PM</p>
                            </div>
                          </div>
                          <div className="col">
                            <h5>{English.G282}</h5>
                            <div className="podcast-profile-detail">
                              <p className="designation">
                                {English.G283}
                                {' '}
                                {data.min_price}
                                {' '}
                                -
                                {' '}
                                {English.G283}
                                {' '}
                                {data.max_price}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="hotel-details row">
                          <div className="col">
                            <h5>{English.G284}</h5>
                            <div className="podcast-profile-detail">
                              <p className="designation">{data.room_types}</p>
                            </div>
                          </div>
                          <div className="col">
                            <h5>{English.A187}</h5>
                            <div className="podcast-profile-detail">
                              <p className="designation">{data.bed_options}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group w-full aspect-[1.99/1] sticky top-0 rounded-[15px] border-1 border-SecondaryBorder overflow-hidden">
                      <LoadScriptNext
                        googleMapsApiKey={locationSuggestion}
                        libraries={GOOGLE_MAP_LIBRARIES}
                      >
                        {/* {isParticularHotelData.map((data, index) => ( */}
                        <MapComponent
                          key={index}
                          inputFields={inputFields}
                          setInputFields={setInputFields}
                          // markerPosition={{
                          //   lat: data.latitude,
                          //   lng: data.longitude
                          // }}
                          markerPosition={markerPosition}
                        />
                        {/* ))} */}
                      </LoadScriptNext>
                    </div>
                  </div>
                </div>
                {isPlatFormModal && (
                  <div className="add-item-model">
                    <div className="add-item-content local_type_modal">
                      <div className="type_close mb-2  local_sticky_header">
                        <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                          {English.G277}
                        </div>
                        <button
                          className="cancel_icon"
                          type="button"
                          onClick={handleCancels}
                        >
                          <img src={Images.RemoveLocal} alt="" />
                        </button>
                      </div>
                      <div className="grid grid-cols-4 !gap-5">
                        {appData.map((i, index) => {
                          const isChecked =
                            isParticularHotelData[0]?.app_data?.some(
                              (d) => d.id === i.id
                            )
                          return (
                            <div
                              className="profile-checkbox aspect-[3/2]"
                              key={index}
                            >
                              <img src={urlSystemIcon + i.icon} alt="" />
                              <div className="custom-check-box">
                                <CommonInput
                                  type="checkbox"
                                  value={i.id}
                                  checked={isChecked || i.checked}
                                  onChange={(e) => {
                                    const newCheckboxes = [...appData]
                                    newCheckboxes[index].checked =
                                      e.target.checked
                                    setAppData(newCheckboxes)
                                  }}
                                  className="!min-h-max"
                                />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <div className="local_button_row">
                        <CommonButton
                          label={English.G116}
                          onClick={handleCancels}
                          isPrimary={false}
                          context={English.G232}
                        />
                        <CommonButton
                          label={English.G128}
                          type="button"
                          isPrimary
                          // disabled={appData.every((i) => !i.checked)}
                          onClick={() => {
                            const clone = cloneDeep(isParticularHotelData)
                            setIsParticularHotelData(clone)
                            HotelPublishApi()
                          }}
                          context={English.G232}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>

        {isShow && (
          <div className="local-tabs section-local">
            <Tabs>
              <div className="list_overview">
                <TabList>
                  <Tab>
                    <span>{English.G287}</span>
                  </Tab>
                  <Tab>
                    <span>{English.G288}</span>
                  </Tab>
                  <Tab>
                    <span>Ratings</span>
                  </Tab>
                </TabList>
              </div>

              <TabPanel>
                <RoomShow isHotelId={isHotelId} />
              </TabPanel>
              <TabPanel>
                <DiscountShow isHotelId={isHotelId} />
              </TabPanel>
              <TabPanel>
                <RatingsReviews />
              </TabPanel>
            </Tabs>
          </div>
        )}
      </div>

      {personDetails && (
        <ContactModal
          contactLoading={contactLoading}
          cancelContact={cancelContact}
          onUploadImage={onUploadImage}
          inputFields={inputFields}
          s3Image={s3ImageHotel}
          onChange={onChange}
          nextClickApiCall={hotelContactEditApi}
          disabled={!isStep4Valid()}
        />
      )}

      {isHotel && (
        <HotelPopup
          onCancel={handleCancel}
          defaultData={isAddingNewHotel ? {} : editData}
          apiSuccess={() => {
            // allHotelData(null, currentPage)
            clearValues()
            setIsHotel(false)
          }}
          // particularHotelApiCall={(id) => {
          //   particularHotelData(id)
          // }}
          // setIsShowHotelList={setIsShowHotelList}
          setIsShow={setIsShow}
          editHotel={isHotelEdit}
        />
      )}
    </div>
  )
}
export default HotelProfileDetails

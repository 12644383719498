import React from 'react'

const RatingSummary = () => {
  const ratings = [
    { stars: 5, percentage: 88 },
    { stars: 4, percentage: 7 },
    { stars: 3, percentage: 5 },
    { stars: 2, percentage: 2 },
    { stars: 1, percentage: 1 }
  ]

  return (
    <div className="bg-white p-4 flex gap-3 border-1 border-SecondaryBorder rounded-[14px] w-full">
      <div>
        <div className="flex items-center mb-2">
          <span className="text-[28px] italic font-black text-[#303030] leading-none">4.5</span>
        </div>
        <div className="flex items-center gap-[6px] mb-2">
          {[...Array(5)].map((_, index) => (
            <span key={index} className="bg-primaryGradient bg-clip-text text-transparent font-black text-xl leading-none">★</span>
        ))}
        </div>
        <p className="text-gray-600 text-sm">1145 Ratings</p>
      </div>
      <div className="flex-col flex-1">
        {ratings.map((rating) => (
          <div key={rating.stars} className="items-center mb-2 flex gap-2">
            <span className="text-sm ">{rating.stars}</span>
            <div className="relative w-full h-2 bg-SecondaryBorder rounded-full mx-2">
              <div
                className="absolute top-0 left-0 h-2 bg-[#1a1f87] rounded-full"
                style={{ width: `${rating.percentage}%` }}
              />
            </div>
            <span className="text-sm">
              {rating.percentage}
              %
            </span>
          </div>
      ))}
      </div>
    </div>
  )
}

export default RatingSummary

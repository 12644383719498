import React from 'react'
import Marquee from 'react-fast-marquee'
import './MarqueeComponent.css'

const MarqueeComponent = ({
  children,
  direction = 'left',
  speed = 40,
  loop = 0
}) => {
  return (
    <div className="w-full">
      <Marquee direction={direction} loop={loop} speed={speed}>
        {children}
      </Marquee>
    </div>
  )
}

export default MarqueeComponent

/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react'
import Loader from '../Loader/Loader'
import { English, Images } from '../../helpers'
import CommonInput from '../InputComponet/CommonInput'
import {
  capitalizeFirstLetter,
  preventSpaceInput,
  preventSpacePaste
} from '../../helpers/Utility'
import CommonDatePicker from '../CommonDatePicker/CommonDatePicker'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import CommonButton from '../ButtonComponent/ButtonCommon'

const CommonDiscount = ({
  discountAddLoading,
  isEdit,
  handleCancels,
  handleChange,
  inputFields,
  setInputFields,
  handleFileChange,
  selectedFilePath,
  disabled,
  discountAddApiCall,
  text
}) => {
  return (
    <div className="add-item-model">
      {discountAddLoading && <Loader />}
      <div className="add-item-content local_type_modal !max-w-[1200px] !m-5">
        <div className="type_close mb-2 local_sticky_header">
          <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
            {isEdit ? 'EDIT DISCOUNT' : 'CREATE DISCOUNT'}
          </div>
          <button className="cancel_icon" type="button" onClick={handleCancels}>
            <img src={Images.RemoveLocal} alt="" />
          </button>
        </div>
        <div className="item-details-input pt-0 local_contact_scroll_bars !pl-0">
          <div className="grid grid-cols-2 !gap-5">
            <div>
              <div className="">
                <div>
                  <label>{English.G353}</label>
                  <CommonInput
                    placeholder={English.G308}
                    name="discount_name"
                    onChange={handleChange}
                    // allowTextOnly
                    value={capitalizeFirstLetter(inputFields.discount_name)}
                  />
                </div>
                <div>
                  <label>{English.G354}</label>
                  <CommonInput
                    placeholder={English.G355}
                    name="coupan_code"
                    onChange={handleChange}
                    value={inputFields.coupan_code}
                    onKeyDown={preventSpaceInput}
                    onPaste={preventSpacePaste}
                  />
                </div>
              </div>
              <div className="discount-date">
                <label>{English.G204}</label>
                <div className="calendar event_calender">
                  <CommonDatePicker
                    inputFields={inputFields}
                    setInputFields={setInputFields}
                    fieldName="expiry_date"
                    placeholder={English.G57}
                    minDate={new Date()}
                    maxDate={new Date(new Date().getFullYear() + 10, 11, 31)}
                    popperPlacement="bottom"
                  />
                </div>
              </div>
              {text === 'Restaurant' ? (
                <div>
                  <label>{English.G375}</label>
                  <CommonInput
                    placeholder={English.G368}
                    name="min_amount"
                    onChange={handleChange}
                    value={inputFields.min_amount}
                    pattern="/^-?\d+\.?\d*$/"
                    maxLength={3}
                    onKeyPress={(e) => {
                      if (e.target.value.length === 3) {
                        e.preventDefault()
                      }
                    }}
                    allowNumberOnly
                  />
                </div>
              ) : null}
              <div>
                <label>{English.G356}</label>
                <CommonInput
                  placeholder={English.G357}
                  name="discount"
                  type="number"
                  onChange={handleChange}
                  pattern="/^-?\d+\.?\d*$/"
                  maxLength={2}
                  onKeyPress={(e) => {
                    if (e.target.value.length === 2) {
                      e.preventDefault()
                    }
                  }}
                  className="mb-0"
                  value={inputFields.discount}
                  allowNumberOnly
                />
              </div>
            </div>
            <div className="hotel_image_upload_section">
              <div className="hotel_upload_div upload-input-with-border mb-0 mt-0 upload_height_local discount-uploader flex items-center justify-center h-[calc(80vh-220px)] overflow-y-auto sticky top-0">
                <label htmlFor="upload-file">
                  <CommonImageUpload
                    handleImageChange={handleFileChange}
                    index={0}
                    buttonText="SELECT Image"
                    className="discount_image_size m-0"
                    selectedImg={selectedFilePath}
                    defaultImg=""
                    forImage="image"
                    forName="video"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="local_button_row">
          <CommonButton
            label={English.G116}
            onClick={handleCancels}
            isPrimary={false}
            context={English.A197}
          />
          <CommonButton
            label={English.G117}
            type="button"
            isPrimary
            disabled={disabled}
            onClick={discountAddApiCall}
            context={English.A197}
          />
        </div>
      </div>
    </div>
  )
}

export default CommonDiscount

import React, { useState } from 'react'
import './style.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Link } from 'react-router-dom'
import Dropdown from '../Dropdown/Dropdown'
import MyMap from './MyMap'
import English from '../../helpers/English'
import Images from '../../helpers/Images'

const EventDetail = () => {
  const [count, setCount] = useState(1)
  function incrementCount() {
    setCount(count + 1)
  }
  function decrementCount() {
    if (count > 1) {
      setCount(count - 1)
    }
  }
  const generateStars = (totalRating) => {
    const totalStars = 5 // Total number of stars to display
    const count = Math.min(Math.floor(parseFloat(totalRating)), totalStars)
    const remainder = parseFloat(totalRating) - count // Decimal part to represent partial star
    const stars = []
    for (let i = 0; i < count; i++) {
      stars.push(<img alt="" key={i} src="./images/star.svg" />)
    }
    if (remainder > 0) {
      stars.push(<img alt="" key={count} src="./images/star.svg" />)
    }
    for (let i = stars.length; i < totalStars; i++) {
      stars.push(<img alt="" key={i} src="./images/star-blank.svg" />)
    }

    return stars
  }
  const items = [
    {
      date: '05',
      month: 'May',
      host: ['./images/event/host1.png', './images/event/host2.png'],
      type: 'Art Gallery',
      name: 'Oktoberfest, Mu...',
      bookedTicket: '1,456',
      text: 'Lorem ipsum dolor sit amet, consectetur ',
      actualPrice: '€25.00',
      offerPrice: '€25.00',
      cover: './images/event/7.jpg'
    },
    {
      date: '05',
      month: 'May',
      host: ['./images/event/host1.png', './images/event/host2.png'],
      type: 'Art Gallery',
      name: 'DFB Pokal, Berlin',
      bookedTicket: '1,456',
      text: 'Lorem ipsum dolor sit amet, consectetur ',
      actualPrice: '€25.00',
      offerPrice: '€25.00',
      cover: './images/event/8.jpg'
    },
    {
      date: '05',
      month: 'May',
      host: ['./images/event/host1.png', './images/event/host2.png'],
      type: 'Art Gallery',
      name: 'Karneval, Cologne',
      bookedTicket: '1,456',
      text: 'Lorem ipsum dolor sit amet, consectetur ',
      actualPrice: '€25.00',
      offerPrice: '€25.00',
      cover: './images/event/9.jpg'
    },
    {
      date: '05',
      month: 'May',
      host: ['./images/event/host1.png', './images/event/host2.png'],
      type: 'Art Gallery',
      name: 'Karneval, Cologne',
      bookedTicket: '1,456',
      text: 'Lorem ipsum dolor sit amet, consectetur ',
      actualPrice: '€25.00',
      offerPrice: '€25.00',
      cover: './images/event/10.jpg'
    }
  ]
  const guest = [
    {
      name: 'Marathon Training',
      image: './images/guest/1.jpg',
      type: 'Speaker'
    },
    {
      name: 'Marathon Training',
      image: './images/guest/2.jpg',
      type: 'Speaker'
    },
    {
      name: 'Marathon Training',
      image: './images/guest/3.jpg',
      type: 'Speaker'
    },
    {
      name: 'Marathon Training',
      image: './images/guest/4.jpg',
      type: 'Speaker'
    },
    {
      name: 'Marathon Training',
      image: './images/guest/5.jpg',
      type: 'Speaker'
    },
    {
      name: 'Marathon Training',
      image: './images/guest/6.jpg',
      type: 'Speaker'
    },
    {
      name: 'Marathon Training',
      image: './images/guest/7.jpg',
      type: 'Speaker'
    },
    {
      name: 'Marathon Training',
      image: './images/guest/8.jpg',
      type: 'Speaker'
    }
  ]
  return (
    <div className="creator-profile-main">
      <div className="ticket-overview">
        <div className="row">
          <div className="col-md-6">
            <img alt="" className="ticket-overview--img" src="./images/event/3.jpg" />
            <div className="ticket-cover-row">
              <img alt="" src="./images/event/1.jpg" />
              <img alt="" src="./images/event/2.jpg" />
              <img alt="" src="./images/event/3.jpg" />
              <img alt="" src="./images/event/4.jpg" />
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <div className="event-silde--header">
                <div className="event-slide--date">
                  <h3>05</h3>
                  <span>May</span>
                </div>
                <div className="event-slide--name">
                  <h6>Festival lineup (2022)</h6>
                  <p>Rock am Ring & Rock im Park</p>
                </div>
              </div>
              <div className="vertical-divider" />
              <div className="price--ticket-count d-flex justify-content-between align-items-center">
                <div>
                  <p className="price--ticket-offer mb-2">$700</p>
                  <p className="price--ticket-actual m-0">$800</p>
                </div>
                <div className="price-ticket-counter--wrapper">
                  <span>Tickets Qty.</span>
                  <div className="price--ticket-counter">
                    <button type="button" onClick={decrementCount}>-</button>
                    <div>{count}</div>
                    <button type="button" onClick={incrementCount}>+</button>
                  </div>
                </div>
              </div>
              <div className="vertical-divider" />
              <div className="price--ticket-location d-flex align-items-center col-gap-10">
                <img alt="" src={Images.Location} />
                <p className="m-0">Rathausmarkt 1, 20095 Hamburg, Germany</p>
              </div>
              <div className="vertical-divider" />
              <div className="price--ticket-type py-3 d-flex align-items-center justify-content-between">
                <Dropdown
                  title="Select Tickets"
                  Data={[
                    { title: 'Standard Ticket' },
                    { title: 'Premium Ticket' },
                    { title: 'VIP Ticket' }
                  ]}
                />
                <button type="button" className="primary-btn">Book Now</button>
              </div>
              <div className="vertical-divider" />
              <div className="price--ticket-atendent d-flex align-items-center col-gap-10">
                <div className="d-flex align-items-center">
                  <img alt="" src="./images/event/A1.png" />
                  <img alt="" src="./images/event/A1.png" />
                  <img alt="" src="./images/event/A1.png" />
                  <img alt="" src="./images/event/A1.png" />
                </div>
                <p className="m-0">
                  Your friends
                  {' '}
                  <span>Sara, Emily, Jenny, Alexa</span>
                  {' '}
                  are
                  attending this event, Join them.
                </p>
              </div>
              <div className="vertical-divider" />
            </div>
          </div>
        </div>
        <div className="ticket-overview--profile d-flex align-items-center col-gap-10">
          <img alt="" src="./images/seller-logo.png" />
          Jacob Jones
          <span className="divider mx-3" />
          <div>{generateStars(4.5)}</div>
          4.5 rating
        </div>
        <div className="discription-box">
          <h3>About the event</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra
            luctus vestibulum sed lectus imperdiet proin. Sed mi pellentesque
            nullam sit amet, lorem. Semper vel egestas convallis nisl duis
            mattis ut molestie sed. Mauris feugiat tincidunt nunc, quam mauris,
            massa diam faucibus. Bibendum tristique nec pharetra nulla
            suspendisse.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra
            luctus vestibulum sed lectus imperdiet proin. Sed mi pellentesque
            nullam sit amet, lorem. Semper vel egestas convallis nisl duis
            mattis ut molestie sed. Mauris feugiat tincidunt nunc, quam mauris,
            massa diam faucibus. Bibendum tristique nec pharetra nulla
            suspendisse.
            {' '}
          </p>
        </div>
        <div className="ticket-overview--type-detail">
          <Tabs>
            <TabList>
              <Tab>
                <img alt="" src={Images.StandardTicketIco} />
                <span>Standard Ticket</span>
              </Tab>
              <Tab>
                <img alt="" src={Images.PremiumTicketIco} />
                <span>Premium Ticket</span>
              </Tab>
              <Tab>
                <img alt="" src={Images.VipTicketIco} />
                <span>VIP Ticket</span>
              </Tab>
              <Tab>
                <img alt="" src={Images.GuestListIco} />
                <span>Guestlist</span>
              </Tab>
            </TabList>

            <TabPanel>
              <div className="common-card-body recent-video-component">
                <h3>Standard Ticket</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  viverra luctus vestibulum sed lectus imperdiet proin. Sed mi
                  pellentesque nullam sit amet, lorem. Semper vel egestas
                  convallis nisl duis mattis ut molestie sed. Mauris feugiat
                  tincidunt nunc, quam mauris, massa diam faucibus. Bibendum
                  tristique nec pharetra nulla suspendisse.
                  {' '}
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  viverra luctus vestibulum sed lectus imperdiet proin. Sed mi
                  pellentesque nullam sit amet, lorem. Semper vel egestas
                  convallis nisl duis mattis ut molestie sed. Mauris feugiat
                  tincidunt nunc, quam mauris, massa diam faucibus. Bibendum
                  tristique nec pharetra nulla suspendisse.
                  {' '}
                </p>
                <button type="button" className="primary-btn d-flex align-items-center col-gap-10 ms-auto">
                  <img alt="" src={Images.LightTicketIco} />
                  $ 209.00
                </button>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="common-card-body recent-video-component">
                <h3>Premium Ticket</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  viverra luctus vestibulum sed lectus imperdiet proin. Sed mi
                  pellentesque nullam sit amet, lorem. Semper vel egestas
                  convallis nisl duis mattis ut molestie sed. Mauris feugiat
                  tincidunt nunc, quam mauris, massa diam faucibus. Bibendum
                  tristique nec pharetra nulla suspendisse.
                  {' '}
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  viverra luctus vestibulum sed lectus imperdiet proin. Sed mi
                  pellentesque nullam sit amet, lorem. Semper vel egestas
                  convallis nisl duis mattis ut molestie sed. Mauris feugiat
                  tincidunt nunc, quam mauris, massa diam faucibus. Bibendum
                  tristique nec pharetra nulla suspendisse.
                  {' '}
                </p>
                <button type="button" className="primary-btn d-flex align-items-center col-gap-10 ms-auto">
                  <img alt="" src={Images.LightTicketIco} />
                  $ 209.00
                </button>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="common-card-body recent-video-component">
                <h3>VIP Ticket</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  viverra luctus vestibulum sed lectus imperdiet proin. Sed mi
                  pellentesque nullam sit amet, lorem. Semper vel egestas
                  convallis nisl duis mattis ut molestie sed. Mauris feugiat
                  tincidunt nunc, quam mauris, massa diam faucibus. Bibendum
                  tristique nec pharetra nulla suspendisse.
                  {' '}
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  viverra luctus vestibulum sed lectus imperdiet proin. Sed mi
                  pellentesque nullam sit amet, lorem. Semper vel egestas
                  convallis nisl duis mattis ut molestie sed. Mauris feugiat
                  tincidunt nunc, quam mauris, massa diam faucibus. Bibendum
                  tristique nec pharetra nulla suspendisse.
                  {' '}
                </p>
                <button type="button" className="primary-btn d-flex align-items-center col-gap-10 ms-auto">
                  <img alt="" src={Images.LightTicketIco} />
                  $ 209.00
                </button>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="common-card-body recent-video-component">
                <h3>Guestlist</h3>
                <div className="ticket-overview--guest">
                  {guest.map((item) => (
                    <div
                      className="ticket-overview-guest--overview text-center"
                      key={item.name}
                    >
                      <img alt="" src={item.image} />
                      <h2>{item.name}</h2>
                      <span>{item.type}</span>
                      <div className="vertical-divider mb-3" />
                      <Link>View Profile</Link>
                    </div>
                  ))}
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
        <div className="ticket-overview--location common-card-body recent-video-component mt-30">
          <h3>{English.G256}</h3>
          <MyMap />
        </div>
        <div className="common-card-body recent-video-component mt-30">
          <h3>Similar events nearby</h3>
          <div className="event-offer-grid mt-30">
            {items.map((item) => (
              <div
                className="event-offer-item position-relative"
                key={item.name}
              >
                <div
                  className="event-offer-cover"
                  style={{
                    background: `url(${item.cover})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                  <div className="event-thumb--date">
                    <h3>{item.date}</h3>
                    <span>{item.month}</span>
                  </div>
                  <div className="event-host">
                    {item?.host.map((img, index) => (
                      <img alt="" src={img} key={index} />
                    ))}
                  </div>
                  <div className="event-name-and-text">
                    <h6>{item.type}</h6>
                    <p>{item.name}</p>
                    <h5>
                      Booked Ticket
                      {item.bookedTicket}
                    </h5>
                  </div>
                </div>
                <div className="event-ticket-detail">
                  <div className="about-event">{item.text}</div>
                  <div>
                    <p className="offer-price-event">{item.offerPrice}</p>
                    <p className="actual-price-event">{item.actualPrice}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default EventDetail

import React, { useEffect } from 'react'
import './CreatorProfile.css'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Feeds from '../Feeds/Feeds'
// import HotelComponent from '../AddHotel/HotelComponent'
// import RestaurantComponent from '../AddRestaurant/RestaurantComponent'
// import EventComponent from '../AddEvent/EventComponent'
import LocalHomePage from './LocalHomePage'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
// import ProfileHomePage from './profileHomePage'
// import ProfileHeadLocal from '../ProfileBuilder/ProfileHeadLocal'

const LocalBuilder = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()

  const TabsComponents = () => {
    return (
      <Tabs defaultIndex={state?.defaultIndex ? state?.defaultIndex : 0}>
        <TabList>
          <Tab>
            <img className="component_icon" src={Images.HomeIco} alt="Home icon" />
            <span>{English.G222}</span>
          </Tab>
          <Tab>
            <img className="component_icon" src={Images.FeedIco} alt="Home icon" />
            <span>{English.G223}</span>
          </Tab>
          {/* <Tab>
            <img className="component_icon" src={Images.HomeIco} alt="Home icon" />
            {English.G227}
          </Tab>
          <Tab>
            <img className="component_icon" src={Images.HomeIco} alt="Home icon" />
            {English.G228}
          </Tab>
          <Tab>
            <img className="component_icon" src={Images.HomeIco} alt="Home icon" />
            {English.G229}
          </Tab> */}
        </TabList>

        <TabPanel>
          <LocalHomePage />
        </TabPanel>
        <TabPanel>
          <div>
            <Feeds />
          </div>
        </TabPanel>
        {/* <TabPanel>
          <div>
            <HotelComponent />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <RestaurantComponent />
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <EventComponent />
          </div>
        </TabPanel> */}
      </Tabs>
    )
  }
  useEffect(() => {
    const handleBeforeUnload = () => {
      navigate(`/local-builder/${id}`)
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])
  return (
    <div className="creator-profile-main creator-profile-create">
      {/* <ProfileHeadLocal /> */}
      <div className="mt-4">
        <div className="details-tab-view pt-0">
          <div className="tabs-for-details-view">
            <TabsComponents />
          </div>
        </div>
      </div>
    </div>
  )
}
export default LocalBuilder

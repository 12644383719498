import React, { useState } from 'react'
import { s3ImageVideo } from '../../../services/Service'
import { English, Images } from '../../../helpers'
import { formatDurations, formatViews, timeDifference } from '../../../helpers/Utility'
import Loader from '../../Loader/Loader'

const VideoItem = ({ item, index, onClick, itemClick }) => {
  const [expandedIndex, setExpandedIndex] = useState(null)
  const [updatingIndex, setUpdatingIndex] = useState()
  const handleItemClick = (videoData) => {
    console.log('asdasdasdasdas', videoData)
    itemClick()
  }
  const onClickEditPodcast = () => {
    setUpdatingIndex()
    onClick()
  }
  return (
    <div
      className="video-detail-item overflow-hidden"
      key={s3ImageVideo + item.cover_attachment}
    >
      <div
        className="video-detail-cover !aspect-[1.63/1] !w-full relative"
        onClick={() => handleItemClick(item)}
        style={{
          backgroundImage: `url(${
            s3ImageVideo + item.cover_attachment
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%'
        }}
      >
        <div className="video_time !bottom-[15px] !shadow-none">
          <span className="!text-[#0B0A0A] !text-[10px] !font-black !italic">
            {formatDurations(item.duration)}
          </span>
        </div>
      </div>
      <div
        className={`video-content ${expandedIndex === index ? 'expanded' : ''}`}
      >
        <div className="flex flex-row items-center gap-2 mb-2">
          <div className="bg-shadyNight rounded-full w-8 h-8 overflow-hidden">
            <img
              className="h-full w-full p-[2px] rounded-full"
              src={s3ImageVideo + item.cover_attachment}
              alt={s3ImageVideo + item.cover_attachment}
            />
          </div>
          <span className="text-xs font-semibold text-Main">{item.title}</span>
          <img className="w-5 h-5" src={Images.creator} alt="creatore" />
        </div>
        <p className="text-[#303030] text-sm font-bold">
          {expandedIndex === index ? item?.description : `${item?.description?.slice(0, 60)}`}
          {item?.description?.length > 60 && (
            <span
              className="see-more"
              onClick={(e) => {
                e.stopPropagation()
                setExpandedIndex(
                  expandedIndex === index ? null : index
                )
              }}
            >
              {expandedIndex === index ? English.G145 : English.G146}
            </span>
          )}
        </p>
        <div className="views-and-time">
          <span>
            <img src={Images.ViewIco} alt="" />
            {formatViews(item?.views || 0)}
          </span>
          <span>
            <img src={Images.ClockFillIco} alt="" />
            {timeDifference(item.created_at)}
          </span>
        </div>
      </div>
      <div className="image-buttons z-1">
        <label>
          <button
            type="button"
            onClick={() => onClickEditPodcast(item)}
            className="d-none"
          />
        </label>
      </div>
      {updatingIndex === index && <Loader />}
    </div>
  )
}

export default VideoItem

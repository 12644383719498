import React from 'react'
import Stories from '../Stories/AddStories'
import GridImage from '../GridImage/GridImage'
// import StoreBannerComponent from '../UploadComponent/StoreBannerComponent'

const ProfileHomePage = () => {
    // if (pageType === 'store') {
    //   return (
    //     <div>
    //       <StoreBannerComponent />
    //     </div>
    //   )
    // }
    return (
      <div className="">
        <Stories />
        <GridImage />
      </div>
    )
}
export default ProfileHomePage

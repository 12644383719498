/* eslint-disable max-len */
import React from 'react'
import { useSelector } from 'react-redux'
import { s3ImagePodcast, s3ImageProfile } from '../../../services/Service'
import { cleanTimeString, timeDifference } from '../../../helpers/Utility'
import { English, Images } from '../../../helpers'
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal'
import Loader from '../../Loader/Loader'
import ImageComponent from '../../ImageComponent/ImageComponent'

// eslint-disable-next-line no-unused-vars
const PodcastItem = ({ item, index, playVolumeIcon, handleItemClick, expandedIndex, setExpandedIndex, onClickEditPodcast, setUpdatingIndex, ShowConfirm, ShowConfirmModel, updatingIndex, deletePodcast, handleCancel, delLoading }) => {
  const userData = useSelector((state) => state.user?.userData)
  return (
    <div className="Podcasts-item-detail" key={item.name}>
      <div
        className="podacast-items position-relative"
        key={s3ImagePodcast + item.cover_attachment}
        // onMouseEnter={() => handleMouseEnter(
        //     index,
        //     `${s3ImagePodcast}${item.attachment}`
        //   )}
        // onMouseLeave={() => handleMouseLeave(index)}
      >
        <div
          className="podcast-cover"
          onClick={(e) => {
            e.stopPropagation()
            handleItemClick(item)
          }}
        >
          <ImageComponent
            className="podcast-cover"
            imageUrl={s3ImagePodcast + item.cover_attachment}
            key={item.cover_attachment}
          />
          <div
            className="time-badge"
          >
            <span className="text-[#0B0A0A] text-[11px] font-black">
              {cleanTimeString(item?.minutes)}
            </span>
          </div>
          {/* <div className="play">
            <img src={Images.Play} alt="" />
          </div>
          {playVolumeIcon(index)} */}
        </div>
        <div
          className={`video-details podcast-detail ${expandedIndex === index ? 'expanded' : ''}`}
        >
          <div className="d-flex justify-content-between user_images">
            <h6>
              <img
                src={s3ImageProfile + userData.profile_image}
                alt=""
                className="w-7 h-7 rounded-full border-2 border-[#eb3469]"
              />
              <span className="podcast_user_name">
                {userData.user_name}
              </span>
              <img className="!w-4 h-4 ms-2" src={Images.creator} alt="creatore" />
            </h6>
          </div>
          <p>
            {expandedIndex === index
              ? item?.title
              : `${item?.title?.slice(0, 45)}`}
            {item?.title?.length > 45 && (
              <span
                className="see-more"
                onClick={(e) => {
                  e.stopPropagation()
                  setExpandedIndex(
                    expandedIndex === index ? null : index
                  )
                }}
              >
                {expandedIndex === index
                  ? English.G145
                  : English.G146}
              </span>
            )}
          </p>
          <div className="other-info">
            <span>
              <img
                className="d-block"
                src={Images.ClockFillIco}
                alt=""
              />
              {timeDifference(item.created_at)}
            </span>
          </div>
        </div>
        <div className="image-buttons z-1">
          <label>
            <button
              type="button"
              onClick={() => onClickEditPodcast(item)}
              className="d-none"
            />
          </label>
          <button
            type="button"
            className="p-0"
            onClick={(e) => {
              e.stopPropagation()
              setUpdatingIndex(index)
              ShowConfirm(item.podcast_id)
            }}
          />
        </div>
        <ConfirmationModal
          isVisible={ShowConfirmModel && updatingIndex === index}
          onConfirm={(e) => {
            e.stopPropagation()
            deletePodcast()
          }}
          onCancel={(e) => {
            e.stopPropagation()
            handleCancel()
          }}
          isLoading={delLoading}
        />
        {delLoading && updatingIndex === index && <Loader />}
      </div>
    </div>
  )
}

export default PodcastItem

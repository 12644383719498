import React from 'react'
import { English } from '../../helpers'
import CommonModalMain from '../CommonModalMain'
import PollOptionView from '../CommanComponent/PollOptionView'

const AddPollModalTwo = ({
    HandleCancel,
    publishPoll,
    HandleBackCancel,
    isPublishEnabled,
    inputFields,
    setInputFields
}) => {
  return (
    <div className="">
      <CommonModalMain
        showCross
        title={English.A211}
        isPrevious={English.G78}
        isNext={English.G98}
        onPressClose={HandleCancel}
        onPressSave={publishPoll}
        onPressBack={HandleBackCancel}
        showFooter
        isButtonDisabled={!isPublishEnabled}
        currentCount={2}
        totalCount={2}
        showCount={2}
      >
        <div className="w-full h-full flex flex-col gap-5 justify-between overflow-y-auto">
          <div className="item-details-input pt-0">
            <PollOptionView
              onOptionChange={(value, field, index) => {
                setInputFields((data) => {
                  const newData = {
                    ...data,
                    options: [...data.options]
                  }
                  newData.options[index] = { id: newData.options[index]?.id, option: value }
                  return newData
                })
              }}
              onPressAddMoreOption={() => {
                setInputFields((data) => {
                  const newData = { ...data }
                  newData.options.push({ option: '', id: '' })
                  return newData
                })
              }}
              onPressRemoveOptions={(index) => {
                setInputFields((data) => {
                  const newOptionsData = { ...data }
                  const Data = []
                  newOptionsData?.options?.forEach((item, arrayIndex) => {
                    if (arrayIndex === index && item?.id) {
                      Data.push({ ...item, isDelete: true })
                    } else if (arrayIndex !== index) {
                      Data.push(item)
                    }
                  })
                  return { ...newOptionsData, options: Data }
                })
              }}
              options={inputFields.options}
            />
          </div>
        </div>
      </CommonModalMain>
    </div>
  )
}
export default AddPollModalTwo

/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import ContentMangerCommonTitle from './SubComponent/ContentMangerCommon.js/ContentMangerCommonTitle'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import PodcastAddModal from './Modal/PodcastAddModal'
import { emitter, EventType } from '../../helpers/Emitter'
import ContentMangerCommonSeeAll from './SubComponent/ContentMangerCommon.js/ContentMangerCommonSeeAll'
import PodcastItem from './SubComponent/PodcastItem'

const Podcasts = () => {
  // const navigate = useNavigate()
  const audioRefs = useRef({})
  // const userData = useSelector((state) => state.user?.userData)

  const [ShowPodcastModel, setShowPodcastModel] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isPlaying, setIsPlaying] = useState({})
  const [podcastData, setPodcastData] = useState([])
  const [expandedIndex, setExpandedIndex] = useState(null)

  const getPodcastLibraryData = () => {
    setIsLoading(true)
    APICall('post', {}, `${EndPoints.getPodcastLibrary}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setPodcastData(res?.decryptedData.data)
          setIsLoading(false)
        } else {
          toast.error(res?.data?.message)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLoading(false)
      })
  }

  const togglePlayPause = (index) => {
    const audio = audioRefs.current[index]

    if (audio) {
      if (audio.paused) {
        audio
          .play()
          .then(() => {
            setIsPlaying((prev) => ({ ...prev, [index]: true }))
          })
          .catch((error) => {
            console.error('Error playing audio:', error)
          })
      } else {
        audio.pause()
        setIsPlaying((prev) => ({ ...prev, [index]: false }))
      }
    }
  }

  const playVolumeIcon = (index) => (
    <img
      src={isPlaying[index] ? Images.PodcastPauseIco : Images.PodcastPlayIco}
      alt={isPlaying[index] ? 'Pause' : 'Play'}
      onClick={() => togglePlayPause(index)}
      className="volume-icon"
    />
  )

  useEffect(() => {
    getPodcastLibraryData()
  }, [])

  useEffect(() => {
    return () => {
      Object.keys(audioRefs.current).forEach((index) => {
        const audio = audioRefs.current[index]
        if (audio) {
          audio.pause()
          audio.src = ''
        }
      })
    }
  }, [])

  const handleCancelModal = () => {
    setShowPodcastModel(false)
  }

  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [delLoading, setDelLoading] = useState(false)
  const [delPodcastId, setDelPodcastId] = useState()
  const [editData, setEditData] = useState(null)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const navigate = useNavigate()
  const ShowConfirm = (videoId) => {
    setDelPodcastId(videoId)
    setShowConfirmModel(true)
    Object.keys(audioRefs.current).forEach((index) => {
      const audio = audioRefs.current[index]
      if (audio) {
        audio.pause()
        audio.currentTime = 0
        setIsPlaying((prev) => ({ ...prev, [index]: false }))
      }
    })
  }
  const deletePodcast = () => {
    const data = {
      podcast_id: delPodcastId
    }
    setDelLoading(true)
    APICall('post', data, `${EndPoints.deletePodcast}`, 'contentManager')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setTimeout(() => {
            getPodcastLibraryData()
          }, 4000)
          setDelLoading(false)
          toast.success(res?.data?.message)
        } else {
          setDelLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setDelLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const handleCancel = () => {
    setShowConfirmModel(false)
  }

  const handleItemClick = (videoData) => {
    navigate('/podcast-detail', { state: videoData })
  }

  const onClickEditPodcast = (videoData) => {
    setEditData(videoData)
    setShowPodcastModel(true)
  }

  return (
    <div className="content-manager-podcasts-wrapper">
      <ContentMangerCommonTitle
        title={English.G226}
        buttonName={podcastData?.length === 0 ? null : English.G160}
        onButtonClick={
          podcastData?.length === 0 ? null : () => setShowPodcastModel(true)
        }
      />
      {podcastData?.length === 0 && !isLoading && (
        <CommonEmptyData
          image={Images.podcast_icon}
          mainTitle={English.B110}
          descriptionText={English.B111}
          onClick={() => { setShowPodcastModel(true) }}
          addIcon={Images.FileUploadWhite}
          btnText={English.G160}
          className="!border-solid"
          classNameDescription="font-normal text-[#7A7474] text-[16px]"
        />
      )}
      <div className="Podcasts-item-details">
        {isLoading ? (
          <div className="awards-item-video">
            <Skeleton className="add-profile-video-cover" />
            <div className="video-content">
              <p>
                <Skeleton width={250} />
              </p>
              <div className="views-and-time">
                <span>
                  <Skeleton width={50} />
                </span>
                <span>
                  <Skeleton width={60} />
                </span>
              </div>
            </div>
          </div>
        ) : (
          podcastData?.slice(0, 4)?.map((item, index) => (
            // <div className="Podcasts-item-detail" key={item.name}>
            //   <div
            //     className="podacast-items"
            //     key={s3ImagePodcast + item.cover_attachment}
            //     onMouseEnter={()
            // => handleMouseEnter(index, `${s3ImagePodcast}${item.attachment}`)}
            //     onMouseLeave={() => handleMouseLeave(index)}
            //   >
            //     <div
            //       className="podcast-cover"
            //       style={{
            //         backgroundImage: `url(${
            //           hoveredIndex === index
            //             ? `${s3ImagePodcast}${item.cover_attachment}`
            //             : `${s3ImagePodcast}${item.cover_attachment}`
            //         })`,
            //         backgroundRepeat: 'no-repeat',
            //         backgroundSize: 'cover'
            //       }}
            //     >
            //       {/* {item.app_data?.length > 0 && (
            //         <span
            //           className={`platform-badge ${
            //             item?.app_data?.length === 2 ? 'platform-badge-alt' : ''
            //           }`}
            //         >
            //           {item?.app_data?.map((data, i) => (
            //             <div
            //               key={i}
            //               className={`badge-cover ${
            //                 item?.app_data?.length === 2 ? 'badge-cover-alt' : ''
            //               }`}
            //               style={{
            //                 backgroundImage: `url(${urlSystemIcon + data.svg_icon})`,
            //                 backgroundRepeat: 'no-repeat',
            //                 backgroundSize: '90%',
            //                 backgroundPosition: 'center'
            //               }}
            //             />
            //           ))}
            //         </span>
            //       )} */}
            //       <div className="time-badge">
            //         <span className="text-[#0B0A0A] text-[11px] font-black">
            //           {cleanTimeString(item?.minutes)}
            //         </span>
            //       </div>
            //       {playVolumeIcon(index)}
            //       {/* <button type="button" className="drop-button">
            //       <img className="d-block" src={MoreHorizontal} />
            //     </button> */}
            //     </div>
            //     <div
            //       className={`video-details
            // podcast-detail ${expandedIndex === index ? 'expanded' : ''}`}
            //     >
            //       <div className="d-flex justify-content-between user_images">
            //         <h6>
            //           <img
            //             src={s3ImageProfile + userData.profile_image}
            //             alt=""
            //             className="w-7 h-7 rounded-full border-2 border-[#eb3469]"
            //           />
            //           <span>{userData?.user_name}</span>
            //           <img
            //             className="!w-4 h-4 ms-2"
            //             src={Images.creator}
            //             alt="creatore"
            //           />
            //         </h6>
            //         {/* <div className="rating width_all">
            //           <img
            // className="rating_image_podcast" src={Images.RatingStar} alt="rating" />
            //           <span>4.9</span>
            //         </div> */}
            //       </div>
            //       <p>
            //         {expandedIndex === index
            //           ? item?.title
            //           : `${item?.title?.slice(0, 45)}...`}
            //         {item?.title?.length > 45 && (
            //           <span
            //             className="see-more"
            //             onClick={(e) => {
            //               e.stopPropagation()
            //               setExpandedIndex(
            //                 expandedIndex === index ? null : index
            //               )
            //             }}
            //           >
            //             {expandedIndex === index ? English.G145 : English.G146}
            //           </span>
            //         )}
            //       </p>
            //       <div className="other-info">
            //         <span>
            //           <img
            //             className="d-block"
            //             src={Images.ClockFillIco}
            //             alt=""
            //           />
            //           {timeDifference(item.created_at)}
            //         </span>
            //       </div>
            //     </div>
            //   </div>
            // </div>
            <PodcastItem
              item={item}
              index={index}
              key={index}
              ShowConfirm={ShowConfirm}
              ShowConfirmModel={ShowConfirmModel}
              delLoading={delLoading}
              deletePodcast={deletePodcast}
              expandedIndex={expandedIndex}
              handleCancel={handleCancel}
              handleItemClick={handleItemClick}
              onClickEditPodcast={onClickEditPodcast}
              playVolumeIcon={playVolumeIcon}
              setExpandedIndex={setExpandedIndex}
              setUpdatingIndex={setUpdatingIndex}
              updatingIndex={updatingIndex}
            />
          ))
        )}
      </div>
      {ShowPodcastModel && (
        <PodcastAddModal
          handleCancel={handleCancelModal}
          editData={editData}
          onSubmit={() => {
            getPodcastLibraryData()
            setEditData(null)
          }}
        />
      )}
      {!isLoading && podcastData?.length > 4 && (
        <ContentMangerCommonSeeAll
          onClick={() => emitter.emit(EventType.contentMangerTabs, { tabIndex: 3 })}
        />
      )}
    </div>
  )
}
export default Podcasts

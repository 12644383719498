import { useEffect } from 'react'

const useClickOutside = ({ refArray, onPressOutside }) => {
  useEffect(() => {
    function handleClick(event) {
      const isRefSatisfied = refArray.every((ref) => {
        return ref.current && !ref.current.contains(event.target)
      })
      if (isRefSatisfied) {
        onPressOutside()
      }
    }
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [onPressOutside, refArray])
}
export default useClickOutside

/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { English, Images } from '../../../helpers'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import { s3ImageProfile } from '../../../services/Service'
import CommonInput from '../../InputComponet/CommonInput'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import Loader from '../../Loader/Loader'
import { APICall, EndPoints } from '../../../services'
import Dropdown from '../../Dropdown/Dropdown'
import './editProfileStyle.css'
import { socialMediaOptions, urlRegex } from '../../../helpers/Constant'

const EditProfileModel = ({
  type = 'store',
  componentRef,
  profileEditData,
  title,
  onCancel,
  onSubmite,
  linkTitle
}) => {
  const app_id = useSelector((state) => state.user?.app_id.id)
  const [profileImg, setProfileImg] = useState(profileEditData?.profile_icon)
  const [profileImgUrl, setProfileImgUrl] = useState()
  const [profileImgObj, setProfileImgObj] = useState()
  const [isEdited, setIsEdited] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const [socialMediaLinks, setSocialMediaLinks] =
  useState(Array.isArray(profileEditData.social_media_links)
    ? profileEditData.social_media_links.map((link) => ({ ...link, ...(socialMediaOptions.find((opt) => opt.name === link.linkName) || {}) }))
    : [])
  const [inputFields, setInputFields] = useState({
    description: profileEditData.description,
    store_link: profileEditData?.store_link,
    website_link: profileEditData?.website_link,
    icon: profileEditData.profile_icon
  })
  const maxFileSizeMB = 8
  const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024
  const handleFileChange = (file) => {
    setIsEdited(true)
    if (file.size > maxFileSizeBytes) {
      toast.error(`File size exceeds ${maxFileSizeMB} MB`)
      return
    }
    const reader = new FileReader()
    reader.onload = (e) => {
      setProfileImg(file)
      setProfileImgUrl(e.target.result)
      setProfileImgObj(file)
    }
    reader.readAsDataURL(file)
  }
  useEffect(() => {
    if (!profileEditData) {
      clearValue()
      setLoading(false)
      setIsEdited(false)
    } else {
      setInputFields({
        description: profileEditData.description,
        store_link: profileEditData?.store_link,
        website_link: profileEditData?.website_link,
        icon: profileEditData.profile_icon
      })
    }
  }, [profileEditData])
  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
    setIsEdited(true)
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    await finishSubmit()
  }
  const finishSubmit = async () => {
    const socialMediaObject = socialMediaLinks.reduce((acc, item) => {
      acc[item.name] = item.linkContent
      return acc
    }, {})
    if (socialMediaLinks.length > 0) {
      const updatedLinks = [...socialMediaLinks]
      const isValid = updatedLinks.every((item) => item.linkContent !== '' && urlRegex.test(item?.linkContent))
      updatedLinks.forEach((item, index) => {
        if (item.linkContent === '' || !urlRegex.test(item.linkContent)) {
          updatedLinks[index].error = English.B62
        } else {
          updatedLinks[index].error = ''
        }
      })
      setSocialMediaLinks(updatedLinks)
      setInputFields({ ...inputFields, social_media: updatedLinks })
      if (!isValid) {
        return
      }
    }
    setLoading(true)
    const platFormData = {
      app_id,
      icon: inputFields.icon,
      description: inputFields?.description,
      store_link: inputFields?.store_link,
      social_media: socialMediaObject,
      website_link: inputFields?.website_link
    }
    if (profileImgObj) {
      const compressedImage = await compressImage(profileImgObj)
      const formData = new FormData()
      formData.append('file', compressedImage)
      formData.append('type', type)
      formData.append('sub_type', 'profile_image')
      APICall('post', formData, EndPoints.uploadImage, 'profile', true)
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            platFormData.icon = dataDecrypt(res.data.data)
            onSubmite(platFormData)
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((e) => {
          toast.error(e?.data?.message)
        })
    } else {
      onSubmite(platFormData)
    }
  }
  const clearValue = () => {
    setInputFields({
      ...inputFields,
      description: '',
      store_link: '',
      website_link: '',
      icon: ''
    })
    setProfileImgUrl('')
  }
  const handleInputChange = (index, value, img, name) => {
    const updatedLinks = [...socialMediaLinks]
    updatedLinks[index] = { ...updatedLinks[index], linkContent: value, img, name }
    setSocialMediaLinks(updatedLinks)
    setInputFields({ ...inputFields, social_media: updatedLinks })
    const isValid = updatedLinks.every((item) => item.linkContent !== '' && urlRegex.test(item?.linkContent))
    setIsEdited(isValid)
    setIsEdited(true)
  }
  const handleDeleteLink = (index) => {
    const updatedLinks = socialMediaLinks.filter((_, i) => i !== index)
    setSocialMediaLinks(updatedLinks)
    setInputFields({ ...inputFields, social_media: updatedLinks })
    setIsEdited(true)
  }
  const addInputField = () => {
    const options = socialMediaOptions.filter((option) => !socialMediaLinks.some((link) => link.name === option.name))
    const updatedLinks = [...socialMediaLinks, { linkContent: '', img: options[0].img, name: options[0].name }]
    setSocialMediaLinks(updatedLinks)
    setInputFields({ ...inputFields, social_media: updatedLinks })
    setIsEdited(true)
  }
  return (
    <div className="add-item-model edit-profile">
      <div
        className="add-item-content edit-creator px-0 pb-0 !pt-0"
        ref={componentRef}
      >
        {isLoading && <Loader />}
        <h3 className="text-center edit_profile_header">{title}</h3>
        <form onSubmit={handleSubmit} className="pr-3">
          {/* <div className="scroll-bars position-relative pb-2 profile_modal"> */}
          <div className="scroll-bars-no-scroll relative pb-2 profile_modal max-h-[450px]">
            <div className="profile-pics w-100 h-100 mt-4">
              <div className="profile-pic-image">
                <CommonImageUpload
                  handleImageChange={handleFileChange}
                  index={0}
                  forImage="create-profile"
                  className="profile-image-store"
                  selectedImg={profileImgUrl || s3ImageProfile + profileImg}
                  defaultImgCamera={Images.SelectImg}
                />
              </div>
            </div>
            <div className="item-details-input pt-0">
              <div>
                <label className="mb-2">{English.G112}</label>
                <CommonInput
                  name="description"
                  placeholder={English.G83}
                  onChange={handleChange}
                  className="description-input"
                  value={inputFields.description}
                  // maxLength={150}
                  isTextArea
                />
              </div>
              <div>
                {profileEditData.social_media_links ?
                  <div>
                    <label>{English.G115}</label>
                    {socialMediaLinks && socialMediaLinks.length > 0 ? (
                      socialMediaLinks.map((link, index) => (
                        <div
                          key={index}
                          className="!mb-4"
                        >
                          <div
                            className="item-details-input p-0 d-flex gap-3 items-center"
                          >
                            <div className="drp flex flex-row w-100">
                              <Dropdown
                                title=""
                                img={link.img}
                                Data={socialMediaOptions.filter((option) => !socialMediaLinks.some((link) => link.name === option.name))}
                                onChanged={(value) => {
                                  handleInputChange(
                                    index,
                                    link.link,
                                    value.img,
                                    value.name,
                                  )
                                }}
                                customClass="link_dropdown !h-auto content-center edit_profile_dropdown"
                              />
                              <CommonInput
                                placeholder="Paste here"
                                className="!mb-[0px] w-100 edit_link_input"
                                onChange={(e) => handleInputChange(index, e.target.value, link.img, link.name)}
                                value={link.linkContent}
                              />
                            </div>
                            <button
                              className="delete_icon !shadow-none"
                              type="button"
                              onClick={() => handleDeleteLink(index)}
                            />
                          </div>
                          {link?.error !== '' && <span className="error-message">{link.error}</span>}
                        </div>
                      ))
                    ) : (
                      <p>No Link</p>
                    )}
                    {socialMediaLinks.length < 7 ? (
                      <CommonButton
                        label="Add another"
                        imgSrc={Images.Plus}
                        onClick={addInputField}
                        className="secondary-button mt-2 w-100"
                      />
                    ) : null}
                  </div>
                  :
                  <div>
                    <label className="mb-2">{linkTitle}</label>
                    <CommonInput
                      name={inputFields?.website_link !== undefined ? 'website_link' : 'store_link'}
                      placeholder={English.G83}
                      onChange={handleChange}
                      value={inputFields?.store_link || inputFields?.website_link}
                    />
                  </div>}
              </div>
            </div>
          </div>
          <div className="button-row edit-model gap-3 edit_profile_button">
            <CommonButton
              label={English.G116}
              onClick={() => {
                    onCancel()
                    setIsEdited(false)
                  }}
              isPrimary={false}
              context={English.G232}
            />
            <CommonButton
              label={English.G117}
              type="submit"
              isPrimary
              disabled={!isEdited}
              context={English.G232}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditProfileModel

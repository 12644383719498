import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import { toast } from 'react-toastify'
import { Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import { useSelector } from 'react-redux'
import { s3ImageProfile, urlImageBannerPathStore } from '../../services/Service'
import { compressImage, dataDecrypt } from '../../services/http-services'
import Loader from '../Loader/Loader'
import CommonInput from '../InputComponet/CommonInput'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { lockScroll, unlockScroll } from '../../helpers/Utility'
import { APICall, EndPoints } from '../../services'

const StoreBannerComponent = () => {
  const [banner, setBanner] = useState([])
  const profileData = useSelector((state) => state.user?.profileData)
  const [loading, setLoading] = useState(false)
  const app_id = useSelector((state) => state.user?.app_id)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)

  const [inputFields, setInputFields] = useState({
    banner: ''
  })
  const fileInputRef = useRef(null)

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
    EditBanner(e.target.files[0])
  }

  const ShowConfirm = () => {
    setShowConfirmModel(true)
  }
  const HandleCancelConfirm = () => {
    setShowConfirmModel(false)
  }

  const getBannerData = async (msg = null) => {
    setLoading(true)
    const platFormData = {
      app_id: app_id.id
    }
    APICall('post', platFormData, EndPoints.getBanner, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const fetchedBanners = dataDecrypt(res.data.data)
          if (!fetchedBanners) {
            setBanner([])
          } else {
            setBanner(
              Array.isArray(fetchedBanners) ? fetchedBanners : [fetchedBanners]
            )
          }
          if (msg) {
            toast.success(msg)
          }
          setLoading(false)
        } else {
          toast.error(res.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const deleteMainBanner = (data) => {
    const platFormData = {
      app_id: app_id.id,
      banner_id: data.banner_id
    }
    setLoading(true)
    APICall('post', platFormData, EndPoints.deleteBanner, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setBanner([])
          toast.success(res?.data?.message)
          setLoading(false)
          setShowConfirmModel(false)
        } else {
          toast.error(res.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const EditBanner = async ({ file }) => {
    setLoading(true)
    const imageType = 'banner'
    const compressedImage = await compressImage(file)
    const formData = new FormData()
    formData.append('file', compressedImage)
    formData.append('type', imageType)
    APICall('post', formData, EndPoints.uploadImage, 'store', true)
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          inputFields.banner = dataDecrypt(res.data.data)
          userDataSubmit()
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((e) => {
        toast.error(e?.data?.message)
      })
  }

  const userDataSubmit = () => {
    inputFields.app_id = app_id.id
    setLoading(true)
    APICall('post', inputFields, EndPoints.editBanner, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            getBannerData(res?.data?.message)
            setLoading(false)
          }, 3000)
        } else {
          toast.error(res.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const onEditLocalFile = (event) => {
    event?.preventDefault()
    fileInputRef.current.click()
  }

  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowConfirmModel(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const [localStorageValue, setLocalStorageValue] = useState(app_id || null)

  useEffect(() => {
    const handleStorageChange = () => {
      setLocalStorageValue(app_id || null)
    }

    window.addEventListener('storage', handleStorageChange)

    return () => window.removeEventListener('storage', handleStorageChange)
  }, [app_id])

  useEffect(() => {
    if (localStorageValue !== null) {
      getBannerData()
    }
  }, [localStorageValue])

  useEffect(() => {
    if (ShowConfirmModel) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [ShowConfirmModel])

  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const OverUserNameImage = () => {
    return (
      <div className="z-[9] absolute bottom-4">
        <div className="flex flex-row items-center">
          <div className="!h-10 !w-10 bg-electricWhite profile-build-image items-center justify-center rounded-full content-center justify-items-center shadow-md">
            <img className="rounded-full !w-[34px] !h-[34px]" alt="" src={`${s3ImageProfile}${profileData?.profile_icon}`} />
          </div>
          <div className={`${banner?.length > 0 ? 'text-white' : 'text-[#0f0f0f]'} ml-2 font-bold text-[14px]`}>{profileData?.user_name || 'test'}</div>
          {banner?.length > 0 && <img className="ml-2 !w-[16px] !h-[16px] !rounded-none" alt="" src={Images.BuildingWhite} />}
        </div>
      </div>
    )
  }

  return (
    <div className="item-details-input-component">
      {banner?.length > 0 ? (
        banner?.map((d, i) => (
          <div key={`${i}-${d.id}`} className="banner-main position-relative">
            {loading && <Loader />}
            <img
              key={`${i}-${d.banner}`}
              alt=""
              className="banner-image !aspect-[16/9]"
              src={urlImageBannerPathStore + d.banner}
            />
            <div className="image-buttons z-1">
              <label className="overflow-hidden">
                <ImgCrop showGrid rotationSlider aspectSlider showReset aspect={16 / 9}>
                  <Upload
                    name="banner"
                    accept=".jpg,.jpeg,.png"
                    listType="picture"
                    aspect={16 / 9}
                    showUploadList={false}
                    customRequest={EditBanner}
                  >
                    <button type="button" className="d-none" />
                  </Upload>
                </ImgCrop>
              </label>
              <button
                type="button"
                className="p-0"
                onClick={ShowConfirm}
              />
            </div>
            <div className="banner-btn">
              <form onSubmit={onEditLocalFile}>
                <CommonInput
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  type="file"
                  id="banner-img"
                  onChange={handleChange}
                />
              </form>
              {ShowConfirmModel && (
                <div className="add-item-model">
                  <div
                    className="add-item-contents h-auto w-auto"
                    ref={componentRef}
                  >
                    <div className="item-details-input pt-0">
                      <h3 className="mb-0 text-center">{English.G119}</h3>
                      <div className="button-row justify-content-center">
                        <button
                          type="button"
                          className="secondary-btn-model"
                          onClick={HandleCancelConfirm}
                        >
                          <span>{English.G116}</span>
                        </button>
                        <button
                          type="button"
                          className="primary-btn-model"
                          onClick={() => {
                            deleteMainBanner(d)
                          }}
                        >
                          {English.G120}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="ml-4">
              <OverUserNameImage />
            </div>
          </div>
        ))
      ) : (
        <div className="upload-input upload-input-with-border banner-height !pt-0">
          {loading && <Loader />}
          {!loading && (
            <label htmlFor="upload-file" className="banner_label">
              <div className="banner_label">
                <ImgCrop showGrid rotationSlider aspectSlider showReset aspect={16 / 9}>
                  <Upload
                    name="banner"
                    listType="picture"
                    aspect={16 / 9}
                    accept=".jpg,.jpeg,.png"
                    showUploadList={false}
                    customRequest={EditBanner}
                  >
                    <div className="upload-img" onClick={handleFileUploadClick}>
                      <img alt="" className="!w-12 !h-12" src={Images.Add_photo_alternate} />
                    </div>
                    <div className="text-textPrimary text-[18px] italic font-black uppercase">{English.B63}</div>
                    <p className="mb-2 !text-textSecondary !text-[16px] !font-normal">{English.B64}</p>
                    {/* <button type="button" onClick={handleFileUploadClick}>
                      <span>{English.G459}</span>
                    </button> */}
                  </Upload>
                </ImgCrop>
              </div>
            </label>
          )}
          <OverUserNameImage />
        </div>
      )}
    </div>
  )
}
export default StoreBannerComponent

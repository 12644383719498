/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import './Store.css'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { X } from 'lucide-react'
import { English, Images } from '../../helpers'
import Dropdown from '../Dropdown/Dropdown'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import ProductAddModal from './ProductAddModal'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'
import Loader from '../Loader/Loader'
import { APICall, dataDecrypt, EndPoints } from '../../services'
import { urlImageProductPathStore } from '../../services/Service'
import { emitter, EventType } from '../../helpers/Emitter'
// import ParticularProductShow from './particularProductShow'

const StoreProductAllView = () => {
  const app_id = useSelector((state) => state.user?.app_id)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [product, setProduct] = useState([])
  const [isLibraryLoading, setIsLibraryLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [proDeleteIndex, setProDeleteIndex] = useState(null)
  const [ShowLibraryConfirmModel, setShowLibraryConfirmModel] = useState(false)
  const [editData, setEditData] = useState(null)
  const [EditProduct, setEditProduct] = useState(false)
  // const [particularProduct, setParticularProduct] = useState(false)
  const [productData, setProductData] = useState()
  const [searchText, setSearchText] = useState('')
  const [inputFields, setInputFields] = useState({
    category_id: ''
  })
  const [category, setCategory] = useState([])

  const EditLibrary = (data) => {
    setIsModalOpen(true)
    setEditProduct(true)
    setEditData(data)
  }

  const clearSearch = () => {
    setSearchText('')
    handleSearch2('')
  }

  const handleSearch = (value) => {
    setSearchText(value)
    handleSearch2(value)
  }

  useEffect(() => {
    const update = emitter.addListener(
      EventType.editStore,
      ({ product_id }) => {
        setIsModalOpen(true)
        setEditProduct(true)
        setEditData(product_id)
      }
    )
    const updated = emitter.addListener(EventType.productLibrary, () => {
      ProductLibrary()
    })

    return () => {
      update.remove()
      updated.remove()
    }
  }, [])

  const MainCategory = () => {
    APICall('post', {}, EndPoints.category, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setCategory(res?.decryptedData)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }
  const particularClick = async (data) => {
    const platFormData = {
      app_id: app_id?.id,
      plateform: 'web',
      product_id: data?.id
    }
    APICall('post', platFormData, `${EndPoints.productDetail}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const productDetails = dataDecrypt(res.data.data)
          setProductData(productDetails)
          emitter.emit(EventType.productShow, {
            product_id: productDetails,
            action: 'true'
          })
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })

    // setParticularProduct(true)
  }

  const clickOnSee = (data) => {
    emitter.emit(EventType.productListShow, {
      product_id: data,
      action: 'true'
    })
    // setParticularProduct(true)
  }

  const deleteLibraryConfirm = () => {
    setShowLibraryConfirmModel(true)
  }

  const HandleCancelConfirm = () => {
    setShowLibraryConfirmModel(false)
  }

  const ProductLibrary = (search) => {
    setIsLibraryLoading(true)
    const data = {
      search
    }
    APICall(
      'post',
      data,
      `${EndPoints.getProductLibrary}?search=${search}`,
      'store'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setProduct(res?.decryptedData)
          setIsLibraryLoading(false)
        } else {
          setIsLibraryLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsLibraryLoading(false)
      })
  }

  const handleSearch2 = useCallback(
    debounce((inputVal) => ProductLibrary(inputVal), 500),
    []
  )

  const deleteProductLib = (data) => {
    const platFormData = {
      product_id: data?.id
    }
    setLoading(true)
    APICall('post', platFormData, `${EndPoints.deleteProductLibrary}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setTimeout(() => {
            setLoading(false)
            emitter.emit(EventType.ProductLibraryDelete, {
              product_id: data.id,
              action: 'update'
            })
            ProductLibrary()
            //   onSubmited()
            setShowLibraryConfirmModel(false)
            toast.success(res?.data?.message)
          }, 1500)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const getFilterData = async (category_id) => {
    const platFormData = {
      app_id: app_id?.id,
      category_id
    }
    APICall('post', platFormData, EndPoints.getGeneralProduct, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setProduct(res?.decryptedData)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const productLibraryInitialized = useRef(false)

  useEffect(() => {
    if (!productLibraryInitialized.current) {
      productLibraryInitialized.current = true
      ProductLibrary()
      MainCategory()
    }
  }, [])
  return (
    <div className="feed-container d-block">
      {/* <div className="details-tab-view common-card-body p-20"> */}
      {!productData && (
        <div className="">
          <div className="feed-container d-block">
            <div className="">
              <div className="discussions-wrapper-header common-contnt-wrapper flex-col mb-2.5 !justify-normal gap-2.5 w-full">
                <div className="flex items-center justify-between w-full">
                  <h2 className="italic text-Main font-black text-lg uppercase mb-0">
                    {English.A249}
                  </h2>
                  <div className="drop-row !mb-0 gap-2.5">
                    <div className="add-video-rows">
                      <CommonButton
                        label={English.B71}
                        onClick={() => setIsModalOpen(true)}
                        isPrimary
                        className="add_new_content"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center w-full">
                  <div className="relative">
                    <CommonInput
                      placeholder="Search..."
                      className="!pl-3.5 !rounded-full before: bg-[#FAFAFA] !border-0"
                      onChange={(e) => {
                        handleSearch(e.target.value)
                      }}
                      value={searchText}
                    />
                    {searchText && (
                      <button
                        type="button"
                        className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-black"
                        onClick={clearSearch}
                      >
                        <X className="w-5 h-5" />
                      </button>
                    )}
                  </div>
                  <div className="drop-sub-row !gap-2.5">
                    <Dropdown
                      title={
                        category?.find(
                          (it) => it.id === inputFields.category_id
                        )?.name || 'Categories'
                      }
                      onChanged={(d) => {
                        if (d.id === 'all') {
                          ProductLibrary()
                          setInputFields({
                            ...inputFields,
                            category_id: null
                          })
                        } else {
                          getFilterData(d.id)
                          setInputFields({
                            ...inputFields,
                            category_id: d.id
                          })
                        }
                      }}
                      Data={[
                        ...(inputFields.category_id
                            ? [{ title: 'All Product', id: 'all' }]
                        : []),
                        ...(category || []).map((d) => ({
                          title: d.name,
                          id: d.id
                        }))
                      ]}
                      customClass="store_dropdown"
                    />
                    <Dropdown
                      title="Ratings"
                      Data={[
                        { title: 'Scaloo' },
                        { title: 'Spacure' },
                        { title: 'PETZINO' },
                        { title: 'A-Z FIT' },
                        { title: 'TESTIEE' }
                      ]}
                      customClass="disabledDropdown"
                    />
                  </div>
                </div>
              </div>

              {/* <div className="discussions-wrapper-header common-contnt-wrapper mb-2.5 !justify-normal gap-2.5">
                <h2 className="italic text-Main font-black text-lg uppercase">
                  {English.A249}
                </h2>
                <div className="items-filter flex flex-1 justify-end">
                  <div className="relative">
                    <CommonInput
                      placeholder="Search..."
                      className="!pl-3.5 !rounded-full before: bg-[#FAFAFA] !border-0"
                      onChange={(e) => {
                        handleSearch(e.target.value)
                      }}
                      value={searchText}
                    />
                    {searchText && (
                      <button
                        type="button"
                        className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-black"
                        onClick={clearSearch}
                      >
                        <X className="w-5 h-5" />
                      </button>
                    )}
                  </div>
                </div>
                <div className="drop-row !mb-0 gap-2.5">
                  <div className="drop-sub-row !gap-2.5">
                    <Dropdown
                      title={
                        category?.find(
                          (it) => it.id === inputFields.category_id
                        )?.name || 'Categories'
                      }
                      onChanged={(d) => {
                        if (d.id === 'all') {
                          ProductLibrary()
                          setInputFields({
                            ...inputFields,
                            category_id: null
                          })
                        } else {
                          getFilterData(d.id)
                          setInputFields({
                            ...inputFields,
                            category_id: d.id
                          })
                        }
                      }}
                      Data={[
                        ...(inputFields.category_id
                          ? [{ title: 'All Product', id: 'all' }]
                          : []),
                        ...(category || []).map((d) => ({
                          title: d.name,
                          id: d.id
                        }))
                      ]}
                      customClass="store_dropdown"
                    />
                    <Dropdown
                      title="Ratings"
                      Data={[
                        { title: 'Scaloo' },
                        { title: 'Spacure' },
                        { title: 'PETZINO' },
                        { title: 'A-Z FIT' },
                        { title: 'TESTIEE' }
                      ]}
                      customClass="disabledDropdown"
                    />
                  </div>
                  <div className="add-video-rows">
                    <CommonButton
                      label={English.B71}
                      onClick={() => setIsModalOpen(true)}
                      isPrimary
                      className="add_new_content"
                    />
                  </div>
                </div>
              </div> */}

              {/* <div className="store_scroll_bar grid-cols-5 grid gap-2"> */}
              <div className="grid-cols-5 grid gap-x-6 gap-y-2.5 max-[1440px]:grid-cols-4 max-[1200px]:grid-cols-3">
                {product?.length === 0 && !isLibraryLoading ? (
                  <CommonEmptyData
                    className="w-full !border-solid col-span-5"
                    image={Images.recommendation}
                    mainTitle={English.B69}
                    descriptionText={English.B70}
                  />
                ) : isLibraryLoading ? (
                  <div className="awards-item">
                    <div className="add-profile-video-cover add-product-video-cover">
                      <button type="button">
                        <img src={Images.Plus} alt="add library" />
                      </button>
                    </div>
                    <div className="product-footer">
                      <p className="add-value">
                        <Skeleton
                          className="add-value"
                          width={202}
                          height={35}
                        />
                      </p>
                      <span className="add-value">
                        <Skeleton
                          className="add-value"
                          width={67}
                          height={35}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  product?.map((data, i) => (
                    <div
                      key={i}
                      className="product-item rounded-xl relative overflow-hidden border-[0.5px] border-SecondaryBorder"
                    >
                      <div className="z-1 top-10 !z-[9999] absolute image-buttons">
                        <label>
                          <button
                            type="button"
                            className="d-none"
                            onClick={(e) => {
                              e.stopPropagation()
                              EditLibrary(data)
                            }}
                          />
                        </label>
                        <button
                          type="button"
                          className="p-0"
                          onClick={(e) => {
                            e.stopPropagation()
                            deleteLibraryConfirm()
                          }}
                        />
                        {ShowLibraryConfirmModel && (
                          <div className="add-item-model">
                            <div className="add-item-contents h-auto w-auto">
                              <div className="item-details-input pt-0">
                                <h3 className="mb-0 text-center">
                                  {English.G119}
                                </h3>
                                <div className="button-row justify-content-center">
                                  <button
                                    type="button"
                                    className="secondary-btn-modelConfirm"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      HandleCancelConfirm()
                                    }}
                                  >
                                    <span>{English.G116}</span>
                                  </button>
                                  <button
                                    type="button"
                                    className="primary-btn-modelConfirm"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      setProDeleteIndex(i)
                                      deleteProductLib(data)
                                    }}
                                  >
                                    {English.G120}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {loading && proDeleteIndex === i && <Loader />}
                      <div
                        onClick={(e) => {
                          e.stopPropagation()
                          particularClick(data)
                        }}
                        className="h-full"
                      >
                        <div
                          className="aspect-[10/13] w-full"
                          style={{
                            backgroundImage: `url(${urlImageProductPathStore + data.image})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                          }}
                        >
                          <div className="rating hidden">
                            <img src={Images.RatingStar} alt="star" />
                            <span>4.9</span>
                          </div>
                        </div>
                        <div className="product-item-details justify-content-between">
                          <div className="flex flex-row gap-2">
                            {data.discount_price > 0 ? (
                              <>
                                <div className="font-bold text-[16px]">
                                  {data.discount_price}
                                  €
                                </div>
                                {/* <div className="font-bold text-[16px] line-through opacity-50">
                                {data.regular_price}
                                €
                              </div> */}
                              </>
                            ) : (
                              <div className="font-bold text-base text-Main">
                                {data.regular_price}
                                €
                              </div>
                            )}
                            {/* <div className="font-bold text-[16px]">
                            {data.discount_price}
                            €
                          </div> */}
                          </div>
                          <div className="text-[10px] font-medium text-Main truncate">
                            {data?.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
              {product?.length > 4 && (
              <div className="flex justify-center mt-3 cursor-pointer bg-primaryGradient font-bold text-[16px] bg-clip-text text-transparent" onClick={() => clickOnSee(product)}>See All</div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* {isModalOpen && ( */}
      <ProductAddModal
        onClose={() => setIsModalOpen((prev) => !prev)}
        isMainModalOpen={isModalOpen}
        editData={editData}
        isProductEdit={EditProduct}
        setIsProductEdit={setEditProduct}
      />
      {/* )} */}
      {/* {productData && (
        <ParticularProductShow ProductDetails={productData} />
      )} */}
    </div>
  )
}
export default StoreProductAllView

import React, { useState } from 'react'
import { s3ImageGiveaway } from '../../../services/Service'
import { English, Images } from '../../../helpers'
import { formatDateTimeLeft } from '../../../helpers/Utility'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import ImageComponent from '../../ImageComponent/ImageComponent'

const GiveawayItem = ({
  item,
  index,
  onClick,
  itemClick,
  onClickDelete,
  text,
  editBtn
}) => {
  const [expandedIndex, setExpandedIndex] = useState(null)
  const handleItemClick = (videoData) => {
    itemClick(videoData)
  }
  const onClickEditGiveaway = () => {
    onClick()
  }
  const onClickDeleteGiveaway = () => {
    // setUpdatingIndex()
    onClickDelete()
  }
  return (
    <div key={index} className="added-giveways-item position-relative">
      <div className="image-buttons z-1 !right-0">
        <label>
          <button
            type="button"
            onClick={() => onClickEditGiveaway(item)}
            className="d-none"
          />
        </label>
        <button
          type="button"
          className="p-0"
          onClick={() => {
            onClickDeleteGiveaway()
          }}
        />
      </div>
      <div
        key={index}
        className="giveaways-container !items-start"
        onClick={() => handleItemClick(item)}
      >
        <div className="giveaways-thumbnails">
          {item.images.slice(1, 4).map((img, idx) => (
            <ImageComponent
              className="thumbnail"
              imageUrl={`${s3ImageGiveaway}${img}`}
              key={idx + img}
            />
          ))}
        </div>

        <div className="giveaways-item-cover">
          {/* <img src={s3ImageGiveaway + item.images[0]} alt="giveaway" /> */}
          <ImageComponent
            imageUrl={s3ImageGiveaway + item.images[0]}
            key={item.images[0]}
          />
        </div>
      </div>
      <div className="giveways-item-details flex flex-col pb-2">
        <h3 className="!mb-[6px] giveaway_title text-Main text-lg font-bold">{item.title}</h3>
        <div className="d-flex align-items-center justify-content-between">
          <div className="giveways-profile-detail">
            {text === 'contentManager' || text === 'contentManagerOverview' ? (
              <div className="giveways-com-rating">
                <span className="giveway-profile-name !text-[#151515] !text-[14px] !font-normal">
                  {item.brand_name}
                </span>
              </div>
            ) : (
              ''
            )}
            {text === 'contentManagerOverview' ? (
              <>
                <div className="vertical-devider" />
                <div className="giveways-com-rating">
                  <div className="rating-block-container flex">
                    <img src={Images.FillStar} alt="star" />
                    <img src={Images.FillStar} alt="star" />
                    <img src={Images.FillStar} alt="star" />
                    <img src={Images.FillStar} alt="star" />
                    <img src={Images.EmptyStar} alt="star" />
                  </div>
                  <span>
                    {item.rating}
                    {' '}
                    4.5 rating
                  </span>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <hr className="bg-[#E1E2E5]" />
        <div className="flex gap-3 h-[25px]">
          <h6 className="mb-0 text-Main opacity-60 text-base font-medium">
            Type:
          </h6>
          <p className="mt-0 mb-0 text-Main text-base font-medium">
            {item.type_name}
          </p>
        </div>
        <hr className="bg-[#E1E2E5]" />
        <p className="!block !text-[14px] text-[#151515] font-normal !mb-4 !min-h-0 flex-1">
          {expandedIndex === index
            ? item?.description
            : `${item?.description?.slice(0, 210)}`}
          {item?.description?.length > 210 && (
            <span
              className="see_more_local"
              onClick={(e) => {
                e.stopPropagation()
                setExpandedIndex(expandedIndex === index ? null : index)
              }}
            >
              ...
              {' '}
              {expandedIndex === index ? English.G193 : English.G194}
            </span>
          )}
        </p>
        <div className="flex items-center justify-between">
          <div className="giveway-time-left">
            <img src={Images.ClockBlue} alt="" />
            <span>{formatDateTimeLeft(item.expire_date)}</span>
          </div>
          {editBtn === true ?
            <div>
              <CommonButton
                label={English.A261}
                type="button"
                isPrimary
                onClick={(e) => {
                e.stopPropagation()
                onClickEditGiveaway(item)
              }}
                context={English.A197}
                className="!w-[150px] !px-0"
              />
            </div> : ''}

        </div>
      </div>
    </div>
  )
}

export default GiveawayItem

/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import '../HotelComponent/RoomAdd.css'
import './Menu.css'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import Pagination from '../../Pagination/Pagination'
import English from '../../../helpers/English'
import Images from '../../../helpers/Images'
import { APICall, EndPoints } from '../../../services'
import { s3ImageRestaurant } from '../../../services/Service'

const MenuShow = ({ isRestaurantId }) => {
  const app_id = useSelector((state) => state.user?.app_id)
  const [menuListData, setMenuListData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const RestaurantMenuGetData = (currentPage) => {
    const payload = {
      app_id: app_id.id,
      restaurant_id: isRestaurantId
    }
    APICall(
      'post',
      payload,
      `${EndPoints.getMenu}?page=${currentPage}`,
      'local'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setMenuListData(res?.decryptedData.data)
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const groupedMenuData = useMemo(() => {
    return menuListData.reduce((acc, item) => {
      if (!acc[item.cuisine]) {
        acc[item.cuisine] = []
      }
      acc[item.cuisine].push(item)
      return acc
    }, {})
  }, [menuListData])

  const onClickLike = (menu_id, index) => {
    const data = {
      app_id: app_id.id,
      menu_id
    }
    APICall('post', data, `${EndPoints.menuLike}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const newArr = [...menuListData]
          newArr[index].is_like = !newArr[index].is_like
          setMenuListData(newArr)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const renderTable = useMemo(() => {
    return (
      <div className="discount_list">
        {Object.entries(groupedMenuData).map(([cuisine, items]) => (
          <div key={cuisine}>
            <h2 className="text-[16px] font-semibold text-[#7A7474] uppercase my-3">
              {cuisine}
            </h2>
            <div className="grid grid-cols-2 gap-[25px]">
              {items.map((data, index) => (
                <div key={index}>
                  <div className="flex border border-[#E1E2E5] border-l-0 rounded-[20px]">
                    <div>
                      <img
                        src={s3ImageRestaurant + data?.images[0]}
                        alt="roomImage"
                        className="!w-[149px] !h-[126px] object-center rounded-tl-[20px] rounded-bl-[20px]"
                      />
                    </div>
                    <div className="p-[10px] flex flex-col justify-between flex-1">
                      <div>
                        <div className="flex gap-1 items-center justify-between">
                          <h6 className="mb-0 text-Main text-sm font-extrabold">
                            {data.name}
                          </h6>
                          <div>
                            {data.is_like === true ? (
                              <button
                                type="button"
                                onClick={() => onClickLike(data.menu_id, index)}
                              >
                                <img alt="" src={Images.LikeFill_blue} />
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={() => onClickLike(data.menu_id, index)}
                              >
                                <img alt="" src={Images.Feed_UnLike} />
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="flex gap-1 items-center py-2">
                          <img src={Images.spoon_icon} alt="a" />
                          <div className="flex items-center gap-1">
                            <h6 className="mb-0 text-Colormain text-[10px]">
                              {data.cuisine}
                            </h6>
                          </div>
                        </div>
                        <div className="flex gap-1 items-center">
                          <img src={Images.money_icon} alt="a" />
                          <div className="flex items-center gap-1">
                            <h6 className="mb-0 text-Colormain text-[10px]">
                              €
                              {data.price}
                            </h6>
                          </div>
                        </div>
                        <div className="flex justify-end items-center bg-PrimaryBg w-fit ms-auto gap-1 rounded-[999px] !py-1 !px-2">
                          <img src={Images.Fire_icon_blue} alt="a" />
                          <div className="flex items-center gap-1">
                            <h6 className="mb-0 text-[10px] font-extrabold bg-primaryGradient bg-clip-text text-transparent">
                              DEAL
                            </h6>
                          </div>
                        </div>
                        <h6 className="text-[20px] font-extrabold uppercase">
                          {data.discount_name}
                        </h6>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2">
                          <labe className="mb-0 text-Colormain text-[10px] font-medium">
                            See Details
                          </labe>
                        </div>
                        <img
                          className="rotate-180"
                          src={Images.left_arrow_icon}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }, [menuListData])

  const handlePageChange = (pageNumber) => {
    RestaurantMenuGetData(pageNumber)
    setCurrentPage(pageNumber)
  }

  const initialized = useRef(false)

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      RestaurantMenuGetData(currentPage)
    }
  }, [])

  return (
    <div className="">
      <div className="position-relative">
        {menuListData.length === 0 ? (
          <div className="add_room_text">
            <div className="add_room_text">
              <h4>No Menu Available</h4>
            </div>
          </div>
        ) : (
          <div className="local-room-main">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h3 className="uppercase pe-2 mb-0">{English.G363}</h3>
            </div>
            {renderTable}
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default MenuShow

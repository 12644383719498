/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable max-len */
import React, {
  useEffect,
  useState
} from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import './AddProduct.css'
import { useSelector } from 'react-redux'
import { emitter, EventType } from '../../helpers/Emitter'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'

// const review = [
//   {
//     id: 1,
//     name: 'Kathly parry',
//     profile: Images.profileStarIcon,
//     date: '2025-02-05',
//     ratting: 5,
//     review: 'this is classinfd day classical data this is no more about the classic absoved '
//   },
//   {
//     id: 2,
//     name: 'Kathly parry',
//     profile: Images.profileStarIcon,
//     date: '2025-02-02',
//     ratting: 2,
//     review: 'this is classinfd day classical data this is no more about the classic absoved '
//   },
//   {
//     id: 4,
//     name: 'Kathly parry',
//     profile: Images.profileStarIcon,
//     date: '2025-02-02',
//     ratting: 0,
//     review: 'this is classinfd day classical data this is no more about the classic absoved '
//   },
//   {
//     id: 4,
//     name: 'Kathly parry',
//     profile: Images.profileStarIcon,
//     date: '2025-02-02',
//     ratting: 1,
//     review: 'this is classinfd day classical data this is no more about the classic absoved this is classinfd day classical data this is no more about the classic absoved  this is classinfd day classical data this is no more about the classic absoved this is classinfd day classical data this is no more about the classic absoved '
//   },
//   {
//     id: 5,
//     name: 'Kathly parry',
//     profile: Images.profileStarIcon,
//     date: '2025-02-02',
//     ratting: 3.5,
//     review: 'this is classinfd day classical data this is no more about the classic absoved this is classinfd day classical data this is no more about the classic absoved  this is classinfd day classical data this is no more about the classic absoved this is classinfd day classical data this is no more about the classic absoved '
//   },
//   {
//     id: 6,
//     name: 'Kathly parry',
//     profile: Images.profileStarIcon,
//     date: '2025-02-02',
//     ratting: 4.5,
//     review: 'this is classinfd day classical data this is no more about the classic absoved this is classinfd day classical data this is no more about the classic absoved  this is classinfd day classical data this is no more about the classic absoved this is classinfd day classical data this is no more about the classic absoved '
//   }
// ]

const LatestReviews = () => {
  const app_id = useSelector((state) => state.user?.app_id)

  const [trendingProduct, setTrendingProduct] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [viewAll, setViewAll] = useState(false)

  const getTrending = async () => {
    setIsLoading(true)
    setTrendingProduct([])
    const platFormData = {
      app_id: app_id.id
    }
    APICall('post', platFormData, EndPoints.getTrendingProduct, 'store')
    .then((res) => {
      if (res.status === 200 && res.data?.code === 200) {
        // setTrendingProduct(res?.decryptedData)
        setIsLoading(false)
      } else {
        toast.error(res.data?.message)
        setIsLoading(false)
      }
    })
    .catch((error) => {
      toast.error(error?.data?.message)
      setIsLoading(false)
    })
  }

  const [localStorageValue, setLocalStorageValue] = useState(app_id || null)

  useEffect(() => {
    const event = emitter.addListener(EventType.ProductLibraryDelete, ({ product_id, action }) => {
      if (action === 'delete') {
        const temp = [...trendingProduct]
        const newData = temp.filter((item) => item.id !== product_id)
        setTrendingProduct(newData)
      } else {
        getTrending()
      }
    })
    return () => event.remove()
  }, [trendingProduct])

  useEffect(() => {
    const handleStorageChange = () => {
      setLocalStorageValue(app_id || null)
    }

    window.addEventListener('storage', handleStorageChange)

    return () => window.removeEventListener('storage', handleStorageChange)
  }, [app_id])

  useEffect(() => {
    if (localStorageValue !== null) {
      getTrending()
    }
  }, [localStorageValue])

  return (
    <div className="content-manager-awards-wrapper add-profile-video">
      <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
        <div className="tag_link mb-0 flex-1 flex-row">
          <h2>{English.B78}</h2>
        </div>
        {trendingProduct.length >= 4 && <div className="cursor-pointer" onClick={() => setViewAll(!viewAll)}>
          <h2>{viewAll ? English.B84 : English.A141}</h2>
        </div>}
      </div>
      {trendingProduct?.length === 0 && !isLoading && (
        <CommonEmptyData
          className="w-full !border-solid"
          image={Images.latestReview}
          mainTitle={English.B80}
          descriptionText={English.B81}
          classNameDescription="!text-textSecondary !text-[16px] !font-normal"
        />
      )}
      <div className="grid grid-cols-4 gap-6">
        {isLoading && trendingProduct?.length === 0 ? (
          <div className="awards-item">
            <div className="add-profile-video-cover add-product-video-cover">
              <button type="button">
                <img src={Images.Plus} alt="add library" />
              </button>
            </div>
            <div className="product-footer">
              <p className="add-value">
                <Skeleton className="add-value" width={202} height={35} />
              </p>
              <span className="add-value">
                <Skeleton className="add-value" width={67} height={35} />
              </span>
            </div>
          </div>
        ) : (
          trendingProduct?.slice(0, (viewAll ? trendingProduct.length : 4))?.map((data, i) => {
            return (
              <div key={`${data.id} + ${i}`} className="position-relative !w-[320px] !h-[220px] rounded-xl p-4 overflow-hidden border-[1px] border-BorderLight">
                <div className="flex flex-1 flex-row items-center gap-3">
                  <img src={data.profile} alt={data.profile} className="w-[60px] h-[60px] p-1 bg-primaryGradient rounded-full" />
                  <div className="flex-col">
                    <div className="text-[16px] font-semibold text-secondary">{data.name}</div>
                    <div className="text-[16px] font-normal text-[#8D8D8D]">{data.date}</div>
                  </div>
                </div>
                <div className="flex flex-row relative gap-1 items-center my-2">
                  <img className="w-[20px]" src={data.ratting >= 1 ? Images.FillStar : Images.EmptyStar} alt="star" />
                  <img className="w-[20px]" src={data.ratting >= 2 ? Images.FillStar : Images.EmptyStar} alt="star" />
                  <img className="w-[20px]" src={data.ratting >= 3 ? Images.FillStar : Images.EmptyStar} alt="star" />
                  <img className="w-[20px]" src={data.ratting >= 4 ? Images.FillStar : Images.EmptyStar} alt="star" />
                  <img className="w-[20px]" src={data.ratting >= 5 ? Images.FillStar : Images.EmptyStar} alt="star" />
                  <div className="leading-none font-medium text-[16px] ml-2">{data.ratting}</div>
                </div>
                <p className="text-[14px] font-normal text-secondary !leading-[1] line-clamp-5 overflow-hidden">{data.review}</p>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}
export default LatestReviews

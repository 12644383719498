/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react'
import '../HotelComponent/Discount.css'
import './Event.css'
import { Image, Table } from 'antd'
import { toast } from 'react-toastify'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { s3ImageOrganizer } from '../../../services/Service'
import Pagination from '../../Pagination/Pagination'
import English from '../../../helpers/English'
import { APICall, EndPoints } from '../../../services'
import TicketsShow from './TicketsShow'
import RatingsReviews from '../RatingReview'
import Guest from './guest'
import { Images } from '../../../helpers'

const EventShow = ({ isOrganizerId, latitude, longitude }) => {
  const [isAddRoom, setIsAddRoom] = useState(false)
  const [eventListData, setEventListData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [eventId, setEventId] = useState()

  const [particularEvent, setParticularEvent] = useState([])

  const showEvent = (record) => {
    setIsAddRoom(true)
    particularEventData(record.event_id)
    setEventId(record.event_id)
  }

  const formatViews = (views) => {
    if (views >= 1_000_000_000) {
      return `${(views / 1_000_000_000).toFixed(1)}B`
    }
    if (views >= 1_000_000) {
      return `${(views / 1_000_000).toFixed(1)}M`
    }
    if (views >= 1_000) {
      return `${(views / 1_000).toFixed(2)}k`
    }
    return views.toString()
  }

  const columns = [
    {
      title: 'EVENT NAME & IMAGE',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="image_name">
          <Image
            width={100}
            src={s3ImageOrganizer + record.images[0]}
            alt="img"
            style={{ marginRight: 10 }}
            preview={false}
          />
          <span>
            {record.name.length > 15
              ? `${record.name.substring(0, 15)}...`
              : record.name}
          </span>
        </div>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
      className: 'custom-column'
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a.date.localeCompare(b.date)
    },
    {
      title: 'TIMING',
      dataIndex: 'timing',
      key: 'timing',
      sorter: (a, b) => a.start_time.localeCompare(b.start_time),
      render: (text, record) => {
        const formatTime = (time) => time.slice(0, 5)
        return `${formatTime(record.start_time)} - ${formatTime(record.end_time)}`
      }
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'industry_type',
      key: 'industry_type',
      sorter: (a, b) => a.industry_type.localeCompare(b.industry_type)
    },
    {
      title: 'TICKET QTY',
      dataIndex: 'tickets_qty',
      key: 'tickets_qty',
      sorter: (a, b) => a.tickets_qty - b.tickets_qty,
      render: (text) => formatViews(text)
    },
    {
      title: 'SPEAKERS',
      dataIndex: 'performer_details',
      key: 'Speakers',
      render: (performer_details) => {
        const visiblePerformers = performer_details.slice(0, 3)
        const hiddenPerformersCount =
          performer_details.length - visiblePerformers.length

        return (
          <div className="follower-shows">
            <div className="performer-img-group d-flex">
              {visiblePerformers.map((performer, index) => (
                <img
                  key={performer.performer_id}
                  alt={`speaker ${index + 1}`}
                  src={s3ImageOrganizer + performer.performer_profile_pic}
                  className="w-10 h-10 rounded-full border-2 border-white shadow-sm"
                />
              ))}
              {hiddenPerformersCount > 0 && (
                <div className="w-[30px] h-[30px] rounded-full flex items-center ml-[-6px] justify-center bg-[#01104D] from-gray-800 to-blue-900 text-white text-sm font-semibold">
                  +
                  {hiddenPerformersCount}
                </div>
              )}
            </div>
          </div>
        )
      },
      sorter: (a, b) => a.performer_details.length - b.performer_details.length
    }
  ]

  const filteredData = eventListData?.filter((item) => item.name.toLowerCase())

  const allEventData = (currentPage) => {
    const isEventPath = window.location.pathname === '/event'
    const payload = {
      organizer_id: isOrganizerId,
      ...(isEventPath && { app_id: 2 })
    }
    APICall(
      'post',
      payload,
      `${EndPoints.getEvent}?page=${currentPage}`,
      'local'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setEventListData(res?.decryptedData.data)
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const particularEventData = async (event_id) => {
    // setEventID(event_id)
    const payload = {
      organizer_id: isOrganizerId,
      event_id
    }
    APICall('post', payload, `${EndPoints.particularEvent}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setParticularEvent(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handlePageChange = (pageNumber) => {
    allEventData(pageNumber)
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    allEventData(currentPage)
  }, [])

  return (
    <div className="">
      <div className="statistic-chart-container position-relative">
        {eventListData?.length === 0 ? (
          <div className="add_room_text">
            <h3 className="uppercase pe-2">No Events</h3>
          </div>
        ) : !isAddRoom ? (
          <div>
            <div className="local-room-main">
              <h3 className="d-flex align-items-center justify-content-between uppercase italic">
                {English.G306}
              </h3>
              <div className="discount_list">
                <div className="discount_list">
                  <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={false}
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          showEvent(record)
                        }
                      }
                    }}
                  />
                </div>
                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="position-relative local-tabs section-local event-tab">
            <button
              type="button"
              className="back-button"
              onClick={() => setIsAddRoom(false)}
            >
              <img src={Images.Black_back} alt="" />
            </button>
            <Tabs>
              <div className="list_overview">
                <TabList>
                  <Tab>
                    <span>Tickets</span>
                  </Tab>
                  <Tab>
                    <span>Guest</span>
                  </Tab>
                  <Tab>
                    <span>Ratings</span>
                  </Tab>
                </TabList>
              </div>

              <TabPanel>
                <TicketsShow
                  isOrganizerId={isOrganizerId}
                  latitude={latitude}
                  longitude={longitude}
                  eventId={eventId}
                />
              </TabPanel>
              <TabPanel>
                <Guest ParticularEvent={particularEvent} />
              </TabPanel>
              <TabPanel>
                <RatingsReviews />
              </TabPanel>
            </Tabs>
          </div>
        )}
      </div>
    </div>
  )
}
export default EventShow

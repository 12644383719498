import React, { useCallback, useEffect, useRef, useState } from 'react'
import { s3ImageProfile } from '../../../services/Service'
import Utility from '../../../helpers/Utility'
import { English } from '../../../helpers'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import ReplyInput from './ReplyInput'
import ChatContent from './ChatContent'
import useClickOutside from '../../../hooks/useClickOutside'

const CommentCard = ({
  created_at,
  is_like,
  main_id,
  userName,
  profile_icon,
  total_like,
  comment,
  onPressSend,
  setTextContent,
  textContent,
  handleCommentLike,
  isSubReply
}) => {
  const [activeReplyId, setActiveReplyId] = useState(null)
  const replyInputRef = useRef(null)
  const [isLiked, setIsLiked] = useState(is_like)

  const closeReplyInput = useCallback(() => {
    setTimeout(() => {
      setActiveReplyId(null)
    }, 200)
  }, [])

  useEffect(() => {
    setIsLiked(is_like)
  }, [is_like])

  const handleReplyClick = useCallback(
    (id) => {
      setActiveReplyId(null)
      setTimeout(() => {
        setActiveReplyId(id)
        setTextContent(id, '')
      }, 300)
    },
    [setTextContent]
  )

  useClickOutside({
    refArray: [replyInputRef],
    onPressOutside: closeReplyInput
  })

  return (
    <div className="flex flex-col gap-7 overflow-y-auto pe-2">
      <div key={main_id}>
        <ChatContent
          profile_icon={profile_icon && s3ImageProfile + profile_icon}
          user_name={userName}
          comment={comment}
          handleLikeComment={() => handleCommentLike(main_id)}
          isLiked={isLiked}
          likeCount={total_like}
        />
        <div className="flex w-full items-center justify-between mt-0.5 pb-3.5">
          <span
            className={`text-textLight font-thin text-sm pb-0 ${activeReplyId !== main_id ? '' : 'hidden'}`}
          >
            {Utility.calculateTimePeriod(created_at)}
          </span>
          {!isSubReply && (
            <CommonButton
              type="button"
              label={English.G150}
              disabled={false}
              context=""
              className={`${activeReplyId !== main_id ? '' : 'hidden'} !pb-0 !bg-transparent !capitalize !border-none`}
              spanClassName="!text-brandText !text-sm !not-italic"
              onClick={() => handleReplyClick(main_id)}
            />
          )}
        </div>
        {!isSubReply && (
          <ReplyInput
            textContent={textContent}
            setTextContent={setTextContent}
            id={textContent.id}
            onPressSend={() => {
              setActiveReplyId(null)
              onPressSend()
            }}
            ref={replyInputRef}
            className={`${activeReplyId === main_id ? '' : 'hidden'}`}
          />
        )}
      </div>
    </div>
  )
}

export default CommentCard

import React from 'react'
import { Images } from '../../helpers'

const reviews = [
  {
    id: 1,
    name: 'Andre Miles',
    rating: 4.5,
    review:
      'Rarely seen such a cool sweepstakes, but Lacoste has really created a cool sweepstakes',
    time: '5 days ago',
    image: Images.Friend_image1
  },
  {
    id: 2,
    name: 'Davina Parker',
    rating: 4.5,
    review:
      'I really want to win it for my friend as a surprise. He is crazy about Lacoste',
    time: '4 days ago',
    image: Images.Friend_image2
  },
  {
    id: 3,
    name: 'Mary K.',
    rating: 4.5,
    review: 'I just love this brand! Hopefully, luck is on my side',
    time: '3 days ago',
    image: Images.Friend_image3
  },
  {
    id: 4,
    name: 'Andre Miles',
    rating: 4.5,
    review:
      'Rarely seen such a cool sweepstakes, but Lacoste has really created a cool sweepstakes',
    time: '5 days ago',
    image: Images.Friend_image1
  },
  {
    id: 5,
    name: 'Davina Parker',
    rating: 4.5,
    review:
      'I really want to win it for my friend as a surprise. He is crazy about Lacoste',
    time: '4 days ago',
    image: Images.Friend_image2
  },
  {
    id: 6,
    name: 'Mary K.',
    rating: 4.5,
    review: 'I just love this brand! Hopefully, luck is on my side',
    time: '3 days ago',
    image: Images.Friend_image3
  }
]
const ratings = [
  { stars: 5, percentage: 88 },
  { stars: 4, percentage: 7 },
  { stars: 3, percentage: 5 },
  { stars: 2, percentage: 2 },
  { stars: 1, percentage: 1 }
]

const RatingsReviews = () => {
  return (
    <div className="">
      <div className="border border-SecondaryBorder rounded-[14px] !p-5">
        <h3 className="text-[#0B0A0A] font-black text-lg italic mb-0">
          OVERALL RATINGS
        </h3>
        <div className="text-center w-full flex items-center gap-12">
          <div className="flex items-center !gap-5">
            <p className="text-[48px] italic font-black text-[#303030] leading-none">4.5</p>
            <div>
              <div className="flex justify-center gap-[6px] mb-2">
                {[...Array(5)].map((_, index) => (
                  <span key={index} className="bg-primaryGradient bg-clip-text text-transparent font-black text-xl leading-none">
                    ★
                  </span>
                ))}
              </div>
              <p className="text-gray-500">1145 ratings</p>
            </div>
          </div>

          <div className="flex-col flex-1">
            {ratings.map((rating) => (
              <div
                key={rating.stars}
                className="items-center mb-2 w-full flex gap-2"
              >
                <span className="text-base font-black text-Main">{rating.stars}</span>
                <div className="relative w-full h-2 bg-gray-200 rounded-full mx-2">
                  <div
                    className="h-2 bg-[#1a1f87] rounded-l-full"
                    style={{ width: `${rating.percentage}%` }}
                  />
                  <div
                    className="h-2 bg-[#E1E2E5] rounded-r-full absolute top-0"
                    style={{ left: `${rating.percentage}%`, width: `${100 - rating.percentage}%` }}
                  />
                </div>
                <span className="text-sm">
                  {rating.percentage}
                  %
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="border border-SecondaryBorder rounded-[14px] !p-5 mt-3.5">
        <div className="w-full">
          <h3 className="text-lg text-Main font-black italic mb-7">REVIEWS</h3>
          {reviews.map((review) => (
            <div
              key={review.id}
              className="pb-3 mb-3 last:border-none last:pb-0 last:mb-0"
            >
              <div className="flex gap-3">
                <div className="">
                  <img className="w-[36px] h-[36px]" src={review.image} alt="" />
                </div>
                <div className="w-full">
                  <div className="flex items-center justify-between w-full">
                    <h4 className="text-[16px] font-bold text-[#151515] mb-0">
                      {review.name}
                    </h4>
                    <p className="text-sm text-textSecondary mb-0">{review.time}</p>
                  </div>
                  <div className="flex items-center mb-2">
                    {[...Array(5)].map((_, index) => (
                      <span key={index} className="text-[#FFD739] text-xl">
                        ★
                      </span>
                    ))}
                    <span className="ml-2 text-sm font-semibold">
                      {review.rating}
                    </span>
                  </div>
                  <p className="text-[#4E4949] font-normal text-[16px]">
                    {review.review}
                  </p>
                  <div className="mt-2 flex gap-4 text-blue-500 text-sm">
                    <button type="button" className="flex items-center gap-[6px]">
                      <img src={Images.like_icon} alt="" />
                      <span className="font-normal text-[14px]">Like</span>
                    </button>
                    <button type="button" className="flex items-center gap-[6px]">
                      <img src={Images.reply_icon} alt="" />
                      <span className="font-normal text-[14px]">Reply</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-4 flex items-center gap-3.5">
          <img
            src={Images.Friend_image3}
            alt="User"
            className="w-12 h-12 rounded-full mr-3 border !border-[#C7D5E0]"
          />
          <input
            type="text"
            placeholder="Type here to reply..."
            className="w-full outline-none bg-headerbg rounded-[35px] h-12 px-7"
          />
        </div>
      </div>
    </div>
  )
}

export default RatingsReviews

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState } from 'react'
import './Store.css'
import { Link } from 'react-router-dom'
import { urlImageProductPathStore } from '../../services/Service'
import { English, Images } from '../../helpers'
import { emitter, EventType } from '../../helpers/Emitter'
import ReviewsSection from '../Local/EventComponent/RatingCards'

const ParticularProductShow = ({ ProductDetails, onBack }) => {
  const [expandedIndex, setExpandedIndex] = useState(null)
  const getStoreLink = (storeLink) => {
    if (!storeLink) return undefined
    return storeLink.startsWith('http') ? storeLink : `http://${storeLink}`
  }

  const clickOnEdit = (data) => {
    emitter.emit(EventType.editStore, {
      product_id: data,
      action: 'true'
    })
  }

  return (
    <div className="feed-container d-block bg-white rounded-[15px] !px-5">
      <div className="flex items-center !gap-3 link_click bg-white">
        <Link onClick={onBack} className="text-[#4E4949] text-base font-medium">
          Home
        </Link>
        <img src={Images.breadcrumbAerrow} alt="" />
        <Link onClick={onBack} className="text-[#4E4949] text-base font-medium">
          Product
        </Link>
        <img src={Images.breadcrumbAerrow} alt="" />
        {ProductDetails?.name && (
          <Link
            to=""
            className="bg-primaryGradient bg-clip-text text-transparent text-base font-bold"
          >
            {ProductDetails?.name}
          </Link>
        )}
      </div>
      <div className="">
        <div className="flex justify-between items-center">
          <div className="flex gap-2.5 items-center">
            <h1 className="text-[24px] font-black italic !pe-2 bg-primaryGradient bg-clip-text text-transparent uppercase !mb-0">
              {ProductDetails?.name}
            </h1>
            <span className="px-2.5 py-1 bg-[#FFD694] text-sm font-semibold rounded-full border border-[#FFBBBC]">
              Draft
            </span>
          </div>
          <div>
            <button
              className="uppercase italic secondary-btn-model-store"
              type="button"
              onClick={() => clickOnEdit(ProductDetails)}
            >
              <span className="!pe-2">Edit</span>
            </button>
          </div>
        </div>
        <div className="flex !mt-5 gap-7">
          <div className="w-[500px] aspect-square max-[1440px]:w-[350px]">
            <div className="giveaways-container !items-start !ms-0 h-full">
              <div className="giveaways-thumbnails h-full">
                {ProductDetails?.images?.slice(1, 4).map((img, idx) => (
                  <img
                    key={idx}
                    src={urlImageProductPathStore + img.image}
                    alt={`Thumbnail ${idx + 1}`}
                    className="thumbnail w-[120px] !h-auto aspect-[0.76/1]"
                  />
                ))}
              </div>

              {/* Main Image */}
              <div className="giveaways-item-cover h-full w-[360px]">
                {ProductDetails?.images?.slice(0, 1).map((img, idx) => (
                  <img
                    key={idx}
                    src={urlImageProductPathStore + img.image}
                    alt="giveaway"
                    className="!w-full !h-full object-cover"
                  />
                ))}
              </div>
            </div>
          </div>

          <div>
            <h2 className="font-black text-Main italic uppercase !mb-2 text-lg">Product Details</h2>
            <p className="text-Main text-base mb-2.5">
              {expandedIndex === 1
                ? ProductDetails?.description
                : `${ProductDetails?.description?.slice(0, 360)}`}
              {ProductDetails?.description?.length > 360 && (
                <span
                  className="see_more_local"
                  onClick={(e) => {
                    e.stopPropagation()
                    setExpandedIndex(expandedIndex === 1 ? null : 1)
                  }}
                >
                  ...
                  {' '}
                  {expandedIndex === 1 ? English.G193 : English.G194}
                </span>
              )}
            </p>
            <p className="!mb-2 text-lg font-black italic uppercase">Booking Link</p>
            <div className="">
              <a
                className="text-Main text-base"
                target="_blank"
                href={getStoreLink(ProductDetails?.product_link)}
                rel="noreferrer"
              >
                {ProductDetails?.product_link}
              </a>
            </div>
            <p className="text-[32px] italic text-Main font-extrabold mb-0 mt-7">
              €
              {' '}
              {ProductDetails?.regular_price}
            </p>
          </div>
        </div>
      </div>

      <div className="">
        <ReviewsSection />
      </div>
    </div>
  )
}
export default ParticularProductShow

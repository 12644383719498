import React from 'react'
import { Images } from '../../helpers'
// import { emitter, EventType } from '../../helpers/Emitter'

const ProfileHeader = ({ onClick }) => {
  const onEditProfile = () => {
    // emitter.emit(EventType.editProfileModal)
  }

  return (
    <div className="flex items-center justify-between bg-white border border-b-PrimaryBorder border-b-[0.5px] py-2.5 px-10">
      <div className="flex items-center gap-4 w-full justify-between">
        <span onClick={onEditProfile} className="font-black text-base italic">EDIT PROFILE</span>
        <div className="view-button">
          <button type="button" className="active">
            <img className="mx-auto" alt="" src={Images.Desk} />
          </button>
          <button type="button">
            <img className="mx-auto" alt="" src={Images.Tab} />
          </button>
          <button type="button">
            <img className="mx-auto" alt="" src={Images.Mob} />
          </button>
          <button type="button">
            <img className="mx-auto" alt="" src={Images.View} />
          </button>
        </div>
        <div className="flex items-center gap-4">
          <button
            type="button"
            className="primary-btn"
          >
            PUBLISH
          </button>
          <button type="button" className="text-black text-lg" onClick={onClick}>✖</button>
        </div>
      </div>

    </div>
  )
}
export default ProfileHeader

/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { LoadScriptNext } from '@react-google-maps/api'
import { toast } from 'react-toastify'
import { English, Images } from '../../../helpers'
import CommonInput from '../../InputComponet/CommonInput'
import Dropdown from '../../Dropdown/Dropdown'
import AutocompleteInput from '../../MapComponent/AutoComplete'
import { GOOGLE_MAP_LIBRARIES } from '../../../services/Service'
import MapComponent from '../../MapComponent/MapComponent'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import { APICall, EndPoints } from '../../../services'
import '../../Dropdown/Dropdown.css'

const LocationPopup = ({
  defaultData = {},
  inputFields,
  setInputFields,
  onCancel,
  onSubmit,
  locationSuggestion,
  markerPosition,
  onMapPositionChange,
  disabled
}) => {
  // api call

  const [isCountryList, setIsCountryList] = useState([])
  const [isProvinceList, setIsProvinceList] = useState([])
  const [isCityListData, setIsCityListData] = useState([])

  const countryListData = () => {
    APICall('get', '', `${EndPoints.countryList}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsCountryList(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const provinceListData = (data) => {
    APICall('post', data, `${EndPoints.provinceList}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsProvinceList(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const cityListData = (data) => {
    APICall('post', data, `${EndPoints.cityList}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsCityListData(res?.decryptedData.data)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const getCountryName = (country_id) => {
    const categoryObj = isCountryList.find(
      (cat) => cat.country_id === country_id
    )
    return categoryObj ? categoryObj.country_name : English.G408
  }
  const getProvinceName = (province_id) => {
    const categoryObj = isProvinceList.find(
      (cat) => cat.province_id === province_id
    )
    return categoryObj ? categoryObj.province_name : English.G409
  }
  const getCityName = (city_id) => {
    const categoryObj = isCityListData.find((cat) => cat.city_id === city_id)
    return categoryObj ? categoryObj.city_name : English.G272
  }

  const handleStartStep2 = () => {
    onSubmit()
  }

  useEffect(() => {
    countryListData()
  }, [])

  useEffect(() => {
    if (Object.keys(defaultData).length > 0) {
      setInputFields(defaultData)
      provinceListData({
        country_id: defaultData.country_id
      })
      cityListData({
        province_id: defaultData.province_id
      })
    }
  }, [defaultData])

  return (
    <div className="add-item-model">
      <div className="add-item-content local_type_modal !max-w-[1200px] !m-5">
        <div className="flex items-center justify-between local_sticky_header">
          <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
            <img
              className="cursor-pointer"
              onClick={onCancel}
              src={Images.BackAerrow}
              alt="Back"
            />
            Add Location
          </div>
          <button
            className="cancel_icon border-0 p-0"
            type="button"
            onClick={onCancel}
          >
            <img src={Images.RemoveLocal} alt="Close" />
          </button>
        </div>

        <div className="flex items-center justify-between bg-[#E4E4E480] rounded-[10px] py-2.5 !px-5 mt-6 mb-3">
          <div className="text-main test-base font-bold">Location Details</div>
          <div className="bg-primaryGradient bg-clip-text text-transparent text-sm flex gap-1 items-end leading-none">
            <span className="font-black text-xl leading-none italic">02</span>
            {' '}
            of 04
          </div>
        </div>

        <div className="item-details-input !pt-5 local_contact_scroll_bars">
          <div className="grid grid-cols-12 !gap-5">
            <div className="col-span-5">
              <label>
                {English.A198}
              </label>
              <CommonInput
                placeholder={English.A199}
                name="location_title"
                onChange={(e) => setInputFields({
                    ...inputFields,
                    location_title: e.target.value
                  })}
                // allowTextOnly
                // maxLength={30}
                value={inputFields.location_title}
              />
              <div className="!mb-5">
                <label>{English.G309}</label>
                <Dropdown
                  title={getCountryName(inputFields.country_id)}
                  name="type_id"
                  onChanged={(d) => {
                    provinceListData({
                      country_id: d.id
                    })
                    setInputFields((prev) => ({
                      ...prev,
                      country_id: d.id,
                      country: d.title
                    }))
                  }}
                  Data={isCountryList.map((d) => ({
                    title: d.country_name,
                    id: d.country_id
                  }))}
                  customClass={
                    inputFields.country_id ? 'selected-class' : 'local-dropdown'
                  }
                  customClassHeight="dropdown-height-medium"
                />
              </div>
              <div className="!mb-5">
                <div className="!mb-5">
                  <label>{English.G311}</label>
                  <Dropdown
                    title={getProvinceName(inputFields.province_id)}
                    name="type_id"
                    onChanged={(d) => {
                      cityListData({
                        province_id: d.id
                      })
                      setInputFields((prev) => ({
                        ...prev,
                        province_id: d.id,
                        province_name: d.title,
                        city: '',
                        city_name: ''
                      }))
                    }}
                    Data={isProvinceList.map((d) => ({
                      title: d.province_name,
                      id: d.province_id
                    }))}
                    customClass={
                      inputFields.province_id
                        ? 'selected-class'
                        : 'local-dropdown'
                    }
                    customClassHeight="dropdown-height-medium"
                  />
                </div>
                <div>
                  <label>City</label>
                  <Dropdown
                    title={getCityName(inputFields.city_id)}
                    name="type_id"
                    onChanged={(d) => {
                      setInputFields({
                        ...inputFields,
                        city_id: d.id,
                        city_name: d.title
                      })
                    }}
                    Data={isCityListData.map((d) => ({
                      title: d.city_name,
                      id: d.city_id
                    }))}
                    customClass={
                      inputFields.city_id ? 'selected-class' : 'local-dropdown'
                    }
                    customClassHeight="dropdown-height-small"
                  />
                </div>
              </div>

              <LoadScriptNext
                googleMapsApiKey={locationSuggestion}
                libraries={GOOGLE_MAP_LIBRARIES}
              >
                <div className="form-group">
                  <label>{English.G187}</label>
                  <AutocompleteInput
                    setInputFields={setInputFields}
                    inputFields={inputFields}
                  />
                </div>
              </LoadScriptNext>

              <div className="!mt-5">
                <label>{English.G313}</label>
                <CommonInput
                  placeholder={English.A246}
                  name="zip_code"
                  type="number"
                  className="mb-0"
                  onChange={(e) => setInputFields({ ...inputFields, zip_code: e.target.value })}
                  pattern="^\d{0,5}$"
                  maxLength={5}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault()
                    }
                    if (e.target.value.length === 5) {
                      e.preventDefault()
                    }
                  }}
                  value={inputFields.zip_code}
                />
              </div>
            </div>

            <div className="col-span-7">
              <div className="form-group h-[calc(80vh-245px)] sticky top-0 rounded-[15px] overflow-hidden">
                <LoadScriptNext
                  googleMapsApiKey={locationSuggestion}
                  libraries={GOOGLE_MAP_LIBRARIES}
                >
                  <MapComponent
                    inputFields={inputFields}
                    setInputFields={setInputFields}
                    onMapPositionChange={onMapPositionChange}
                    markerPosition={markerPosition}
                  />
                </LoadScriptNext>
              </div>
            </div>
          </div>
        </div>

        <div className="local_button_row local_sticky_btn mb-0 mt-0 !justify-end">
          {/* <CommonButton
            label={English.G116}
            onClick={onCancel}
            isPrimary={false}
            context={English.A197}
          /> */}
          <CommonButton
            label={English.G69}
            type="button"
            isPrimary
            onClick={handleStartStep2}
            context={English.A197}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  )
}

export default LocationPopup

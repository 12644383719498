import React from 'react'
import { s3ImageGiveaway } from '../../services/Service'

const GiveawayImage = ({ inputField }) => {
  console.log('inputField', inputField)

  return (
    <div className="flex flex-col gap-[20px]">
      <div className="w-full">
        <img
          src={
                typeof inputField[0] === 'string'
                  ? s3ImageGiveaway + inputField[0]
                  : URL.createObjectURL(inputField[0])
              }
          alt="Main Preview"
          className="w-[500px] h-[300px] object-cover rounded-lg border"
        />
      </div>

      <div className="flex gap-[14px] overflow-x-auto">
        {inputField.slice(1, 4).map((file, index) => (
          <div
            key={index}
            className="flex-shrink-0 w-[157px] h-[120px] border rounded-lg overflow-hidden hover:ring-2 hover:ring-blue-400 transition-all"
          >
            <img
              src={
                typeof file === 'string'
                  ? s3ImageGiveaway + file
                  : URL.createObjectURL(file)
              }
              alt={`Thumbnail ${index + 1}`}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default GiveawayImage

/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import React, {
  useEffect,
  useState
} from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import './AddProduct.css'
import { useSelector } from 'react-redux'
import { lockScroll, unlockScroll } from '../../helpers/Utility'
import CommonButton from '../ButtonComponent/ButtonCommon'
import { emitter, EventType } from '../../helpers/Emitter'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import ProductItem from './Component/ProductItem'
import AddDealModal from './AddDealModal'
import CommonEmptyData from '../CommonNoDataModal/CommonEmptyData'

const LatestDeal = () => {
  const app_id = useSelector((state) => state.user?.app_id)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [ShowLibraryConfirmModel, setShowLibraryConfirmModel] = useState(false)
  const [ShowProduct, setShowProduct] = useState(false)
  const [EditProduct, setEditProduct] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)

  const [trendingProduct, setTrendingProduct] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const AddProducts = () => {
    setShowProduct(true)
  }

  const HandleCancelConfirm = () => {
    setShowConfirmModel(false)
    setShowLibraryConfirmModel(false)
  }
  const getTrending = async () => {
    setIsLoading(true)
    const platFormData = {
      app_id: app_id.id
    }
    APICall('post', platFormData, EndPoints.userllDiscountProducts, 'store')
    .then((res) => {
      if (res.status === 200 && res.data?.code === 200) {
        setTrendingProduct(res?.decryptedData.data)
        setIsLoading(false)
      } else {
        toast.error(res.data?.message)
        setIsLoading(false)
      }
    })
    .catch((error) => {
      toast.error(error?.data?.message)
      setIsLoading(false)
    })
  }

  const deleteTrending = (data) => {
    const platFormData = {
      app_id: app_id.id,
      delete_trending_products: [data.id]
    }
    setLoading(true)
    APICall('post', platFormData, EndPoints.deleteTrendingProduct, 'store')
    .then((res) => {
      if (res.status === 200 && res.data?.code === 200) {
        setTimeout(() => {
          getTrending()
          setLoading(false)
          setShowConfirmModel(false)
          toast.success(res?.data?.message)
        }, 1500)
      } else {
        toast.error(res.data?.message)
        setLoading(false)
      }
    })
    .catch((error) => {
      toast.error(error?.data?.message)
      setLoading(false)
    })
  }

  const trendingProductLikeData = (data) => {
    const temp = [...trendingProduct]
    temp.forEach((item, index) => {
      if (item.id === data.id) {
        temp[index].is_like = !item.is_like
      } else {
        temp[index].is_like = item.is_like
      }
    })
    shotLikeData(data.id)
    setTrendingProduct(temp)
  }

  const shotLikeData = (product_id) => {
    const platFormData = {
      app_id: app_id.id,
      product_id,
      plateform: 'web'
    }
    APICall('post', platFormData, `${EndPoints.productLike}`, 'store')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          toast.success(res?.data?.message)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const [localStorageValue, setLocalStorageValue] = useState(app_id || null)

  useEffect(() => {
    const event = emitter.addListener(EventType.ProductLibraryDelete, ({ product_id, action }) => {
      if (action === 'delete') {
        const temp = [...trendingProduct]
        const newData = temp.filter((item) => item.id !== product_id)
        console.log(newData)
        // setTrendingProduct()
      } else {
        getTrending()
      }
    })
    return () => event.remove()
  }, [trendingProduct])

  useEffect(() => {
    const handleStorageChange = () => {
      setLocalStorageValue(app_id || null)
    }

    window.addEventListener('storage', handleStorageChange)

    return () => window.removeEventListener('storage', handleStorageChange)
  }, [app_id])

  useEffect(() => {
    if (localStorageValue !== null) {
      getTrending()
    }
  }, [localStorageValue])

  useEffect(() => {
    if (
      ShowConfirmModel ||
      EditProduct ||
      ShowProduct ||
      ShowLibraryConfirmModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [
    ShowConfirmModel,
    EditProduct,
    ShowProduct,
    ShowLibraryConfirmModel
  ])

  const [editData, setEditData] = useState(null)
  const EditLibrary = (data) => {
    setEditProduct(true)
    setShowProduct(true)
    setEditData(data)
    // setSelectedImages([data.image])
    // oneProductDetails(data)
  }

  return (
    <div className="content-manager-awards-wrapper add-profile-video">
      <div className="discussions-wrapper-header common-contnt-wrapper pb-3">
        <div className="tag_link mb-0">
          <h2>{English.B75}</h2>
          {(trendingProduct?.length < 4 && trendingProduct.length !== 0) && (
            <div className="add-video-rows hidden">
              <CommonButton
                label={English.G137}
                imgSrc={Images.AddIco}
                onClick={AddProducts}
                isPrimary
                className="add_new_content"
              />
            </div>
          )}
        </div>
      </div>

      {trendingProduct?.length === 0 && !isLoading && (
        <CommonEmptyData
          className="w-full !border-solid"
          image={Images.Inventory_2}
          mainTitle={English.B72}
          descriptionText={English.B73}
          // onClick={AddProducts}
          // addIcon={Images.AddIco}
          // btnText={English.B74}
          classNameDescription="!text-textSecondary !text-[16px] !font-normal"
        />
      )}

      <div className="!grid !grid-cols-4 gap-6 max-[1300px]:!grid-cols-3">
        {isLoading && trendingProduct?.length === 0 ? (
          <div className="awards-item">
            <div className="add-profile-video-cover add-product-video-cover">
              <button type="button">
                <img src={Images.Plus} alt="add library" />
              </button>
            </div>
            <div className="product-footer">
              <p className="add-value">
                <Skeleton className="add-value" width={202} height={35} />
              </p>
              <span className="add-value">
                <Skeleton className="add-value" width={67} height={35} />
              </span>
            </div>
          </div>
        ) : (
          trendingProduct?.map((data, i) => {
            return (<ProductItem
              i={i}
              isDeal
              EditLibrary={EditLibrary}
              HandleCancelConfirm={HandleCancelConfirm}
              setShowConfirmModel={setShowConfirmModel}
              ShowConfirmModel={ShowConfirmModel}
              data={data}
              deleteTrending={deleteTrending}
              loading={loading}
              productLikeData={() => trendingProductLikeData(data)}
              setUpdatingIndex={setUpdatingIndex}
              updatingIndex={updatingIndex}
              key={i}
            />)
          })
        )}
      </div>

      {ShowProduct && (
        <AddDealModal
          isProductEdit={EditProduct}
          setIsProductEdit={setEditProduct}
          setShowProduct={setShowProduct}
          onComplete={() => {
            if (EditProduct) {
              getTrending()
            }
            setEditProduct(false)
            setEditData(null)
          }}
          editData={editData}
        />
      )}

    </div>
  )
}
export default LatestDeal

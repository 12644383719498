import React from 'react'

const HeadingComponent = ({
  title,
  className,
  parentClass,
  children
}) => {
  return (
    <div className={`flex justify-between items-center mt-6 mb-2.5 ${parentClass}`}>
      <h2
        className={`text-mainColor text-[18px] font-black italic leading-none mb-0 uppercase ${className}`}
      >
        {title}
      </h2>
      <div className="flex items-center gap-1.5">
        {children}
      </div>
    </div>
  )
}

export default HeadingComponent

import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import { APICall, EndPoints } from '../../../services'
import { English } from '../../../helpers'
import { s3ImageProfile, urlSystemIcon } from '../../../services/Service'

const PlatformSelect = ({ defaultIds, onSelectedIds, step }) => {
  const [appData, setAppData] = useState([])
  const [isAppLoading, setIsAppLoading] = useState([])

  useEffect(() => {
    getApplicationsData()
  }, [step])

  const getApplicationsData = async () => {
    setIsAppLoading(true)
    APICall('get', '', EndPoints.applications, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedData = res?.decryptedData
          const newAppData = []
          decryptedData.forEach((item) => {
            newAppData.push({
              ...item,
              isSelected: defaultIds?.some((ids) => ids.id === item.id)
            })
          })
          setAppData(newAppData)
          setIsAppLoading(false)
        } else {
          toast.error(res.data?.message)
          setIsAppLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsAppLoading(false)
      })
  }

  return (
    <div className="w-full transition-all">
      <span className="text-black my-3 flex place-content-center font-semibold text-base items-center self-center ">
        {English.B125}
      </span>
      {isAppLoading &&
      <div className="grid grid-cols-4">
        {Array.from({ length: 4 }).map((_, index) => {
          return (
            <div key={index} className="w-[265.5px] h-[130px] content-center text-center justify-items-center overflow-hidden rounded-2xl bg-white border-[0.5px] border-BorderLight">
              <Skeleton circle width={80} height={80} />
            </div>
          )
        })}
      </div>}
      {!isAppLoading &&
      <div className="grid grid-cols-4">
        {appData.map((i, index) => (
          <div
            key={i.id}
            className={`w-[265.5px] h-[130px] p-3 content-center justify-items-center rounded-2xl bg-white ${
              i.isSelected ? '!border-[1px] !border-[#020C1C]' : 'border-[1px] border-SecondaryBorder'
            }`}
            onClick={() => {
              const newCheckboxes = [...appData]
              newCheckboxes[index].isSelected =
                !newCheckboxes[index].isSelected
              newCheckboxes[index].checked = newCheckboxes[index].isSelected
              setAppData(newCheckboxes)
              onSelectedIds(appData.filter((item) => item.isSelected))
            }}
          >
            {!i.isSelected ?
              <img src={urlSystemIcon + i.icon} alt="" />
            :
              <div className="justify-end transition-all">
                <div className="rounded-tr-[15px] rounded-bl-[15px] relative">
                  <img
                    className="!w-[100px] !h-[100px] rounded-[60px] border-[2px] border-[#020C1C]"
                    alt=""
                    src={s3ImageProfile + i.profile_image}
                  />
                  <div className="h-[40px] w-[40px] bg-background rounded-full p-2 border-[2px] border-[#020C1C] text-center mt-[6px] mx-auto absolute bottom-0 right-0">
                    <img
                      className="h-[23px] w-[35px]"
                      alt=""
                      src={urlSystemIcon + i.svg_icon}
                    />
                  </div>
                </div>
              </div>}
          </div>
        ))}
      </div>}
    </div>
  )
}

export default PlatformSelect

import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { English, Images } from '../../helpers'
import Loader from '../Loader/Loader'
import { s3ImageProfile } from '../../services/Service'
import { timeDifference } from '../../helpers/Utility'
import CommonButton from '../ButtonComponent/ButtonCommon'
import ImageComponent from '../ImageComponent/ImageComponent'

const reviews = [
  {
    id: 1,
    comment_id: 1,
    user_name: 'Andre Miles',
    comment:
      'Rarely seen such a cool sweepstakes, but Lacoste has really created a cool sweepstakes',
    created_at: '2025-03-11 15:22:53',
    profile_icon: Images.Friend_image1,
    replay_data: [],
    is_like: false
  },
  {
    id: 2,
    comment_id: 2,
    user_name: 'Davina Parker',
    comment:
      'I really want to win it for my friend as a surprise. He is crazy about Lacoste',
    created_at: '2025-03-11 10:22:53',
    profile_icon: Images.Friend_image2,
    replay_data: [],
    is_like: false
  },
  {
    id: 3,
    comment_id: 3,
    user_name: 'Mary K.',
    comment: 'I just love this brand! Hopefully, luck is on my side',
    created_at: '2025-03-10 15:22:53',
    profile_icon: Images.Friend_image3,
    replay_data: [],
    is_like: false
  },
  {
    id: 4,
    comment_id: 4,
    user_name: 'Andre Miles',
    comment:
      'Rarely seen such a cool sweepstakes, but Lacoste has really created a cool sweepstakes',
    created_at: '2025-03-01 15:22:53',
    profile_icon: Images.Friend_image1,
    replay_data: [],
    is_like: false
  },
  {
    id: 5,
    comment_id: 5,
    user_name: 'Davina Parker',
    comment:
      'I really want to win it for my friend as a surprise. He is crazy about Lacoste',
    created_at: '2025-02-11 15:22:53',
    profile_icon: Images.Friend_image2,
    replay_data: [],
    is_like: false
  },
  {
    id: 6,
    comment_id: 6,
    user_name: 'Mary K.',
    comment: 'I just love this brand! Hopefully, luck is on my side',
    created_at: '2025-03-10 18:22:53',
    profile_icon: Images.Friend_image3,
    replay_data: [],
    is_like: false
  }
]

const DummyComment = ({
  data,
  isLoadingComments,
  onNewComment,
  onCommentReplay,
  onLikeClick,
  commentsCount,
  hasMore,
  onLoadMore
}) => {
  const userData = useSelector((state) => state.user?.userData)
  const [comments, setCommets] = useState(reviews)
  const [replayTo, setReplayTo] = useState(null)
  const [newComment, setNewComment] = useState('')
  const [showReplay, setShowReplay] = useState(null)

  useEffect(() => {
    if (data) {
      setCommets(data)
    }
  }, [data])

  const CommentItem = ({ review, index }) => {
    return (
      <div
        key={`${review.comment_id}`}
        className={`${review?.reply_data ? 'mb-3' : 'mt-3'} last:border-none last:pb-0 last:mb-0`}
      >
        <div className="flex gap-3">
          <div className="">
            <ImageComponent
              className="w-[36px] h-[36px] rounded-full"
              imageUrl={`${data ? s3ImageProfile : ''}${review?.profile_icon}`}
              key={review?.profile_icon}
            />
          </div>
          <div className="w-full">
            <div className="flex items-center justify-between w-full">
              <h4 className="text-[16px] font-bold text-[#151515] mb-0">
                {review.user_name}
              </h4>
              <p className="text-sm text-textSecondary mb-0">
                {timeDifference(moment(review?.created_at).toDate())}
              </p>
            </div>
            <div className="flex items-center mb-2">
              {[...Array(5)].map((_, index) => (
                <span key={index} className="text-[#FFD739] text-xl">
                  ★
                </span>
              ))}
              <span className="ml-2 text-sm font-semibold">
                {review.rating}
              </span>
            </div>
            <p className="text-[#4E4949] font-normal text-[16px]">
              {review.comment}
            </p>
            <div className="mt-2 flex gap-4 text-blue-500 text-sm">
              <button
                onClick={() => onLikeClick && onLikeClick(review)}
                type="button"
                className="flex items-center gap-[6px]"
              >
                {review?.is_like ? (
                  <img
                    className="h-[17px] w-[17px]"
                    src={Images.like_icon_unFill}
                    alt="Unlike"
                  />
                ) : (
                  <img
                    className="h-[17px] w-[17px]"
                    src={Images.like_icon}
                    alt="Like"
                  />
                )}
                <span className="font-normal text-[14px]">
                  {review?.is_like ? 'UnLike' : 'Like'}
                </span>
              </button>
              {review?.reply_data && (
                <button
                  onClick={() => setReplayTo(review)}
                  type="button"
                  className="flex items-center gap-[6px]"
                >
                  <img src={Images.reply_icon} alt="" />
                  <span className="font-normal text-[14px]">Reply</span>
                </button>
              )}
              {review?.reply_data?.length > 0 && (
                <button
                  onClick={() => setShowReplay(showReplay === index ? undefined : index)}
                  type="button"
                  className="flex items-center gap-[6px]"
                >
                  <span className="font-normal text-[14px]">
                    {showReplay === index ? 'hide replay' : 'show replay'}
                  </span>
                </button>
              )}
            </div>
            {showReplay === index && (
              <div className="mt-3 py-2 border-y-[1px] border-BorderLight">
                {review?.reply_data?.map((item, index) => {
                  return (
                    <CommentItem
                      key={`${index} - item.comment_id`}
                      review={item}
                    />
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  const coommentView = useMemo(() => {
    return (
      <>
        {comments.map((review, index) => (
          <CommentItem key={review?.comment_id} index={index} review={review} />
      ))}
      </>
    )
  }, [comments, showReplay])

  return (
    <div className="">
      {isLoadingComments && <Loader />}
      <div className="border border-SecondaryBorder rounded-[14px] p-[14px] mt-3.5">
        <div className="w-full">
          <h3 className="text-lg text-Main font-black italic mb-7">
            Comment (
            {commentsCount || 0}
            )
          </h3>
          {coommentView}
          {hasMore && (
            <div className="flex justify-center mb-4">
              <CommonButton
                type="button"
                label="Load More"
                onClick={onLoadMore}
                disabled={isLoadingComments}
                context=""
                className="px-4 py-2 text-sm font-semibold text-white bg-primaryColor !border-none hover:!bg-none !w-[220px] flex gap-2"
                spanClassName="!text-brandText !text-sm !not-italic"
                imgSrc={Images.load_more_icon}
              />
            </div>
          )}
        </div>

        {replayTo && (
          <div className="flex flex-row justify-between bg-background p-2">
            <span>
              replay to
              {replayTo.user_name}
            </span>
            <div className="cursor-pointer" onClick={() => setReplayTo(null)}>
              x
            </div>
          </div>
        )}
        <div className="mt-4 flex items-center gap-3.5">
          <img
            src={s3ImageProfile + userData.profile_image}
            alt="User"
            className="w-12 h-12 rounded-full mr-3 border !border-[#C7D5E0]"
          />
          <input
            type="text"
            placeholder="Type here to reply..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            className="w-full outline-none bg-headerbg rounded-[35px] h-12 px-7"
          />
          <button
            type="button"
            onClick={() => {
              if (replayTo && onCommentReplay) {
                onCommentReplay(newComment, replayTo)
                setReplayTo(null)
              } else if (onNewComment) {
                onNewComment(newComment)
              }
              setNewComment('')
            }}
          >
            {English.G152}
          </button>
        </div>
      </div>
    </div>
  )
}

export default DummyComment

/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default-member */
import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import DatePicker from 'react-datepicker'
import { Link, useNavigate } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import { useSelector } from 'react-redux'
import moment from 'moment/moment'
import {
  compressImage,
  dataDecrypt,
  setUserSliceData
} from '../../services/http-services'
import '../../App.css'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import {
  // preventNonAlphabeticKeydownNoSpaces,
  preventNonAlphabeticKeydownWithSingleSpace,
  // preventNonAlphabeticPasteNoSpaces,
  preventNonAlphabeticPasteWithSingleSpace,
  preventNonAlphanumericKeydownWithSingleSpace,
  preventNonAlphanumericPasteWithSingleSpace
} from '../../helpers/Utility'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import SidebarPageCreator from './SidebarPageCreator'
import CountryStateCityDropDown from './CommonComponent/CountryStateCityDropDown'

const UserInformation = () => {
  // const token = Utility.getToken()
  const UserData = useSelector((state) => state.user?.userData)
  const user_type = useSelector((state) => state.user?.user_type)
  const navigate = useNavigate()
  const [profileImg, setProfileImg] = useState(null)
  const [profileImgObj, setProfileImgObj] = useState(null)
  const [inputFields, setInputFields] = useState({
    company_name: '',
    company_street: '',
    company_city: '',
    company_vat: '',
    company_zip_code: 1,
    date_of_birth: '',
    full_name: '',
    profile_image: ''
  })
  const [selectedCity, setSelectedCity] = useState('')
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [showSuccessModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [infoModal, setInfoModal] = useState(false)

  const datePickerRef = useRef(null)

  const validateValues = () => {
    const errors = {}
    if (!inputFields.full_name.match(/^[a-zA-ZÀ-Ÿ-. ]*$/)) {
      errors.full_name = English.G48
    }
    if (!formatDate(startDate).match(/^\d{4}-\d{2}-\d{2}$/)) {
      errors.date_of_birth = English.G49
    }
    if (!inputFields.company_zip_code.match(/^[0-9]*$/)) {
      errors.company_zip_code = English.G50
    }
    if (!inputFields.company_vat.match(/^[A-Z]{2}[A-Z0-9]{2,30}$/)) {
      errors.company_vat = English.G51
    }
    return errors
  }
  const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']
  const maxFileSizeMB = 8
  const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024

  function formatDate(date) {
     return moment(date).format('YYYY-MM-DD')
   }

  const handleImageUpload = (file) => {
    if (file) {
      if (allowedTypes.includes(file.type)) {
        if (file.size > maxFileSizeBytes) {
          toast.error(`File size exceeds ${maxFileSizeMB} MB`)
          return
        }
        const imgUrl = URL.createObjectURL(file)
        setProfileImg(imgUrl)
        setProfileImgObj(file)
      } else {
        toast.error('Please upload a valid image file (JPEG/JPG/PNG)')
      }
    }
  }

  // ... existing code ...

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors(validateValues(inputFields))
    setSubmitting(true)
  }

  const userDataSubmit = () => {
    setLoading(true)
    inputFields.date_of_birth = formatDate(startDate)
    inputFields.type = user_type
    APICall('post', inputFields, EndPoints.userUpdateProfile, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedData = dataDecrypt(res?.data?.data)
          const data_data =
                    user_type === 'creator'
                        ? decryptedData?.creator_data
                        : user_type === 'business'
                          ? decryptedData?.business_data
                          : decryptedData?.member_data
                    const userData = { ...decryptedData, ...data_data }
                    setUserSliceData(userData, 'userInfo')
          navigate('/creator-slide')
          setLoading(false)
          toast.success(res?.data?.message)
        } else {
          toast.error(res.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const finishSubmit = async () => {
    // if (submitting) {
    //   navigate('/creator-slide')
    //   return
    // }
    const compressedImage = await compressImage(profileImgObj)
    const formData = new FormData()
    formData.append('file', compressedImage)
    formData.append('type', 'business_profile')
    formData.append('sub_type', 'profile_image')
     APICall('post', formData, EndPoints.uploadImage, 'profile', true)
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          inputFields.profile_image = dataDecrypt(res.data.data)
          userDataSubmit()
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((e) => {
        toast.error(e?.data?.message)
      })
  }

  const handleButtonClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true)
    }
  }

  const InfoClick = () => {
    setInfoModal(true)
  }
  const ClickOk = () => {
    setInfoModal(false)
  }

  const handleDateChange = (date) => {
    const today = new Date()
    const minDate = new Date(
      today.getFullYear() - 15,
      today.getMonth(),
      today.getDate()
    )

    if (date <= minDate) {
      setStartDate(date)
    } else {
      setStartDate(null)
      if (datePickerRef.current) {
        datePickerRef.current.setOpen(true)
      }
      toast.error(English.G52)
    }
  }
  const today = new Date()
  const minYear = today.getFullYear() - 15
  const maxYear = today.getFullYear() - 100

  const years = []
  for (let year = minYear; year >= maxYear; year--) {
    years.push(year)
  }

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit()
    }
  }, [errors])
  useEffect(() => {
    setIsButtonEnabled(
      inputFields?.company_street?.length > 0 &&
        inputFields?.company_city?.length > 0 &&
        inputFields?.company_vat?.length > 0 &&
        inputFields?.company_zip_code?.length > 4 &&
        startDate &&
        inputFields?.full_name?.length > 0 &&
        profileImg
    )
  }, [inputFields, profileImg])

  useEffect(() => {
    setInputFields({
      ...inputFields,
      company_city: selectedCity
    })
  }, [selectedCity])

  return (
    <div className="auth-register user-info">
      <div className="row overlay-height bg-overlay">
        <div className="col-md-6 py-[20px]">
          <SidebarPageCreator />
        </div>
        <div className="col-md-6 h-100 pt-[20px] pb-4">
          <div className="inline-padding bg-white rounded-2xl h-full d-flex flex-col mr-[20px] overflow-y-auto no-scrollbar">
            <img
              src={Images.Black_back}
              alt="back"
              className="w-[15px] h-[15px] cursor-pointer"
              onClick={() => {
                setUserSliceData(false, 'user_type')
                navigate('/user-type-select')
              }}
            />
            <h2 className="signup_text mb-0">{English.A59}</h2>
            <h5 className="bg-[#151515] text-center bg-transparent text-[24px] font-black italic mb-1">
              {English.A81}
            </h5>
            <div className="input-profile-img mt-4 !mb-[30px]">
              <div className="d-flex user-profile-crop">
                <div>
                  <CommonImageUpload
                    handleImageChange={handleImageUpload}
                    index={0}
                    forImage="profile"
                    className="profile-upload"
                    selectedImg={profileImg}
                    defaultImg={Images.dummy_avatar}
                    defaultImgCamera={Images.AddImageIco}
                  />
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="d-flex col-gap-20">
                <p className="w-50">
                  <label className="business_label_text">{English.G54}</label>
                  <div>
                    <CommonInput
                      className="ps-3"
                      name="full_name"
                      placeholder={English.G55}
                      required
                      onChange={handleChange}
                      onKeyDown={preventNonAlphabeticKeydownWithSingleSpace}
                      onPaste={preventNonAlphabeticPasteWithSingleSpace}
                    />
                  </div>
                  {errors.full_name ? (
                    <p className="error-text">{errors.full_name}</p>
                  ) : null}
                </p>
                <p className="w-50">
                  <label className="business_label_text">{English.A63}</label>
                  <div className="email-input input-container">
                    <img
                      src={Images.input_logo}
                      alt="Logo"
                      className="input-logo"
                    />
                    <CommonInput
                      type="text"
                      placeholder={UserData?.user_name}
                      className="custom-input business_email"
                      readOnly
                    />
                  </div>
                </p>
              </div>
              <div className="d-flex col-gap-20">
                <p className="w-50">
                  <label className="business_label_text">{English.G56}</label>
                  <div className="celender">
                    <DatePicker
                      ref={datePickerRef}
                      selected={startDate}
                      required
                      showMonthDropdown
                      autoComplete="off"
                      showYearDropdown
                      onChange={handleDateChange}
                      name="date_of_birth"
                      dateFormat="yyyy-MM-dd"
                      placeholderText={English.G57}
                      maxDate={
                        new Date(
                          today.getFullYear() - 15,
                          today.getMonth(),
                          today.getDate()
                        )
                      }
                      onKeyDown={(e) => {
                        if (
                          e.key !== 'Backspace' &&
                          e.key !== 'ArrowLeft' &&
                          e.key !== 'ArrowRight'
                        ) {
                          e.preventDefault()
                        }
                      }}
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled
                      }) => (
                        <div className="datepicker-header">
                          <button
                            type="button"
                            className="datepicker-button"
                            onClick={(e) => {
                              e.preventDefault()
                              decreaseMonth()
                            }}
                            disabled={prevMonthButtonDisabled}
                          >
                            {'<'}
                          </button>
                          <select
                            className="datepicker-select"
                            value={date.getFullYear()}
                            onChange={({ target: { value } }) => changeYear(Number(value))}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          <select
                            className="datepicker-select"
                            value={months[date.getMonth()]}
                            onChange={({ target: { value } }) => {
                              changeMonth(months.indexOf(value))
                            }}
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          <button
                            type="button"
                            className="datepicker-button"
                            onClick={(e) => {
                              e.preventDefault()
                              increaseMonth()
                            }}
                            disabled={nextMonthButtonDisabled}
                          >
                            {'>'}
                          </button>
                        </div>
                      )}
                    />
                    <button
                      className="celender-button"
                      type="button"
                      onClick={handleButtonClick}
                    >
                      <img src={Images.calendar} alt="" />
                    </button>
                  </div>
                  {errors.date_of_birth ? (
                    <p className="error-text">{errors.date_of_birth}</p>
                  ) : null}
                </p>
                <p className="w-50">
                  <label className="business_label_text">{English.G58}</label>
                  <div className="">
                    <CommonInput
                      name="company_name"
                      className="ps-3"
                      placeholder={English.G59}
                      onChange={handleChange}
                      onKeyDown={preventNonAlphabeticKeydownWithSingleSpace}
                      onPaste={preventNonAlphabeticPasteWithSingleSpace}
                    />
                  </div>
                </p>
              </div>
              <div className="d-flex col-gap-20">
                <p className="w-50">
                  <label className="business_label_text">{English.A82}</label>
                  <div className="">
                    <CommonInput
                      name="company_street"
                      className="ps-3"
                      placeholder={English.G62}
                      required
                      onChange={handleChange}
                      onKeyDown={preventNonAlphanumericKeydownWithSingleSpace}
                      onPaste={preventNonAlphanumericPasteWithSingleSpace}
                    />
                  </div>
                </p>
                <p className="w-50">
                  <div className="flex justify-between">
                    <label className="business_label_text">{English.G63}</label>
                    <button onClick={InfoClick} type="button" className="mr-1 w-[20px] h-[20px]">
                      <img alt="" src={Images.Info_icon} />
                    </button>
                  </div>
                  <div className="">
                    <CommonInput
                      name="company_vat"
                      className="ps-3"
                      placeholder={English.G64}
                      required
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        const regex = /^[a-zA-Z0-9]*$/
                        if (!regex.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                    />
                  </div>
                  {errors.company_vat ? (
                    <p className="error-text">{errors.company_vat}</p>
                  ) : null}
                </p>
              </div>

              <div className="d-flex col-gap-20">
                <p className="w-50">
                  <label className="business_label_text">{English.G65}</label>
                  <div className="">
                    <CountryStateCityDropDown
                      type="city"
                      onValueSelected={(value) => {
                        setSelectedCity(value)
                      }}
                      value={inputFields.company_city || English.G272}
                      key="city"
                    />
                  </div>
                </p>
                <p className="w-50">
                  <label className="business_label_text">{English.G67}</label>
                  <div className="">
                    <CommonInput
                      name="company_zip_code"
                      className="ps-3"
                      placeholder={English.G68}
                      required
                      maxLength={5}
                      onChange={handleChange}
                      pattern="\d{5}"
                      inputMode="numeric"
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/\D/g, '')
                          .slice(0, 5)
                      }}
                    />
                  </div>
                  {errors.company_zip_code ? (
                    <p className="error-text">{errors.company_zip_code}</p>
                  ) : null}
                </p>
              </div>

              <p className="d-flex justify-center mt-3">
                <CommonButton
                  label={
                    <span className="flex items-center">
                      {English.G69}
                      <img
                        src={Images.left_white_aero}
                        alt="white aero"
                        className="ml-5 inline-block"
                      />
                    </span>
                  }
                  type="submit"
                  id="sub_btn"
                  loading={loading}
                  className="primary-button m-auto relative !max-w-[380px]"
                  disabled={!isButtonEnabled || loading}
                  context="primary"
                  isPrimary
                />
              </p>

              {infoModal && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                  <div className="bg-white rounded-2xl shadow-lg p-8 w-[460px]">
                    <h2 className="text-2xl font-black italic text-center bg-[#0B0A0A] pb-3 mb-3">
                      {English.A92}
                    </h2>
                    <p className="text-[#303030] font-medium text-base text-center mb-6">
                      {English.A93}
                    </p>
                    <CommonButton
                      label={English.G72}
                      type="button"
                      onClick={ClickOk}
                      className="primary-button m-auto login_button"
                      context="primary"
                      isPrimary
                    />
                  </div>
                </div>
              )}
            </form>
            <footer className="flex-1 d-flex flex-col pt-4">
              <div className="text-[10px] text-placeholder text-center items-center justify-center !max-w-[550px] !mx-auto">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor
                erat enim mattis nunc, eget volutpat. Aenean sed urna, facilisi
                vitae, volutpat faucibus.enim mattis nunc, eget volutpat. Aenean
                sed urna, facilisi vitae, volutpat faucibus
              </div>
            </footer>
          </div>
        </div>
      </div>
      {showSuccessModal && (
        <div className="custom-model-overlay">
          <div className="success-modal">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="62"
              height="62"
              viewBox="0 0 62 62"
              fill="none"
            >
              <path
                d="M62 30.9998C62 37.131 60.1821 43.1245 56.7757 48.2224C53.3694 53.3204 48.5277 57.2938 42.8631 59.6402C37.1986 61.9865 30.9654 62.6005 24.952 61.4044C18.9386 60.2082 13.4152 57.2557 9.07979 52.9203C4.74436 48.5849 1.79188 43.0611 0.595741 37.0476C-0.600401 31.0342 0.0132298 24.8014 2.35955 19.1369C4.70586 13.4724 8.67931 8.6306 13.7772 5.22427C18.8752 1.81794 24.8687 0 30.9999 0C39.2203 0.00436442 47.1029 3.27177 52.9156 9.08446C58.7282 14.8972 61.9956 22.7794 62 30.9998Z"
                fill="url(#paint0_linear_1862_74860)"
              />
              <path
                d="M46.8556 23.8712L28.3674 42.0935C28.1057 42.3562 27.7935 42.565 27.4491 42.7074C27.1048 42.8498 26.7351 42.9231 26.3617 42.9231C25.9883 42.9231 25.6187 42.8498 25.2743 42.7074C24.93 42.565 24.6178 42.3562 24.356 42.0935L15.1046 32.9823C14.5853 32.4531 14.298 31.7436 14.3048 31.0071C14.3116 30.2707 14.6118 29.5663 15.1407 29.0465C15.6696 28.5267 16.3846 28.2331 17.1312 28.2291C17.8778 28.2251 18.5962 28.5113 19.1308 29.0255L26.369 36.1507L42.8294 19.8997C43.0937 19.6389 43.4073 19.4319 43.7527 19.2908C44.0981 19.1497 44.4684 19.0769 44.8423 19.0769C45.2162 19.0769 45.5865 19.1497 45.9319 19.2908C46.2773 19.4319 46.5912 19.6389 46.8556 19.8997C47.12 20.1604 47.3298 20.4701 47.4728 20.8108C47.6159 21.1515 47.6893 21.5164 47.6893 21.8852C47.6893 22.254 47.6159 22.6193 47.4728 22.96C47.3298 23.3007 47.12 23.6104 46.8556 23.8712Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1862_74860"
                  x1="15.5"
                  y1="4.1509"
                  x2="46.5"
                  y2="57.8491"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00FF00" />
                  <stop offset="0.61" stopColor="#00DA00" />
                  <stop offset="1" stopColor="#00C700" />
                </linearGradient>
              </defs>
            </svg>
            <h4>{English.G70}</h4>
            <p>
              {English.G71}
              {' '}
            </p>
            <Link to="/app-icons" className="w-100">
              <button className="secondary-button m-auto" type="button">
                <span>{English.G72}</span>
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}
export default UserInformation

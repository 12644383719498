/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { toast } from 'react-toastify'
import { cloneDeep } from 'lodash'
import ReactDatePicker from 'react-datepicker'
import ParticularSkeleton from './particularSkeleton'
import EventPopup from './addPopup/eventPopup'
import CommonButton from '../ButtonComponent/ButtonCommon'
import { compressImage, dataDecrypt } from '../../services/http-services'
import { s3ImageOrganizer, urlSystemIcon } from '../../services/Service'
import CommonInput from '../InputComponet/CommonInput'
import { normalizeAddress } from '../../helpers/Utility'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'
import { emitter, EventType } from '../../helpers/Emitter'
import ContactModal from './ContactModal'
import RatingSummary from './EventComponent/RatingSummary'
import EventShow from './EventComponent/EventShow'
import OrderChart from './chart'
import LocalDetailGrid from './CommonComponent/LocalDetailGrid'


const OrganizerProfileDetails = ({ organizerId }) => {
  const [startDate, setStartDate] = useState(new Date())
  const [isPlatFormModal, setIsPlatFormModal] = useState(false)
  const [isEvent, setIsEvent] = useState(false)
  const [inputFields, setInputFields] = useState({
    city_id: '',
    contact_name: '',
    contact_designation: '',
    // contact_phone: '',
    contact_profile_pic: '',
    contact_id: '',
    organizer_id: ''
  })

  const [isParticularEventData, setIsParticularEventData] = useState([])
  const [isOrganizerId, setIsOrganizerId] = useState(organizerId)
  const [editData, setEditData] = useState({})
  const [isShow, setIsShow] = useState(false)
  const [appData, setAppData] = useState([])
  //   const [particularOrganizerDetail, setParticularOrganizerDetail] =
  //     useState(false)
  const [personDetails, setPersonDetails] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isParticularLoading, setIsParticularLoading] = useState(false)
  const [isEventEdit, setIsEventEdit] = useState(false)
  const [expandedIndex, setExpandedIndex] = useState(null)
  const [isAddingNewEvent, setIsAddingNewEvent] = useState(false)
  const [contactLoading, setContactLoading] = useState(false)

  const handleCancels = () => {
    const resetAppData = appData.map((item) => ({
      ...item,
      checked: false
    }))
    setAppData(resetAppData)
    setIsPlatFormModal(false)
  }

  const clearContactValue = () => {
    setInputFields({
      ...inputFields,
      city_id: '',
      contact_name: '',
      contact_designation: '',
      //   contact_phone: '',
      contact_profile_pic: '',
      contact_id: ''
    })
    setIsEdit(false)
  }

  const onClickEdit = (data) => {
    setIsEvent(true)
    setIsEventEdit(true)
    setEditData({
      organizer_id: data?.organizer_id,
      location_title: data?.location_title,
      name: data?.name,
      address: normalizeAddress(data?.address),
      description: data?.description,
      country_id: data?.country_id,
      province_id: data?.province_id,
      city_id: data?.city_id,
      zip_code: data?.zip_code,
      longitude: data?.longitude,
      latitude: data?.latitude,
      website: data?.website,
      logo: data?.logo,
      images: data?.images,
      price_range:
        data?.min_price && data?.max_price
          ? `${data?.min_price} - ${data?.max_price}`
          : '',
      min_price: data?.min_price,
      max_price: data?.max_price,
      contact_name1: data?.contact_details?.[0]?.contact_name ?? '',
      contact_name2: data?.contact_details?.[1]?.contact_name ?? '',
      contact_designation1:
        data?.contact_details?.[0]?.contact_designation ?? '',
      contact_designation2:
        data?.contact_details?.[1]?.contact_designation ?? '',
      //   contact_phone1: data?.contact_details?.[0]?.contact_phone ?? null,
      //   contact_phone2: data?.contact_details?.[1]?.contact_phone ?? null,
      contact_profile_pic: [
        data?.contact_details?.[0]?.contact_profile_pic,
        data?.contact_details?.[1]?.contact_profile_pic
      ]
    })
  }

  const onChange = (e) => {
    setInputFields((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const clearValues = () => {
    setIsParticularEventData([])
  }

  const handleCancel = () => {
    setIsEvent(false)
    setIsAddingNewEvent(false)
  }

  const getStoreLink = (website) => {
    if (!website) return undefined
    return website.startsWith('http') ? website : `http://${website}`
  }

  const onUploadImage = async (file, fieldName) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'organizer_contact')

        try {
          const res = await APICall(
            'post',
            coverFormData,
            '',
            'imageUploadLocal',
            true
          )

          if (res.status === 200 && res.data?.code === 200) {
            const decryptedCoverData = dataDecrypt(res.data.data)
            setInputFields((prev) => {
              return {
                ...prev,
                [fieldName]: decryptedCoverData
              }
            })
          } else {
            toast.error(res?.data?.message || 'Error uploading image')
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || 'Upload failed')
        }
      }

      reader.readAsDataURL(file)
    }
  }

  const cancelContact = () => {
    setPersonDetails(false)
    clearContactValue()
  }

  const getApplicationsData = async () => {
    APICall('get', '', EndPoints.applications, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedData = res?.decryptedData
          setAppData(decryptedData)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const OrganizerPublishApi = async (app_id) => {
    const publishState = !app_id
    const data = {
      organizer_id: isOrganizerId,
      app_id: app_id
        ? [app_id]
        : appData.filter(({ checked }) => checked).map((e) => e.id),
      publish: publishState
    }
    APICall('post', data, `${EndPoints.publishOrganizer}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          getApplicationsData()
          setIsPlatFormModal(false)
          toast.success(res?.data?.message)
        } else {
          toast.error(res?.data?.message)
          setIsPlatFormModal(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const particularEventData = (id, type = null) => {
    setIsParticularLoading(true)
    setIsOrganizerId(id)
    const payload = {
      organizer_id: id
    }
    APICall('post', payload, `${EndPoints.particularOrganizer}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsShow(true)
          setIsParticularEventData(res?.decryptedData)
          //   setParticularOrganizerDetail(true)
          getApplicationsData()
          setIsParticularLoading(false)
          if (type === 'isEdit') {
            onClickEdit(res?.decryptedData[0])
          }
        } else {
          setIsParticularLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsParticularLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const eventContactEditApi = async () => {
    setContactLoading(true)
    const data = {
      organizer_id: isOrganizerId,
      contact_name: inputFields.contact_name,
      contact_designation: inputFields.contact_designation,
      //   contact_phone: inputFields.contact_phone,
      contact_profile_pic: inputFields.contact_profile_pic,
      contact_id: inputFields.contact_id
    }
    if (isEdit) {
      APICall('post', data, `${EndPoints.organizerContactEdit}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setTimeout(() => {
              setPersonDetails(false)
              setIsEdit(false)
              clearContactValue()
              particularEventData(inputFields.organizer_id)
              toast.success(res?.data?.message)
              setContactLoading(false)
            }, 2500)
          } else {
            setIsEdit(false)
            setContactLoading(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setContactLoading(false)
          setIsEdit(false)
          toast.error(error?.data?.message)
        })
    } else {
      APICall('post', data, `${EndPoints.organizerContactAdd}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setTimeout(() => {
              setPersonDetails(false)
              particularEventData(isOrganizerId)
              toast.success(res?.data?.message)
              setContactLoading(false)
              clearContactValue()
            }, 2500)
          } else {
            setContactLoading(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setContactLoading(false)
          toast.error(error?.data?.message)
        })
    }
  }

  const isStep4Valid = () => {
    return (
      inputFields.contact_profile_pic.length > 0 &&
      inputFields.contact_name &&
      inputFields.contact_designation
      //   inputFields.contact_phone.length > 10
    )
  }

  useEffect(() => {
    particularEventData(organizerId)
  }, [])

  useEffect(() => {
    const event = emitter.addListener(
      EventType.particularOrganizer,
      ({ action }) => {
        if (action === 'edit') {
          particularEventData(organizerId)
        }
      }
    )
    return () => event.remove()
  }, [isParticularEventData])

  return (
    <div className="">
      <div className="bg-white rounded-[15px] !p-0">
        <div className="!mt-0">
          {isParticularLoading ? (
            <ParticularSkeleton />
          ) : (
            isParticularEventData.map((data, index) => (
              <div key={index}>
                <LocalDetailGrid type="Event" data={data} baseImageUrl={s3ImageOrganizer} />
                <div className="grid grid-cols-2 w-full !gap-5">
                  <div>
                    <div className="font-black text-lg italic text-Colormain uppercase">
                      About
                    </div>
                    <div
                      className=""
                    >
                      <p className="text-Colormain text-base mb-2.5">
                        {expandedIndex === index
                          ? data?.description
                          : `${data?.description?.slice(0, 180)}`}
                        {data?.description?.length > 180 && (
                          <span
                            className="see_more_local"
                            onClick={(e) => {
                              e.stopPropagation()
                              setExpandedIndex(
                                expandedIndex === index ? null : index
                              )
                            }}
                          >
                            ...
                            {' '}
                            {expandedIndex === index
                              ? English.G193
                              : English.G194}
                          </span>
                        )}
                      </p>
                      <div className="profile-redirect">
                        <a
                          className="gredient-txt"
                          target="_blank"
                          href={getStoreLink(data.website)}
                          rel="noreferrer"
                        >
                          <img alt="" src={Images.Redirect} />
                          <u className="border-b border-[#001597]">
                            {data.website ? data.website : English.G278}
                          </u>
                        </a>
                      </div>
                      <div className="flex gap-2 items-center mt-4">
                        <img
                          alt=""
                          src={Images.dummygroup_img}
                          className=""
                        />
                        <p className="text-[#0B0A0A] text-[14px] font-normal mb-0">Your friends Sara, Emily, Jenny, Alexa are attending this event</p>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <RatingSummary />
                  </div>
                </div>
                {isPlatFormModal && (
                  <div className="add-item-model">
                    <div className="add-item-content local_type_modal">
                      <div className="type_close mb-2  local_sticky_header">
                        <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                          {English.G277}
                        </div>
                        <button
                          className="cancel_icon"
                          type="button"
                          onClick={handleCancels}
                        >
                          <img src={Images.RemoveLocal} alt="" />
                        </button>
                      </div>
                      <div className="grid grid-cols-4 !gap-5">
                        {appData.map((i, index) => {
                          const isChecked =
                            isParticularEventData[0]?.app_data?.some(
                              (d) => d.id === i.id
                            )
                          return (
                            <div
                              className="profile-checkbox aspect-[3/2]"
                              key={index}
                            >
                              <img src={urlSystemIcon + i.icon} alt="" />
                              <div className="custom-check-box">
                                <CommonInput
                                  type="checkbox"
                                  value={i.id}
                                  checked={isChecked || i.checked}
                                  onChange={(e) => {
                                    const newCheckboxes = [...appData]
                                    newCheckboxes[index].checked =
                                      e.target.checked
                                    setAppData(newCheckboxes)
                                  }}
                                  className="!min-h-max"
                                />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <div className="local_button_row">
                        <CommonButton
                          label={English.G116}
                          onClick={handleCancels}
                          isPrimary={false}
                          context={English.G232}
                        />
                        <CommonButton
                          label={English.G128}
                          type="button"
                          isPrimary
                          onClick={() => {
                            const clone = cloneDeep(isParticularEventData)
                            setIsParticularEventData(clone)
                            OrganizerPublishApi()
                          }}
                          context={English.G232}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>

        {isShow && (
          <div className="position-relative local-tabs section-local event-tab">
            <Tabs>
              <div className="list_overview">
                <TabList>
                  <Tab><span>Overview</span></Tab>
                  <Tab>
                    <span>Event</span>
                  </Tab>
                </TabList>

              </div>

              <TabPanel>
                {' '}
                <div className="grid grid-cols-4 !gap-4 !mb-5">
                  <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
                    <div className="w-[50px] h-[50px]">
                      <img src={Images.Visitor} alt="visitor" />
                    </div>
                    <div className="">
                      <p className="text-Main text-base italic font-black mb-[5px] uppercase">
                        Locations
                      </p>
                      <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                        25
                        {/* <img src={Images.UpPath} alt="" />
                        <span>0%</span> */}
                      </span>
                    </div>
                  </div>
                  <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
                    <div className="">
                      <img src={Images.impressionLocal} alt="impression" />
                    </div>
                    <div className="">
                      <p className="text-Main text-base italic font-black mb-[5px] uppercase">
                        Footfall
                      </p>
                      <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                        2.5k
                        <img src={Images.UpPath} alt="" />
                        <span className="text-[#00B69B] text-sm font-semibold">
                          1.3%
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
                    <div className="">
                      <img src={Images.ClicksLocal} alt="Clicks" />
                    </div>
                    <div className="">
                      <p className="text-Main text-base italic font-black mb-[5px] uppercase">
                        Clicks
                      </p>
                      <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                        358
                        <img src={Images.UpPath} alt="" />
                        <span className="text-[#00B69B] text-sm font-semibold">
                          1.3%
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
                    <div className="">
                      <img src={Images.RequestsLocal} alt="request" />
                    </div>
                    <div className="">
                      <p className="text-Main text-base italic font-black mb-[5px] uppercase">
                        engagement
                      </p>
                      <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                        86.04%
                        <img src={Images.UpPath} alt="" />
                        <span className="text-[#00B69B] text-sm font-semibold">
                          1.3%
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="content-manager-Statistics">
                  <div className="statistic-chart-container position-relative">
                    <h4>{English.G286}</h4>
                    <div className="drop-and-date !top-2.5">
                      <ReactDatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showYearPicker
                        dateFormat="yyyy"
                      />
                    </div>
                    <OrderChart />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <EventShow
                  isOrganizerId={isOrganizerId}
                  latitude={isParticularEventData[0]?.latitude}
                  longitude={isParticularEventData[0]?.longitude}
                />
              </TabPanel>
            </Tabs>
          </div>
        )}
      </div>

      {personDetails && (
        <ContactModal
          contactLoading={contactLoading}
          cancelContact={cancelContact}
          onUploadImage={onUploadImage}
          inputFields={inputFields}
          s3Image={s3ImageOrganizer}
          onChange={onChange}
          nextClickApiCall={eventContactEditApi}
          disabled={!isStep4Valid()}
        />
      )}

      {isEvent && (
        <EventPopup
          onCancel={handleCancel}
          defaultData={isAddingNewEvent ? {} : editData}
          apiSuccess={() => {
            // allOrganizerData(null, currentPage)
            clearValues()
            setIsEvent(false)
          }}
          setIsShow={setIsShow}
          editEvent={isEventEdit}
        />
      )}
    </div>
  )
}
export default OrganizerProfileDetails
